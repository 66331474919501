<mat-card class="over-Flow">
    <mat-card-header>  
      <mat-card-title  class="title" >
  
        Work With Events</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <!-- <div class="buttons">
          <button mat-button (click)="OrderEventsHardDeleteForm()" class="addButton buttons">
            <mat-icon>add</mat-icon>
            <span>New Storer Attribute</span>
          </button>
        </div> -->
                    
        <form [formGroup]="form" class="form over-Flow">
          <div class="row">
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Bill Number</mat-label>
                  <input matInput formControlName="SRCH1"/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Data Element Code</mat-label>
                  <input matInput formControlName="DECD"/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>From Date</mat-label>
                  <input matInput formControlName="FRDATE"/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>To Date</mat-label>
                  <input matInput formControlName="TODATE"/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Status Code</mat-label>
                  <input matInput formControlName="EVCD"/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Event Status</mat-label>
                  <input matInput formControlName="ESTS"/>
              </mat-form-field>

            
                 <button
                  class="addButton"
                     mat-raised-button
                        type="submit"
                        placeholder="Search">
                        Search
                  </button>
                </div>
              </div>
            </form>           
        </mat-card-content>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Opt">
              <th mat-header-cell mat-header-cell *matHeaderCellDef>Opt</th> 
              <td mat-cell *matCellDef="let element">{{ element.OPTION }}</td>
            </ng-container>
    
            <ng-container matColumnDef="Bill Number">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Bill Number</th> 
                <td mat-cell *matCellDef="let element">{{ element.EVDOCN }}</td>
              </ng-container>
              <ng-container matColumnDef="Seq No">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Seq No</th> 
                <td mat-cell *matCellDef="let element">{{ element.EVNUMR }}</td>
              </ng-container>
              <ng-container matColumnDef="Elm No">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Elm No</th> 
                <td mat-cell *matCellDef="let element">{{ element.EVDELM }}</td>
              </ng-container>
              <ng-container matColumnDef="Status Code">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Status Code </th> 
                <td mat-cell *matCellDef="let element">{{ element.EVCODE }}</td>
              </ng-container>
              <ng-container matColumnDef="Description">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Description</th> 
                <td mat-cell *matCellDef="let element">{{ element.DESC }}</td>
              </ng-container>
              <ng-container matColumnDef="Reason Code">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Reason Code</th> 
                <td mat-cell *matCellDef="let element">{{ element.EVSRCD }}</td>
              </ng-container>
              <ng-container matColumnDef="Date">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Date</th> 
                <td mat-cell *matCellDef="let element">{{ element.EVDATE }}</td>
              </ng-container>
              <ng-container matColumnDef="Time">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Time </th> 
                <td mat-cell *matCellDef="let element">{{ element.EVTIME }}</td>
              </ng-container>
              <ng-container matColumnDef="Sts">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>Sts</th> 
                <td mat-cell *matCellDef="let element">{{ element.EVSTAT }}</td>
              </ng-container>
      
              <ng-container matColumnDef="Actions">
                <th  mat-header-cell mat-header-cell *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
             
                  <mat-option>
                  <button mat-icon-button (click)="OrderEventChangeStatusForm(element)">
                    <mat-icon mat-icon-button color="primary">edit</mat-icon>
                    <mat-label>Change</mat-label>
                  </button>
                </mat-option>
                <mat-option>
                  <button mat-icon-button (click)="OrderEventDeleteForm(element)">
                    <mat-icon mat-icon-button color="primary">delete</mat-icon>
                    <mat-label>Delete</mat-label>
                  </button>
                </mat-option> 
                  
                </mat-select>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
             
          </table>
          <mat-paginator
      #paginator
      [pageSizeOptions]="[4, 8, 16]"
      showFirstLastButtons
    ></mat-paginator>
          </mat-card>