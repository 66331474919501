import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderCommentsComponent } from './order-comments/order-comments.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderDeleteComponent } from './order-delete/order-delete.component';
import { OrderDshipComponent } from './order-dship/order-dship.component';
import { OrderEventEntryComponent } from './order-event-entry/order-event-entry.component';
import { OrderEventHistoryComponent } from './order-event-history/order-event-history.component';
import { OrderMoveHistoryInquiryComponent } from './order-move-history-inquiry/order-move-history-inquiry.component';
import { OrderSendtoMoveQueueComponent } from './order-sendto-move-queue/order-sendto-move-queue.component';
import { OrderShipConfirmComponent } from './order-ship-confirm/order-ship-confirm.component';
import { OrderUpdateComponent } from './order-update/order-update.component';
import { OrderService } from './order.service';

import { GlobalComponent } from 'src/app/Global-Component';

interface CustomColumn {
  position: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css'],
})
export class OrdersComponent implements OnInit {
  
  constructor(
    private router: Router,
    public orderSvc: OrderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  displayedColumns: string[] = [
    'Sts',
    'OrderNumber',
    'OT',
    'StorerNumber',
    'ShpTyp',
    'SchedShip',
    'ActualShip',
    'StorerReference',
    'Actions',
  ];

  GlobalComponent: any = GlobalComponent;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  selectedRow: any;
  editedRows: any;  
  //form!: FormGroup;
  total_quantity: any;
  consignee: any;
  dataSource: any = [];
  duplicateSource: any;
  user = localStorage.getItem('userName');
  result: any;
  searchTextDisplay: any;
  columnShowHideList: CustomColumn[] = [];
  
  updateForm = this.fb.group({
    billNo: '',
    refNo: '',
    poNo: '',
    proNo: '',
    consignee: '',
    carrier: '',
    shipDates: '',
    status: '',
    bldg: '',
    storer: '',
    suffix: '',
    building: '',
    trk: '',
    broker: '',
    openAll: 'O',
  });

  ngOnInit(): void {

    this.editedRows = [];
    this.initializeColumnProperties();
    if (localStorage.getItem('billOfLading') !== null) {
      localStorage.removeItem('billOfLading');
      localStorage.removeItem('orderStorer');
      localStorage.removeItem('totalQuantity');
      localStorage.removeItem('consignee');
    }

    this.orderSvc.getAllOrderDetails(this.user).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('ORDERS DATA');
      console.log(this.duplicateSource);
      this.dataSource = new MatTableDataSource(this.duplicateSource);
      this.dataSource.paginator = this.paginator;
    });
  }

  readOneOrder(res: any) {
    this.orderSvc
      .readOrderService(
        this.user,
        res.billNo,
        res.status,
        res.storer,
        res.suffix,
        res.refNo,
        res.consignee,
        res.building,
        res.poNo,
        res.carrier,
        res.proNo
      )
      .subscribe((data) => {
        this.result = data;
        var response = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(response);
        this.duplicateSource = parsedData.returnedData;
        console.log('ORDERS DATA');
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  toggleColumn(column: any) {
    if (column.isActive) {
      if (column.position > this.displayedColumns.length - 1) {
        this.displayedColumns.splice(this.displayedColumns.length-1,0,column.name);
      } else {
        this.displayedColumns.splice(column.position, 0, column.name);
      }
    } else {
      let i = this.displayedColumns.indexOf(column.name);
      let opr = i > -1 ? this.displayedColumns.splice(i, 1) : undefined;
    }
    console.log(this.displayedColumns);
    
  }

  initializeColumnProperties() {
    this.displayedColumns.forEach((element, index) => {
      if (element != 'Actions') {
        this.columnShowHideList.push({
          position: index,
          name: element,
          isActive: true,
        });
      }
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length,
      name: 'Consignee',
      isActive: false,
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length,
      name: 'Carrier',
      isActive: false,
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length,
      name: 'PONumber',
      isActive: false,
    });
  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }

  onBack() {
    this.router.navigate(['tallys']);
  }

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  OrdersUpdateForm(element: any) {
    this.matDialog.open(OrderUpdateComponent, {
      width: '5000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }

  OrdersDeleteForm(element: any) {
    this.matDialog.open(OrderDeleteComponent, {
      width: '5000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }

  OrderEventHistory(element: any) {
    this.matDialog.open(OrderEventHistoryComponent, {
      width: '5000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }

  orderDship(element: any) {
    console.log(element);
    this.matDialog.open(OrderDshipComponent, {
      width: '5000px',
      maxHeight: '130vh',
      data: element,
      autoFocus: false,
    });
  }

  OrderSendToMoveQueue(element: any) {
    this.matDialog.open(OrderSendtoMoveQueueComponent, {
      width: '1000px',
      maxHeight: '130vh',
      data: element,
      autoFocus: false,
    });
  }

  OrderMoveToHistoryInquiry(element: any) {
    this.matDialog.open(OrderMoveHistoryInquiryComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  OrderComments(element: any) {
    this.matDialog.open(OrderCommentsComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  orderShipConfirm(element: any) {
    this.matDialog.open(OrderShipConfirmComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  addOrder() {
    this.matDialog.open(OrderCreateComponent, {
      width: '1000px',
      maxHeight: '130vh',
      autoFocus: false,
    });
  }

  orderDetails(element: any) {
    this.router.navigateByUrl('/main-nav/orders/details', { state: element });
  }

  EventEntry(element: any) {
    this.matDialog.open(OrderEventEntryComponent, {
      width: '1000px',
      maxHeight: '130vh',
      autoFocus: false,
    });
  }
}
