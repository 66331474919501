<mat-card class="over-Flow">
  
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title class="title">Work With Consignee Documents</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Consignee </mat-label>
            <input matInput formControlName="Consignee" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="IO">
      <th mat-header-cell *matHeaderCellDef>IO</th>
      <td mat-cell *matCellDef="let element">{{ element.IO }}</td>
    </ng-container>

    <ng-container matColumnDef="DocumentType">
      <th mat-header-cell *matHeaderCellDef width="60px">Document Type</th>
      <td mat-cell *matCellDef="let element">
        {{ element.DocumentType }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ApplicationName">
      <th mat-header-cell *matHeaderCellDef>Application Name</th>
      <td mat-cell *matCellDef="let element">{{ element.ApplicationName }}</td>
    </ng-container>

    <ng-container matColumnDef="ValidityProgram">
      <th mat-header-cell *matHeaderCellDef>Validity Program</th>
      <td mat-cell *matCellDef="let element">{{ element.ValidityProgram }}</td>
    </ng-container>

    <ng-container matColumnDef="ValidityMessage">
        <th mat-header-cell *matHeaderCellDef>Validity Message</th>
        <td mat-cell *matCellDef="let element">{{ element.ValidityMessage }}</td>
      </ng-container>
  

    <ng-container matColumnDef="File">
      <th mat-header-cell *matHeaderCellDef>File</th>
      <td mat-cell *matCellDef="let element">{{ element.File }}</td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
