import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-ww-inbound-details-extra-data',
  templateUrl: './ww-inbound-details-extra-data.component.html',
  styleUrls: ['./ww-inbound-details-extra-data.component.css']
})
export class WwInboundDetailsExtraDataComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [    
    'Extra Data Information',
    'Actions',
    
    
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      EDDESC:'',
      Actions:'',     

        
    },  
    
  ]
  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwInboundDetailsExtraDataComponent>
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      EDDOCT:'943', 
      DOCN:'1157820',
      
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/WwInboundDetails/read']);
 
}
  closeDialog(){
    this.dialogRef.close();
  }

}
