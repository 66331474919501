<mat-card class="over-Flow">

  <div class="main-header-title">    
      <mat-card-header>  
        <mat-card-title>Move Locations</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>
      
    <mat-card-content class="tally-serach-form"> 
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
            <mat-form-field  appearance="outline"><mat-label> Storer : </mat-label> 
              <input matInput formControlName="Storer" />
            </mat-form-field>
             

            <mat-form-field appearance="outline">  <mat-label> Item: </mat-label> 
            
              <input
                matInput
                formControlName="Item"
                style="text-transform: uppercase"
                readonly
              />
            
            </mat-form-field>

            <mat-form-field appearance="outline">  <mat-label> Lots : </mat-label> 
              <input matInput formControlName="Lots" />
            </mat-form-field>
             
            <mat-form-field appearance="outline">  <mat-label> From: </mat-label> 
              <input
                matInput
                formControlName="Form"
                style="text-transform: uppercase"
                readonly
              />            
            </mat-form-field>      

            <mat-form-field appearance="outline">  <mat-label> Quantity  : </mat-label> 
              <input matInput formControlName="Quantity" readonly/>
            </mat-form-field>
             
            <mat-form-field appearance="outline">  <mat-label> NewLocation: </mat-label> 
              <input
                matInput
                formControlName="NewLocation"
                style="text-transform: uppercase"
                required
              />  
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> NewLots: </mat-label> 
              <input matInput formControlName="NewLots" />
            </mat-form-field>
          
              
            </div>
          
          </div>
          <button [disabled]="!(form.valid && form.dirty)" mat-button class="buttons addButton" (click)="Locations()">Update</button>
        
       </form>
    </mat-card-content>
  
</mat-card>