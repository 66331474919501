import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TallyData } from '../tally-data';
import { GlobalComponent } from 'src/app/Global-Component';
import { EmailExitPointsCreateComponent } from './email-exit-points-create/email-exit-points-create.component';
import { EmailExitPointsUpdateComponent } from './email-exit-points-update/email-exit-points-update.component';
import { EmailExitPointsDeleteComponent } from './email-exit-points-delete/email-exit-points-delete.component';
import { EmailExitPointsCopyComponent } from './email-exit-points-copy/email-exit-points-copy.component';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
interface EmailExitPointIn {
  EPPOIN: String;
  EPEFMT: String;
  EPNTYP: String;
  EPCTYP: String;
  EPMNAM: String;
  EPMBLD: String;
  EPSTAT: String;
}

@Component({
  selector: 'app-email-exit-points',
  templateUrl: './email-exit-points.component.html',
  styleUrls: ['./email-exit-points.component.css'],
})
export class EmailExitPointsComponent implements OnInit {
  form = this.fb.group({
    EPPOIN: [],
  });

  displayedColumns: String[] = [
    'EmailExitPoint',
    'MessageFormat',
    'ControlNoType',
    'ContactType',
    'MessageName',
    'MessageBuilder',
    'StatusCode',
    'Actions',
  ];

  dataSource: EmailExitPointIn[] = [
    {
      EPPOIN: 'RECCONF',
      EPEFMT: 'HTML',
      EPNTYP: 'IHSTOR',
      EPCTYP: '944CSR',
      EPMNAM: 'IHCONFHTML',
      EPMBLD: 'IHP300R',
      EPSTAT: 'AC',
    },
    {
      EPPOIN: 'RECCONF',
      EPEFMT: 'TEXT',
      EPNTYP: 'IHSTOR',
      EPCTYP: '944CSR',
      EPMNAM: 'IHCONFTXTM',
      EPMBLD: 'IHP300R',
      EPSTAT: 'AC',
    },
    {
      EPPOIN: 'RECSCHD',
      EPEFMT: 'HTML',
      EPNTYP: 'IHSTOR',
      EPCTYP: 'ARRCSR',
      EPMNAM: 'IHRCPTHTML',
      EPMBLD: 'IHP300R',
      EPSTAT: 'AC',
    },
    {
      EPPOIN: 'RECSCHD',
      EPEFMT: 'TEXT',
      EPNTYP: 'IHSTOR',
      EPCTYP: 'ARRCSR',
      EPMNAM: 'MHRCPTHTML',
      EPMBLD: 'IHP300R',
      EPSTAT: 'AC',
    },
    {
      EPPOIN: 'RECSCHDC',
      EPEFMT: 'HTML',
      EPNTYP: 'IHCONS',
      EPCTYP: '943CSR',
      EPMNAM: 'IHRCPTHTML',
      EPMBLD: 'IHP300R',
      EPSTAT: 'AC',
    },
    {
      EPPOIN: 'RECSCHDC',
      EPEFMT: 'TEXT',
      EPNTYP: 'IHCONS',
      EPCTYP: '943CSR',
      EPMNAM: 'IHRCPTTEXT',
      EPMBLD: 'IHP300R',
      EPSTAT: 'AC',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private route: Router,
    private router: ActivatedRoute
  ) {}
  addEmailExitPoint() {
    // this.route.navigate(['../', 'add'], { relativeTo: this.router });
    this.matDialog.open(EmailExitPointsCreateComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
    });
  }
  getUpdateForm(element: any) {
    // this.route.navigateByUrl('main-nav/email-exit-points/update', {
    //   state: element,
    // });
    this.matDialog.open(EmailExitPointsUpdateComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }
  getDeleteForm(element: any) {
    // this.route.navigateByUrl('main-nav/email-exit-points/delete', {
    //   state: element,
    // });
    this.matDialog.open(EmailExitPointsDeleteComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }
  getCopyForm(element: any) {
    // this.route.navigateByUrl('main-nav/email-exit-points/copy', {
    //   state: element,
    // });
    this.matDialog.open(EmailExitPointsCopyComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }
  ngOnInit(): void {}
}
