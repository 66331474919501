<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Item Weights & Measure </mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">  
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Storer</mat-label>
              <input readonly matInput formControlName="storeNumber" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Suffix</mat-label>
              <input readonly matInput formControlName="suffix" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Item </mat-label>
              <input readonly matInput formControlName="item" />
            </mat-form-field>
        </div>
      </div>
      <b>Weights</b>
      <hr />

      <div class="row"> 
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Unit Gross Weight</mat-label>
          <input matInput formControlName="unitGrossWeight" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Weight Unit of Measure</mat-label>
          <mat-select formControlName="unitWeightType">
            <mat-option value="LB">LB = Pound</mat-option>
            <mat-option value="OZ">OZ = Ounce</mat-option>
            <mat-option value="GR">GR = Gram</mat-option>
            <mat-option value="KG">KG = Kilogram</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </div>
      <b>Dimensions</b>
      <hr />

      <div class="row"> 
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Units per Tier</mat-label>
          <input matInput formControlName="casesPerTier" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Tiers per Pallet </mat-label>
          <input matInput formControlName="tiersPerPallet" />
        </mat-form-field>
      </div>
      </div>

      <div class="row"> 
        <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Unit Square Feet</mat-label>
          <input matInput formControlName="unitSquareFeet" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Unit Cubic Feet</mat-label>
          <input matInput formControlName="unitCubeFeet" />
        </mat-form-field>
      </div>
      </div>

      <div class="row"> 
        <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>High </mat-label>
          <input matInput formControlName="unitInchesHigh" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Wide </mat-label>
          <input matInput formControlName="unitInchesWide" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Deep </mat-label>
          <input matInput formControlName="unitInchesDeep" />
        </mat-form-field>
      </div>
      </div>
      <b>Units of Measure</b>

      <hr />
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>1st Unit of Measure</mat-label>
          <input matInput formControlName="unitOfMeasure1" />
        </mat-form-field>
      </div>
      </div>
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>2nd Unit of Measure</mat-label>
          <input matInput formControlName="unitOfMeasure2" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Unit Multiplier</mat-label>
          <input matInput formControlName="qtyPer2UnitOfMultiplier" />
        </mat-form-field>
      </div>
      </div>
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>3rd Unit of Measure</mat-label>
          <input matInput formControlName="unitOfMeasure3" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Unit Multiplier</mat-label>
          <input matInput formControlName="qtyPer3UnitOfMultiplier" />
        </mat-form-field>
      </div>
      </div>
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>4th Unit of Measure</mat-label>
          <input matInput formControlName="unitOfMeasure4" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Unit Multiplier</mat-label>
          <input matInput formControlName="qtyPer4UnitOfMultiplier" />
        </mat-form-field>
      </div>
      </div>
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Inventory Unit</mat-label>
          <input matInput formControlName="inventoryUnit" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Shipping Unit</mat-label>
          <input matInput formControlName="shippingUnit" />
        </mat-form-field>

      </div>  
      </div>
      <button mat-button (click)="itemUpdate2()" class="addButton buttons">
        Update
      </button>
    </form>
    </mat-card-content>
</mat-card>

