<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>Maintain Contacts</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
    
    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form" fxLayoutAlign="center">
            <div class="row">
                <div class="col">
                    <mat-form-field  appearance="outline">
                        <mat-label> Consignee  </mat-label> 
                        <input readonly matInput formControlName="Consignee"/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Contact Name  </mat-label> 
                        <input readonly matInput formControlName="contactName" required/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Contact Type </mat-label> 
                        <input matInput formControlName="contactType" required/>
                    </mat-form-field>
                
                    <mat-form-field  appearance="outline">
                        <mat-label> Telephone Number  </mat-label> 
                        <input matInput formControlName="telePhone"/>
                    </mat-form-field>
                    <mat-form-field  appearance="outline">
                        <mat-label>Cellphone Number  </mat-label> 
                        <input matInput formControlName="cellPhone" />
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label>Fax Number  </mat-label> 
                        <input matInput formControlName="faxNumber"/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label>Email Address  </mat-label> 
                        <input matInput formControlName="emailAddress" />
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Email Format   </mat-label> 
                        <mat-select formControlName="emailFormat">
                            <mat-option value="TEXT">TEXT</mat-option>
                            <mat-option value="HTML">HTML</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Status Code </mat-label> 
                        <input matInput formControlName="statusCode"/>
                    </mat-form-field>

                 
                    <button mat-button (click)="updateStorerContact()" class="buttons addButton">
                        <span>Update</span>
                    </button>

                </div>
            </div>

        </form>
    </mat-card-content>
</mat-card>

