import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwStorerAttributesCreateComponent } from './ww-storer-attributes-create/ww-storer-attributes-create.component';
import { WwStorerAttributesUpdateComponent } from './ww-storer-attributes-update/ww-storer-attributes-update.component';
import { WwStorerAttributesDeleteComponent } from './ww-storer-attributes-delete/ww-storer-attributes-delete.component';

@Component({
  selector: 'app-ww-storer-attributes',
  templateUrl: './ww-storer-attributes.component.html',
  styleUrls: ['./ww-storer-attributes.component.css']
})
export class WwStorerAttributesComponent implements OnInit {


  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    
    'Attribute',
    'Value',
    'Actions'
   
  ];
  dataSource:any=[]


  duplicateSource:any =[
    {
      IANTYP:'MAXCCTRY',
      IANUMB:'3',
        
    },
   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      IASTOR:'65', 
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
 
}

StorerAttributesCreateForm(){
  this.matDialog.open(WwStorerAttributesCreateComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
    //  'data': element,
     "autoFocus": false                           
    });
    }

    StorerAttributesUpdateForm(element:any){
      this.matDialog.open(WwStorerAttributesUpdateComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        }
        StorerAttributesDeleteForm(element:any){
          this.matDialog.open(WwStorerAttributesDeleteComponent, {
              "width": '1000px',
               "maxHeight": '90vh',
             'data': element,
             "autoFocus": false                           
            });
            }

            addAttribute(){

            }

            // df
             

}
