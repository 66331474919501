<mat-card>

  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Lot Code Inquiry</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>

  <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
            <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Storer</mat-label>
                   <input readonly matInput value="{{this.storer}}"/>
               </mat-form-field>
             
                <mat-form-field appearance="outline">
                    <mat-label>Item</mat-label>
                    <input readonly matInput value="{{this.item}}"/>
                </mat-form-field>
              
            </div>
        </div>     
      </form>
  </mat-card-content>

   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">

       <ng-container matColumnDef="lots">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Lots
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.ODLOTS}}</td>
         </ng-container>

         <ng-container matColumnDef="location">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Location
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.ODLOCN}}</td>
         </ng-container>
      
         <ng-container matColumnDef="available">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Available
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.ODQAVL}}</td>
         </ng-container>
        
         <ng-container matColumnDef="commited">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           Commited
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ODQCOM}}</td>
        </ng-container>
        <ng-container matColumnDef="damaged">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           Damaged
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ODQDAM}}</td>
        </ng-container>
        <ng-container matColumnDef="held">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           Held
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ODQHLD}}</td>
        </ng-container>
        <ng-container matColumnDef="user1">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           User1
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ODQUS1}}</td>
        </ng-container>
        <ng-container matColumnDef="user2">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           User2
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ODQUS2}}</td>
        </ng-container>
        <ng-container matColumnDef="user3">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           User3
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ODQUS3}}</td>
        </ng-container>
        <ng-container matColumnDef="workOrder">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           Work Order
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ODQUS4}}</td>
        </ng-container>
        <ng-container matColumnDef="shipped">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           Shipped
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ODQSHP}}</td>
        </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

       </table>
</mat-card>
