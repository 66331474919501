<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title class="title">Maintain Consignees</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" >
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Consignee Code</mat-label>
                    <input matInput formControlName="ConsigneeCode" required/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="Name" />
                </mat-form-field>
           
                <mat-form-field appearance="outline">
                    <mat-label>Address1</mat-label>
                    <input matInput formControlName="address1"  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Address2</mat-label>
                    <input matInput formControlName="address2"  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Address3</mat-label>
                    <input matInput formControlName="address3" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Address4</mat-label>
                    <input matInput formControlName="address4" />
                </mat-form-field>
           
                <mat-form-field appearance="outline">
                    <mat-label>Zip Code</mat-label>
                    <input matInput formControlName="ZipCode" required/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Zip Code Suffix</mat-label>
                    <input matInput formControlName="zipcodeSuffix" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Area Code Of PhoneNumber</mat-label>
                    <input matInput formControlName="areaCodeOfPhoneNumber" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>1st 3 digits of Phone mumber</mat-label>
                    <input matInput formControlName="firstThreeDigOfPhNum" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>final 4 digit of phone number</mat-label>
                    <input matInput formControlName="finalFourDigOfPhNum" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Zone Code</mat-label>
                    <input matInput formControlName="ZoneCode" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Destination Code</mat-label>
                    <input matInput formControlName="DestinationCode" />
                </mat-form-field>

                
            </div>            
        </div>
        <div>
            <button
                    class="buttons addButton"
                    mat-raised-button
                    (click)="addConsinee()">
                    Create
                </button>
        </div>
    </form>    
    </mat-card-content>

</mat-card>


<!-- <mat-card-footer>
    <button mat-button (click)="addConsignee()" class="addButton">           
        <mat-icon mat-icon-button color="primary">create</mat-icon>
        <mat-label>Consignee</mat-label>
    </button>
</mat-card-footer> -->

