import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import Swal from 'sweetalert2';
import { OrderDetailsService } from '../order-details.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-detail-move-queue',
  templateUrl: './order-detail-move-queue.component.html',
  styleUrls: ['./order-detail-move-queue.component.css']
})
export class OrderDetailMoveQueueComponent implements OnInit {

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    public orderDtlSrv: OrderDetailsService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
   private dialogRef: MatDialogRef<OrderDetailMoveQueueComponent>
   ) 
   { }

   storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  ngOnInit(): void {
    this.form = this.fb.group({
  
      storer:this.anyvariable.ODSTOR,
      bol:this.anyvariable.ODBILL,
      seq: this.anyvariable.ODSEQ,
      qty:this.anyvariable.ODQTYS,
      priority: '',
      location: ''
       
    });
  }


  orderDtlMoveQueue(){
    var res = this.form.value;   
    this.result = this.orderDtlSrv
      .orderDtlMoveQueueService(    
        res.bol,     
        res.seq,
        res.qty,
        res.priority,
        res.location  

        ).subscribe((data: any) => {
        this.result = data;
          console.log(this.result);
          
       var length = this.result[0].data.length;
          if(this.result[0].data[length-1].value=='Moveq Created Successfully'){
          
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg);
          this.closeDialog();
          
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/orders/read']);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
