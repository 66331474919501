<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Event History inquiry</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>BoxNumber</mat-label>
                      <input matInput formControlName="BoxNumber"/>
                      </mat-form-field>
                    
                      <mat-form-field appearance="outline">
                       <mat-label>Dates</mat-label>
                       <input matInput formControlName="Dates"/>
                       </mat-form-field>
                    
                     </div>
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Content</mat-label>
                       <input matInput formControlName="Content"/>
                       </mat-form-field>
                       
                      <mat-form-field appearance="outline">
                        <mat-label>User</mat-label>
                       <input matInput formControlName="User"/>
                       </mat-form-field>

                         
                      <mat-form-field appearance="outline">
                        <mat-label>Reason</mat-label>
                       <input matInput formControlName="Reason"/>
                       </mat-form-field>
                     </div>
                     <div class="col">
                              
                      <mat-form-field appearance="outline">
                        <mat-label>Account</mat-label>
                       <input matInput formControlName="Account"/>
                       </mat-form-field>
                     </div>
                   
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="Date">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Date
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Date }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Time">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Time
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Time }}</td>
        </ng-container>

        <ng-container matColumnDef="User">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                User
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.User }}</td>
          </ng-container>

          <ng-container matColumnDef="Description">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Description
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
          </ng-container>

          <ng-container matColumnDef="Sts">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Sts
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Sts }}</td>
          </ng-container>
  
        <ng-container matColumnDef="opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
