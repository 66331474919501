import { Building } from './../buildings/buildings.component';
import { GlobalComponent } from './../../Global-Component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { LocationStatusInquiryComponent } from './location-status-inquiry/location-status-inquiry.component';
import { MapLocationCreateComponent } from './map-location-create/map-location-create.component';
import { MapLocationDeleteComponent } from './map-location-delete/map-location-delete.component';
import { MapLocationUpdateComponent } from './map-location-update/map-location-update.component';
import { IMapLocationService } from './i-map-location.service';
import { MapCrossReferenceComponent } from './map-cross-reference/map-cross-reference.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-map-location',
  templateUrl: './map-location.component.html',
  styleUrls: ['./map-location.component.css'],
})
export class MapLocationComponent implements OnInit {
  
  constructor(
    private activeRouter: ActivatedRoute,
    public iMapSvc: IMapLocationService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder 
  ) {}

  displayedColumns: string[] = [
    'Location',
    'Zone',
    'F',
    'Rsrv_Slot',
    'Storer',
    'Item_Description',
    'PctOpen',
    'Pallets',
    'Dimensions',

    'Actions',
  ];
 
  form!: FormGroup;
  id: any;
  postingDate: any;
  result: any;
  dataSource: any = [];
  duplicateSource: any = [];
  @ViewChild('paginator') paginator!: MatPaginator;
  workHeaders: any = [];
  TallyHeaders: any = [];
  searchTextDisplay: any;

  ngOnInit(): void {

    this.form = this.fb.group({
      building: '',
      zone: '',
      location: '',
      hwd: '',
      availableCapacity: '',
    });
    
    this.mapLocation();
  }
 
  user = localStorage.getItem('userName');
  mapLocation() {
    this.iMapSvc.getAll(this.user).subscribe((data) => {
      this.result = data;
      var res = this.result;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);      
      this.duplicateSource = parsedData.returnedData;
      console.log('MAP LOCATION DATA')
      console.log(this.duplicateSource);
      this.dataSource = new MatTableDataSource(this.duplicateSource);      
      this.dataSource.paginator = this.paginator;
    });
  }

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  getMapLocationBySerach(maplocation: any) {
    this.iMapSvc
      .getMapLocation(
        this.user,
       GlobalComponent.fullBuilding(maplocation.building),
        maplocation.zone,
        '',
        '',
        ''
      )
      .subscribe((data) => {

        this.result = data;
        this.TallyHeaders = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('MAP LOCATION DATA BY SEARCH')
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  mapLocationCreate() {
    this.matDialog.open(MapLocationCreateComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
    });
  }

  mapLocationUpdate(maplocation: any) {
    this.matDialog.open(MapLocationUpdateComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: maplocation,
      autoFocus: false,
    });
  }

  mapLocationDelete(maplocation: any) {
    this.matDialog.open(MapLocationDeleteComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: maplocation,
      autoFocus: false,
    });
  }

  locationStatusInquiry() {
    this.matDialog.open(LocationStatusInquiryComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
    });
  }
  XReference(element: any) {
    this.matDialog.open(MapCrossReferenceComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: element,
      autoFocus: false,
    });
  }

  closeDialog() {
    //  this.dialogRef.close();
  }

  backstorer() {

  }
  
}
