import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-code-change',
  templateUrl: './product-code-change.component.html',
  styleUrls: ['./product-code-change.component.css']
})
export class ProductCodeChangeComponent implements OnInit {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<ProductCodeChangeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      storerNumber: [null],
      productCode: [null],
      description:[null],
      status:[null]
     


    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }


}
