import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkScheduleCreateComponent } from './work-schedule-create/work-schedule-create.component';
import { WorkScheduleDeleteComponent } from './work-schedule-delete/work-schedule-delete.component';
import { WorkScheduleUpdateComponent } from './work-schedule-update/work-schedule-update.component';

@Component({
  selector: 'app-ww-work-schedule',
  templateUrl: './ww-work-schedule.component.html',
  styleUrls: ['./ww-work-schedule.component.css']
})
export class WwWorkScheduleComponent implements OnInit {

  displayedColumns: string[] = ['type', 'build', 'account', 'dayOfTheWeek', 
   'startTime', 'endTime', 'Actions'];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      type: 'APPT',
      build: '001',
      account:'LCSTOR',
      dayOfTheWeek:'MONDAY',
      startTime:'',
      endTime:'',
      Actions:''

     


    }
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      typeOfHours: [null],
      building: [null],
      accountCode: [null]
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  workScheduleCreate() {
    this.matDialog.open(WorkScheduleCreateComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  workScheduleUpdate() {
    this.matDialog.open(WorkScheduleUpdateComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  workScheduleDelete(element :any) {
    this.matDialog.open(WorkScheduleDeleteComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }
  delete() {
    alert('deleted');
  }


}
