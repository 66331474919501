import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-general-invoice-details-change',
  templateUrl: './general-invoice-details-change.component.html',
  styleUrls: ['./general-invoice-details-change.component.css']
})
export class GeneralInvoiceDetailsChangeComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<GeneralInvoiceDetailsChangeComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      DGTASK:this.anyvariable.DGTASK,
      DGLEGC:this.anyvariable.DGLEGC,
      DGQTY:this.anyvariable.DGQTY,
      DGRATE:this.anyvariable.DGRATE,
      DGBASE:'',
      DGAMT:this.anyvariable.DGAMT,
      DGMINM:this.anyvariable.DGMINM,
      DGDESC:this.anyvariable.DGDESC,
      DGDTYP:'',
      DGDOCN:this.anyvariable.DGDOCN
    })
  }

  change(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
