import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { IitemService } from '../../iitem.service';

@Component({
  selector: 'app-item-adjustment-display',
  templateUrl: './item-adjustment-display.component.html',
  styleUrls: ['./item-adjustment-display.component.css']
})
export class ItemAdjustmentDisplayComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  constructor(public itemSvc: IitemService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ItemAdjustmentDisplayComponent>
   ) 
   { }

   dataSource:any;
   dateAndTime:any;
   changeUserId:any;
  time:any;
  ngOnInit(): void {

    this.dateAndTime='';
    this.changeUserId='';

  this.itemSvc.getItemAdjustmentInquiry(this.user, this.anyvariable.AJTRAN,this.anyvariable.AJSTOR,
        this.anyvariable.AJSSFX,this.anyvariable.AJITEM,this.anyvariable.AJLTC1,
        this.anyvariable.AJLTC2,this.anyvariable.AJLTC3,this.anyvariable.AJBLDG,
        this.anyvariable.AJFLOR,this.anyvariable.AJAISL,this.anyvariable.AJSLOT).subscribe((data:any) => {
        this.result = data;
        // this.TallyHeaders = data;
        var res = this.result[0].data[1]?.value;
        console.log(res);
        console.log('GET ALL DATA');
        var parsedData = JSON.parse(res);
        this.dataSource = parsedData.returnedData;
        //console.log(this.duplicateSource);
        this.dateAndTime = this.dataSource[0].AJADYY+'-'+this.dataSource[0].AJADMM+'-'+this.dataSource[0].AJADDD;
       if(this.dataSource[0].AJCHGT.toString().length == 4){
        this.time = this.dataSource[0].AJCHGT.toString().slice(0,2) +":"+ this.dataSource[0].AJCHGT.toString().slice(2,4);
       } 
       else{
        this.time = this.dataSource[0].AJCHGT.toString().slice(0,1)+":"+ this.dataSource[0].AJCHGT.toString().slice(1,3);
       }
       console.log(this.time);
       
       this.dateAndTime +=" "+ this.time;
        this.changeUserId=this.dataSource[0].AJCHGU;
          console.log(this.dateAndTime);
      
        });

      this.form = this.fb.group({

    storer:this.anyvariable.AJSTOR,
    item:this.anyvariable.AJITEM,
    lot:this.anyvariable.AJLTC1 +' '+this.anyvariable.AJLTC2+' '+this.anyvariable.AJLTC3,
    Location:this.anyvariable.AJBLDG+' ' +this.anyvariable.AJAISL+"/"+this.anyvariable.AJSLOT,
    adjustmentQuantity:this.anyvariable.AJBLWR,
    inventoryBucket:this.anyvariable.AJITYP,
    date_Time:this.dateAndTime,
    changeUserID:this.anyvariable.AJCHGU,
    wRorBillNumber:'',
    serial:'',
    reasonforAdjustment:'',

   
       
    })
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}
