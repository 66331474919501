<button mat-icon-button (click)="closeDialog()">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>
  
<form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Maintain Printer Routing Information</mat-card-title
        >
        &nbsp;
      </mat-card-header>
      <mat-card-content>
        
              <div class="col">
                
                <mat-form-field  appearance="outline">
                    <mat-label> Storer: </mat-label> 
                    <input  readonly matInput formControlName="storeNumber" style="text-transform: uppercase"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                  <mat-label> Suffix: </mat-label> 
                  <input readonly  matInput formControlName="suffix" style="text-transform: uppercase"/>
              </mat-form-field>

              <mat-form-field  appearance="outline">
                  <mat-label> Store Name: </mat-label> 
                  <input readonly  matInput formControlName="storeName" style="text-transform: uppercase"/>
              </mat-form-field>

            
                <mat-form-field  appearance="outline">
                    <mat-label> B/L Printer: </mat-label> 
                    <input matInput formControlName="blPrinter" style="text-transform: uppercase" /> 
                    <mat-error *ngIf="form.controls['blPrinter'].hasError('required')">
                      *This field is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls['blPrinter'].hasError('maxlength')">
                      only 10 characters are <strong>allowed</strong>
                  </mat-error>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> PickSheet Printer: </mat-label> 
                    <input matInput formControlName="picksheetPrinter" style="text-transform: uppercase" />  
                    <mat-error *ngIf="form.controls['picksheetPrinter'].hasError('required')">
                      *This field is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls['picksheetPrinter'].hasError('maxlength')">
                      only 10 characters are <strong>allowed</strong>
                  </mat-error>
                </mat-form-field>
             
                <mat-form-field  appearance="outline">
                    <mat-label> Control Copy Printer: </mat-label> 
                    <input matInput formControlName="controlcopyPrinter" style="text-transform: uppercase" /> 
                    <mat-error *ngIf="form.controls['controlcopyPrinter'].hasError('required')">
                      *This field is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls['controlcopyPrinter'].hasError('maxlength')">
                      only 10 characters are <strong>allowed</strong>
                  </mat-error>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label> W/R Printer: </mat-label> 
                    <input matInput formControlName="wrPrinter" style="text-transform: uppercase" /> 
                    <mat-error *ngIf="form.controls['wrPrinter'].hasError('required')">
                      *This field is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls['wrPrinter'].hasError('maxlength')">
                      only 10 characters are <strong>allowed</strong>
                  </mat-error>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Storage Invoice Printer: </mat-label> 
                    <input matInput formControlName="storageInvoicePrinter" style="text-transform: uppercase" /> 
                    <mat-error *ngIf="form.controls['storageInvoicePrinter'].hasError('required')">
                      *This field is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls['storageInvoicePrinter'].hasError('maxlength')">
                      only 10 characters are <strong>allowed</strong>
                  </mat-error>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> General Invoice Printer: </mat-label> 
                    <input matInput formControlName="generalInvoicePrinter" style="text-transform: uppercase" />  
                    <mat-error *ngIf="form.controls['generalInvoicePrinter'].hasError('required')">
                      *This field is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls['generalInvoicePrinter'].hasError('maxlength')">
                      only 10 characters are <strong>allowed</strong>
                  </mat-error>
                </mat-form-field>
                
                    
                <mat-form-field  appearance="outline">
                    <mat-label> EDI Report Printer: </mat-label> 
                    <input matInput formControlName="cartEdiReportPrinter" style="text-transform: uppercase" /> 
                    <mat-error *ngIf="form.controls['cartEdiReportPrinter'].hasError('required')">
                         *This field is <strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls['cartEdiReportPrinter'].hasError('maxlength')">
                      only 10 characters are <strong>allowed</strong>
                  </mat-error>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Accessorial Invoice  Printer: </mat-label> 
                    <input matInput formControlName="accessInvoicePrinter" style="text-transform: uppercase" />  
                    <mat-error *ngIf="form.controls['accessInvoicePrinter'].hasError('required')">
                      *This field is <strong>required</strong>
                      </mat-error>
                      <mat-error *ngIf="form.controls['accessInvoicePrinter'].hasError('maxlength')">
                        only 10 characters are <strong>allowed</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Freight Bill Printer: </mat-label> 
                    <input matInput formControlName="freightBillPrinter" style="text-transform: uppercase"  /> 
                    <mat-error *ngIf="form.controls['freightBillPrinter'].hasError('required')">
                      *This field is<strong>required</strong>
                    </mat-error>
                    <mat-error *ngIf="form.controls['freightBillPrinter'].hasError('maxlength')">
                      only 10 characters are <strong>allowed</strong>
                  </mat-error>
                </mat-form-field>

                        

                <button [disabled]="!(form.valid && form.dirty)" mat-button (click)="storerUpdate5()" class="addButton buttons">
                    
                    <span>Update</span>
                </button>
              
            </div>
         
      </mat-card-content>
     
    </mat-card>
  </form> 




