<!-- Forn No : task no:63.7(481) -->
<button mat-icon-button (click)="closeDialog()">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>
<mat-card>
    <mat-card-header>
   <mat-card-title>
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Inventory Synopsys</mat-card-title>
    </mat-card-header>
 
     <table mat-table [dataSource]="DataSource" class="mat-elevation-z8">
       <ng-container matColumnDef="Book">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Book
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Book}}</td>
         </ng-container>
         <ng-container matColumnDef="Number">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Number
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Number}}</td>
         </ng-container>

         <ng-container matColumnDef="Quantity">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Quantity
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Quantity}}</td>
          </ng-container>

  
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table> 

 
       <table mat-table [dataSource]="DataSource1" class="mat-elevation-z8">
        <ng-container matColumnDef="Count">
     <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Count
     </th> 
     <td mat-cell *matCellDef="let element">{{ element.Count }}</td>
   </ng-container>

   <ng-container matColumnDef="Number">
     <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Number
     </th> 
     <td mat-cell *matCellDef="let element">{{ element.Number }}</td>
   </ng-container>

   <ng-container matColumnDef="Pct">
       <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Pct
       </th> 
       <td mat-cell *matCellDef="let element">{{ element.Pct }}</td>
     </ng-container>

     <ng-container matColumnDef="Quantity">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Quantity
        </th> 
        <td mat-cell *matCellDef="let element">{{ element.Quantity }}</td>
      </ng-container>

      <ng-container matColumnDef="Pct1">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Pct1       </th> 
        <td mat-cell *matCellDef="let element">{{ element.Pct1 }}</td>
      </ng-container>

   <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
   <tr mat-row *matRowDef="let row; columns: displayedColumns1" ></tr>

   
 </table>

