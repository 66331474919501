import { AddDoorUsageComponent } from './add-door-usage/add-door-usage.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-door-usage',
  templateUrl: './door-usage.component.html',
  styleUrls: ['./door-usage.component.css'],
})
export class DoorUsageComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = ['Build', 'Door', 'Account', 'Actions'];
  dataSource: any = [];

  duplicateSource: any = [
    {
      Build: 901,
      Door: 13,
      Account: 1301,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,

    private dialogRef: MatDialogRef<DoorUsageComponent>
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      BuildingNumber: 1,
      DoorNumber: '',
      Account: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  addDoorUsage() {
    this.matDialog.open(AddDoorUsageComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }
  delete() {
    alert('deleted');
  }
}
