<mat-card class="over-Flow">

    <mat-card-header>
      <mat-card-title class="title">Inbound 1Step Receiving</mat-card-title>
    </mat-card-header>
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" novalidate class="over-Flow">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Tally Number</mat-label>
              <input
                matInput
                placeholder="Tally Number"
                formControlName="tallyNumber"
                required
              />
              <mat-error *ngIf="form.controls['tallyNumber'].hasError('required')">
                Tally is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <div style="color: red" *ngIf="error">
              <br />
              <p>*{{ error }}</p>
            </div>

            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Item</mat-label>
              <input matInput placeholder="Item" formControlName="item" />
              <mat-error *ngIf="form.controls['item'].hasError('required')">
                Item is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Quantity</mat-label>
              <input matInput placeholder="Quantity" formControlName="Quantity" />
              <mat-error *ngIf="form.controls['Quantity'].hasError('required')">
                Quantity is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Lot Unit Weight</mat-label>
              <input
                matInput
                placeholder="LotUnitWeight"
                formControlName="LotUnitWeight"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Type </mat-label>
              <mat-select formControlName="InventoryType">
                <mat-option value="A">A - Available </mat-option>
                <mat-option value="D">D - Damaged </mat-option>
                <mat-option value="H">H - Held </mat-option>
              </mat-select>
            </mat-form-field>
        
        <div *ngIf="count > 0">
          
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Lot1</mat-label>
                <input
                  matInput
                  placeholder="Lot1"
                  formControlName="lot1"
                  required
                />
                <mat-error *ngIf="form.controls['lot1'].hasError('required')">
                  Lot1 is <strong>required</strong>
                </mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Lot2</mat-label>
                <input matInput placeholder="Lot2" formControlName="lot2" />
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Lot3</mat-label>
                <input matInput placeholder="Lot3" formControlName="lot3" />
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Location</mat-label>
                <input
                  matInput
                  placeholder="Location"
                  formControlName="location"
                  required
                />
                <mat-error
                  *ngIf="form.controls['location'].hasError('required')"
                >
                  Location is <strong>required</strong>
                </mat-error>
              </mat-form-field>
           
          </div>
        </div>
        <mat-card-actions>
          <button class="buttons addButton new-tally-form-btn"  type="submit"
            *ngIf="count == 0"
            mat-raised-button
            color="primary"
            type="submit"
            (click)="getCount2()"
            [disabled]="!form.valid"
          >
            Next
          </button>
          <button class="buttons addButton new-tally-form-btn"  type="submit"
            mat-raised-button
            *ngIf="count == 1"
            mat-button
            color="primary"
            type="submit"
            (click)="onSubmit()"
            [disabled]="!form.valid"
          >
            Submit
          </button>
        </mat-card-actions>
      </div>
    </form>
    </mat-card-content>
  </mat-card>

<!--
  </mat-grid-tile>
</mat-grid-list>
-->
