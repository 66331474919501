<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title 
                    >Maintain Contact Types</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Contact Type: </mat-label> 
                          <input matInput formControlName="contactType" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Contact Type Name: </mat-label> 
                          <input matInput formControlName="contactTypeName" />
                        </mat-form-field>
            </div>
            
      
     
            <div >
             <button mat-button class="buttonCss" (click)="attributes()">ADD</button>
            </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  
  
