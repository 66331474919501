import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-freight-code',
  templateUrl: './add-freight-code.component.html',
  styleUrls: ['./add-freight-code.component.css'],
})
export class AddFreightCodeComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<AddFreightCodeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      FreightCode: [null],
      Description: [null],
      Hazardous: [null],
      PlacardType: [null],
      NMFCNumber: [null],
      SubCode: [null],
      UNCode: '',
      NACode: '',
      LTLFreightClass: '',
      TruckLoad: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  add() {
    console.log('added');
  }
}
