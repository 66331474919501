<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title fxLayoutAlign="center">Delete/Consolidate A Consignee</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form" fxLayoutAlign="center">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Consignee Code</mat-label>
                    <input matInput formControlName="ConsigneeCode" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Name</mat-label>
                    <input matInput formControlName="Name" />
                </mat-form-field>
        
                <mat-form-field appearance="outline">
                    <mat-label>Address</mat-label>
                    <input matInput formControlName="Address"  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>ZipCode</mat-label>
                    <input matInput formControlName="ZipCode"  />
                </mat-form-field>
            
            <!-- <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Consolidate To</mat-label>
                    <input matInput formControlName="ConsolidateTo"  />
                </mat-form-field>
            </div> -->
                <!-- <button mat-button (click)="deleteConsignee()" class="buttons removeButton">           
                    <mat-icon mat-icon-button color="white">delete</mat-icon>
                    <mat-label>Consignee</mat-label>
                </button> -->
                <div id="custom-alert">
                    <div class="custom-alert-content">
                      <button mat-button class="buttons removeButton" 
                      (click)="deleteConsignee()">Delete</button>
              
                    </div>
                  </div>
              </div>
            </div>
            
        </form>
    </mat-card-content>

    <!-- <mat-card-footer>
    </mat-card-footer> -->
</mat-card>