import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-freight-code',
  templateUrl: './edit-freight-code.component.html',
  styleUrls: ['./edit-freight-code.component.css'],
})
export class EditFreightCodeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditFreightCodeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      FreightCode: [null],
      Description: [null],
      Hazardous: [null],
      PlacardType: [null],
      NMFCNumber: [null],
      SubCode: [null],
      UNCode: '',
      NACode: '',
      LTLFreightClass: '',
      TruckLoad: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }
}
