import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { IheaderService } from '../../tallys/iheader.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { StorerCreate2Component } from '../storer-create2/storer-create2.component';
import { StorerServiceService } from '../storer-service.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-storer-create',
  templateUrl: './storer-create.component.html',
  styleUrls: ['./storer-create.component.css']
})
export class StorerCreateComponent implements OnInit {

  constructor(
    
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    private matDialog : MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerCreateComponent>
    ) { }

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');


  ngOnInit(): void {

      this.form = this.fb.group({        
        
        storeNumber: [
          '',
          [
            Validators.required,
            Validators.min(0),
            Validators.pattern('[0-9]{1,4}'),
          ],
        ],
        suffix:[''],
        storeName:['', [
          Validators.required,
        ]],
        address1:[''],
        address2:[''],
        address3:[''],
        zipCode:[
          '',
          [
            Validators.required,
            Validators.pattern('[0-9]{5}'),
            
          ],
        ],
        zipSuffix:[''],
        areaCode:['', [
          // Validators.min(3),
          // Validators.max(3),
          Validators.pattern('[0-9]{3}'),
        ]],
        phoneExtension:['', [
          Validators.pattern('[0-9]{3}'),
        ],],
        phoneNumber:[
          '',
          [
            Validators.pattern('[0-9]{4}'),
          ],
        ],
        primaryRep:[''],
        creditLimit:[
          '',
          [
           
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ],
        creditHold:[
          'N',
          [
            Validators.required
            
          ],],
        building:[''],
        trackQBL:[''], //Keep Quality By Location
        standardClose:[
          'Y',
          [
            Validators.required
            
          ],],
        midMnthCutOff:[''],
        midMnthCutOffDt:[''],
        forcePSBL:[''],
        comboPSBL:[
          'N',
          [
            Validators.required
            
          ],],
        cutsAllowed:[
          'Y',
          [
            Validators.required
            
          ],],
        blindTally:[''],
        chepPallets:[
          'N',
          [
            Validators.required
            
          ],],
        preventConsolidation:[
          'N',
          [
            Validators.required
            
          ],]

      });
  }
  move(e:any,p:any,c:any,n:any){
    var length=c.value.length;
    var maxlength=c.getAttribute('maxlength');
    if(length == maxlength){
      if(n !=""){
        n.focus();
      }
    }
    if(e.key === "Backspace"){
      if(length==""){
        p.focus();
      
      }
      
        
      
    }
  }

  storerCreate1(){
    var res = this.form.value;
    
   
    this.result = this.storerSvc
      .storerServiceCreate1( 
       res.storeNumber,
       res.suffix,
       res.storeName,
       res.address1,
       res.address2,
       res.address3,
       res.zipCode,
       res.zipSuffix,
       res.areaCode,
       res.phoneExtension,
       res.phoneNumber,
       res.primaryRep,
       res.creditLimit,
       res.creditHold,
       res.building,
       res.trackQBL,
       res.standardClose,
       res.midMnthCutOff,
       res.midMnthCutOffDt,
       res.forcePSBL,
       res.comboPSBL,
       res.cutsAllowed,
       res.blindTally,
       res.chepPallets,
       res.preventConsolidation,
       this.user

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       console.log(this.result[0].data.length);
          console.log("*********************");

       console.log(this.result[0].data[this.result[0].data.length-1].value);
       console.log("*********************");

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Created'){
          //alert('Record Created SuccessFully');
          
         const msg= this.result[0].data[this.result[0].data.length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.create2();
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
       // const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    //this.router.navigate(['/main-nav/storer/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/storer/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  

  create2() {
    var res = this.form.value;
    this.matDialog.open(StorerCreate2Component, {
      
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

}
