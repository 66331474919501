import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-forecast-create',
  templateUrl: './forecast-create.component.html',
  styleUrls: ['./forecast-create.component.css'],
})
export class ForecastCreateComponent implements OnInit {
  forecastForm = this.fb.group({
    IFSTOR: [],
    IFCONS: [],
    FDAT: [],
    IFFTYP: [],
    IFDTYP: [],
    BHOR: [],
    EHOR: [],
    IFDLOC: [],
    IFQTYP: [],
    IFUOM: [],
    IFSPLR: [],
    IFSREF: [],
    IFRELS: [],
    IFSTAT: [],
  });

  constructor(
    private dialogRef: MatDialogRef<ForecastCreateComponent>,
    private fb: FormBuilder,
    private router: Router
  ) {}

  add() {}
  closeDialog() {
    this.dialogRef.close();
  }
  ngOnInit(): void {}
}
