import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StorerServiceService } from '../storer-service.service';
import { StorerUpdate6Component } from '../storer-update6/storer-update6.component';

@Component({
  selector: 'app-storer-update5',
  templateUrl: './storer-update5.component.html',
  styleUrls: ['./storer-update5.component.css']
})
export class StorerUpdate5Component implements OnInit {
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerUpdate5Component>,
   private matDialog: MatDialog
    ) { }

    ngOnInit(): void {

      console.log(this.anyvariable.storerNumber);
         this.form = this.fb.group({
          
          storeNumber: this.anyvariable.STORE,
          suffix: this.anyvariable.STSFX,
          storeName: this.anyvariable.STNAME,
          blPrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],//STBLPR     QPRINT
          picksheetPrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],  //STPSPR
          controlcopyPrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],//STBLCP    
          wrPrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],  //STWRPR
          storageInvoicePrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],//STSIPR     
          generalInvoicePrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],  //STGIPR
          cartEdiReportPrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],//STCIPR     
          accessInvoicePrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],  //STAIPR
          freightBillPrinter:['QPRINT',[
            Validators.required,
            Validators.maxLength(10),
            
          ],],//STFBPR   
         });
    }
  
  
    storerUpdate5(){
      var res = this.form.value;
         
      this.result = this.storerSvc
        .storerServiceUpdate5( 
          res.storeNumber,
          res.suffix,
          res.storeName,
          res.blPrinter,
          res.picksheetPrinter,
          res.controlcopyPrinter,
          res.wrPrinter,
          res.storageInvoicePrinter,
          res.generalInvoicePrinter,
          res.cartEdiReportPrinter,
          res.accessInvoicePrinter,
          res.freightBillPrinter,
          this.user
  
        ).subscribe((data: any) => {
          this.result = data;
          if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
            const msg= this.result[0].data[this.result[0].data.length-1].value;
            this.successMessage(msg)
            this.closeDialog();
            this.update6();
         }else{
          this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
         }
        });
    }
  
  
    successMessage(msg:any){
      Swal.fire(msg,'','success');
      //this.router.navigate(['/main-nav/storer/read']);
    }
  
    failureMessage(msg:any){
      Swal.fire(msg,'','error');
     // this.router.navigate(['/main-nav/storer/read']);
    }
  
    update6() {
      var res = this.anyvariable;
      this.matDialog.open(StorerUpdate6Component, {        
        width: '1000px',
        maxHeight: '80vh',  
        data: res,
        autoFocus: false,
      });
    }

  closeDialog(){
    this.dialogRef.close();
  }

}
