<mat-card class="over-Flow">

    <div class="main-header-title">  
        <mat-card-header>
            <mat-card-title >Delete Tally Detail</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
        
    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">       
              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Tally</mat-label>
                <input matInput required formControlName="tally" readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label>Line Number </mat-label>
                <input matInput formControlName="linenumber" readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Storer  </mat-label>
                <input matInput  formControlName="storer" readonly/>
              </mat-form-field>
                
              <mat-form-field appearance="outline">
                <mat-label>Item </mat-label>
                <input matInput formControlName="item" readonly/>
              </mat-form-field>
                
              <mat-form-field appearance="outline">
                <mat-label>Lots</mat-label>
                <input matInput formControlName="lots" readonly />
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label>Received</mat-label>
                <input matInput formControlName="received" readonly />
              </mat-form-field>

                    
              <mat-form-field appearance="outline">
                <mat-label>Intransit</mat-label>
                <input matInput  formControlName="intransit" readonly />
              </mat-form-field>

            </div>
          </div>
          <div id="custom-alert">
            <div class="custom-alert-content">
              <button mat-button class="buttons removeButton" 
              (click)="deletetallyDetail()">Delete</button>

            </div>
          </div>
          <!-- <button mat-button class="buttons removeButton" 
            (click)="deletetallyDetail()">Delete</button> -->

        </form>      
    </mat-card-content>
</mat-card>
      
    

