import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-lot-status-change',
  templateUrl: './ww-lot-status-change.component.html',
  styleUrls: ['./ww-lot-status-change.component.css']
})
export class WwLotStatusChangeComponent implements OnInit {
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwLotStatusChangeComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      StorerNumber:'1301',
      ItemNumber:'',
      LotCode:'22',
      LotWeight:'111',
      Expiration:'',
      DateHeld:'',
      DateReleased:'12-5-2023',
      Status:''
  
    });
  }
  changeBox(){
    
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
