<mat-card class="over-Flow">
<div class="main-header-title">
  <mat-card-header>
    <mat-card-title>Work With an Items Lots</mat-card-title>
  </mat-card-header>
  <button mat-icon-button (click)="closeDialog()">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
</div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label>Storer</mat-label>
            <input  readonly matInput formControlName="storer" />
          </mat-form-field>
        
          <mat-form-field appearance="outline"
            ><mat-label>Item</mat-label>
            <input  readonly matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Lot 1</mat-label>
            <input matInput formControlName="lot1" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Lot 2</mat-label>
            <input matInput formControlName="lot2" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Lot 3</mat-label>
            <input matInput formControlName="lot3" />
          </mat-form-field>

          

        </div>

      </div>
      <div>
      <button mat-button (click)="readItemLot()" class="addButton buttons">
        <span>Search</span>
      </button>
    </div>
    </form>
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="Sts">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.LTSTAT }}</td>
    </ng-container>

    <ng-container matColumnDef="LotCodes">
      <th mat-header-cell *matHeaderCellDef>Lot Codes 1 | 2 | 3</th>
      <td mat-cell *matCellDef="let element">{{ element.LTLOT1 }} | {{ element.LTLOT2 }} | {{ element.LTLOT3 }}</td>
    </ng-container>

    <ng-container matColumnDef="LotUnitWeight">
      <th mat-header-cell *matHeaderCellDef>Lot Unit Weight</th>
      <td mat-cell *matCellDef="let element">{{ element.LTLUWT }}</td>
    </ng-container>

    <ng-container matColumnDef="FirstReceived">
      <th mat-header-cell *matHeaderCellDef>First Received</th>
      <td mat-cell *matCellDef="let element">{{ element.LTRCVD | date : this.GlobalComponent.DATE_FORMAT}}</td>
    </ng-container>

    <ng-container matColumnDef="LastShipped">
      <th mat-header-cell *matHeaderCellDef>Last Shipped</th>
      <td mat-cell *matCellDef="let element">{{ element.LTSHPL | date : this.GlobalComponent.DATE_FORMAT}}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef>
      <!-- Actions-->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="updateItemLots(element)">
                <mat-icon mat-icon-button  color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="deleteItemLot(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
            
          </ul>
        </div>
      </td>
    </ng-container>

    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 12]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>

</mat-card>
