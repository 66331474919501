import { Building } from './../../../buildings/buildings.component';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { IItemBalanceService } from '../i-item-balance.service';

@Component({
  selector: 'app-add-balance',
  templateUrl: './add-balance.component.html',
  styleUrls: ['./add-balance.component.css'],
})
export class AddBalanceComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private iItemBalanSvc: IItemBalanceService,

    private dialogRef: MatDialogRef<AddBalanceComponent>
  ) {}
  element: any;

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      StorerNumber: this.anyvariable.ITSTOR,
      suffix: this.anyvariable.ITSSFX,
      Item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
      BalanceDate: ['', Validators.required],
      LotCode1: [''],
      LotCode2: '',
      LotCode3: '',
      Building: this.anyvariable.ITBLDG,
      RecordType: ['', Validators.required],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  required: boolean = false;
  lotValue = this.anyvariable.ITLOTT;

  addItemBalance() {
    var res = this.form.value;

    res.BalanceDate = res.BalanceDate.toLocaleDateString('fr-CA');

    this.result = this.iItemBalanSvc
      .createItemBalance(
        res.StorerNumber,
        res.suffix,
        this.anyvariable.ITEM,
        res.LotCode1,
        res.LotCode2,
        res.LotCode3,
        res.Building,
        res.BalanceDate,
        res.RecordType
      )

      .subscribe((data) => {
        this.result = data;
        console.log(this.result[0].data[11].value);

        if (this.result[0].data[11].value != '') {
          const msg = this.result[0].data[11].value;
          console.log(msg);

          this.successMessage(msg);

          location.reload();

          //this.router.navigate(['/main-nav/item/read']);
        } else {
          const msg = this.result[0].data[11].value;
          this.failureMessage(msg);
        }
      });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');

    // this.router.navigate(['/main-nav/item/balance/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    this.router.navigate(['/main-nav/item/read']);
  }
}
