import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MoveQueueService {

  url = environment.API_BASE_URL;

  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  readAllMoveService(user: any, storer:any): Observable<any> {
    return this.httpClient.get(this.url + `/movequeue/read?user=${user}&storer=${storer}`);
  }
  
  
  deleteMapZone(user: any,building:any,zone:any): Observable<any> {
    return this.httpClient.delete(this.url + `/mapzone/delete?user=${user}&building=${building}&zone=${zone}`);
  }


  readMoveInquiry(user: any, transNum:any): Observable<any> {
    return this.httpClient.get(this.url + `/movequeue/moveInquiry?user=${user}&transNum=${transNum}`);
  }


  updateMoveAssignment(
    user:any,
    transNum:any,
    operatorId:any,
    priority:any

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/moveAssignment', { 

        user,
        transNum,
        operatorId,
        priority
      
       })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateMoveStagingLoc(
    user:any,
    transNum:any,
    location:any,
    changeThisMove:any,
    changeAllMoves:any

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/update/moveStageLocation', { 

        user,
        transNum,
        location,
        changeThisMove,
        changeAllMoves
      
       })
      .pipe(retry(1), catchError(this.httpError));
  }


  readOneMoveService(user: any, priority:any, item:any, type:any, document:any, building:any, storer:any, suffix:any, operator:any, status:any): Observable<any> {
    return this.httpClient.get(this.url + `/movequeue/readOne?user=${user}&priority=${priority}&item=${item}&type=${type}&document=${document}&building=${building}&storer=${storer}&suffix=${suffix}&operator=${operator}&status=${status}`
    );
  }

  
  updateMovePriorityService(
    user:any,
    transNum:any, 
    updatePriorityBy:any,
    changeThisMove:any, 
    changeAllForDoc:any,
    changeAllForItem:any,
    changeAllForFromLoc:any,
    changeAllForToLoc:any

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/update/movePriority', { 

        user,
        transNum, 
		  updatePriorityBy,
		  changeThisMove, 
		  changeAllForDoc,
		  changeAllForItem,
		  changeAllForFromLoc,
		  changeAllForToLoc
      
       })
      .pipe(retry(1), catchError(this.httpError));
  }


  resolveMoveProblemService(
    user:any,
    transNum:any, 
    value:any,
    resolutionText:any

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/update/resolve', { 

        user,
        transNum, 
        value,
        resolutionText
      
       })
      .pipe(retry(1), catchError(this.httpError));
  }


  noPickService(
    
    transNum:any,    

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/nopick', { 

        transNum
      
       })
      .pipe(retry(1), catchError(this.httpError));
  }


  updateMoveCompleteService(
    
    transNum:any,

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/moveCompletion', { 

        transNum
        
       })
      .pipe(retry(1), catchError(this.httpError));
  }


  redirect1Service(
    
    transNumber:any,
    location:any,
    lot1:any,
    lot2:any,
    lot3:any

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/redirectForm1', { 

        transNumber,
        location,
        lot1,
        lot2,
        lot3

        
       })
      .pipe(retry(1), catchError(this.httpError));
  }


  redirect2Service(
    
    transcationNumber:any,
    location:any,
    lotCode1:any,
    lotCode2:any,
    lotCode3:any

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/redirectForm2', { 

        transcationNumber,
        location,
        lotCode1,
        lotCode2,
        lotCode3
        
       })
      .pipe(retry(1), catchError(this.httpError));
  }


  deAssignmentService(
    
    transNum:any  

    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/movequeue/MoveDeAssignment', { 

        transNum
        
       })
      .pipe(retry(1), catchError(this.httpError));
  }



  deleteMoveService(transNumber:any): Observable<any> {
    return this.httpClient.delete(this.url + `/movequeue/delete?transNumber=${transNumber}`
    );
  }




  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
