import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IMapLocationService } from '../i-map-location.service';
import { GlobalComponent } from 'src/app/Global-Component';

@Component({
  selector: 'app-map-location-update',
  templateUrl: './map-location-update.component.html',
  styleUrls: ['./map-location-update.component.css'],
})
export class MapLocationUpdateComponent implements OnInit {
  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  constructor(
    public mapLocationSrv: IMapLocationService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<MapLocationUpdateComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({
      BuildingNumber: this.anyvariable.MABLDG,
      Floor: this.anyvariable.MAFLOR,
      Aisle: this.anyvariable.MAAISL,
      Slot: this.anyvariable.MASLOT,
      PalletsHigh: this.anyvariable.MAHEIG,
      Deep: this.anyvariable.MADEPT,
      SlotHeight: this.anyvariable.MASLTH,
      Width: this.anyvariable.MASLTW,
      Depth: this.anyvariable.MASLTD,
      PickingSequence: this.anyvariable.MARTG,
      WarehouseZone: this.anyvariable.MAABC,
      ReservedSlot: this.anyvariable.MARESV,
      ReservedStorerNumber: [
        this.anyvariable.MASTOR,
        [Validators.min(0), Validators.pattern('[0-9]{1,10}')],
      ],
      ReservedStorerSuffix: this.anyvariable.MASTRX,
      ReservedItemcode: [
        this.anyvariable.MAITEM,
        [Validators.min(0), Validators.pattern('[0-9]{1,10}')],
      ],
    });
  }

  updateMapLocation() {
    var res = this.form.value;
    console.log(res);

    this.result = this.mapLocationSrv
      .updateMapLocation(
        GlobalComponent.fullBuilding(res.BuildingNumber), // res.Floor,
        (res.Floor = res.Floor == 0 ? (res.Floor = '') : res.Floor),
        GlobalComponent.fullSlotAisl(res.Aisle),

        GlobalComponent.fullSlotAisl(res.Slot),

        //res.PalletsHigh,
        (res.PalletsHigh =
          res.PalletsHigh == 0 ? (res.PalletsHigh = '') : res.PalletsHigh),
        //res.Deep,

        (res.Deep = res.Deep == 0 ? (res.Deep = '') : res.Deep),
        // res.SlotHeight,
        (res.SlotHeight =
          res.SlotHeight == 0 ? (res.SlotHeight = '') : res.SlotHeight),
        //  res.Width,
        (res.Width = res.Width == 0 ? (res.Width = '') : res.Width),
        // res.Depth,
        (res.Depth = res.Depth == 0 ? (res.Depth = '') : res.Depth),
        // res.PickingSequence,
        (res.PickingSequence =
          res.PickingSequence == 0
            ? (res.PickingSequence = '')
            : res.PickingSequence),
        res.WarehouseZone,

        res.ReservedSlot,

        // res.ReservedStorerNumber,
        (res.ReservedStorerNumber =
          res.ReservedStorerNumber == 0
            ? (res.ReservedStorerNumber = '')
            : res.ReservedStorerNumber),
        res.ReservedStorerSuffix,
        res.ReservedItemcode,
        this.user
      )
      .subscribe((data: any) => {
        this.result = data;
        if (JSON.parse(this.result[0].data[1].value).success) {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/map-location/read']);
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.failureMessage(msg);
        }
      });
  }
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/map-location/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backstorer() {}
  onBack() {
    this.router.navigate(['/main-nav/map-location/read']);
  }
}
