import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IitemService } from 'src/app/features/item/iitem.service';
import Swal from 'sweetalert2';
import { OrderDetailsService } from '../order-details.service';

@Component({
  selector: 'app-order-details-create',
  templateUrl: './order-details-create.component.html',
  styleUrls: ['./order-details-create.component.css']
})
export class OrderDetailsCreateComponent implements OnInit {

  constructor(
    public orderDetailSrv: OrderDetailsService, 
    public itemService:IitemService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router : Router,
   private dialogRef: MatDialogRef<OrderDetailsCreateComponent>
   ) { }

   storer: any;
   form!: FormGroup;
   result: any;
   user = localStorage.getItem('userName');
   duplicateSource:any;
   itemOptions: string[] = [];



  ngOnInit(): void {

    console.log("any******",this.anyvariable);
    
    this.form = this.fb.group({
      bol:localStorage.getItem('billOfLading'),
      seq:this.anyvariable.OHMSEQ,
      quantity: '',
      items: '',
      lot1:'',
      lot2:'',
      lot3:'',
      shipFrom:'Available',
      ovrWeight: '',
      changeReason:'',
      origLine:'',  
       
    });
  

    this.itemService.getItemBySearch(this.user,
      localStorage.getItem('orderStorer'),
      '',
      ''
    ).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('SINGLE ITEM DATA');
      console.log(this.duplicateSource);

      for (const item of parsedData.returnedData) {
        this.itemOptions.push(item.ITEM);
      }  
      console.log(this.anyvariable.OHSTOR);
      
      console.log("create itemoptions",this.itemOptions);
      
    });
  }

  createOrderDetail() {
    var res = this.form.value;    
    this.result = this.orderDetailSrv.createOrderDetailService (
      res.bol,
      res.seq,
      res.quantity,
      res.items,
      res.lot1,
      res.lot2,
      res.lot3,
      res.shipFrom,
      res.ovrWeight,
      res.changeReason,
      res.origLine
    ).subscribe((data: any) => {
      this.result = data;
      var length = this.result[0].data.length;
      console.log(this.result);
      
      if(this.result[0].data[length-1].value=='' && this.result[0].data[length-2].value=='Successfully Created'){
        const msg= this.result[0].data[length-2].value;
        this.successMessage(msg);
        this.closeDialog();
      this.router.navigate(['/main-nav/orders/details']);
      }else{
        const msg=this.result[0].data[length-2].value;
        this.failureMessage(msg);
      }
    });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
   this.dialogRef.close();
  }

  backstorer(){
    
  }
  maintain(){
    
  }

}
