import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import Swal from 'sweetalert2';
import { EquipmentoperatorService } from '../../equipmentoperator.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-equipment-operators',
  templateUrl: './add-equipment-operators.component.html',
  styleUrls: ['./add-equipment-operators.component.css'],
})
export class AddEquipmentOperatorsComponent implements OnInit {
  form!: FormGroup;

  constructor(
    public eopSvc: EquipmentoperatorService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<AddEquipmentOperatorsComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      Operator: '',
      Building: '',
      Storer: '',
      Suffix: '',
      Shift: '',
      Status: '',
      From1: '',
      To1: '',
      From2: '',
      To2: '',
      From3: '',
      To3: '',
      From4: '',
      To4: '',
    });
  }

  user = localStorage.getItem('userName');
  result: any;

  addEquipmentOperator() {
    var res = this.form.value;
    console.log(res);

    this.result = this.eopSvc
      .createEquipmentOperator(
        res.Operator,
        res.Storer,
        res.Suffix,
        res.Building,
        res.Status,
        res.Shift,
        this.user
      )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
        // var msg = JSON.parse(this.result).returnedData.message;

        // this.successMessage(msg);
        if (JSON.parse(this.result[0].data[1].value).success) {
          //alert('Record Created SuccessFully');
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/equipmentOperators/read']);
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.failureMessage(msg);
        }
      });
  }
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/equipmentOperators/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
