<!-- <mat-card class="over-Flow">


  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Move Queue Entry Inquiry</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"><mat-label> Item : </mat-label>
            <input readonly matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> On Queue: </mat-label>
            <input readonly matInput formControlName="onQueue" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Lots: </mat-label>
            <input readonly matInput formControlName="lots" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Assigned: </mat-label>
            <input readonly matInput formControlName="assigned" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> From: </mat-label>

            <input readonly matInput formControlName="from" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Completed: </mat-label>
            <input readonly matInput formControlName="completed" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> To: </mat-label>

            <input readonly matInput formControlName="to" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> By: </mat-label>
            <input readonly matInput formControlName="by" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Quantity: </mat-label>

            <input readonly matInput formControlName="quantity" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Priority: </mat-label>
            <input readonly matInput formControlName="priority" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Source: </mat-label>

            <input readonly matInput formControlName="source" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Status: </mat-label>
            <input readonly matInput formControlName="status" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> MoveProblem: </mat-label>
            <input matInput formControlName="MoveProblem" />
          </mat-form-field>

          

          <mat-form-field appearance="outline">
            <mat-label> Description: </mat-label>
            <input matInput formControlName="Description" />
          </mat-form-field>


          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Reported Date: </mat-label>
            <input matInput placeholder="Reported " formControlName="Reported" [matDatepicker]="Reported" />
            <mat-datepicker-toggle matSuffix [for]="Reported"></mat-datepicker-toggle>
            <mat-datepicker #Reported></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> By: </mat-label>
            <input matInput formControlName="By" />
          </mat-form-field>



          <mat-form-field appearance="outline">
            <mat-label> Resolution: </mat-label>
            <input matInput formControlName="Resolution" />
          </mat-form-field>


          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Resolved Date: </mat-label>
            <input matInput placeholder="Resolved " formControlName="Resolved" [matDatepicker]="Resolved" />
            <mat-datepicker-toggle matSuffix [for]="Resolved"></mat-datepicker-toggle>
            <mat-datepicker #Resolved></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> By: </mat-label>
            <input matInput formControlName="By" />
          </mat-form-field>



        </div>
      </div>
      <button mat-button class="buttons addButton" (click)="Inquiry()">
        Inquiry
      </button>

    </form>
  </mat-card-content>


</mat-card> -->


<!-- 

<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Move Queue Entry Inquiry</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"><mat-label> Item : </mat-label>
            <input readonly matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> On Queue: </mat-label>
            <input readonly matInput formControlName="onQueue" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Lots: </mat-label>
            <input readonly matInput formControlName="lots" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Assigned: </mat-label>
            <input readonly matInput formControlName="assigned" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> From: </mat-label>

            <input readonly matInput formControlName="from" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Completed: </mat-label>
            <input readonly matInput formControlName="completed" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> To: </mat-label>

            <input readonly matInput formControlName="to" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> By: </mat-label>
            <input readonly matInput formControlName="by" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Quantity: </mat-label>

            <input readonly matInput formControlName="quantity" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Priority: </mat-label>
            <input readonly matInput formControlName="priority" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Source: </mat-label>

            <input readonly matInput formControlName="source" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Status: </mat-label>
            <input readonly matInput formControlName="status" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> MoveProblem: </mat-label>
            <input matInput formControlName="MoveProblem" />
          </mat-form-field>

          

          <mat-form-field appearance="outline">
            <mat-label> Description: </mat-label>
            <input matInput formControlName="Description" />
          </mat-form-field>


          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Reported Date: </mat-label>
            <input matInput placeholder="Reported " formControlName="Reported" [matDatepicker]="Reported" />
            <mat-datepicker-toggle matSuffix [for]="Reported"></mat-datepicker-toggle>
            <mat-datepicker #Reported></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> By: </mat-label>
            <input matInput formControlName="By" />
          </mat-form-field>



          <mat-form-field appearance="outline">
            <mat-label> Resolution: </mat-label>
            <input matInput formControlName="Resolution" />
          </mat-form-field>


          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Resolved Date: </mat-label>
            <input matInput placeholder="Resolved " formControlName="Resolved" [matDatepicker]="Resolved" />
            <mat-datepicker-toggle matSuffix [for]="Resolved"></mat-datepicker-toggle>
            <mat-datepicker #Resolved></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> By: </mat-label>
            <input matInput formControlName="By" />
          </mat-form-field>



        </div>
      </div>
      <button mat-button class="buttons addButton" (click)="Inquiry()">
        Inquiry
      </button>
    </form>
  </mat-card-content>
</mat-card> -->

<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Move Queue Entry Inquiry</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row ">
        <div class="col">
          <mat-form-field appearance="outline"><mat-label> Item : </mat-label>
            <input readonly matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> On Queue: </mat-label>
            <input readonly matInput formControlName="onQueue" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Lots: </mat-label>
            <input readonly matInput formControlName="lots" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Assigned: </mat-label>
            <input readonly matInput formControlName="assigned" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> From: </mat-label>
            <input readonly matInput formControlName="from" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Completed: </mat-label>
            <input readonly matInput formControlName="completed" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> To: </mat-label>
            <input readonly matInput formControlName="to" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> By: </mat-label>
            <input readonly matInput formControlName="by" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Quantity: </mat-label>
            <input readonly matInput formControlName="quantity" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Priority: </mat-label>
            <input readonly matInput formControlName="priority" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Source: </mat-label>
            <input readonly matInput formControlName="source" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Status: </mat-label>
            <input readonly matInput formControlName="status" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> MoveProblem: </mat-label>
            <input matInput formControlName="MoveProblem" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Description: </mat-label>
            <input matInput formControlName="Description" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Reported Date: </mat-label>
            <input matInput placeholder="Reported " formControlName="Reported" [matDatepicker]="Reported" />
            <mat-datepicker-toggle matSuffix [for]="Reported"></mat-datepicker-toggle>
            <mat-datepicker #Reported></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> By: </mat-label>
            <input matInput formControlName="By" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Resolution: </mat-label>
            <input matInput formControlName="Resolution" />
          </mat-form-field>
          
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Resolved Date: </mat-label>
            <input matInput placeholder="Resolved " formControlName="Resolved" [matDatepicker]="Resolved" />
            <mat-datepicker-toggle matSuffix [for]="Resolved"></mat-datepicker-toggle>
            <mat-datepicker #Resolved></mat-datepicker>
          </mat-form-field>

        </div>
      </div>
          
    </form>        
  </mat-card-content>
</mat-card>