import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-create2',
  templateUrl: './order-create2.component.html',
  styleUrls: ['./order-create2.component.css']
})
export class OrderCreate2Component implements OnInit {

  constructor(
    public iOrderService: OrderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
   private dialogRef: MatDialogRef<OrderCreate2Component>
   ) 
   { }
   
  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  

  ngOnInit(): void {
    this.form = this.fb.group({
    schedShipDate:'',
    schedArrivalDate:'',
    shipType:'',
    carrierCode:'',
    freightCharge:'',
    codAmount:'',
    applyAutoCharge:'',
    billToCustomer:'',
    billToSuffix:'',
    brokerName:'',
    totalQuantity:'',
    shipOrderFrom:'',
    overrideQty:'',
    building:'',
    zone:'',
    commitInventory:'',
    ctrl:'',
    ctrlImmed:'',
    pick:'',
    pickImmed:'',
    bol:'',
    bolImmed:'',
       
    });
  }

  orderCreate2(){
    var res = this.form.value;   
    this.result = this.iOrderService
      .orderServiceCreate2( 
        
       res.billOfLading,
       res.storerNumber,
       res.suffix,
       res.schShippedDt,
       res.schArrivalDt,
       res.shipType, 
       res.carrierCode,
       res.freightCode,
       res.codAmount,
       res.spCharges,
       res.billToStorer,
       res.billToSuffix,
       res.brokerName,
       res.totalQuantity,
       res.shipFromBucket,
       res.overrideQty,
       res.building,
       res.ctrlCopyPrinterId,
       res.ctrlCopyImmedFlag,
       res.zoneCode,
       res.commitFlag,
       res.pickSheetPrinterId,
       res.pickSheetImmedFlag,
       res.bolPrinter,
       res.bolImmedFlag
        

        ).subscribe((data: any) => {
        this.result = data;
        var length = this.result[0].data.length;
        if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Successfully Updated'){
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/item/read']);
  }
  closeDialog(){
    this.dialogRef.close();
  }


  backstorer(){
    
  }

}