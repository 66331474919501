<mat-card class="over-Flow">

    <div class="main-header-title">    
        <mat-card-header>  
          <mat-card-title>Move Locations</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
        
      <mat-card-content class="tally-serach-form"> 
        <form [formGroup]="form">
          <div class="row">
            <div class="col">

                <mat-form-field  appearance="outline"><mat-label> Transaction No: </mat-label> 
                    <input readonly matInput formControlName="transNumber" />
                  </mat-form-field>

              <mat-form-field  appearance="outline"><mat-label> Storer : </mat-label> 
                <input readonly matInput formControlName="storer" />
              </mat-form-field>
               
  
              <mat-form-field appearance="outline">  <mat-label> Item: </mat-label> 
              
                <input readonly
                  matInput
                  formControlName="item"
                  style="text-transform: uppercase"
                />
              
              </mat-form-field>
  
              <mat-form-field appearance="outline">  <mat-label> Lots : </mat-label> 
                <input readonly matInput formControlName="lots" />
              </mat-form-field>
               
              <mat-form-field appearance="outline">  <mat-label> From: </mat-label> 
                <input readonly
                  matInput
                  formControlName="fromLocation"
                  style="text-transform: uppercase"
                />            
              </mat-form-field>      
  
              <mat-form-field appearance="outline">  <mat-label> Quantity  : </mat-label> 
                <input readonly matInput formControlName="quantity" />
              </mat-form-field>
               
              <mat-form-field appearance="outline">  <mat-label> NewLocation: </mat-label> 
                <input required
                  matInput
                  formControlName="newLocation"
                  style="text-transform: uppercase"
                />  
              </mat-form-field>
             <hr>
            <label>New Lots: </label>

              <mat-form-field appearance="outline">  <mat-label> Lot 1: </mat-label> 
                <input matInput formControlName="newLot1" />
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Lot 2: </mat-label> 
                <input matInput formControlName="newLot2" />
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Lot 3: </mat-label> 
                <input matInput formControlName="newLot3" />
              </mat-form-field>
            
                
              </div>
            
            </div>
            <button [disabled]="!(form.valid && form.dirty)" mat-button class="buttons addButton" (click)="redirect1()">Update</button>
          
         </form>
      </mat-card-content>
    
  </mat-card>