<mat-card>

  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Tally Inquiry</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>

  <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
            <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Tally Number</mat-label>
                   <input readonly matInput  value="{{this.tally}}"/>
               </mat-form-field>
             
                <mat-form-field appearance="outline">
                    <mat-label>Account Of</mat-label>
                    <input readonly matInput value="{{this.accountOf}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Bill To</mat-label>
                    <input readonly matInput value="{{this.billTo}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Carrier</mat-label>
                    <input readonly matInput value="{{this.carrier}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>PRO Number</mat-label>
                    <input readonly matInput value="{{this.proNumber}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Reference No</mat-label>
                    <input readonly matInput value="{{this.referenceNo}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Car/Trailer</mat-label>
                    <input readonly matInput value="{{this.invoiceNo}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Invoice No</mat-label>
                    <input readonly matInput value="{{this.invoiceNo}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Invoice Date</mat-label>
                    <input readonly matInput value="{{this.invoiceDate}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Received Date</mat-label>
                    <input readonly matInput value="{{this.receivedDate}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Scheduled Date</mat-label>
                    <input readonly matInput value="{{this.scheduledDate}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Building No</mat-label>
                    <input readonly matInput value="{{this.buildingNo}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Load Type</mat-label>
                    <input readonly matInput value="{{this.loadType}}"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Receipt Type</mat-label>
                  <input readonly matInput value="{{this.receiptType}}"/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                  <mat-label>Seal 1/2 Broken</mat-label>
                  <input readonly matInput value="{{this.seal1Or2Broken}}"/>
              </mat-form-field>
              
            </div>
        </div>     
      </form>
  </mat-card-content>

   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">

    <ng-container matColumnDef="Qty">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>
    
    <ng-container matColumnDef="Item">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Item</th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <ng-container matColumnDef="LotCodes">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Lot Codes</th>
      <td mat-cell *matCellDef="let element">
        
      </td>
    </ng-container>

    <ng-container matColumnDef="Weight">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Weight</th>
      <td mat-cell *matCellDef="let element">
        
      </td>
    </ng-container>

    <ng-container matColumnDef="Rate">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Rate</th>
      <td mat-cell *matCellDef="let element">
        
      </td>
    </ng-container>

    <ng-container matColumnDef="Base">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Base</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSM }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Amount">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSM }}
      </td>
    </ng-container>

    <ng-container matColumnDef="HSM">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>HSM</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHTALY }}
      </td>
    </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  </table>

  

  <mat-paginator #paginator [pageSizeOptions]="[4, 7, 10]" showFirstLastButtons
    class="tally-table-pagination"></mat-paginator>


    <p>
    <label style="font-weight:bolder">Total Handling : {{this.totalHandling}}</label>
  
    <label style="font-weight:bolder">Storage : {{this.storage}}</label>
    <label style="font-weight:bolder">Special : {{this.special}}</label>
    <label style="font-weight:bolder">W/R Total : {{this.wrTotal}}</label>
    </p> 

        




</mat-card>
