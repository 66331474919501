<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With General Invoice Details</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
  
  
      
      <div class="buttons">
        <button mat-button (click)="add()"  class="addButton">
          <mat-icon>add</mat-icon>
          <span>New General Invoice Detail</span>
        </button>
      </div>
     
     <div class="formDiv">
      <form [formGroup]="form">
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Control No:</mat-label>
            <input matInput formControlName="DGCTL"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Customer:</mat-label>
            <input matInput formControlName="CSDSC"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Invoice Amount:</mat-label>
            <input matInput formControlName="HGAMT"/>
          </mat-form-field>

        </div>     
      </form>
     </div>
     
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="DGSTAT">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            ST
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.DGSTAT }}</td>
        </ng-container>
  
        <ng-container matColumnDef="DGTASK">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Task Code
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.DGTASK }}</td>
        </ng-container>
  
        <ng-container matColumnDef="DGLEGC">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Ledger Codes
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.DGLEGC }}</td>
        </ng-container>
  
        <ng-container matColumnDef="GDESC">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Description
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.GDESC }}</td>
        </ng-container>

        <ng-container matColumnDef="DGDOCN">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
             Doc Number
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.DGDOCN }}</td>
          </ng-container>
  
        <ng-container matColumnDef="DGQTY">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
           Quantity
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.DGQTY }}</td>
        </ng-container>

        <ng-container matColumnDef="DGRATE">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Rate
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.DGRATE }}</td>
          </ng-container>

        <ng-container matColumnDef="DGAMT">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
             Charge Amount
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.DGAMT }}</td>
        </ng-container>

        <ng-container matColumnDef="DGMINM">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
             MN
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.DGMINM }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          <mat-option>
            <button mat-icon-button  (click)="change(element)">           
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button>
          </mat-option>

          <mat-option>
            <button mat-icon-button  (click)="delete(element)">           
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button>
          </mat-option>

          <mat-option>
            <button mat-icon-button  (click)="comment(element)">           
              <mat-icon mat-icon-button color="primary">comment</mat-icon>
              <mat-label>comment</mat-label>
            </button>
          </mat-option>
  
       
  
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
    </mat-card-content>
  </mat-card>

