import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-load-out',
  templateUrl: './delete-load-out.component.html',
  styleUrls: ['./delete-load-out.component.css'],
})
export class DeleteLoadOutComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteLoadOutComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      PalletSerial: '00000000000000',
      OrderNumber: 4790417,
      DocumentNumber: 4255,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  delete() {
    console.log('deleted');
  }
}
