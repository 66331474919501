<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Delete a Building</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Building Number </mat-label>
          <input matInput formControlName="buildingNumber" readonly />
        </mat-form-field>
      
      
        <mat-form-field appearance="outline">
          <mat-label> Building Name : </mat-label>
          <input matInput formControlName="buildingName" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Address </mat-label>
          <input matInput formControlName="address" readonly />
        </mat-form-field>
        
      </div>

      </div>

      <button
          class="buttons removeButton"
          mat-raised-button
          (click)="deleteBuilding()"
        >
          <span>Delete</span>
        </button>
    </form>
  </mat-card-content>
</mat-card>
