<mat-card class="over-Flow">
  
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title class="title">Work With UCC Label Control File</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Consignee </mat-label>
            <input matInput formControlName="Consignee" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Storer </mat-label>
            <input matInput formControlName="Storer" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="Storer">
      <th mat-header-cell *matHeaderCellDef>Storer</th>
      <td mat-cell *matCellDef="let element">{{ element.Storer }}</td>
    </ng-container>

    <ng-container matColumnDef="Form">
      <th mat-header-cell *matHeaderCellDef width="60px">Form</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Form }}
      </td>
    </ng-container>

    <ng-container matColumnDef="LblType">
      <th mat-header-cell *matHeaderCellDef>Lbl Type</th>
      <td mat-cell *matCellDef="let element">{{ element.LblType }}</td>
    </ng-container>

    <ng-container matColumnDef="MessageFile">
      <th mat-header-cell *matHeaderCellDef>Message File</th>
      <td mat-cell *matCellDef="let element">{{ element.MessageFile }}</td>
    </ng-container>

    <ng-container matColumnDef="ValidityProgram">
      <th mat-header-cell *matHeaderCellDef>Validity Program</th>
      <td mat-cell *matCellDef="let element">{{ element.ValidityProgram }}</td>
    </ng-container>

    <ng-container matColumnDef="LabelName">
      <th mat-header-cell *matHeaderCellDef>Label Name</th>
      <td mat-cell *matCellDef="let element">{{ element.LabelName }}</td>
    </ng-container>

    <ng-container matColumnDef="OutputQueue">
      <th mat-header-cell *matHeaderCellDef>Output Queue</th>
      <td mat-cell *matCellDef="let element">{{ element.OutputQueue }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
