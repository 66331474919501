import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-carriers-edit',
  templateUrl: './carriers-edit.component.html',
  styleUrls: ['./carriers-edit.component.css']
})
export class CarriersEditComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CarriersEditComponent>
   ) 
   { }

  ngOnInit(): void {
    this.form = this.fb.group({
  
    SCACCode: 'RWDY',
    CarrierName:'ROADWAY EXPRESS/YRC',
    CarrierAddress:'P O BOX 471 106',
    ZipPostalCode:'44309 Akron OH',
    FuelSurchargeTBL:'',
    HazardousMaterials:'Y',
    PalletExchange:'Y',
    SummaryPayments:'Y',
    ChangeDate:'2017-05-31-16.08.21.271189',
    ChangedBy:'DWHITE',
    Status:'AC',

 
   
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}
