<!-- Task No : 67.24 -->
<mat-card>
    <mat-card-header>  
      <mat-card-title >Work with Dock Scheduler</mat-card-title>
    </mat-card-header>

    <mat-card-content>

        <button mat-button (click)="addDocScheduler()" class="addButton">
            <mat-icon>add</mat-icon>
            <span>Doc Scheduler</span>
      </button>
      
        <div class="formDiv">

             
         

          <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline">
                            <mat-label>Date</mat-label>
                            <input matInput formControlName="Date"/>
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>SCAC</mat-label>
                            <input matInput formControlName="SCAC"/>
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>Bldg/Dr</mat-label>
                            <input matInput formControlName="BldgDr"/>
                          </mat-form-field>

                         
                    </div>
    
                    <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Open</mat-label>
                        <input matInput formControlName="Open"/>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Appt</mat-label>
                        <input matInput formControlName="Appt"/>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Appt Type</mat-label>
                        <input matInput formControlName="ApptType"/>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Load Type</mat-label>
                        <input matInput formControlName="LoadType"/>
                      </mat-form-field>
                    </div>   

                    <div class="col">

                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <input matInput formControlName="Status"/>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>PO</mat-label>
                          <input matInput formControlName="PO"/>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>Ref</mat-label>
                          <input matInput formControlName="Ref"/>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Acct</mat-label>
                            <input matInput formControlName="Acct"/>
                          </mat-form-field>
                    </div>  

                    <div class="col">  
                        
                        <mat-form-field appearance="outline">
                            <mat-label>In/Out</mat-label>
                            <input matInput formControlName="InOut"/>
                          </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>Priority</mat-label>
                          <input matInput formControlName="Priority"/>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Document</mat-label>
                            <input matInput formControlName="Document"/>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Type</mat-label>
                            <input matInput formControlName="Type"/>
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

       

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Appt#">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Appt
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.Appt }}</td>
            </ng-container>
      
            <ng-container matColumnDef="I/O">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                I/O
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.IO }}</td>
            </ng-container>

            <ng-container matColumnDef="ApTy">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    ApTy
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.ApTy }}</td>
            </ng-container>

            <ng-container matColumnDef="LT">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    LT
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LT }}</td>
            </ng-container>

            <ng-container matColumnDef="LP">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    LP
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LP }}</td>
            </ng-container>

            <ng-container matColumnDef="SCAC">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    SCAC
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.SCAC }}</td>
            </ng-container>

            <ng-container matColumnDef="Bldg">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Bldg
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Bldg }}</td>
            </ng-container>

            <ng-container matColumnDef="App">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    App
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.App }}</td>
            </ng-container>

            <ng-container matColumnDef="Date">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Date
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Date }}</td>
            </ng-container>
      
            <ng-container matColumnDef="Time">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Time
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Time }}</td>
            </ng-container>
         
            <ng-container matColumnDef="Load/Batch">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Load/Batch
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LoadBatch }}</td>
            </ng-container>

            <ng-container matColumnDef="Door">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Door
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Door }}</td>
            </ng-container>

            <ng-container matColumnDef="Pri">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Pri
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Pri }}</td>
            </ng-container>

            <ng-container matColumnDef="Sts">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Sts
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Pri }}</td>
            </ng-container>

            <ng-container matColumnDef="Action">
                <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
                    <mat-option>
                        <button mat-icon-button>
                          <mat-label>Move History</mat-label>
                        </button>
                      </mat-option>
                  </mat-select>
                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            
        </table>
    </mat-card-content>
</mat-card>
