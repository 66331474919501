<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Item Master Maintenance</mat-card-title>
      
    </mat-card-header>
    <mat-card-content>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Storer Number </mat-label>
          <input readonly matInput formControlName="ITSTOR" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Suffix </mat-label>
          <input readonly matInput formControlName="ITSSFX" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Item Code</mat-label>
          <input matInput formControlName="ITEM" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <input matInput formControlName="ITDSC1" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>UPC</mat-label>
          <input matInput formControlName="ITUPCC" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Tail Code</mat-label>
          <input matInput formControlName="ITTAIL" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Product Code</mat-label>
          <input matInput formControlName="ITPDCD" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Allow Web Selection </mat-label>
          <mat-select formControlName="ITQUIC">
            <mat-option value="Y">Yes</mat-option>
            <mat-option value="N">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Lotted Item </mat-label>
          <mat-select formControlName="ITLOTT">
            <mat-option value="Y">Yes</mat-option>
            <mat-option value="N">No</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Lot Unit Weight </mat-label>
          <mat-select formControlName="ITLUWT">
            <mat-option value="Y">Yes</mat-option>
            <mat-option value="N">No</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Sort Mask </mat-label>
          <input matInput formControlName="sortMask" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Apply To Lot </mat-label>
          <input matInput formControlName="ITLTLB" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Place After </mat-label>
          <input matInput formControlName="ITPREV" />
        </mat-form-field>
        <button mat-button class="buttons addButton" (click)="itemUpdate1()">
          <mat-label>Create</mat-label>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
