import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderDetailsService } from '../order-details.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-detail-split-line',
  templateUrl: './order-detail-split-line.component.html',
  styleUrls: ['./order-detail-split-line.component.css']
})

export class OrderDetailSplitLineComponent implements OnInit {

  constructor(
    public orderDetailSrv: OrderDetailsService,
    private fb: FormBuilder,
    private router : Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<OrderDetailSplitLineComponent>
  ) { }

  //form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  form = this.fb.group({
    bol:this.anyvariable.ODBILL,
    item:this.anyvariable.ODITEM,
    seq:this.anyvariable.ODSSEQ,
    shipFrom: this.anyvariable.ODSHFR,
    lots: this.anyvariable.ODLOT1 + ' | ' + this.anyvariable.ODLOT2 + ' | ' + this.anyvariable.ODLOT3,
    lot1:this.anyvariable.ODLOT1,
    lot2:this.anyvariable.ODLOT2,
    lot3:this.anyvariable.ODLOT3,
    location: this.anyvariable.ODAISL + ' | ' + this.anyvariable.ODSLOT,
    splitQuantity:'' 
  });

  ngOnInit(): void {
    
  }

  splitLine() {
    var res = this.form.value;    
    this.result = this.orderDetailSrv.splitLineService(
      res.bol,
      res.seq,
      res.location,
      res.lot1,
      res.lot2,
      res.lot3,
      res.splitQuantity
    ).subscribe((data: any) => {
      this.result = data;
      var length = this.result[0].data.length;
      if(this.result[0].data[length-1].value=='Split order line Successfull'){
        const msg= this.result[0].data[length-1].value;
        this.successMessage(msg);
        this.closeDialog();
      }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
      }
    });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/details']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  changeShipForm(){
    
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
