import { DeleteLoadOutComponent } from './delete-load-out/delete-load-out.component';
import { UnloadPalletComponent } from './unload-pallet/unload-pallet.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-load-out',
  templateUrl: './load-out.component.html',
  styleUrls: ['./load-out.component.css'],
})
export class LoadOutComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'PalletNumber',
    'T',
    'LoadTrk',
    'Order',
    'Pos',
    'Lvl',
    'ST',
    'Actions',
  ];
  dataSource: any = [];

  duplicateSource: any = [
    {
      PalletNumber: '00000000000000',
      T: 'A',
      LoadTrk: 4255,
      Order: 4790417,
      Pos: 1,
      Lvl: 'A',
      ST: 'LV',
    },
  ];

  constructor(private fb: FormBuilder, private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      Bill: '',
      LoadType: '',
      LoadNmbr: '',
      SRL: '',
      Status: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  deleteLoadOut() {
    this.matDialog.open(DeleteLoadOutComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  unloadPallet() {
    this.matDialog.open(UnloadPalletComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
}
