import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsigeeService {

  url = environment.API_BASE_URL;
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  
  getConsignee(user: any, consCode:any,name:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/consignee/read?user=${user}&consCode=${consCode}&name=${name}`
    );
  }

  getAll(user: any): Observable<any> {
    return this.httpClient.get(
      this.url+`/consignee/readAll?user=${user}`
    );
  }

  

  createConsignee(
    CONSCD:any, //set
    CONAME:any, //set
    COADD1:any, //set
    COADD2:any, //set
    COADD3:any, //set
    COADD4:any, //set
    COZIP:any, //set
    COZSFX:any, //set
    COZONE:any, //set
    COPHAR:any, //set
    COPHEX:any, //set
    COPHNU:any, //set
    CODEST:any, //set
    user:any


    
    ): Observable<any> {
    return this.httpClient
      .post<any>(this.url+'/consignee/create', { 
        CONSCD, //consinee Code        
        CONAME, //Name
        COADD1, //Address1
        COADD2, //Address2
        COADD3, //Address3
        COADD4, //Address4
        COZIP, //zip code
        COZSFX, //zip code suffix
        COZONE, //zone code
        COPHAR, //area code of phone number
        COPHEX, //first 3 digit of phone number
        COPHNU, //final four digit phone number
        CODEST, //Destination Code
        user


     })
      .pipe(retry(1), catchError(this.httpError));
  }
 
  updateConsignee(
    CONSCD:any, //set
    CONAME:any, //set
    COADD1:any, //set
    COADD2:any, //set
    COADD3:any, //set
    COADD4:any, //set
    COZIP:any, //set
    COZSFX:any, //set
    COZONE:any, //set
    COPHAR:any, //set
    COPHEX:any, //set
    COPHNU:any, //set
    CODEST:any, //set
    user:any


    
    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/consignee/update', { 
        CONSCD, //consinee Code        
        CONAME, //Name
        COADD1, //Address1
        COADD2, //Address2
        COADD3, //Address3
        COADD4, //Address4
        COZIP, //zip code
        COZSFX, //zip code suffix
        COZONE, //zone code
        COPHAR, //area code of phone number
        COPHEX, //first 3 digit of phone number
        COPHNU, //final four digit phone number
        CODEST, //Destination Code
        user


     })
      .pipe(retry(1), catchError(this.httpError));
  }
  
  deleteConsignee(user: any, consCode:any): Observable<any> {
    return this.httpClient.delete<any>(
      this.url+`/consignee/delete?user=${user}&consCode=${consCode}`
    );
  }
//----------------- CONSIGNEE x REFERENCE -----------------

  getConsigneeXrefService(user: any, consigneeCode:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/consigneeCrossRef/read?user=${user}&consigneeCode=${consigneeCode}`
    );
  }

  
  createConsigneeXrefService(
    consigneeCode:any,
    parentConsignee:any,
    user:any
    
    ): Observable<any> {
    return this.httpClient
      .post<any>(this.url+'/consigneeCrossRef/create', { 
        consigneeCode,        
        parentConsignee,
        user

     })
      .pipe(retry(1), catchError(this.httpError));
  }



  updateConsigneeXrefService(
    consigneeCode:any,
    parentConsignee:any,
    user:any
    
    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/consigneeCrossRef/update', { 
        consigneeCode,        
        parentConsignee,
        user


     })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteConsigneeXrefService(user: any, consigneeCode:any): Observable<any> {
    return this.httpClient.delete<any>(
      this.url+`/consigneeCrossRef/delete?user=${user}&consigneeCode=${consigneeCode}`
    );
  }

  //-------------consignee system comments-------------
  getConsigneeComments(user:any, consCode:any,subType:any, sequence:any){
    return this.httpClient.get(
      this.url+`/consignee/commentRead?user=${user}&consigneeCode=${consCode}&subType=${subType}&sequence=${sequence}`
    )
  }

  createConsigneeCommentService(
    consigneeCode:any,
    subType:any,
    sequence:any,
    comment:any,  
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .post<any>(this.url+'/consignee/commentCreate', {
        consigneeCode,
        subType,
        sequence,
        comment,  
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  
  updateConsigneeCommentService(
    consigneeCode:any,
    subType:any,
    sequence:any,
    comment:any,  
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/consignee/commentUpdate ', {
        consigneeCode,
        subType,
        sequence,
        comment,  
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteconsigneeCommentService(user:any, consCode: any, subType: any, sequence:any): Observable<any> {
    return this.httpClient
      .delete<any>(this.url+`/consignee/commentDelete?user=${user}&consigneeCode=${consCode}&subType=${subType}&sequence=${sequence}`)
      .pipe(retry(1), catchError(this.httpError));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
