<!-- Form No: 671 -->
<mat-card class="over-Flow">

  <div class="main-header-title">
      <mat-card-header>      
          <mat-card-title>Maintain Consignee Items</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button> 
  </div>             
        
    <mat-card-content class="tally-serach-form"> 
        <form [formGroup]="form"> 
          <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Storer: </mat-label> 
                    <input matInput formControlName="Storer" />
                </mat-form-field>
        
                <mat-form-field appearance="outline">  <mat-label> ItemCode: </mat-label> 
                    <input matInput formControlName="ItemCode" />
                </mat-form-field>
        
                <mat-form-field appearance="outline">  <mat-label> ConsigneeCode: </mat-label> 
                    <input matInput formControlName="ConsigneeCode" />
                </mat-form-field>
        
                <mat-form-field appearance="outline">  <mat-label> BuyerItemCode: </mat-label> 
                    <input matInput formControlName="BuyerItemCode" />
                </mat-form-field>
        
                <mat-form-field appearance="outline">  <mat-label> UPCCode: </mat-label> 
                    <input matInput formControlName="UPCCode" />
                </mat-form-field>
        
                <mat-form-field appearance="outline">  <mat-label> SecondaryItemCode: </mat-label> 
                    <input matInput formControlName="SecondaryItemCode" />
                </mat-form-field>
        
                <mat-form-field appearance="outline">  <mat-label> StatusCode: </mat-label> 
                    <input matInput formControlName="StatusCode" />
                </mat-form-field>
            
                <button mat-button class="buttons addButton" (click)="attributes()">Add</button>
            </div>
          </div>
        </form>  
    </mat-card-content>

</mat-card>
  
