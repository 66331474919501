<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>change Labor Plan</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">


                <mat-form-field  appearance="outline">
                    <mat-label> DocumentNumber </mat-label> 
                    <input matInput formControlName="DocumentNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Date</mat-label> 
                    <input matInput formControlName="Date"/>
                </mat-form-field>


                <mat-form-field  appearance="outline">
                    <mat-label>Location </mat-label> 
                    <input matInput formControlName="Location"/>
                </mat-form-field>


            </div> 
            <div class="col">
                <button mat-button (click)="changeLaborPlan()" class="addButton">  
                    <span>Change</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>
