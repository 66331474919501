import { GlobalComponent } from './../../../Global-Component';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { InboundStagingService } from './inbound-staging.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-inbound-staging',
  templateUrl: './inbound-staging.component.html',
  styleUrls: ['./inbound-staging.component.css'],
})
export class InboundStagingComponent implements OnInit {
  error: string = '';
  stagingForm = this.fb.group({
    tallyNumber: [null, Validators.required],
    item: [null, Validators.required],
    Quantity: [null, Validators.required],
    InventoryType: [null, Validators.required],
    lot1: [null],
    lot2: [null],
    lot3: [null],
    location: [null],
    LotUnitWeight: [null, Validators.required],
  });
  result: any = [];
  count = 0;
  constructor(
    private fb: FormBuilder,
    private router: Router,

    public iStagingSvc: InboundStagingService
  ) {}
 // user = GlobalComponent.user;
 user = localStorage.getItem('userName');

  ngOnInit(): void {
    // this.user = localStorage.getItem('userName');
  }

  getCount2(){
    this.count++;
  }

  // onSubmit(): void {
  //   var res = this.stagingForm.value;

  //   this.result = this.iStagingSvc
  //     .getData(
  //       res.tallyNumber,
  //       res.item,
  //       res.lot1,
  //       res.lot2,
  //       res.lot3,
  //       res.LotUnitWeight,
  //       res.Quantity,
  //       res.location,
  //       res.InventoryType,
  //       this.user
  //     )
  //     .subscribe((data) => {
  //       this.result = data[0];
  //       var msg = 'Data saved successfully';

  //       if (this.result.data[11].value === '') {
  //         this.successMessage(msg);
  //       } else {
  //         msg = this.result.data[11].value;
  //         this.FailureMessage(msg);
  //       }
  //     });
  // }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.reloadCurrentRoute();
  }

  FailureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  onSubmit() {
    this.error = '';
    var res = this.stagingForm.value;
    if (this.count === 1) {
      this.result = this.iStagingSvc
        .getItem(
          res.tallyNumber,
          res.item,
          res.lot1,
          res.lot2,
          res.lot3,
          res.LotUnitWeight,
          res.Quantity,
          res.InventoryType,
          this.user
        )
        .subscribe((data) => {
          this.result = data;
          if (this.result[0].data[10].value == 'Tally Not Found.') {
            this.error = 'Tally Number not available';
          }
        });
     // this.count++;
    }
    if (this.count === 1) {
      this.result = this.iStagingSvc
        .getLocation(res.tallyNumber, res.location, this.user)
        .subscribe((data) => {
          this.result = data[0];
        });
    }

    if(this.count === 1){

      var res = this.stagingForm.value;

    this.result = this.iStagingSvc
      .getData(
        res.tallyNumber,
        res.item,
        res.lot1,
        res.lot2,
        res.lot3,
        res.LotUnitWeight,
        res.Quantity,
        res.location,
        res.InventoryType,
        this.user
      )
      .subscribe((data) => {
        this.result = data[0];
        var msg = 'Data saved successfully';

        if (this.result.data[11].value === '') {
          this.successMessage(msg);
        } else {
          msg = this.result.data[11].value;
          this.FailureMessage(msg);
        }
      });

    }
  }
}
