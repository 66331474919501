import { ImapZoneService } from './../imap-zone.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IbuildingsService } from '../../buildings/ibuildings.service';
@Component({
  selector: 'app-create-mapzone',
  templateUrl: './create-mapzone.component.html',
  styleUrls: ['./create-mapzone.component.css'],
})
export class CreateMapzoneComponent implements OnInit {
  anyvariable: any;
  constructor(
    private router: Router,
    public imapSvc: ImapZoneService,
    public ibuildingSvc: IbuildingsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<CreateMapzoneComponent>
  ) {}

  //form!: FormGroup;
  form:any;
  result: any;
  duplicateSource:any;
  user=localStorage.getItem('userName');
  itemOptions: object[] = [];
  element:any;

  ngOnInit(): void {
    this.form = this.fb.group({
      buildingNumber: [null],
      zone: [null],
      description: [null,[Validators.required]],
      inboundStaging: [null,[Validators.required]],
      outboundStaging: [null,[Validators.required]],
      backCount: [null,[Validators.required]],
      customUsage1: [null],
      customUsage2: [null],
      customUsage3: [null],
      customUsage4: [null],
      productPutaway: [null,[Validators.required]],
      productPicking: [null,[Validators.required]],
    });

    this.ibuildingSvc.getAll(this.user
      ).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('SINGLE MAP ZONE');
      console.log(this.duplicateSource);
      this.element=parsedData.returnedData;

      for (const item of parsedData.returnedData) {
        this.itemOptions.push(item);
      }  
     // console.log(this.anyvariable.WZBLDG);
     
      console.log("create mapzone",this.itemOptions);
      console.log(this.itemOptions);
      
    });
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  addMapZone() {
    console.log(this.form.get('buildingNumber').value,'Building Value');
    var res = this.form.value;
    console.log(res);

    this.result = this.imapSvc
      .createMapZone(
       // res.buildingNumber,
       this.form.get('buildingNumber').value.BUILD,
        res.zone,
        res.description,
        res.inboundStaging,
        res.productPutaway,
        res.outboundStaging,
        res.productPicking,
        res.backCount,
        res.customUsage1,
        res.customUsage1,
        res.customUsage1,
        res.customUsage1,
       this.user)
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       // var msg = JSON.parse(this.result).returnedData.message;

       // this.successMessage(msg);
       if(JSON.parse(this.result[0].data[1].value).sucess){
        //alert('Record Created SuccessFully');
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/mapzone/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/mapzone/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
}
