<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Batch Details </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Batch Number</mat-label>
            <input matInput formControlName="BatchNumber" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Carrier</mat-label>
            <input matInput formControlName="Carrier" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="BillNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Bill Number
      </th>
      <td mat-cell *matCellDef="let element">{{ element.BillNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="St">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>St</th>
      <td mat-cell *matCellDef="let element">{{ element.St }}</td>
    </ng-container>

    <ng-container matColumnDef="LansPos">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Lans/Pos
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.LansPos }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Consignee">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Consignee
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Consignee }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ScheduledShipDate">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Scheduled Ship Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.ScheduledShipDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Cubes">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Cubes
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Cubes }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Weight">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Weight
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Weight }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
              #paginator
              [pageIndex]="0"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
