<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Copy A Workflow Process</mat-card-title>
      &nbsp;
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Process</mat-label>
            <input matInput formControlName="Process" />
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>From Storer </mat-label>
            <input matInput formControlName="FromStorer" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> To Storer </mat-label>
            <input matInput formControlName="ToStorer" />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
