import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-rebalances',
  templateUrl: './item-rebalances.component.html',
  styleUrls: ['./item-rebalances.component.css']
})
export class ItemRebalancesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
