import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ConsigeeService } from '../../consignee/consigee.service';
import { OrderUpdate2Component } from '../order-update2/order-update2.component';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-update',
  templateUrl: './order-update.component.html',
  styleUrls: ['./order-update.component.css']
})
export class OrderUpdateComponent implements OnInit {

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    public iOrderService: OrderService,
    public consigeeService: ConsigeeService,  
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
   private dialogRef: MatDialogRef<OrderUpdateComponent>
   ) 
   { }

   storer: any;
   form!: FormGroup;
   result: any;
   user = localStorage.getItem('userName');
   dataSource:any;
   consName:any;
   consAdrs1:any;
   consAdrs2:any;
   consAdrs3:any;
   consAdrs4:any;
   

  ngOnInit(): void {

  this.consName='';
  this.consAdrs1='';
  this.consAdrs2='';
  this.consAdrs3='';
   this.consAdrs4='';
    
    this.consigeeService.getConsignee(this.user,this.anyvariable.OHCONS,'').subscribe((data) => {     

      this.result = data;
         // this.TallyHeaders = data;
          //console.log(this.TallyHeaders);
          var res = this.result[0].data[1]?.value;
          console.log(res);
          var parsedData = JSON.parse(res);
          console.log(parsedData);
          console.log('DaTA');
           this.dataSource = parsedData.returnedData;
          //this.dataSource = new MatTableDataSource(parsedData.returnedData);
         
          console.log('DaTA');
          console.log(this.dataSource[0]);

          this.consName = this.dataSource[0].CONAME;
          this.consAdrs1 = this.dataSource[0].COADD1;
          this.consAdrs2 = this.dataSource[0].COADD2;
          this.consAdrs3 = this.dataSource[0].COADD3;
          this.consAdrs4 = this.dataSource[0].COADD4;
          
         
        
    });  

    this.form = this.fb.group({
     
      bolNumber:this.anyvariable.OHBILL,
      orderStatus:this.anyvariable.OHSTAT,
      storer:this.anyvariable.OHSTOR,
      suffix:this.anyvariable.OHSSFX,
      storerRef:this.anyvariable.OHSREF,
      purchaseOrder:this.anyvariable.OHPO,
      consignee:this.anyvariable.OHCONS,

      consigneeName:this.consName,
      address1:this.consAdrs1,
      address2:this.consAdrs2,
      address3:this.consAdrs3,
      address4:this.consAdrs4,
      address5:'', // Not getting from response 
  
       
    });
  }

  orderUpdate1(){
    var res = this.form.value;
    
   
    this.result = this.iOrderService
      .orderServiceUpdate1( 
        
        res.bolNumber,
        res.orderStatus,
        res.storer,
        res.suffix,
        res.storerRef,
        res.purchaseOrder,
        res.consignee,
        res.consigneeName,
        res.address1,
        res.address2,
        res.address3,
        res.address4,
        res.address5
        

        ).subscribe((data: any) => {
        this.result = data;
        console.log( this.result,'*******');
  

       var length = this.result[0].data.length;
        if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Order Updated Successfully'){
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.updateOrder2();
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/orders/read']);
  }


  closeDialog(){
    this.dialogRef.close();
  }


  backstorer(){
    
  }

  updateOrder2(){
    var res = this.form.value;
    var ord= this.anyvariable;
    var orders =[res,ord]
    this.matDialog.open(OrderUpdate2Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: orders,
      autoFocus: false,
    });

  }
}