<mat-card>
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Change Detail Location</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Bill : </mat-label>
            <input matInput value="{{ this.bol }}" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label> Item : </mat-label>
            <input matInput value="{{ this.item }}" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label> Line no : </mat-label>
            <input matInput value="{{ this.seq }}" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Quantity: </mat-label>
            <input matInput value="{{ this.quantity }}" readonly />
          </mat-form-field>

          <hr />

          <mat-form-field appearance="outline">
            <mat-label> Building: </mat-label>
            <input
              matInput
              readonly
              value="{{ this.building }}"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> AISL/SLOT: </mat-label>
            <input matInput formControlName="aislSlot" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Lot 1: </mat-label>
            <input
              matInput
              formControlName="lot1"
              style="text-transform: uppercase"
             
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Lot 2: </mat-label>
            <input
              matInput
              formControlName="lot2"
              style="text-transform: uppercase"
              
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Lot 3: </mat-label>
            <input
              matInput
              formControlName="lot3"
              style="text-transform: uppercase"
      
            />
          </mat-form-field>
        </div>
      </div>
      <div>
        <button
          mat-button
          class="buttons addButton"
          (click)="updateOrderDetailLoc()"
        >
          Update
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
