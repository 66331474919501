import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { OrderDetailsService } from '../../orders/order-details/order-details.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-item-status-inquiry',
  templateUrl: './item-status-inquiry.component.html',
  styleUrls: ['./item-status-inquiry.component.css']
})
export class ItemStatusInquiryComponent implements OnInit {

  constructor(
    public orderDetailSrv:OrderDetailsService,
   @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
   private dialogRef: MatDialogRef<ItemStatusInquiryComponent>
   ) { }

  @ViewChild(MatPaginator) paginator!:MatPaginator;
  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  duplicateSource:any;
  dataSource:any;

  storerName:any;
  itemDesc:any;

  onHand:any;
  available:any;
  futureAllocation:any;
  committed:any;
  shipped:any;
  inTransit:any;
  bucket1:any;
  bucket2:any;
  bucket3:any;
  bucket4:any;
  ITCDAM:any;
  Damaged:any;
  User:any;

  ngOnInit(): void {

    this.form = this.fb.group({ 
      itemStorer:this.anyvariable.ITSTOR,
      suffix:this.anyvariable.ITSSFX,
      item:this.anyvariable.ITEM,
      Damaged: this.anyvariable.LCQDAM,
              
    });

    var res = this.form.value;
    this.orderDetailSrv.orderDtlItemStatusService(
      this.user,
      res.itemStorer,
      res.suffix,
      res.item
    ).subscribe((data) => {
      this.result = data;    
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('ORDER DETAIL ITEM STATUS DATA');
      console.log(this.duplicateSource);

      this.storerName = this.duplicateSource[0].ITNAME;
      this.itemDesc = this.duplicateSource[0].ITDSC1;

      this.onHand=this.duplicateSource[0].ITOHND;
      this.available=this.duplicateSource[0].ITCAVL;
      this.futureAllocation=this.duplicateSource[0].ITCALL;
      this.committed=this.duplicateSource[0].ITCCOM;
      this.shipped=this.duplicateSource[0].ITCSHP;
      this.inTransit=this.duplicateSource[0].ITCINT;
      this.bucket1=this.duplicateSource[0].ITCUS1;
      this.bucket2=this.duplicateSource[0].ITCUS2;
      this.bucket3=this.duplicateSource[0].ITCUS3;
      this.bucket4=this.duplicateSource[0].ITCUS4;
      this.ITCDAM=this.duplicateSource[0].ITCDAM;
    })
  }

  closeDialog(){
    this.dialogRef.close();
  }

  backstorer(){
    
  }
  inquiry(){
    
  }

}
