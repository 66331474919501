<mat-card>

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Item Status Inquiry</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

      <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
          <div class="col">

              <mat-form-field  appearance="outline"><mat-label> Storer: </mat-label> 
                <input matInput formControlName="itemStorer" readonly/>
              </mat-form-field>

              <mat-form-field  appearance="outline"><mat-label> Storer Name: </mat-label> 
                <input matInput value={{storerName}} readonly/>
              </mat-form-field>


              <mat-form-field appearance="outline">  <mat-label> Item: </mat-label> 
                <input matInput formControlName="item" readonly/>
              </mat-form-field>

              <mat-form-field  appearance="outline"><mat-label> Desc: </mat-label> 
                <input matInput value={{itemDesc}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> On Hand: </mat-label> 
                <input matInput value={{onHand}} readonly/>
              </mat-form-field>

              <mat-form-field  appearance="outline"><mat-label> Available : </mat-label> 
                <input matInput  value={{available}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Future Allocation: </mat-label> 
                <input matInput  value={{futureAllocation}} readonly/>
              </mat-form-field>

              <mat-form-field  appearance="outline"><mat-label> Committed : </mat-label> 
                <input matInput  value={{committed}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Shipped: </mat-label> 
                <input matInput  value={{shipped}} readonly/>
              </mat-form-field>

              <mat-form-field  appearance="outline"><mat-label> In-Transit : </mat-label> 
                <input matInput  value={{inTransit}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Bucket1: </mat-label> 
                <input matInput  value={{bucket1}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Bucket2: </mat-label> 
                <input matInput  value={{bucket2}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Bucket3: </mat-label> 
                <input matInput  value={{bucket3}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Bucket4: </mat-label> 
                <input matInput  value={{bucket4}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> ITCDAM: </mat-label> 
                <input matInput  value={{ITCDAM}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Damaged: </mat-label> 
                <input matInput  value={{Damaged}} readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> User: </mat-label> 
                <input matInput  value={{User}} readonly/>
              </mat-form-field>

        </div>
     </form>  
   </mat-card-content>
</mat-card>
  


