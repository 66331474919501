<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Maintain Labor Plan Group Users</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Group Name </mat-label>
            <input matInput formControlName="LUNAME" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Operator </mat-label>
            <input matInput formControlName="LUOPER" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Priority </mat-label>

            <input matInput formControlName="LUPRIO" />
          </mat-form-field>

          <button
            mat-button
            class="buttons addButton"
            mat-raised-button
            (click)="update()"
          >
            Update
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
