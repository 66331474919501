<mat-card>
    <mat-card-header>  
      <mat-card-title>Work With Equipment Operators</mat-card-title>

        <div class="buttons">
          
      <button
      mat-button
      (click)="searchTally()"
      class="addButton"
      *ngIf="searchTextDisplay"
    >
      <mat-icon>clear</mat-icon>
      <!-- <span>Hide Search Details</span> -->
    </button>

    <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay" matTooltip="Search Equipment Operator"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>
  
          <button mat-button (click)="addEquipmentOperators()" matTooltip="New Equipment Operator" class="addButton">
            <mat-icon>add</mat-icon>
          </button>
        </div>
  
       
    </mat-card-header>
  
    <mat-card-content class="tally-serach-form">
  
  
      
      
     
     
      <form [formGroup]="form" (ngSubmit)="getEquipmentOperator(form.value)" *ngIf="searchTextDisplay">
        <div class="row">
          <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Operator:</mat-label>
            <input matInput formControlName="Operator"/>
          </mat-form-field>

          <button
          class="buttons addButton"
          mat-raised-button
          type="submit"
          placeholder="Search"
        >
          Search
        </button>

        </div>     
        </div>
       
      </form>
    </mat-card-content>
     
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="Operator">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Operator
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.EOUSER }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Name">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Name
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.EONAME }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Storer">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Storer
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.EOSTOR }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Building">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Building
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.EOBLDG }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Status">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Status
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.EOSTAT }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
          <!--  Actions -->
          </th>

          <td mat-cell *matCellDef="let element">
            <div class="dropdown more_options_btn">
              <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <mat-icon>more_vert</mat-icon>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <button mat-icon-button  (click)="changeEquipmentOperators(element)">           
                    <mat-icon mat-icon-button color="primary">edit</mat-icon>
                    <mat-label>Edit</mat-label>
                  </button>
                </li>

                <li>
                  <button mat-icon-button  (click)="deleteEquipmentOperators(element)">           
                    <mat-icon mat-icon-button color="warn">delete</mat-icon>
                    <mat-label>Delete</mat-label>
                  </button>
                </li>

                <li>
                  <button mat-icon-button  (click)="openWorkAssignments(element)">           
                    <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                    <mat-label>Work With Work Assignments</mat-label>
                  </button>
                </li>

              </ul>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[4,8]" 
        showFirstLastButtons class="tally-table-pagination"
      ></mat-paginator>
    
  </mat-card>

