<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Split An Another Line</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Bill Number : </mat-label>
            <input matInput formControlName="bol" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Item Code : </mat-label>
            <input matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Line Number : </mat-label>
            <input matInput formControlName="seq" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Ship From : </mat-label>
            <input matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot Codes : </mat-label>
            <input matInput formControlName="lots" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Location : </mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Split Quantity : </mat-label>
            <input matInput formControlName="splitQuantity" />
          </mat-form-field>
        </div>
      </div>
      <div>
        <button mat-button (click)="splitLine()" class="buttons addButton">
          Split
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
