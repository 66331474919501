<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>De-Ship an Order</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
            <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Bill Number</mat-label>
                    <input matInput formControlName="BillNumber" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="Storer" />
                </mat-form-field>


                <mat-form-field appearance="outline">
                    <mat-label>Consignee</mat-label>
                    <input matInput formControlName="Consignee" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Carrier</mat-label>
                    <input matInput formControlName="Carrier" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Pro Number</mat-label>
                    <input matInput formControlName="ProNumber" />
                </mat-form-field>

                <!-- <mat-form-field appearance="outline">
                    <mat-label>Ship Date</mat-label>
                    <input matInput formControlName="ShipDate" />
                </mat-form-field> -->
                <mat-form-field appearance="outline">
                    <mat-label> Ship Date</mat-label>
                    <input
                      matInput
                      formControlName="ShipDate"
                      [matDatepicker]="datePicker"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="datePicker"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #datePicker></mat-datepicker>
                  </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Ship Time</mat-label>
                    <input matInput formControlName="ShipTime " />
                    
                </mat-form-field>
            </div>
            </div>
        
            <p style="color: red; font-size: 20px;margin-left: 10px;">An order cannot be de-shipped unless it was shippped within 24hrs request-denied</p>

            <button mat-button class="buttons removeButton" (click)="deshipOrder()">De-ship</button>
            <button mat-button class="buttons addButton">Cancel</button>
        
        </form>
    </mat-card-content>
</mat-card>