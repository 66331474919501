import { GlobalComponent } from './../../Global-Component';
import { BuildingContactComponent } from './building-contact/building-contact.component';
import { DoorsComponent } from './doors/doors.component';
import { DeleteBuildingComponent } from './delete-building/delete-building.component';
import { UpdateBuildingComponent } from './update-building/update-building.component';
import { CreateBuildingComponent } from './create-building/create-building.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { IbuildingsService } from './ibuildings.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

export interface Building {
  name: string;
  address: string;
  zip: number;
}

const ELEMENT_DATA: Building[] = [
  {
    name: 'A',
    address: 'Address',
    zip: 43312,
  },
  {
    name: 'B',
    address: 'Address2',
    zip: 12345,
  },
];

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.css'],
})
export class BuildingsWorkWithComponent implements OnInit {
  displayedColumns: string[] = ['No', 'Name', 'Address', 'Zip', 'Actions'];

  form = this.fb.group({
    building: '',
    name: [null],
    address: [null],
    zip: [null],
  });
  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    public ibuildingSvc: IbuildingsService,
    private fb: FormBuilder,
    private matDialog: MatDialog
  ) {}

  id: any;
  result: any;
  dataSource: any = [];
  duplicateSource: any = [];
  @ViewChild('paginator') paginator!: MatPaginator;
  BuildingHeaders: any = [];
  user = localStorage.getItem('userName');
  building = localStorage.getItem('building');

  ngOnInit(): void {
    this.getAll();
  }

  searchTextDisplay: any;

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  getBuildingBySearch(building: any) {
    var res = this.form.value;
    for (var i = building.building.length; i < 3; i++) {
      res.building = ' ' + res.building;
    }

    this.ibuildingSvc.getBuilding(this.user, res.building).subscribe((data) => {
      this.result = data;
      var res = this.result;

      var res = this.result[0].data[1]?.value;

      var parsedData = JSON.parse(res);

      this.duplicateSource = parsedData.returnedData;
      
      this.dataSource = new MatTableDataSource(this.duplicateSource);

      this.dataSource.paginator = this.paginator;
    });
  }

  getAll() {
    this.ibuildingSvc.getAll(this.user).subscribe((data) => {
      this.result = data;
      var res = this.result;

      var res = this.result[0].data[1]?.value;

      console.log(res);
      console.log(res);

      var parsedData = JSON.parse(res);

      this.duplicateSource = parsedData.returnedData;
      console.log(this.duplicateSource);
      
      //this.dataSource.replaceAll("BUILD#","BUILD");

      this.dataSource = new MatTableDataSource(this.duplicateSource);

      this.dataSource.paginator = this.paginator;
    });

    // this.dataSource = new MatTableDataSource(this.duplicateSource);
    // this.dataSource.paginator = this.paginator;
  }

  getBuildingById() {}

  // duplicateSource:any =[
  //   {
  //     no:'15',
  //     name: 'db1',
  //     address:'ad1',
  //     zip:' 74125 ',

  //   },
  //   {
  //     no:'16',
  //     name:' update test',
  //     address:' updated address',
  //     zip:'43026',
  //   }
  // ]

  closeDialog() {
    // this.dialogRef.close();
  }
  updateForm(element: any) {
    this.matDialog.open(UpdateBuildingComponent, {
      width: '7000px',
      maxHeight: '120vh',
      data: element,
      autoFocus: false,
    });
  }

  addBuilding() {
    this.matDialog.open(CreateBuildingComponent, {
      width: '7000px',
      maxHeight: '120vh',
      autoFocus: false,
    });
  }

  deleteForm(building: any) {
    this.matDialog.open(DeleteBuildingComponent, {
      width: '7000px',
      maxHeight: '120vh',
      data: building,
      autoFocus: false,
    });
  }

  doorForm() {
    this.matDialog.open(DoorsComponent, {
      width: '1250px',
      maxHeight: '355vh',
      autoFocus: false,
    });
  }
  buildingContact() {
    this.matDialog.open(BuildingContactComponent, {
      width: '1250px',
      maxHeight: '355vh',
      autoFocus: false,
    });
  }
}
