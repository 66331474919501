import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-serial-number',
  templateUrl: './edit-serial-number.component.html',
  styleUrls: ['./edit-serial-number.component.css'],
})
export class EditSerialNumberComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditSerialNumberComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      KNDOCN: 123455239,
      KNLINE: 1,
      ODITEM: 1234,
      PD: 123456,
      ALT: 12345,
      ProductSerialNumber: 56687,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }
}
