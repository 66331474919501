import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-labor-plan-details',
  templateUrl: './ww-labor-plan-details.component.html',
  styleUrls: ['./ww-labor-plan-details.component.css']
})
export class WwLaborPlanDetailsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'ProcessTask',
    'ReleaseDate',
    'ReleaseTime',
    'CompletedDate',
    'CompletedTime',
    'OR',
    'ST',
    'Operator'
    // 'Opt'
  ];

  dataSource: any = []

  duplicateSource: any = [
    {
      ProcessTask: 'VSTAGE',
      MoveType: '4790321',
      ReleaseDate: '08/27/19',
      ReleaseTime: '11:30:17',
      CompletedDate: '',
      CompletedTime: '',
      Operator: '',
      OR : 'Y',
      ST : 'AC',


    },


  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwLaborPlanDetailsComponent>
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      Document: '',
      // MustStartDate: '',
      // Building: '',
      // Status: 'OP',
      // Storer: '',
      // AppointmentDate: '',
      // Active: '',
      // SCAC: '',
      // Operator: '',
      // Manual_Auto: 'A'

    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
