<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title fxLayoutAlign="center">Maintain Periods </mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()" class="closeIcon">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" fxLayoutAlign="center">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="PESTOR" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Period Start Date</mat-label>
            <input type="date" matInput formControlName="STDT" required/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Period End Date</mat-label>
            <input type="date" matInput formControlName="ENDT" required/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Balance Written </mat-label>
            <mat-select formControlName="PEBALN">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Storage Invoice Number</mat-label>
            <input matInput formControlName="PEINV" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="PESTAT">
              <mat-option value="AV">Available</mat-option>
              <mat-option value="CL">Closed</mat-option>
            </mat-select>
          </mat-form-field>

          <div class="row">
            <div class="col">
              <button
                class="buttons addButton"
                mat-raised-button
                (click)="updatePeriod()"
              >
                <span>Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-footer> </mat-card-footer>
</mat-card>
