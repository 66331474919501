<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Orders Create</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer Number</mat-label>
            <input
              required
              matInput
              formControlName="storer"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Suffix</mat-label>
            <input
              matInput
              formControlName="suffix"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Storer Reference </mat-label>
            <input
              matInput
              formControlName="storerRef"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Purchase Order </mat-label>
            <input
              matInput
              formControlName="purchaseOrder"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Consignee Number </mat-label>
            <input
              required
              matInput
              formControlName="consignee"
              style="text-transform: uppercase"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="btn-text-right">
        <button mat-button class="button addButton" (click)="orderCreate1()">
          Next
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
