<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Inventory Synopsis</mat-card-title>
</mat-card-header>

<mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Book">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Book
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Book }}</td>
        </ng-container>

        <ng-container matColumnDef="Number">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Number
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Number }}</td>
          </ng-container>

          <ng-container matColumnDef="Quantity">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Quantity
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Quantity }}</td>
          </ng-container>


  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>

      <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8">
        <ng-container matColumnDef="Count">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Count
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Count }}</td>
        </ng-container>

        <ng-container matColumnDef="Number">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Number
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Number }}</td>
          </ng-container>

          <ng-container matColumnDef="Pct1">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Pct1
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Pct1 }}</td>
          </ng-container>
          

          <ng-container matColumnDef="Quantity">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Quantity
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Quantity }}</td>
          </ng-container>
  
     

          <ng-container matColumnDef="Pct2">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Pct
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Pct2 }}</td>
          </ng-container>

  
        <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns1" ></tr>
  
        
      </table>
</mat-card-content>
