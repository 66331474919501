import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../iheader.service';
import { IitemService } from '../../item/iitem.service';

@Component({
  selector: 'app-maintain',
  templateUrl: './maintain.component.html',
  styleUrls: ['./maintain.component.css'],
})
export class MaintainComponent implements OnInit {
  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    public itemService:IitemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<MaintainComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    
    
  ) {}
  form!: FormGroup;
  result: any;
 // user = GlobalComponent.user;
  tally:any;
  user:any;
  building:any;
  storer:any;
  itemOptions: string[] = [];
  duplicateSource:any;

  ngOnInit(): void {

    this.tally=this.data.IHTALY;
    this.storer=this.data.IHSTOR;
    console.log(this.data,'88888888');
    if(localStorage.getItem('LoggedIn')){
      this.user = localStorage.getItem('userName')
      this.building = localStorage.getItem('building')
      this.storer = localStorage.getItem('storer')
  }else{
    this.user= GlobalComponent.user;
    this.building=GlobalComponent.building;
  }
    this.form = this.fb.group({

      // quantity: [
      //   '',
      //   [
      //     Validators.required,
      //     Validators.min(0),
      //     Validators.pattern('[0-9]{1,10}'),
      //   ],
      // ],
      // item: [null],
      // lotCode1: [null],
      // lotCode2: [null],
      // lotCode3: [null],
      // rateType: [null],
      // dmgReas: [null],
      // overrideWeight: [
      //   '',
      //   [
      //     Validators.required,
      //     Validators.min(0),
      //     Validators.pattern('[0-9]{1,10}'),
      //   ],
      // ],

      quantity:'',
      items: '',
      lotCode1: '',
      lotCode2: '',
      lotCode3: '',
      rateType: '',
      dmgReas: '',
      overrideWeight: ''
    });


    this.itemService.getItemBySearch(this.user,
      this.data.IHSTOR,
      '',
      ''
    ).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('SINGLE ITEM DATA');
      console.log(this.duplicateSource);

      for (const item of parsedData.returnedData) {
        this.itemOptions.push(item.ITEM);
      }  
      
    });



  }
 
  addTallyDetail() {
    var res = this.form.value;
    
   
    this.result = this.iHeaderSvc
      .createTallyDetail( 
        
        this.tally,
        this.user,
       this.storer,
       this.building,
        res.quantity,
        res.items,
       res.lotCode1,
        res.lotCode2,
        res.lotCode3,
        res.rateType,
        res.dmgReas,
        res.overrideWeight
        

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

        if(JSON.parse(this.result[0].data[1].value).sucess){
          //alert('Record Created SuccessFully');
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this.closeDialog();
          this.router.navigate(['/main-nav/tallys/details']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/tallys/details']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
