<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
          <button mat-button (click)="backstorer()">
              <mat-icon></mat-icon>
              
          </button>
          <mat-card-title style="color: rgb(9, 10, 11)"
                    >Delete Metric Constraint</mat-card-title>
                  
        </mat-card-header>
        <mat-card-content> 
                        
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Set </mat-label> 
                    <input matInput formControlName="CNSTRSET" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">  <mat-label>Description</mat-label> 
                    <input matInput formControlName="DESCRIPT" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">  <mat-label>Status</mat-label> 
                    <input matInput formControlName="STATUS" />
                  </mat-form-field>
           </div>
                              <div >
                                <button mat-button class="buttonCss" (click)="attributes()">Delete</button>
                            </div>
      
        </mat-card-content>
    </mat-card>
   </form>  



