import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ItemCommentDeleteComponent } from './item-comment-delete/item-comment-delete.component';
import { ItemCommentUpdateComponent } from './item-comment-update/item-comment-update.component';
import { ItemCommentCreateComponent } from './item-comment-create/item-comment-create.component';
import { MatTableDataSource } from '@angular/material/table';
import { GlobalComponent } from 'src/app/Global-Component';
import { MatPaginator } from '@angular/material/paginator';
import { IitemService } from '../iitem.service';

interface CustomColumn {
  possition: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-item-comments',
  templateUrl: './item-comments.component.html',
  styleUrls: ['./item-comments.component.css'],
})
export class ItemCommentsComponent implements OnInit {

  constructor(
    private router: Router,
    public itemService: IitemService,
    private fb: FormBuilder,
    private matDialog: MatDialog
  ) {}

  displayedColumns: string[] = [
    'ID',
    'Key',
    'Tp',
    'Seq',
    'Comment',
    'Actions'
  ];


  @ViewChild('paginator') paginator!: MatPaginator;
  selectedRow: any;
  editedRows: any;
  GlobalComponent:any=GlobalComponent;
   searchText: any;
   fullStorerAndItem:any;
  result: any = [];
  dataSource :any;
  duplicateSource = [];
  singleTallyData = [];
  documents = [];
  user = localStorage.getItem('userName');
  building = GlobalComponent.building;
  tally: any;
  searchValue: string = '';
  condition: boolean = true;
  prevText: string = '';
  element:any;  
  TallyHeaders: any = [];
  id: number = 0;
  public columnShowHideList: CustomColumn[] = [];
  position: any;
  name: any;
  isActive: any;
  searchTextDisplay: any = false;
  form!: FormGroup;


  
  ngOnInit(): void {

    this.element=history.state;

    const prefixWithZeros = (number: any, length: number) => String(number).padStart(length, '0');
     
    console.log('FULL STORER:')
    console.log(prefixWithZeros(this.element.ITSTOR,4));
    this.fullStorerAndItem = prefixWithZeros(this.element.ITSTOR,4) + '  ' + this.element.ITEM;
    
    this.form = this.fb.group({ 
      storerAndItem:this.element.ITSTOR + '  ' + this.element.ITEM,
      fileId: 'IT',
      key:this.fullStorerAndItem,
      subType:'',
      sequence:''
     
    });


    this.initializeColumnProperties();
    this.editedRows = [];

    
      var res = this.form.value;
    this.itemService.readItemCommentService(this.user,this.fullStorerAndItem , res.subType, res.sequence)
      .subscribe((data:any) => {
        this.result = data; 
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('ALL ITEM COMMENTS DATA');
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }
 

  toggleColumn(column: any) {
    if (column.isActive) {
      if (column.possition > this.displayedColumns.length - 1) {
        this.displayedColumns.push(column.name);
      } else {
        this.displayedColumns.splice(column.possition, 0, column.name);
      }
    } else {
      let i = this.displayedColumns.indexOf(column.name);
      let opr = i > -1 ? this.displayedColumns.splice(i, 1) : undefined;
    }
  }


  initializeColumnProperties() {
    this.displayedColumns.forEach((element, index) => {
      if (element != 'Actions' && element != 'Carrier') {
        this.columnShowHideList.push({
          possition: index,
          name: element,
          isActive: true,
        });
      }
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length,
      name: 'LastMaintained',
      isActive: false,
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length - 1,
      name: 'By',
      isActive: false,
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length - 1,
      name: 'Job',
      isActive: false,
    });

  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }

  
  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }
  
  searchItemComment(storeContact:any){

    const prefixWithZeros = (number: any, length: number) => String(number).padStart(length, '0');
    var res = this.form.value;
    this.TallyHeaders = this.itemService.readItemCommentService(this.user, this.fullStorerAndItem, res.subType, '')
      .subscribe((data:any) => {
        this.result = data; 
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('ITEM COMMENTS DATA BY SEARCH:');
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });

  }

    itemCommentCreate(){
      var res = this.form.value;
      this.matDialog.open(ItemCommentCreateComponent, {
        "width": '1000px',
      "maxHeight": '80vh',  
      "data": res,
      "autoFocus": false
    });
    
  }

  itemCommentUpdate(element: any){

    this.matDialog.open(ItemCommentUpdateComponent, {
      "width": '1000px',
    "maxHeight": '80vh',
    "data": element,
    "autoFocus": false
  });
  }

  itemCommentDelete(element: any){

    this.matDialog.open(ItemCommentDeleteComponent, {
      "width": '1000px',
    "maxHeight": '80vh',
    "data": element,
    "autoFocus": false
  });
  }

  onBack() {
    this.router.navigate(['/main-nav/item/read']);
  }
}
