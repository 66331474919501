import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { OrderDetailsService } from '../order-details.service';


@Component({
  selector: 'app-order-detail-cross-doc-ref',
  templateUrl: './order-detail-cross-doc-ref.component.html',
  styleUrls: ['./order-detail-cross-doc-ref.component.css']
})

export class OrderDetailCrossDocRefComponent implements OnInit {

  constructor(
    public orderDetailsSrv : OrderDetailsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private router: Router,
    private dialogRef: MatDialogRef<OrderDetailCrossDocRefComponent>
  ) { }

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  bol: any;
  seq: any;
  tallyNo:any;
  duplicateSource: any;

  ngOnInit(): void {
    this.bol= this.anyvariable.ODBILL;
    this.seq= this.anyvariable.ODSEQ;

    this.orderDetailsSrv.readXDocRef(this.user, this.bol, this.seq, '').subscribe((data) => {
      this.result = data;    
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log("ORD DTL CROSS DOC REF DATA");
      console.log(this.duplicateSource);
      this.tallyNo = this.duplicateSource[0].XDTALY;
    });

    this.form = this.fb.group({
      tally:''       
    });
  }

  createXDocRef(){        
    var res = this.form.value;
    this.result = this.orderDetailsSrv.createXDocRefService(
      this.bol,
      this.seq,
      res.tally,
      this.user      
    ).subscribe((data: any) => {
      this.result = data;
      var length = this.result[0].data.length;
      if (JSON.parse(this.result[0].data[1].value).success) {
        const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg);
        this.closeDialog();
        this.router.navigate(['/main-nav/orders/details']);
      } else {
        const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
      }
    });
  }

  updateXDocRef(){
    var res = this.form.value;
    this.result = this.orderDetailsSrv.updateXDocRefService(
      this.bol,
      this.seq,
      res.tally,
      this.user      
    ).subscribe((data: any) => {
      this.result = data;
      var length = this.result[0].data.length;
      if (JSON.parse(this.result[0].data[1].value).success) {
        const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg);
        this.closeDialog();
        this.router.navigate(['/main-nav/orders/details']);
      } else {
        const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
      }
    });
  }

  deleteXDocRef(){
    var res = this.form.value;    
    this.result = this.orderDetailsSrv.deleteXDocRef(this.user,this.bol,this.seq).subscribe((data: any) => {
      this.result = data;
      var length = this.result[0].data.length;
      console.log(this.result);
      
      if (JSON.parse(this.result[0].data[1].value).success) {
        const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg);
        this.closeDialog();
        this.router.navigate(['/main-nav/orders/details']);
      } else {
        const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
      }
    });    
  }


  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/details']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  changeShipForm(){
    
  }

  closeDialog(){
    this.dialogRef.close();
  }
  
}
