<mat-card>

  <div class="main-header-title">
      <mat-card-header>
          <mat-card-title fxLayoutAlign="center">Work with Accounting Attributes</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" fxLayoutAlign="center">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Customer</mat-label>
                    
                    <input matInput formControlName="Customer" />
                </mat-form-field>
                
            </div>          
        </div>     
      </form>
  </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="Attribute">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Attribute
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Attribute }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Attribute Value">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Attribute Value
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Value }}</td>
          </ng-container>

        <ng-container matColumnDef="Action">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>

</mat-card>