import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { ExtractReportSortClausesAddComponent } from './extract-report-sort-clauses-add/extract-report-sort-clauses-add.component';
import { ExtractReportSortClausesDeleteComponent } from './extract-report-sort-clauses-delete/extract-report-sort-clauses-delete.component';
import { ExtractReportSortClausesUpdateComponent } from './extract-report-sort-clauses-update/extract-report-sort-clauses-update.component';

@Component({
  selector: 'app-extract-report-sort-clauses',
  templateUrl: './extract-report-sort-clauses.component.html',
  styleUrls: ['./extract-report-sort-clauses.component.css']
})
export class ExtractReportSortClausesComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'ExtractName',
    'ExtractFile',
    'OrderBy',
    'GroupBy',
    'Actions'
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      ExtractName:'ITEMAGE',
      ExtractFile:'ITEMST',
      OrderBy:'ITSTOR,ITEM',
      GroupBy:''
    },
    {
      ExtractName:'ITEMBAL',
      ExtractFile:'ITEMST',
      OrderBy:'ITSTOR,ITEM',
      GroupBy:''
    },

  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      ExtractName:'',
      ExtractFile:'',
      OrderBy:'',
      GroupBy:''

    })

    this.dataSource = this.duplicateSource;
  }

  addExtractReportSort()
  {
   this.matDialog.open(ExtractReportSortClausesAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
  UpdateExtractReportSort()
  {
   this.matDialog.open(ExtractReportSortClausesUpdateComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
  deleteExtractReportSort()
  {
   this.matDialog.open(ExtractReportSortClausesDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

}
