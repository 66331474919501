import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { ScheduleExceptionAddComponent } from './schedule-exception-add/schedule-exception-add.component';
import { ScheduleExceptionChangeComponent } from './schedule-exception-change/schedule-exception-change.component';
import { ScheduleExceptionDeleteComponent } from './schedule-exception-delete/schedule-exception-delete.component';

@Component({
  selector: 'app-ww-schedule-exceptions',
  templateUrl: './ww-schedule-exceptions.component.html',
  styleUrls: ['./ww-schedule-exceptions.component.css']
})
export class WwScheduleExceptionsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'type',
    'build',
    'account',
    'exceptionDate',
    'inOut',
    'inclOmit',
    'startTime',
    'endTime',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      type:'APPT',
      build:100,
      account:'',
      exceptionDate:'02/10/23',
      inOut:'',
      inclOmit:'I',
      startTime:'8:00',
      endTime:'12:00',

    },
    {
      type:'APPT',
      build:100,
      account:'',
      exceptionDate:'07/4/23',
      inOut:'',
      inclOmit:'O',
      startTime:'01:00',
      endTime:'23:59',
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      TYPE:'',
      BLDG:'',
      ACCT:''
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  add(){
    this.matDialog.open(ScheduleExceptionAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  change(element:any){
    this.matDialog.open(ScheduleExceptionChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  delete(element:any){
    this.matDialog.open(ScheduleExceptionDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

}
