import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { CustomerRatingPrefAddComponent } from './customer-rating-pref-add/customer-rating-pref-add.component';
import { CustomerRatingPrefChangeComponent } from './customer-rating-pref-change/customer-rating-pref-change.component';

@Component({
  selector: 'app-ww-customer-rating-preference',
  templateUrl: './ww-customer-rating-preference.component.html',
  styleUrls: ['./ww-customer-rating-preference.component.css']
})
export class WwCustomerRatingPreferenceComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  displayedColumns: string[] = [
    'customerNumber',
    'sT',
    'tariffName',
    'sCACCode',
    'disc',
    'interstateMinimum',
    'perMile',
    'flatRate',
    'ST',
    'opt',
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      customerNumber:'BASE' ,
      sT:'KS',
      tariffName:'TRUCKLOAD',
      sCACCode:'BAXS',
      disc:'',
      interstateMinimum:'',
      perMile:'',
      flatRate:'128.00  ',
      ST:'AC',


      
      
    },
    {
      customerNumber:'BASE' ,
      sT:'MO',
      tariffName:'TRUCKLOAD',
      sCACCode:'EXLA',
      disc:'',
      interstateMinimum:'',
      perMile:'.3000',
      flatRate:'  ',
      ST:'AC',


      
      
    },

  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    // private dialogRef: MatDialogRef<AttributesComponent>
    
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      customerNumber:'',
    
      

    
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
 
 }



   PreferencesChangeForm(element:any){
    this.matDialog.open(CustomerRatingPrefChangeComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });
  }


 
  PreferencesAddForm(element:any){
    this.matDialog.open(CustomerRatingPrefAddComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });
  }
 
 

addAttribute(){

}
}
