import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-bill-text-freight-code',
  templateUrl: './bill-text-freight-code.component.html',
  styleUrls: ['./bill-text-freight-code.component.css'],
})
export class BillTextFreightCodeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<BillTextFreightCodeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      fileID: 'FR',
      Code: '0005',
      Sub: 'BL',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
