<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title fxLayoutAlign="center">Work with UCC Label Control File</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
          <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
    
    <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" fxLayoutAlign="center">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="Storer" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Consignee</mat-label>
                    <input matInput formControlName="Consignee" />
                </mat-form-field>
                
            </div>
        </div>     
    </form>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="Consignee">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Consignee
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Consignee }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Form">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Form
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Form }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Lbl Typ">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Lbl Typ
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LblTyp }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Message File">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Message File
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.MessageFile }}</td>
        </ng-container>

        <ng-container matColumnDef="Validity Program">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Validity Program
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.ValidityProgram }}</td>
          </ng-container>

          <ng-container matColumnDef="Label Name">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Label Name
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LabelName }}</td>
          </ng-container>

          <ng-container matColumnDef="Output Queue">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Output Queue
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.OutputQueue }}</td>
          </ng-container>
  
        <ng-container matColumnDef="Action">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          
        
  
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>

  </mat-card-content>
</mat-card>