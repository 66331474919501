import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { PeriodsService } from '../periods.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-periods-add',
  templateUrl: './periods-add.component.html',
  styleUrls: ['./periods-add.component.css']
})
export class PeriodsAddComponent implements OnInit {

  constructor(
    public periodSvc: PeriodsService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<PeriodsAddComponent>
  ) { }

  form!: FormGroup;
  user=localStorage.getItem('userName');
  result:any;

  ngOnInit(): void {
    this.form= this.fb.group({
      PESTOR:this.anyvariable.STOR, //storer
      STDT:'', //periodStartDate
      ENDT:'', //period end date
      PEBALN:'', // balance written
      PEINV:'0', // storage invoice  number
      PESTAT:'' //status
    })
  }

  createPeriod() {
    var res = this.form.value;
    console.log(res);

    this.result = this.periodSvc
      .createPeriod(
        res.PESTOR,
        res.STDT,
        res.ENDT,
        res.PEBALN,
        res.PEINV,
        res.PESTAT,
       this.user)
      .subscribe((data: any) => {
        this.result = data;
       if(JSON.parse(this.result[0].data[1].value).success){
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg);
        this. closeDialog();
        this.router.navigate(['/main-nav/storer']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/storer']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
