import { DeleteitemlocationComponent } from './deleteitemlocation/deleteitemlocation.component';
import { SideAdjustmentComponent } from './side-adjustment/side-adjustment.component';
import { CreateitemLocationComponent } from './createitem-location/createitem-location.component';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TwoSideAdjustmentComponent } from './two-side-adjustment/two-side-adjustment.component';
import { ReSequenceComponent } from './re-sequence/re-sequence.component';
import { IItemLocationService } from './i-item-location.service';
import { StorerServiceService } from '../../storer/storer-service.service';
import { TwosidedAdjBucketToBucketComponent } from './twosided-adj-bucket-to-bucket/twosided-adj-bucket-to-bucket.component';
import { GlobalComponent } from 'src/app/Global-Component';

@Component({
  selector: 'app-item-locations',
  templateUrl: './item-locations.component.html',
  styleUrls: ['./item-locations.component.css'],
})
export class ItemLocationsComponent implements OnInit {
  GlobalComponent:any=GlobalComponent;
  form!: FormGroup;
  element : any;
  displayedColumns: string[] = [
    'BldNbr',
    'Floor',
    'Aisle',
    'Slot',
    'LotCode1',
    'FirstReceived',
    'TotalCapacity',
    'OnHand',
    'AddlCapacity',
    'Actions',
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    public iItemLcnSvc: IItemLocationService,
    public storerSvc: StorerServiceService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
  ) {
    this.element = history.state;
    this.form = this.fb.group({
      storer: fb.control(this.element.ITSTOR),
      item: fb.control(
        this.element.ITEM + '\xa0' + this.element.ITDSC1
      ),
      building: '',
      floor:'',
      aisle:'',
      slot:'',
      LOT1: '',
      LOT2: '',
      LOT3: '',
    });
  }
  @ViewChild('paginator') paginator!: MatPaginator;
  dataSource: any;
  duplicateSource: any;
  result: any;
  searchTextDisplay: any = false;
  
  user = localStorage.getItem('userName');

  ngOnInit(): void {
    console.log(this.element.ITSTOR);
    
    // this.storerSvc.getAll(this.user, this.element.ITSTOR).subscribe((data) => {
    //     this.result = data;
    //     var res = this.result[0].data[1]?.value;
    //     var parsedData = JSON.parse(res);
    //     this.duplicateSource = parsedData.returnedData;
    //     console.log("STORER DATA");
    //     console.log(this.duplicateSource);

    //     this.form.reset({
    //       storer:
    //       this.element.ITSTOR + ' ' + this.duplicateSource[0].STNAME,
    //       item: this.element.ITEM + '\xa0' + this.element.ITDSC1,
    //       building: '',
    //       floor:'',
    //       aisle:'',
    //       slot:'',
    //       LOT1: '',
    //       LOT2: '',
    //       LOT3: '',
    //     });
     // });

    var building = GlobalComponent.fullBuilding(this.element.ITBLDG);

    this.iItemLcnSvc.getItemLocation(
        this.user,
        building,
        this.element.ITSTOR,
        this.element.ITEM
      ).subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log("ITEM LOCATIONS DATA");
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  getItemLocationBySearch(element:any){
    this.iItemLcnSvc.getItemLocationBySearch(this.user,element.building,element.floor,element.aisle,element.slot,this.element.ITEM,element.storer,element.LOT1,element.LOT2,element.LOT3).subscribe((data)=>{
      this.result = data;
      console.log(this.result);
      
      var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log("ITEM LOCATIONS DATA");
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
    })
  }

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }
  itemLocationCreate() {
    var res = this.element;
    this.matDialog.open(CreateitemLocationComponent, {
      width: '1200px',
      maxHeight: '95vh',
      data: res,
      autoFocus: false,
    });
  }
  itemLocationDelete(element: any) {
    element.desc = this.element.ITDSC1;

    this.matDialog.open(DeleteitemlocationComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }
  oneSideAdj(location: any) {
    var itemAndLocData = [this.element, location];
    this.matDialog.open(SideAdjustmentComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: itemAndLocData,
      autoFocus: false,
    });
  }
  twoSideAdj(location: any) {
    var itemAndLocData = [this.element, location];
    this.matDialog.open(TwoSideAdjustmentComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: itemAndLocData,
      autoFocus: false,
    });
  }
  bucketToBucket(location: any) {
    var itemAndLocData = [this.element, location]
    this.matDialog.open(TwosidedAdjBucketToBucketComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: itemAndLocData,
      autoFocus: false,
    });
  }
  itemResequence(element: any) {
    this.matDialog.open(ReSequenceComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }
  
}
