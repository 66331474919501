import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
@Component({
  selector: 'app-schedule-exception-change',
  templateUrl: './schedule-exception-change.component.html',
  styleUrls: ['./schedule-exception-change.component.css']
})
export class ScheduleExceptionChangeComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ScheduleExceptionChangeComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      HETYPE:this.anyvariable.type,
      HEBLDG:this.anyvariable.build,
      HEACCT:this.anyvariable.account,
      DATE:this.anyvariable.exceptionDate,
      INOU:this.anyvariable.inOut,
      HEINOM:this.anyvariable.inclOmit,
      HESTRT:this.anyvariable.startTime,
      HEENDT:this.anyvariable.endTime,
      HEDESC:''
    })
  }

  change(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
