<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>Delete Consignee Contacts</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form" fxLayoutAlign="center">
            <div class="row">
                <div class="col">
                    <mat-form-field  appearance="outline">
                        <mat-label> Consignee Code : </mat-label> 
                        <input matInput formControlName="Consignee" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Contact Name : </mat-label> 
                        <input matInput formControlName="contactName" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Contact Type : </mat-label> 
                        <input matInput formControlName="contactType" readonly/>
                    </mat-form-field>
                
                    <mat-form-field  appearance="outline">
                        <mat-label> Telephone Number : </mat-label> 
                        <input matInput formControlName="telePhone" readonly/>
                    </mat-form-field>
                    <mat-form-field  appearance="outline">
                        <mat-label>Cellphone Number : </mat-label> 
                        <input matInput formControlName="cellPhone" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label>Fax Number : </mat-label> 
                        <input matInput formControlName="faxNumber" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label>Email Address : </mat-label> 
                        <input matInput formControlName="emailAddress" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Status Code </mat-label> 
                        <input matInput formControlName="statusCode" readonly/>
                    </mat-form-field>
                    
                    <div class="row">
                    <div class="col">
                        <button mat-button (click)="deleteStorerContact()" class="buttons removeButton">    
                            <span>Delete</span>
                        </button>
                    </div>
                    </div>
                </div>
            </div>

        </form>
    </mat-card-content>
</mat-card>

