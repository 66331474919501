<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Move History Inquiry</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Account</mat-label>
                    <input matInput formControlName="Account" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Document</mat-label>
                    <input matInput formControlName="Document" />
                </mat-form-field>
                
             

                <mat-form-field appearance="outline">
                    <mat-label>Sort</mat-label>
                    <input matInput formControlName="Sort" />
                </mat-form-field>

            </div>

            <div class="col">
             

                <mat-form-field appearance="outline">
                    <mat-label>PendingMoves</mat-label>
                    <input matInput formControlName="PendingMoves" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>CompletedMoves</mat-label>
                    <input matInput formControlName="CompletedMoves" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>TransactionType</mat-label>
                    <input matInput formControlName="TransactionType" />
                </mat-form-field>

               
            </div>

        </div>
     
    </form>
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Move Number">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Move Nmbr
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.MoveNumber }}</td>
          </ng-container>

          <ng-container matColumnDef="Item">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Item
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Item }}</td>
          </ng-container>

          <ng-container matColumnDef="Lot">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Lot
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Lot }}</td>
          </ng-container>

          <ng-container matColumnDef="Type">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Type
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Type }}</td>
          </ng-container>

          <ng-container matColumnDef="Sub">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Sub
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Sub }}</td>
          </ng-container>

          <ng-container matColumnDef="Quantity">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Quantity
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Quantity }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>

    </table>

    <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
</mat-card-content>
