<mat-card class="over-Flow">

    <div class="main-header-title">    
      <mat-card-header>
        <mat-card-title>Maintain Putaway Locations</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

      <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
          <div class="row">
              <div class="col">
                
                  <mat-form-field  appearance="outline">
                      <mat-label> Floor: </mat-label> 
                      <input matInput formControlName="floor" style="text-transform: uppercase"/>
                  </mat-form-field>

                  <mat-form-field  appearance="outline">
                    <mat-label> Aisle: </mat-label> 
                    <input matInput formControlName="aisle" style="text-transform: uppercase"/>
                  </mat-form-field>

                  <mat-form-field  appearance="outline">
                    <mat-label> Slot: </mat-label> 
                    <input matInput formControlName="slot" style="text-transform: uppercase"/>
                  </mat-form-field>
             
                   <mat-form-field appearance="outline" >   
                      <mat-label> Quantity Available: </mat-label>
                      <input matInput formControlName="quantityAvailable"/>
                  </mat-form-field>
             
                  <mat-form-field appearance="outline" >
                      <mat-label> Damaged:</mat-label>
                      <input matInput formControlName="damaged"/>
                  </mat-form-field>

                  <mat-form-field appearance="outline" >
                    <mat-label> Held:</mat-label>
                    <input matInput formControlName="held"/>
                </mat-form-field>
                
                <div style = "margin-left:20px">
                  <b>Quantity</b>
                </div>
                   <mat-form-field appearance="outline" >
                      <mat-label> User:</mat-label>
                      <input matInput formControlName="user1"/>
                  </mat-form-field>
                            
                  <mat-form-field appearance="outline" >
                    <mat-label> User 2:</mat-label>
                    <input matInput formControlName="user2"/>
                </mat-form-field>

                <mat-form-field appearance="outline" >
                    <mat-label> User 3:</mat-label>
                    <input matInput formControlName="user3"/>
                </mat-form-field>

                <mat-form-field appearance="outline" >
                    <mat-label> User 4:</mat-label>
                    <input matInput formControlName="user4"/>
                </mat-form-field>

            </div>
          </div>
            <button mat-button (click)="createPutawayLoc()" class="addButton buttons">
              <span>Create</span>
            </button>
          </form>
      </mat-card-content>
</mat-card>
   


