import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { ItemUpdate3Component } from '../item-update3/item-update3.component';
import Swal from 'sweetalert2';
import { IitemService } from '../iitem.service';

@Component({
  selector: 'app-item-create2',
  templateUrl: './item-update2.component.html',
  styleUrls: ['./item-update2.component.css'],
})
export class ItemUpdate2Component implements OnInit {
  form!: FormGroup;
  result: any;

  constructor(
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    public itemSvc: IitemService,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemUpdate2Component>,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({

      storeNumber: this.anyvariable.ITSTOR,
      suffix: this.anyvariable.ITSSFX,
      item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,

      unitGrossWeight:this.anyvariable.ITGRSW,
      unitWeightType:this.anyvariable.ITWTYP,
      casesPerTier: this.anyvariable.ITCSTR,
      tiersPerPallet:this.anyvariable.ITTRPL,
      unitSquareFeet: this.anyvariable.ITSQFT,
      unitCubeFeet: this.anyvariable.ITCBFT, 
      unitInchesHigh: this.anyvariable.ITINHI,
      unitInchesWide:this.anyvariable.ITINWD, 
      unitInchesDeep: this.anyvariable.ITINDP, 
      unitOfMeasure1:this.anyvariable.ITPUNT,
      unitOfMeasure2:this.anyvariable.ITUNM2,
      qtyPer2UnitOfMultiplier:this.anyvariable.ITUNQ2,
      unitOfMeasure3: this.anyvariable.ITUNM3, 
      qtyPer3UnitOfMultiplier: this.anyvariable.ITUNQ3,
      unitOfMeasure4:this.anyvariable.ITUNM4,
      qtyPer4UnitOfMultiplier:this.anyvariable.ITUNQ4,
      inventoryUnit: this.anyvariable.ITVNUM,
      shippingUnit:this.anyvariable.ITSIUM,
    });
  }

  itemUpdate2(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemServiceUpdate2( 
      res.storeNumber,
      this.anyvariable.ITSSFX,
      this.anyvariable.ITEM,
        res.unitGrossWeight,
        res.unitWeightType,
        res.casesPerTier,
        res.tiersPerPallet,
        res.unitSquareFeet,
        res.unitCubeFeet,
        res.unitInchesHigh,
        res.unitInchesWide,
        res.unitInchesDeep,
        res.unitOfMeasure1,
        res.unitOfMeasure2,
        res.qtyPer2UnitOfMultiplier,
        res.unitOfMeasure3,
        res.qtyPer3UnitOfMultiplier,
        res.unitOfMeasure4,
        res.qtyPer4UnitOfMultiplier,
        res.inventoryUnit,
        res.shippingUnit

        

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       console.log(this.result[0].data.length);
          console.log("*********************");

       console.log(this.result[0].data[23].value);
       console.log("*********************");

       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Created/Updated'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this. update3();
         // this.router.navigate(['/main-nav/tallys/read']);
         this.router.navigate(['/main-nav/item/read']);
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
    this.router.navigate(['/main-nav/item/read']);
  }


  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  update3() {
    var res = this.anyvariable;
    this.matDialog.open(ItemUpdate3Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

  
}
