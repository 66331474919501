<mat-card class="over-Flow"> 

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain A Lot</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
  
      <mat-card-content  class="tally-serach-form">
        <form [formGroup]="form">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Storer </mat-label>
              <input readonly matInput formControlName="storer" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label> Suffix </mat-label>
              <input readonly matInput formControlName="suffix" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label> Item Code</mat-label>
              <input readonly matInput formControlName="item" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Lot 1</mat-label>
              <input matInput formControlName="lot1" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Lot 2</mat-label>
              <input matInput formControlName="lot2" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Lot 3</mat-label>
              <input matInput formControlName="lot3" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Lot Unit Weight</mat-label>
              <input matInput formControlName="lotUnitWeight" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Lot First Received</mat-label>
              <input matInput formControlName="lotFirstReceived" />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Lot Last Shipped</mat-label>
                <input matInput formControlName="lotLastShipped" />
              </mat-form-field>

          </div>
        </div>
        <button mat-button class="buttons addButton" (click)="updateItemLot()">
          <span>Update</span>
        </button>
      </form>
      </mat-card-content>
    </mat-card>
  
  