import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwLocaterInquiryComponent } from './ww-locater-inquiry/ww-locater-inquiry.component';
import { WwLocaterDeleteComponent } from './ww-locater-delete/ww-locater-delete.component';
import { WwLocaterResequenceComponent } from './ww-locater-resequence/ww-locater-resequence.component';
import { WwLocater1sAdjustComponent } from './ww-locater1s-adjust/ww-locater1s-adjust.component';
import { WwLocater2sAdjustBBComponent } from './ww-locater2s-adjust-bb/ww-locater2s-adjust-bb.component';
import { WwLocater2sAdjustNewItmComponent } from './ww-locater2s-adjust-new-itm/ww-locater2s-adjust-new-itm.component';
import { WwLocaterLoadSheduledFloorMoveComponent } from './ww-locater-load-sheduled-floor-move/ww-locater-load-sheduled-floor-move.component';
@Component({
  selector: 'app-ww-locater',
  templateUrl: './ww-locater.component.html',
  styleUrls: ['./ww-locater.component.css'],
})
export class WwLocaterComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Location',
    'Storer',
    'Item',
    'First Received',
    'Total Capacity',
    'On hand',
    'Actions',
  ];
  dataSource: any = [];

  duplicateSource: any = [
    {
      LOCAT: '1',
      LCSTOR: '5002',
      LCITEM: '244',
      LCRCVD: '04/16/18',
      TCAP: '8910',
      ONHND: '90',
    },
  ];

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      STOR: '',
      STRX: '',
      ITEM: '',
      LOCN: '',
      LOT1: '',
      LOT2: '',
      LOT3: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }

  // StorerAttributesCreateForm(){
  // this.matDialog.open(WwStorerAttributesCreateComponent, {
  //     "width": '1000px',
  //      "maxHeight": '90vh',
  //   //  'data': element,
  //    "autoFocus": false
  //   });
  //   }

  LocaterInquiryForm(element: any) {
    this.matDialog.open(WwLocaterInquiryComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }
  LocaterDeleteForm(element: any) {
    this.matDialog.open(WwLocaterDeleteComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }
  LocaterResequenceForm(element: any) {
    this.matDialog.open(WwLocaterResequenceComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }
  Locater1sAdjustForm(element: any) {
    this.matDialog.open(WwLocater1sAdjustComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }

  Locater2sAdjustBBForm(element: any) {
    this.matDialog.open(WwLocater2sAdjustBBComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }
  Locater2sAdjustNewItmForm(element: any) {
    this.matDialog.open(WwLocater2sAdjustNewItmComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }
  LocaterLoadScheduledForm(element: any) {
    this.matDialog.open(WwLocaterLoadSheduledFloorMoveComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }

  addAttribute() {}

  // df
}
