<!-- Form No : 225 Work with errors -->
<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Work With Balance Errors</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
    
    <mat-card-content class="tally-serach-form">
     <form [formGroup]="form">
        <div class="row" style="float:left;" >
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Storer</mat-label>
                   <input matInput formControlName="Storer"/>
               </mat-form-field>
               <mat-form-field appearance="outline">
                <mat-label>Item</mat-label>
                <input matInput formControlName="Item"/>
            </mat-form-field>
            
            </div>   
        </div>     
     </form>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
       <ng-container matColumnDef="StorerNumber">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            StorerNumber
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.StorerNumber}}</td>
         </ng-container>
         <ng-container matColumnDef="Item">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Item
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Item}}</td>
         </ng-container>
         <ng-container matColumnDef="Location">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Location
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Location}}</td>
         </ng-container>
         <ng-container matColumnDef="ErrorMessage">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            ErrorMessage
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.ErrorMessage}}</td>
         </ng-container>
         <ng-container matColumnDef="DateWritten">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            DateWritten
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.DateWritten}}</td>
         </ng-container>
         <ng-container matColumnDef="Opt">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Opt
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Opt}}</td>
         </ng-container>

        

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    </mat-card-content>   
</mat-card>