import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-ledger-code',
  templateUrl: './edit-ledger-code.component.html',
  styleUrls: ['./edit-ledger-code.component.css'],
})
export class EditLedgerCodeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditLedgerCodeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      LELEGC: 100,
      LELEGS: '',
      LEDESC: 'Handling Charges',
      LECC: 'H',
      LECSFX: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }
}
