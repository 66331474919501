import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { StorerServiceService } from '../../storer/storer-service.service';
@Component({
  selector: 'app-stock-enquiry',
  templateUrl: './stock-enquiry.component.html',
  styleUrls: ['./stock-enquiry.component.css'],
})
export class StockEnquiryComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'Location',
    'F',
    'LotCodes',
    'LotReceived',
    'Actual',
    'Avail',
    'Commit',
  ];

  dataSource: any = [];

  duplicateSource: any = [
    {
      LotCodes: 'Item Totals',
    },
  ];

  user = localStorage.getItem('user');
  result: any;
  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<StockEnquiryComponent>
  ) {
    this.form = this.fb.group({
      Storer: fb.control(''),
      Item: fb.control(''),
      UPC: fb.control(''),
      In_Transit: fb.control(''),
      FutureAllocated: fb.control(''),
      Lot1: '',
      Lot2: '',
      Lot3: '',
      Location: '',
    });
  }

  ngOnInit(): void {
    this.storerSvc
      .getAll(this.user, this.anyvariable.ITSTOR)
      .subscribe((data) => {
        this.result = data;
        // var res = this.result;
        var res = this.result[0].data[1]?.value;

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        this.form.reset({
          Storer:
            this.anyvariable.ITSTOR + ' ' + this.duplicateSource[0].STNAME,
          Item: this.anyvariable.ITEM + ' ' + this.anyvariable.ITDSC1,

          UPC: this.anyvariable.ITUPCC,
          In_Transit: this.anyvariable.ITCINT,
          FutureAllocated: this.anyvariable.ITCALL,
          Lot1: '',
          Lot2: '',
          Lot3: '',
          Location: '',
        });
      });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;

  }

  closeDialog() {
    this.dialogRef.close();
  }

  onBack() {
    this.router.navigate(['item/adjustment']);
  }
}
