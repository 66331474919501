<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain Exit Points</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Storer </mat-label>
            <input matInput formControlName="Storer" readonly />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Exit Point</mat-label>

            <input matInput formControlName="ExitPoint" readonly />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Choice </mat-label>

            <input matInput formControlName="Choice" />
          </mat-form-field>
        </div>
        </div>
          <button class="buttons addButton" mat-raised-button (click)="updateExitPoint()">
            Update
          </button>
        
      </form>
    </mat-card-content>
  </mat-card>

