<button mat-icon-button (click)="closeDialog()" class="closeIcon">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">
      Work With Receivable Details
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Selections</mat-label>
            <input matInput formControlName="selections" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Ledger</mat-label>
            <input matInput formControlName="ledger" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="St">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>St</th>
      <td mat-cell *matCellDef="let element">{{ element.IHTALY }}</td>
    </ng-container>

    <ng-container matColumnDef="InvoiceNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Invoice Number
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IHINVO }}</td>
    </ng-container>

    <ng-container matColumnDef="SeqNo">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Seq No.
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTOR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="BillToCustomer">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Bill-To Customer
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTRF }}
      </td>
    </ng-container>

    <ng-container matColumnDef="InvoiceDate">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Invoice Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHACTA }}
      </td>
    </ng-container>

    <ng-container matColumnDef="TransDate">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Trans Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSCHT }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Amount">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Amount
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTOR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="updateLedger(id)">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Update Ledger</mat-label>
            </button></mat-option
          >
          <mat-option>
            <button mat-icon-button (click)="adjustmentInquiry(id)">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Adjustment Inquiry</mat-label>
            </button></mat-option
          >
          <mat-option>
            <button mat-icon-button (click)="adjustmentTransactionInquiry(id)">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Transaction Inquiry</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
            #paginator
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
