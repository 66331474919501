import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { ConsigeeService } from '../consigee.service';

@Component({
  selector: 'app-consignee-create',
  templateUrl: './consignee-create.component.html',
  styleUrls: ['./consignee-create.component.css']
})
export class ConsigneeCreateComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
   // public iHeaderSvc: IheaderService,
public consigneeService : ConsigeeService,
   
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ConsigneeCreateComponent>,
    private router : Router
  ) { }

  result:any;
  
  user= localStorage.getItem('userName');

  ngOnInit(): void {
    this.form= this.fb.group({
      ConsigneeCode:'',  //CONSCD
      Name:'',        // CONAME
      address1:'', //COADD1
      address2:'', //COADD2
      address3:'', //COADD3
      address4:'', //COADD4
      ZipCode:'', //COZIP
      zipcodeSuffix:'', //COZSFX
      ZoneCode:'',  //COZONE
      areaCodeOfPhoneNumber:'', //COPHAR
      firstThreeDigOfPhNum:'', //COPHEX
      finalFourDigOfPhNum:'', //COPHNU
      DestinationCode:'', //CODEST
     
    })
  }

  addConsinee() {
    var res = this.form.value;
    console.log(res);

    this.result = this.consigneeService
      .createConsignee(
        res.ConsigneeCode,
        res.Name,
        res.address1,
        res.address2,
        res.address3,
        res.address4,
        res.ZipCode,
        res.zipcodeSuffix,
        res.ZoneCode,
        res.areaCodeOfPhoneNumber,
        res.firstThreeDigOfPhNum,
        res.finalFourDigOfPhNum,
        res.DestinationCode,
       this.user)
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       // var msg = JSON.parse(this.result).returnedData.message;

       // this.successMessage(msg);
       if(JSON.parse(this.result[0].data[1].value).sucess){
        //alert('Record Created SuccessFully');
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/consignee/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/consignee/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


}
