<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Data Element Code Maintenance</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Data Element Code </mat-label>
            <input matInput formControlName="dataElementCode" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Data Element Definition </mat-label>

            <input matInput formControlName="dataElementDefinition" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Internal/External </mat-label>

            <input matInput formControlName="InternalExternal" />
          </mat-form-field>
        </div>

        <button
          class="buttons addButton"
          mat-raised-button
          (click)="addDataElement()"
        >
          Add
        </button>
      </div>
      <br /><br />
    </mat-card-content>
  </mat-card>
</form>
