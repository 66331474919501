<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Schedule Exceptions</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
  
  
      
      <div class="buttons">
        <button mat-button (click)="add()"  class="addButton">
          <mat-icon>add</mat-icon>
          <span>New Schedule Exceptions</span>
        </button>
      </div>
     
     <div class="formDiv">
      <form [formGroup]="form">
        
          <mat-form-field appearance="outline">
            <mat-label>Type of Hours:</mat-label>
            <input matInput formControlName="TYPE"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Building:</mat-label>
            <input matInput formControlName="BLDG"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Account Code:</mat-label>
            <input matInput formControlName="ACCT"/>
          </mat-form-field>

         
      </form>
     </div>
     
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="type">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Type
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.type }}</td>
        </ng-container>
  
        <ng-container matColumnDef="build">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Build
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.build }}</td>
        </ng-container>
  
        <ng-container matColumnDef="account">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Account
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.account }}</td>
        </ng-container>
  
        <ng-container matColumnDef="exceptionDate">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Exception Date
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.exceptionDate }}</td>
        </ng-container>
  
        <ng-container matColumnDef="inOut">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            In/Out
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.inOut }}</td>
        </ng-container>

        
        <ng-container matColumnDef="inclOmit">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                InclOmit
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.inclOmit }}</td>
        </ng-container>

        <ng-container matColumnDef="startTime">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                StartTime
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.startTime }}</td>
        </ng-container>


        <ng-container matColumnDef="endTime">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                EndTime
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.endTime }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          <mat-option>
            <button mat-icon-button  (click)="change(element)">           
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button>
          </mat-option>

          <mat-option>
            <button mat-icon-button  (click)="delete(element)">           
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button>
          </mat-option>
  
       
  
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
    </mat-card-content>
  </mat-card>

