<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Orders Create</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
  
  
  
  <form [formGroup]="form" class="form over-Flow">
    <div class="row">
      <div class="col">       

              <mat-form-field  appearance="outline"><mat-label> Sched Ship Date: </mat-label> 
                <input matInput formControlName="schedShipDate" />
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> Sched Arrival Date: </mat-label> 
                <input
                  matInput
                  formControlName="schedArrivalDate"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> Ship Type: </mat-label>
                <input
                  matInput
                  formControlName="shipType"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> Carrier Code: </mat-label> 
                <input
                  matInput
                  formControlName="carrierCode"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> Freight Charge: </mat-label>
                <input
                  matInput
                  formControlName="freightCharge"
                  style="text-transform: uppercase"
                />            
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> COD Amount: </mat-label> 
                <input
                  matInput
                  formControlName="codAmount"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> Apply Auto Charge : </mat-label>
                <input
                  matInput
                  formControlName="applyAutoCharge"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> Bill To Customer: </mat-label>
                <input
                  matInput
                  formControlName="billToCustomer"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
           
            
                <mat-form-field appearance="outline">  <mat-label> Broker Name: </mat-label>
                  <input
                    matInput
                    formControlName="brokerName"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              

              
                <mat-form-field appearance="outline">  <mat-label> Total Quantity: </mat-label>
                  <input
                    matInput
                    formControlName="totalQuantity"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              

              
                <mat-form-field appearance="outline">  <mat-label> Ship Order From: </mat-label>
                  <input
                    matInput
                    formControlName="shipOrderFrom"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              

              
                <mat-form-field appearance="outline">  <mat-label> Override Quantity: </mat-label>
                  <input
                    matInput
                    formControlName="overrideQty"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              

              
                <mat-form-field appearance="outline">  <mat-label> Building: </mat-label>
                  <input
                    matInput
                    formControlName="building"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              
              
                <mat-form-field appearance="outline">  <mat-label> Zone: </mat-label>
                  <input
                    matInput
                    formControlName="zone"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              

              
                <mat-form-field appearance="outline">  <mat-label> Commit Inventory? : </mat-label>
                  <input
                    matInput
                    formControlName="commitInventory"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              

  
            <div style="margin-left:20px"><b>Printer</b></div>
  
            
              <mat-form-field appearance="outline">  <mat-label> Control Copy Printer: </mat-label> 
                <input
                  matInput
                  formControlName="ctrl"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
  
  
            
              <mat-form-field appearance="outline">  <mat-label> Pick Sheet Printer: </mat-label> 
                <input
                  matInput
                  formControlName="pick"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            

            
                <mat-form-field appearance="outline">  <mat-label> BOL Printer: </mat-label> 
                  <input
                    matInput
                    formControlName="bol"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
             
  
            <div style="margin-left:20px"><b>Immed</b></div>
  
            
              <mat-form-field appearance="outline">  <mat-label> Control Copy Immed: </mat-label> 
                <input
                  matInput
                  formControlName="ctrl"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> Pick Sheet Immed: </mat-label> 
                <input
                  matInput
                  formControlName="address2"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
  
            
              <mat-form-field appearance="outline">  <mat-label> BOL Immed: </mat-label> 
                <input
                  matInput
                  formControlName="bolImmed"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            </div>
          </div>
            
          
          <div class="btn-text-right">
            <button mat-button class="button addButton"(click)="orderCreate2()">Next</button>
          </div>
        
     
     
      
      
    
        </form>
      </mat-card-content>
   </mat-card>
