<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Work With Inbound Details</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input readonly matInput formControlName="Storer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Item</mat-label>
            <input readonly matInput formControlName="Item" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="TallyNumber">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Tally Number</th>
      <td mat-cell *matCellDef="let element">{{ element.IDTALY }}</td>
    </ng-container>
    <ng-container matColumnDef="Seq">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Sequence</th>
      <td mat-cell *matCellDef="let element">{{ element.IDSEQ }}</td>
    </ng-container>
    <ng-container matColumnDef="ST">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.IDRTTY }}</td>
    </ng-container>
    <ng-container matColumnDef="transDate">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Transaction Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IDPRMM }}/{{ element.IDPRDD }}/{{ element.IDPRYY }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ArrivalDate">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Arrival Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IDARMM }}/{{ element.IDARDD }}/{{ element.IDARYY }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Expected">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Expected</th>
      <td mat-cell *matCellDef="let element">{{ element.IDQINT }}</td>
    </ng-container>

    <ng-container matColumnDef="Recv">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Received</th>
      <td mat-cell *matCellDef="let element">{{ element.IDQREC }}</td>
    </ng-container>

    <ng-container matColumnDef="Lots">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Lots</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IDLOT }} | {{ element.IDLTC1 }} | {{ element.IDLTC3 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="opt">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        <!-- Actions-->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="itemTallyInquiry(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Item Tally Inquiry</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="jumpToTally(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Jump To Tally</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 12]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
