import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';


import { AddEquipmentOperatorsComponent } from './add-equipment-operators/add-equipment-operators.component';
import { ChangeEquipmentOperatorsComponent } from './change-equipment-operators/change-equipment-operators.component';
import { DeleteEquipmentOperatorsComponent } from './delete-equipment-operators/delete-equipment-operators.component';
import { WwWorkAssignmentsComponent } from './ww-work-assignments/ww-work-assignments.component';
import { EquipmentoperatorService } from '../equipmentoperator.service';

@Component({
  selector: 'app-ww-equipment-operators',
  templateUrl: './ww-equipment-operators.component.html',
  styleUrls: ['./ww-equipment-operators.component.css']
})
export class WwEquipmentOperatorsComponent implements OnInit {

 // form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Operator',
    'Name',
    'Storer',
    'Building',
    'Status',
    'Actions' 
  ];

  dataSource:any=[]

  user=localStorage.getItem('userName');

  duplicateSource:any=[];

  // duplicateSource:any =[
  //   {
  //     Operator:'ADEAN',
  //     Name:'Amy Dean',
  //     Storer:'',
  //     Building:559,
  //     Status:''
  //   },
  //   {
  //     Operator:'BGAMA',
  //     Name:'Ben Gama',
  //     Storer:559,
  //     Building:559,
  //     Status:'AC'
  //   },
  //   {
  //     Operator:'BMORTAN',
  //     Name:'Brad Mortan',
  //     Storer:1301,
  //     Building:13,
  //     Status:'AC'
  //   }
  // ]

  result:any;

  constructor(
    private router: Router,
    public eopSvc: EquipmentoperatorService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  form= this.fb.group({
    Operator:''
  })

  searchTextDisplay:any

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  ngOnInit(): void {
    
    this.getEQuipmentOperator();
    
   
  }
  
  getEQuipmentOperator(){
  this.eopSvc.getAllEQuipmentOperator(this.user).subscribe((data) => {
    this.result = data;
   // var res = this.result;
    var res = this.result[0].data[1]?.value;
    console.log(data);

    var parsedData = JSON.parse(res);
    this.duplicateSource = parsedData.returnedData;
    console.log(this.duplicateSource);

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  });
  }

  getEquipmentOperator(eoperator:any){

    this.eopSvc.getEQuipmentOperator(this.user, eoperator.Operator,'').subscribe((data) => {
      this.result = data;
     // var res = this.result;
      var res = this.result[0].data[1]?.value;
      console.log(data);

      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log(this.duplicateSource);

      this.dataSource = new MatTableDataSource(this.duplicateSource);
      this.dataSource.paginator = this.paginator;
    });


  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  addEquipmentOperators(){
    this.matDialog.open(AddEquipmentOperatorsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  changeEquipmentOperators(element:any){
    this.matDialog.open(ChangeEquipmentOperatorsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  deleteEquipmentOperators(element:any)
  {
    this.matDialog.open(DeleteEquipmentOperatorsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  openWorkAssignments(element:any)
  {
    this.matDialog.open(WwWorkAssignmentsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }


}
