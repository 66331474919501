<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Unload Pallet Serial</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Pallet Serial </mat-label>
            <input matInput formControlName="PalletSerial" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Order Number </mat-label>
            <input matInput formControlName="OrderNumber" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Document Number </mat-label>

            <input matInput formControlName="DocumentNumber" />
          </mat-form-field>
        </div>
      </div>
      <div>
        <p>Press Enter to confirm Unload</p>
        <button mat-button (click)="unload()" class="removeButton">
          Unload
        </button>
        <br />
      </div>
    </mat-card-content>
  </mat-card>
</form>
