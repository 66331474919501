<mat-card>
  <mat-card-header>
    <mat-card-title> Work With An Items Location </mat-card-title>

    <!-- nnn  <button mat-button  class="addButton"  (click)="createPutawayLocation()" matTooltip="Add Tally Detais Putaway Location">
            <mat-icon>add</mat-icon>
            <span>New Tally Detail</span>
          </button>  -->

    <div class="buttons">
      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search Tally"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>
      <button mat-button (click)="itemLocationCreate()" class="addButton">
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <!-- <div class="buttons">
          <button mat-button (click)="itemLocationCreate()" class="addButton">
            <mat-icon>add</mat-icon>
          </button>
        </div> -->

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow" *ngIf="searchTextDisplay">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label>Storer</mat-label>
            <input readonly matInput formControlName="storer" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Item</mat-label>
            <input readonly matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Building</mat-label>
            <input matInput formControlName="building" />
          </mat-form-field>

          <mat-form-field appearance="outline"
          ><mat-label>Floor</mat-label>
          <input matInput formControlName="floor" />
        </mat-form-field>

        <mat-form-field appearance="outline"
        ><mat-label>Aisle</mat-label>
        <input matInput formControlName="aisle" />
      </mat-form-field>

      <mat-form-field appearance="outline"
      ><mat-label>Slot</mat-label>
      <input matInput formControlName="slot" />
    </mat-form-field>

          <div style="margin-left: 20px">
            <b>Lot Code :</b>
          </div>

          <mat-form-field appearance="outline"
            ><mat-label>Lot code 1</mat-label>
            <input matInput formControlName="LOT1" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Lot code 2</mat-label>
            <input matInput formControlName="LOT2" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Lot code 3</mat-label>
            <input matInput formControlName="LOT3" />
          </mat-form-field>
        </div>
      </div>

       <button
        class="buttons addButton"
        mat-raised-button
        type="submit"
        placeholder="Search"
        (click)="getItemLocationBySearch(form.value)"
      >
        Search
      </button> 
    </form>
  </mat-card-content>

  <table
    [dataSource]="dataSource"
    mat-table
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="BldNbr">
      <th mat-header-cell *matHeaderCellDef>Bld Nbr</th>
      <td mat-cell *matCellDef="let element">{{ element.LCBLDG }}</td>
    </ng-container>

    <ng-container matColumnDef="Floor">
      <th mat-header-cell *matHeaderCellDef>Floor</th>
      <td mat-cell *matCellDef="let element">{{ element.LCFLOR }}</td>
    </ng-container>

    <ng-container matColumnDef="Aisle">
      <th mat-header-cell *matHeaderCellDef>Aisle</th>
      <td mat-cell *matCellDef="let element">{{ element.LCAISL }}</td>
    </ng-container>

    <ng-container matColumnDef="Slot">
      <th mat-header-cell *matHeaderCellDef>Slot</th>
      <td mat-cell *matCellDef="let element">{{ element.LCSLOT }}</td>
    </ng-container>

    <ng-container matColumnDef="LotCode1">
      <th mat-header-cell *matHeaderCellDef>Lot Code 1</th>
      <td mat-cell *matCellDef="let element">{{ element.LCLTC1 }}</td>
    </ng-container>
    <ng-container matColumnDef="FirstReceived">
      <th mat-header-cell *matHeaderCellDef>First Received</th>
      <td mat-cell *matCellDef="let element">
        {{ element.LCRCVD | date : this.GlobalComponent.DATE_FORMAT }}
      </td>
    </ng-container>

    <ng-container matColumnDef="TotalCapacity">
      <th mat-header-cell *matHeaderCellDef>Total Capacity</th>
      <td mat-cell *matCellDef="let element">{{ element.LCTCAP }}</td>
    </ng-container>

    <ng-container matColumnDef="OnHand">
      <th mat-header-cell *matHeaderCellDef>On Hand</th>
      <td mat-cell *matCellDef="let element">{{ element.LCQAVL }}</td>
    </ng-container>

    <ng-container matColumnDef="AddlCapacity">
      <th mat-header-cell *matHeaderCellDef>Addl Capacity</th>
      <td mat-cell *matCellDef="let element">{{ element.LCADDL }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef>
        <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="itemLocationDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="oneSideAdj(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>One Side Adjustment</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="twoSideAdj(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>Two Side Adjustment</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="bucketToBucket(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>2 Side Adj Bucket to Bucket</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="itemResequence(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>Resequence</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
