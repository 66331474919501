import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
import { OrderService } from '../order.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-order-dship',
  templateUrl: './order-dship.component.html',
  styleUrls: ['./order-dship.component.css']
})
export class OrderDshipComponent implements OnInit {

  form!:FormGroup
  constructor(
    public orderSvc: OrderService,
    private fb: FormBuilder,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<OrderDshipComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.anyvariable)
    this.form= this.fb.group({
      BillNumber:this.anyvariable.OHBILL,
      Storer:this.anyvariable.OHSTOR ,
      Consignee:this.anyvariable.OHCONS ,
      Carrier:this.anyvariable.OHCAR,
      ProNumber:'',
      ShipDate:this.anyvariable.OHACTS,
      ShipTime:''
      
    })
  }

  result:any;

  
  deshipOrder() {
    var res = this.form.value;
    if (confirm('Are you sure you want to deship Oeder ' + this.anyvariable.OHBILL + '?')) {
      this.result = this.orderSvc
      .orderDeship( 
        this.anyvariable.OHBILL,
       ).subscribe((data: any) => {
        this.result = data;
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

      
       console.log("*********************");

       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[length-1].value==''){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
    }
  }
  reloadCurrentRoute() {
    this.router.navigate(['/main-nav/order/read']);
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


}
