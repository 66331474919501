import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';

@Component({
  selector: 'app-ww-metric-conversions',
  templateUrl: './ww-metric-conversions.component.html',
  styleUrls: ['./ww-metric-conversions.component.css']
})
export class WwMetricConversionsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  displayedColumns: string[] = [
    'typeofMetric',
    'fromUnit',
    'baseUnit',
    'conversionFactor',
    'example',
    'Opt',
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      typeofMetric:'netton' ,
      fromUnit:'NT',
      baseUnit:'',
      conversionFactor:'1.00000',
      example:'100 NT Equals',
   
      
      
    },

    {
      typeofMetric:'Area' ,
      fromUnit:'AK',
      baseUnit:'M2',
      conversionFactor:'4046.90000',
      example:'100 AK Equals 4046900000 CM2',
   
      
      
    },
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    // private dialogRef: MatDialogRef<AttributesComponent>
    
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      typeofmetric:'',
      fromUnit:'',
     
      
      

    
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
 
 


   }



  //  ConversionsChangeForm(element:any){
  //   this.matDialog.open(ConversionsChangeComponent, {
  //     "width": '1000px',
  //     "maxHeight": '90vh',
  //     'data': element,
  //     "autoFocus": false                           
  //   });
  // }


  // ConversionsAddForm(element:any){
  //   this.matDialog.open(ConversionsAddComponent, {
  //     "width": '1000px',
  //     "maxHeight": '90vh',
  //     'data': element,
  //     "autoFocus": false                           
  //   });
  // }
 
 

addAttribute(){

}


}
