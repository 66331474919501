import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { TripExpensesAddComponent } from './trip-expenses-add/trip-expenses-add.component';
import { TripExpensesChangeComponent } from './trip-expenses-change/trip-expenses-change.component';

@Component({
  selector: 'app-ww-trip-expenses',
  templateUrl: './ww-trip-expenses.component.html',
  styleUrls: ['./ww-trip-expenses.component.css']
})
export class WwTripExpensesComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Sl.No',
    'Category',
    'Sub Category',
    'Description',
    'Amount',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      Slno:1,
      Category:'Food',
      SubCategory:'Restaurant',
      Description:'Meals eaten out - TGI Fridays ',
      Amount:54.00
    },
    {
      Slno:2,
      Category:'Trans',
      SubCategory:'Parking',
      Description:'Parking Fees',
      Amount:30.00
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      TripNumber:'1623 return expenses for User Meeting'
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  addTripExpenses(){
    this.matDialog.open(TripExpensesAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  changeTripExpenses(element:any){
    this.matDialog.open(TripExpensesChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

}
