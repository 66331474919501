import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-hazmat-class',
  templateUrl: './edit-hazmat-class.component.html',
  styleUrls: ['./edit-hazmat-class.component.css'],
})
export class EditHazmatClassComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditHazmatClassComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      HazmatClass: '',
      Description: ' ',
      PlacardType: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }
}
