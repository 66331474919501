import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IitemService } from '../iitem.service';

@Component({
  selector: 'app-item-update4',
  templateUrl: './item-update4.component.html',
  styleUrls: ['./item-update4.component.css'],
})
export class ItemUpdate4Component implements OnInit {
  form!: FormGroup;
  result: any;

  constructor(
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    public itemSvc: IitemService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemUpdate4Component>,
    private router:Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({
      
      Receiving: '', // no data in RPG
      Regular: '', // no data in RPG
      Recurring: '', // no data in RPG
      Distribution: '', // no data in RPG
      ItemMinimum: '', // no data in RPG
      Alternate: '', // no data in RPG
      LotMinimum: '', // no data in RPG
      ItemMinimum1: '', // no data in RPG
      LotMinimum1: '', // no data in RPG

     storeNumber: this.anyvariable.ITSTOR,
     suffix: this.anyvariable.ITSSFX,
      item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
      
      rcvStorageRate: this.anyvariable.ITRCST,
      rcvStorageBase: this.anyvariable.ITRSBA,
      rcvStorageMin: this.anyvariable.ITRSMI, 
      rcrStorageRate: this.anyvariable. ITMNST, 
      rcrStorageBase: this.anyvariable. ITMSBA, 
      rcrStorageMin: this.anyvariable. ITMSMI, 
      regHandlingRate: this.anyvariable. ITRGHD,
      regHandlingBase: this.anyvariable. ITRHBA, 
      regHandlingMin: this.anyvariable. ITRHMI, 
      distHandlingRate: this.anyvariable. ITDSHD, 
      distHandlingBase: this.anyvariable. ITDHBA, 
      distHandlingMin: this.anyvariable. ITDHMI, 
      altHandlingRate: this.anyvariable. ITALHD, 
      altHandlingBase: this.anyvariable. ITAHBA, 
      altHandlingMin: this.anyvariable. ITAHMI, 
      insLiabilityAmount: this.anyvariable. ITINLB 
    });
  }

  itemUpdate4(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemServiceUpdate4( 
        this.anyvariable.ITSTOR,
        this.anyvariable.ITSSFX,
        this.anyvariable.ITEM,
        res.rcvStorageRate,
        res.rcvStorageBase,
        res.rcvStorageMin,
        res.rcrStorageRate,
        res.rcrStorageBase,
        res.rcrStorageMin,
        res.regHandlingRate,
        res.regHandlingBase,
        res.regHandlingMin,
        res.distHandlingRate,
        res.distHandlingBase,
        res.distHandlingMin,
        res.altHandlingRate,
        res.altHandlingBase,
        res.altHandlingMin,
        res.insLiabilityAmount
       

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       console.log(this.result[0].data.length);
          console.log("*********************");

       //console.log(this.result[0].data[23].value);
       console.log("*********************");

       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Created/Updated'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          // this. updated();
          this.router.navigate(['/main-nav/item/read']);
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg= this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
    this.router.navigate(['/main-nav/item/read']);
  }

  updated() {
    console.log("UPDATED...");
    console.log(this.anyvariable);
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
