import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { AccountSelectionUpdateComponent } from '../account-selection/account-selection-update/account-selection-update.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  mySubscription:any;
  constructor(
    private router :Router,
    private matDialog: MatDialog
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
  // Trick the Router into believing it's last link wasn't previously loaded
      this.router.navigated = false;
      }
    }); 
   }

  ngOnInit(): void {

   // localStorage.setItem('menuStatus', 'true');
   localStorage.removeItem('menuStatus');
   //localStorage.setItem('dashboardStatus', 'false');


  }

  opened=false;
  togglesidebar(){
    this.opened=!this.opened;
    this.router.events.subscribe(event => {
      // close sidenav on routing
      this.opened=false;
    })

    
  };

  user=localStorage.getItem('userName');

  accountSelectionUpdate(userName: any) {
    // this.router.navigate(['tallys/posting',tally]);
    this.matDialog.open(AccountSelectionUpdateComponent, {
      width: '500px',
      maxHeight: '80vh',
      data: userName,
      autoFocus: false,
    });
  }

  Logout() {
    localStorage.removeItem('LoggedIn');
    localStorage.removeItem('userName');
    localStorage.removeItem('building');
    localStorage.removeItem('storer');
    localStorage.removeItem('storerSuffix');
    localStorage.removeItem('menuStatus');
    this.router.navigateByUrl('/');
  }

  customerServiceMenu(){

    localStorage.setItem('menuStatus', 'customerService');
   // this.router.navigateByUrl('/main-nav');
    this.router.navigateByUrl('/main-nav/tallys/read');
   
  }

  managementMenu(){

    localStorage.setItem('menuStatus', 'management');
   // this.router.navigateByUrl('/main-nav');
    this.router.navigateByUrl('/main-nav/tallys/read');
   
  }

  inventory(){
    localStorage.setItem('menuStatus', 'inventory');
   // this.router.navigateByUrl('/main-nav');
    this.router.navigateByUrl('/main-nav/item/read');
  }

  rfMenu(){
    localStorage.setItem('menuStatus', 'rfMenu');
   // this.router.navigateByUrl('/main-nav');
    this.router.navigateByUrl('/main-nav/receive/staging');
  }

  maintanance(){

    localStorage.setItem('menuStatus', 'maintanance');
   // this.router.navigateByUrl('/main-nav');
    this.router.navigateByUrl('/main-nav/storer');

  }

}
