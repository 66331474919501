import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-hazard-materials-group-add',
  templateUrl: './ww-hazard-materials-group-add.component.html',
  styleUrls: ['./ww-hazard-materials-group-add.component.css']
})
export class WwHazardMaterialsGroupAddComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user = 'NKOHAN';

  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwHazardMaterialsGroupAddComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      HazardMatGroup : '',
      ProperName : '',
      Class : '',
      IdNo : '',
      PackingGroup : '',
      SpecialRequire:''

     });
  }

  addHazardMaterials(){
    
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
