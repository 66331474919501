<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">
      <button mat-button (click)="onBack()" class="backButton">
        <mat-icon>navigate_before </mat-icon>
      </button>
      Tally Details</mat-card-title
    >

    <div class="buttons">
      <button
        mat-button
        (click)="addTally()"
        class="addButton"
        matTooltip="Add Tally Detais"
      >
        <mat-icon>add</mat-icon>
        <!-- <span>New Tally Detail</span> -->
      </button>
    </div>
  </mat-card-header>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Tally Number:</mat-label>
            <input matInput formControlName="tally" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Storer: </mat-label>
            <input matInput formControlName="storer" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Name: </mat-label>
            <input matInput formControlName="storerName" readonly/>
          </mat-form-field>

           <!-- <label> Tally Number:</label><b>{{ tally }} </b>
          <label> Storer :</label> <b>{{ storer }}   </b>       
          <label> Name:</label><b>{{storerName}} </b> -->
        </div>
      </div>
    </form>
    <!--<div class="example-container">
            <mat-form-field hintLabel="Max 10 characters">
              <input matInput #input maxlength="10" placeholder="Enter some input">
            
            </mat-form-field>
           
        </div>-->
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="ST">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.IDACDL }}</td>
    </ng-container>

    <ng-container matColumnDef="Sequence">
      <th mat-header-cell *matHeaderCellDef>Sequence</th>
      <td mat-cell *matCellDef="let element">{{ element.IDSEQ }}</td>
    </ng-container>

    <ng-container matColumnDef="Item">
      <th mat-header-cell *matHeaderCellDef>Item Code</th>
      <td mat-cell *matCellDef="let element">{{ element.IDITEM }}</td>
    </ng-container>

    <ng-container matColumnDef="LotCodes">
      <th mat-header-cell *matHeaderCellDef>Lot Codes</th>
      <td mat-cell *matCellDef="let element">{{ element.IDLOT }}</td>
    </ng-container>

    <ng-container matColumnDef="InTransit">
      <th mat-header-cell *matHeaderCellDef>Intransit</th>
      <td mat-cell *matCellDef="let element">{{ element.IDQINT }}</td>
    </ng-container>

    <ng-container matColumnDef="QtyRecvd">
      <th mat-header-cell *matHeaderCellDef>Quantity Received</th>
      <td mat-cell *matCellDef="let element">{{ element.IDQREC }}</td>
    </ng-container>

    <ng-container matColumnDef="PutAway">
      <th mat-header-cell *matHeaderCellDef>Put Away</th>
      <td mat-cell *matCellDef="let element">{{ element.PUT }}</td>
    </ng-container>
    <!-- <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef> Qty Rcevd </th>
            <td mat-cell *matCellDef="let element"> {{ element.code }} </td>
        </ng-container>

        <ng-container matColumnDef="intransit">
            <th mat-header-cell *matHeaderCellDef> Intransit </th>
            <td mat-cell *matCellDef="let element"> {{ element.intransit }} </td>
        </ng-container>
         
        
          <ng-container matColumnDef="receive">
            <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                Recieved
            </th>
            <td mat-cell *matCellDef="let element">{{ element.receive }}</td>
          </ng-container> -->

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
      <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button mat-icon-button (click)="tallyDetailUpdate(element)">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Update</mat-label>
            </button>
          </li>

          <li>
            <button mat-icon-button (click)="tallyDetailsComment(element)">
              <mat-icon mat-icon-button color="primary">comment</mat-icon>
              <mat-label>Comments</mat-label>
            </button>
          </li>

          <li>
            <button mat-icon-button (click)="tallyDtlDeReceive(element)">
              <mat-icon mat-icon-button color="primary">comment</mat-icon>
              <mat-label>De Receive</mat-label>
            </button>
          </li>

          <li>
            <button mat-icon-button (click)="tallyDetailDelete(element)">
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button>
          </li>

          <li>
            <button mat-icon-button (click)="tallyDetailPutawayLocation(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Putaway Location</mat-label>
            </button>
          </li>

          <li>
            <button mat-icon-button (click)="stockStatus(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Stock Status</mat-label>
            </button>
          </li>


          <!-- <li>
            <button mat-icon-button (click)="tallyDetailPutawayLocationUpdate(id)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Putaway Location Update</mat-label>
            </button>
          </li> -->
        </ul>
        </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
