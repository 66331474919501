import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-ww-work-assignments',
  templateUrl: './ww-work-assignments.component.html',
  styleUrls: ['./ww-work-assignments.component.css']
})
export class WwWorkAssignmentsComponent implements OnInit {

  form!: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Operator',
    'Seq',
    'Prty',
    'Type',
    'Sub Type',
    'I',
    'Shipment Type',
    'Bldg',
    'Zone',
    'Aisle',    
    'Slot',
    'Status',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      Operator:'ADEAN',
      Seq:'',
      Prty:'',
      Type:'',
      SubType:'',
      I:'',
      ShipmentType:'',
      Bldg:'',
      Zone:'',
      Aisle:'',
      Slot:'',
      Status:'AC',
    }
  ]
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwWorkAssignmentsComponent>,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      Operator:this.anyvariable.EOUSER
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }


  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
