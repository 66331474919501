import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StorerServiceService } from '../storer-service.service';

@Component({
  selector: 'app-storer-update6',
  templateUrl: './storer-update6.component.html',
  styleUrls: ['./storer-update6.component.css']
})
export class StorerUpdate6Component implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerUpdate6Component>,
   private matDialog: MatDialog
    ) { }

    ngOnInit(): void {

      console.log(this.anyvariable.storerNumber);
         this.form = this.fb.group({
          
          storeNumber: this.anyvariable.STORE,
          suffix: this.anyvariable.STSFX,
          storeName: this.anyvariable.STNAME,         
          winsCommsID:this.anyvariable.STCMID, 
          dunsNumber:[this.anyvariable.STDUNS,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          customEDICommsId:this.anyvariable.STEDI1,  
          chepPalletUser:this.anyvariable.STCHEP,
          AsnRequired856:this.anyvariable.ST856F,  
         });
    }
  
  
    storerUpdate6(){
      var res = this.form.value;         
      this.result = this.storerSvc
        .storerServiceUpdate6( 
          res.storeNumber,
          res.suffix,
          res.storeName,
          res.winsCommsID,
          res.dunsNumber,
          res.customEDICommsId,
          res.chepPalletUser,
          res.AsnRequired856,
          this.user
    
        ).subscribe((data: any) => {
          this.result = data;
        if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
            const msg= this.result[0].data[this.result[0].data.length-1].value;
            this.successMessage(msg)
            this.closeDialog();
           // this.router.navigate(['/main-nav/tallys/read']);
         }else{
         // const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
         }
        });
    }  
  
    successMessage(msg:any){
      Swal.fire(msg,'','success');
      //this.router.navigate(['/main-nav/storer/read']);
    }
  
    failureMessage(msg:any){
      Swal.fire(msg,'','error');
     // this.router.navigate(['/main-nav/storer/read']);
    }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
