<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
  
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Add Extract Report</mat-card-title>
        &nbsp;
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Extract Name: </mat-label>
              <input matInput formControlName="extractName" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Description:</mat-label>
              <input matInput maxlength="1" formControlName="description" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Calling Program:</mat-label>
              <input matInput formControlName="callingProgram" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Output Type</mat-label>
              <input matInput formControlName="outputType" />
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>IFS Location:</mat-label>
              <input matInput formControlName="ifsLocation" />
            </mat-form-field>
          </div>

          <div class="col">
            <button mat-button class="buttons addButton" (click)="addExtractReport()">
              <mat-label>Add</mat-label>
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  