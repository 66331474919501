import { AnimationMetadataType } from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IMapLocationService } from '../i-map-location.service';
import { GlobalComponent } from 'src/app/Global-Component';
import { CustomAlertService } from '../../custom-alert/custom-alert.service';

@Component({
  selector: 'app-map-cross-reference',
  templateUrl: './map-cross-reference.component.html',
  styleUrls: ['./map-cross-reference.component.css'],
})
export class MapCrossReferenceComponent implements OnInit {
  item: any;
  //form!: FormGroup;
  duplicateSource: any;
  dataSource: any;
  constructor(
    public iMapSvc: IMapLocationService,
    private fb: FormBuilder,
    private router: Router,
    private CustomAlertService:CustomAlertService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<MapCrossReferenceComponent>
  ) {}

  user=localStorage.getItem('userName');
  result:any;
  building = GlobalComponent.fullBuilding(this.anyvariable.MABLDG);
  floor = this.anyvariable.MAFLOR;
  aisl = GlobalComponent.fullSlotAisl(this.anyvariable.MAAISL);
  slot = GlobalComponent.fullSlotAisl(this.anyvariable.MASLOT);
  location = '';
  

  ngOnInit(): void {
  
      this.iMapSvc
      .getMapLocationReference('',this.building,this.floor,this.aisl,this.slot,this.user)
      .subscribe((data) => {
        this.result = data;        
        var res = data;

        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('MAP LOCATION X_REFERENCE DATA');
        console.log(this.duplicateSource);

        if(this.duplicateSource[0]&&this.duplicateSource[0].MXLOCN)
        {
          this.location=this.duplicateSource[0].MXLOCN;
          this.floor = this.duplicateSource[0].MXFLOR;
          this.aisl = this.duplicateSource[0].MXAISL;
          this.slot = this.duplicateSource[0].MXSLOT;
        }
      });

      


  }

  form = this.fb.group({
        
    building:this.building,
    floor:this.floor,
    aisl: this.aisl,
    slot:this.slot,
    xReference: this.location
  });


  createMapLocXref(){
    var res = this.form.value;
       
    this.result = this.iMapSvc
      .createServiceMapLocXref( 

       res.xReference,
       res.building,
       res.floor,
       res.aisl,
       res.slot,
       this.user
        
        ).subscribe((data: any) => {
        this.result = data;

       var length = this.result[0].data.length;

        if(JSON.parse(this.result[0].data[1].value).success){
    
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          //this.router.navigate(['/main-nav/maplocation/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
  }



  updateMapLocXref(){
    var res = this.form.value;
    console.log('RESPONSE') 
     console.log(res) 
    this.result = this.iMapSvc
      .updateServiceMapLocXref( 

        res.xReference,
        res.building,
        res.floor,
        res.aisl,
        res.slot,
        this.user   

        
        ).subscribe((data: any) => {
        this.result = data;
       
       var length = this.result[0].data.length;
     
        if(JSON.parse(this.result[0].data[1].value).success){        
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          this.router.navigate(['/main-nav/maplocation/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
  }



  deletePutawayLocation(){
    const dialogRef = this.CustomAlertService.openCustomAlert('Delete this putaway location ? '+this.location);
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
    //if(confirm('Delete this putaway location ? '+this.location)){
      var res = this.form.value;
      this.result = this.iMapSvc
      .deleteServiceMapLocXref(
        
        this.location,
        res.building,
        res.floor,
        res.aisl,
        res.slot,
        this.user
        
        )
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
  
        if (JSON.parse(this.result[0].data[1].value).success) {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.successMessage(msg);
          this.closeDialog();
        
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.failureMessage(msg);
        
        }
      });
  
    }
  });
}
  
  successMessage(msg:any){
    Swal.fire(msg,'','success');
   this.closeDialog();
    this.router.navigate(['/main-nav/map-location/read']);
  }
  
  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }


  closeDialog() {
    this.dialogRef.close();
  }
}
