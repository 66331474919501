<mat-card>
  <mat-card-header>
    <mat-card-title> Work With Item Forecast Details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button
        mat-button
        (click)="ItemForecastDetailsCreateForm()"
        class="addButton buttons"
      >
        <mat-icon>add</mat-icon>
        <span>New Item Forecast details</span>
      </button>
    </div>

    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="STOR" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Consignee</mat-label>
            <input matInput formControlName="CONS" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Forecast</mat-label>
            <input matInput formControlName="FDAT" />
          </mat-form-field>
          <button
            class="addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="ScheduleDate">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>ScheduleDate</th>
        <td mat-cell *matCellDef="let element">{{ element.SDAT }}</td>
      </ng-container>

      <ng-container matColumnDef="Quantity">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element">{{ element.IGQTYS }}</td>
      </ng-container>

      <ng-container matColumnDef="LineSet">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>LineSet</th>
        <td mat-cell *matCellDef="let element">{{ element.IGLSET }}</td>
      </ng-container>
      <ng-container matColumnDef="Job No">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Job No</th>
        <td mat-cell *matCellDef="let element">{{ element.IGJOB }}</td>
      </ng-container>
      <ng-container matColumnDef="Item">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Item</th>
        <td mat-cell *matCellDef="let element">{{ element.Item }}</td>
      </ng-container>
      <ng-container matColumnDef="MRN No">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>MRN No</th>
        <td mat-cell *matCellDef="let element">{{ element.MRN }}</td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.IGSTAT }}</td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <mat-select placeholder="Select Option">
            <mat-option>
              <button
                mat-icon-button
                (click)="ItemForecastDetailsUpdateForm(element)"
              >
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Change</mat-label>
              </button>
            </mat-option>

            <mat-option>
              <button
                mat-icon-button
                (click)="ItemForecastDetailsDeleteForm(element)"
              >
                <mat-icon mat-icon-button color="primary">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </mat-option>
          </mat-select>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      #paginator
      [pageSizeOptions]="[4, 8, 16]"
      showFirstLastButtons
    ></mat-paginator></mat-card-content
></mat-card>
