import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { MaintquestionsAddComponent } from './maintquestions-add/maintquestions-add.component';
import { MaintquestionsChangeComponent } from './maintquestions-change/maintquestions-change.component';
import { MaintquestionsCopyComponent } from './maintquestions-copy/maintquestions-copy.component';
import { MaintquestionsDeleteComponent } from './maintquestions-delete/maintquestions-delete.component';

@Component({
  selector: 'app-maint-questions',
  templateUrl: './maint-questions.component.html',
  styleUrls: ['./maint-questions.component.css']
})
export class MaintQuestionsComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'BuildingNumber',
    'EquipmentType',
    'SequenceNumber',
    'MaintenanceQuestion',
    'MaintenanceSeverity',
    'MandatoryQuestion',
    'StatusCode',
    'Actions'
   
  ];
  dataSource:any=[]
  duplicateSource:any =[
    {
      BLDG:'13',
      EZTYPE:'FORK',
      EZSEQ:'1',
      EZQUES:'BRAKES',
      EZSEV:'40',
      EZMAND:'Y',
      EZSTAT:'AC'
        
    }
   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      BLDG:'13',
      EZTYPE:'FORK' 
     
    })
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
    
  }
  onBack() {
    this.router.navigate(['/main-nav/maintquestions/read']);
 
}
MaintQuestionsAddForm(){
  this.matDialog.open(MaintquestionsAddComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
    //  'data': element,
     "autoFocus": false                           
    });
    }
    MaintQuestionsUpdateForm(element:any){
      this.matDialog.open(MaintquestionsChangeComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        }
        MaintQuestionsDeleteForm(element:any){
          this.matDialog.open(MaintquestionsDeleteComponent, {
              "width": '1000px',
               "maxHeight": '90vh',
             'data': element,
             "autoFocus": false                           
            });
            }
            MaintQuestionsCopyForm(element:any){
              this.matDialog.open(MaintquestionsCopyComponent, {
                  "width": '1000px',
                   "maxHeight": '90vh',
                 'data': element,
                 "autoFocus": false                           
                });
                }

            addAttribute(){

            }


}
