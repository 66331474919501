import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ForecastUpdateComponent } from './forecast-update/forecast-update.component';
import { ForecastDeleteComponent } from './forecast-delete/forecast-delete.component';
import { ForecastCreateComponent } from './forecast-create/forecast-create.component';
import { ForecastDetailsComponent } from './forecast-details/forecast-details.component';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.css'],
})
export class ForecastComponent implements OnInit {
  form = this.fb.group({
    IFSTOR: [],
    IFSREF: [],
    IFRELS: [],
    IFCONS: [],
    IFSTAT: [],
  });

  displayedColumns: String[] = [
    'Storer',
    'Consignee',
    'ForecastDate',
    'FT',
    'DT',
    'BeginHorizon',
    'EndHorizon',
    'QT',
    'UOM',
    'ST',
    'Release',
    'Supplier',
    'Reference',
    'Actions',
  ];

  dataSource = [
    {
      IFSTOR: 220,
      IFCONS: 65,
      FDAT: '12/09/19',
      IFFTYP: '',
      IFDTYP: '',
      BHOR: '12/09/19',
      EHOR: '07/05/20',
      IFQTYP: '',
      IFUOM: 'PC',
      IFSTAT: 'AC',
      IFRELS: '',
      IFSPLR: '57393X4',
      IFSREF: 'GLOBAL SUPPLY',
    },
    {
      IFSTOR: 220,
      IFCONS: 65,
      FDAT: '12/09/19',
      IFFTYP: '',
      IFDTYP: '',
      BHOR: '12/09/19',
      EHOR: '07/05/20',
      IFQTYP: '',
      IFUOM: 'PC',
      IFSTAT: 'AC',
      IFRELS: '',
      IFSPLR: '57393X4',
      IFSREF: 'GLOBAL SUPPLY',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  getUpdateForm(element: any) {
    //this.router.navigateByUrl('main-nav/forecast/update', { state: element });
    this.matDialog.open(ForecastUpdateComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }
  getDeleteForm(element: any) {
    //this.router.navigateByUrl('main-nav/forecast/delete', { state: element });
    this.matDialog.open(ForecastDeleteComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }
  addForecast() {
    //this.router.navigateByUrl('main-nav/forecast/add');
    this.matDialog.open(ForecastCreateComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
    });
  }
  getDetailsForm(element: any) {
    //this.router.navigateByUrl('main-nav/forecast/details', { state: element });
    this.matDialog.open(ForecastDetailsComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }
  ngOnInit(): void {}
}
