<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>Delete System Comments</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
    <mat-card-content>
        <form [formGroup]="form" fxLayoutAlign="center">
           
                <div class="col">
                    <mat-form-field  appearance="outline">
                        <mat-label> File ID   </mat-label> 
                        <input  matInput formControlName="fileId" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Key  </mat-label> 
                        <input  matInput formControlName="key" readonly/>
                    </mat-form-field>
                    
                    <mat-form-field  appearance="outline">
                        <mat-label> Sub Code  </mat-label> 
                        <input  matInput formControlName="subCode" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Sequence  </mat-label> 
                        <input  matInput formControlName="sequence" readonly/>
                    </mat-form-field>

                    <mat-form-field style="width: 100%;" appearance="outline">
                        <mat-label> Text  </mat-label> 
                        <input  matInput formControlName="text" readonly/>
                    </mat-form-field>       
                    
                    <mat-form-field  appearance="outline">
                        <mat-label> Last Changed Date  </mat-label> 
                        <input  matInput formControlName="lastChangeDate" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Last Changed Time  </mat-label> 
                        <input  matInput formControlName="lastChangeTime" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Changed By ID  </mat-label> 
                        <input  matInput formControlName="changedBy" readonly/>
                    </mat-form-field>
                
                <button mat-button (click)="deleteItemComment()" class="buttons addButton">                        
                    <span>Delete</span>
                </button>
            
            </div>                    
        </form>
    </mat-card-content>
</mat-card>
