<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Contacts </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Contact Type</mat-label>
            <input matInput formControlName="ContactType" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="Name" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput formControlName="Status" maxlength="2" />
          </mat-form-field>
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Tally Column -->
        <ng-container matColumnDef="Type">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Type
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Type }}</td>
        </ng-container>

        <ng-container matColumnDef="ContactName">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Contact Name
          </th>
          <td mat-cell *matCellDef="let element">{{ element.ContactName }}</td>
        </ng-container>

        <ng-container matColumnDef="Telephone">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Telephone
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Telephone }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Status">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Status
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Status }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table></form
  ></mat-card-content>
  <!-- <mat-paginator
                #paginator
                [pageIndex]="0"
                [pageSize]="10"
                [pageSizeOptions]="[5, 10, 25, 100]"
              >
              </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
