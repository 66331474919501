import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwInboundDetailsChangeComponent } from './ww-inbound-details-change/ww-inbound-details-change.component';
import { WwInboundDetailsCommentsComponent } from './ww-inbound-details-comments/ww-inbound-details-comments.component';
import { WwInboundDetailsDeleteComponent } from './ww-inbound-details-delete/ww-inbound-details-delete.component';
import { WwInboundDetailsAddComponent } from './ww-inbound-details-add/ww-inbound-details-add.component';
import { WwInboundDetailsStockStatusComponent } from './ww-inbound-details-stock-status/ww-inbound-details-stock-status.component';
import { WwInboundDetailsActivityInquiryComponent } from './ww-inbound-details-activity-inquiry/ww-inbound-details-activity-inquiry.component';
import { WwInboundDetailsExtraDataComponent } from './ww-inbound-details-extra-data/ww-inbound-details-extra-data.component';
import { WwInboundDetailsPurchaseOrdsComponent } from './ww-inbound-details-purchase-ords/ww-inbound-details-purchase-ords.component';
@Component({
  selector: 'app-ww-inbound-details',
  templateUrl: './ww-inbound-details.component.html',
  styleUrls: ['./ww-inbound-details.component.css']
})
export class WwInboundDetailsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    
    'ST',
    'Seq',
    'Item Code',
    'Lot Codes',
    'In transit',
    'Qty Recvd',
    'actions'
    
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      IDACDL:'',
      IDSEQ:'100',
      IDITEM:'TEST 1',
      LOTS:'4',
      IDQINT:'32',
      IDQREC:'32',

        
    },
   
  ]



  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      IHTALY:'1157820', 
      IHSTOR:'200',
      STNAME:'Ramiya Test'
      
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/WwInboundDetails/read']);
 
}


    InboundDetailsChangeForm(element:any){
      this.matDialog.open(WwInboundDetailsChangeComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        
    
  }

  InboundDetailsCommentsForm(element:any){
    this.matDialog.open(WwInboundDetailsCommentsComponent, {
        "width": '1000px',
         "maxHeight": '90vh',
       'data': element,
       "autoFocus": false                           
      });

      
      
  
}


InboundDetailsDeleteForm(element:any){
  this.matDialog.open(WwInboundDetailsDeleteComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
     'data': element,
     "autoFocus": false                           
    });
    

}

InboundDetailsStockStatusForm(element:any){
  this.matDialog.open(WwInboundDetailsStockStatusComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
     'data': element,
     "autoFocus": false                           
    });
    

}

InboundDetailsActivityInquiryForm(element:any){
  this.matDialog.open(WwInboundDetailsActivityInquiryComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
     'data': element,
     "autoFocus": false                           
    });
    

}

InboundDetailsExtraDataForm(element:any){
  this.matDialog.open(WwInboundDetailsExtraDataComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
     'data': element,
     "autoFocus": false                           
    });
    

}

InboundDetailsPurchaseOrdsForm(element:any){
  this.matDialog.open(WwInboundDetailsPurchaseOrdsComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
     'data': element,
     "autoFocus": false                           
    });
    

}

InboundDetailsAddForm(){
  this.matDialog.open(WwInboundDetailsAddComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
    //  'data': element,
     "autoFocus": false                           
    });

    
    

}

  addAttribute(){

  }


}


