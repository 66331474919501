import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorerServiceService {

  url = environment.API_BASE_URL;
 // BASE_URL = 'http://170.249.89.98:5200/api';
 // BASE_URL = 'http://localhost:5200/api';
  constructor(private httpClient: HttpClient) {
    // if (environment.API_BASE_URL) {
    //   var BASE_URL = environment.API_BASE_URL;
    // }
  }
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAll(user: any, storer:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/storer/readStorer?user=${user}&storer=${storer}`
    );
  }

  storerSearch(user: any, building:any, storer:any, storeName:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/storer/readStorer?user=${user}&building=${building}&storer=${storer}&storeName=${storeName}`
    );
  }

  getAll2(user: any): Observable<any> {
    return this.httpClient.get(
      this.url+`/storer/read?user=${user}`
    );
  }

  getAllStorerContacts(user: any, storer:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/storercontact/read2?user=${user}&storer=${storer}`
    );
  }

  getStorerContactsBySearch(user: any, storer:any, type:any, name:any, status:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/storercontact/readBySearch?user=${user}&storer=${storer}&type=${type}&name=${name}&status=${status}`
    );
  }

  //storer contact create
  storerCreate(
    CTCONS: any, //consignee
    CTCELN : any, //cell number
    CTNAME: any, //contactname
    CTTYPE: any, //contact type
    CTEMAL: any, //contact email
    CTEFMT: any, //mail format
    CTFAXN: any, //fax
    CTSTAT: any, //status
    CTTELN: any, //puhoen numenr
    user: any
  ): Observable<any> {
    
    return this.httpClient
   
      .post<any>(this.url+'/storercontact/create', {
        CTCONS,
        CTCELN,
        CTNAME,
        CTTYPE,
        CTEMAL,
        CTEFMT,
        CTFAXN,
        CTSTAT,
        CTTELN,
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  //storer contact update
  storerContactUpdate(
    CTCONS: any, //consignee
    CTCELN : any, //cell number
    CTNAME: any, //contactname
    CTTYPE: any, //contact type
    CTEMAL: any, //contact email
    CTEFMT: any, //mail format
    CTFAXN: any, //fax
    CTSTAT: any, //status
    CTTELN: any, //puhoen numenr
    user: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/storercontact/update', {
        CTCONS,
        CTCELN,
        CTNAME,
        CTTYPE,
        CTEMAL,
        CTEFMT,
        CTFAXN,
        CTSTAT,
        CTTELN,
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }
  
  storerServiceCreate1(
    storeNumber:any,
    suffix:any,
    storeName:any,
    address1:any,
    address2:any,
    address3:any,
    zipCode:any,
    zipSuffix:any,
    areaCode:any,
    phoneExtension:any,
    phoneNumber:any,
    primaryRep:any,
    creditLimit:any,
    creditHold:any,
    building:any,
    keepQtyByLoc:any,
    standardClose:any,
    midMnthCutOff:any,
    midMnthCutOffDt:any,
    forcePsBlPrint:any,
    comboPsBlForm:any,
    cutsAllowed:any,
    blindTally:any,
    chepUser:any,
    preventConsodl:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .post<any>(this.url+'/storer/create1', {
      storeNumber,
		  suffix, 
		  storeName,
		  address1,
		  address2,
		  address3,
		  zipCode,
		  zipSuffix,
		  areaCode,
		  phoneExtension,
		  phoneNumber,
		  primaryRep,
		  creditLimit,
		  creditHold,
		  building,
		  keepQtyByLoc,
		  standardClose,
		  midMnthCutOff,
		  midMnthCutOffDt,
		  forcePsBlPrint,
		  comboPsBlForm,
		  cutsAllowed,
		  blindTally,
		  chepUser,
		  preventConsodl,
      user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  storerServiceCreate2(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    user1:any,
    user2:any,
    user3:any,
    user4:any,
    allItemsLotted:any,
    lotCodeLabel1:any,
    lotCodeLabel2:any,
    lotCodeLabel3:any,
    sortmaskPointer:any,
    sortMask1:any,
    sortMask2:any,
    sortMask3:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .post<any>(this.url+'/storer/create2', {
        storeNumber,
        suffix, 
        storeName,
        user1,
        user2,
        user3,
        user4,
        allItemsLotted,
        lotCodeLabel1,
        lotCodeLabel2,
        lotCodeLabel3,
        sortmaskPointer,
        sortMask1,
        sortMask2,
        sortMask3,
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  storerServiceCreate3(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    freightCharge:any,
    defaultCOD:any,
    defHandlingPer:any,
    rcrStorage:any,
    rcrBasis:any,
    rcvStorage:any,
    rcvBasis:any,
    regHandling:any,
    regBasis:any,
    altHandling:any,
    altBasis:any,
    distHandling:any,
    distBasis:any,
    liablBasis:any, 
    liablAmount:any,
    wrLotMin:any,
    storageLotMin:any,
    wrItemHandMin:any,
    storageItemMin:any,
    wrItemStorageMin:any,
    storageInvoiceMin:any,
    wrStorageMin:any,
    wrHandlingMin:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/create3', {
    storeNumber,
    suffix, 
    storeName,
    freightCharge,
    defaultCOD,
    defHandlingPer,
    rcrStorage,
    rcrBasis,
    rcvStorage,
    rcvBasis,
    regHandling,
    regBasis,
    altHandling,
    altBasis,
    distHandling,
    distBasis,
    liablBasis, 
    liablAmount,
    wrLotMin,
    storageLotMin,
    wrItemHandMin,
    storageItemMin,
    wrItemStorageMin,
    storageInvoiceMin,
    wrStorageMin,
    wrHandlingMin,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  storerServiceCreate4(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    inCarLoadWght:any,
    inCarLoadCube:any,
    outCarLoadWght:any,
    outCarLoadCube:any,
    inTlWght:any,
    inTlCube:any,
    outTlWght:any,
    outTlCube:any,
    storageInv:any,
    accessInv:any,
    wrInvoice:any,
    generalInv:any,
    freightInv:any,
    cartageInv:any, 
    storageVarPer:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/create4', {
        storeNumber,
        suffix, 
        storeName,
        inCarLoadWght,
        inCarLoadCube,
        outCarLoadWght,
        outCarLoadCube,
        inTlWght,
        inTlCube,
        outTlWght,
        outTlCube,
        storageInv,
        accessInv,
        wrInvoice,
        generalInv,
        freightInv,
        cartageInv, 
        storageVarPer,
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  storerServiceCreate5(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    billPrinter:any,
    pickPrinter:any,
    controlPrinter:any,
    wrPrinter:any,
    storagePrinter:any,
    genInvPrinter:any,
    cartInvPrinter:any,
    aiPrinter:any,
    freightPrinter:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/create5', {
    storeNumber,
    suffix, 
    storeName,
    billPrinter,
    pickPrinter,
    controlPrinter,
    wrPrinter,
    storagePrinter,
    genInvPrinter,
    cartInvPrinter,
    aiPrinter,
    freightPrinter,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  storerServiceCreate6(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    winsCommsId:any,
    dunsNumber:any,
    custEdiComms:any,
    chepUser:any,
    user856:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/create6', {
    storeNumber,
    suffix, 
    storeName,
    winsCommsId,
    dunsNumber,
    custEdiComms,
    chepUser,
    user856,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  storerServiceUpdate1(
    storeNumber:any,
    suffix:any,
    StoreName:any,
    address1:any,
    address2:any,
    address3:any,
    zipCode:any,
    zipSuffix:any,
    areaCode:any,
    phoneExtension:any,
    phoneNumber:any,
    primaryRep:any,
    creditLimit:any,
    creditHold:any,
    building:any,
    keepQtyByLoc:any,
    standardClose:any,
    midMnthCutOff:any,
    midMnthCutOffDt:any,
    forcePsBlPrint:any,
    comboPsBlForm:any,
    cutsAllowed:any,
    blindTally:any,
    chepUser:any,
    preventConsodl:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/update1', {
      storeNumber,
		  suffix, 
		  StoreName,
		  address1,
		  address2,
		  address3,
		  zipCode,
		  zipSuffix,
		  areaCode,
		  phoneExtension,
		  phoneNumber,
		  primaryRep,
		  creditLimit,
		  creditHold,
		  building,
		  keepQtyByLoc,
		  standardClose,
		  midMnthCutOff,
		  midMnthCutOffDt,
		  forcePsBlPrint,
		  comboPsBlForm,
		  cutsAllowed,
		  blindTally,
		  chepUser,
		  preventConsodl,
      user
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  storerServiceUpdate2(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    user1:any,
    user2:any,
    user3:any,
    user4:any,
    allItemsLotted:any,
    lotCodeLabel1:any,
    lotCodeLabel2:any,
    lotCodeLabel3:any,
    sortmaskPointer:any,
    sortMask1:any,
    sortMask2:any,
    sortMask3:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/update2', {
        storeNumber,
        suffix, 
        storeName,
        user1,
        user2,
        user3,
        user4,
        allItemsLotted,
        lotCodeLabel1,
        lotCodeLabel2,
        lotCodeLabel3,
        sortmaskPointer,
        sortMask1,
        sortMask2,
        sortMask3,
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }
  

  storerServiceUpdate3(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    freightCharge:any,
    defaultCOD:any,
    defHandlingPer:any,
    rcrStorage:any,
    rcrBasis:any,
    rcvStorage:any,
    rcvBasis:any,
    regHandling:any,
    regBasis:any,
    altHandling:any,
    altBasis:any,
    distHandling:any,
    distBasis:any,
    liablBasis:any, 
    liablAmount:any,
    wrLotMin:any,
    storageLotMin:any,
    wrItemHandMin:any,
    storageItemMin:any,
    wrItemStorageMin:any,
    storageInvoiceMin:any,
    wrStorageMin:any,
    wrHandlingMin:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/update3', {
    storeNumber,
    suffix, 
    storeName,
    freightCharge,
    defaultCOD,
    defHandlingPer,
    rcrStorage,
    rcrBasis,
    rcvStorage,
    rcvBasis,
    regHandling,
    regBasis,
    altHandling,
    altBasis,
    distHandling,
    distBasis,
    liablBasis, 
    liablAmount,
    wrLotMin,
    storageLotMin,
    wrItemHandMin,
    storageItemMin,
    wrItemStorageMin,
    storageInvoiceMin,
    wrStorageMin,
    wrHandlingMin,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  storerServiceUpdate4(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    inCarLoadWght:any,
    inCarLoadCube:any,
    outCarLoadWght:any,
    outCarLoadCube:any,
    inTlWght:any,
    inTlCube:any,
    outTlWght:any,
    outTlCube:any,
    storageInv:any,
    accessInv:any,
    wrInvoice:any,
    generalInv:any,
    freightInv:any,
    cartageInv:any, 
    storageVarPer:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/update4', {
        storeNumber,
        suffix, 
        storeName,
        inCarLoadWght,
        inCarLoadCube,
        outCarLoadWght,
        outCarLoadCube,
        inTlWght,
        inTlCube,
        outTlWght,
        outTlCube,
        storageInv,
        accessInv,
        wrInvoice,
        generalInv,
        freightInv,
        cartageInv, 
        storageVarPer,
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }
  
  storerServiceUpdate5(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    billPrinter:any,
    pickPrinter:any,
    controlPrinter:any,
    wrPrinter:any,
    storagePrinter:any,
    genInvPrinter:any,
    cartInvPrinter:any,
    aiPrinter:any,
    freightPrinter:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/update5', {
    storeNumber,
    suffix, 
    storeName,
    billPrinter,
    pickPrinter,
    controlPrinter,
    wrPrinter,
    storagePrinter,
    genInvPrinter,
    cartInvPrinter,
    aiPrinter,
    freightPrinter,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  storerServiceUpdate6(
    storeNumber:any,
    suffix:any, 
    storeName:any,
    winsCommsId:any,
    dunsNumber:any,
    custEdiComms:any,
    chepUser:any,
    user856:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/update6', {
    storeNumber,
    suffix, 
    storeName,
    winsCommsId,
    dunsNumber,
    custEdiComms,
    chepUser,
    user856,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  writeBalanceRecord(
    storeNumber:any,
    suffix:any,
    balanceDate:any,
    recordType:any,
    
  ): Observable<any> {
    
    return this.httpClient
   
      .post<any>(this.url+'/storer/writeBalanceRecords', {
      storeNumber,
		  suffix, 
		  balanceDate,
		  recordType,
		  
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  rebalanceOptionService(
    storeNumber:any,
    suffix:any,
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storer/rebalanceOption', {
    storeNumber,
    suffix,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }



  storerServiceDeactivate(storeNumber: any, suffix: any): Observable<any> {
    return this.httpClient
      .delete<any>(this.url+`/storer/deactivate?storeNumber=${storeNumber}&suffix=${suffix}`)
      .pipe(retry(1), catchError(this.httpError));
  }

  storerServiceDelete(storeNumber: any, suffix: any): Observable<any> {
    return this.httpClient
      .delete<any>(this.url+`/storer/delete?storeNumber=${storeNumber}&suffix=${suffix}`)
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteStorerContact(user: any, name: any, type :any,storer:any): Observable<any> {
    return this.httpClient
      .delete<any>(this.url+`/storercontact/delete?user=${user}&name=${name}&type=${type}&storer=${storer}`)
      .pipe(retry(1), catchError(this.httpError));
  }


  //---- STORER COMMENTS ------

  readStorerCommentService(user:any, storer: any, subType: any, sequence:any): Observable<any> {
    return this.httpClient
      .get<any>(this.url+`/storerComments/read?user=${user}&storer=${storer}&subType=${subType}&sequence=${sequence}`)
      .pipe(retry(1), catchError(this.httpError));
  }

  createStorerCommentService(
    storer:any,
    subType:any,
    sequence:any,
    comment:any,  
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .post<any>(this.url+'/storerComments/create', {
        storer,
        subType,
        sequence,
        comment,  
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateStorerCommentService(
    storer:any,
    subType:any,
    sequence:any,
    comment:any,  
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/storerComments/update', {
        storer,
        subType,
        sequence,
        comment,  
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteStorerCommentService(user:any, storer: any, subType: any, sequence:any): Observable<any> {
    return this.httpClient
      .delete<any>(this.url+`/storerComments/delete?user=${user}&storer=${storer}&subType=${subType}&sequence=${sequence}`)
      .pipe(retry(1), catchError(this.httpError));
  }




  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
 


}
