import { IitemService } from './../iitem.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ItemUpdate2Component } from '../item-update2/item-update2.component';

@Component({
  selector: 'app-item-update1',
  templateUrl: './item-update1.component.html',
  styleUrls: ['./item-update1.component.css'],
})
export class ItemUpdate1Component implements OnInit {
  constructor(
    private router: Router,
    public itemSvc: IitemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemUpdate1Component>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({

      storeNumber: this.anyvariable.ITSTOR,
      suffix: this.anyvariable.ITSSFX,
      item: this.anyvariable.ITEM, 
      
      itemDescription1: this.anyvariable.ITDSC1, 
      itemDescription2: this.anyvariable.ITDSC2, 
      itemUpcCode: this.anyvariable.ITUPCC,
      itemUpcTailCode: this.anyvariable.ITTAIL, 
      manufacturerProdCode: this.anyvariable.ITPDCD, //Product Code
      quickItem: this.anyvariable.ITQUIC, //Allow Web Selection
      lottedItem: this.anyvariable.ITLOTT, 
      lotUnitWeight: this.anyvariable.ITLUWT,
      lotSortMask: this.anyvariable.ITSRMK,
      primSortMaskPointer: this.anyvariable.ITLTLB, //Apply to Lot
      prevItem: this.anyvariable.ITPREV, // Place After
    });
  }

  itemUpdate1(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemServiceUpdate1( 
        
        res.storeNumber,
        res.suffix,
        res.item,
        res.itemDescription1,
        res.itemDescription2,
        res.itemUpcCode,
        res.itemUpcTailCode,
        res.manufacturerProdCode,
        res.quickItem,
        res.lottedItem,
        res.lotUnitWeight,
        res.lotSortMask,
        res.primSortMaskPointer,
        res.prevItem
        

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       //console.log(this.result[0].data[16].value);
       console.log("*********************");
       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Successfully Updated'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.update2();
          this.router.navigate(['/main-nav/item/read']);
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg= this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  update2() {
    var res = this.anyvariable;
    this.matDialog.open(ItemUpdate2Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

}
