<!-- <p>
  {{ TallyHeaders.type }}
   {{ TallyHeaders.pgm }} 
</p> -->
<!-- <button mat-icon-button (click)="closeDialog()">           
  <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button> -->
<!-- <mat-card > -->
<!-- <mat-card-title class="title">{{
    "update" ? "Update Tally" : "Add Tally"
  }}</mat-card-title> -->

<!-- <mat-grid-list cols="1" rows="1" rowHeight="2:1">
    <mat-grid-tile > -->

<form [formGroup]="form" class="form over-Flow">
  <mat-card>
    <mat-card-header>
      <mat-card-title>
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon>navigate_before </mat-icon></button
        >Tally {{ element.IHTALY }}
      </mat-card-title>
    </mat-card-header>

    <mat-card-content class="serach-form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Storer </mat-label>
            <input  readonly matInput required formControlName="storer" value="{{fullStorer}}"/>
            <mat-error *ngIf="form.controls['storer'].hasError('pattern')">
              Only <strong>numbers </strong> are allowed
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Storer Reference </mat-label>
            <input
              matInput
              formControlName="storerRef"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <!-- <mat-form-field appearance="outline" class="full-width">
            <mat-label> Building </mat-label>
            <input
              matInput readonly
              value="{{ element.IHBLDG }}"
              [readonly]="true"
              style="text-transform: uppercase"
            />
          </mat-form-field> -->

          <mat-form-field appearance="outline">
            <mat-label> Bill To </mat-label>
            <input
              matInput
              formControlName="billTo"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <div>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> Invoice: </mat-label>
              <input
                matInput
                formControlName="invoice"
                style="text-transform: uppercase"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> Building: </mat-label>
              <input readonly
                matInput
                formControlName="building"
                style="text-transform: uppercase"
              />
            </mat-form-field>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> Status: </mat-label>
              <!-- <input
                matInput
                formControlName="status"
                style="text-transform: uppercase"
              /> -->
              <mat-select formControlName="status">
                <mat-option value="I"> I = Intransit </mat-option>
                <mat-option value="A"> A = Arrived (at dock) </mat-option>
                <mat-option value="P"> P = Put Away </mat-option>
                <mat-option value="S"> S = Submitted for W/R print </mat-option>
                <mat-option value="W"> W = W/R printed </mat-option>
                <mat-option value="D"> D = Deleted </mat-option>
                <mat-option value="C"> c = Cancelled </mat-option>
              </mat-select>
            </mat-form-field>
          </div>


          <!-- <mat-form-field appearance="outline" class="full-width">
            <mat-label> Status </mat-label>
            <input
              matInput readonly
              value="{{ element.IHRCPS }}"
              [readonly]="true"
              style="text-transform: uppercase"
            />
          </mat-form-field> -->

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Load Type </mat-label>
            <mat-select formControlName="loadType">
              <mat-option value="S">S = Slip Sheet </mat-option>
              <mat-option value="F">F = Floor unloaded </mat-option>
              <mat-option value="C">C = Clamp </mat-option>
              <mat-option value="P">P = Pallet </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Receipt Type </mat-label>
            <mat-select formControlName="recptType">
              <mat-option value="L"> L = Less than truckload </mat-option>
              <mat-option value="C"> C = Car load </mat-option>
              <mat-option value="S"> S = 20 Ft. Container </mat-option>
              <mat-option value="O"> O = Container </mat-option>

              <mat-option value="P"> P = Pallet</mat-option>
              <mat-option value="T"> T = Truckload</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Received from </mat-label>
            <input
              matInput
              required
              formControlName="recvdFrom"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Total Quantity: </mat-label>
            <input matInput formControlName="qty" />
            <mat-error *ngIf="form.controls['qty'].hasError('required')">
              Quantity From is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Returned </mat-label>
            <mat-select appearance="outline" formControlName="returnValue">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Authorization </mat-label>
            <input
              matInput
              formControlName="authorization"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Scheduled Arrival </mat-label>
            <input
              matInput
              placeholder="Scheduled Arrival"
              formControlName="schdldArrival"
              [matDatepicker]="schdldArrivalDatePicker"
              [min]="minDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="schdldArrivalDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #schdldArrivalDatePicker></mat-datepicker>
            <input
            type="time"
            id="exampleInput"
            name="input"
            placeholder="HH:mm"
            value="{{ this.element.IHSCHT }}"
            formControlName="schdldtime"
            required
          />
          </mat-form-field>
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Actual Arrival </mat-label>
            <input
              matInput
              formControlName="actualArrival"
              [matDatepicker]="actualArrivalDatePicker"
              [min]="minDate"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="actualArrivalDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #actualArrivalDatePicker></mat-datepicker>
            <input type="time" value="{{ element.IHACTT }}" formControlName="actualtime" required />
          </mat-form-field>

          

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Pro Number </mat-label>
            <input
              matInput
              formControlName="pro"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Carrier</mat-label>
            <input
              matInput
              formControlName="carrier"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Car/Trailer No: </mat-label>
            <input
              matInput
              formControlName="car"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <div>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Seal 1</mat-label>
              <input
                matInput
                formControlName="seal1"
                style="text-transform: uppercase"
              />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Broken</mat-label>
              <mat-select formControlName="broken1">
                <mat-option value="Y">Yes</mat-option>
                <mat-option value="N">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div>
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Seal 2</mat-label>
              <input
                matInput
                formControlName="seal2"
                style="text-transform: uppercase"
              />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Broken</mat-label>
              <mat-select formControlName="broken2">
                <mat-option value="Y">Yes</mat-option>
                <mat-option value="N">No</mat-option>
              </mat-select>
            </mat-form-field>
            </div>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Notification</mat-label>
            <mat-select formControlName="notification">
              <mat-option value="D"> D = Receiving Documents exist</mat-option>
              <mat-option value="E"> E = Electronic/EDI </mat-option>
              <mat-option value="N"
                >N = no notification was received</mat-option
              >
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Invoice Date</mat-label>
            <input
              matInput
              formControlName="invoiceDate"
              [matDatepicker]="invoiceDatePicker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="invoiceDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #invoiceDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Special Charge</mat-label>
            <mat-select formControlName="specialCharge">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="S">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Override Weight</mat-label>
            <input matInput formControlName="wgt" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Charge Type </mat-label>
            <mat-select formControlName="chargeType">
              <mat-option value="N">N = No Charge W/R </mat-option>
              <mat-option value="D"> D = Distribution Only W/R </mat-option>
              <mat-option value="S">S = Storage Only W/R </mat-option>
              <mat-option value="H"> H = Handling Only W/R </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <label> Charge Type : </label> &nbsp;
                <mat-form-field class="full-width">
                  <input matInput formControlName="wgt" />
                </mat-form-field> -->

          <button
            class="buttons addButton"
            *ngIf="update"
            mat-raised-button
            (click)="updateTally(tallyId)"
          >
            Update
          </button>
        </div>
      </div>
      <!-- <label> Charge Type : </label> &nbsp;
                <mat-form-field class="full-width">
                  <input matInput formControlName="wgt" />
                </mat-form-field> -->
    </mat-card-content>
    <!-- <button
            class="buttons addButton"
            *ngIf="update"
            mat-raised-button
            (click)="updateTally(tallyId)"
            [disabled]="!form.dirty"
          >
            Update
          </button> -->
    <!-- <button
            class="buttons addButton"
            *ngIf="!update"
            mat-raised-button
            (click)="addTally()"
            [disabled]="!form.valid"
          > 
            update
          </button> -->
  </mat-card>
</form>
<!-- </mat-grid-tile>
  </mat-grid-list>
</mat-card> -->
