import { IitemService } from './../../iitem.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IItemLocationService } from '../i-item-location.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-createitem-location',
  templateUrl: './createitem-location.component.html',
  styleUrls: ['./createitem-location.component.css'],
})
export class CreateitemLocationComponent implements OnInit {
  constructor(
    public iItemLcnSvc: IItemLocationService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<CreateitemLocationComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({
      storer: this.anyvariable.ITSTOR,
      suffix: this.anyvariable.ITSSFX,
      item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
      buildingnumber: this.anyvariable.ITBLDG,
      Floor: '',
      Aisle: '',
      Slot: '',
      sortseqNumber: '',
      lotCode1: '',
      lotCode2: '',
      lotCode3: '',
      weight: ['', [Validators.pattern('[0-9]{1,7}(.[0-9]{1,2})?')]],
      ReceivedDate: ['', Validators.required],
    });
  }
  dataSource: any;
  duplicateSource: any;

  closeDialog() {
    this.dialogRef.close();
  }

  addItemLocation() {
    var res = this.form.value;
    var x = 4;
    for (var i = res.Aisle.length; i < x; i++) {
      res.Aisle = ' ' + res.Aisle;
    }

    for (var i = res.Slot.length; i < x; i++) {
      res.Slot = ' ' + res.Slot;
    }
    for (var i = res.buildingnumber.length; i < 3; i++) {
      res.buildingnumber = ' ' + res.buildingnumber;
    }
    this.iItemLcnSvc
      .createItemLocation(
        res.buildingnumber,
        res.Floor,
        res.Aisle,
        res.Slot.toUpperCase(),
        res.storer,
        res.suffix,
        this.anyvariable.ITEM,
        res.lotCode1,
        res.lotCode2,
        res.lotCode3,
        res.weight,
        res.ReceivedDate,
        res.sortseqNumber
      )
      .subscribe((data) => {
        this.result = data;
        // this.TallyHeaders = data;
        var res = this.result[0].data[15].value;
        console.log(this.result);

        if (res == 'Successfully Created') {
          this.successMessage(res);
          this.closeDialog();
          this.router.navigate(['/main-nav/item/read']);
        } else {
          this.failureMessage(res);
          //this.closeDialog();
        }
      });
  }
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }
}
