import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-labor-plan-add',
  templateUrl: './ww-labor-plan-add.component.html',
  styleUrls: ['./ww-labor-plan-add.component.css']
})
export class WwLaborPlanAddComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user = 'NKOHAN';

  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwLaborPlanAddComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      T: '',
      DocumentNumber: '',
      CR: '',
      RP: '',
      BuildingLocation: '',
      Storer: '',
      Date: '',
      Time: '',
      Actv: '',
      SCAC: '',
      Operator: '',
      Location: ''

     });
  }

  addLaborPlan(){
    
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
