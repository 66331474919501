import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';


@Component({
  selector: 'app-labor-plan-create',
  templateUrl: './labor-plan-create.component.html',
  styleUrls: ['./labor-plan-create.component.css']
})
export class LaborPlanCreateComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<LaborPlanCreateComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      LBDOCT:'',//a, i ,o
      LBDOCN:'',
      MDATE:'',
      MTIME:'',
      LBLOCN:'',
      LBPROC:''
    })
  }

  add(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


}
