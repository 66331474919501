import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StorerCreate5Component } from '../storer-create5/storer-create5.component';
import { StorerServiceService } from '../storer-service.service';

@Component({
  selector: 'app-storer-create4',
  templateUrl: './storer-create4.component.html',
  styleUrls: ['./storer-create4.component.css']
})
export class StorerCreate4Component implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerCreate4Component>,
   private matDialog: MatDialog
    ) { }

  ngOnInit(): void {

    console.log(this.anyvariable.storerNumber);
       this.form = this.fb.group({
        
        storeNumber: this.anyvariable.storeNumber, // STORE#
        suffix: this.anyvariable.suffix, // STSFX
        storeName: this.anyvariable.storeName, // STNAME
        clInboundWeight:'',//STCLIW     
        clInboundCube:'',  //STCLIC
        clOutboundWeight:'',//STCLOW    
        clOutboundCube:'',  //STCLOC
        tlInboundWeight:'',//STTLIW     
        tlInboundCube:'',  //STTLIC
        tlOutboundWeight:'',//STTLOW     
        tlOutboundCube:'',  //STTLOC
        storageInvoice:'',//STSTIA     
        accessorialInvoice:'',  //STASIA
        wrInvoice:'',//STWRIA    
        generalInvoice:'',  //STGNIA
        freightInvoive:'',//STFBIA     
        cartageInvoice:'',  //STCAIA
        storageVariancePercent:[
          '',
          [            
            Validators.maxLength(10),            
          ],
         ]//STINVA
       });
  }

  storerCreate4(){
    var res = this.form.value;
       
    this.result = this.storerSvc
      .storerServiceCreate4( 
        res.storeNumber,
        res.suffix,
        res.storeName,
        res.clInboundWeight,
        res.clInboundCube,
        res.clOutboundWeight,
        res.clOutboundCube,
        res.tlInboundWeight,
        res.tlInboundCube,
        res.tlOutboundWeight,
        res.tlOutboundCube,
        res.storageInvoice,
        res.accessorialInvoice,
        res.wrInvoice,
        res.generalInvoice,
        res.freightInvoive,
        res.cartageInvoice,
        res.storageVariancePercent,
        this.user

      ).subscribe((data: any) => {
        this.result = data;
        if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
          const msg= this.result[0].data[this.result[0].data.length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.create5();
       }else{
        this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  create5() {
    var res = this.form.value;
    this.matDialog.open(StorerCreate5Component, {      
      width: '1000px',
      maxHeight: '80vh',
      data: res,
      autoFocus: false,
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
