import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-extract-report-whereclauses-delete',
  templateUrl: './extract-report-whereclauses-delete.component.html',
  styleUrls: ['./extract-report-whereclauses-delete.component.css']
})
export class ExtractReportWhereclausesDeleteComponent {
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';

  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ExtractReportWhereclausesDeleteComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      ExtractName:'ACTIVITY',
      FileName:'SACTWF',
      WhereClause:'SASTOR = {STORER}'

     });
  }

  deleteExtractReport(){
    
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
