import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IbuildingsService {

  url = environment.API_BASE_URL;
  
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  getBuilding(user: any, building:any): Observable<any> {
    return this.httpClient.get(
      this.url + `/bucrud/read?user=${user}&building=${building}`
    );
  }

  getAll(user: any): Observable<any> {
    return this.httpClient.get(
      this.url + `/bucrud/readAll?user=${user}`
    );
  }

  createBuilding(
    user:any,
    building: any,
    name: any,
    address1: any,
    address2: any,
    zipcode: any,
    suffix: any,
    numberofdoors: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/bucrud/create', {
        user,
        building,
        name,
        address1,
        address2,
        zipcode,
        suffix,
        numberofdoors,
        
       
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateBuilding( user:any,
    building: any,
    name: any,
    address1: any,
    address2: any,
    zipcode: any,
    suffix: any,
    numberofdoors: any): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/bucrud/update', {
        user,
        building,
        name,
        address1,
        address2,
        zipcode,
        suffix,
        numberofdoors
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteBuilding(user: any,building:any): Observable<any> {
    return this.httpClient.delete(
      this.url + `/bucrud/delete?user=${user}&building=${building}`
    );
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
