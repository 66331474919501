import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StorerServiceService } from '../storer-service.service';
import { StorerUpdate2Component } from '../storer-update2/storer-update2.component';

@Component({
  selector: 'app-storer-update',
  templateUrl: './storer-update.component.html',
  styleUrls: ['./storer-update.component.css']
})
export class StorerUpdateComponent implements OnInit {

  constructor(
    public storerSvc: StorerServiceService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router:Router,
    private matDialog : MatDialog,
    private dialogRef: MatDialogRef<StorerUpdateComponent>
   ) 
   { }

  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');  

  ngOnInit(): void {
    this.form = this.fb.group({
      storeNumber: this.anyvariable.STORE,
      suffix:this.anyvariable.STSFX,
      storeName:[this.anyvariable.STNAME,[
        Validators.required,
      ]],
      address1:this.anyvariable.STADD1,
      address2:this.anyvariable.STADD2,
      address3:this.anyvariable.STADD3,
      zipCode:[this.anyvariable.STZIP,
        [
          Validators.required,
          Validators.pattern('[0-9]{5}'),
          
        ]],
      zipSuffix:this.anyvariable.STZSFX,
      areaCode: [this.anyvariable.STPHAR,[
          // Validators.min(3),
          // Validators.max(3),
          Validators.pattern('[0-9]{3}'),
      ]],
      phoneExtension: [this.anyvariable.STPHEX,[
          Validators.pattern('[0-9]{3}'),
      ]],
      phoneNumber: [this.anyvariable.STPHNU,[
        Validators.pattern('[0-9]{4}'),
      ]],
      primaryRep:this.anyvariable.STPREP,
      creditLimit:[this.anyvariable.STCRED,[
        
        Validators.min(0),
        Validators.pattern('[0-9]{1,10}'),
      ],],
      creditHold:[this.anyvariable.STCHLD,[
        Validators.required
        
      ],],
      building:this.anyvariable.STBLDG,
      trackQBL:this.anyvariable.STQTLO, //Keep Quality By Location
      standardClose:[this.anyvariable.STSTCL,[
        Validators.required
        
      ],],
      midMnthCutOff:this.anyvariable.STMMCD,
      midMnthCutOffDt:this.anyvariable.STMMCO,
      forcePSBL:this.anyvariable.STFPRT,
      comboPSBL:[this.anyvariable.STPSBL,[
        Validators.required
        
      ],],
      cutsAllowed:[this.anyvariable.STNCUT,[
        Validators.required
        
      ],],
      blindTally:this.anyvariable.STBLDT,
      chepPallets:[this.anyvariable.STCHEP,[
        Validators.required
        
      ],],
      preventConsolidation:[this.anyvariable.STNOCO,[
        Validators.required
        
      ],] 
       
    });
  }

  storerUpdate1(){
    var res = this.form.value;   
    this.result = this.storerSvc
      .storerServiceUpdate1( 
       res.storeNumber,
       res.suffix,
       res.storeName,
       res.address1,
       res.address2,
       res.address3,
       res.zipCode,
       res.zipSuffix,
       res.areaCode,
       res.phoneExtension,
       res.phoneNumber,
       res.primaryRep,
       res.creditLimit,
       res.creditHold,
       res.building,
       res.trackQBL,
       res.standardClose,
       res.midMnthCutOff,
       res.midMnthCutOffDt,
       res.forcePSBL,
       res.comboPSBL,
       res.cutsAllowed,
       res.blindTally,
       res.chepPallets,
       res.preventConsolidation,
       this.user

        ).subscribe((data: any) => {
        this.result = data;
      if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
          
         const msg= this.result[0].data[this.result[0].data.length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.update2();
      }else{
        this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
   this.dialogRef.close();
  }

  update2() {
    var res = this.anyvariable;
    this.matDialog.open(StorerUpdate2Component, {      
      width: '1000px',
      maxHeight: '80vh',
      data: res,
      autoFocus: false,
    });
  }

}
