import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwItemForecastDetailsCreateComponent } from './ww-item-forecast-details-create/ww-item-forecast-details-create.component';
import { WwItemForecastDetailsUpdateComponent } from './ww-item-forecast-details-update/ww-item-forecast-details-update.component';
import { WwItemForecastDetailsDeleteComponent } from './ww-item-forecast-details-delete/ww-item-forecast-details-delete.component';
@Component({
  selector: 'app-ww-item-forecast-details',
  templateUrl: './ww-item-forecast-details.component.html',
  styleUrls: ['./ww-item-forecast-details.component.css']
})
export class WwItemForecastDetailsComponent implements OnInit {


  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    
    'ScheduleDate',
    'Quantity',
    'LineSet',
    'Job No',
    'Item',
    'MRN No',
    'Status',
    'Actions',

   
  ];
  dataSource:any=[]


  duplicateSource:any =[
    {
      SDAT:'',
      IGQTYS:'',
      IGLSET:'',
      IGJOB:'',
      ITEM:'',
      MRN:'',
      IGSTAT:'',
        
    },
   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      STOR:'65',
      // IANUMB:'3',
      CONS:'065',
      FDAT:'12/09/19', 
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
 
}

ItemForecastDetailsCreateForm(){
  this.matDialog.open(WwItemForecastDetailsCreateComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
    //  'data': element,
     "autoFocus": false                           
    });
    }

    ItemForecastDetailsUpdateForm(element:any){
      this.matDialog.open(WwItemForecastDetailsUpdateComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        }
        ItemForecastDetailsDeleteForm(element:any){
          this.matDialog.open(WwItemForecastDetailsDeleteComponent, {
              "width": '1000px',
               "maxHeight": '90vh',
             'data': element,
             "autoFocus": false                           
            });
            }

            addAttribute(){

            }

            
             

}
