<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Freight Codes </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="addFreightCode()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>New Freight Code</span>
      </button>
    </div>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label> Code</mat-label>
            <input matInput formControlName="Code" />
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label> NMFC</mat-label>
            <input matInput formControlName="NMFC" />
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label> Description</mat-label>
            <input matInput formControlName="Description" />
          </mat-form-field>
          <button
            class="addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
    <ng-container matColumnDef="FreightCode">
      <th mat-header-cell *matHeaderCellDef>Freight Code</th>
      <td mat-cell *matCellDef="let element">{{ element.FreightCode }}</td>
    </ng-container>
    <ng-container matColumnDef="NMFCNumber">
      <th mat-header-cell *matHeaderCellDef>NMFC Number/Subcode</th>
      <td mat-cell *matCellDef="let element">{{ element.NMFCNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef>Freight Bill Description</th>
      <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
    </ng-container>

    <ng-container matColumnDef="UNCode">
      <th mat-header-cell *matHeaderCellDef>UN Code</th>
      <td mat-cell *matCellDef="let element">{{ element.UNCode }}</td>
    </ng-container>
    <ng-container matColumnDef="NACode">
      <th mat-header-cell *matHeaderCellDef>NA Code</th>
      <td mat-cell *matCellDef="let element">{{ element.NACode }}</td>
    </ng-container>
    <ng-container matColumnDef="Hazmat">
      <th mat-header-cell *matHeaderCellDef>Hazmat</th>
      <td mat-cell *matCellDef="let element">{{ element.Hazmat }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select placeholder="Select Option">
          <mat-option>
            <button mat-button (click)="editFreightCode()">
              <mat-icon mat-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-button (click)="billTextFreightCode()">
              <mat-icon mat-button color="primary">call_made</mat-icon>
              <mat-label>Bill Text</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-button (click)="delete()">
              <mat-icon mat-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
