import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ConsigeeService } from '../../consignee/consigee.service';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-update2',
  templateUrl: './order-update2.component.html',
  styleUrls: ['./order-update2.component.css']
})
export class OrderUpdate2Component implements OnInit {

  constructor(
    public orderSvc: OrderService, 
    public consigeeService: ConsigeeService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router:Router,
   private dialogRef: MatDialogRef<OrderUpdate2Component>
   ) 
   { }

   storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
   duplicateSource:any;
   dataSource:any;

   consName:any;
   consAdrs1:any;
   consAdrs2:any;
   consAdrs3:any;
   consAdrs4:any;

   bol:any;
   status:any;
   store:any;
   sfx:any;
   ref:any;
   purchase:any;
   consNo:any;


  ngOnInit(): void {

    this.consName='';
    this.consAdrs1='';
    this.consAdrs2='';
    this.consAdrs3='';
     this.consAdrs4='';

     this.bol='';
    this.status='';
    this.store='';
    this.sfx='';
     this.ref='';
     this.purchase='';
     this.consNo='';
///////////////////////////////////////////////////////////////////////////
    this.consigeeService.getConsignee(this.user,this.anyvariable[0].consignee,'').subscribe((data) => {   
      this.result = data;
          var res = this.result[0].data[1]?.value;
          console.log(res);
          var parsedData = JSON.parse(res);
          console.log(parsedData);         
           this.dataSource = parsedData.returnedData;    
          console.log(this.dataSource[0]);   
          
          this.consName = this.dataSource[0].CONAME;
          this.consAdrs1 = this.dataSource[0].COADD1;
          this.consAdrs2 = this.dataSource[0].COADD2;
          this.consAdrs3 = this.dataSource[0].COADD3;
          this.consAdrs4 = this.dataSource[0].COADD4;                   
    });  
//////////////////////////////////////////////////////////////
          this.orderSvc.readOrderService(this.user, this.anyvariable[0].bolNumber, '', '', '', '', '', '', '', '', '').subscribe((data) => {
            this.result = data;
            console.log(this.result);
            var response = this.result[0].data[1]?.value;

            var parsedData = JSON.parse(response);
            this.duplicateSource = parsedData.returnedData;
            console.log(this.duplicateSource);

            this.bol=this.duplicateSource[0].OHBILL;
            this.status=this.duplicateSource[0].OHSTAT;
            this.store=this.duplicateSource[0].OHSTOR;
            this.sfx=this.duplicateSource[0].OHSSFX;
            this.purchase=this.duplicateSource[0].OHPO;
            this.consNo=this.duplicateSource[0].OHCONS;

          });
////////////////////////////////////////////////////////////////

    this.form = this.fb.group({

      bolNumber:this.bol,
      orderStatus:this.status,
      storer:this.store,
      suffix:this.sfx,
      storerRef:this.ref,
      purchaseOrder:this.purchase,
      consignee:this.consNo,

    schedShipDate:this.anyvariable[1].OHSCHS,
    schedArrivalDate:this.anyvariable[1].OHSCHA,
    shipType:this.anyvariable[1].OHSHPT,
    carrierCode:this.anyvariable[1].OHCAR,
    freightCharge:this.anyvariable[1].OHFCOV,
    codAmount:this.anyvariable[1].OHCOD,
    applyAutoCharge:this.anyvariable[1].OHCHGF,
    billToCustomer:this.anyvariable[1].OHSTRB,
    billToSuffix:this.anyvariable[1].OHSFXB,
    brokerName:this.anyvariable[1].OHBRKR,
    totalQuantity:this.anyvariable[1].OHHQTY,
    shipOrderFrom:this.anyvariable[1].OHSHFR,
    overrideQty:this.anyvariable[1].OHOQTY,
    building:this.anyvariable[1].OHBLDG,
    zone:this.anyvariable[1].OHZONE,
    commitInventory:'',
    ctrl:this.anyvariable[1].OHBLCP,
    ctrlImmed:'',
    pick:this.anyvariable[1].OHPSPR,
    pickImmed:'',
    bol:this.anyvariable[1].OHBLPR,
    bolImmed:'',

    consigneeName:this.consName,
    address1:this.consAdrs1,
    address2:this.consAdrs2,
    address3:this.consAdrs3,
    address4:this.consAdrs4,
    address5:'', // Not getting from response
       
    });
  }

  orderUpdate2(){
    var res = this.form.value;
    console.log(res);
   
    this.result = this.orderSvc
      .orderServiceUpdate2( 
        
        this.bol,
        this.store,
        this.sfx,
        res.schedShipDate,
        res.schedArrivalDate,
        res.shipType,
        res.carrierCode,
        res.freightCharge,
        res.codAmount,
        res.applyAutoCharge,
        res.billToCustomer,
        res.billToSuffix,
        res.brokerName,
        res.totalQuantity,
        res.shipOrderFrom,
        res.overrideQty,
        res.building,
        res.ctrl,
        res.ctrlImmed,
        res.zone,
        res.commitInventory,
        res.pick,
        res.pickImmed,
        res.bol,
        res.bolImmed
        

        ).subscribe((data: any) => {
        this.result = data;

       var length = this.result[0].data.length;

          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Created/Updated Successfully'){
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
        
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/orders/read']);
  }


  closeDialog(){
    this.dialogRef.close();
  }


  backstorer(){
    
  }

}