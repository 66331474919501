<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Labor Plan Groups</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="addGroup()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>Create Group</span>
      </button>
    </div>

    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Group Name</mat-label>
            <input matInput formControlName="NAME" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Building</mat-label>
            <input matInput formControlName="BLDG" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="GroupName">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Group Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.GroupName }}</td>
    </ng-container>

    <ng-container matColumnDef="Building">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Building
      </th>
      <td mat-cell *matCellDef="let element">{{ element.Building }}</td>
    </ng-container>

    <ng-container matColumnDef="MaximumConcurrent">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Maximum Concurrent
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MaximumConcurrent }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Status }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="updateGroup()">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="deleteGroup()">
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="detailsGroup()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Details</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
                    #paginator
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                  >
                  </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
