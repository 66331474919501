import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IItemLocationService } from 'src/app/features/item/item-locations/i-item-location.service';

@Component({
  selector: 'app-stock-status',
  templateUrl: './stock-status.component.html',
  styleUrls: ['./stock-status.component.css']
})
export class StockStatusComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private activeRouter: ActivatedRoute,
    public iItemLcnSvc: IItemLocationService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,   
    private dialogRef: MatDialogRef<StockStatusComponent>
  ) {}

  displayedColumns: string[] = [
    'location',
    'f',
    'lotCodes',
    'lotRcv',
    'actual',
    'avail',
    'commit'
  ];

  @ViewChild('paginator') paginator!: MatPaginator;
  dataSource: any;
  duplicateSource: any;
  result: any;
  user = localStorage.getItem('userName');

  form = this.fb.group({
    storer: this.anyvariable[1].IDSTOR,
   // item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
   item:this.anyvariable[1].IDITEM,
   upc:'',
   inTrans:'',
   futureAllocate: '',
    location: '',
    lot1: '',
    lot2:'',
    lot3:''
  });

  ngOnInit(): void {

    var res = this.form.value;

    this.iItemLcnSvc.getItemLocation(
        this.user,
        this.anyvariable[0].IHBLDG,
        res.storer,
        res.item
      ).subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log("TALLY DTL STOCK STATUS DATA : (SAME AS ITEM LOCATION)");
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
