import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-forecast-delete',
  templateUrl: './forecast-delete.component.html',
  styleUrls: ['./forecast-delete.component.css'],
})
export class ForecastDeleteComponent implements OnInit {
  deleteForecastForm!: FormGroup;
  constructor(
    private dialogRef: MatDialogRef<ForecastDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private fb: FormBuilder,
    private router: Router
  ) {}

  delete() {}
  closeDialog() {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.deleteForecastForm = this.fb.group({
      IFSTOR: [this.data.IFSTOR],
      IFCONS: [this.data.IFCONS],
      FDAT: [this.data.FDAT],
      IFFTYP: [this.data.IFFTYP],
      IFDTYP: [this.data.IFDTYP],
      BHOR: [this.data.BHOR],
      EHOR: [this.data.EHOR],
      IFDLOC: [this.data.IFDLOC],
      IFQTYP: [this.data.IFQTYP],
      IFUOM: [this.data.IFUOM],
      IFSPLR: [this.data.IFSPLR],
      IFSREF: [this.data.IFSREF],
      IFRELS: [this.data.IFRELS],
      IFSTAT: [this.data.IFSTAT],
    });
  }
}
