<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">
      <button mat-button (click)="onBack()" class="backButton">
        <mat-icon>navigate_before </mat-icon>
      </button>
      Work With Order Details</mat-card-title
    >

    <div class="buttons">
      <button mat-button (click)="addOrderDetail()" class="addButton">
        <mat-icon>add</mat-icon>
        <span>New Order Detail</span>
      </button>

      <mat-menu #mainMenuButton="matMenu">
        <span
          (click)="$event.stopPropagation()"
          *ngFor="let column of columnShowHideList; index as i"
        >
          <mat-slide-toggle
            style="color: blue"
            [(ngModel)]="column.isActive"
            (change)="toggleColumn(column)"
          >
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button
        mat-button
        matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>
    </div>
  </mat-card-header>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <mat-form-field appearance="outline">
        <mat-label>Bill of Lading</mat-label>
        <input matInput formControlName="Bill_of_Lading" readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Storer</mat-label>
        <input matInput formControlName="Storer" readonly />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>total Quantity</mat-label>
        <input matInput formControlName="total_Quantity" readonly />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Consignee</mat-label>
        <input matInput formControlName="Consignee" readonly />
      </mat-form-field>
    </form>
  </mat-card-content>

  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="Quantity">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Quantity</th>
      <td mat-cell *matCellDef="let element">{{ element.ODQTYS }}</td>
    </ng-container>
    <ng-container matColumnDef="ItemCodeDescription">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Item Code/ Description
      </th>
      <td mat-cell *matCellDef="let element">{{ element.ODITDS }}</td>
    </ng-container>
    <ng-container matColumnDef="Weight">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Weight</th>
      <td mat-cell *matCellDef="let element">{{ element.ODGRSW }}</td>
    </ng-container>
    <ng-container matColumnDef="Ovr">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Ovr</th>

      <td mat-cell *matCellDef="let element">{{ element.OVRFLG }}</td>
    </ng-container>
    <ng-container matColumnDef="Status">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.ODSTAT }}</td>
    </ng-container>

    <ng-container matColumnDef="ShipFrom">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Ship From</th>
      <td mat-cell *matCellDef="let element">{{ element.ODSHFR }}</td>
    </ng-container>

    <ng-container matColumnDef="Sequence">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Sequence</th>
      <td mat-cell *matCellDef="let element">{{ element.ODSEQ }}</td>
    </ng-container>

    <ng-container matColumnDef="Lot">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Lot</th>
      <td mat-cell *matCellDef="let element">{{ element.ODLOT1 }}</td>
    </ng-container>

    <ng-container matColumnDef="QuantityOrdered">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Quantity Ordered
      </th>
      <td mat-cell *matCellDef="let element">{{ element.ODQTYO }}</td>
    </ng-container>

    <ng-container matColumnDef="OriginalSequence">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Original Sequence
      </th>
      <td mat-cell *matCellDef="let element">{{ element.ODSSEQ }}</td>
    </ng-container>

    <ng-container matColumnDef="Location">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ODFLOR + " " + element.ODAISL + "/" + element.ODSLOT }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        <!--  Opt -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="orderDetailEdit(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Order-Detail-Edit</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailStackStatus(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Order-Stack-Status</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailItemStatus(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Order-Item-Status</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailsCommit(element)">
                <mat-icon mat-icon-button color="primary">comment</mat-icon>
                <mat-label>Commit</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailsDeCommit(element)">
                <mat-icon mat-icon-button color="primary">comment</mat-icon>
                <mat-label>De-Commit</mat-label>
              </button>
            </li>

            <li>
              <button
                mat-icon-button
                (click)="orderDetailsNonStockReas(element)"
              >
                <mat-icon mat-icon-button color="primary">comment</mat-icon>
                <mat-label>Non-Stock Reas</mat-label>
              </button>
            </li>

            <li>
              <button
                mat-icon-button
                (click)="orderDetailIStockActivity(element)"
              >
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Order-Stock-Activity</mat-label>
              </button>
            </li>

            <li>
              <button
                mat-icon-button
                (click)="orderDetailChnageShipFrom(element)"
              >
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Order-Change-Ship-From</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailSplitLine(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Order-Detail-Split-Line</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailCrossDocRef(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Order-Cross-Doc-Ref</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailComments(element)">
                <mat-icon mat-icon-button color="primary">comment</mat-icon>
                <mat-label>Order-Detail-Comments</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailCut(element)">
                <mat-icon mat-icon-button color="primary">delete</mat-icon>
                <mat-label>Order-Detail-Cut</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="changeEvent(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Change-Event</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="changeEventComments(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Change-Event-Comments</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderChangeLocLot(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Change-Location/Lot</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailLotStatus(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Lot-Status</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailForce(element)">
                <mat-icon mat-icon-button color="warn">call_made</mat-icon>
                <mat-label>Order-Details-Force</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailDeleteNos(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete Nos</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="hardAllocate(element)">
                <mat-icon mat-icon-button color="warn">call_made</mat-icon>
                <mat-label>Hard Allocate</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="removeHardAllocate(element)">
                <mat-icon mat-icon-button color="warn">call_made</mat-icon>
                <mat-label>Remove Hard Allocate</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailUcc128Maint(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>UCC-128-Maint</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="orderDetailMoveQueue(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Move-Queue</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <!-- <mat-form-field appearance="outline">
        <b><input matInput value={{this.element.OHHQTY}}/></b>
    </mat-form-field> -->

  <button mat-button class="tally-table-pagination">
    Total of detail quantities : <b>{{ this.element.OHHQTY }}</b>
  </button>

  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
