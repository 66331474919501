<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Maintain Item X-Ref</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" fxLayoutAlign="center" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Storer Number</mat-label>
            <input
              matInput
              formControlName="storer"
              style="text-transform: uppercase"
            />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Warehouse Item Code</mat-label>
            <input
              matInput
              formControlName="itemCode"
              style="text-transform: uppercase"
            />
          </mat-form-field>
        

        <!-- <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Consignee Code</mat-label>
                    <input matInput formControlName="consigneeCode" style="text-transform: uppercase"  />
                </mat-form-field>
                
            </div> -->

        
          <mat-form-field appearance="outline">
            <mat-label>Storer Item Code</mat-label>
            <input
              matInput
              formControlName="itemXcode"
              value="{{ duplicateSourcevalue }}"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col">
            <button
              class="buttons removeButton"
              mat-raised-button
              (click)="deleteConsigneeXref()"
            >
              Delete
            </button>

            <button
              class="buttons addButton"
              mat-raised-button
              (click)="updateItemXref()"
            >
              Update
            </button>

            <button
              class="buttons addButton"
              mat-raised-button
              (click)="createItemXref()"
            >
              Create
            </button>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
