import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwLotStatusAddComponent } from './ww-lot-status-add/ww-lot-status-add.component';
import { WwLotStatusChangeComponent } from './ww-lot-status-change/ww-lot-status-change.component';
import { WwLotStatusInquiryComponent } from './ww-lot-status-inquiry/ww-lot-status-inquiry.component';
@Component({
  selector: 'app-work-with-lot-status',
  templateUrl: './work-with-lot-status.component.html',
  styleUrls: ['./work-with-lot-status.component.css']
})
export class WorkWithLotStatusComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'StorerNumber',
    'ItemNumber',
    'LotCode',
    'DateHeld',
    'DateReleased',
    'St',
    'Expiration',
    'Received',
    'Action'
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      StorerNumber:'1',
      ItemNumber:'2',
      LotCode:'JAMES JAMES',
      DateHeld:'2-3-2001',
      DateReleased:'2-4-2001',
      St:'1',
      Expiration: '12',
      Received : '10'
    },

    {
      StorerNumber:'2',
      ItemNumber:'1',
      LotCode:'JAMES JAMES',
      DateHeld:'5-3-2001',
      DateReleased:'2-6-2001',
      St:'1',
      Expiration: '12',
      Received : '10'
    },

  ]
  constructor(private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      StorerNumber:'',
      ItemNumber:'',
      LotCode:'',
      Status:''

    })

    this.dataSource = this.duplicateSource;
  }
  addLotStatus()
  {
   this.matDialog.open(WwLotStatusAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  changeLotStatus()
  {
   this.matDialog.open(WwLotStatusChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  inquiryLotStatus()
  {
   this.matDialog.open(WwLotStatusInquiryComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

}
