import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../iheader.service';
import { MAT_DIALOG_DATA,MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { FormBuilder } from '@angular/forms';
import { UpdateTallyDtlCommentsComponent } from '../tally-detail-comments/update-tally-dtl-comments/update-tally-dtl-comments.component';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {

  constructor(private activeRouter:ActivatedRoute, 
    public iHeaderSvc: IheaderService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable:any,
    private dialogRef:MatDialogRef<CommentsComponent>) { }
    
  id:any
  result:any;
  user=localStorage.getItem('userName');
  displayedColumns: string[] = ['Comments', 'Actions'];
  dataSource:any;
  duplicateSource:any;

  status="";
  sequence="";
  sub = 'WR';
  commData="";
  chgy="";
  chgm="";
  chgd="";
  time="";
  wsid="";

    form = this.fb.group({
      commData:'',
    })

  ngOnInit(): void {
    this.getComment()
    console.log('TALLY DETAILS : ' + this.anyvariable)
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  getComment(){
    this.iHeaderSvc.getTallyComment(this.user,this.status, this.anyvariable.IHTALY,this.sequence,this.sub).subscribe((data) => {
      this.result = data;
      var res = this.result;
      var res = this.result[0].data[1]?.value;
      console.log(res);
      console.log(res);
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      //this.dataSource.replaceAll("BUILD#","BUILD");
      this.dataSource = new MatTableDataSource(this.duplicateSource);
      this.dataSource.paginator = this.paginator;
    });
  }

  createTallyComment() {
    var res = this.form.value;
    console.log('############################')
    console.log(res)
    
    this.result = this.iHeaderSvc
      .createTallyCommentService(
        this.user,
        this.status,
        this.anyvariable.IHTALY,
        this.sequence,
        this.sub,
        res.commData,
        this.wsid

       )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       if(JSON.parse(this.result[0].data[1].value).success){

        //alert('Record Created SuccessFully');

       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg);
        this. closeDialog();
        //this.router.navigate(['/main-nav/maplocation/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }

  updateTallyComment(res: any) {
    // this.router.navigate(['tallys/posting',tally]);
    this.matDialog.open(UpdateTallyDtlCommentsComponent, {
      width: '1100px',
      maxHeight: '80vh',
      data: res,
      autoFocus: false,
    });
    this.closeDialog();
  }


  deleteTallyComment(){
    this.iHeaderSvc.deleteTallyCommentService(
      this.user,
      this.status,
      this.anyvariable.IHTALY,
      this.sequence,
      this.sub

      ).subscribe((data) => {
      this.result = data;
      console.log(this.result);
      
      if(JSON.parse(this.result[0].data[1].value).Success){

        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
         this.successMessage(msg)
 
         this. closeDialog();
 
        // this.router.navigate(['/main-nav/tallys/details']);
      }else{
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
       this.failureMessage(msg);
      }
    });
  }


  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }



  closeDialog(){
    this.dialogRef.close();
  }

}
