<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title class="title">Work With Contacts</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Consignee Code </mat-label>
            <input matInput formControlName="ConsigneeCode" />
          </mat-form-field>
        
          <mat-form-field appearance="outline"
            ><mat-label> Contact Type </mat-label>
            <input matInput formControlName="ContactType" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label> Name </mat-label>
            <input matInput formControlName="Name" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label> Status </mat-label>
            <input matInput formControlName="Status" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="Type">
      <th mat-header-cell *matHeaderCellDef>Type</th>
      <td mat-cell *matCellDef="let element">{{ element.Type }}</td>
    </ng-container>

    <ng-container matColumnDef="ContactName">
      <th mat-header-cell *matHeaderCellDef width="60px">Contact Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ContactName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Telephone">
      <th mat-header-cell *matHeaderCellDef>Telephone</th>
      <td mat-cell *matCellDef="let element">{{ element.Telephone }}</td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
