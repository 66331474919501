<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title 
                    >Maintain Customer Pref</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Customer Number: </mat-label> 
                          <input matInput formControlName="customerNumber" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label>State Code: </mat-label> 
                          <input matInput formControlName="stateCode" />
                        </mat-form-field>
                  </div> 

                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Tariff Name: </mat-label> 
                      <input matInput formControlName="tariffName" /> 
                    </mat-form-field>
                    </div>
                 
                    <div class="col">
                        <mat-form-field appearance="outline">  <mat-label>Origin Zone: </mat-label> 
                          <input matInput formControlName="originZone" /> 
                        </mat-form-field>
                        </div>
                        
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>From Zip: </mat-label> 
                      <input matInput formControlName="fromZip" /> 
                    </mat-form-field>
                    </div>
                    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>To Zip: </mat-label> 
                      <input matInput formControlName="toZip" /> 
                    </mat-form-field>
                    </div>
                    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Scac Code: </mat-label> 
                      <input matInput formControlName="scacCode" /> 
                    </mat-form-field>
                    </div>
                    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Discount: </mat-label> 
                      <input matInput formControlName="discount" /> 
                    </mat-form-field>
                    </div>
                    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Inter State Min: </mat-label> 
                      <input matInput formControlName="interStateMin" /> 
                    </mat-form-field>
                    </div>
                    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Intra State Min: </mat-label> 
                      <input matInput formControlName="intraStateMin" /> 
                    </mat-form-field>
                    </div>
                    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Flate Rate: </mat-label> 
                      <input matInput formControlName="flateRate" /> 
                    </mat-form-field>
                    </div>
                    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Per Mile Rate: </mat-label> 
                      <input matInput formControlName="perMileRate" /> 
                    </mat-form-field>
                    </div>
                    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Custom Rate PGM: </mat-label> 
                      <input matInput formControlName="customRatePGM" /> 
                    </mat-form-field>
                    </div>
                    
                    <div class="col">
                        <mat-form-field appearance="outline">  <mat-label> Status  </mat-label> 
                            <mat-select matNativeControl required>
                            <mat-option value="Item">AC</mat-option>
                       
                            </mat-select> 
                        </mat-form-field>
                     </div>
                  
    
                                  
                            <div >
                              <button mat-button class="buttonCss" (click)="attributes()">CHANGE</button>
                          </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  



