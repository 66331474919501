<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Movement Queue </mat-card-title>
    <div class="buttons">
      <button mat-button (click)="searchTally()" class="addButton" *ngIf="searchTextDisplay">
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button mat-button (click)="searchTally()" class="addButton" *ngIf="!searchTextDisplay"
        matTooltip="Search Move Queue">
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>

      <mat-menu #mainMenuButton="matMenu">
        <span (click)="$event.stopPropagation()" *ngFor="let column of columnShowHideList; index as i">
          <mat-slide-toggle style="color: blue" [(ngModel)]="column.isActive" (change)="toggleColumn(column)">
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button mat-button class="addButton" matTooltip="Add New Building" matTooltip="Add Move Queue">
        <mat-icon>add</mat-icon>
        <!-- <span>New Move Queue</span> -->
      </button>

      <button mat-button matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn" #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton">
        <mat-icon>visibility_off</mat-icon>
      </button>
    </div>
  </mat-card-header>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="updateForm" (ngSubmit)="readOneMove(updateForm.value)" class="form over-Flow"
      *ngIf="searchTextDisplay">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Building</mat-label>
            <input matInput formControlName="building" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="storer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Suffix</mat-label>
            <input matInput formControlName="suffix" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Priority </mat-label>
            <input matInput formControlName="priority" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Document </mat-label>
            <input matInput formControlName="document" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> From </mat-label>
            <input matInput formControlName="from" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Operator </mat-label>
            <input matInput formControlName="operator" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Type </mat-label>
            <input matInput formControlName="type" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Item </mat-label>
            <input matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> To </mat-label>
            <input matInput formControlName="to" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Status </mat-label>
            <input matInput formControlName="sts" />
          </mat-form-field>

        </div>
      </div>          
      <button class="buttons addButton" mat-raised-button type="submit" placeholder="Search">
            Search
          </button>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
    <!-- Tally Column -->
    <ng-container matColumnDef="Priority">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Priority
      </th>
      <td mat-cell *matCellDef="let element">{{ element.MQPRTY }}</td>
    </ng-container>

    <ng-container matColumnDef="Item/Desctiption">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Item/Description
      </th>
      <td mat-cell *matCellDef="let element">{{ element.MQITEM }}</td>
    </ng-container>

    <ng-container matColumnDef="Quantity">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Quantity
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQQTY }}
      </td>
    </ng-container>

    <ng-container matColumnDef="I">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Item
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQITYP }}
      </td>
    </ng-container>

    <ng-container matColumnDef="From">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        From
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQBLDG }} &nbsp;&nbsp;{{ element.MQFRFL }}/
        {{ element.MQFRAI }} / {{ element.MQFRSL }}
      </td>
    </ng-container>

    <ng-container matColumnDef="To">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>To</th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQBLDG }}&nbsp;&nbsp;{{ element.MQTOFL }} /
        {{ element.MQTOAI }} / {{ element.MQTOSL }}
      </td>
    </ng-container>

    <ng-container matColumnDef="sourceType">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Source Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQTTYP }}
      </td>
    </ng-container>

    <ng-container matColumnDef="enteredQueue">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Entered Queue
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQBLDG }} &nbsp;&nbsp; {{ element.MQTOAI }} /
        {{ element.MQTOSL }}
      </td>
    </ng-container>

    <ng-container matColumnDef="assigned">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Assigned
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQBLDG }} &nbsp;&nbsp; {{ element.MQTOAI }} /
        {{ element.MQTOSL }}
      </td>
    </ng-container>

    <ng-container matColumnDef="lots">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Lots
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQLOT1 }}&nbsp;{{ element.MQLOT2 }}&nbsp;{{ element.MQLOT3 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="transNumber">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Trans Number
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQTRAN }}
      </td>
    </ng-container>

    <ng-container matColumnDef="operator">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Operator
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQUSER }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.MQSTAT }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">

            <li>
              <button mat-icon-button (click)="moveQueueChange(id)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit-MoveQueue</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="redirectForm1(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Redirect One</mat-label>
              </button>
            </li>

            <!-- <li>
              <button mat-icon-button (click)="redirectForm1(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Redirect Two</mat-label>
              </button>
            </li> -->

            <li>
              <button mat-icon-button (click)="moveQueueComplete(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Move-Completion</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="moveQueueEntryAssign(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Move-Entry-Assign</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="deAssignment(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>De-Assignment</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="moveQueueEntryInquiry(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Move-Entry-Inquiry</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="moveChangeStagingLocation(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Change Staging Location</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="moveQueueNoPick(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Move Queue No Pick</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="changeMovePriority(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Change Queue Prioity</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="resolveMoveProblem(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Resolve Move Problem</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="moveQueueDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
        #paginator
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator> -->
  <mat-paginator [pageSizeOptions]="[4, 8, 12]" showFirstLastButtons class="tally-table-pagination"></mat-paginator>
</mat-card>