<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Maintain Carriers</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>  

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Carrier Code </mat-label>
            <input matInput formControlName="carrierCode" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Carrier Name</mat-label>
            <input matInput  formControlName="carrierName" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Carrier Address 1</mat-label>
            <input matInput formControlName="address1" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Carrier Address 2</mat-label>
            <input matInput formControlName="address2" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Zip Code</mat-label>
            <input matInput formControlName="zipcode" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Suffix</mat-label>

            <input matInput formControlName="suffix" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Carrier SCAC Code</mat-label>
            <input matInput formControlName="carrierSCACCode" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Hazardous Materials? </mat-label>
            <mat-select formControlName="hazmat">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Pallet Exchange? </mat-label>
            <mat-select formControlName="pallet">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Billing Frequency</mat-label>
            <mat-select formControlName="billingFrequency">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Loading Allowance % </mat-label>
            <input matInput formControlName="loadingAllowance" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Hundred Weight % </mat-label>
            <input matInput formControlName="weight" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Minimum Amount </mat-label>
            <input matInput formControlName="minAmount" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Maximum Amount </mat-label>
            <input matInput formControlName="maxAmount" />
          </mat-form-field>
        </div>
      </div>
      <div>
          <button
            mat-button [disabled]="!(form.valid && form.dirty)"
            class="buttons addButton"
            mat-raised-button
            (click)="createCarrier()"
          >
            Add
          </button>
          <button 
            mat-button
            class="buttons removeButton"
            mat-raised-button
            (click)="initializeForm()"
          >
            Reset
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>

