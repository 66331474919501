import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IMapLocationService {
  url = environment.API_BASE_URL;
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getMapLocation(user: any, building: any, zone: any, floor:any, aisl:any, slot:any): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/maplocation/read?user=${user}&building=${building}&zone=${zone}&floor=${floor}&aisl=${aisl}&slot=${slot}`
    );
  }

  getMapLocationReference( 
    location: any,
    building: any,
    floor: any,
    aisl: any,
    slot: any,
    user: any
  ): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/mapLocCrossRef/read?user=${user}&building=${building}&location=${location}&floor=${floor}&aisl=${aisl}&slot=${slot}`
    );
  }

  getAll(user: any): Observable<any> {
    return this.httpClient.get(
      this.url +`/maplocation/readAll?user=${user}`
    );
  }

  getLocationStatusInquiry(
    user: any,
    building: any,
    storer: any,
    item: any
  ): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/maplocation/read?user=${user}&building=${building}&storer=${storer}&item=${item}`
    );
  }

  getAllLocStatusInquiry(user: any): Observable<any> {
    return this.httpClient.get(
      this.url + `/maplocation/readLocAll?user=${user}`
    );
  }

  createMapLocation(
    MAWHSE: any, //set
    MAFLOR: any, //set
    MAAISL: any, //set
    MASLOT: any, //set
    MAHEIG: any, //set
    MADEPT: any, //set
    MASLTH: any, //set
    MASLTW: any, //set
    MASLTD: any, //set
    MARTG: any, //set
    MAABC: any, //set
    MARESV: any, //set
    MASTOR: any, //set
    MASFX: any, //set
    MAITEM: any, //set
    user: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/maplocation/create', {
        MAWHSE, //Building
        MAFLOR, //floor
        MAAISL, //Aisle
        MASLOT, //slot
        MAHEIG, //pallets height
        MADEPT, //deep
        MASLTH, //slot height
        MASLTW, //slot width
        MASLTD, //slot depth
        MARTG, //picking sequence
        MAABC, //ware house zone
        MARESV, //reserves slot
        MASTOR, //reserved storer nummber
        MASFX, //reserved storer suffix
        MAITEM, //reserved Item Code
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateMapLocation(
    MAWHSE: any, //set
    MAFLOR: any, //set
    MAAISL: any, //set
    MASLOT: any, //set
    MAHEIG: any, //set
    MADEPT: any, //set
    MASLTH: any, //set
    MASLTW: any, //set
    MASLTD: any, //set
    MARTG: any, //set
    MAABC: any, //set
    MARESV: any, //set
    MASTOR: any, //set
    MASFX: any, //set (MASTRX)
    MAITEM: any, //set
    user: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/maplocation/update', {
        MAWHSE, //Building
        MAFLOR, //floor
        MAAISL, //Aisle
        MASLOT, //slot
        MAHEIG, //pallets height
        MADEPT, //deep
        MASLTH, //slot height
        MASLTW, //slot width
        MASLTD, //slot depth
        MARTG, //picking sequence
        MAABC, //ware house zone
        MARESV, //reserves slot
        MASTOR, //reserved storer nummber
        MASFX, //reserved storer suffix
        MAITEM, //reserved Item Code
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteMapLocation(
    user: any,
    building: any,
    floor: any,
    aisel: any,
    slot: any
  ): Observable<any> {
    return this.httpClient.delete<any>(
      this.url +
        `/maplocation/delete?user=${user}&building=${building}&floor=${floor}&aisel=${aisel}&slot=${slot}`
    );
  }

  createServiceMapLocXref(
    location: any,
    building: any,
    floor: any,
    aisl: any,
    slot: any,
    user: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/mapLocCrossRef/create', {
        location,
        building,
        floor,
        aisl,
        slot,
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateServiceMapLocXref(
    location: any,
    building: any,
    floor: any,
    aisl: any,
    slot: any,
    user: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/mapLocCrossRef/update', {
        location,
        building,
        floor,
        aisl,
        slot,
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteServiceMapLocXref(
    location: any,
    building: any,
    floor: any,
    aisl: any,
    slot: any,
    user: any
  ): Observable<any> {
    return this.httpClient
      .delete<any>(
        this.url +
          `/mapLocCrossRef/delete?location=${location}&building=${building}&floor=${floor}&aisl=${aisl}&slot=${slot}&user=${user}`
      )
      .pipe(retry(1), catchError(this.httpError));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
