import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-email-address',
  templateUrl: './edit-email-address.component.html',
  styleUrls: ['./edit-email-address.component.css'],
})
export class EditEmailAddressComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditEmailAddressComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      Name: 'Neil Kohan',
      EmailAddress: 'neil@gmail.com',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }
}
