<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Campus Buildings</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="buttons">
      <button
        mat-button
        (click)="addCampusBuilding()"
        class="addButton buttons"
      >
        <mat-icon>add</mat-icon>
        <span>New Campus Building</span>
      </button>
    </div>
    <div class="formDiv">
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Region</mat-label>
              <input matInput formControlName="Region" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Campus</mat-label>
              <input matInput formControlName="Campus" />
            </mat-form-field>
          </div>
        </div>
      </form>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Region">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>Region</th>
          <td mat-cell *matCellDef="let element">{{ element.Region }}</td>
        </ng-container>

        <ng-container matColumnDef="Campus">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>Campus</th>
          <td mat-cell *matCellDef="let element">{{ element.Campus }}</td>
        </ng-container>

        <ng-container matColumnDef="Bldg">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>Bldg</th>
          <td mat-cell *matCellDef="let element">{{ element.Bldg }}</td>
        </ng-container>
        <ng-container matColumnDef="Description">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell mat-header-cell width="250px" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select class="mat-select" placeholder="Select Option">
              <mat-option>
                <button mat-icon-button (click)="deleteCampusBuilding()">
                  <mat-icon mat-icon-button color="secondary">delete</mat-icon>
                  <mat-label>Delete</mat-label>
                </button>
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[1, 2]"
      showFirstLastButtons
    ></mat-paginator> </mat-card-content
></mat-card>
