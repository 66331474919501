import { Component, OnInit, Inject,   } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { LoginService } from 'src/app/login/login.service';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { TallysComponent } from '../../tallys/tallys.component';

@Component({
  selector: 'app-account-selection-update',
  templateUrl: './account-selection-update.component.html',
  styleUrls: ['./account-selection-update.component.css']
})
export class AccountSelectionUpdateComponent implements OnInit {

  userData:any;
  accountSelelctionForm:any
  storerAndBuilding:any;

  
  constructor(
    private router: Router,
    public loginSvc: LoginService,
    public loginSvc2:IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public userName:any,
    private dialogRef:MatDialogRef<AccountSelectionUpdateComponent>
  ) {}

  // accountSelelctionForm = this.fb.group({
  //   building: [null, Validators.required]
  // });
  
accountSelection: any = '';
  //userName = GlobalComponent.user;
  result: any;
  element: any;
  
  
  ngOnInit(): void {

    this.accountSelelctionForm = this.fb.group({
      building: [null, Validators.required]
    });
    
  //  this.userData= history.state;
   // console.log(this.userData,'*********');
   // console.log(this.userData.user,'*********');

    
    
    this.accountSelection = this.loginSvc2
      .getAccountSelection(this.userName)
      .subscribe((data: any) => {
        this.result = data;
        var res = JSON.parse(this.result[0].data[1].value);
        this.element = res.returnedData;
        console.log(this.element);
        console.log('Account selection Data');
      });
  }

  submit() {
    //alert('success');
    console.log(this.accountSelelctionForm.get('building').value,'Building Value');
    console.log(this.accountSelelctionForm.get('building'));
    this.storerAndBuilding = this.accountSelelctionForm.get('building').value;

    
    
    
    
      this.loginSvc
      .updateAccountUser(
        this.userName,
        this.storerAndBuilding.name,
        this.storerAndBuilding.storerNumber,
        this.storerAndBuilding.storerSuffix,
        this.storerAndBuilding.buildingNumber,
        this.storerAndBuilding.status
      )
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
        console.log(JSON.parse(this.result[0].data[1].value).success);
        console.log('*******')
        if(JSON.parse(this.result[0].data[1].value).success ){
          this.dialogRef.close();

          try {

            this.failureMessage(JSON.parse(this.result[0].data[1].value).data.message);
    //       console.log(this.storerAndBuilding.storerNumber);
    //       console.log('******************');
    // localStorage.setItem('building', this.storerAndBuilding.buildingNumber);
    // localStorage.setItem('storer', this.storerAndBuilding.storerNumber);
    // localStorage.setItem('storerSuffix', this.storerAndBuilding.storerSuffix);
      //    this.failureMessage(JSON.parse(this.result[0].data[1].value).returnedData.message);

          
         
          this.router.navigateByUrl('/dashbaord');
          //this.router.navigateByUrl('/dashbaord');
            
          } catch (error) {

            this.successMessage(JSON.parse(this.result[0].data[1].value).returnedData.message);
            console.log(this.storerAndBuilding.storerNumber);
            console.log('******************');
      
           // this.successMessage(JSON.parse(this.result[0].data[1].value).returnedData.message);
            localStorage.setItem('building', this.storerAndBuilding.buildingNumber);
      localStorage.setItem('storer', this.storerAndBuilding.storerNumber);
      localStorage.setItem('storerSuffix', this.storerAndBuilding.storerSuffix);
      
           
            
            
          }
          
        }else{
          this.failureMessage(JSON.parse(this.result[0].data[1].value).data.message);
        }
      });
    
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success').then(() => {
    
      this.router.navigateByUrl('/', {skipLocationChange: true})
      .then(() => this.router.navigate(['/dashboard']));
  });;
   
  }

  changeRourer(){
    
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
    this.router.navigate(['/dashboard']);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
