<mat-card>
    <mat-card-header>  
      <mat-card-title>
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
        Work With Message Class Shred</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="buttons">
            <button mat-button (click)="MessageClassShredAddForm()" class="addButton buttons">
              <mat-icon>add</mat-icon>
              <span>New Message class</span>
            </button>
        </div>
        <form [formGroup]="form" class="form over-Flow">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Document</mat-label>
                    <input matInput formControlName="DOCUMENT"/>
                </mat-form-field>
                   <button
                    class="addButton"
                       mat-raised-button
                          type="submit"
                          placeholder="Search">
                          Search
                    </button>
                  </div>
                </div>
              </form>
                         
          </mat-card-content>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Document">
              <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Document
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.DOCUMENT }}</td>
            </ng-container>
            <ng-container matColumnDef="Type">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                  Type
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.PARSETYPE }}</td>
            </ng-container>
            <ng-container matColumnDef="DefaultSchema">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                  Default Schema
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.DFTSCHEMA }}</td>
            </ng-container>
            <ng-container matColumnDef="Actions">
                <th  mat-header-cell mat-header-cell *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-select  placeholder="Select Option">
                        <mat-option>
                            <button mat-icon-button (click)="MessageClassShredAddForm()">
                                <mat-icon mat-icon-button color="primary">add</mat-icon>
                                <mat-label>Add</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                            <button mat-icon-button (click)="MessageClassShredUpdateForm(element)">
                              <mat-icon mat-icon-button color="primary">edit</mat-icon>
                              <mat-label>Change</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                            <button mat-icon-button (click)="MessageClassShredDeleteForm(element)">
                              <mat-icon mat-icon-button color="primary">delete</mat-icon>
                              <mat-label>Delete</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                            <button mat-icon-button (click)="MessageClassShredCopyForm(element)">
                              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                              <mat-label>Copy</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                          <button mat-icon-button (click)="MessageClassShredDetailsForm(element)">
                            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                            <mat-label>Details</mat-label>
                          </button>
                      </mat-option>
                    </mat-select>
                
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
        </table>
        <mat-paginator
#paginator
[pageSizeOptions]="[4, 8, 16]"
showFirstLastButtons
></mat-paginator>

