<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Trip Expenses</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
  
  
      
      <div class="buttons">
        <button mat-button (click)="addTripExpenses()"  class="addButton">
          <mat-icon>add</mat-icon>
          <span>New Trip Expense</span>
        </button>
      </div>
     
     <div class="formDiv">
      <form [formGroup]="form">
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Trip Number:</mat-label>
            <input matInput formControlName="TripNumber"/>
          </mat-form-field>

        </div>     
      </form>
     </div>
     
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Sl.No">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Sl.No
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Slno }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Category">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Category
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Category }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Sub Category">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Sub Category
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.SubCategory }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Description">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Description
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Amount">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Amount
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Amount }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          <mat-option>
            <button mat-icon-button  (click)="changeTripExpenses(element)">           
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button>
          </mat-option>
  
       
  
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
    </mat-card-content>
  </mat-card>
