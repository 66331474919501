import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';


@Component({
  selector: 'app-messageclassshred-details-details-change',
  templateUrl: './messageclassshred-details-details-change.component.html',
  styleUrls: ['./messageclassshred-details-details-change.component.css']
})
export class MessageclassshredDetailsDetailsChangeComponent implements OnInit {
  form!:FormGroup
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<MessageclassshredDetailsDetailsChangeComponent>
  ) { }

  

  ngOnInit(): void {
    this.form = this.fb.group({
      DOCUMENT:'AXWAY 943',
      FILE:'EXH943P',
      FIELD:'EXDATA',
      DTYPE:'CHAR(120)',
      XPATH1:'EXDATA',
      ALLOWNULL:'Y',
      DVALU1:''
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}