import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { ConsigeeService } from '../consigee.service';

@Component({
  selector: 'app-consignee-xref',
  templateUrl: './consignee-xref.component.html',
  styleUrls: ['./consignee-xref.component.css']
})
export class ConsigneeXrefComponent implements OnInit {

  form!:FormGroup
  constructor(
    public consigneeService: ConsigeeService,
    private router: Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ConsigneeXrefComponent>
  ) { }

  result:any;
  dataSource:any;
  duplicateSource:any;
  duplicateSourcevalue:any;
  user=localStorage.getItem('userName');
  consCode = this.anyvariable.CONSCD.toUpperCase();
  
  ngOnInit(): void {
    console.log('ANY VARIABLE : ' + this.anyvariable.CONSCD)
this.duplicateSourcevalue='';
    this.consigneeService
    .getConsigneeXrefService(this.user, this.consCode)
    .subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;

      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('Test :'+this.duplicateSource[0]&&this.duplicateSource[0].CXPCON)
      if(this.duplicateSource[0]&&this.duplicateSource[0].CXPCON)
      {
        this.duplicateSourcevalue=this.duplicateSource[0].CXPCON;
        console.log('PARENT CONSIGNEE 1 : ' + this.duplicateSource[0].CXPCON);
      }
    });
  
    this.form= this.fb.group({
      consigneeCode:this.anyvariable.CONSCD,
      parentConsignee:this.duplicateSourcevalue
      
    })
  }

  createConsigneeXref() {
    console.log(this.form);
    var res = this.form.value;
    console.log(res);

    this.result = this.consigneeService
      .createConsigneeXrefService(
        this.consCode,
        res.parentConsignee,
        this.user
       )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       if(JSON.parse(this.result[0].data[1].value).success){

        //alert('Record Created SuccessFully');

       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/consignee/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/consignee/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }


  updateConsigneeXref() {
    var res = this.form.value;
    console.log(res);

    this.result = this.consigneeService
      .updateConsigneeXrefService(
        this.consCode,
        res.parentConsignee,
        this.user
       )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       if(JSON.parse(this.result[0].data[1].value).success){

        //alert('Record Updated Successfully');

       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/consignee/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  

  deleteConsigneeXref() {
    this.consigneeService
      .deleteConsigneeXrefService(this.user, this.consCode)
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        if(JSON.parse(this.result[0].data[1].value).success){

          //alert('Record Deleted SuccessFully');
  
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          this.router.navigate(['/main-nav/consignee/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
  }



  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
