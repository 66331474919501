import { Component, OnInit, ViewChild, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { ConsigeeService } from '../consigee.service';
import { ConsigneeCommentsCreateComponent} from './consignee-comments-create/consignee-comments-create.component';
import { ConsigneeCommentsUpdateComponent } from './consignee-comments-update/consignee-comments-update.component';
import { ConsigneeCommentsDeleteComponent } from './consignee-comments-delete/consignee-comments-delete.component';

@Component({
  selector: 'app-consinee-comments',
  templateUrl: './consinee-comments.component.html',
  styleUrls: ['./consinee-comments.component.css']
})
export class ConsineeCommentsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  searchTextDisplay: any = false;
  

  displayedColumns: string[] = [
    'ID',
    'Key',
    'Tp',
    'Seq',
    'Comment',
    'Actions' 
  ];

  dataSource:any;
  element:any;

  constructor(
    public consigneeService: ConsigeeService,
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
   // @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   // private dialogRef: MatDialogRef<ConsineeCommentsComponent>
  ) { }

  result:any;
  duplicateSourcevalue:any;
  user=localStorage.getItem('userName');
  consCode:any;
  duplicateSource = [];
  TallyHeaders: any = [];

  ngOnInit(): void {
    this.element = history.state;
    
    this.consCode = this.element.CONSCD;
    console.log("**********");
    console.log(this.element.CONSCD);
    this.duplicateSourcevalue='';


    this.consigneeService
    .getConsigneeComments(this.user, this.consCode,'','')
    .subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;

      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log("consignee Comments data");
      console.log(this.duplicateSource);
      this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
    });
  
    this.form= this.fb.group({

      fileId:'CO',
      key:this.consCode,
      subType:'',
      sequence:''
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/consignee/read']);
  }

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  searchConsigneeComment(consignee:any){

    const prefixWithZeros = (number: any, length: number) => String(number).padStart(length, '0');
      console.log(prefixWithZeros(this.element.STORE,4));

      this.consCode = this.element.CONSCD;
    var res = this.form.value;
    this.TallyHeaders = this.consigneeService.getConsigneeComments(this.user, this.consCode, res.subType, '')
      .subscribe((data:any) => {
        this.result = data; 
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('STORER COMMENTS BY SEARCH');
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });

  }

  consigneeCommentCreate(){
    var res = this.form.value;
    this.matDialog.open(ConsigneeCommentsCreateComponent, {
      "width": '1000px',
     "maxHeight": '80vh',  
     "data": res,
     "autoFocus": false
   });
  }

   consigneeCommentUpdate(element : any){
    this.matDialog.open(ConsigneeCommentsUpdateComponent, {
      "width": '1000px',
     "maxHeight": '80vh',
     "data": element,
     "autoFocus": false
   });
  }

  consigneeCommentDelete(element: any){

    this.matDialog.open(ConsigneeCommentsDeleteComponent, {
      "width": '1000px',
     "maxHeight": '80vh',
     "data": element,
     "autoFocus": false
   });
  }

}




