<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>Record Storage Lot Status Maintenance</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Storer Number  : </mat-label> 
                    <input matInput formControlName="StorerNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Item Number: </mat-label> 
                    <input matInput formControlName="ItemNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Lot Code : </mat-label> 
                    <input matInput formControlName="LotCode"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Lot Weight : </mat-label> 
                    <input matInput formControlName="LotWeight"/>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Expiration : </mat-label> 
                    <input matInput formControlName="Expiration"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Date Held: </mat-label> 
                    <input matInput formControlName="DateHeld" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Date Released : </mat-label> 
                    <input matInput formControlName="DateReleased"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Status </mat-label> 
                    <input matInput formControlName="Status"/>
                </mat-form-field>
            </div>

            
            

            <div class="col">
                <button mat-button (click)="changeBox()" class="addButton">
                    
                    <span>change</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>

