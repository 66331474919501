<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Maintain Door Usage</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Building Number </mat-label>
            <input matInput formControlName="BldgNumb" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Door Number </mat-label>

            <input matInput formControlName="DoorNumb" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Account Code </mat-label>

            <input matInput formControlName="Account" />
          </mat-form-field>

          <button
            mat-button
            class="buttons addButton"
            mat-raised-button
            (click)="add()"
          >
            Add
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
