<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Control Groups </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Control Number</mat-label>
            <input matInput formControlName="ControlNumber" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="ControlNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Control Number
      </th>
      <td mat-cell *matCellDef="let element">{{ element.ControlNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="TeamName">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Team Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.TeamName }}</td>
    </ng-container>

    <ng-container matColumnDef="StartingTag">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Starting Tag
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.StartingTag }}
      </td>
    </ng-container>

    <ng-container matColumnDef="EndingTag">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Ending Tag
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.EndingTag }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Storer">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Storer
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Storer }}
      </td>
    </ng-container>
    <ng-container matColumnDef="BuildingNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Building Number
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.BuildingNumber }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Floor">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Floor
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Floor }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Status">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Status }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
              #paginator
              [pageIndex]="0"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
