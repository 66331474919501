import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../iheader.service';

@Component({
  selector: 'app-update-tally-dtl-putaway-loc',
  templateUrl: './update-tally-dtl-putaway-loc.component.html',
  styleUrls: ['./update-tally-dtl-putaway-loc.component.css']
})
export class UpdateTallyDtlPutawayLocComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<UpdateTallyDtlPutawayLocComponent>,
  ) {}

  ngOnInit(): void {

       this.form = this.fb.group({

        floor:this.anyvariable[1].ILFLOR,
        aisle:this.anyvariable[1].ILAISL,
        slot:this.anyvariable[1].ILSLOT,
        quantityAvailable:this.anyvariable[1].ILQAVL, 
        damaged:this.anyvariable[1].ILQDAM,
        held:this.anyvariable[1].ILQHLD,
        user1:this.anyvariable[1].ILQUS1,
        user2:this.anyvariable[1].ILQUS2,
        user3:this.anyvariable[1].ILQUS3,
        user4:this.anyvariable[1].ILQUS4,

       });
  }

  updatePutawayLoc(){
    var res = this.form.value;
    
   
    this.result = this.iHeaderSvc
      .updateServicePutawayLoc( 
        this.anyvariable[1].ILACDL, // ACDL
        this.anyvariable[1].ILTRTY, // Rate Type
        this.anyvariable[1].ILTALY,
        this.anyvariable[1].ILSEQ,
        GlobalComponent.fullBuilding(this.anyvariable[1].ILBLDG),
       // localStorage.getItem('building'),//building
        this.anyvariable[1].ILSTOR,
        this.anyvariable[1].ILITEM,
        this.anyvariable[1].ILLTC1,
        this.anyvariable[1].ILLTC2,
        this.anyvariable[1].ILLTC3, 
        res.floor,
        GlobalComponent.fullSlotAisl(res.aisle),
        GlobalComponent.fullSlotAisl(res.slot),
        res.quantityAvailable,
        res.damaged,
        res.held,
        res.user1,
        res.user2,
        res.user3,
        res.user4,
        this.user

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
      
      if(JSON.parse(this.result[0].data[1].value).success){
        //alert('Record Created SuccessFully');
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
       
        this.router.navigate(['/main-nav/putawayLocation/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    //this.router.navigate(['/main-nav/storer/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/storer/read']);
  }
  


 

  closeDialog(){
    this.dialogRef.close();
  }

}
