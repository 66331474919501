<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Operator Groups </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="addOperatorGroup()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>Create Operator Group</span>
      </button>
    </div>

    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Operator</mat-label>
            <input matInput formControlName="Operator" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="Operator">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Operator
      </th>
      <td mat-cell *matCellDef="let element">{{ element.Operator }}</td>
    </ng-container>
    <ng-container matColumnDef="GroupName">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Group Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.GroupName }}</td>
    </ng-container>
    <ng-container matColumnDef="GroupOperatorPriority">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Group Operator Priority
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.GroupOperatorPriority }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Status }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="updateOperatorGroup()">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="delete()">
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
                        #paginator
                        [pageIndex]="0"
                        [pageSize]="10"
                        [pageSizeOptions]="[5, 10, 25, 100]"
                      >
                      </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
