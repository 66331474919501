<mat-card>
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Tally Event Inquiry</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form class="form over-Flow">
      <div class="row">
        <div class="col">
          <label style="margin-left: 4%;"> <b>Tally Number : </b></label>{{ anyvariable.IHTALY }}
          <label style="margin-left: 6%;"> <b>Storer : </b></label>
          {{ anyvariable.IHSTOR + " " + anyvariable.IHCHGU }}
          <label style="margin-left: 8%;"> <b>Consignee : </b></label> {{ anyvariable.IHCONS }}
        </div>
      </div>
    </form>
    <!--<div class="example-container">
            <mat-form-field hintLabel="Max 10 characters">
              <input matInput #input maxlength="10" placeholder="Enter some input">
            
            </mat-form-field>
           
        </div>-->
  </mat-card-content>

  <table
    [dataSource]="dataSource"
    mat-table
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="Date/time">
      <th mat-header-cell *matHeaderCellDef>Date/time</th>
      <td mat-cell *matCellDef="let element">{{ element.IVEVDT }}</td>
    </ng-container>

    <ng-container matColumnDef="Event">
      <th mat-header-cell *matHeaderCellDef>Event</th>
      <td mat-cell *matCellDef="let element">{{ element.IVSEQN }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.severity }}</td>
    </ng-container>

    <ng-container matColumnDef="User">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let element">{{ element.IVUSER }}</td>
    </ng-container>

    <ng-container matColumnDef="Received">
      <th mat-header-cell *matHeaderCellDef>Received</th>
      <td mat-cell *matCellDef="let element">{{ element.item }}</td>
    </ng-container>

    <ng-container matColumnDef="ST">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.IVSTAT }}</td>
    </ng-container>

    <!--   <ng-container matColumnDef="intransit">
            <th mat-header-cell *matHeaderCellDef> Intransit </th>
            <td mat-cell *matCellDef="let element"> {{ element.intransit }} </td>
        </ng-container>
         
        
          <ng-container matColumnDef="receive">
            <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                Recieved
            </th>
            <td mat-cell *matCellDef="let element">{{ element.receive }}</td>
          </ng-container> -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
