import { EditOperatorGroupComponent } from './edit-operator-group/edit-operator-group.component';
import { AddOperatorGroupComponent } from './add-operator-group/add-operator-group.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-operator-groups',
  templateUrl: './operator-groups.component.html',
  styleUrls: ['./operator-groups.component.css'],
})
export class OperatorGroupsComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Operator',
    'GroupName',
    'GroupOperatorPriority',
    'Status',
    'Actions',
  ];
  dataSource: any = [];

  duplicateSource: any = [
    {
      Operator: 'ISHWARYAP',
      GroupName: 'ABCD',
      GroupOperatorPriority: '1',
      Status: 'AC',
    },
  ];

  constructor(private fb: FormBuilder, private matDialog: MatDialog) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      Operator: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  addOperatorGroup() {
    this.matDialog.open(AddOperatorGroupComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  updateOperatorGroup() {
    this.matDialog.open(EditOperatorGroupComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  delete() {
    alert('deleted');
  }
}
