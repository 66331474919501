import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { OrderChangeLocLotComponent } from './order-change-loc-lot/order-change-loc-lot.component';
import { OrderDetailChangeEventComponent } from './order-detail-change-event/order-detail-change-event.component';
import { OrderDetailChangeShipFromComponent } from './order-detail-change-ship-from/order-detail-change-ship-from.component';
import { OrderDetailCommentsComponent } from './order-detail-comments/order-detail-comments.component';
import { OrderDetailCrossDocRefComponent } from './order-detail-cross-doc-ref/order-detail-cross-doc-ref.component';
import { OrderDetailCutComponent } from './order-detail-cut/order-detail-cut.component';
import { OrderDetailDeleteComponent } from './order-detail-delete/order-detail-delete.component';
import { OrderDetailEditComponent } from './order-detail-edit/order-detail-edit.component';
import { OrderDetailItemStatusComponent } from './order-detail-item-status/order-detail-item-status.component';
import { OrderDetailLotStatusComponent } from './order-detail-lot-status/order-detail-lot-status.component';
import { OrderDetailMoveQueueComponent } from './order-detail-move-queue/order-detail-move-queue.component';
import { OrderDetailSplitLineComponent } from './order-detail-split-line/order-detail-split-line.component';
import { OrderDetailStackActivityComponent } from './order-detail-stack-activity/order-detail-stack-activity.component';
import { OrderDetailStockStatusComponent } from './order-detail-stock-status/order-detail-stock-status.component';
import { OrderDetailUcc128MaintComponent } from './order-detail-ucc128-maint/order-detail-ucc128-maint.component';
import { OrderDetailsCreateComponent } from './order-details-create/order-details-create.component';
import { OrderDetailsService } from './order-details.service';
import { ChangeEventCommentsComponent } from './change-event-comments/change-event-comments.component';

import { ConsigeeService } from '../../consignee/consigee.service';
import { StorerServiceService } from '../../storer/storer-service.service';

interface CustomColumn {
  position: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css'],
})
export class OrderDetailsComponent implements OnInit {

  constructor(
    private router: Router,
    public orderdetailSrvc: OrderDetailsService,
    public consigneeSrvc: ConsigeeService,
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {
    this.form = this.fb.group({
      Bill_of_Lading: fb.control(''),
      Storer: fb.control(''),
      total_Quantity: fb.control(''),
      Consignee: fb.control(''),
    });
  }

  displayedColumns: string[] = [
    'Quantity',
    'ItemCodeDescription',
    'Weight',
    'Ovr',
    'Status',
    'Actions',
  ];

  selectedRow: any;
  editedRows: any;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  form!: FormGroup;
  dataSource: any = [];
  user = localStorage.getItem('userName');
  result: any;
  orderSeq: any;
  element: any;
  duplicateSource: any;
  billOfLading: any;
  storer: any;
  totalQuantity: any;
  consignee: any;
  consigneeName: any;
  storerName: any;
  columnShowHideList: CustomColumn[] = [];
  

  ngOnInit(): void {
    this.element = history.state;
    this.editedRows = [];
    this.initializeColumnProperties();
    if (localStorage.getItem('billOfLading') === null) {
      localStorage.setItem('billOfLading', this.element.OHBILL);
      localStorage.setItem('orderStorer', this.element.OHSTOR);
      localStorage.setItem('totalQuantity', this.element.OHHQTY);
      localStorage.setItem('consignee', this.element.OHCONS);

      this.billOfLading = this.element.OHBILL;
      this.storer = this.element.OHSTOR;
      this.totalQuantity = this.element.OHHQTY;
      this.consignee = this.element.OHCONS;
    } else {
      this.billOfLading = localStorage.getItem('billOfLading');
      this.storer = localStorage.getItem('orderStorer');
      this.totalQuantity = localStorage.getItem('totalQuantity');
      this.consignee = localStorage.getItem('consignee');
    }

    this.storerSvc.getAll(this.user, this.storer).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log(this.duplicateSource[0].STNAME);
      this.storerName = this.duplicateSource[0].STNAME;
    });

    this.consigneeSrvc
      .getConsignee(this.user, this.consignee, '')
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log(this.duplicateSource[0].CONAME);
        this.consigneeName = this.duplicateSource[0].CONAME;

        this.storerSvc.getAll(this.user, this.storer).subscribe((data) => {
          this.result = data;
          var res = this.result[0].data[1]?.value;
          var parsedData = JSON.parse(res);
          this.duplicateSource = parsedData.returnedData;
          console.log(this.duplicateSource[0].STNAME);
          this.storerName = this.duplicateSource[0].STNAME;

          this.form = this.fb.group({
            Bill_of_Lading: this.billOfLading,
            Storer: this.storer + ' ' + this.storerName,
            total_Quantity: this.totalQuantity,
            Consignee: this.consignee + ' ' + this.consigneeName,
          });
        });
      });

    this.orderdetailSrvc
      .getOrderDetails(this.user, this.billOfLading)
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('ORDER DETAILS DATA');
        console.log(this.duplicateSource);
        this.orderSeq = this.duplicateSource.length + 1;
        console.log('Next Order Dtl Create Sequence: ');
        console.log(this.duplicateSource.length + 1);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  toggleColumn(column: any) {
    if (column.isActive) {
      if (column.position > this.displayedColumns.length - 1) {
        this.displayedColumns.splice(this.displayedColumns.length-1,0,column.name);
      } else {
        this.displayedColumns.splice(column.position, 0, column.name);
      }
    } else {
      let i = this.displayedColumns.indexOf(column.name);
      let opr = i > -1 ? this.displayedColumns.splice(i, 1) : undefined;
    }
    console.log(this.displayedColumns);
    
  }

  initializeColumnProperties() {
    this.displayedColumns.forEach((element, index) => {
      if (element != 'Actions') {
        this.columnShowHideList.push({
          position: index,
          name: element,
          isActive: true,
        });
      }
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length,
      name: 'ShipFrom',
      isActive: false,
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length ,
      name: 'Sequence',
      isActive: false,
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length ,
      name: 'Lot',
      isActive: false,
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length,
      name: 'QuantityOrdered',
      isActive: false,
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length ,
      name: 'OriginalSequence',
      isActive: false,
    });
    this.columnShowHideList.push({
      position: this.columnShowHideList.length ,
      name: 'Location',
      isActive: false,
    });
    console.log(this.columnShowHideList);
    
  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }


  orderDetailsCommit(commit: any) {
    var res = this.form.value;
    this.result = this.orderdetailSrvc
      .orderDetailsCommitService(commit.ODBILL, commit.ODSEQ)
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
        var length = this.result[0].data.length;
        if (this.result[0].data[length - 1].value == '') {
          const msg = 'Successfully Committed';
          this.successMessage(msg);
          this.router.navigateByUrl('/main-nav/orders/details');
        } else {
          const msg = this.result[0].data[length - 1].value;
          this.failureMessage(msg);
        }
      });
  }

  orderDetailsDeCommit(deCommit: any) {
    var res = this.form.value;
    this.result = this.orderdetailSrvc
      .orderDetailsDeCommitService(deCommit.ODBILL, deCommit.ODSEQ)
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
        var length = this.result[0].data.length;
        if (this.result[0].data[length - 1].value == '') {
          const msg = 'Successfully De-Committed';
          this.successMessage(msg);
          this.router.navigateByUrl('/main-nav/orders/details');
        } else {
          const msg = this.result[0].data[length - 1].value;
          this.failureMessage(msg);
        }
      });
  }

  orderDetailsNonStockReas(deCommit: any) {
    var res = this.form.value;
    this.result = this.orderdetailSrvc
      .orderDetailsNonStockReasService(deCommit.ODBILL, deCommit.ODSEQ)
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
        var length = this.result[0].data.length;
        if (this.result[0].data[length - 1].value == '') {
          const msg = 'Success';
          this.successMessage(msg);
        } else {
          const msg = 'Insufficient full pallet stock.';
          this.failureMessage(msg);
        }
      });
  }

  orderDetailForce(force: any) {
    this.result = this.orderdetailSrvc
      .orderDetailForceService(force.ODBILL, force.ODSEQ)
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
        var length = this.result[0].data.length;
        if (this.result[0].data[length - 1].value == '') {
          const msg = 'Success';
          this.successMessage(msg);
        } else {
          const msg = 'Error.';
          this.failureMessage(msg);
        }
      });
  }

  orderDetailDeleteNos(deleteNos: any) {
    if (confirm('Delete this order detail?')) {
      this.result = this.orderdetailSrvc
        .orderDetailDeleteNosService(deleteNos.ODBILL, deleteNos.ODSEQ)
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);
          var length = this.result[0].data.length;
          if (this.result[0].data[length - 1].value == '') {
            const msg = 'Success';
            this.successMessage(msg);
          } else {
            const msg = 'No return response from server.';
            this.failureMessage(msg);
          }
        });
    }
  }

  hardAllocate(hardAllocate: any) {
    if (confirm('Click Ok to Flag this line as Hard Allocated.')) {
      this.result = this.orderdetailSrvc
        .hardAllocateService(hardAllocate.ODBILL, hardAllocate.ODSEQ)
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);
          var length = this.result[0].data.length;
          if (
            this.result[0].data[length - 1].value ==
            'Hard Allocation Successfully Updated'
          ) {
            const msg = this.result[0].data[length - 1].value;
            this.successMessage(msg);
          } else {
            const msg = this.result[0].data[length - 1].value;
            this.failureMessage(msg);
          }
        });
    }
  }

  removeHardAllocate(hardAllocate: any) {
    if (confirm('Click Ok to Remove Hard Allocation from this Line.')) {
      this.result = this.orderdetailSrvc
        .removeHardAllocateService(hardAllocate.ODBILL, hardAllocate.ODSEQ)
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);
          var length = this.result[0].data.length;
          if (
            this.result[0].data[length - 1].value ==
            'Hard Allocation Removed Successfully'
          ) {
            const msg = this.result[0].data[length - 1].value;
            this.successMessage(msg);
          } else {
            const msg = this.result[0].data[length - 1].value;
            this.failureMessage(msg);
          }
        });
    }
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  onBack() {
    this.router.navigate(['/main-nav/orders/read']);
  }

  addOrderDetail() {
    var sequence = this.orderSeq;
    var res = this.element;
    res.OHMSEQ = sequence;
    this.matDialog.open(OrderDetailsCreateComponent, {
      data: history.state,
      width: '1000px',
      maxHeight: '130vh',
      autoFocus: false,
    });
  }

  orderDetailEdit(order: any) {
    this.matDialog.open(OrderDetailEditComponent, {
      width: '1000px',
      maxHeight: '130vh',
      data: order,
      autoFocus: false,
    });
  }

  orderDetailStackStatus(order: any) {
    this.matDialog.open(OrderDetailStockStatusComponent, {
      width: '1000px',
      maxHeight: '130vh',
      autoFocus: false,
    });
  }

  orderDetailItemStatus(order: any) {
    this.matDialog.open(OrderDetailItemStatusComponent, {
      width: '1000px',
      maxHeight: '130vh',
      data: order,
      autoFocus: false,
    });
  }

  orderDetailIStockActivity(order: any) {
    this.matDialog.open(OrderDetailStackActivityComponent, {
      width: '1000px',
      maxHeight: '130vh',
      autoFocus: false,
    });
  }

  orderDetailChnageShipFrom(order: any) {
    this.matDialog.open(OrderDetailChangeShipFromComponent, {
      width: '1000px',
      maxHeight: '130vh',
      data: order,
      autoFocus: false,
    });
  }

  orderDetailSplitLine(order: any) {
    this.matDialog.open(OrderDetailSplitLineComponent, {
      width: '1000px',
      maxHeight: '130vh',
      data: order,
      autoFocus: false,
    });
  }

  orderDetailCrossDocRef(order: any) {
    this.matDialog.open(OrderDetailCrossDocRefComponent, {
      width: '1000px',
      maxHeight: '130vh',
      data: order,
      autoFocus: false,
    });
  }

  orderDetailComments(order: any) {
    this.matDialog.open(OrderDetailCommentsComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: order,
      autoFocus: false,
    });
  }

  orderDetailCut(order: any) {
    this.matDialog.open(OrderDetailCutComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: order,
      autoFocus: false,
    });
  }

  changeEvent(element: any) {
    this.matDialog.open(OrderDetailChangeEventComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  changeEventComments(element: any) {
    this.matDialog.open(ChangeEventCommentsComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  orderChangeLocLot(order: any) {
    this.matDialog.open(OrderChangeLocLotComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: order,
      autoFocus: false,
    });
  }

  orderDetailLotStatus(order: any) {
    this.matDialog.open(OrderDetailLotStatusComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: order,
      autoFocus: false,
    });
  }

  orderDetailUcc128Maint(order: any) {
    this.matDialog.open(OrderDetailUcc128MaintComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
    });
  }

  orderDetailMoveQueue(element: any) {
    this.matDialog.open(OrderDetailMoveQueueComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  orderDetailDelete(order: any) {
    this.matDialog.open(OrderDetailDeleteComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: order,
      autoFocus: false,
    });
  }
}
