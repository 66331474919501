import { GlobalComponent } from './../../../Global-Component';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
import { ImapZoneService } from '../imap-zone.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-mapzone',
  templateUrl: './update-mapzone.component.html',
  styleUrls: ['./update-mapzone.component.css'],
})
export class UpdateMapzoneComponent implements OnInit {
  mapZone: any;
  form!: FormGroup;
  result: any;
 // user = GlobalComponent.user;
 user=localStorage.getItem('userName');
  dataSource = [];

  constructor(
    public iMapsvc: ImapZoneService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router:Router,
    private dialogRef: MatDialogRef<UpdateMapzoneComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      buildingNumber: this.anyvariable.WZBLDG,
      zone: this.anyvariable.WZZONE,
      description: [this.anyvariable.WZDESC,Validators.required],
      inboundStaging: this.anyvariable.WZINST,
      outboundStaging: this.anyvariable.WZOUST,
      backCount: this.anyvariable.WZUSE5,
      customUsage1: this.anyvariable.WZUSE1,
      customUsage2: this.anyvariable.WZUSE2,
      customUsage3: this.anyvariable.WZUSE3,
      customUsage4: this.anyvariable.WZUSE4,
      productPutaway: this.anyvariable.WZPUT,
      productPicking:  this.anyvariable.WZPICK,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  updateMapZone() {
    var res = this.form.value;
    console.log(res);

    this.result = this.iMapsvc
      .updateMapZone(
        GlobalComponent.fullBuilding(res.buildingNumber),
        res.zone,
        res.description,
        res.inboundStaging,
        res.productPutaway,
        res.outboundStaging,
        res.productPicking,
        res.backCount,
        res.customUsage1,
        res.customUsage2,
        res.customUsage3,
        res.customUsage4,
       this.user)
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       // var msg = JSON.parse(this.result).returnedData.message;

       // this.successMessage(msg);
       if(JSON.parse(this.result[0].data[1].value).success){
        //alert('Record Created SuccessFully');
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/mapzone/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/mapzone/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
}
