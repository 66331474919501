//import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CustomerCreateComponent } from './customer-create/customer-create.component';
//import { TallyDetailCommentsComponent } from './tally-detail-comments/tally-detail-comments.component';
//import { MaintainComponent } from './maintain/maintain.component';
//import { FormBuilder, Validators } from '@angular/forms';
import { Component, NgModule, OnInit, ViewChild } from '@angular/core';
//import { IheaderService } from './iheader.service';
//import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TallyData } from '../tally-data';
import { GlobalComponent } from 'src/app/Global-Component';
import { Router } from '@angular/router';
import { CustomerUpdateComponent } from './customer-update/customer-update.component';
import { CustomerConsolidateComponent } from './customer-consolidate/customer-consolidate.component';
interface customer {
  CUST: Number;
  CUNAME: String;
  CUADD1: String;
  CUZIP: Number;
  CUPHAR: Number;
  CUFXAR: Number;
}

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css'],
})
export class CustomersComponent implements OnInit {
  form = this.fb.group({
    CUST: [],
    CUNAME: [],
  });

  displayedColumns: string[] = [
    'CustomerNo',
    'CustomerName',
    'Address',
    'ZipCode',
    'PhoneNumber',
    'FaxNumber',
    'Actions',
  ];

  searchTextDisplay = false;
  dataSource: customer[] = [
    {
      CUST: 1,
      CUNAME: 'Ram',
      CUADD1: 'hyd',
      CUZIP: 123,
      CUPHAR: 987656787,
      CUFXAR: 999,
    },
    {
      CUST: 2,
      CUNAME: 'Ramu',
      CUADD1: 'wgl',
      CUZIP: 456,
      CUPHAR: 887656787,
      CUFXAR: 111,
    },
  ];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog
  ) {}

  addCustomer() {
    // this.router.navigateByUrl('/main-nav/customers/add');
    this.matDialog.open(CustomerCreateComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
    });
  }

  getUpdateForm(element: any) {
    this.matDialog.open(CustomerUpdateComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
    //this.router.navigateByUrl('main-nav/customers/update', { state: element });
  }

  getConsolidateForm(element: any) {
    this.matDialog.open(CustomerConsolidateComponent, {
      width: '950px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }

  ngOnInit(): void {}
}
