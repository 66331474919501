<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Customer Receivables inquiry</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div> 
    
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
       <div class="row">
           <div class="col">
              <mat-form-field appearance="outline">
                   <mat-label>Aging_Date</mat-label>
                   <input matInput formControlName="Aging_Date"/>
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label>Customer</mat-label>
                <input matInput formControlName="Customer"/>
              </mat-form-field>
                      
              <mat-form-field appearance="outline">
                  <mat-label>IncludeTypes</mat-label>
                  <input matInput formControlName="IncludeTypes"/>
              </mat-form-field>
              <mat-form-field appearance="outline">
                  <mat-label>Omit</mat-label>
                  <input matInput formControlName="Omit"/>
              </mat-form-field>
        
           </div>

       </div>     
      </form>
    

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
       <ng-container matColumnDef="St">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            St
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.St}}</td>
         </ng-container>
         <ng-container matColumnDef="InvoiceNumber">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            InvoiceNumber
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.InvoiceNumber}}</td>
         </ng-container>
         <ng-container matColumnDef="InvoiceDate">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            InvoiceDate
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.InvoiceDate}}</td>
         </ng-container>
         <ng-container matColumnDef="TT">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            TT
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.TT}}</td>
         </ng-container>
         <ng-container matColumnDef="TrCd">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            TrCd
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.TrCd}}</td>
         </ng-container>
         <ng-container matColumnDef="BldNo">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            BldNo
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.BldNo}}</td>
         </ng-container>

         <ng-container matColumnDef="Bill_TO">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Bill_TO
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Bill_TO}}</td>
          </ng-container>

          <ng-container matColumnDef="Customer">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Customer
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Customer}}</td>
          </ng-container>

          <ng-container matColumnDef="BalanceDue">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                BalanceDue
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.BalanceDue}}</td>
          </ng-container>

          <ng-container matColumnDef="Opt">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Opt
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Opt}}</td>
          </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>