<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>Ship From Another Bucket</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form" fxLayoutAlign="center">
            <div class="row">
                <div class="col">
                    <mat-form-field  appearance="outline">
                        <mat-label> Item  : </mat-label> 
                        <input matInput value="{{this.item}}" readonly/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Quantity : </mat-label> 
                        <input matInput value="{{this.qty}}" readonly/>
                    </mat-form-field>
                    <mat-form-field  appearance="outline">
                        <mat-label>Current Bucket : </mat-label> 
                        <input matInput  value="{{this.currentBkt}}" readonly/>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="formfields">  
                        <mat-label> Ship From Inventory Bucket  </mat-label> 
                
                        <mat-select formControlName="shipInventoryBucket">
                            <mat-option value="A"> Available </mat-option>
                            <mat-option value="D"> Damaged </mat-option>
                            <mat-option value="H"> Held </mat-option>
                            <mat-option value="1"> User Bucket #1 </mat-option>
                            <mat-option value="2">User Bucket #2</mat-option>
                            <mat-option value="3">User Bucket #3 </mat-option>
                            <mat-option value="4">User Bucket #4 </mat-option>
                        </mat-select>
                      </mat-form-field>

                </div>

                <div class="row">
                <div class="col">
                    <button mat-button (click)="changeShipForm()" class="buttons addButton">
                        
                        <span>Update Ship From</span>
                      </button>
                </div>
                </div>
            </div>

        </form>
    </mat-card-content>
</mat-card>

