<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Insert After Email Format</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Sequence  : </mat-label> 
                    <input matInput formControlName="Sequence"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Format: </mat-label> 
                    <input matInput formControlName="Format"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>MergeFile : </mat-label> 
                    <input matInput formControlName="MergeFile"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Contents : </mat-label> 
                    <input matInput formControlName="Contents"/>
                </mat-form-field>
            </div>
         
        </div>

    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="insertAfterEmailFormat()" class="addButton">           
        <mat-icon mat-icon-button color="primary">edit</mat-icon>
        <mat-label> Insert After Email Format</mat-label>
    </button>
</mat-card-footer>

