import { IitemService } from './../iitem.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';
@Component({
  selector: 'app-delete-item',
  templateUrl: './delete-item.component.html',
  styleUrls: ['./delete-item.component.css'],
})
export class DeleteItemComponent implements OnInit {
  item: any;
  form!: FormGroup;
  constructor(
    public itemSvc: IitemService,
    private router: Router,
    private matDialog: MatDialog,
    private CustomAlertService: CustomAlertService,

    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteItemComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({
      storerNumber: this.anyvariable.ITSTOR,
      itemCode: this.anyvariable.ITEM,

      description: this.anyvariable.ITDSC1,
    });
  }
  result: any;
  deleteItem() {
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure you want to delete Item : ' +
    
    this.anyvariable.ITEM +
    '?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      this.result = this.itemSvc
        .deleteItem(
          this.anyvariable.ITSTOR,
          this.anyvariable.ITSSFX,
          this.anyvariable.ITEM
        )
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);
          var value = this.result[0].data[4].value;

          if (this.result[0].data[4].value == 'SUCCESSFULLY DELETED') {
            const msg = value;

            this.successMessage(msg);
            this.closeDialog();
            this.reloadCurrentRoute();
          } else {
            const msg = value;

            this.failureMessage(msg);
          }
        });
      }
    });
    }
  reloadCurrentRoute() {
    this.router.navigate(['/main-nav/item/read']);
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
