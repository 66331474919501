<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>  
      <mat-card-title >Work With an Item Adjustments</mat-card-title>
    </mat-card-header>

    <mat-card-content>
       <div class="formDiv">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="Storer"/>
                  </mat-form-field>
          
                  <mat-form-field appearance="outline">
                    <mat-label>Item</mat-label>
                    <input matInput formControlName="Item"/>
                  </mat-form-field>

            </div>

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <input matInput formControlName="Type"/>
                  </mat-form-field>
          
                  <mat-form-field appearance="outline">
                    <mat-label>Location</mat-label>
                    <input matInput formControlName="Location"/>
                  </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Date</mat-label>
                    <input matInput formControlName="Date"/>
                </mat-form-field>
          
                
                <mat-form-field appearance="outline">
                    <mat-label>Lots</mat-label>
                    <input matInput formControlName="Lots"/>
                </mat-form-field>
                 
            </div>
            
          </div>     
        </form>
       </div>


       <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Number">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Number
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Number }}</td>
        </ng-container>
  
        <ng-container matColumnDef="L Date">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            L Date
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LDate }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Lot Codes">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Lot Codes
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LotCodes }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Location">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Location
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Location }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Bkt">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Bkt
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Bkt }}</td>
        </ng-container>

        <ng-container matColumnDef="Qty">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Qty
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Qty }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Action">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
            </mat-select>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
        </table>
    </mat-card-content>
</mat-card>
