<div class="admin-user-header">
  <button (click)="togglesidebar()" class="toggle-btn">
    <img
      src="assets/images/Hamburger_icon.svg"
      class="hamburger_icon"
      alt=""
    />
  </button>

  <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ this.name }}
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
      <li>
        <button
          mat-raised-button
          class="signout"
          (click)="accountSelectionUpdate(this.userName)"
        >
          Change Account Selection
        </button>
      </li>
      <li>
        <button mat-raised-button class="signout" (click)="Logout()">
          Sign Out
        </button>
      </li>
    </ul>
  </div>
</div>
<mat-sidenav-container class="admin-sidebar">
  <mat-sidenav #drawer mode="side" [opened]="opened" class="admin-sidebar-main">
   <div class="logo-sidebar"> 
    <mat-list-item class="parent"> 
      <a routerLink="/dashboard"
      ><img class="imageCss admin-sidebar-logo" src="assets/images/logo.png"
        /></a> 
    </mat-list-item>
  </div>
  
  <div class="admin-sidebar-left"><br>
    <div class="mb-3 navbar-search-input">
      <input
        type="email"
        class="form-control"
        id="exampleFormControlInput1"
        placeholder="Search for menu "
      />
    </div>
    <mat-nav-list>
      <!--<mat-list-item class="parent">
        <span class="full-width" routerLink="/dashboard"> Dashboard</span>
        <mat-icon mat-list-icon>dashboard</mat-icon>
      </mat-list-item>-->

      <!-- Receive Sub Menu -->
      <mat-list-item
        *ngIf="menuStatus === 'rfMenu'"
        (click)="showreceviceSubmenushowhide()"
        class="parent"
      >
        <span class="full-width" *ngIf="isExpanded || isShowing">Receive</span>
        <mat-icon mat-list-icon>store</mat-icon>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showreceviceSubmenu }"
          *ngIf="isExpanded || isShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
      <div
        [style.display]="receviedisplay"
        [style.flex-direction]="receviedirection"
        class="submenu"
        [ngClass]="{ expanded: showreceviceSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <a mat-list-item routerLink="/main-nav/receive/staging">Staging</a>
        <a mat-list-item routerLink="/main-nav/receive/putaway">Putaway</a>
        <a mat-list-item routerLink="/main-nav/receive/onestep">
          Inbound 1-Step</a
        >
      </div>

      <!-- Tally Menu -->
      <mat-list-item
        *ngIf="menuStatus === 'customerService' || menuStatus === 'management'"
        class="parent" routerLink="/main-nav/tallys/read"
      >
      <mat-icon mat-list-icon>assignment_ind</mat-icon>
      <div class="menu-item-with-icon">
        <span class="full-width" >
          Tally info</span
        >
      </div>
        
      </mat-list-item>

      <div
        [style.display]="tallysdisplay"
        [style.flex-direction]="tallysdirection"
        class="submenu"
        [ngClass]="{ expanded: showtallysSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <a mat-list-item routerLink="/main-nav/tallys/read">Read</a>
        <!-- <button mat-list-item routerLink="/tallys/add">Create</button> -->
        <!-- <a mat-list-item>Update</a> -->
      </div>

      <!-- Building Sub Menu -->
      <mat-list-item
        *ngIf="menuStatus === 'maintanance'"
        (click)="showBuildingSubmenushowhide()"
        class="parent" routerLink="/main-nav/building/read"
      >

      <mat-icon mat-list-icon>home</mat-icon>

      <div class="menu-item-with-icon">
        <span class="full-width w-100"
        *ngIf="isExpanded || isShowing"
          >Building</span
        >
      </div>
       
        <!-- <mat-icon
              class="menu-button"
              [ngClass]="{ rotated: showBuildingSubmenu }"
              *ngIf="isExpanded || isShowing"
              >expand_more</mat-icon
            > -->
      </mat-list-item>
      <div
        [style.display]="buildingdisplay"
        [style.flex-direction]="buildingdirection"
        class="submenu"
        [ngClass]="{ expanded: showBuildingSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/building/read">Read</a> -->
      </div>

      <!-- Carriers -->
      <mat-list-item
        *ngIf="menuStatus === 'maintanance'"
        (click)="showCarrierSubmenushowhide()"
        class="parent" routerLink="/main-nav/carrier/read"
      >
      <mat-icon mat-list-icon>assignment_ind</mat-icon>

      <div class="menu-item-with-icon">
        <span class="full-width w-100"
          *ngIf="isExpanded || isShowing" 
          >Carriers</span
        >
      </div>
        
        <!-- <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showCarrierSubmenu }"
          *ngIf="isExpanded || isShowing"
          >expand_more</mat-icon
        > -->
      </mat-list-item>
      <div
        [style.display]="carrierdisplay"
        [style.flex-direction]="carrierdirection"
        class="submenu"
        [ngClass]="{ expanded: showCarrierSubmenu }"
        *ngIf="isShowing || isExpanded"
      ></div>

      <!-- <mat-list-item (click)="showItemSubmenushowhide()" class="parent">
          <span class="full-width" *ngIf="isExpanded || isShowing">Items</span>
          <mat-icon mat-list-icon>assignment_ind</mat-icon>
          <mat-icon
            class="menu-button"
            [ngClass]="{ rotated: showItemSubmenu }"
            *ngIf="isExpanded || isShowing"
            >expand_more</mat-icon
          >
        </mat-list-item>
        <div
          [style.display]="itemdisplay"
          [style.flex-direction]="itemdirection"
          class="submenu"
          [ngClass]="{ expanded: showItemSubmenu }"
          *ngIf="isShowing || isExpanded"
        >
          <a mat-list-item routerLink="/item/read">Read</a>
        </div> -->

      <!-- MapZone menu -->
      <mat-list-item
        *ngIf="menuStatus === 'maintanance'"
        (click)="showMapZoneSubmenushowhide()"
        class="parent" routerLink="/main-nav/mapzone/read"
      >
        <mat-icon mat-list-icon>map</mat-icon>
        <div class="menu-item-with-icon">
          <span
            class="full-width w-100"
            *ngIf="isExpanded || isShowing"

            >Map Zone</span
          >
          <!-- <mat-icon
              class="menu-button"
              [ngClass]="{ rotated: showMapZoneSubmenu }"
              *ngIf="isExpanded || isShowing"
              >expand_more</mat-icon
            > -->
        </div>
      </mat-list-item>
      <div
        [style.display]="mapZonedisplay"
        [style.flex-direction]="mapZonedirection"
        class="submenu"
        [ngClass]="{ expanded: showMapZoneSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/mapzone/read">Read</a> -->
      </div>

      <!-- Order Sub Menu -->
      <mat-list-item
        *ngIf="menuStatus === 'customerService' || menuStatus === 'management'"
        (click)="showordersSubmenushowhide()"
        class="parent" routerLink="/main-nav/orders/read"
      >

      <mat-icon mat-list-icon>add_shopping_cart</mat-icon>

      <div class="menu-item-with-icon">
        <span
          class="full-width"
          *ngIf="isExpanded || isShowing"
          
          >Orders</span
        >
      </div>
       
        <!-- <mat-icon
              class="menu-button"
              [ngClass]="{ rotated: showorderSubmenu }"
              *ngIf="isExpanded || isShowing"
              >expand_more</mat-icon
            > -->
      </mat-list-item>
      <div
        [style.display]="ordersdisplay"
        [style.flex-direction]="ordersdirection"
        class="submenu"
        [ngClass]="{ expanded: showorderSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/orders/read">Order-Read</a> -->
      </div>

      <!-- Storer Sub Menu -->
      <mat-list-item
        *ngIf="menuStatus === 'maintanance'"
        (click)="showstorerSubmenushowhide()"
        class="parent" routerLink="/main-nav/storer"
      >
      <mat-icon mat-list-icon>store</mat-icon>

      <div class="menu-item-with-icon">
        <span
          class="full-width"
          *ngIf="isExpanded || isShowing"
          
          >Storer</span
        >
      </div>
        
        <!-- <mat-icon
              class="menu-button"
              [ngClass]="{ rotated: showstorerSubmenu }"
              *ngIf="isExpanded || isShowing"
              >expand_more</mat-icon
            > -->
      </mat-list-item>
      <div
        [style.display]="storerdisplay"
        [style.flex-direction]="storerdirection"
        class="submenu"
        [ngClass]="{ expanded: showstorerSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/storer">Storer</a> -->
        <!-- <a mat-list-item routerLink="/receive/putaway">Putaway</a> -->
        <!-- <a mat-list-item routerLink="/receive/onestep"> Inbound 1-Step</a> -->
      </div>

      <!-- Shipping Sub Menu -->
      <mat-list-item
        *ngIf="menuStatus === 'tall'"
        (click)="showshippingsSubmenushowhide()"
        class="parent"
      >
        <span class="full-width" *ngIf="isExpanded || isShowing">Shipping</span>
        <mat-icon mat-list-icon>explore</mat-icon>
        <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showshippingSubmenu }"
          *ngIf="isExpanded || isShowing"
          >expand_more</mat-icon
        >
      </mat-list-item>
      <div
        [style.display]="shippingsdisplay"
        [style.flex-direction]="shippingsdirection"
        class="submenu"
        [ngClass]="{ expanded: showshippingSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item></a> -->
      </div>

      <!-- Map Location Sub Menu -->
      <mat-list-item
        *ngIf="
          menuStatus === 'management' ||
          menuStatus === 'inventory' ||
          menuStatus === 'maintanance'
        "
        (click)="showMapLocationMenu()"
        class="parent"  routerLink="/main-nav/map-location/read"
      >
        <mat-icon mat-list-icon>explore</mat-icon>
        <div class="menu-item-with-icon">
          <span
            class="full-width w-100"
            *ngIf="isExpanded || isShowing"
           
            >Map Location</span
          >
          <!-- <mat-icon
                class="menu-button"
                [ngClass]="{ rotated: showMapLocationMenu }"
                *ngIf="isExpanded || isShowing"
                >expand_more</mat-icon
              > -->
        </div>
      </mat-list-item>
      <div
        [style.display]="mapLocationDispaly"
        [style.flex-direction]="mapLocationDirection"
        class="submenu"
        [ngClass]="{ expanded: showMapLocationMenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/map-location/read"
              >Map Location</a
            > -->
      </div>

      <!-- Map Items -->
      <mat-list-item
        *ngIf="
          menuStatus === 'customerService' ||
          menuStatus === 'management' ||
          menuStatus === 'inventory'
        "
        (click)="showItemSubmenushowhide()"
        class="parent" routerLink="/main-nav/item/read"
      >
      <div class="menu-item-with-icon">
        <span
          class="full-width"
          *ngIf="isExpanded || isShowing"
          
          >Items</span
        >
      </div>
        <mat-icon mat-list-icon>assignment_ind</mat-icon>
        <!-- <mat-icon
              class="menu-button"
              [ngClass]="{ rotated: showItemSubmenu }"
              *ngIf="isExpanded || isShowing"
              >expand_more</mat-icon
            > -->
      </mat-list-item>
      <div
        [style.display]="itemdisplay"
        [style.flex-direction]="itemdirection"
        class="submenu"
        [ngClass]="{ expanded: showItemSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/item/read">Read</a> -->
      </div>

      <!-- Consignee -->
      <mat-list-item
        *ngIf="menuStatus === 'maintanance'"
        (click)="showConsigneeSubmenuShowhide()"
        class="parent" routerLink="/main-nav/consignee/read"
      >

      <div class="menu-item-with-icon">
        <span
          class="full-width"
          *ngIf="isExpanded || isShowing"
          
          >Consignee</span
        >
      </div>
        <mat-icon mat-list-icon>explore</mat-icon>
        <!-- <mat-icon
              class="menu-button"
              [ngClass]="{ rotated: showConsigneeSubmenu }"
              *ngIf="isExpanded || isShowing"
              >expand_more</mat-icon
            > -->
      </mat-list-item>
      <div
        [style.display]="consigneeDisplay"
        [style.flex-direction]="consigneeDirection"
        class="submenu"
        [ngClass]="{ expanded: showConsigneeSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/consignee/read"
              >Work With Consignee</a
            > -->
      </div>

      <!-- Exit points -->
      <mat-list-item
        *ngIf="menuStatus === 'maintanance'"
        (click)="showExitPointSubMenuShowhide()"
        class="parent" routerLink="/main-nav/exitpoint/read"
      >
        <mat-icon mat-list-icon>explore</mat-icon>
        <div class="menu-item-with-icon">
          <span
            class="full-width w-100"
            *ngIf="isExpanded || isShowing"
            
            >Exit-Points</span
          >
          <!-- <mat-icon
              class="menu-button"
              [ngClass]="{ rotated: showExitPointSubmenu }"
              *ngIf="isExpanded || isShowing"
              >expand_more</mat-icon
            > -->
        </div>
      </mat-list-item>
      <div
        [style.display]="exitPointDisplay"
        [style.flex-direction]="exitPointDirection"
        class="submenu"
        [ngClass]="{ expanded: showExitPointSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/exitpoint/read"
              >Work with Exit points</a
            > -->
      </div>

      <!-- Move Queue -->
      <mat-list-item
        *ngIf="menuStatus === 'management'"
        (click)="showMoveQueueSubmenuShowhide()"
        class="parent" routerLink="/main-nav/movequeue/read"
      >
      <mat-icon mat-list-icon>store</mat-icon>

      <div class="menu-item-with-icon">
        <span
          class="full-width"
          *ngIf="isExpanded || isShowing"
          
          >Move Queue</span
        >
      </div>
        
        <!-- <mat-icon
              class="menu-button"
              [ngClass]="{ rotated: showMoveQueueSubmenu }"
              *ngIf="isExpanded || isShowing"
              >expand_more</mat-icon
            > -->
      </mat-list-item>
      <div
        [style.display]="moveQueueDisplay"
        [style.flex-direction]="moveQueueDirection"
        class="submenu"
        [ngClass]="{ expanded: showMoveQueueSubmenu }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/movequeue/read"
              >Work with Move Queue</a
            > -->
      </div>


        <!--Equpment Operators Submenu(941)-->
        <mat-list-item   *ngIf="menuStatus === 'management'" 
          (click)="showEquipmentOperatorsSubmenu()" 
          class="parent" routerLink="/main-nav/equipmentOperators/read"
        >
        <mat-icon mat-list-icon>home</mat-icon>
          <div class="menu-item-with-icon">
              <span class="full-width" *ngIf="isExpanded || isShowing" 
                >Equipment Operators</span
              >
          </div>
        
        <!-- <mat-icon
          class="menu-button"
          [ngClass]="{ rotated: showEquipmentOperators }"
          *ngIf="isExpanded || isShowing"
          >expand_more</mat-icon
        > -->
      </mat-list-item>
      <div
        [style.display]="equipmentOperatorsDisplay"
        [style.flex-direction]="equipmentOperatorsDirection"
        class="submenu"
        [ngClass]="{ expanded: showEquipmentOperators }"
        *ngIf="isShowing || isExpanded"
      >
        <!-- <a mat-list-item routerLink="/main-nav/equipmentOperators/read">Read</a> -->
      </div> 
    </mat-nav-list>
  </div></mat-sidenav>

  <mat-sidenav-content>
    <div class="admin-user-header">
      <button (click)="togglesidebar()" class="toggle-btn">
        <img
          src="assets/images/Hamburger_icon.svg"
          class="hamburger_icon"
          alt=""
        />
      </button>
      <div class="dropdown">
        <button
          class="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ this.name }}
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li>
            <button
              mat-raised-button
              class="signout"
              (click)="accountSelectionUpdate(this.userName)"
            >
              Change Account Selection
            </button>
          </li>
          <li>
            <button mat-raised-button class="signout" (click)="Logout()">
              Sign Out
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div class="content mat-elevation-z4">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
