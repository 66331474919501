<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>Extract Report Sort Clause</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>ExtractName</mat-label> 
                    <input matInput formControlName="ExtractName"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> ExtractFile </mat-label> 
                    <input matInput formControlName="ExtractFile"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>OrderBy </mat-label> 
                    <input matInput formControlName="OrderBy"/>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>GroupBy </mat-label> 
                    <input matInput formControlName="GroupBy"/>
                </mat-form-field>

            </div> 
            <div class="col">
                <button mat-button (click)="addExtractReportSort()" class="addButton">
                    
                    <span>Create</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>
