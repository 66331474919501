import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { ItemCreate3Component } from '../item-create3/item-create3.component';
import Swal from 'sweetalert2';
import { IitemService } from '../iitem.service';

@Component({
  selector: 'app-item-create2',
  templateUrl: './item-create2.component.html',
  styleUrls: ['./item-create2.component.css'],
})
export class ItemCreate2Component implements OnInit {
  form!: FormGroup;
  result: any;

  constructor(
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    public itemSvc: IitemService,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemCreate2Component>,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({

      storeNumber: this.anyvariable.storeNumber,
      suffix: this.anyvariable.suffix,
      fullStorer: this.anyvariable.storeNumber + '\xa0' + this.anyvariable.suffix,
      item: this.anyvariable.item + '\xa0' + this.anyvariable.itemDescription1,

      unitGrossWeight: '',
      unitWeightType: '', //unit weight of mewasure
      casesPerTier: '', //unit per tier
      tiersPerPallet: '', 
      unitSquareFeet: '',
      unitCubeFeet: '',
      unitInchesHigh: '',
      unitInchesWide: '', 
      unitInchesDeep: '',
      unitOfMeasure1: '', 
      unitOfMeasure2: '', 
      qtyPer2UnitOfMultiplier: '', //unit multiplier1
      unitOfMeasure3: '', 
      qtyPer3UnitOfMultiplier: '',//unit multiplier2
      unitOfMeasure4: '', 
      qtyPer4UnitOfMultiplier: '', //unit multiplier3
      inventoryUnit: '',
      shippingUnit: '', 
    });
  }

  createItem2(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemCreate2( 
       res.storeNumber,
      this.anyvariable.suffix,
       this.anyvariable.item,
       
        res.unitGrossWeight,
       res.unitWeightType,
       res.casesPerTier,
        res.tiersPerPallet,
        res.unitSquareFeet,
        res.unitCubeFeet,
        res.unitInchesHigh,
        res.unitInchesWide, 
        res.unitInchesDeep,
        res.unitOfMeasure1, 
        res.unitOfMeasure2, 
        res.qtyPer2UnitOfMultiplier, //unit multiplier1
        res.unitOfMeasure3, 
        res.qtyPer3UnitOfMultiplier,//unit multiplier2
        res.unitOfMeasure4, 
        res.qtyPer4UnitOfMultiplier, //unit multiplier3
        res.inventoryUnit,
        res.shippingUnit

        

        ).subscribe((data: any) => {
        this.result = data;

       var length = this.result[0].data.length;

          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Created/Updated'){
         
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this. create3();
         
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
    this.router.navigate(['/main-nav/item/read']);
  }


  closeDialog(){
    this.dialogRef.close(); 
  }



  create3() {
    var res = this.anyvariable;
    this.matDialog.open(ItemCreate3Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

  
}
