import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-labor-processing-add',
  templateUrl: './labor-processing-add.component.html',
  styleUrls: ['./labor-processing-add.component.css']
})
export class LaborProcessingAddComponent implements OnInit {
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<LaborProcessingAddComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      Building:'',
      Storer:'',
      Process:'',
      Group:'',
      AccountStep:'',
      MoveType:'',
      Condition:'',
      Task:'',
      Responsible:'',
      MoveQueueTransaction:''
  
    });
  }

  addLaborProcessing(){
    
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
