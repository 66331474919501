import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { StorerServiceService } from '../storer-service.service';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';

@Component({
  selector: 'app-storer-delete',
  templateUrl: './storer-delete.component.html',
  styleUrls: ['./storer-delete.component.css']
})
export class StorerDeleteComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user = GlobalComponent.user;

  constructor(
    
    private activeRouter:ActivatedRoute, 
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router: Router,
    private CustomAlertService: CustomAlertService,
    private matDialog: MatDialog,

    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<StorerDeleteComponent>
    ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
     storeNumber:this.anyvariable.STORE,
     suffix:this.anyvariable.STSFX,
     address:this.anyvariable.STADD1,
     phone:this.anyvariable.STPHAR + '\xa0' + this.anyvariable.STPHEX + '\xa0' +  this.anyvariable.STPHNU,
    // primaryAccountRep:''

     });
  }

  storerDelete(){
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure you want to Delete this storer '+this.anyvariable.STORE+' ?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {

    
      var res = this.form.value;
      this.result = this.storerSvc
      .storerServiceDelete(
        
        res.storeNumber,
        res.suffix
        
        )
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
  
        if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Deleted Successfully'){
          //alert('Record Created SuccessFully');
          
         const msg= this.result[0].data[this.result[0].data.length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.deleted();
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
       // const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
       }
      });
  
    }
  });
  }
  
  successMessage(msg:any){
    Swal.fire(msg,'','success');
   this.closeDialog();
    this.router.navigate(['/main-nav/storer']);
   // this.router.navigate(['/main-nav/tallys/read']);
  }
  
  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  deleted(){
    console.log('*************STORER DELETED**********')
  }

}
