import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
@Component({
  selector: 'app-general-invoice-details-comments',
  templateUrl: './general-invoice-details-comments.component.html',
  styleUrls: ['./general-invoice-details-comments.component.css']
})
export class GeneralInvoiceDetailsCommentsComponent implements OnInit {


  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<GeneralInvoiceDetailsCommentsComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
     Control:'',
     sequence:'',
     description:''
    })
  }

  comment(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
