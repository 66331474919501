<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain Item Rates</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Storer: </mat-label>
          <input
            matInput
            formControlName="storeNumber"
            style="text-transform: uppercase"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Suffix: </mat-label>
          <input
            matInput
            formControlName="suffix"
            style="text-transform: uppercase"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Item: </mat-label>
          <input
            matInput
            formControlName="item"
            style="text-transform: uppercase"
          />
        </mat-form-field>
      </div>
      </div>
      <b>Storer Level Settings</b>
      <hr />
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Receiving </mat-label>
          <input readonly matInput formControlName="Receiving" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Regular </mat-label>
          <input readonly matInput formControlName="Regular" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Recurring</mat-label>
          <input matInput formControlName="Recurring" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Distribution</mat-label>
          <input matInput formControlName="Distribution" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Item Minimum</mat-label>
          <input matInput formControlName="ItemMinimum" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Alternate</mat-label>
          <input matInput formControlName="Alternate" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Lot Minimum</mat-label>
          <input matInput formControlName="LotMinimum" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Item Minimum</mat-label>
          <input matInput formControlName="ItemMinimum1" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Lot Minimum</mat-label>
          <input matInput formControlName="LotMinimum1" />
        </mat-form-field>
      </div>
      </div>
      <b>Storage Charges</b>
      <hr />

      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Receiving Rate</mat-label>
          <input matInput formControlName="rcvStorageRate" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Basis</mat-label>
          <input matInput formControlName="rcvStorageBase" />
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label> Minimum</mat-label>
          <input matInput formControlName="rcvStorageMin" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Recurring Rate</mat-label>
          <input matInput formControlName="rcrStorageRate" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Basis</mat-label>
          <input matInput formControlName="rcrStorageBase" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Minimum</mat-label>
          <input matInput formControlName="rcrStorageMin" />
        </mat-form-field>
      </div>
      </div>
      <b>Handling Charges</b>
      <hr />
      <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Regular Rate </mat-label>
          <input matInput formControlName="regHandlingRate" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Basis</mat-label>
          <mat-select formControlName="regHandlingBase">
            <mat-option value="QY">QY-Received Qty</mat-option>
            <mat-option value="CF">CF-Cubic Feet</mat-option>
            <mat-option value="CW">CW-Hundred Weight</mat-option>
            <mat-option value="TN">TN-Ton</mat-option>
            <mat-option value="PL">PL-Pallet</mat-option>
          </mat-select>
        </mat-form-field>
        
        <mat-form-field appearance="outline">
          <mat-label> Minimum</mat-label>
          <input matInput formControlName="regHandlingMin" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Distribution Rate </mat-label>
          <input matInput formControlName="distHandlingRate" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Basis</mat-label>
          <mat-select formControlName="distHandlingBase">
            <mat-option value="QY">QY-Received Qty</mat-option>
            <mat-option value="CF">CF-Cubic Feet</mat-option>
            <mat-option value="CW">CW-Hundred Weight</mat-option>
            <mat-option value="TN">TN-Ton</mat-option>
            <mat-option value="PL">PL-Pallet</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Minimum</mat-label>
          <input matInput formControlName="distHandlingMin" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Alternate Rate </mat-label>
          <input matInput formControlName="altHandlingRate" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Basis</mat-label>
          <mat-select formControlName="altHandlingBase">
            <mat-option value="QY">QY-Received Qty</mat-option>
            <mat-option value="CF">CF-Cubic Feet</mat-option>
            <mat-option value="CW">CW-Hundred Weight</mat-option>
            <mat-option value="TN">TN-Ton</mat-option>
            <mat-option value="PL">PL-Pallet</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Minimum</mat-label>
          <input matInput formControlName="altHandlingMin" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Insurance Liability Amount</mat-label>
          <input matInput formControlName="insLiabilityAmount" />
        </mat-form-field>

      </div>
      </div>
      <button mat-button (click)="itemUpdate4()" class="addButton buttons">
        Update
      </button>
      </form>
    </mat-card-content>
</mat-card>

