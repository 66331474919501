<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>Maintain Storer Rates & Bases</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

      <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                
                  <mat-form-field  appearance="outline">
                      <mat-label> Storer: </mat-label> 
                      <input matInput formControlName="storeNumber" style="text-transform: uppercase"/>
                  </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Suffix: </mat-label> 
                        <input matInput formControlName="suffix" style="text-transform: uppercase"/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Store Name: </mat-label> 
                        <input matInput formControlName="storeName" style="text-transform: uppercase"/>
                    </mat-form-field>
            
                    <div>
                    <mat-form-field appearance="outline">   
                      <mat-label> Default Freight Charge: </mat-label>
                      <mat-select formControlName="defaultFreightCharge">
                        <mat-option value="PS">PS = Prepaid storer</mat-option>
                        <mat-option value="CF">CO = Collect</mat-option>
                        <mat-option value="CW">PW = Prepaid Warehouse</mat-option>
                        <mat-option value="WC">WC = Will Call </mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls['defaultFreightCharge'].hasError('required')">
                        Default Freight Charge is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
             
               
                    <mat-form-field appearance="outline" >
                        <mat-label> Default COD Charge:</mat-label>
                        <input matInput formControlName="defaultCODCharge"/>
                    </mat-form-field>

                    <mat-form-field appearance="outline" >
                    <mat-label> Deferred Handling Percentage:</mat-label>
                    <input matInput formControlName="deferredHandlingPer"/>
                    <mat-error *ngIf="form.controls['deferredHandlingPer'].hasError('required')">
                        Deferred Handling Percentage is <strong>required</strong>
                      </mat-error>
                    </mat-form-field>
                    </div>
                
                <table style="margin-left: 20px">
                    <tr>
                        <th style="text-align: center;">Default</th>
                        <th style="text-align: center;">Rate</th>
                        <th style="text-align: center;">Basis</th>
                    </tr>
                    <tr>
                        <td>Recurring Storage</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="rcrStorageRate"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <mat-select formControlName="rcrStorageBasis">
                                <mat-option value="QY">QY=Received Qty</mat-option>
                                <mat-option value="CF">CF=Cubic Feet</mat-option>
                                <mat-option value="CW">CW=Hundred Weight</mat-option>
                                <mat-option value="PL">PL=Pallet </mat-option>
                            </mat-select>
                            </mat-form-field>
                        </td> 
                    </tr>

                    <tr>
                        <td>Receiving Storage</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="recStorageRate"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <mat-select formControlName="recStorageBasis">
                                <mat-option value="QY">QY=Received Qty</mat-option>
                                <mat-option value="CF">CF=Cubic Feet</mat-option>
                                <mat-option value="CW">CW=Hundred Weight</mat-option>
                                <mat-option value="PL">PL=Pallet </mat-option>
                              </mat-select>
                            </mat-form-field>
                        </td> 
                    </tr>

                    <tr>
                        <td>Regular Handling</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="regHandStorageRate"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <mat-select formControlName="regHandStorageBasis">
                                <mat-option value="QY">QY=Received Qty</mat-option>
                                <mat-option value="CF">CF=Cubic Feet</mat-option>
                                <mat-option value="CW">CW=Hundred Weight</mat-option>
                                <mat-option value="PL">PL=Pallet </mat-option>
                              </mat-select>
                            </mat-form-field>
                        </td> 
                    </tr>

                    <tr>
                        <td>Alternate Handling</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="altHandStorageRate"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <mat-select formControlName="altHandStorageBasis">
                                <mat-option value="QY">QY=Received Qty</mat-option>
                                <mat-option value="CF">CF=Cubic Feet</mat-option>
                                <mat-option value="CW">CW=Hundred Weight</mat-option>
                                <mat-option value="PL">PL=Pallet </mat-option>
                              </mat-select>
                            </mat-form-field>
                        </td> 
                    </tr>

                    <tr>
                        <td>Distribution Handling</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="distHandStorageRate"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <mat-select formControlName="distHandStorageBasis">
                                <mat-option value="QY">QY=Received Qty</mat-option>
                                <mat-option value="CF">CF=Cubic Feet</mat-option>
                                <mat-option value="CW">CW=Hundred Weight</mat-option>
                                <mat-option value="PL">PL=Pallet </mat-option>
                              </mat-select>
                            </mat-form-field>
                        </td> 
                        
                    </tr>

                    <tr>
                        <td>Insurance Liability</td>
                        <td></td>
                        <td>
                            <mat-form-field >
                                <mat-label> </mat-label>
                                <mat-select formControlName="insLiability">
                                    <mat-option value="LB">LB=Pound</mat-option>
                                    <mat-option value="PV">PV=To Be determined</mat-option>
                                    <mat-option value="SR">SR=To Be determined</mat-option>
                                    <mat-option value="OT">OT=Other</mat-option>
                                </mat-select>
                              </mat-form-field>
                        </td> 
                        
                    </tr>
                </table>
                
                    
                <div style="margin-left: 20px"><b>Amount:
                    <mat-form-field appearance="outline" >
                        <mat-label> </mat-label>
                        <input matInput formControlName="amount" class="example-right-align" placeholder="0"/><span matSuffix>$</span>
                      <mat-hint >Enter in Dollars</mat-hint>
                      <mat-error *ngIf="form.controls['amount'].hasError('pattern')">
                        Only <strong>numbers </strong> are allowed
                      </mat-error>
                    </mat-form-field>
                </b></div>
                            <mat-form-field appearance="outline" >
                                <mat-label> W/R Lot Minimum:</mat-label>
                                <input matInput formControlName="wrLotMinimum"/>
                                <mat-error *ngIf="form.controls['wrLotMinimum'].hasError('pattern')">
                                    Only <strong>numbers </strong> are allowed
                                  </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" >
                                <mat-label> W/R Item Hdl Min:</mat-label>
                                <input matInput formControlName="wrItemHdlMin"/>
                                <mat-error *ngIf="form.controls['wrItemHdlMin'].hasError('pattern')">
                                    Only <strong>numbers </strong> are allowed
                                  </mat-error>
                            </mat-form-field>
                            
                            <mat-form-field appearance="outline" >
                                <mat-label>W/R Item Stg Min:</mat-label>
                                <input matInput formControlName="wrItemStgMin"/>
                                <mat-error *ngIf="form.controls['wrItemStgMin'].hasError('pattern')">
                                    Only <strong>numbers </strong> are allowed
                                  </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" >
                                <mat-label>  W/R Storage Min:</mat-label>
                                <input matInput formControlName="wrStorageMin"/>
                                <mat-error *ngIf="form.controls['wrStorageMin'].hasError('pattern')">
                                    Only <strong>numbers </strong> are allowed
                                  </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" >
                                <mat-label>  W/R Handling Min:</mat-label>
                                <input matInput formControlName="wrHandlingMin"/>
                                <mat-error *ngIf="form.controls['wrHandlingMin'].hasError('pattern')">
                                    Only <strong>numbers </strong> are allowed
                                  </mat-error>
                            </mat-form-field>

                        <div>
                            <mat-form-field appearance="outline" >
                                <mat-label>Storage Lot Minimum:</mat-label>
                                <input matInput formControlName="storageLotMin"/>
                                <mat-error *ngIf="form.controls['storageLotMin'].hasError('pattern')">
                                    Only <strong>numbers </strong> are allowed
                                  </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" >
                                <mat-label>Storage Item Minimum:</mat-label>
                                <input matInput formControlName="storageItemMin"/>
                                <mat-error *ngIf="form.controls['storageItemMin'].hasError('pattern')">
                                    Only <strong>numbers </strong> are allowed
                                  </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" >
                                <mat-label>storage Invoice Min</mat-label>
                                <input matInput formControlName="storageInvoiceMin"/>
                                <mat-error *ngIf="form.controls['storageInvoiceMin'].hasError('pattern')">
                                    Only <strong>numbers </strong> are allowed
                                  </mat-error>
                            </mat-form-field>

                            
                            <button mat-button (click)="storerUpdate3()"class="addButton buttons">
                            <span>Update</span>
                            </button>
                        </div>
              </div>
            </div>
        </form>
      </mat-card-content>    
    
</mat-card> 


