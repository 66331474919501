import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IItemLocationService } from '../i-item-location.service';
import { StorerServiceService } from 'src/app/features/storer/storer-service.service';


@Component({
  selector: 'app-two-side-adjustment',
  templateUrl: './two-side-adjustment.component.html',
  styleUrls: ['./two-side-adjustment.component.css'],
})

export class TwoSideAdjustmentComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    public iItemLcnSvc: IItemLocationService,
    public storerService: StorerServiceService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<TwoSideAdjustmentComponent>
  ) {}

  form!: FormGroup;
  result:any;
  duplicateSource: any;
  fullStorer:any;
  user = localStorage.getItem('userName');
  fullItem = this.anyvariable[0].ITEM + '  ' + this.anyvariable[0].ITDSC1;

  ngOnInit(): void {

    console.log('ITEM AND ITEM LOCATION ARRAY DATA');
    console.log(this.anyvariable);

     // ----- CALL TO STORER FOR STORER NAME -----
     this.storerService.getAll(this.user, this.anyvariable[1].LCSTOR).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('STORER NAME FOR 2-SIDE ADJUSTMENT');
      console.log(this.duplicateSource[0].STNAME);
      this.fullStorer = this.anyvariable[1].LCSTOR + '  ' + this.duplicateSource[0].STNAME;
    });

    this.form = this.fb.group({
      storer: this.anyvariable[1].LCSTOR,
      suffix: '',
      itemCode: this.anyvariable[1].LCITEM ,
      lot1: this.anyvariable[1].LCLTC1,
      lot2: this.anyvariable[1].LCLTC2,
      lot3: this.anyvariable[1].LCLTC3,
      bldgNum: this.anyvariable[1].LCBLDG,
      floor: this.anyvariable[1].LCFLOR,
      aisle: this.anyvariable[1].LCAISL,
      slot: this.anyvariable[1].LCSLOT, 
      inventoryAdjBucket: '',
      adjQuantity: '',
      reasonCode: '',
      referToBill: '',
      number: '',
      line: this.anyvariable[1].LCLSEQ,
      comment:'',
      reasonCodeDef:'',
      toItem:'',
      toBucket:'',
      toLot1:'',
      toLot2:'',
      toLot3:'',
      bldg:'',
      toFloor:'',
      toAisle:'',
      toSlot:'',
      toSortSeq:'',
      lots: this.anyvariable[1].LCLTC1 + ' | ' + this.anyvariable[1].LCLTC2 + ' | ' + this.anyvariable[1].LCLTC3,
      location: this.anyvariable[1].LCBLDG + '   ' + this.anyvariable[1].LCFLOR + '  ' + this.anyvariable[1].LCAISL + '/' + this.anyvariable[1].LCSLOT,
        
      available: this.anyvariable[1].LCQAVL,
      held: this.anyvariable[1].LCQHLD,
      committed: this.anyvariable[1].LCQCOM,
      damaged:  this.anyvariable[1].LCQDAM,
      user1: this.anyvariable[1].LCQUS1,
      user2: this.anyvariable[1].LCQUS2,
      user3: this.anyvariable[1].LCQUS3,
      user4: this.anyvariable[1].LCQUS4,


    });
  }

  twoSideAdj() {
  
    var res = this.form.value;
    this.iItemLcnSvc
      .ItemLocTwoSideAdj(
        res.storer,
        res.suffix,
        res.itemCode,
        res.lot1,
        res.lot2,
        res.lot3,
        res.bldgNum,
        res.floor,
        res.aisle,
        res.slot,
        res.inventoryAdjBucket,
        res.adjQuantity,
        res.reasonCode,
        res.referToBill,
        res.number,
        res.line,
        res.comment,
        res.reasonCodeDef,
        res.toItem,
        res.toBucket,
        res.toLot1,
        res.toLot2,
        res.toLot3,
        res.bldg,
        res.toFloor,
        res.toAisle,
        res.toSlot,
        res.toSortSeq
      )
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[15].value;
        var length = this.result[0].data.length;

        if(this.result[0].data[length-1].value=='TwoSided Adjustment Successfully Created'){
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
       }else{
        const msg= this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }
  
  closeDialog() {
    this.dialogRef.close();
  }
}
