<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
          <mat-card-title>Consolidate Carriers</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
    
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" class="form over-Flow">
      
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Carrier Code</mat-label>
              <input matInput required formControlName="carrierCode" />
            </mat-form-field>
      
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Carrier Name</mat-label>
              <input matInput formControlName="carrierName" />
            </mat-form-field>
      
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Carrier Address </mat-label>
              <input matInput required formControlName="address1" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Carrier Address 2 </mat-label>
              <input matInput required formControlName="address2" />
            </mat-form-field>

            <mat-form-field appearance="outline"  class="full-width">
              <mat-label>Carrier SCAC Code </mat-label>
              <input matInput required formControlName="carrierSCACCode" />
            </mat-form-field>
       
            <mat-form-field appearance="outline"  class="full-width">
              <mat-label>Consolidate References to Carrier</mat-label>
              <input matInput required formControlName="consolidateRef" />
            </mat-form-field>
        
            
          </div>
        </div>
        <div>
          <button mat-button class="buttons removeButton" (click)="deleteCarrier()">
            Delete
          </button>
        </div>
      </form>
    </mat-card-content>
</mat-card>
