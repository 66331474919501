import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-customer-consolidate',
  templateUrl: './customer-consolidate.component.html',
  styleUrls: ['./customer-consolidate.component.css'],
})
export class CustomerConsolidateComponent implements OnInit {
  consolidateCustomerForm!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CustomerConsolidateComponent>,
    private router: Router,
    private fb: FormBuilder
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.consolidateCustomerForm = this.fb.group({
      CUST: [this.data.CUST],
      CUNAME: [this.data.CUNAME],
      XCUST: [],
    });
  }
}
