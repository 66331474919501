<mat-card>
    <mat-card-header>  
      <mat-card-title>    
        Work With Inbound Details</mat-card-title>
    </mat-card-header>

    <mat-card-content>        
        
          <div class="buttons">
            <button mat-button (click)="InboundDetailsAddForm()" class="addButton buttons">
              <mat-icon>add</mat-icon>
              <span>New Inbound Details</span>
            </button>
          </div>
          
                      
          <form [formGroup]="form" class="form over-Flow">
            <div class="row">
               <div class="col">
  
                       <mat-form-field appearance="outline">
                         <mat-label>Tally</mat-label>
                        <input matInput formControlName="IHTALY"/>
                       </mat-form-field>
  
                        <mat-form-field appearance="outline">
                        <mat-label>Storer</mat-label>
                                             
                       <input matInput formControlName="IHSTOR">
                       <!-- <input matInput formControlName="STNAME"> -->
                                        
                      </mat-form-field>  
                      <button
                  class="addButton"
                     mat-raised-button
                        type="submit"
                        placeholder="Search">
                        Search
                  </button>
             </div>  
            
            </div>
          </form>
          

          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="ST">
              <th mat-header-cell mat-header-cell *matHeaderCellDef>
                ST
              </th> 
              <td mat-cell *matCellDef="let element">{{element.IDACDL}}</td>
            </ng-container>
    
            <ng-container matColumnDef="Seq">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Seq 
                </th> 
                <td mat-cell *matCellDef="let element">{{element.IDSEQ}}</td>
            </ng-container>
    
            <ng-container matColumnDef="Item Code">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Item Code
                </th> 
                <td mat-cell *matCellDef="let element">{{element.IDITEM}}</td>
            </ng-container>
    
            <ng-container matColumnDef="Lot Codes">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Lot Codes
                </th> 
                <td mat-cell *matCellDef="let element">{{element.LOTS}}</td>
            </ng-container>
    
            <ng-container matColumnDef="In transit">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    In transit
                </th> 
                <td mat-cell *matCellDef="let element">{{element.IDQINT}}</td>
            </ng-container>
    
            <ng-container matColumnDef="Qty Recvd">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Qty Recvd
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.IDQREC }}</td>
            </ng-container>
              
            <ng-container matColumnDef="actions">
              <th  mat-header-cell mat-header-cell *matHeaderCellDef>
                Actions
              </th>
              <td mat-cell *matCellDef="let element">
                <mat-select  placeholder="Select Option">            
           
                <mat-option>
                    <button mat-icon-button (click)="InboundDetailsChangeForm(element)">
                    <mat-icon mat-icon-button color="primary">edit</mat-icon>
                    <mat-label>Change</mat-label>
                    </button>
                </mat-option>

                <mat-option>
                  <button mat-icon-button (click)="InboundDetailsStockStatusForm(element)">
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label>Stock Status</mat-label>
                  </button>
              </mat-option>

              <mat-option>
                <button mat-icon-button (click)="InboundDetailsActivityInquiryForm(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Activity Inquiry</mat-label>
                </button>
            </mat-option>

            <mat-option>
              <button mat-icon-button (click)="InboundDetailsExtraDataForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Extra Data</mat-label>
              </button>
          </mat-option>

          <mat-option>
            <button mat-icon-button (click)="InboundDetailsPurchaseOrdsForm(element)">
            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
            <mat-label>Purchase Ords</mat-label>
            </button>
        </mat-option>
                
                <mat-option>
                    <button mat-icon-button (click)="InboundDetailsCommentsForm(element)">
                    <mat-icon mat-icon-button color="primary">add_comment</mat-icon>
                    <mat-label>Comments</mat-label>
                    </button>
                </mat-option>
    
    
                

              

            <mat-option>
              <button mat-icon-button (click)="InboundDetailsDeleteForm(element)">
              <mat-icon mat-icon-button color="primary">delete</mat-icon>
              <mat-label>Delete</mat-label>
              </button>
          </mat-option>
    
             
              
              
                
                </mat-select>
              </td>
            </ng-container>
      
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
      
            
          </table>
    
          <mat-paginator
        #paginator
        [pageSizeOptions]="[4, 8, 16]"
        showFirstLastButtons
      ></mat-paginator>
    
