import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ExtractReportAddComponent } from './extract-report-add/extract-report-add.component';
import { ExtractReportChangeComponent } from './extract-report-change/extract-report-change.component';

@Component({
  selector: 'app-exract-reports-base-fields',
  templateUrl: './exract-reports-base-fields.component.html',
  styleUrls: ['./exract-reports-base-fields.component.css']
})
export class ExractReportsBaseFieldsComponent implements OnInit {

  displayedColumns: string[] = ['extractName', 'sequence', 'fieldName', 'filterName', 'columnHeading', 'Actions'];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      extractName: 'ACTIVESRL',
      sequence: '001',
      fieldName:'LCSTOR',
      filterName:'',
      columnHeading:'STORER',


    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      reportName: [null],
      fieldName: [null]
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  extractReportCreate() {
    this.matDialog.open(ExtractReportAddComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  extractReportUpdate(element :any) {
    this.matDialog.open(ExtractReportChangeComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }
  delete() {
    alert('deleted');
  }

}
