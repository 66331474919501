<mat-card>
    <mat-card-header>  
      <mat-card-title  >
        Work With Item Attributes</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div>
      <div class="buttons">
        <button
          mat-button
          (click)="ItemAttributesCreateForm()"
          class="addButton buttons"
        >
          <mat-icon>add</mat-icon>
          <span>New Item Attribute</span>
        </button>
      </div>
      <form [formGroup]="form" class="form over-Flow">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Storer</mat-label>
              <input matInput formControlName="IASTOR" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Item</mat-label>
              <input matInput formControlName="IAITEM" />
            </mat-form-field>

            <button
              class="addButton"
              mat-raised-button
              type="submit"
              placeholder="Search"
            >
              Search
            </button>
          </div>
        </div>
      </form>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="NumberType">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>Number Type</th>
          <td mat-cell *matCellDef="let element">{{ element.IANTYP }}</td>
        </ng-container>

        <ng-container matColumnDef="Number">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>Number</th>
          <td mat-cell *matCellDef="let element">{{ element.IANUMB }}</td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>Opt</th>
          <td mat-cell *matCellDef="let element">
            <mat-select placeholder="Select Option">
              <mat-option>
                <button
                  mat-icon-button
                  (click)="ItemAttributesChangeForm(element)"
                >
                  <mat-icon mat-icon-button color="primary">edit</mat-icon>
                  <mat-label>Change</mat-label>
                </button>
              </mat-option>

              <mat-option>
                <button
                  mat-icon-button
                  (click)="ItemAttributesDeleteForm(element)"
                >
                  <mat-icon mat-icon-button color="warn">delete</mat-icon>
                  <mat-label>Delete</mat-label>
                </button>
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <mat-paginator
        #paginator
        [pageSizeOptions]="[4, 8, 16]"
        showFirstLastButtons
      ></mat-paginator></div></mat-card-content
></mat-card>
