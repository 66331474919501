<!-- Task No :56 -->
<mat-card>
    <mat-card-header>  
      <mat-card-title >Work with Batch Details</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline">
                        <mat-label>Batch Number</mat-label>
                        <input matInput formControlName="BatchNo"/>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Carrier</mat-label>
                        <input matInput formControlName="Carrier"/>
                    </mat-form-field>

                </div>
            </div>
        </form>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Bill Number">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Bill Number
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.BillNumber }}</td>
            </ng-container>
      
            <ng-container matColumnDef="ST">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                ST
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.ST }}</td>
            </ng-container>

            <ng-container matColumnDef="Lane/Pos">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Lane/Pos
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LanePos }}</td>
            </ng-container>

            <ng-container matColumnDef="Consignee">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Consignee
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Consignee }}</td>
            </ng-container>

            <ng-container matColumnDef="ScheduledShipDate">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Scheduled Ship Date
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.ScheduledShipDate }}</td>
            </ng-container>

            <ng-container matColumnDef="Cubes">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Cubes
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Cubes }}</td>
            </ng-container>

            <ng-container matColumnDef="Weight">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Weight
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Weight }}</td>
            </ng-container>
            

            <ng-container matColumnDef="Action">
                <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
                    <mat-option>
                        <button mat-icon-button (click)="shipConfirm()">
                            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                          <mat-label>Ship Confirm</mat-label>
                        </button>
                    </mat-option>

                    <mat-option>
                      <button mat-icon-button (click)="billSynopsis()">
                        <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                        <mat-label>Bill Synopsys</mat-label>
                      </button>
                  </mat-option>

                  <mat-option>
                    <button mat-icon-button (click)="eventHistory()">
                      <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                      <mat-label>Event History</mat-label>
                    </button>
                </mat-option>

                <mat-option>
                    <button mat-icon-button (click)="appointmentEntry()">
                      <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                      <mat-label>Appointment Entry</mat-label>
                    </button>
                </mat-option>

                <mat-option>
                    <button mat-icon-button (click)="userEventEntry()">
                      <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                      <mat-label>User Event Entry</mat-label>
                    </button>
                </mat-option>

                <mat-option>
                    <button mat-icon-button (click)="shortShip()">
                      <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                      <mat-label>Short Ship</mat-label>
                    </button>
                </mat-option>
                 
            
            </mat-select>
                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            
        </table>
    </mat-card-content>
</mat-card>
