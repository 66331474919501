//import { ContactsConsigneeComponent } from './contacts-consignee/contacts-consignee.component';
import { AttributeConsigneeComponent } from './attribute-consignee/attribute-consignee.component';
import { UccLabelCtrlFileComponent } from './ucc-label-ctrl-file/ucc-label-ctrl-file.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { ConsigneeCreateComponent } from './consignee-create/consignee-create.component';
import { ConsigneeDeleteComponent } from './consignee-delete/consignee-delete.component';
import { ConsigneeUpdateComponent } from './consignee-update/consignee-update.component';
import { ConsigneeXrefComponent } from './consignee-xref/consignee-xref.component';
import { DocumentsConsigneeComponent } from './documents-consignee/documents-consignee.component';
import { SrchAttrInquiryComponent } from './srch-attr-inquiry/srch-attr-inquiry.component';
import { ContactsConsigneeComponent } from './contacts-consignee/contacts-consignee.component';
import { ConsigeeService } from './consigee.service';
import { TableServiceService } from 'src/app/table-service.service';
import { CustomColumn } from 'src/app/table-service.service';
//import { DocumentsConsigneeComponent } from './documents-consignee/documents-consignee.component';
//import { SrchAttrInquiryComponent } from './srch-attr-inquiry/srch-attr-inquiry.component';

@Component({
  selector: 'app-consignee',
  templateUrl: './consignee.component.html',
  styleUrls: ['./consignee.component.css'],
})
export class ConsigneeComponent implements OnInit {
 // form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Code',
    'Name',
    'Zip Code',
    'City',
    'State',
    'Actions',
  ];
  otherColumns:string[]= [
    'Phone',
    'PreferredCarrier',
  ]
  columnShowHideList:CustomColumn[] = [];
  selectedRow:any;
  dataSource: any = [];

  // duplicateSource: any = [
  //   {
  //     code: 'ABB1660T5A3',
  //     name: 'ABBott Laboratories Ltd',
  //     zipCode: 'T5A 3',
  //     city: 'Edmonton',
  //     state: 'AB',
  //   },
  //   {
  //     code: 'ABB610517362',
  //     name: 'ABBY Sneeringer',
  //     zipCode: '17362',
  //     city: 'Spring Grove',
  //     state: 'PA',
  //   },
  // ];

  constructor(
    private router: Router,
   // public iHeaderSvc: IheaderService,
   public tableSrv:TableServiceService,
   public consigneeService :ConsigeeService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  form = this.fb.group({
    Code: '',
    Name: '',
  });

  user=localStorage.getItem('userName');
  result:any;

  ngOnInit(): void {
    this.getConsignee();
    this.columnShowHideList = this.tableSrv.initializeColumnProperties(this.displayedColumns,this.otherColumns);
    
  }
  getConsignee(){
    this.consigneeService.getAll(this.user).subscribe((data) => {
      // this.result = data;
      // var res = this.result;
      // console.log(data);

      // var parsedData = JSON.parse(res);
      // this.duplicateSource = parsedData.returnedData;
      // console.log(this.duplicateSource);

      // this.dataSource = new MatTableDataSource(this.duplicateSource);
      // this.dataSource.paginator = this.paginator;

      this.result = data;
         // this.TallyHeaders = data;
          //console.log(this.TallyHeaders);
          var res = this.result[0].data[1]?.value;
          console.log(res);
          var parsedData = JSON.parse(res);
          console.log(parsedData);
          console.log('DaTA');
          // this.dataSource = parsedData.returnedData;
          this.dataSource = new MatTableDataSource(parsedData.returnedData);
          console.log(this.dataSource);
          console.log('DaTA');
        
          //console.log(this.dataSource);
          this.dataSource.paginator = this.paginator;
    });
    // this.dataSource = new MatTableDataSource(this.duplicateSource);
    // this.dataSource.paginator = this.paginator;

  }
  
  searchTextDisplay:any

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  getConsineeBySearch(consignee:any){

    this.consigneeService.getConsignee(this.user, consignee.Code, consignee.Name).subscribe((data) => {
     

      this.result = data;
         // this.TallyHeaders = data;
          //console.log(this.TallyHeaders);
          var res = this.result[0].data[1]?.value;
          console.log(res);
          var parsedData = JSON.parse(res);
          console.log(parsedData);
          console.log('DaTA');
          // this.dataSource = parsedData.returnedData;
          this.dataSource = new MatTableDataSource(parsedData.returnedData);
          console.log(this.dataSource);
          console.log('DaTA');
        
          
          this.dataSource.paginator = this.paginator;
    });

  }

  toggleColumn(column:any){
    this.displayedColumns = this.tableSrv.toggleColumn(column,this.displayedColumns)
  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }

  onBack() {
    this.router.navigate(['/main-nav/consignee/read']);
  }

  consigneeUpdateForm(element: any) {
    this.matDialog.open(ConsigneeUpdateComponent, {
      width: '5000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }

  consigneeDeleteForm(element: any) {
    this.matDialog.open(ConsigneeDeleteComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }

  consigneeComments(element: any) {
     this.router.navigateByUrl('/main-nav/consignee/comments', { state: element });
    // this.matDialog.open(ConsineeCommentsComponent, {
    //   width: '1000px',
    //   maxHeight: '90vh',
    //   data: element,
    //   autoFocus: false,
    // });
  }

  consigneeXRefForm(element: any) {
    this.matDialog.open(ConsigneeXrefComponent, {
      width: '1000px',
      maxHeight: '90vh',
      data: element,
      autoFocus: false,
    });
  }

  addConsignee() {
    this.matDialog.open(ConsigneeCreateComponent, {
      width: '1000px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  uccLabelFile() {
    this.matDialog.open(UccLabelCtrlFileComponent, {
      width: '1000px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  attributeConsignee() {
    this.matDialog.open(AttributeConsigneeComponent, {
      width: '1000px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  ContactConsignee() {
    this.matDialog.open(ContactsConsigneeComponent, {
      width: '1000px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  DocumentConsignee() {
    this.matDialog.open(DocumentsConsigneeComponent, {
      width: '1000px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  SrchAttribute() {
    this.matDialog.open(SrchAttrInquiryComponent, {
      width: '1000px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  consigneeItems() {
    this.router.navigate(['/main-nav/consignee-items/read']);
    // this.matDialog.open(ConsigneeCreateComponent, {
    //   "width": '1000px',
    //   "maxHeight": '100vh',
    //   "autoFocus": false
    // });
  }

  consigneeChildItems() {
    this.router.navigate(['/main-nav/consignee-child-items/read']);
    // this.matDialog.open(ConsigneeCreateComponent, {
    //   "width": '1000px',
    //   "maxHeight": '100vh',
    //   "autoFocus": false
    // });
  }
}
