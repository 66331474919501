import { GlobalComponent } from './../../Global-Component';
import { DeleteCarriersComponent } from './delete-carriers/delete-carriers.component';
import { UpdateCarriersComponent } from './update-carriers/update-carriers.component';
import { CreateCarriersComponent } from './create-carriers/create-carriers.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CarriersService } from './carriers.service';
import { TableServiceService } from 'src/app/table-service.service';
import { CustomColumn } from 'src/app/table-service.service';

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.css'],
})
export class CarriersComponent implements OnInit {
  displayedColumns: string[] = [
    'CarrierCode',
    'CarrierName',
    'ZipCode',
    'Suffix',
    'PalletExch',
    'Hazmat',
    'Actions',
  ];
  otherColumns:string[] = [
    'LoadingAllowance',
    'CWT',
    'Min/Max'
  ]
  selectedRow:any;
  columnShowHideList:CustomColumn[] = []
  form = this.fb.group({
    carrierCode: [''], 
    carrierName: [''],
  });

  constructor(
    private activeRouter: ActivatedRoute,
   // public iHeaderSvc: IheaderService,
    public carrierSvc: CarriersService,
    public tableSvc:TableServiceService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}
  
  ngOnInit(): void {
    this.columnShowHideList = this.tableSvc.initializeColumnProperties(this.displayedColumns,this.otherColumns);
    this.posting();

  }

  searchTextDisplay:any

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

 toggleColumn(column:any){
  this.displayedColumns = this.tableSvc.toggleColumn(column,this.displayedColumns);
 }
 
 rowClick(rowId: any) {
  this.selectedRow = rowId;
}
  getCarrierBySearch(form:any){
    form.carrierCode = form.carrierCode.toUpperCase();
    this.carrierSvc.getCarrier(this.user,form.carrierCode,form.carrierName).subscribe((data) => {
      this.result = data;
     // var res = this.result;
      var res = this.result[0].data[1]?.value;
      console.log(data);

      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log(this.duplicateSource);

      this.dataSource = new MatTableDataSource(this.duplicateSource);
      this.dataSource.paginator = this.paginator;
    });
  }


  
  TallyHeaders: any = [];
  id: any;
  postingDate: any;
  result: any;
  dataSource: any = [];
  duplicateSource: any = [];
  @ViewChild('paginator') paginator!: MatPaginator;
  workHeaders: any = [];

  user=localStorage.getItem('userName');

  posting() {
    this.TallyHeaders = this.carrierSvc
      .getAllCarriers(this.user)
      .subscribe((data) => {
        this.result = data;
        this.TallyHeaders = data;
        var res = this.result[0].data[1]?.value;

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log(this.duplicateSource);

        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  carrierCreate() {
    this.matDialog.open(CreateCarriersComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  carrierUpdate(carrier:any) {
    this.matDialog.open(UpdateCarriersComponent, {
      width: '1000px',
      maxHeight: '80vh',

     "data": carrier,
      autoFocus: false,
    });
  }

  carrierDelete(element:any) {
    this.matDialog.open(DeleteCarriersComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data:element,
      // "data": storer,
      autoFocus: false,
    });
  }
}
