<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title style="color: rgb(32, 68, 145)"
                    >Maintain Dock Scheduler Control</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Control: </mat-label> 
                          <input matInput formControlName="control" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label>Building Number: </mat-label> 
                          <input matInput formControlName="buildingNumber" />
                        </mat-form-field>
                  </div> 

                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Account Code: </mat-label> 
                      <input matInput formControlName="accountCode" /> 
                    </mat-form-field>
                    </div>
                 
                    <div class="col">
                        <mat-form-field appearance="outline">  <mat-label>In/Out: </mat-label> 
                          <input matInput formControlName="inOut" /> 
                        </mat-form-field>
                        </div>
                        
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>value: </mat-label> 
                      <input matInput formControlName="value" /> 
                    </mat-form-field>
                    </div>
                    
             
                    
        
                            <div >
                              <button mat-button class="buttonCss" (click)="attributes()">UPDATE</button>
                          </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  

