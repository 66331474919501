import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ConsigeeService } from '../../consignee/consigee.service';
import { OrderCreate2Component } from '../order-create2/order-create2.component';
import { OrderService } from '../order.service';
import { OrdersCreateFilled1Component } from '../orders-create-filled1/orders-create-filled1.component';

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.css']
})
export class OrderCreateComponent implements OnInit {

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    public iOrderService: OrderService, 
    public consigeeService: ConsigeeService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
   private dialogRef: MatDialogRef<OrderCreateComponent>
   ) 
   { }

  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  

  ngOnInit(): void {
    this.form = this.fb.group({
  
    storer:'',
    suffix:'',
    storerRef:'',
    purchaseOrder:'',
    consignee:'',
       
    });
  }

  // dataSource:any;

  // checkConsignee(){
  //   var res = this.form.value; 
  //   this.consigeeService.getConsignee(this.user, res.consignee, res.consigneeName).subscribe((data) => {
     

  //     this.result = data;
  //        // this.TallyHeaders = data;
  //         //console.log(this.TallyHeaders);
  //         var res = this.result[0].data[1]?.value;
  //         console.log(res);
  //         var parsedData = JSON.parse(res);
  //         console.log(parsedData);
  //         console.log('DaTA');
  //          this.dataSource = parsedData.returnedData;
  //         //this.dataSource = new MatTableDataSource(parsedData.returnedData);
  //         console.log(this.dataSource[0]);
  //         console.log('DaTA');
  //        // if(this.dataSource.len)
  //         this.orderCreateFilled1(this.dataSource);
        
  //   });

  // }

  orderCreate1(){
    var res = this.form.value;   
    this.result = this.iOrderService
      .orderServiceCreate1(         
        res.storer,
        res.suffix,
        res.storerRef,
        res.purchaseOrder,
        res.consignee   

        ).subscribe((data: any) => {
        this.result = data;
       var length = this.result[0].data.length;
        if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Order Created Successfully'){
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          //this.createOrder2();
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/orders/read']);
  }

  closeDialog(){
    this.dialogRef.close();
  }

  // orderCreateFilled1(consignee:any) {
  //   var res = this.form.value;
  //   var  cons= consignee;
  //   var data1= [res,cons];
  //   this.matDialog.open(OrdersCreateFilled1Component, {
  //     width: '1000px',
  //     maxHeight: '80vh',

  //     data: data1,
  //     autoFocus: false,
  //   });
  // }

}