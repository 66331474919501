import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { StorerServiceService } from '../storer-service.service';
@Component({
  selector: 'app-storer-create6',
  templateUrl: './storer-create6.component.html',
  styleUrls: ['./storer-create6.component.css']
})
export class StorerCreate6Component implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerCreate6Component>,
   private matDialog: MatDialog
    ) { }

  ngOnInit(): void {

    console.log(this.anyvariable.storerNumber);
       this.form = this.fb.group({
        
        storeNumber: this.anyvariable.storeNumber, // STORE#
        suffix: this.anyvariable.suffix, // STSFX
        storeName: this.anyvariable.storeName, // STNAME         
        winsCommsID:'',//STCMID  
        dunsNumber:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ],  //STDUNS
        customEDICommsId:'',//STEDI1    
        chepPalletUser:'',  //STCHEP
        AsnRequired856:'',//ST856F  
       });
  }


  storerCreate6(){
    var res = this.form.value;
       
    this.result = this.storerSvc
      .storerServiceCreate6( 
       res.storeNumber,
       res.suffix,
       res.storeName,
       res.winsCommsID,
       res.dunsNumber,
       res.customEDICommsId,
       res.chepPalletUser,
       res.AsnRequired856,
       this.user

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       console.log(this.result[0].data.length);
          console.log("*********************");

       console.log(this.result[0].data[this.result[0].data.length-1].value);
       console.log("*********************");

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
          //alert('Record Created SuccessFully');
          
         const msg= this.result[0].data[this.result[0].data.length-1].value;
          this.successMessage(msg)
          this.closeDialog();
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
       // const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
       }
      });
  }


  successMessage(msg:any){
    Swal.fire(msg,'','success');
    //this.router.navigate(['/main-nav/storer/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/storer/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
