<mat-card class="over-Flow">
    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Inbound Detail Comments</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" class="form over-Flow">
     
        <div class="row">
          <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Tally</mat-label>
            <input matInput formControlName="tally" required />
          </mat-form-field>
        
          <mat-form-field appearance="outline" class="full-width w-100">
            <mat-label>Comments :</mat-label>
            <textarea matInput
            formControlName="comments" maxlength="60"> Comments</textarea>
          </mat-form-field>
        </div>
      </div> 
      <button mat-button class="buttons addButton" (click)="updateTallyComment()">Update</button>
     </form>
      
      </mat-card-content>
    </mat-card>