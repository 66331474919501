<mat-card class="over-Flow">
  
    <div class="main-header-title">  
        <mat-card-header>
          <mat-card-title>Change Queue Priorities</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

        <mat-card-content class="tally-serach-form"> 
          <form [formGroup]="form">
            <div class="row">
              <div class="col">
            
                  <mat-form-field  appearance="outline"><mat-label> Item </mat-label> 
                    <input matInput formControlName="item" />
                  </mat-form-field>
            
                  <mat-form-field  appearance="outline"><mat-label> Transaction No </mat-label> 
                      <input matInput formControlName="transNum" />
                  </mat-form-field>
              
                  <mat-form-field  appearance="outline"><mat-label> Resolution </mat-label> 
                      <textarea matInput formControlName="resolutionText" ></textarea>
                  </mat-form-field>
              
                  <mat-form-field appearance="outline">  <mat-label> Value </mat-label> 
          
                    <mat-select formControlName="value"  matNativeControl required>
                    <mat-option value="DP">DP</mat-option>  
                    <mat-option value="HA">HA</mat-option> 
                    <mat-option value="RD">RD</mat-option>  
                    <mat-option value="ZZ">ZZ</mat-option>
                    </mat-select>
                  </mat-form-field>
              
                  
                    
              </div>
            </div>  <button [disabled]="!(form.valid && form.dirty)" mat-button class="buttons addButton" (click)="resolveMoveProblem()">Update</button>
          </form>  
      </mat-card-content>
</mat-card>
    
  