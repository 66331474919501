import { ICarrierService } from './../i-carrier.service';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CarriersService } from '../carriers.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-update-carriers',
  templateUrl: './update-carriers.component.html',
  styleUrls: ['./update-carriers.component.css'],
})
export class UpdateCarriersComponent implements OnInit {
  storer: any;
  form!: FormGroup;
  result: any;
  constructor(
    public icarSvc: CarriersService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdateCarriersComponent>
  ) {}

  user=localStorage.getItem('userName');

  ngOnInit(): void {
    this.form = this.fb.group({
      carrierCode: this.anyvariable.CRNUMB,
      carrierName: this.anyvariable.CRNAME,
      address1: this.anyvariable.CRADD1,
      address2: this.anyvariable.CRADD2,
      suffix: this.anyvariable.CRZSFX,
      pallet: this.anyvariable.CRPALE,
      hazmat: this.anyvariable.CRHAZM,
      zipcode: this.anyvariable.CRZIP,
      billingFrequency: this.anyvariable.CRBILL,
      carrierSCACCode: this.anyvariable.CRSCAC,
      loadingAllowance: this.anyvariable.CRLAPR,
      weight: this.anyvariable.CRLAWG,
      minAmount: this.anyvariable.CRLAMI,
      maxAmount: this.anyvariable.CRLAMA,
    });
  }

  updateCarrier() {
    var res = this.form.value;
    console.log(res);

    this.result = this.icarSvc
      .updateCarrier(
        res.carrierCode,
        res.carrierName,
        res.address1,
        res.address2,
        res.zipcode,
        res.suffix,
        res.carrierSCACCode,
        res.hazmat,
        res.pallet,
        res.billingFrequency,
        res.loadingAllowance,
        res.weight,
        res.minAmount,
        res.maxAmount,
       this.user)
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       // var msg = JSON.parse(this.result).returnedData.message;

       // this.successMessage(msg);
       if(JSON.parse(this.result[0].data[1].value).success){
        //alert('Record Created SuccessFully');
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/carrier/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog() {
    this.dialogRef.close();
  }
  back() {
    this.router.navigate(['carrier/read']);
  }
}
