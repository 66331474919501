import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MoveQueueService } from '../move-queue.service';

@Component({
  selector: 'app-move-queue-resolve',
  templateUrl: './move-queue-resolve.component.html',
  styleUrls: ['./move-queue-resolve.component.css']
})
export class MoveQueueResolveComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  
  constructor(
    public moveQueueSrv: MoveQueueService,
   @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router : Router,
   private dialogRef: MatDialogRef<MoveQueueResolveComponent>
   ) 
   { }

  ngOnInit(): void {
    this.form = this.fb.group({
  
      item : [this.anyvariable.MQITEM],
      transNum : this.anyvariable.MQTRAN,
      value : '',
      resolutionText : ''
       
    });
  }

  resolveMoveProblem(){
    var res = this.form.value;
    
   
    this.moveQueueSrv
      .resolveMoveProblemService( 
        
        this.user,
        res.transNum,
        res.value,
        res.resolutionText
        

        ).subscribe((data: any) => {
        this.result = data;
        
       console.log("*********************",data);
       var length = this.result[0].data.length;

      // console.log(this.result[0].data[length-1].value=='Staging Location Updated Successfully');

       // if(JSON.parse(this.result[0].data[1].value).success){
          if( this.result[0].data[length-1].value=='Moveq Updated Successfully'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/movequeue/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/movequeue/read']);
  }


  closeDialog(){
    // Write your stuff here
   this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
}
