<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Maintain Message Class Table File</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Document</mat-label>
                    <input matInput formControlName="DOCUMENT" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>File Name</mat-label>
                    <input  matInput formControlName="FILE" />
                </mat-form-field>
            </div> 

            <div class="col" >
                <mat-form-field appearance="outline">
                    <mat-label>XPATH</mat-label>
                    <input matInput formControlName="XPATH1"  />
                </mat-form-field>

               
            </div>
        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="add()" class="addButton">           
        <mat-icon mat-icon-button color="primary">create</mat-icon>
        <mat-label>Message Class Table</mat-label>
    </button>
</mat-card-footer>

