<mat-card class="over-Flow">
  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Tally Posting Errors</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
  
      <mat-card-content class="tally-serach-form"> 
  
         
        <form class="form over-Flow">
              <div class="row">
                  <div class="col">
                      
                      <mat-form-field appearance="outline">
                        <mat-label>Tally Number: </mat-label>
                        <input matInput value="{{anyvariable.IHTALY}}" readonly/> 
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Total Quantity: </mat-label>
                        <input matInput value="{{anyvariable.IHTQTY}}" readonly/> 
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Actual Arrival: </mat-label>
                        <input matInput value="{{anyvariable.IHACTA}}" readonly/> 
                      </mat-form-field>
                  
                  </div>
              </div>
             
          </form>     
          
  </mat-card-content>
   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
          <ng-container matColumnDef="tally">
              <th mat-header-cell *matHeaderCellDef> Tally </th>
              <td mat-cell *matCellDef="let element"> {{ element.IETALY }} </td>
          </ng-container>
  
          <ng-container matColumnDef="sequence">
              <th mat-header-cell *matHeaderCellDef> Sequence </th>
              <td mat-cell *matCellDef="let element"> {{ element.IESEQ }} </td>
          </ng-container>
  
          <ng-container matColumnDef="severity">
              <th mat-header-cell *matHeaderCellDef> Severity </th>
              <td mat-cell *matCellDef="let element"> {{ element.severity }} </td>
          </ng-container>
  
          <ng-container matColumnDef="message">
              <th mat-header-cell *matHeaderCellDef> Message </th>
              <td mat-cell *matCellDef="let element"> {{ element.IEMSGD }} </td>
          </ng-container>
  
          <ng-container matColumnDef="item">
              <th mat-header-cell *matHeaderCellDef> Item </th>
              <td mat-cell *matCellDef="let element"> {{ element.item }} </td>
          </ng-container>
  
          <ng-container matColumnDef="code">
              <th mat-header-cell *matHeaderCellDef> Code </th>
              <td mat-cell *matCellDef="let element"> {{ element.code }} </td>
          </ng-container>
  
          <ng-container matColumnDef="intransit">
              <th mat-header-cell *matHeaderCellDef> Intransit </th>
              <td mat-cell *matCellDef="let element"> {{ element.intransit }} </td>
          </ng-container>
           
          
            <ng-container matColumnDef="receive">
              <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                  Recieved
              </th>
              <td mat-cell *matCellDef="let element">{{ element.receive }}</td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <mat-paginator
      #paginator
      [pageSizeOptions]="[8, 16, 24]"
      showFirstLastButtons class="tally-table-pagination"
    ></mat-paginator>
    
  </mat-card>
  