<!-- Form No: 250 storer product codes -->
<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Work With Product Codes</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Storer</mat-label>
                   <input matInput formControlName="Storer"/>
               </mat-form-field>         
           </div>
        </div>     
      </form>
  </mat-card-content>

   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
       <ng-container matColumnDef="Storer">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Storer
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Storer}}</td>
         </ng-container>
         <ng-container matColumnDef="Product_Code">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Product_Code
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Product_Code}}</td>
         </ng-container>
         <ng-container matColumnDef="Description">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Description
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Description}}</td>
         </ng-container>
         <ng-container matColumnDef="Sts">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Sts
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Sts}}</td>
         </ng-container>
         <ng-container matColumnDef="Opt">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Opt
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Opt}}</td>
         </ng-container>
         
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
</mat-card>