import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IItemBalanceService {
  url = environment.API_BASE_URL;
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  getItemBalance(user: any, storer: any, item: any): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/item/itemBalance/read?
      user=${user}
      &storer=${storer}
      &item=${item}`
    );
  }

  getItemBalanceBySearch(
    user: any,
    storer: any,
    suffix: any,
    item: any,
    lot1: any,
    lot2: any,
    lot3: any,
    date: any
  ): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/item/itemBalance/readBySearch?
      user=${user}&storer=${storer}&suffix=${suffix}
      &item=${item}&lot1=${lot1}
      &lot2=${lot2}&lot3=${lot3}&date=${date}`
    );
  }

  createItemBalance(
    storeNumber: any,
    suffix: any,
    itemCode: any,
    lotCode1: any,
    lotCode2: any,
    lotCode3: any,
    buildingNumber: any,
    recordDateWritten: any,
    recordType: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/item/itemBalance/create', {
        storeNumber,
        suffix,
        itemCode,
        lotCode1,
        lotCode2,
        lotCode3,
        buildingNumber,
        recordDateWritten,
        recordType,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteItemBalance(
    storeNumber: any,
    suffix: any,
    itemCode: any,
    lotCode1: any,
    lotCode2: any,
    lotCode3: any,
    buildingNumber: any,
    recordDateWritten: any
  ): Observable<any> {
    return this.httpClient.delete(
      this.url +
        `/item/itemBalance/delete?storeNumber=${storeNumber}&suffix=${suffix}&itemCode=${itemCode}&lotCode1=${lotCode1}&lotCode2=${lotCode2}&lotCode3=${lotCode3}&buildingNumber=${buildingNumber}&recordDateWritten=${recordDateWritten}`
    );
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
