import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { MoveChangeQueuePriorityComponent } from './move-change-queue-priority/move-change-queue-priority.component';
import { MoveChangeStagingLocationComponent } from './move-change-staging-location/move-change-staging-location.component';
import { MoveQueueChangeComponent } from './move-queue-change/move-queue-change.component';
import { MoveQueueCompleteComponent } from './move-queue-complete/move-queue-complete.component';
import { MoveQueueEntryAssignComponent } from './move-queue-entry-assign/move-queue-entry-assign.component';
import { MoveQueueEntryInquiryComponent } from './move-queue-entry-inquiry/move-queue-entry-inquiry.component';
import { MoveQueueNoPickComponent } from './move-queue-no-pick/move-queue-no-pick.component';
import { MoveQueueService } from './move-queue.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MoveQueueResolveComponent } from './move-queue-resolve/move-queue-resolve.component';
import { RedirectForm1Component } from './redirect-form1/redirect-form1.component';
import Swal from 'sweetalert2';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';
import { TableServiceService } from 'src/app/table-service.service';
import { CustomColumn } from 'src/app/table-service.service';

@Component({
  selector: 'app-move-queue',
  templateUrl: './move-queue.component.html',
  styleUrls: ['./move-queue.component.css'],
})
export class MoveQueueComponent implements OnInit {
  selectedRow: any;
  editedRows: any;

  displayedColumns: string[] = [
    'Priority',
    'Item/Desctiption',
    'Quantity',
    'I',
    'From',
    'To',

    'Actions',
  ];

  otherColumns: string[] = [
    'sourceType',
    'lots',
    'enteredQueue',
    'transNumber',
    'assigned',
    'operator',
    'status',
  ];
  searchText: any;

  updateForm = this.fb.group({
    storer: ['', [Validators.min(0), Validators.pattern('[0-9]{1,10}')]],
    suffix: '',
    building: '',
    priority: '',
    document: '',
    from: '',
    operator: '',
    type: '',
    item: '',
    to: '',
    sts: '',
  });

  result: any = [];
  dataSource: any;
  duplicateSource = [];
  singleTallyData = [];
  documents = [];
  user = localStorage.getItem('userName');
  storer = localStorage.getItem('storer');
  building = 13;
  tally: any;
  searchValue: string = '';
  condition: boolean = true;
  prevText: string = '';
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  constructor(
    private router: Router,
    public moveQueueSrv: MoveQueueService,
    public tableSvc: TableServiceService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private CustomAlertService: CustomAlertService
  ) {}
  TallyHeaders: any = [];
  id: number = 0;

  public columnShowHideList: CustomColumn[] = [];

  position: any;
  name: any;
  isActive: any;

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }

  ngOnInit(): void {
    this.editedRows = [];
    this.columnShowHideList = this.tableSvc.initializeColumnProperties(
      this.displayedColumns,
      this.otherColumns
    );

    this.TallyHeaders = this.moveQueueSrv
      .readAllMoveService(this.user, this.storer)
      .subscribe((data) => {
        this.result = data;
        this.TallyHeaders = data;
        console.log(data);
        var res = this.result[0].data[1]?.value;
        console.log(res);

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        //  this.duplicateSource = this.dataSource;
        console.log(this.duplicateSource);

        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  toggleColumn(column: any) {
    this.displayedColumns = this.tableSvc.toggleColumn(
      column,
      this.displayedColumns
    );
  }

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }
  searchTextDisplay: any;

  readOneMove(res: any) {
    this.moveQueueSrv
      .readOneMoveService(
        this.user,
        res.priority,
        res.item,
        res.type,
        res.document,
        res.building,
        res.storer,
        res.suffix,
        res.operator,
        res.sts
      )
      .subscribe((data) => {
        this.result = data;
        console.log(this.result);
        var res = this.result[0].data[1]?.value;
        console.log(res);

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  moveQueueDelete(element: any) {
    const dialogRef = this.CustomAlertService.openCustomAlert(
      'Delete this Move Queue ' + element.MQTRAN + '?'
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.result = this.moveQueueSrv
          .deleteMoveService(element.MQTRAN)
          .subscribe((data: any) => {
            this.result = data;
            console.log(this.result);
            var length = this.result[0].data.length;
            if (this.result[0].data[length - 1].value == '') {
              const msg = this.result[0].data[length - 1].value;
              this.successMessage(msg);
            } else {
              const msg = this.result[0].data[length - 1].value;
              this.failureMessage(msg);
            }
          });
      }
    });
  }
  deAssignment(element: any) {
    this.result = this.moveQueueSrv
      .deAssignmentService(element.MQTRAN)
      .subscribe((data: any) => {
        this.result = data;
        var length = this.result[0].data.length;
        if (this.result[0].data[length - 1].value == '') {
          const msg = 'Move De-Assigned';
          this.successMessage(msg);
        } else {
          const msg = this.result[0].data[length - 1].value;
          this.failureMessage(msg);
        }
      });
  }

  moveQueueChange(tally: any) {
    this.matDialog.open(MoveQueueChangeComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: tally,
      autoFocus: false,
    });
  }

  redirectForm1(element: any) {
    this.matDialog.open(RedirectForm1Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: element,
      autoFocus: false,
    });
  }

  moveQueueComplete(move: any) {
    this.matDialog.open(MoveQueueCompleteComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: move,
      autoFocus: false,
    });
  }

  moveQueueEntryAssign(move: any) {
    this.matDialog.open(MoveQueueEntryAssignComponent, {
      width: '1500px',
      maxHeight: '80vh',

      data: move,
      autoFocus: false,
    });
  }

  moveQueueEntryInquiry(move: any) {
    this.matDialog.open(MoveQueueEntryInquiryComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: move,
      autoFocus: false,
    });
  }

  moveChangeStagingLocation(move: any) {
    this.matDialog.open(MoveChangeStagingLocationComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: move,
      autoFocus: false,
    });
  }

  moveQueueNoPick(move: any) {
    // this.router.navigate(['storer/contacts', storer]);
    this.matDialog.open(MoveQueueNoPickComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: move,
      autoFocus: false,
    });
  }

  changeMovePriority(move: any) {
    this.matDialog.open(MoveChangeQueuePriorityComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: move,
      autoFocus: false,
    });
  }

  resolveMoveProblem(move: any) {
    this.matDialog.open(MoveQueueResolveComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: move,
      autoFocus: false,
    });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/movequeue/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }
}
