<mat-card>
    <mat-card-header>
        <mat-card-title>Work With Hazard Materials Group</mat-card-title>
    </mat-card-header>

    <mat-card-content>

        <button mat-button (click)="addWwHazardMaterialsGroup()" class="addButton">
            <mat-icon>add</mat-icon>
            <span>Hazard Materials Group</span>
        </button>

        <div class="formDiv">




            <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline">
                            <mat-label>IdNo</mat-label>
                            <input matInput formControlName="IdNo" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>ProperName</mat-label>
                            <input matInput formControlName="ProperName" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Class</mat-label>
                            <input matInput formControlName="Class" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>HazardMatGroup</mat-label>
                            <input matInput formControlName="HazardMatGroup" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>PackingGroup</mat-label>
                            <input matInput formControlName="PackingGroup" />
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

        
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="HazardMatGroup">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>HazardMatGroup</th>
                <td mat-cell *matCellDef="let element">{{ element.HazardMatGroup }}</td>
            </ng-container>

            <ng-container matColumnDef="ProperName">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    ProperName
                </th>
                <td mat-cell *matCellDef="let element">{{ element.ProperName }}</td>
            </ng-container>

            <ng-container matColumnDef="Class">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Class
                </th>
                <td mat-cell *matCellDef="let element">{{ element.Class }}</td>
            </ng-container>
            <ng-container matColumnDef="IdNo">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>IdNo</th>
                <td mat-cell *matCellDef="let element">{{ element.IdNo }}</td>
            </ng-container>

            <ng-container matColumnDef="PackingGroup">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    PackingGroup
                </th>
                <td mat-cell *matCellDef="let element">{{ element.PackingGroup }}</td>
            </ng-container>

            <ng-container matColumnDef="SpecialRequire">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    SpecialRequire
                </th>
                <td mat-cell *matCellDef="let element">{{ element.SpecialRequire }}</td>
            </ng-container>



            <ng-container matColumnDef="Actions">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Actions
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-select placeholder="Select Option">
                        <mat-option>
                            <button mat-icon-button (click)=" changeWwHazardMaterialsGroup()">
                                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                                <mat-label>Change</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                            <button mat-icon-button (click)=" deleteWwHazardMaterialsGroup()">
                                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                                <mat-label>Delete</mat-label>
                            </button>
                        </mat-option>


                    </mat-select>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>
        
    </mat-card-content>
</mat-card>