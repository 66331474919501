<mat-card>
    <mat-card-header>
        <mat-card-title>Work With Sort Report</mat-card-title>
    </mat-card-header>

    <mat-card-content>

        <button mat-button (click)="addExtractReportSort()" class="addButton">
            <mat-icon>add</mat-icon>
            <span>ExtractReportSort</span>
        </button>

        <div class="formDiv">




            <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline">
                            <mat-label>ExtractName</mat-label>
                            <input matInput formControlName="ExtractName" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>ExtractFile</mat-label>
                            <input matInput formControlName="ExtractFile" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>OrderBy</mat-label>
                            <input matInput formControlName="OrderBy" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>GroupBy</mat-label>
                            <input matInput formControlName="GroupBy" />
                        </mat-form-field>
                    </div>

                </div>
            </form>
        </div>

        
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="ExtractName">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>ExtractName</th>
                <td mat-cell *matCellDef="let element">{{ element.ExtractName }}</td>
            </ng-container>

            <ng-container matColumnDef="ExtractFile">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    ExtractFile
                </th>
                <td mat-cell *matCellDef="let element">{{ element.ExtractFile }}</td>
            </ng-container>
            <ng-container matColumnDef="OrderBy">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    OrderBy
                </th>
                <td mat-cell *matCellDef="let element">{{ element.OrderBy }}</td>
            </ng-container>

            <ng-container matColumnDef="GroupBy">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    GroupBy
                </th>
                <td mat-cell *matCellDef="let element">{{ element.GroupBy }}</td>
            </ng-container>



            <ng-container matColumnDef="Actions">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Actions
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-select placeholder="Select Option">
                        <mat-option>
                            <button mat-icon-button (click)=" UpdateExtractReportSort()">
                                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                                <mat-label>Update</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                            <button mat-icon-button (click)=" deleteExtractReportSort()">
                                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                                <mat-label>Delete</mat-label>
                            </button>
                        </mat-option>


                    </mat-select>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>
        
    </mat-card-content>
</mat-card>