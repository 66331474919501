import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { MessageclassshredAddComponent } from './messageclassshred-add/messageclassshred-add.component';
import { MessageclassshredChangeComponent } from './messageclassshred-change/messageclassshred-change.component';
import { MessageclassshredCopyComponent } from './messageclassshred-copy/messageclassshred-copy.component';
import { MessageclassshredDeleteComponent } from './messageclassshred-delete/messageclassshred-delete.component';
import { MessageclassshredDetailsComponent } from './messageclassshred-details/messageclassshred-details.component';

@Component({
  selector: 'app-message-class-shred',
  templateUrl: './message-class-shred.component.html',
  styleUrls: ['./message-class-shred.component.css']
})
export class MessageClassShredComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'Document',
    'Type',
    'DefaultSchema',
    'Actions'
   
  ];
  dataSource:any=[]
  duplicateSource:any =[
    {
      DOCUMENT:'AXWAY 943',
      PARSETYPE:'XML',
      DFTSCHEMA:'QTEMP',
        
    },
   
  ]
  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }
  ngOnInit(): void {

    this.form= this.fb.group({
      DOCUMENT:'AXWAY 943', 
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/message-class-shred/read']);
 
}
MessageClassShredAddForm(){
  this.matDialog.open(MessageclassshredAddComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
    //  'data': element,
     "autoFocus": false                           
    });
    }
    MessageClassShredUpdateForm(element:any){
      this.matDialog.open(MessageclassshredChangeComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        }
        MessageClassShredDeleteForm(element:any){
          this.matDialog.open(MessageclassshredDeleteComponent, {
              "width": '1000px',
               "maxHeight": '90vh',
             'data': element,
             "autoFocus": false                           
            });
            }
            MessageClassShredCopyForm(element:any){
              this.matDialog.open(MessageclassshredCopyComponent, {
                  "width": '1000px',
                   "maxHeight": '90vh',
                 'data': element,
                 "autoFocus": false                           
                });
                }
                MessageClassShredDetailsForm(element: any) {
                  this.router.navigate(['/main-nav/message-class-shred/details']);
                  // this.matDialog.open(OrderCreateComponent, {
                  //   "width": '1000px',
                  //   "maxHeight": '130vh',
                  //   "autoFocus": false
                  // });
                }
              

            addAttribute(){

            }


  

}
