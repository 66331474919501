<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Item Count Schedules</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
  
  
      
      <div class="buttons">
        <button mat-button (click)="add()"  class="addButton">
          <mat-icon>add</mat-icon>
          <span>New Item Count Schedule</span>
        </button>
      </div>
     
     <div class="formDiv">
      <form [formGroup]="form">
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Storer:</mat-label>
            <input matInput formControlName="STOR"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Item:</mat-label>
            <input matInput formControlName="ITEM"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Post Date:</mat-label>
            <input type="date" matInput formControlName="DATE"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Status:</mat-label>
            <input matInput formControlName="STAT1"/>
          </mat-form-field>

        </div>     
      </form>
     </div>
     
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="storer">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Storer
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.storer }}</td>
        </ng-container>
  
        <ng-container matColumnDef="itemCode">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Item Code
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.itemCode }}</td>
        </ng-container>
  
        <ng-container matColumnDef="qty">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Qty
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.qty }}</td>
        </ng-container>
  
        <ng-container matColumnDef="locnBuckets">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Locn Buckets
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.locnBuckets }}</td>
        </ng-container>
  
        <ng-container matColumnDef="lots">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Lots
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.lots }}</td>
        </ng-container>

        <ng-container matColumnDef="posted">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Posted
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.posted }}</td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Status
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.status }}</td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          <mat-option>
            <button mat-icon-button  (click)="change(element)">           
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button>
          </mat-option>
  
       
  
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
    </mat-card-content>
  </mat-card>



