<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Exit Points</mat-card-title>
    <div class="buttons">
      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search Exit Points"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>

      <button
        mat-button
        (click)="addExitPoint()"
        class="addButton buttons"
        matTooltip="Add Exit Points"
      >
        <mat-icon>add</mat-icon>
        <!-- <span>New Exit Point</span> -->
      </button>
    </div>
  </mat-card-header>

  <mat-card-content class="tally-serach-form">
    <form
      [formGroup]="form"
      (ngSubmit)="getExitPointBySearch(form.value)"
      *ngIf="searchTextDisplay"
    >
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="Storer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Storer Suffix</mat-label>
            <input matInput formControlName="storerSuffix" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Exit Point</mat-label>
            <input matInput formControlName="ExitPoint" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Choice</mat-label>
            <input matInput formControlName="Choice" />
          </mat-form-field>
        </div>
      </div>
      <button
      class="buttons addButton"
      mat-raised-button
      type="submit"
      placeholder="Search"
    >
      Search
    </button>
    </form>
  </mat-card-content>

  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="Storer">
      <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
        Storer
      </th>
      <td mat-cell *matCellDef="let element">{{ element.SFSTOR }}</td>
    </ng-container>

    <ng-container matColumnDef="ExitPoint">
      <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
        Exit Point
      </th>
      <td mat-cell *matCellDef="let element">{{ element.SFFORM }}</td>
    </ng-container>

    <ng-container matColumnDef="Choice">
      <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
        Choice
      </th>
      <td mat-cell *matCellDef="let element">{{ element.SFPGM }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
        Description
      </th>
      <td mat-cell *matCellDef="let element">{{ element.SFTEXT }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef>
        <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="exitUpdateForm(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="exitDeleteForm(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
