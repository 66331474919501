<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Delete Map Location</mat-card-title>
    </mat-card-header>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Building Number </mat-label>
            <input matInput formControlName="buildingNumber" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Floor </mat-label>
            <input matInput formControlName="floor" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Aisle </mat-label>
            <input matInput formControlName="aisle" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Slot </mat-label>
            <input matInput formControlName="slot" readonly />
          </mat-form-field> <br>
          
          <button mat-button (click)="deleteMapLocation()" class="buttons removeButton">
            Delete
          </button>
          
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
