<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Create Work Schedule </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>Type Of Hours</mat-label>
                  <mat-select  formControlName="typeOfHours">
                   
                    <mat-option value="APPT">APPT</mat-option>
                    <mat-option value="SHF1">SHF1</mat-option>
                    <mat-option value="SHF2">SHF2</mat-option>
                    <mat-option value="SHF3">SHF3</mat-option>
                   
                  </mat-select>
              </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label> Building Number </mat-label>
  
              <input matInput formControlName="buildingNumber" />
            </mat-form-field>
            
            <mat-form-field appearance="outline">
                <mat-label>Day Of The Week</mat-label>
                  <mat-select  formControlName="dayOfTheWeek">
                   
                    <mat-option value="1 SUNDAY">SUNDAY</mat-option>
                    <mat-option value="2 MONDAY" >MONDAY</mat-option>
                    <mat-option value="3 TUESDAY">TUESDAY</mat-option>
                    <mat-option value="4 WEDNESDAY">WEDNESDAY</mat-option>
                    <mat-option value="5 THURSDAY">THURSDAY</mat-option>
                    <mat-option value="6 FRIDAY">FRIDAY</mat-option>
                    <mat-option value="7 SATURDAY">SATURDAY</mat-option>
                   
                   
                  </mat-select>
              </mat-form-field>
              
              
              <mat-form-field appearance="outline">
                <mat-label>In/Out</mat-label>
                  <mat-select  formControlName="inOrOut">
                   
                    <mat-option value="I">INBOUND</mat-option>
                    <mat-option value="O">OUTBOUND</mat-option>
                   
                  </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Start Time</mat-label>
    
                <input type="time" matInput formControlName="startTime" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> End Time </mat-label>
    
                <input type="time" matInput formControlName="endTime" />
              </mat-form-field>

           
              <button
              mat-button
              class="buttons addButton"
              mat-raised-button
              (click)="add()"
            >
              Add
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  


