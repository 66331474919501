import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { MoveQueueService } from '../move-queue.service';

@Component({
  selector: 'app-move-queue-complete',
  templateUrl: './move-queue-complete.component.html',
  styleUrls: ['./move-queue-complete.component.css'],
})
export class MoveQueueCompleteComponent implements OnInit {
  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  constructor(
    public moveQueueSrv: MoveQueueService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<MoveQueueCompleteComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      storer: this.anyvariable.MQSTOR,
      item: this.anyvariable.MQITEM,
      transNum: this.anyvariable.MQTRAN,
      lots: this.anyvariable.MQLOT1 + ' | ' + this.anyvariable.MQLOT2 + ' | ' + this.anyvariable.MQLOT3,
      from: this.anyvariable.MQBLDG + '  ' + this.anyvariable.MQFRAI + '/' + this.anyvariable.MQFRSL,
      to: this.anyvariable.MQBLDG + '  ' + this.anyvariable.MQTOAI + '/' + this.anyvariable.MQTOSL,
      quantity: this.anyvariable.MQQTY,
      type: this.anyvariable.MQTTYP + ' ' + this.anyvariable.MQDOCN,
      priority: this.anyvariable.MQPRTY,
      operator: this.anyvariable.MQUSER,
      status: this.anyvariable.MQSTAT,
    });
  }

  updateMoveComplete() {
    var res = this.form.value;

    this.result = this.moveQueueSrv
      .updateMoveCompleteService(res.transNum)
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result, '*******');
        // console.log(JSON.parse(this.result).returnedData.messaage);

        console.log('*********************');

        var length = this.result[0].data.length;

        // if(JSON.parse(this.result[0].data[1].value).success){
        if (this.result[0].data[length - 1].value == 'MoveQ Entry Complete') {
          //alert('Record Created SuccessFully');
          const msg = this.result[0].data[length - 1].value;
          this.successMessage(msg);
          this.closeDialog();

          // this.router.navigate(['/main-nav/tallys/read']);
        } else {
          const msg = this.result[0].data[length - 1].value;
          this.failureMessage(msg);
        }
      });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/movequeue/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    this.router.navigate(['/main-nav/movequeue/read']);
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  backstorer() {}
}
