import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
import { MoveQueueService } from '../move-queue.service';

@Component({
  selector: 'app-move-queue-entry-inquiry',
  templateUrl: './move-queue-entry-inquiry.component.html',
  styleUrls: ['./move-queue-entry-inquiry.component.css'],
})
export class MoveQueueEntryInquiryComponent implements OnInit {
  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  moveInqSvc: any;
  datasource: any;
  constructor(
    public moveQueueSrv: MoveQueueService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MoveQueueEntryInquiryComponent>
  ) {}

  moveItem: any;
  ngOnInit(): void {
    this.moveItem = '';
    this.moveInqSvc = this.moveQueueSrv
      .readMoveInquiry(this.user, this.anyvariable.MQTRAN)
      .subscribe((data) => {
        this.result = data;

        var res = this.result[0].data[1]?.value;
        console.log(res);

        var parsedData = JSON.parse(res);
        this.datasource = parsedData.returnedData;
        //  this.duplicateSource = this.dataSource;
        console.log('#######################');
        console.log(this.datasource[0].MQITEM);
      });

    this.form = this.fb.group({
      item: this.anyvariable.MQITEM,
      lots:
        this.anyvariable.MQLOT1 +
        ' | ' +
        this.anyvariable.MQLOT2 +
        ' | ' +
        this.anyvariable.MQLOT3,
      from:
        this.anyvariable.MQBLDG +
        '  ' +
        this.anyvariable.MQFRAI +
        '/' +
        this.anyvariable.MQFRSL,
      to:
        this.anyvariable.MQBLDG +
        '  ' +
        this.anyvariable.MQTOAI +
        '/' +
        this.anyvariable.MQTOSL,
      quantity: this.anyvariable.MQQTY,
      source: this.anyvariable.MQSTAT + '  ' + this.anyvariable.MQDOCN,
      onQueue: this.anyvariable.MQQDAT + '  ' + this.anyvariable.MQQTIM,
      assigned: this.anyvariable.MQADAT + '  ' + this.anyvariable.MQATIM,
      completed: this.anyvariable.MQCDAT + '  ' + this.anyvariable.MQCTIM,
      by: this.anyvariable.MQUSER,
      priority: this.anyvariable.MQPRTY,
      status: this.anyvariable.MQSTAT,

      MoveProblem: '',
      Description: '',
      Reported: '',
      By: '',
      Resolution: '',
      Resolved: '',
      By1: '',
    });
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  backstorer() {}
  Inquiry() {}
}
