<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form class="over-Flow" [formGroup]="consolidateCustomerForm">
  <mat-card class="over-Flow">
    <mat-card-header>
      <mat-card-title> Consolidate a Customer</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Customer Number </mat-label>
            <input matInput required formControlName="CUST" maxlength="8" />
            <mat-error>
              Customer No From is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Customer Name </mat-label>
            <input
              matInput
              formControlName="CUNAME"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Consolidate References to Customer </mat-label>
            <input
              matInput
              formControlName="XCUST"
              style="text-transform: uppercase"
            />
          </mat-form-field>
        </div>
      </div>
      <button class="buttons addButton" mat-raised-button>Consolidate</button>
    </mat-card-content>
  </mat-card>
</form>
