import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-carriers-fak-trans',
  templateUrl: './carriers-fak-trans.component.html',
  styleUrls: ['./carriers-fak-trans.component.css']
})
export class CarriersFakTransComponent implements OnInit {

  form!: FormGroup;



  displayedColumns: string[] = [
    'SCACCode',
    'NMFCClass',
    'RateNMFCClass',
    'AsDiscountPercentage',
    'Action',
    
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      SCACCode:'',
      NMFCClass:'',
      RateNMFCClass:'',     
      AsDiscountPercentage:'',

    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<CarriersFakTransComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
    SCACCode:'SJPS'
     });

     this.dataSource = this.duplicateSource;
  }



  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }



}
