import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConsigeeService } from '../../consigee.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';

@Component({
  selector: 'app-consignee-comments-delete',
  templateUrl: './consignee-comments-delete.component.html',
  styleUrls: ['./consignee-comments-delete.component.css']
})
export class ConsigneeCommentsDeleteComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user =localStorage.getItem('userName');

  constructor(
    private ConsigneeService : ConsigeeService,
   // private toast : ToastrService,
   private router : Router,
   private CustomAlertService: CustomAlertService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ConsigneeCommentsDeleteComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      fileId: this.anyvariable.CMRECD,
      key: this.anyvariable.CMKEY, 
      subCode: this.anyvariable.CMSUBD,
      sequence: this.anyvariable.CMSEQ,
      text: this.anyvariable.CMCOMM,
      lastChangeDate: this.anyvariable.CMDATE,
      lastChangeTime: this.anyvariable.CMTIME,
      changedBy: this.anyvariable.CMUSER
     });
  }

  deleteConsigneeComment(){

    var res = this.form.value;
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete comment' + '?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result){
      this.result = this.ConsigneeService
        .deleteconsigneeCommentService(this.user, res.key, res.subCode, res.sequence)
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);

          if (JSON.parse(this.result[0].data[1].value).success) {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
            this.closeDialog();
            this.successMessage(msg);
            this.router.navigate(['/main-nav/consignee/read']);
          } else {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.failureMessage(msg);
            
          }
        });
    }
  });
    
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/consignee/read']);
    //this.router.navigate(['/main-nav/storer/comments']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
    this.dialogRef.close(); 
  }
}
