import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IitemService } from 'src/app/features/item/iitem.service';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import Swal from 'sweetalert2';
import { OrderDetailsService } from '../order-details.service';

@Component({
  selector: 'app-order-detail-change-event',
  templateUrl: './order-detail-change-event.component.html',
  styleUrls: ['./order-detail-change-event.component.css'],
})
export class OrderDetailChangeEventComponent implements OnInit {
  constructor(
    public orderDetailSrv: OrderDetailsService,
    public itemService: IitemService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<OrderDetailChangeEventComponent>
  ) {}

  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  duplicateSource: any;
  itemOptions: string[] = [];

  ngOnInit(): void {
    this.form = this.fb.group({
      bol: this.anyvariable.ODBILL,
      seq: this.anyvariable.ODSEQ,
      quantity: this.anyvariable.ODQTYS,
      items: this.anyvariable.ODITEM,
      lot1: this.anyvariable.ODLOT1,
      lot2: this.anyvariable.ODLOT2,
      lot3: this.anyvariable.ODLOT3,
      shipFrom: this.anyvariable.ODSHFR,
      ovrWeight: this.anyvariable.ODGRSW,
      changeReason: this.anyvariable.ODREAS,
      originalLine: '',
    });

    this.itemService
      .getItemBySearch(this.user, this.anyvariable.ODSTOR, '', '')
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('ITEM DATA');
        console.log(this.duplicateSource);

        for (const item of parsedData.returnedData) {
          this.itemOptions.push(item.ITEM);
        }
        console.log('user', this.user);
        console.log(this.anyvariable.ODSTOR);

        console.log('itemoptions', this.itemOptions);
      });
  }

  changeEvent() {
    var res = this.form.value;
    this.result = this.orderDetailSrv
      .changeEventService(
        res.bol,
        res.seq,
        res.quantity,
        res.items,
        res.lot1,
        res.lot2,
        res.lot3,
        res.shipFrom,
        res.ovrWeight,
        res.changeReason,
        res.originalLine
      )
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);

        var length = this.result[0].data.length;
        if (this.result[0].data[length - 1].value == 'Successfully Updated') {
          const msg = this.result[0].data[length - 1].value;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/orders/details']);
        } else {
          const msg = this.result[0].data[length - 1].value;
          this.failureMessage(msg);
        }
      });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  backstorer() {}
  maintain() {}
}
