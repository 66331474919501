<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>Record Storage Box Maintenance</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Box Number  : </mat-label> 
                    <input matInput formControlName="BoxNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Box Size: </mat-label> 
                    <input matInput formControlName="BoxSize"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Description : </mat-label> 
                    <input matInput formControlName="Description"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Second Desc : </mat-label> 
                    <input matInput formControlName="SecondDesc"/>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Primary Ref : </mat-label> 
                    <input matInput formControlName="PrimaryRef"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Second Ref: </mat-label> 
                    <input matInput formControlName="SecondRef" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>File Type : </mat-label> 
                    <input matInput formControlName="FileType"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Age Class </mat-label> 
                    <input matInput formControlName="AgeClass"/>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>Content Range </mat-label> 
                    <input matInput formControlName="ContentRange"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Retention : </mat-label> 
                    <input matInput formControlName="Retention" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Scheduled Destruction </mat-label> 
                    <input matInput formControlName="ScheduledDestruction"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Customer : </mat-label> 
                    <input matInput formControlName="Customer" />
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>Ref Box </mat-label> 
                    <input matInput formControlName="RefBox"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Dept : </mat-label> 
                    <input matInput formControlName="Dept" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>District : </mat-label> 
                    <input matInput formControlName="District"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Division : </mat-label> 
                    <input matInput formControlName="Division" />
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>Buliding : </mat-label> 
                    <input matInput formControlName="Buliding"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Alpha From : </mat-label> 
                    <input matInput formControlName="AlphaFrom" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Alpha To : </mat-label> 
                    <input matInput formControlName="AlphaTo"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Location : </mat-label> 
                    <input matInput formControlName="Location" />
                </mat-form-field>
            </div>
            

            

            <div class="col">
                <button mat-button (click)="changeBox()" class="addButton">
                    
                    <span>change</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>
