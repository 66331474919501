import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { ExtractReportFiltersAddComponent } from './extract-report-filters-add/extract-report-filters-add.component';
import { ExtractReportFiltersCopyComponent } from './extract-report-filters-copy/extract-report-filters-copy.component';
import { ExtractReportFiltersUpdateComponent } from './extract-report-filters-update/extract-report-filters-update.component';

@Component({
  selector: 'app-extract-report-filters',
  templateUrl: './extract-report-filters.component.html',
  styleUrls: ['./extract-report-filters.component.css']
})
export class ExtractReportFiltersComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Filter Name',
    'Program',
    'Description',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      FilterName:'ACTIVEONLY',
      Program:'EXFPLOCAT',
      Description:'Skip Non Active Serials'
    },
    {
      FilterName:'ACTUNIT',
      Program:'EXFPACTIVE',
      Description:'Actively Reporting UOM'
    },
    {
      FilterName:'BLANKS',
      Program:'EXFPLOCAT',
      Description:'Return a Blank Value'
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      FilterName:'',
      ProgramName:''
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  addextractReportFilters(){
    this.matDialog.open(ExtractReportFiltersAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  changeextractReportFilters(element:any){
    this.matDialog.open(ExtractReportFiltersUpdateComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  copyextractReportFilters(element:any)
  {
    this.matDialog.open(ExtractReportFiltersCopyComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

}
