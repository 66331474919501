import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { ExtractReportsAddComponent } from './extract-reports-add/extract-reports-add.component';
import { ExtractReportsEditComponent } from './extract-reports-edit/extract-reports-edit.component';

@Component({
  selector: 'app-work-with-extract-reports',
  templateUrl: './work-with-extract-reports.component.html',
  styleUrls: ['./work-with-extract-reports.component.css']
})
export class WorkWithExtractReportsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  displayedColumns: string[] = [

    
    'reportName',
    'programName',
    'description',
    'actions',
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      reportName:'ACTIVESRL',
      programName:'EXRPLOCAT',
      description:'Active Serial Extract',

    },
    {   
      reportName:'ACTIVITY',
      programName:'EXRPACTIV',
      description:'Item Activity Report',

    },
    {      
      reportName:'ACTIVITYDR',
      programName:'EXRPACTIV',
      description:'Item Activity Report - WITH DATE RANGE',
      
    },
    {      
      reportName:'ACTW5000G',
      programName:'EXRPACTIV',
      description:'Web Based Workfile Creation',
      
    },
    {
      reportName:'ACTW5000GI',
      programName:'EXRPACTIV',
      description:'Web Based Workfile Creation',
      
    }

   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    // private dialogRef: MatDialogRef<AttributesComponent>
    
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      reportName:'',
      
    
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
 
 


  }

  createExtractReport() {
    this.matDialog.open(ExtractReportsAddComponent, {
      width: '850px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }
  
  editExtractReports(element:any){
    this.matDialog.open(ExtractReportsEditComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });
 
  }

  
//   ContactTypesAddForm(element:any){
//     this.matDialog.open(ContactTypesAddComponent, {
//       "width": '1000px',
//       "maxHeight": '90vh',
//       'data': element,
//       "autoFocus": false                           
//     });
 
//   }
 

  


// addAttribute(){

// }

}
