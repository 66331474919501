import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-labor-plan-delete',
  templateUrl: './ww-labor-plan-delete.component.html',
  styleUrls: ['./ww-labor-plan-delete.component.css']
})
export class WwLaborPlanDeleteComponent implements OnInit {


  form!: FormGroup;
  result: any;
  user = 'NKOHAN';

  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwLaborPlanDeleteComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({

      DocumentType: '0',                         
   DocumentNumber: '4790321',              
   MustStartDate: '2019 - 08 - 27 - 15.55.00.000000 ',
   BuildingNumber: '100',                        
     StorerNumber: '100',                       
    StageLocation: 'DOCK/1',                     
  WorkflowProcess: 'PICKORD'

    });
  }

  deleteLaborPlan() {

  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
