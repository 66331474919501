import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ImapZoneService {

  url = environment.API_BASE_URL;
  
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  
  getMapZone(user: any, building: any, zone: any): Observable<any> {
    return this.httpClient.get(
      this.url +`/mapzone/read?user=${user}&building=${building}&zone=${zone}`
    );
  }

  getAll(user: any): Observable<any> {
    return this.httpClient.get(
      this.url +`/mapzone/readAll?user=${user}`
    );
  }

  createMapZone(
    WZBLDG: any, //buildingnumber
    WZZONE: any, //zone
    WZDESC: any, //description
    WZINST: any, //inbound staging
    WZPUT: any, //product pruaway
    WZOUST: any, //outbound staging
    WZPICK: any, //product picking
    WZUSE5: any, //back count
    WZUSE1: any, //custome usage1
    WZUSE2: any, //custome usage1
    WZUSE3: any, //custome usage1
    WZUSE4: any, //custome usage1
    user: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url +'/mapzone/create', {
        WZBLDG, //buildingnumber
        WZZONE, //zone
        WZDESC, //description
        WZINST, //inbound staging
        WZPUT, //product pruaway
        WZOUST, //outbound staging
        WZPICK, //product picking
        WZUSE5, //back count
        WZUSE1, //custome usage1
        WZUSE2, //custome usage2
        WZUSE3, //custome usage3
        WZUSE4, //custome usage4
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateMapZone(
    WZBLDG: any, //buildingnumber
    WZZONE: any, //zone
    WZDESC: any, //description
    WZINST: any, //inbound staging
    WZPUT: any, //product pruaway
    WZOUST: any, //outbound staging
    WZPICK: any, //product picking
    WZUSE5: any, //back count
    WZUSE1: any, //custome usage1
    WZUSE2: any, //custome usage1
    WZUSE3: any, //custome usage1
    WZUSE4: any, //custome usage1
    user: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url +'/mapzone/update', {
        WZBLDG, //buildingnumber
        WZZONE, //zone
        WZDESC, //description
        WZINST, //inbound staging
        WZPUT, //product pruaway
        WZOUST, //outbound staging
        WZPICK, //product picking
        WZUSE5, //back count
        WZUSE1, //custome usage1
        WZUSE2, //custome usage2
        WZUSE3, //custome usage3
        WZUSE4, //custome usage4
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteMapZone(user: any, building: any, zone: any): Observable<any> {
    return this.httpClient.delete(
      this.url +`/mapzone/delete?user=${user}&building=${building}&zone=${zone}`
    );
  }
  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}