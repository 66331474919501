<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
    <form [formGroup]="form">
        <mat-card>
        <mat-card-header>
            
            
            <mat-card-title style="color: rgb(203, 206, 212)"
            >Maintain Inbound Details</mat-card-title>
            
        </mat-card-header>
        <mat-card-content>
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Quantity: </mat-label> 
                    <input matInput formControlName="IDQINT" />
                  </mat-form-field>
    
                  <mat-form-field appearance="outline">  <mat-label> Item:</mat-label> 
                      <input matInput formControlName="IDITEM" />
                    </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Lot Codes:</mat-label> 
                    <input matInput formControlName="IDLOT" />
                  </mat-form-field>           
           </div>
           <div class="col">
                        
            <mat-form-field appearance="outline">  <mat-label> Rate Type:</mat-label> 
                <input matInput formControlName="IDRTTY" />
              </mat-form-field>
         </div>
         <div class="col">
            <mat-form-field appearance="outline">  <mat-label> Dmg Reas:</mat-label> 
                <input matInput formControlName="IDDAMR" />
              </mat-form-field>
              <mat-form-field appearance="outline">  <mat-label> Override Weight:</mat-label> 
                <input matInput formControlName="IDOGRW" />
              </mat-form-field>           
       </div>
       <div >
        <button mat-button class="buttonCss" (click)="attributes()">Update</button>
    </div>
    
    </mat-card-content>
    </mat-card>
    </form> 
