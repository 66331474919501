<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Storer X-ref Maintenance</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Storer </mat-label>
              <input matInput formControlName="storer" />
            </mat-form-field>
        
            <mat-form-field appearance="outline">
              <mat-label>Bill To </mat-label>
              <input matInput formControlName="billTo" />
            </mat-form-field>
          </div>
        </div>
      </form>
    </mat-card-content>
</mat-card>

