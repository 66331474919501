import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../iheader.service';
import { IitemService } from '../../item/iitem.service';

@Component({
  selector: 'app-tally-detail-update',
  templateUrl: './tally-detail-update.component.html',
  styleUrls: ['./tally-detail-update.component.css'],
})
export class TallyDetailUpdateComponent implements OnInit {
  tally: any;
  form!: FormGroup;
  result: any;
  // user = GlobalComponent.user;
  user: any;
  building: any;
  storer: any;
  itemOptions: string[] = [];
  duplicateSource:any;


  constructor(
    public iHeaderSvc: IheaderService,
    public itemService:IitemService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<TallyDetailUpdateComponent>
  ) {}

  ngOnInit(): void {
    if (localStorage.getItem('LoggedIn')) {
      this.user = localStorage.getItem('userName');
      this.building = localStorage.getItem('building');
      this.storer = localStorage.getItem('storer');
    } else {
      this.user = GlobalComponent.user;
      this.building = GlobalComponent.building;
    }
    this.tally = this.data.IDTALY;
    this.form = this.fb.group({
      quantity: this.anyvariable.IDQINT,
      // [
      //   this.anyvariable.IDQINT,
      //   [
      //     Validators.required,
      //     Validators.min(0),
      //     Validators.pattern('[0-9]{1,10}'),
      //   ],
      // ],
      items: this.anyvariable.IDITEM,
      sequence:this.anyvariable.IDSEQ,
      lotCode1: this.anyvariable.IDLOT,
      lotCode2: this.anyvariable.IDLTC1,
      lotCode3: this.anyvariable.IDLTC2,
      rateType: this.anyvariable.IDRTTY,
      dmgAreas: this.anyvariable.IDDAMR,
      overrideWeight: this.anyvariable.IDOGRW,
      // [
      //   this.anyvariable.IDOGRW,
      //   [
      //     Validators.required,
      //     Validators.min(0),
      //     Validators.pattern('[0-9]{1,10}'),
      //   ],
      // ],
    });


    this.itemService
      .getItemBySearch(this.user, this.anyvariable.IDSTOR, '', '')
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('ITEM DATA');
        console.log(this.duplicateSource);

        for (const item of parsedData.returnedData) {
          this.itemOptions.push(item.ITEM);
        }
      
      });


  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  updateTallyDetail() {
    var res = this.form.value;

    this.result = this.iHeaderSvc
      .updateTallyDetail(
        this.user,
        this.tally,
        res.sequence,
        this.storer,
        this.building,
        res.quantity,
        res.items,
        res.lotCode1,
        res.lotCode2,
        res.lotCode3,
        res.rateType,
        res.dmgAreas,
        res.overrideWeight
      )
      .subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log(this.result, '*******');
        // console.log(JSON.parse(this.result).returnedData.messaage);

        if (JSON.parse(this.result[0].data[1].value).success) {
          //alert('Record Created SuccessFully');
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/tallys/details']);
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.failureMessage(msg);
        }
      });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/tallys/details']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    // this.router.navigate(['/main-nav/tallys/read']);
  }
}
