<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Storage Computation Inquiry</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer </mat-label>
            <input matInput formControlName="Storer" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Item</mat-label>
            <input matInput formControlName="Item" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Lot </mat-label>
            <input matInput formControlName="Lot" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Lot Min</mat-label>
            <input matInput formControlName="LotMin" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Item Minimum</mat-label>
            <input matInput formControlName="ItemMinimum" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>On Hand Quantity </mat-label>
            <input matInput formControlName="OnHandQuantity" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Weight </mat-label>
            <input matInput formControlName="Weight" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Quantity/Pallet </mat-label>
            <input matInput formControlName="QuantityPallet" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Cubes </mat-label>
            <input matInput formControlName="Cubes" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Basis </mat-label>
            <input matInput formControlName="Basis" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Basis Quantity </mat-label>
            <input matInput formControlName="BasisQuantity" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>XRate </mat-label>
            <input matInput formControlName="XRate" readonly/>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
