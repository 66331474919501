import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IitemService } from 'src/app/features/item/iitem.service';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import Swal from 'sweetalert2';
import { OrderDetailsService } from '../order-details.service';


@Component({
  selector: 'app-order-detail-delete',
  templateUrl: './order-detail-delete.component.html',
  styleUrls: ['./order-detail-delete.component.css']
})

export class OrderDetailDeleteComponent implements OnInit {

  constructor(
    public orderDetailSrv: OrderDetailsService, 
    public itemService:IitemService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router : Router,
    private dialogRef: MatDialogRef<OrderDetailDeleteComponent>
  ) { }

   storer: any;
   form!: FormGroup;
   result: any;
   user = localStorage.getItem('userName');
   duplicateSource:any;
   itemOptions: string[] = [];

  ngOnInit(): void {

    console.log("data",this.anyvariable);
    

    this.form = this.fb.group({  
      bol:this.anyvariable.ODBILL,
      seq:this.anyvariable.ODSEQ,
      quantity: this.anyvariable.ODQTYS,
      items: this.anyvariable.ODITDS,
      lots:this.anyvariable.ODLOT1,
      shipFrom:this.anyvariable.ODSHFR,     
    });
  
    this.itemService.getItemBySearch(
      this.user, 
      this.anyvariable.OHSTOR,
      '',
      ''
    ).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('GET ALL DATA');
      console.log(this.duplicateSource);
      for (const item of parsedData.returnedData) {
        this.itemOptions.push(item.ITEM);
      }  
    });
  }

  deleteOrderDetail() {
    var res = this.form.value;    
    this.result = this.orderDetailSrv.deleteOrderDetailService(
      res.bol,
      res.seq
    ).subscribe((data: any) => {
      this.result = data;
      console.log(this.result);
      
      var length = this.result[0].data.length;
      if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Successfully Deleted'){
        const msg= this.result[0].data[length-1].value;
        this.successMessage(msg)
        this.closeDialog();
        this.router.navigate(['/main-nav/orders/details']);
      }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
      }
    });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
   this.dialogRef.close();
  }

  backstorer(){
    
  }
  maintain(){
    
  }

}
