import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-item-count-schedule-change',
  templateUrl: './item-count-schedule-change.component.html',
  styleUrls: ['./item-count-schedule-change.component.css']
})
export class ItemCountScheduleChangeComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemCountScheduleChangeComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      INSTOR:this.anyvariable.storer,
      INITEM:this.anyvariable.itemCode,
      INSTAT:this.anyvariable.status
    })
  }

  change(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
