<!-- form : 244 -->
<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title fxLayoutAlign="center">Maintain Putaway Schemes</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form" >
            <table>
                <tbody>
                <tr>
                    <th>1</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme1" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>2</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme2" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>3</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme3" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>4</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme4" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>5</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme5" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>6</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme6" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>7</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme7" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>8</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme8" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>9</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme9" />
                        </mat-form-field> 
                    </td>
                </tr>

                <tr>
                    <th>10</th>
                    <td>
                        <mat-form-field appearance="outline">  
                            <input matInput formControlName="Scheme10" />
                        </mat-form-field> 
                    </td>
                </tr>
                </tbody>
            </table>
        </form>
    </mat-card-content>
</mat-card>