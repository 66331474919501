import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ww-validation-events-errordetails',
  templateUrl: './ww-validation-events-errordetails.component.html',
  styleUrls: ['./ww-validation-events-errordetails.component.css']
})
export class WwValidationEventsErrordetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
