import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { StorerServiceService } from '../../storer/storer-service.service';
import { IheaderService } from '../iheader.service';
import { MaintainComponent } from '../maintain/maintain.component';
import { TallyDetailCommentsComponent } from '../tally-detail-comments/tally-detail-comments.component';
import { TallyDetailDeleteComponent } from '../tally-detail-delete/tally-detail-delete.component';
import { TallyDetailPutawayLocationComponent } from '../tally-detail-putaway-location/tally-detail-putaway-location.component';
import { TallyDetailUpdateComponent } from '../tally-detail-update/tally-detail-update.component';
import { TallyPutawayUpdateComponent } from '../tally-putaway-update/tally-putaway-update.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { StockStatusComponent } from './stock-status/stock-status.component';


@Component({
  selector: 'app-tally-detail-work',
  templateUrl: './tally-detail-work.component.html',
  styleUrls: ['./tally-detail-work.component.css'],
})

export class TallyDetailWorkComponent implements OnInit {

  constructor(
    private activeRouter: ActivatedRoute,
    public iHeaderSvc: IheaderService,
    public storerSrv :StorerServiceService,
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog // @Inject(MAT_DIALOG_DATA) public anyvariable:any, //  private dialogRef:MatDialogRef<TallyDetailWorkComponent>
  ) {

    this.form = this.fb.group({
      tally:fb.control(''),
      storer:fb.control(''),
      storerName:fb.control(''),
      
      });
  }

  displayedColumns: string[] = [
    'ST',
    'Sequence',
    'Item',
    'LotCodes',
    'InTransit',
    'QtyRecvd',
    'PutAway',
    'Actions',
  ];
  // dataSource = ELEMENT_DATA;
  form! : FormGroup;
  id: any;
  postingDate: any;
  result: any;
  //user= GlobalComponent.user;
  dataSource: any;
  sequence = 100;
  user = localStorage.getItem('userName');
  element: any;
  TallyHeaders: any = [];
  duplicateSource: any = [];
  tally:any;
  storer:any;
  storerResponse:any;
  storerName:any;
  storerSource: any = [];
 
  //id:number=0;
  ngOnInit(): void {
    this.element = history.state;
    if(localStorage.getItem("tally") === null){
      localStorage.setItem('tally',this.element.IHTALY);
      localStorage.setItem("storer1",this.element.IHSTOR);
      this.tally = this.element.IHTALY;
      this.storer = this.element.IHSTOR;
    }else{
      this.tally = localStorage.getItem('tally');
      this.storer = localStorage.getItem('storer1');
    }    

    this.posting();
  }

  posting() {
    // this.id=this.activeRouter.snapshot.params['id'];
    this.element = history.state;
    console.log(this.element);
    
    this.iHeaderSvc
      //.getTallyDetails(this.user, this.element.IHTALY, this.element.IHACDL, this.element.IHSEQ)
      .getTallyDetails2(this.user, this.element.IHTALY)
      .subscribe((data) => {
        this.result = data;
        var res = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('TALLY DTL DATA');
        console.log(this.duplicateSource);

        this.dataSource = new MatTableDataSource(this.duplicateSource);
        //  this.duplicateSource=this.dataSource;
      });
      console.log(this.element.IHSTOR );
      
      this.storerSrv
      .getAll(this.user, this.element.IHSTOR)
      .subscribe((data) => {
        this.storerResponse = data;
        console.log(this.storerResponse);
        
        var response = this.storerResponse[0].data[1]?.value;
        var parsedData = JSON.parse(response);
        this.storerSource = parsedData.returnedData;
        console.log('STORER DATA');
        console.log(this.storerSource);
        
        if(localStorage.getItem('storername') === null){
          localStorage.setItem('storername',this.storerSource[0].STNAME);
          this.storerName = this.storerSource[0].STNAME;
        } 
        else{
          
          this.storerName = localStorage.getItem('storername');                           
        }

        this.form.reset({tally:this.tally,storer:this.storer,storerName : this.storerName})
      });

      console.log(this.tally);
      console.log(this.storer);
      console.log(this.storerName);      

  }

  tallyDetailUpdate(tally: any) {
    this.matDialog.open(TallyDetailUpdateComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: tally,
      autoFocus: false,
    });
  }

  tallyDetailPutawayLocation(tally: any) {
    var tally2 = this.element; //tally
    var data = [tally, tally2];
    // this.matDialog.open(TallyDetailPutawayLocationComponent, {
    //   "width": '1000px',
    //   "maxHeight": '80vh',
    //   "data": data,
    //   "autoFocus": false
    // });
    this.router.navigateByUrl('/main-nav/putawayLocation/read', {
      state: data,
    });
  }

  tallyDetailPutawayLocationUpdate(tally: any) {
    this.matDialog.open(TallyPutawayUpdateComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: this.id,
      autoFocus: false,
    });
  }

  stockStatus(tallyDtl: any) {
    var tallyData = this.element;
    var allData = [tallyData,tallyDtl]
    this.matDialog.open(StockStatusComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data:allData,
      autoFocus: false,
    });
  }


  tallyDetailDelete(tally: any) {
    /*this.result = this.iHeaderSvc
      .deleteTally(tally, this.user)
      .subscribe((data: any) => {
        this.result = data;
      });
    alert('Record deleted successfully.');*/

    this.matDialog.open(TallyDetailDeleteComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: tally,
      autoFocus: false,
    });
  }

  tallyDetailsComment(tally: any) {
    console.log(tally);
    this.result = this.iHeaderSvc
      .getTallyDetailComment(tally)
      .subscribe((data: any) => {
        console.log(data);
        this.result = data;
        this.matDialog.open(TallyDetailCommentsComponent, {
          width: '1100px',
          maxHeight: '80vh',
          data: tally,
          autoFocus: false,
        });
      });
  }

  tallyDtlDeReceive(tallyDtl:any) {
    this.result = this.iHeaderSvc.deReceiveTallyDetailsService(
        this.user,
        tallyDtl.IDTALY,
        tallyDtl.IDSEQ,
      ).subscribe((data: any) => {
        this.result = data;
        if(this.result[0].data[length-1].value=='Record Updated Successfully'){
          const msg= this.result[0].data[length-1].value;
          console.log('De Receive Tally Detail Success');
          this.successMessage(msg);
        }else{
          const msg=this.result[0].data[length-1].value;
          console.log('De Receive Tally Detail Failed');
          this.failureMessage(msg);
        }
    });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }  

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }

  addTally() {
    this.matDialog.open(MaintainComponent, {
      width: '1000px',
      maxHeight: '80vh',

      autoFocus: false,
      data: this.element,
    });
  }

  closeDialog() {
    //  this.dialogRef.close();
    this.router.navigate(['/main-nav/tallys/read']);
  }
}
