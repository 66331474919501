import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-lot-status-inquiry',
  templateUrl: './ww-lot-status-inquiry.component.html',
  styleUrls: ['./ww-lot-status-inquiry.component.css']
})
export class WwLotStatusInquiryComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
      
  ];

  dataSource:any=[]


  duplicateSource:any =[   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
     @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwLotStatusInquiryComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
          StorerNumber:'1301',
          ItemNumber:'ITEM1',
          LotCode:'',
          LotWeight:'1.53500',
          Expiration:'',
          DateHeld:'',
          DateReleased:'',
          DateReceived:'',
          DateChanged:'',
          ChangedBy:'NKOHAN',
          Status:''
      
      
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['tallys']);
  }

  closeDialog(){
    this.dialogRef.close();
  }

  addAttribute(){

  }
}
