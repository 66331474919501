import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AddSerialNumberComponent } from '../add-serial-number/add-serial-number.component';
import { DeleteSerialNumberComponent } from '../delete-serial-number/delete-serial-number.component';
import { EditSerialNumberComponent } from '../edit-serial-number/edit-serial-number.component';

@Component({
  selector: 'app-ww-serial-number',
  templateUrl: './ww-serial-number.component.html',
  styleUrls: ['./ww-serial-number.component.css'],
})
export class WwSerialNumberComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = ['Type', 'SerialNumber', 'Actions'];
  dataSource: any = [];

  duplicateSource: any = [
    {
      Type: 'OD',
      SerialNumber: '678HJY ',
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<WwSerialNumberComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      Bill: 123455239,
      OriginalLineNo: 1,
      Item: 1234,
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  closeDialog() {
    this.dialogRef.close();
  }
  addSerialNumber() {
    this.matDialog.open(AddSerialNumberComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  editSerialNumber() {
    this.matDialog.open(EditSerialNumberComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  deleteSerialNumber() {
    this.matDialog.open(DeleteSerialNumberComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
}
