<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Serialized Inventories</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>Book:</mat-label>
                      <input matInput formControlName="book"/>
                      </mat-form-field>  
                   
                     </div>
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="BookName">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            BookName
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.BookName }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Date">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Date
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Date }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Description">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Description
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Description }}
                
            </td>
          </ng-container>
  
          <ng-container matColumnDef="Sts">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Sts
            </th> 
            <td mat-cell *matCellDef="let element" >{{ element.Sts }}</td>
          </ng-container>

        <ng-container matColumnDef="Opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            Opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
           <mat-option>
            <button mat-icon-button (click)="SerializedDetailsForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Serial Details</mat-label>
            </button>
        
          </mat-option>

               
          <mat-option>
            <button mat-icon-button (click)="SerializedWorkSheetForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Serial Worksheet</mat-label>
            </button>
        
          </mat-option>

          <mat-option>
            <button mat-icon-button (click)="SerializedCountForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Serial Count</mat-label>
            </button>
        
          </mat-option>
          <mat-option>
            <button mat-icon-button (click)="SerializedLogForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Adjustment-Log</mat-label>
            </button>
        
          </mat-option>

          <mat-option>
            <button mat-icon-button (click)="SerializedSynopsysForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Book Synopsys</mat-label>
            </button>
        
          </mat-option>

          <mat-option>
            <button mat-icon-button (click)="SerializedBookCountForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Book To Count</mat-label>
            </button>
        
          </mat-option>
    
          </mat-select>
          </td>
          
        </ng-container>
   
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
      </table>
