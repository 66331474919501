<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Delete An Exit Point</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Storer </mat-label>
            <input matInput formControlName="Storer" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Exit Point </mat-label>

            <input matInput formControlName="Exit_Point" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label> Choice </mat-label>
            <input
              matInput
              formControlName="Choice"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <!-- <button mat-button (click)="deleteExitPoint()" class="buttons removeButton">Delete</button> -->
          <div id="custom-alert">
            <div class="custom-alert-content">
              <button mat-button class="buttons removeButton" 
              (click)="deleteExitPoint()">Delete</button>
      
            </div>
          </div>

        </div>
      </div>
    </form>
    
  </mat-card-content>
</mat-card>
