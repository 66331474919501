<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Product Code Description</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>Storer:</mat-label>
                      <input matInput formControlName="Storer"/>
                      </mat-form-field>  
                     </div>
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="Storer">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Storer
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Storer }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Product">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Product
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Product }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Description">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Description
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Description }}
                
            </td>
          </ng-container>
  
        <ng-container matColumnDef="Opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            Opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">

                <mat-option>
                    <button mat-icon-button (click)="DescriptionAddForm(element)">
                      <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                      <mat-label>ADD</mat-label>
                    </button>
                  </mat-option> 
                  
                  <mat-option>
                  <button mat-icon-button (click)="DescriptionChangeForm(element)">
                    <mat-icon mat-icon-button color="primary">edit</mat-icon>
                    <mat-label>Change</mat-label>
                  </button>
                </mat-option>  
              
                 
                
                 
                
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
      </table>
