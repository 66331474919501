import { Injectable } from '@angular/core';

export interface CustomColumn {
  position: number;
  name: string;
  isActive: boolean;
}

@Injectable({
  providedIn: 'root'
})

export class TableServiceService {

  columnsLength:any;
  columnShowHideList:CustomColumn[]= []
  constructor() { }

  toggleColumn(column: any,displayedColumns:string[]) {
    this.columnsLength = displayedColumns.length - 1;
    if (column.isActive) {
      if (column.position > this.columnsLength) {
        displayedColumns.splice(this.columnsLength,0,column.name);
      } 
      else{
        displayedColumns.splice(column.position, 0, column.name);
      }
    } else {
      let i = displayedColumns.indexOf(column.name);
      let opr = i > -1 ? displayedColumns.splice(i, 1) : undefined;
    }
    return displayedColumns;
  }

  initializeColumnProperties(displayedColumns:string[],otherColumns:string[]) {
    this.columnShowHideList = []
    displayedColumns.forEach((element, index) => {
      if (element != 'Actions') {
        this.columnShowHideList.push({
          position: index,
          name: element,
          isActive: true,
        });
      }
    });
    otherColumns.forEach((element)=>{
      this.columnShowHideList.push({
        position:this.columnShowHideList.length,
        name:element,
        isActive:false
      })
    });
    console.log(this.columnShowHideList);
    
    return this.columnShowHideList;
    
  }
}
