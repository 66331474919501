<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
        Work With Metric Conversions</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>Type of Metric:</mat-label>
                      <input matInput formControlName="typeofmetric"/>
                      </mat-form-field> 
                      <mat-form-field appearance="outline">
                        <mat-label>From Unit:</mat-label>
                       <input matInput formControlName="fromUnit"/>
                       </mat-form-field>  
                     </div>
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="typeofMetric">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Type of Metric
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.typeofMetric }}</td>
        </ng-container>
  
        <ng-container matColumnDef="fromUnit">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            From Unit
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.fromUnit }}</td>
        </ng-container>
  
        <ng-container matColumnDef="baseUnit">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Base Unit
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.baseUnit }}
                
            </td>
          </ng-container>
          
        <ng-container matColumnDef="conversionFactor">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Conversion Factor
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.conversionFactor }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="example">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Example
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.example }}
                
            </td>
          </ng-container>

  
        <ng-container matColumnDef="Opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            Opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
                  
                    <mat-option>
                  <button mat-icon-button >
                    <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                    <mat-label>CHANGE</mat-label>
                  </button>
                </mat-option>  
              
                  <mat-option>
                    <button mat-icon-button >
                      <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                      <mat-label>ADD</mat-label>
                    </button>
                  </mat-option>   
                 
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
      </table>

