import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-operator-equipment-copy',
  templateUrl: './operator-equipment-copy.component.html',
  styleUrls: ['./operator-equipment-copy.component.css']
})
export class OperatorEquipmentCopyComponent implements OnInit {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<OperatorEquipmentCopyComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      building: [null],
      equipment: '10',
      maximumCapacity:[null],
      lpOrElectric:[null],
      manufacturer:[null],
      ownOrLease:[null],
      shiftorDayPm:[null],
      trailerCpable:[null],
      frightType:[null],
      raisedForks:[null],
      status:[null]


    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  copy() {
    console.log('updated');
  }


}
