<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title style="color: rgb(32, 68, 145)"
                    >Print Count Listing</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                    <div class="row">    
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> sortOrder  </mat-label> 
                        <mat-select matNativeControl required>
                        <mat-option value="Item">I</mat-option>
                        <mat-option value="Location">L</mat-option>
                        <mat-option value="Book">B</mat-option>
                        </mat-select> 
                    </mat-form-field>
                  </div></div>

                  <div class="row">    
                    <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> listingType  </mat-label> 
                          <mat-select matNativeControl required>
                          <mat-option value="All">A</mat-option>
                          <mat-option value="Recount">R</mat-option>
                          <mat-option value="Difference">D</mat-option>
                          </mat-select> 
                      </mat-form-field>
                    </div></div>

                  <div class="row">
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> showBookCount  </mat-label> 
                        <mat-select matNativeControl required>
                        <mat-option value="Yes">Y </mat-option>
                        <mat-option value="Deviation">D</mat-option>
                        </mat-select> 
                    </mat-form-field>
                  </div>
                </div>
                <div class="col">
                   <h3><b>Optional Filtering</b></h3>
                </div>
<hr>
                <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> sequenceNumber: </mat-label> 
                        <input matInput formControlName="sequenceNumber" />
                      </mat-form-field>
                </div>
                
                
                <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> item: </mat-label> 
                        <input matInput formControlName="item" />
                      </mat-form-field>
                </div>

                
                <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> location: </mat-label> 
                        <input matInput formControlName="location" />
                      </mat-form-field>
                </div>
                            <div >
                              <button mat-button class="buttonCss" (click)="attributes()">CountListing</button>
                          </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  
  
  
