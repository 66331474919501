<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>General Invoice Entry</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Customer </mat-label>
            <input matInput formControlName="Customer" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Building </mat-label>
            <input matInput formControlName="Building" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Bill-to Cust </mat-label>

            <input matInput formControlName="BillToCust" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Invoice Date </mat-label>

            <input matInput formControlName="InvoiceDate" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Type </mat-label>

            <input matInput formControlName="Type" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Amount </mat-label>

            <input matInput formControlName="Amount" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Reference No </mat-label>

            <input matInput formControlName="ReferenceNo" />
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Car/Trlr No </mat-label>

            <input matInput formControlName="CarTrlrNo" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Description </mat-label>

            <input matInput formControlName="Description" />
          </mat-form-field>
        </div>

        <button class="buttons addButton" mat-raised-button (click)="add()">
          Add
        </button>
      </div>
      <br /><br />
    </mat-card-content>
  </mat-card>
</form>
