import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';


@Component({
  selector: 'app-ww-locater-load-sheduled-floor-move',
  templateUrl: './ww-locater-load-sheduled-floor-move.component.html',
  styleUrls: ['./ww-locater-load-sheduled-floor-move.component.css']
})
export class WwLocaterLoadSheduledFloorMoveComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<WwLocaterLoadSheduledFloorMoveComponent>
   ) 
   { }
  
  ngOnInit(): void {
    this.form = this.fb.group({
      STOR:'5002 ',
      ITEM:'244 ',
      LOCAT:"1 A/1",
      LOTS:'20200209',
      AQTY:'90',
      SUCAT:'',
      TOCAT:'',
      MQTY:'',
      PRTY:'',  
    });
  }
  
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
  
  
  backstorer(){
    
  }
  attributes(){
    
  }
  }