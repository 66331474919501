<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Update Extract Report Base Fields </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline"
              ><mat-label> Base Report </mat-label>
              <input matInput formControlName="baseReport" />
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label> Sequence </mat-label>
  
              <input matInput formControlName="sequence" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label> Field Name </mat-label>
    
                <input matInput formControlName="fieldName" />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label> Filter Name </mat-label>
    
                <input matInput formControlName="filterName" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> Column Heading </mat-label>
    
                <input matInput formControlName="columnHeading" />
              </mat-form-field>
            <button
              mat-button
              class="buttons addButton"
              mat-raised-button
              (click)="update()"
            >
              Add
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  

