<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Email Exit Points </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button
        mat-button
        (click)="addEmailExitPoint()"
        class="addButton buttons"
      >
        <mat-icon>add</mat-icon>
        <span>New Email Exit point</span>
      </button>
    </div>
    <form class="form over-Flow" [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label>Exit Point</mat-label>
            <input matInput formControlName="EPPOIN" />
          </mat-form-field>
          <button
            class="addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-card-content></mat-card-content>
  <!-- <mat-card-content>
      <form [formGroup]="addCustomerForm" class="form over-Flow">
        <div class="row" *ngIf="searchTextDisplay">
          <div class="col">
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Tally</mat-label>
              <input matInput formControlName="tally" />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Ref#:{{ searchTextDisplay }}</mat-label>
              <input
                matInput
                formControlName="storerRef"
                style="text-transform: uppercase"
              />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Schd Arv:</mat-label>
              <input
                matInput
                formControlName="schdldArrival"
                [matDatepicker]="schdldArrivalDatePicker"
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="schdldArrivalDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #schdldArrivalDatePicker></mat-datepicker>
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Invoice</mat-label>
              <input matInput formControlName="invoice" />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Carrier </mat-label>
              <input
                matInput
                formControlName="carrier"
                style="text-transform: uppercase"
              />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> Building </mat-label>
              <input matInput />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> Storer </mat-label>
              <input matInput formControlName="storer" />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> Consignee </mat-label>
              <input
                matInput
                formControlName="recvdFrom"
                style="text-transform: uppercase"
              />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> Status </mat-label>
              <input matInput value="0" />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> PRO Number </mat-label>
              <input matInput formControlName="pro" />
            </mat-form-field>
            &nbsp; &nbsp;
          </div>
        </div>
        <div>
         
  
          <button
            *ngIf="searchTextDisplay"
            class="buttons addButton"
            mat-raised-button
            type="submit"
            [disabled]="!addCustomerForm.dirty"
            placeholder="Search"
          >
            Search
          </button>
          <br /><br />
        </div>
      </form>
    </mat-card-content> -->

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="EmailExitPoint">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Email Exit Point
      </th>
      <td mat-cell *matCellDef="let element">{{ element.EPPOIN }}</td>
    </ng-container>

    <ng-container matColumnDef="MessageFormat">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Message Format
      </th>
      <td mat-cell *matCellDef="let element">{{ element.EPEFMT }}</td>
    </ng-container>

    <ng-container matColumnDef="ControlNoType">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Control No Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.EPNTYP }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ContactType">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Contact Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.EPCTYP }}
      </td>
    </ng-container>

    <ng-container matColumnDef="MessageName">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Message Name
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.EPMNAM }}
      </td>
    </ng-container>

    <ng-container matColumnDef="MessageBuilder">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Message Builder
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.EPMBLD }}
      </td>
    </ng-container>

    <ng-container matColumnDef="StatusCode">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Status Code
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.EPSTAT }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option (click)="getUpdateForm(element)">
            <button mat-icon-button>
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >
          <mat-option (click)="getDeleteForm(element)">
            <button mat-icon-button>
              <mat-icon mat-icon-button color="primary">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
          <mat-option (click)="getCopyForm(element)">
            <button mat-icon-button>
              <mat-icon mat-icon-button color="primary">file_copy</mat-icon>
              <mat-label>Copy</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
            #paginator
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator> -->
  <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
<router-outlet></router-outlet>
