<mat-card class="over-Flow">
    
    <div class="main-header-title">
      <mat-card-header>                   
        <mat-card-title >Maintain Attributes</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content  class="tally-serach-form"> 
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
              <mat-form-field  appearance="outline"><mat-label> Attribute : </mat-label> 
                  <input matInput formControlName="Attribute" />
              </mat-form-field>
            
              <mat-form-field appearance="outline">  <mat-label> Attribute Description : </mat-label> 
                  <input matInput formControlName="Attribute_Description" />
              </mat-form-field>
          </div>
        </div>
      
        <button mat-button class="buttons addButton" (click)="attributes()">Add</button>
    
      </form>        
    </mat-card-content>           
</mat-card>  