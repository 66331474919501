<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card>
  <mat-card-header>
    <mat-card-title>Delete a General Invoice</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Control Number </mat-label>
            <input matInput formControlName="ControlNumber" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Building </mat-label>
            <input matInput formControlName="Building" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Invoice Date </mat-label>

            <input matInput formControlName="InvoiceDate" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Amount </mat-label>

            <input matInput formControlName="Amount" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Invoice Type </mat-label>

            <input matInput formControlName="InvoiceType" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Customer </mat-label>

            <input matInput formControlName="Customer" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Reference No </mat-label>

            <input matInput formControlName="ReferenceNo" />
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Car/Trailer </mat-label>

            <input matInput formControlName="CarTrailer" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Description </mat-label>

            <input matInput formControlName="Description" />
          </mat-form-field>
        </div>
      </div>
    </form>
    <div>
      <p>Press Enter to confirm Delete</p>
      <button mat-button (click)="(delete)" class="deleteButton">Delete</button>
      <br />
    </div>
  </mat-card-content>
</mat-card>
