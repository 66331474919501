import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { AddMessageClassTablesComponent } from './add-message-class-tables/add-message-class-tables.component';
import { ChangeMessageClassTablesComponent } from './change-message-class-tables/change-message-class-tables.component';

@Component({
  selector: 'app-ww-messsage-class-tables',
  templateUrl: './ww-messsage-class-tables.component.html',
  styleUrls: ['./ww-messsage-class-tables.component.css']
})
export class WwMesssageClassTablesComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'FILE',
    'PATH',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      FILE:'CITEMS',
      PATH:'//CITEMS'

    },
    {
      FILE:'LOTS',
      PATH:'//LOTS'
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      DOCUMENT:'AXWAY 945'
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }


  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  add(){
    this.matDialog.open(AddMessageClassTablesComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false,

    });
  }

  change(element:any){
    this.matDialog.open(ChangeMessageClassTablesComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  details(element:any)
  {
    this.router.navigateByUrl('/main-nav/messageClassColumns/read', { state: element });
  }

}
