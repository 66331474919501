<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
    <form [formGroup]="form">
        <mat-card>
        <mat-card-header>
            <button mat-button (click)="backstorer()">
                <mat-icon></mat-icon>
                
            </button>
            
            <mat-card-title style="color: rgb(203, 206, 212)"
            >Inbound Comments Maintenance</mat-card-title>
            
        </mat-card-header>
        <mat-card-content>
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Item: </mat-label> 
                    <input matInput formControlName="TITLE" />
                  </mat-form-field>   
                  
            </div>
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label>  </mat-label> 
                    <input matInput formControlName="CICMNT" />
                  </mat-form-field>   
                  
            </div>

            <div >
                <button mat-button class="buttonCss" (click)="attributes()">Submit</button>
            </div>
            
            </mat-card-content>
            </mat-card>
            </form> 
