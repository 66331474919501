<mat-card class="over-Flow">
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Periods</mat-card-title>

        <div class="buttons">

          <button
    mat-button
    (click)="searchTally()"
    class="addButton"
    *ngIf="searchTextDisplay"
  >
    <mat-icon>clear</mat-icon>
    <!-- <span>Hide Search Details</span> -->
  </button>

  <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay" matTooltip="Search Tally"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>

          <button mat-button (click)="addPeriod()"  class="addButton">
            <mat-icon>add</mat-icon>
            <!-- <span>New Period</span> -->
          </button>
        </div>
    </mat-card-header>
  
    <mat-card-content class="mt-4">
  
      <form [formGroup]="form"
      (ngSubmit)="getPeriod(form.value)" *ngIf="searchTextDisplay">
      
     
     
     <div class="col">
     
       
          <mat-form-field appearance="outline">
            <mat-label>Storer Number:</mat-label>
            <input matInput formControlName="STOR" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Search Date:</mat-label>
            <input matInput type="date" formControlName="STDT"/>
          </mat-form-field>

          <button
         
          class="buttons addButton"
          mat-raised-button
          type="submit"
          placeholder="Search"
        >
          Search
        </button>
          
         
     
     </div>
    
    </form>
    </mat-card-content>
  
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="STDSC">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Storer
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.PESTOR }}</td>
        </ng-container>
  
        <ng-container matColumnDef="PESTDT">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Start Date
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.PESTDT }}</td>
        </ng-container>
  
        <ng-container matColumnDef="PEENDT">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            End Date
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.PEENDT }}</td>
        </ng-container>
  
        <ng-container matColumnDef="PEBALN">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Bal Written
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.PEBALN }}</td>
        </ng-container>
  
        <ng-container matColumnDef="PEINV">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Storage Invoice
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.PEINV }}</td>
        </ng-container>

        
        <ng-container matColumnDef="PESTAT">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Status
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.PESTAT }}</td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
           <!-- Actions -->
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="dropdown more_options_btn">
              <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <mat-icon>more_vert</mat-icon>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <button mat-icon-button  (click)="change(element)">           
                  <mat-icon mat-icon-button color="primary">edit</mat-icon>
                  <mat-label>Edit</mat-label>
                </button>
              </li>

              <li>
                <button mat-icon-button  (click)="delete(element)">           
                  <mat-icon mat-icon-button color="warn">delete</mat-icon>
                  <mat-label>Delete</mat-label>
                </button>
              </li>
              </ul>
            </div>
          </td>

        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator 
        [pageSizeOptions]="[4,8]" 
        showFirstLastButtons class="tally-table-pagination"
        ></mat-paginator>
    </mat-card>


