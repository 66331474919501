import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-maintquestions-add',
  templateUrl: './maintquestions-add.component.html',
  styleUrls: ['./maintquestions-add.component.css']
})
export class MaintquestionsAddComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  matDialog: any;

  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MaintquestionsAddComponent>
  ) 
  { }

  ngOnInit(): void {
    this.form = this.fb.group({
      BLDG:'13',
      EZTYPE:'FORK',
      EZSEQ:'1',
      EZQUES:'BRAKES',
      EZSEV:'40',
      EZMAND:'Y',
      EZSTAT:'AC'
       
    });
  }
  MaintQuestionsAddForm(){
    this.matDialog.open(MaintquestionsAddComponent, {
        "width": '1000px',
         "maxHeight": '90vh',
      //  'data': element,
       "autoFocus": false                           
      });
      }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}

