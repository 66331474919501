import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { OperatorEquipmentChangeComponent } from './operator-equipment-change/operator-equipment-change.component';
import { OperatorEquipmentCopyComponent } from './operator-equipment-copy/operator-equipment-copy.component';
import { OperatorEquipmentCreateComponent } from './operator-equipment-create/operator-equipment-create.component';
import { OperatorEquipmentDeleteComponent } from './operator-equipment-delete/operator-equipment-delete.component';

@Component({
  selector: 'app-ww-operator-equipment',
  templateUrl: './ww-operator-equipment.component.html',
  styleUrls: ['./ww-operator-equipment.component.css']
})
export class WwOperatorEquipmentComponent implements OnInit {

  displayedColumns: string[] = ['building', 'equipNumber', 'equipType', 'maxForkCap', 
  'pmForShiftoRDay', 'trailerCapable', 'frightType', 'raisedForks', 'status','Actions'];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      building: 'ACTIVESRL',
      equipNumber: '001',
      equipType:'LCSTOR',
      maxForkCap:'',
      pmForShiftoRDay:'STORER',
      trailerCapable:'STORER',
      frightType:'STORER'


    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      equipment: [null],
      building: [null],
      status: [null]
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  operatorEquipmentCreate() {
    this.matDialog.open(OperatorEquipmentCreateComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  operatorEquipmentDelete() {
    this.matDialog.open(OperatorEquipmentDeleteComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  operatorEquipmentUpdate(element :any) {
    this.matDialog.open(OperatorEquipmentChangeComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  operatorEquipmentCopy(element :any) {
    this.matDialog.open(OperatorEquipmentCopyComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }
  
  delete() {
    alert('deleted');
  }

}
