<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Orders Update</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">


  <form [formGroup]="form" class="form over-Flow">
    <div class="row">
      <div class="col"> 
  
    
      
            <mat-form-field  appearance="outline"><mat-label> BOL Number: </mat-label> 
              <input matInput formControlName="bolNumber" value="{{bol}}" readonly/>
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Order Status: </mat-label> 
              <input value="{{status}}" readonly
                matInput
                formControlName="orderStatus"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Storer Number: </mat-label>
              <input value="{{store}}" readonly
                matInput 
                formControlName="storer"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Suffix: </mat-label> 
              <input value="{{sfx}}" readonly
                matInput
                formControlName="suffix"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Storer Reference: </mat-label>
              <input value="{{ref}}"
                matInput
                formControlName="storerRef"
                style="text-transform: uppercase"
              />            
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Purchase Order: </mat-label> 
              <input value="{{purchase}}"
                matInput
                formControlName="purchaseOrder"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Consignee : </mat-label>
              <input value="{{consNo}}" readonly
                matInput
                formControlName="consignee"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Consignee Name: </mat-label>
              <input value="{{consName}}" readonly 
                matInput
                formControlName="consigneeName" value="{{consName}}"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          <div style="margin-left:20px"><b>Address</b></div>

          
            <mat-form-field appearance="outline">  <mat-label> Address 1: </mat-label> 
              <input
                matInput
                formControlName="address1" value="{{consAdrs1}}" readonly
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Address 2: </mat-label> 
              <input
                matInput
                formControlName="address2" value="{{consAdrs2}}" readonly
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Address 3: </mat-label> 
              <input
                matInput
                formControlName="address3" value="{{consAdrs3}}" readonly
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Address 4: </mat-label> 
              <input
                matInput
                formControlName="address4" value="{{consAdrs4}}" readonly
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Address 5: </mat-label> 
              <input readonly
                matInput
                formControlName="address5"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <!-- <mat-form-field  appearance="outline"><mat-label> Sched Ship Date: </mat-label> 
              <input matInput formControlName="schedShipDate"/>
            </mat-form-field> -->
            <mat-form-field appearance="outline" class="full-width">
              <mat-label>Scheduled Arrival Date </mat-label>
              <input
                matInput
                placeholder="Scheduled Arrival"
                formControlName="schedArrivalDate"
                [matDatepicker]="schdldArrivalDatePicker"
                 
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="schdldArrivalDatePicker"
              ></mat-datepicker-toggle>
              <mat-datepicker #schdldArrivalDatePicker></mat-datepicker>
              <input
              type="time"
              id="exampleInput"
              name="input"
              placeholder="HH:mm"
             
              required
            />
            </mat-form-field>
          
            <!-- <mat-form-field appearance="outline">  <mat-label> Sched Arrival Date: </mat-label> 
              <input 
                matInput 
                formControlName="schedArrivalDate"
                style="text-transform: uppercase"
              />
            </mat-form-field>
           -->
           <mat-form-field appearance="outline" class="full-width">
            <mat-label>Scheduled Ship Date </mat-label>
            <input
              matInput
              formControlName="schedShipDate"
              [matDatepicker]="schedShipDatePicker"
              
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="schedShipDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #schedShipDatePicker></mat-datepicker>
            <input type="time"  required />
          </mat-form-field>


          <!-- <div class="col-md-4">
            <mat-form-field appearance="outline">  <mat-label> Ship Type: </mat-label>
              <input 
                matInput
                formControlName="shipType"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          </div> -->

          
            <mat-form-field appearance="outline">  <mat-label> Ship: </mat-label>
              
              <mat-select formControlName="shipType">
                <mat-option [value]="01">Customer Pickup / Will Call</mat-option>
                <mat-option [value]="02">Common Carrier</mat-option>
                <mat-option [value]="03">Rail Car</mat-option>
                <mat-option [value]="05">Piggyback</mat-option>
                <mat-option [value]="06">Parcel Shipment</mat-option>
                <mat-option [value]="07">Air Freight</mat-option>
                <mat-option [value]="08">Consolidation</mat-option>
                <mat-option [value]="11">International Air</mat-option>
                <mat-option [value]="12">Domestic Air</mat-option>
                <mat-option [value]="14">Broker Cortage</mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Carrier Code: </mat-label> 
              <input 
                matInput
                formControlName="carrierCode"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          </div>
        </div>
      

          <!-- <div class="col-md-4">
            <mat-form-field appearance="outline">  <mat-label> Freight Charge: </mat-label>
              <input 
                matInput
                formControlName="freightCharge"
                style="text-transform: uppercase"
              />            
            </mat-form-field>
          </div> -->

         
            <mat-form-field appearance="outline">  <mat-label> Freight Charge: </mat-label>
              
              <mat-select formControlName="freightCharge">
                <mat-option value="CO">CO = Collect</mat-option>
                <mat-option value="PS">PS = Prepaid Storer</mat-option>
                <mat-option value="PW">PW = Prepaid Warehouse</mat-option>
                <mat-option value="WC">WC = Will Call</mat-option>
                <mat-option value="3P">3P= 3rd Party Billing</mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> COD Amount: </mat-label> 
              <input 
                matInput
                formControlName="codAmount"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Apply Auto Charge : </mat-label>
              <mat-select formControlName="applyAutoCharge">
                  <mat-option value="Y">Yes</mat-option>
                  <mat-option value="N">No</mat-option>
              </mat-select>
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Bill To Customer: </mat-label>
              <input 
                matInput
                formControlName="billToCustomer"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
              <mat-form-field appearance="outline">  <mat-label> Bill To Suffix: </mat-label>
                <input 
                  matInput
                  formControlName="billToSuffix"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
              <mat-form-field appearance="outline">  <mat-label> Broker Name: </mat-label>
                <input 
                  matInput
                  formControlName="brokerName"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
              <mat-form-field appearance="outline">  <mat-label> Total Quantity: </mat-label>
                <input 
                  matInput
                  formControlName="totalQuantity"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
              <mat-form-field appearance="outline">  <mat-label> Ship Order From: </mat-label>
                <input 
                  matInput
                  formControlName="shipOrderFrom"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
              <mat-form-field appearance="outline">  <mat-label> Override Quantity: </mat-label>
                <input 
                  matInput
                  formControlName="overrideQty"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
              <mat-form-field appearance="outline">  <mat-label> Building: </mat-label>
                <input 
                  matInput
                  formControlName="building"
                  style="text-transform: uppercase"
                />
              </mat-form-field> 
            
              <mat-form-field appearance="outline">  <mat-label> Zone: </mat-label>
                <input 
                  matInput
                  formControlName="zone"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            
              <mat-form-field appearance="outline">  <mat-label> Commit Inventory? : </mat-label>
                <input 
                  matInput
                  formControlName="commitInventory"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
   

       
            

          <table style="width:300px">
            <div style="margin-left:40px"><th>Printer</th></div><th>Immed</th>
          <!-- <div style="margin-left:20px"><b>Printer</b></div> -->
          <tr>
            <td>
              <mat-form-field appearance="outline">  <mat-label> Control Copy Printer: </mat-label> 
                <input
                  matInput
                  formControlName="ctrl"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">  <mat-label> Control Copy Immed: </mat-label> 
                <input
                  matInput
                  formControlName="ctrlImmed"
                  style="text-transform: uppercase"
                />
              </mat-form-field>
            </td>
            </tr>

            <tr>
              <td>
                <mat-form-field appearance="outline">  <mat-label> Pick Sheet Printer: </mat-label> 
                  <input
                    matInput
                    formControlName="pick"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline">  <mat-label> Pick Sheet Immed: </mat-label> 
                  <input
                    matInput
                    formControlName="pickImmed"
                    style="text-transform: uppercase"
                  />
                </mat-form-field>
              </td>
              </tr>

              <tr>
                <td>
                  <mat-form-field appearance="outline">  <mat-label> BOL Printer: </mat-label> 
                    <input
                      matInput
                      formControlName="bol"
                      style="text-transform: uppercase"
                    />
                  </mat-form-field>
                </td>
                <td>
                  <mat-form-field appearance="outline">  <mat-label> BOL Immed: </mat-label> 
                    <input
                      matInput
                      formControlName="bolImmed"
                      style="text-transform: uppercase"
                    />
                  </mat-form-field>
                </td>
                </tr>
              

            </table>
          
        
    


  
        
      
      
        <div class="btn-text-right">
          <button mat-button class="button addButton"(click)="orderUpdate2()">Next</button>
        </div>
      </form>
    </mat-card-content>
 </mat-card>
    
  
