
<mat-card class="over-Flow">
<mat-card-header>
      <mat-card-title fxLayoutAlign="center">
        <button mat-button (click)="onBack()" class="backButton">
            <mat-icon>navigate_before </mat-icon>
            
          </button>
        Maintain Putaway Locations
      </mat-card-title>
        
    <div class="buttons">
      <button
        mat-button
        (click)="searchPutAwayLocation()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>
      <button mat-button 
      (click)="searchPutAwayLocation()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search Tally Detais Putaway Location">
          <mat-icon>search</mat-icon>
      </button>

        <button mat-button  class="addButton"  
          (click)="createPutawayLocation()" 
          matTooltip="Add Tally Detais Putaway Location">
          <mat-icon>add</mat-icon>
          <!-- <span>New Tally Detail</span> -->
        </button>
    </div>  
</mat-card-header>

<mat-card-content class="tally-serach-form">
    <form [formGroup]="form"
      class="form over-Flow"
      *ngIf="searchTextDisplay">    
        <div class="row">
            <div class="col">

                <mat-form-field appearance="outline">
                    <mat-label>Tally</mat-label>
                    <input matInput formControlName="tally" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Line</mat-label>
                    <input matInput formControlName="line" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Item</mat-label>
                    <input matInput formControlName="item" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Building</mat-label>
                    <input matInput formControlName="building" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" />
                </mat-form-field>

                <div class="row">
                <div class="col">
                  <div style="margin-left: 20px;">
                    <b>Lot Codes :</b>
                  </div>
                  <mat-form-field appearance="outline">
                      <mat-label>Lot 1</mat-label>
                      <input matInput formControlName="lotCode1" />
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                      <mat-label>Lot 2</mat-label>
                      <input matInput formControlName="lotCode2" />
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                      <mat-label>Lot 3</mat-label>
                      <input matInput formControlName="lotCode3" />
                  </mat-form-field>
                </div>
                </div>
                <mat-form-field appearance="outline">
                    <mat-label>Quantity</mat-label>
                    <input matInput formControlName="Quantity" />
                </mat-form-field>

                <!-- <mat-form-field appearance="outline">
                  <mat-label>Pallet Quantity</mat-label>
                  <input matInput />
                </mat-form-field>

                <mat-form-field appearance="outline">
                  <mat-label>Remaining</mat-label>
                  <input matInput />
                </mat-form-field> -->

                <mat-form-field appearance="outline">
                    <mat-label>Over</mat-label>
                    <input matInput formControlName="over" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Short</mat-label>
                    <input matInput formControlName="short" />
                </mat-form-field>
                
            </div>
          </div>
      <button
        *ngIf="searchTextDisplay"
        class="buttons addButton tally-search-btn"
        mat-raised-button
        type="submit"
      >
        Search
      </button>

        </form>
      </mat-card-content>

        <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
            <ng-container matColumnDef="location">
              <th mat-header-cell *matHeaderCellDef>Location</th>
              <td mat-cell *matCellDef="let element">{{ element.ILFLOR }} {{ element.ILAISL }} {{ element.ILSLOT }}</td>
            </ng-container>
        
            <ng-container matColumnDef="normal">
              <th mat-header-cell *matHeaderCellDef>Normal</th>
              <td mat-cell *matCellDef="let element">{{ element.ILQAVL }}</td>
            </ng-container>
        
            <ng-container matColumnDef="Damaged">
              <th mat-header-cell *matHeaderCellDef>Damaged</th>
              <td mat-cell *matCellDef="let element">{{ element.ILQDAM }}</td>
            </ng-container>
        
            <ng-container matColumnDef="Held">
              <th mat-header-cell *matHeaderCellDef>Held</th>
              <td mat-cell *matCellDef="let element">{{ element.ILQHLD }}</td>
            </ng-container>
        
        
            <ng-container matColumnDef="user1">
              <th mat-header-cell *matHeaderCellDef> User </th>
              <td mat-cell *matCellDef="let element"> {{ element.ILQUS1 }} </td>
            </ng-container>
        
            <ng-container matColumnDef="user2">
              <th mat-header-cell *matHeaderCellDef>User 2</th>
              <td mat-cell *matCellDef="let element">{{ element.ILQUS2 }}</td>
            </ng-container>

            <ng-container matColumnDef="user3">
                <th mat-header-cell *matHeaderCellDef>User 3</th>
                <td mat-cell *matCellDef="let element">{{ element.ILQUS3 }}</td>
            </ng-container>

            <ng-container matColumnDef="user4">
              <th mat-header-cell *matHeaderCellDef>User 4</th>
              <td mat-cell *matCellDef="let element">{{ element.ILQUS4 }}</td>
            </ng-container>
    
            
              <ng-container matColumnDef="Actions">
              <th mat-header-cell mat-header-cell width="180px" *matHeaderCellDef>
              <!-- Actions -->
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="dropdown more_options_btn">
                  <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <button mat-icon-button (click)="updatePutawayLocation(element)">
                      <mat-icon mat-icon-button color="primary">edit</mat-icon>
                      <mat-label>Update</mat-label>
                    </button>
                  </li>                  
        
                  <li>
                    <button mat-icon-button  (click)="deletePutawayLocation(element)">
                      <mat-icon mat-icon-button color="warn">delete</mat-icon>
                      <mat-label>Delete</mat-label>
                    </button>
                  </li>
        
                  </ul>
                </div>
              </td>
            </ng-container>
        
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>

          <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
   

</mat-card>