<mat-card class="over-Flow">
  
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Deactivate A Storer</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer : </mat-label>
            <input readonly matInput formControlName="storer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Name : </mat-label>
            <input readonly matInput formControlName="name" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Building : </mat-label>
            <input readonly matInput formControlName="buliding" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Primary Account Rep : </mat-label>
            <input readonly matInput formControlName="primaryAccountRep" />
          </mat-form-field>
          <p>
            This process marks the storer as inactive. It will remove the storer
            from the normal search panels and prevent entry of new transactions
          </p>
          <!-- <button mat-button (click)="deactivateStorer()" class="buttons removeButton">
            Deactivate
          </button> -->
          <div id="custom-alert">
            <div class="custom-alert-content">
              <button mat-button class="buttons removeButton" 
              (click)="deactivateStorer()">Deactivate</button>
      
            </div>
          </div>
        </div>
      </div>
    </form>

  </mat-card-content>
</mat-card>
