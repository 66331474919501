import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-maintquestions-copy',
  templateUrl: './maintquestions-copy.component.html',
  styleUrls: ['./maintquestions-copy.component.css']
})
export class MaintquestionsCopyComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';

  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MaintquestionsCopyComponent>
  ) 
  { }

  ngOnInit(): void {this.form = this.fb.group({
    BLDG:'13',
      EZTYPE:'FORK',
      EZSEQ:'1',
      EZSEV:'40',
      EZQUES:'BRAKES',
      EZMAND:'Y',
      EZSTAT:'AC',
     
  });
  }

  closeDialog(){
  // Write your stuff here
  this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
  
  }
  attributes(){
  
  }

  

}


