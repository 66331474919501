<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Order Detail Deletion</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">

          <mat-form-field appearance="outline"
            ><mat-label> Bill Of Lading </mat-label>
            <input matInput formControlName="bol" readonly/>
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Sequence </mat-label>
            <input matInput formControlName="seq" readonly/>
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Quantity </mat-label>
            <input matInput formControlName="quantity" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label> Item Code/Description </mat-label>
            <input matInput formControlName="items" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Lot Codes </mat-label>
            <input
              matInput
              formControlName="lots"
              style="text-transform: uppercase"
            readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Ship From </mat-label>
            <input
              matInput
              formControlName="shipFrom"
              style="text-transform: uppercase"
              readonly
            />
          </mat-form-field>
        </div>
      </div>
      <div>
        <button
          mat-button
          class="buttons removeButton"
          (click)="deleteOrderDetail()"
        >
          Delete
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
