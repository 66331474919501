<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title style="color: rgb(32, 68, 145)"
                    >Maintain Master Serials</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Serial: </mat-label> 
                          <input matInput formControlName="Serial" />
                        </mat-form-field>
                   </div>

                   <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Type: </mat-label> 
                          <input matInput formControlName="Type" />
                        </mat-form-field>
                   </div>

                            <div >
                              <button mat-button class="buttonCss" (click)="attributes()">ADD</button>
                          </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  
