import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-serial-number',
  templateUrl: './delete-serial-number.component.html',
  styleUrls: ['./delete-serial-number.component.css'],
})
export class DeleteSerialNumberComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteSerialNumberComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      KNDOCN: 123455239,
      KNLINE: 1,
      ODITEM: 1234,
      PD: 123456,
      ALT: 12345,
      ProductSerialNumber: 56687,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  delete() {
    console.log('delete');
  }
}
