import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { MessageclassshredDetailsChangeComponent } from './messageclassshred-details-change/messageclassshred-details-change.component';
import { MessageclassshredDetailsDeleteComponent } from './messageclassshred-details-delete/messageclassshred-details-delete.component';
import { MessageclassshredDetailsDetailsComponent } from './messageclassshred-details-details/messageclassshred-details-details.component';


@Component({
  selector: 'app-messageclassshred-details',
  templateUrl: './messageclassshred-details.component.html',
  styleUrls: ['./messageclassshred-details.component.css']
})
export class MessageclassshredDetailsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    
    'Table',
    'TableXPath',
    'Actions'
    
  

  ];
  dataSource:any=[]

  duplicateSource:any =[
    {
    DOCUMENT:'AXWAY 943',
    FILE:'EXH943P',
    PATH:'//EXH943P',
  
    },
    {  
    DOCUMENT:'AXWAY 943',
    FILE:'ICD943P',
    PATH:'//ICD943P',
    }
  ]



  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      DOCUMENT:'AXWAY 943',
      FILE:'EXH943P',
      PATH:'//EXH943P',

    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/message-class-shred/read']);

}
MessageClassShredDetailsUpdateForm(element:any){
  this.matDialog.open(MessageclassshredDetailsChangeComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
     'data': element,
     "autoFocus": false                           
    });
    }
    MessageClassShredDetailsDeleteForm(element:any){
      this.matDialog.open(MessageclassshredDetailsDeleteComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        }
        MessageClassShredDetailsDetailsForm(element:any) {
          this.router.navigate(['/main-nav/message-class-shred-details/details']);
          // this.matDialog.open(OrderCreateComponent, {
          //   "width": '1000px',
          //   "maxHeight": '130vh',
          //   "autoFocus": false
          // });
        }
            addAttribute(){

            }
    


  

}



