<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Door Usage</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="addDoorUsage()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>Create Door Usage</span>
      </button>
    </div>

    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Building Number</mat-label>
            <input matInput formControlName="BuildingNumber" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Door Number</mat-label>
            <input matInput formControlName="DoorNumber" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Account</mat-label>
            <input matInput formControlName="Account" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="Build">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Build
      </th>
      <td mat-cell *matCellDef="let element">{{ element.Build }}</td>
    </ng-container>

    <ng-container matColumnDef="Door">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Door
      </th>
      <td mat-cell *matCellDef="let element">{{ element.Door }}</td>
    </ng-container>

    <ng-container matColumnDef="Account">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Account
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Account }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="delete()">
              <mat-icon mat-icon-button color="secondary">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
                  #paginator
                  [pageIndex]="0"
                  [pageSize]="10"
                  [pageSizeOptions]="[5, 10, 25, 100]"
                >
                </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
