import { ReasonCodesElementComponent } from './reason-codes-element/reason-codes-element.component';
import { DeleteDataElementComponent } from './delete-data-element/delete-data-element.component';
import { UpdateDataElementComponent } from './update-data-element/update-data-element.component';
import { AddDataElementComponent } from './add-data-element/add-data-element.component';
import { GlobalComponent } from './../../Global-Component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-data-elements',
  templateUrl: './data-elements.component.html',
  styleUrls: ['./data-elements.component.css'],
})
export class DataElementsComponent implements OnInit {
  displayedColumns: string[] = [
    'ElementCode',
    'Description',
    'Internal/External',
    'Actions',
  ];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      ElementCode: 127,
      Description: 'ACH Sub',
      InternalExternal: '',
    },
    {
      ElementCode: 157,
      Description: 'Status Code',
      InternalExternal: 'I',
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      dataElementCode: [null],
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  dataElementCreate() {
    this.matDialog.open(AddDataElementComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  dataElementUpdate() {
    this.matDialog.open(UpdateDataElementComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  dataElementDelete() {
    this.matDialog.open(DeleteDataElementComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  ReasonCode() {
    this.matDialog.open(ReasonCodesElementComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }
}
