<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>


<form [formGroup]="form">
    <mat-card>
      <mat-card-header>
          <button mat-button (click)="backstorer()">
              <mat-icon></mat-icon>
              
          </button>
          
        <mat-card-title style="color: rgb(145, 128, 32)"
          >Remove Content From A Box</mat-card-title>
        
      </mat-card-header>
      
      <mat-card-content> 

        <div class="col">
            <mat-form-field  appearance="outline"><mat-label> boxNumber : </mat-label> 
                <input matInput formControlName="boxNumber" />
              </mat-form-field>
        </div>

            <div class="col">
                
                <mat-form-field appearance="outline">  <mat-label> Bundle: </mat-label> 
                    <input matInput formControlName="Bundle" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">  <mat-label> sequence: </mat-label> 
                    <input matInput formControlName="sequence" />
                  </mat-form-field>
                </div>

                <div class="col">
                
                    <mat-form-field appearance="outline">  <mat-label> contentIdentifier: </mat-label> 
                        <input matInput formControlName="contentIdentifier" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label> segment: </mat-label> 
                        <input matInput formControlName="segment" />
                      </mat-form-field>
                    </div>
                
                    <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> majorDescription : </mat-label> 
                            <input matInput formControlName="majorDescription" />
                          </mat-form-field>
                    </div>
             
                    <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> minorDescription : </mat-label> 
                            <input matInput formControlName="minorDescription" />
                          </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> clientID : </mat-label> 
                            <input matInput formControlName="clientID" />
                          </mat-form-field>
                          <mat-form-field  appearance="outline"><mat-label> group : </mat-label> 
                            <input matInput formControlName="group" />
                          </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> contentFromDate : </mat-label> 
                            <input matInput formControlName="contentFromDate" />
                          </mat-form-field>
                          <mat-form-field  appearance="outline"><mat-label> toDate : </mat-label> 
                            <input matInput formControlName="toDate" />
                          </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> retentionCode : </mat-label> 
                            <input matInput formControlName="retentionCode" />
                          </mat-form-field>
                          <mat-form-field  appearance="outline"><mat-label> ageClass : </mat-label> 
                            <input matInput formControlName="ageClass" />
                          </mat-form-field>
                    </div>
             
                    <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> recordSource : </mat-label> 
                            <input matInput formControlName="recordSource" />
                          </mat-form-field>
                          <mat-form-field  appearance="outline"><mat-label> filetype : </mat-label> 
                            <input matInput formControlName="filetype" />
                          </mat-form-field>
                    </div>

                    <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> lastChanged : </mat-label> 
                            <input matInput formControlName="lastChanged" />
                          </mat-form-field>
                          <mat-form-field  appearance="outline"><mat-label> by : </mat-label> 
                            <input matInput formControlName="by" />
                          </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> contentStatus : </mat-label> 
                            <input matInput formControlName="contentStatus" />
                          </mat-form-field>
                    </div>
             <p style="color: red;">Press Enter to confirm Deletion</p>
                  <div >
                    <button mat-button class="buttonCss" (click)="invoice()">DELETE</button>
                </div>
              
      </mat-card-content>
    </mat-card>
  </form>  
