import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwHazardMaterialsGroupAddComponent } from './ww-hazard-materials-group-add/ww-hazard-materials-group-add.component';
import { WwHazardMaterialsGroupChangeComponent } from './ww-hazard-materials-group-change/ww-hazard-materials-group-change.component';
import { WwHazardMaterialsGroupDeleteComponent } from './ww-hazard-materials-group-delete/ww-hazard-materials-group-delete.component';

@Component({
  selector: 'app-ww-hazard-materials-group',
  templateUrl: './ww-hazard-materials-group.component.html',
  styleUrls: ['./ww-hazard-materials-group.component.css']
})
export class WwHazardMaterialsGroupComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'HazardMatGroup',
    'ProperName',
    'Class',
    'IdNo',
    'PackingGroup',
    'SpecialRequire',
    'Actions'
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      HazardMatGroup : 2,
      ProperName : 'ORGANOMETALLIC SUBSTANCE LIQUID,WATER',
      Class : '1.3',
      IdNo : '',
      PackingGroup : '',
      SpecialRequire:''
      
    },
    {
      HazardMatGroup : 4,
      ProperName : 'MY LITTLE CHEMICAL LABRATORIES MIX ',
      Class : '2.3',
      IdNo : 'NN 4500',
      PackingGroup : 'II',
      SpecialRequire:'G',
      
    },

  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      IdNo: '',
      ProperName: '',
      Class: '',
      HazardMatGroup:'',
      PackingGroup :''

    })

    this.dataSource = this.duplicateSource;
  }

  addWwHazardMaterialsGroup()
  {
   this.matDialog.open(WwHazardMaterialsGroupAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
  changeWwHazardMaterialsGroup()
  {
   this.matDialog.open(WwHazardMaterialsGroupChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  deleteWwHazardMaterialsGroup()
  {
   this.matDialog.open(WwHazardMaterialsGroupDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

}
