import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PeriodsService {
  url = environment.API_BASE_URL;
  // BASE_URL = 'http://170.249.89.98:5200/api';
  // BASE_URL = 'http://localhost:5200/api';
  constructor(private httpClient: HttpClient) {
    // if (environment.API_BASE_URL) {
    //   var BASE_URL = environment.API_BASE_URL;
    // }
  }
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAll(user: any, storer: any): Observable<any> {
    return this.httpClient.get(
      this.url + `/periods/read?user=${user}&storer=${storer}`
    );
  }

  getAll2(user: any, storer: any, date: any): Observable<any> {
    return this.httpClient.get(
      this.url + `/periods/read?user=${user}&storer=${storer}&date=${date}`
    );
  }

  createPeriod(
    storer: any, //PESTOR
    startDate: any, //PESTDT
    endDate: any, //PEENDT
    balanceWritten: any, //PEBALN
    storageInvc: any, //PEINV#
    status: any, //PESTAT
    user: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/periods/create', {
        storer,
        startDate,
        endDate,
        balanceWritten,
        storageInvc,
        status,
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updatePeriod(
    storer: any, //PESTOR
    startDate: any, //PESTDT
    endDate: any, //PEENDT
    balanceWritten: any, //PEBALN
    storageInvc: any, //PEINV#
    status: any, //PESTAT
    user: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/periods/update', {
        storer,
        startDate,
        endDate,
        balanceWritten,
        storageInvc,
        status,
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deletePeriod(storer: any, user: any, date: any): Observable<any> {
    return this.httpClient.delete(
      this.url + `/periods/delete?storer=${storer}&user=${user}&date=${date}`
    );
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
