import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-order-event-entry',
  templateUrl: './order-event-entry.component.html',
  styleUrls: ['./order-event-entry.component.css'],
})
export class OrderEventEntryComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<OrderEventEntryComponent>
  ) {}
  form!: FormGroup;

  ngOnInit(): void {
    this.form = this.fb.group({
      BillofLoading: '123455235',
      Storer: '1301 Neils Serial Storer',
      Consignee: 'NKOHAN Neil Kohan',
      EventType: '',
      Code: '',
      EventDate: '03/02/2023',
      Time: 774,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
