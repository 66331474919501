<!-- Form No: 287 -->
<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>  
      <mat-card-title >Receivables Transaction Inquiry</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div class="formDiv">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline">
                            <mat-label>Invoice Number</mat-label>
                            <input matInput formControlName="InvoiceNumber"/>
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>Ref No</mat-label>
                            <input matInput formControlName="RefNo"/>
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <input matInput formControlName="Status"/>
                          </mat-form-field>
                    </div>
    
                    <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Invoice Amount</mat-label>
                        <input matInput formControlName="InvoiceAmount"/>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Adj Amount</mat-label>
                        <input matInput formControlName="AdjAmount"/>
                      </mat-form-field>

                      <mat-form-field appearance="outline">
                        <mat-label>Trans Type</mat-label>
                        <input matInput formControlName="TransType"/>
                      </mat-form-field>
                    </div>   

                    <div class="col">

                        <mat-form-field appearance="outline">
                            <mat-label>Invoice Date</mat-label>
                            <input matInput formControlName="InvoiceDate"/>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>Transaction Date</mat-label>
                          <input matInput formControlName="TransactionDate"/>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>Due Date</mat-label>
                          <input matInput formControlName="DueDate"/>
                        </mat-form-field>
                    </div>  

                    <div class="col">
                       
                
                        <mat-form-field appearance="outline">
                          <mat-label>Description</mat-label>
                          <input matInput formControlName="Description"/>
                        </mat-form-field>
      
                      
                    </div>
                </div>
            </form>
        </div>

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Originating Customer">
              <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
                Originating Customer
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.OriginatingCustomer }}</td>
            </ng-container>
      
            <ng-container matColumnDef="Bill to Customer">
              <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
                Bill to Customer
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.BilltoCustomer }}</td>
            </ng-container>
      
         

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            
        </table>

        <table mat-table [dataSource]="dataSource1" class="mat-elevation-z8 tableCss">
            <ng-container matColumnDef="St">
              <th mat-header-cell mat-header-cell *matHeaderCellDef>
                St
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.St }}</td>
              <td *matFooterCellDef></td>
            </ng-container>
      
            <ng-container matColumnDef="Sequence No.">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Sequence No
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.SequenceNo }}</td>
              <td *matFooterCellDef></td>
            </ng-container>
      
            <ng-container matColumnDef="Bld No.">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Bld No
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.BldNo }}</td>
              <td *matFooterCellDef></td>
            </ng-container>
      
            <ng-container matColumnDef="Ledger">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Ledger
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.Ledger }}</td>
              <td *matFooterCellDef></td>
            </ng-container>
      
            <ng-container matColumnDef="Description">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Description
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
                <td *matFooterCellDef>Paid</td>
            </ng-container>

            <ng-container matColumnDef="Paid Amount">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Paid Amount
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.PaidAmount }}</td>
              <td *matFooterCellDef>$.00</td>
            </ng-container>

            <ng-container matColumnDef="AJ">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    AJ
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.AJ }}</td>
                <td *matFooterCellDef>Due:</td>
            </ng-container>

            <ng-container matColumnDef="Original Amount">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    OriginalAmount
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.OriginalAmount }}</td>
                <td *matFooterCellDef>$ 1155.00</td>
            </ng-container>
            

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1" ></tr>
            <tr mat-footer-row class="sticky-footer" *matFooterRowDef="displayedColumns1"></tr>
            
        </table>
    </mat-card-content>
