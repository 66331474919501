import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-labor-plan-change',
  templateUrl: './ww-labor-plan-change.component.html',
  styleUrls: ['./ww-labor-plan-change.component.css']
})
export class WwLaborPlanChangeComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user = 'NKOHAN';

  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwLaborPlanChangeComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      DocumentNumber :'',
      Date :'',
      Location :''

     });
  }

  changeLaborPlan(){
    
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
