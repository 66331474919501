


<!-- <p>
{{ TallyHeaders.type }}
 {{ TallyHeaders.pgm }} 
</p> -->

  <!-- <mat-card-title class="title">{{
      "update" ? "Update Tally" : "Add Tally"
    }}</mat-card-title> -->
  
<mat-card class="over-Flow">
      
    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain Inbound Details</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>  

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">  
        <div class="row">
          <div class="col"> 

            <mat-form-field appearance="outline">
              <mat-label>Sequence </mat-label>
              <input matInput formControlName="sequence" readonly />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Quantity </mat-label>
              <input matInput formControlName="quantity" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Item</mat-label>
              <mat-select formControlName="items">
                <mat-option *ngFor="let item of itemOptions" [value]="item">{{
                  item
                }}</mat-option>
              </mat-select>
            </mat-form-field>
            <div style="margin-left: 20px;">
              <b>Lot Codes:</b>
            </div>
            <mat-form-field appearance="outline">
              <mat-label>Lot 1</mat-label>
              <input
                matInput
                formControlName="lotCode1"/>
            </mat-form-field>
            

            <mat-form-field appearance="outline">
              <mat-label>Lot 2</mat-label> 
              <input matInput 
              formControlName="lotCode2"/>
            </mat-form-field>
            

            <mat-form-field appearance="outline">
              <mat-label>Lot 3</mat-label>
              <input matInput 
              formControlName="lotCode3"/>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Rate Type </mat-label>
              <mat-select  formControlName="rateType">      
                <mat-option value="R">Regular</mat-option>
                <mat-option value="D"> Distribution</mat-option>
                <mat-option value="A">Alternate </mat-option>
              </mat-select>
            </mat-form-field>            

            <mat-form-field appearance="outline">
              <mat-label>Damage Reason</mat-label>
              <input matInput formControlName="dmgAreas" />
            </mat-form-field>           

            <mat-form-field appearance="outline">
              <mat-label> Override weight</mat-label>
              <input matInput formControlName="overrideWeight" />
            </mat-form-field>

          </div>          
        </div>

        <button mat-button class="buttons addButton" (click)="updateTallyDetail()">
          Update
        </button>
        
      </form>
    </mat-card-content>      
</mat-card>
    

  

