import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-operator-group',
  templateUrl: './edit-operator-group.component.html',
  styleUrls: ['./edit-operator-group.component.css'],
})
export class EditOperatorGroupComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<EditOperatorGroupComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      LUNAME: '',
      LUOPER: '',
      LUPRIO: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }
}
