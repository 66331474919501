<mat-card>
    <mat-card-header>  
      <mat-card-title >Work with LTL Batches</mat-card-title>
    </mat-card-header>

    <mat-card-content>

        <div class="formDiv">

          <form [formGroup]="form">
                <div class="row">
                    <div class="col">

                        <mat-form-field appearance="outline">
                            <mat-label>Batch Number</mat-label>
                            <input matInput formControlName="BatchNumber"/>
                          </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

       

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Batch Number">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Batch Number
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.BatchNumber }}</td>
            </ng-container>
      
            <ng-container matColumnDef="SCAC Code">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                SCAC Code
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.SCACCode }}</td>
            </ng-container>

            <ng-container matColumnDef="Number of Shipments">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Number of Shipments
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.NumberofShipments }}</td>
            </ng-container>

            <ng-container matColumnDef="Scheduled Ship Date">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Scheduled Ship Date
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.ScheduledShipDate }}</td>
            </ng-container>

            <ng-container matColumnDef="Changed By">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Changed By
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.ChangedBy }}</td>
            </ng-container>

            <ng-container matColumnDef="Status">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Status
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
            </ng-container>



            <ng-container matColumnDef="Action">
                <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
                    <mat-option>
                        <button mat-icon-button (click)="changeBatch(element)">
                          <mat-label>Change Batch</mat-label>
                        </button>
                    </mat-option>

                    <mat-option>
                        <button mat-icon-button (click)="detailsbatch(element)">
                          <mat-label>Details Batch</mat-label>
                        </button>
                    </mat-option>

                  </mat-select>
                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            
        </table>
    </mat-card-content>
</mat-card>
