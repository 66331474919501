import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrderDetailsService {

    url = environment.API_BASE_URL;

  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getOrderDetails(user: any, billNo:any): Observable<any> {
    return this.httpClient.get(
      this.url + `/orderDetails/read?user=${user}&billNo=${billNo}`
    );
  }

  orderDtlItemStatusService(user: any, itemStorer:any, suffix:any, item:any): Observable<any> {
    return this.httpClient.get(
      this.url + `/orderDetails/itemStatus?user=${user}&itemStorer=${itemStorer}&suffix=${suffix}&item=${item}`
    );
  }

  orderDtlLotStatusService(user: any, storer:any, suffix:any, item:any, billNumber:any,seq:any): Observable<any> {
    return this.httpClient.get(
      this.url + `/orderDetails/lotStatus?user=${user}&storer=${storer}&suffix=${suffix}&item=${item}&billNumber=${billNumber}&seq=${seq}`
    );
  }

  readXDocRef(user: any, bol:any, seq:any, tally:any): Observable<any> {
    return this.httpClient.get(
      this.url + `/orderDetails/crossDocRefRead?user=${user}&billNo=${bol}&seq=${seq}&tally=${tally}`
    );
  }

  createOrderDetailService(
        bol:any,
        seq:any,
			  qty:any,
			  item:any,
			  lot1:any,
			  lot2:any,
			  lot3:any,
			  shipFrom:any,
			  ovrWeight:any,
	      changeReason:any,
	      origLine:any

    ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/orderDetails/create', { 
        bol,
        seq,
			  qty,
			  item,
			  lot1,
			  lot2,
			  lot3,
			  shipFrom,
			  ovrWeight,
	      changeReason,
	      origLine

       })
      .pipe(retry(1), catchError(this.httpError));
  }


  updateOrderDetailService(
    bol:any,
    seq:any,
    qty:any,
    item:any,
    lot1:any,
    lot2:any,
    lot3:any,
    shipFrom:any,
    ovrWeight:any,
    changeReason:any,
    origLine:any

): Observable<any> {
return this.httpClient
  .put<any>(this.url + '/orderDetails/update', { 

    bol,
    seq,
    qty,
    item,
    lot1,
    lot2,
    lot3,
    shipFrom,
    ovrWeight,
    changeReason,
    origLine

   })
  .pipe(retry(1), catchError(this.httpError));
}


deleteOrderDetailService(bol: any,seq:any): Observable<any> {
  return this.httpClient.delete(
    this.url + `/orderDetails/delete?bol=${bol}&seq=${seq}`
  );
}



createOrderDtlCommentService(
  bol:any,
  seq:any,
  comment:any

): Observable<any> {
return this.httpClient
.post<any>(this.url + '/orderDetails/createComment', { 

  bol,
  seq,
  comment

 })
.pipe(retry(1), catchError(this.httpError));
}


orderDetailsCommitService(
  bol:any,
  seq:any,

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/commit', { 

  bol,
  seq

 })
.pipe(retry(1), catchError(this.httpError));
}

orderDetailsDeCommitService(
  bol:any,
  seq:any,

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/deCommit', { 

  bol,
  seq

 })
.pipe(retry(1), catchError(this.httpError));
}


orderDetailsNonStockReasService(
  bol:any,
  seq:any,

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/nonStockReas', { 

  bol,
  seq

 })
.pipe(retry(1), catchError(this.httpError));
}

updateOrderDetailLocService(
  bol:any,
  seq:any,
  location:any,
  lot1:any,
  lot2:any,
  lot3:any

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/chnageLocOrLot', { 

  bol,
  seq,
  location,
  lot1,
  lot2,
  lot3

 })
.pipe(retry(1), catchError(this.httpError));
}


changeShipFormService(
  bol:any,
  seq:any,
  shipInventoryBucket:any

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/changeShipFrom', { 

  bol,
  seq,
  shipInventoryBucket

 })
.pipe(retry(1), catchError(this.httpError));
}


createXDocRefService(
  bol:any,
  seq:any,
  tally: any,
  user: any

): Observable<any> {
return this.httpClient
.post<any>(this.url + '/orderDetails/crossDocRefCreate', { 

  bol,
  seq,
  tally,
  user

 })
.pipe(retry(1), catchError(this.httpError));
}


updateXDocRefService(
  bol:any,
  seq:any,
  tally: any,
  user: any

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/crossDocRefUpdate', { 

  bol,
  seq,
  tally,
  user

 })
.pipe(retry(1), catchError(this.httpError));
}


deleteXDocRef(user: any, bol:any, seq:any): Observable<any> {
  return this.httpClient.delete(
    this.url + `/orderDetails/crossDocRefDelete?user=${user}&billNo=${bol}&seq=${seq}`
  );
}

orderDetailCutService(
  bol:any,
  seq:any,
  reasonCode: any,

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/cut', { 

  bol,
  seq,
  reasonCode

 })
.pipe(retry(1), catchError(this.httpError));
}


orderDetailForceService(
  bol:any,
  seq:any

): Observable<any> {
return this.httpClient
.post<any>(this.url + '/orderDetails/force', { 

  bol,
  seq

 })
.pipe(retry(1), catchError(this.httpError));
}


orderDetailDeleteNosService(bol:any, seq:any): Observable<any> {
  return this.httpClient.delete(
    this.url + `/orderDetails/crossDocRefDelete?billNo=${bol}&seq=${seq}`
  );
}


hardAllocateService(
  bol:any,
  seq:any

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/hardAllocate', { 

  bol,
  seq

 })
.pipe(retry(1), catchError(this.httpError));
}


removeHardAllocateService(
  bol:any,
  seq:any

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/removeHardAllocate', { 

  bol,
  seq

 })
.pipe(retry(1), catchError(this.httpError));
}

splitLineService(
  bol:any,
  seq:any,
  location:any,
  lot1:any,
  lot2:any,
  lot3:any,
  splitQuantity:any

): Observable<any> {
return this.httpClient
.put<any>(this.url + '/orderDetails/splitLine', { 

  bol,
  seq,
  location,
  lot1,
  lot2,
  lot3,
  splitQuantity

 })
.pipe(retry(1), catchError(this.httpError));
}


orderDtlMoveQueueService(
    
  bol:any,
  seq: any,
  qty: any,
  priority:any,
  location:any
  
): Observable<any> {
  return this.httpClient
    .put<any>(this.url + '/orderDetails/movequeue', {
      
      bol,
      seq,
      qty,
      priority,
      location
    })
    .pipe(retry(1), catchError(this.httpError));
}


changeEventService(
    
  bol:any,
  seq: any,
  qty: any,
  item:any,
  lot1:any,
  lot2:any,
  lot3:any,
  shipFrom:any,
  ovrWeight:any,
  changeReason:any,
  origLine:any
  
): Observable<any> {
  return this.httpClient
    .put<any>(this.url + '/orderDetails/changeevent', {
      
      bol,
      seq,
      qty,
      item,
      lot1,
      lot2,
      lot3,
      shipFrom,
      ovrWeight,
      changeReason,
      origLine

    })
    .pipe(retry(1), catchError(this.httpError));
}


changeEventCommentsService(
    
  bol:any,
  seq: any,
  comment1: any,
  print1:any,
  comment2:any,
  print2:any
  
): Observable<any> {
  return this.httpClient
    .put<any>(this.url + '/orderDetails/changeeventcomments', {
      
      bol,
      seq,
      comment1,
      print1,
      comment2,
      print2

    })
    .pipe(retry(1), catchError(this.httpError));
}








  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
