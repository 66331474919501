<button mat-icon-button (click)="closeDialog()">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>
   <mat-card-title style="color: rgb(203, 207, 219)">
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before</mat-icon>
         </button>
       Stock Activity Inquiry</mat-card-title>
    </mat-card-header>
    <mat-card-content>
    <form [formGroup]="form">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="STORE"/>
                </mat-form-field>
                <mat-form-field appearance="outline">
                 <mat-label>Item</mat-label>
                 <input matInput formControlName="ITDSC"/>
             </mat-form-field>
                       
            </div>
 
            <div class="col">
             <mat-form-field appearance="outline">
                 <mat-label>Start</mat-label>
                 <input matInput formControlName="STR"/>
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>End</mat-label>
                 <input matInput formControlName="END"/>
             </mat-form-field>
         
            </div>
 
            <div class="col">
             
             <mat-form-field appearance="outline">
                 <mat-label>Location Adj</mat-label>
                 <input matInput formControlName="LADJS"/>
             </mat-form-field>
         
         </div>
           
     </div>     
    </form>
    </mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Date">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Date
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.DATE}}</td>
          </ng-container>
          <ng-container matColumnDef="Storer">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Storer
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.STORE}}</td>
          </ng-container>
          <ng-container matColumnDef="Reference">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Lot Codes
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.REF}}</td>
          </ng-container>
          <ng-container matColumnDef="Wr/BOL">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Wr/BOL
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.ABTA}}</td>
          </ng-container>
          <ng-container matColumnDef="Description">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Description
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.DESC}}</td>
          </ng-container>
          <ng-container matColumnDef="Amount">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Amount
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.QTY}}</td>
          </ng-container>
 
          <ng-container matColumnDef="Balance">
             <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                 Balance
             </th> 
             <td mat-cell *matCellDef="let element">{{ element.BALNC}}</td>
           </ng-container>
 
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

    
      

        <mat-paginator
        #paginator
        [pageSizeOptions]="[4, 8, 16]"
        showFirstLastButtons
      ></mat-paginator>

