<!-- Form No : 56.8 -->
<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Order Event History</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Bill Of Lading</mat-label>
                    <input matInput formControlName="billOfLading" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="storer" />
                </mat-form-field>
                
             

                <mat-form-field appearance="outline">
                    <mat-label>Consignee</mat-label>
                    <input matInput formControlName="consignee" />
                </mat-form-field>

            </div>

            

        </div>
     
    </form>
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="date">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Date
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.date }}</td>
          </ng-container>

          <ng-container matColumnDef="time">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Time
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.time }}</td>
          </ng-container>

          <ng-container matColumnDef="event">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Event
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.event }}</td>
          </ng-container>

          <ng-container matColumnDef="received">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Received
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.received }}</td>
          </ng-container>

          <ng-container matColumnDef="transmitted">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Transmitted
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.transmitted }}</td>
          </ng-container>

          <ng-container matColumnDef="sts">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                sts
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.sts }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>

    </table>

    <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
</mat-card-content>

