import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-trip-expenses-change',
  templateUrl: './trip-expenses-change.component.html',
  styleUrls: ['./trip-expenses-change.component.css']
})
export class TripExpensesChangeComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<TripExpensesChangeComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      TripNumber:'',
      Date:'',
      Category:this.anyvariable.Category,
      SubCategory:this.anyvariable.SubCategory,
      Description:this.anyvariable.Description,
      Quantity:'',
      Rate:'',
      Amount:this.anyvariable.Amount,
      ReceiptExists:'',
      ClientReimbursable:'',
      OutOfPocket:''
    })
  }

  changeExpense(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
