import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-documents-consignee',
  templateUrl: './documents-consignee.component.html',
  styleUrls: ['./documents-consignee.component.css'],
})
export class DocumentsConsigneeComponent implements OnInit {
  displayedColumns: string[] = [
    'IO',
    'DocumentType',
    'ApplicationName',
    'ValidityProgram',
    'ValidityMessage',
    'File',
    'Status',
  ];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      IO: [null],
      DocumentType: [null],
      ApplicationName: [null],
      ValidityProgram: [null],
      ValidityMessage: [null],
      File: [null],
      Status: [null],
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<DocumentsConsigneeComponent>
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      Consignee: [null],
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  @ViewChild('paginator') paginator!: MatPaginator;
  workHeaders: any = [];

  reasonCode() {
    console.log('Reason Code');
  }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
