import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IitemService } from '../../iitem.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { IItemLocationService } from '../i-item-location.service';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';
@Component({
  selector: 'app-deleteitemlocation',
  templateUrl: './deleteitemlocation.component.html',
  styleUrls: ['./deleteitemlocation.component.css'],
})
export class DeleteitemlocationComponent implements OnInit {
  item: any;
  form!: FormGroup;
  constructor(
    public itemSvc: IItemLocationService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog,

    private CustomAlertService: CustomAlertService,

    private dialogRef: MatDialogRef<DeleteitemlocationComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      storer: this.anyvariable.LCSTOR,
      item: this.anyvariable.LCITEM + '\xa0' + this.anyvariable.desc,
      building: this.anyvariable.LCBLDG,
      floor: this.anyvariable.LCFLOR,
      aisle: this.anyvariable.LCAISL,
      slot: this.anyvariable.LCSLOT,
      lot1: this.anyvariable.LCLTC1,
      lot2: this.anyvariable.LCLTC2,
      lot3: this.anyvariable.LCLTC3,
    });
  }
  result: any;
  deleteItemLocation() {
    var res = this.form.value;
    var x = 4;
    for (var i = res.aisle.length; i < x; i++) {
      res.aisle = ' ' + res.aisle;
    }

    for (var i = res.slot.length; i < x; i++) {
      res.slot = ' ' + res.slot;
    }
    for (var i = res.building.length; i < 3; i++) {
      res.building = ' ' + res.building;
    }
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure you want to delete Item Location : ' +
    '' +
    this.anyvariable.LCITEM +
    '?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
    
      this.result = this.itemSvc
        .deleteItemLocation(
          res.storer,
          this.anyvariable.LCSTRX,
          this.anyvariable.LCITEM,
          res.lot1,
          res.lot2,
          res.lot3,
          res.building,
          res.floor,
          res.aisle,
          res.slot
        )
        .subscribe((data: any) => {
          this.result = data;
          var value = this.result[0].data[11].value;
          console.log(this.result);
          if (value == 'Deleted Successfully') {
            this.successMessage(value);
            this.closeDialog();
            this.router.navigate(['/main-nav/item/read']);
          } else {
            this.failureMessage(value);
            this.closeDialog();
          }
        });
      }
    });
    }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
