import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { GeneralInvoiceDetailsAddComponent } from './general-invoice-details-add/general-invoice-details-add.component';
import { GeneralInvoiceDetailsChangeComponent } from './general-invoice-details-change/general-invoice-details-change.component';
import { GeneralInvoiceDetailsCommentsComponent } from './general-invoice-details-comments/general-invoice-details-comments.component';
import { GeneralInvoiceDetailsDeleteComponent } from './general-invoice-details-delete/general-invoice-details-delete.component';

@Component({
  selector: 'app-ww-general-invoice-details',
  templateUrl: './ww-general-invoice-details.component.html',
  styleUrls: ['./ww-general-invoice-details.component.css']
})
export class WwGeneralInvoiceDetailsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'DGSTAT',
    'DGTASK',
    'DGLEGC',
    'GDESC',
    'DGDOCN',
    'DGQTY',
    'DGRATE',
    'DGAMT',
    'DGMINM',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      DGSTAT:'EN',
      DGTASK:'SPCH',
      DGLEGC:100,
      GDESC:'POSTER METER',
      DGDOCN:1.00,
      DGQTY:'',
      DGRATE:17.5000,
      DGAMT:'$17.50',
      DGMINM:''
    },
    {
      DGSTAT:'EN',
      DGTASK:'SPCH',
      DGLEGC:100,
      GDESC:'OFFICE EQUIPMENT',
      DGDOCN:1.00,
      DGQTY:'',
      DGRATE:36.2500,
      DGAMT:'$34.50',
      DGMINM:''
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      DGCTL:10,
      CSDSC:'1979 TINA',
      HGAMT:'$53.75'
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  add(){
    this.matDialog.open(GeneralInvoiceDetailsAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  change(element:any){
    this.matDialog.open(GeneralInvoiceDetailsChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  delete(element:any)
  {
    this.matDialog.open(GeneralInvoiceDetailsDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  comment(element:any)
  {
    this.matDialog.open(GeneralInvoiceDetailsCommentsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

}
