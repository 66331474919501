<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Order Event History</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Bill of Lading</mat-label>
            <input matInput formControlName="BillofLading" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="Storer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Consignee</mat-label>
            <input matInput formControlName="Consignee" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="Date">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.EVDATE | date : this.GlobalComponent.DATE_FORMAT }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Time">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Time</th>
      <td mat-cell *matCellDef="let element">{{ element.EVTIME }}</td>
    </ng-container>
    <ng-container matColumnDef="Event">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Event</th>
      <td mat-cell *matCellDef="let element">{{ element.EVCODE }}</td>
    </ng-container>
    <ng-container matColumnDef="Received">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Received</th>
      <td mat-cell *matCellDef="let element">
        <div
          *ngIf="
            element.EVRCVT.toString().length == 4;
            then thenBlock;
            else elseBlock
          "
        ></div>
        <ng-template #thenBlock
          >{{ element.EVRCVD + "  " + element.EVRCVT.toString().slice(0, 2) }} :
          {{ element.EVRCVT.toString().slice(2) }}</ng-template
        >
        <ng-template #elseBlock
          >{{
            element.EVRCVD
              | date
                : this.GlobalComponent.DATE_FORMAT +
                    "  " +
                    element.EVRCVT.toString().slice(0, 1)
          }}
          : {{ element.EVRCVT.toString().slice(1) }}</ng-template
        >
      </td>
    </ng-container>
    <ng-container matColumnDef="Transmitted">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Transmitted</th>
      <td mat-cell *matCellDef="let element">{{ element.Transmitted }}</td>
    </ng-container>
    <ng-container matColumnDef="Sts">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.EVSTAT }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
