import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateCarriersComponent } from '../../carriers/create-carriers/create-carriers.component';
import { ICarrierService } from '../../carriers/i-carrier.service';

@Component({
  selector: 'app-extract-reports-add',
  templateUrl: './extract-reports-add.component.html',
  styleUrls: ['./extract-reports-add.component.css']
})
export class ExtractReportsAddComponent implements OnInit {

  constructor(
    private router: Router,
    public icarSvc: ICarrierService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<CreateCarriersComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      extractName: [null],
      description: [null],
      callingProgram: [null],
      outputType: [null],
      ifsLocation: [null],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addExtractReport() {
    console.log('added');
  }

}
