import { ItemBalancesComponent } from './item-balances/item-balances.component';
import { ItemCommentsComponent } from './item-comments/item-comments.component';
import { CommentsComponent } from './../tallys/comments/comments.component';
import { ItemLotsComponent } from './item-lots/item-lots.component';
import { CrossRefItemComponent } from './cross-ref-item/cross-ref-item.component';
import { CreateItemComponent } from './create-item/create-item.component';
import { DeleteItemComponent } from './delete-item/delete-item.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ItemLocationsComponent } from './item-locations/item-locations.component';
import { IitemService } from './iitem.service';
import { ItemUpdate1Component } from './item-update1/item-update1.component';
import { ItemTallyWorkWithComponent } from './item-tally-work-with/item-tally-work-with.component';
import { ItemCrossRefComponent } from './item-cross-ref/item-cross-ref.component';
import { StockEnquiryComponent } from './stock-enquiry/stock-enquiry.component';
import { ItemStatusInquiryComponent } from './item-status-inquiry/item-status-inquiry.component';
import { TableServiceService } from 'src/app/table-service.service';
import { CustomColumn } from 'src/app/table-service.service';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css'],
})
export class ItemComponent implements OnInit {

  selectedRow: any;
  editedRows: any;
  displayedColumns: string[] = [
    'AD',
    'ItemCode',
    'Description',
    'Lot',
    'Bld',
    'UPC',
    'Tail',
    'Actions',
  ];
  otherColumns:string[] = [
    'LotUnitWeight',
    'X-Reference',
    'Allocated',
    'Available',
    'Committed'
  ]
  dataSource: any = [];
  // duplicateSource: any = [
  //   {
  //     AD: '',
  //     ItemCode: 'CPBKLT1',
  //     Description: 'SAMPLE DSP1 BOOKLET',
  //     Lot: 'N',
  //     Bld: 13,
  //     UPC: '',
  //     Tail: '',
  //   },
  //   {
  //     AD: '',
  //     ItemCode: 'CPBKLT2',
  //     Description: 'SAMPLE DSP2 BOOKLET',
  //     Lot: 'N',
  //     Bld: 13,
  //     UPC: '',
  //     Tail: '',
  //   },
  // ];

  // form!: FormGroup;
  @ViewChild('paginator') paginator!: MatPaginator;

  constructor(
    private activeRouter: ActivatedRoute,
    //public iHeaderSvc: IheaderService,
    public tableSvc : TableServiceService,
    public itemService: IitemService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}

  form = this.fb.group({
    storer: '',
    suffix: localStorage.getItem('storerSuffix'),
    itemCode: '',
    description: '',
    product: '',
    onlyActive: 'Y',                                                                        
    inventory: '',
  });

  user = localStorage.getItem('userName');
  result: any;
  duplicateSource: any = [];
  columnShowHideList: CustomColumn[]=[];
  columnsLength : any;
  ngOnInit(): void {

    this.editedRows = [];
    this.columnShowHideList = this.tableSvc.initializeColumnProperties(this.displayedColumns,this.otherColumns);
    
    this.itemService.getItemAll(this.user).subscribe((data) => {
      this.result = data;
      // this.TallyHeaders = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('ITEM DATA');
      console.log(this.duplicateSource);

      this.dataSource = new MatTableDataSource(this.duplicateSource);
      this.dataSource.paginator = this.paginator;
    });
  }

  searchTextDisplay: any;

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  toggleColumn(column: any) {
   
   this.displayedColumns = this.tableSvc.toggleColumn(column,this.displayedColumns); 
  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }


  getItemBySearch(item: any) {
    this.itemService
      .getItemBySearch(this.user, item.storer, item.suffix, item.itemCode)
      .subscribe((data) => {
        this.result = data;
        // this.TallyHeaders = data;
        //   console.log(this.TallyHeaders);
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        // this.dataSource = parsedData.returnedData;
        this.dataSource = new MatTableDataSource(parsedData.returnedData);
        console.log("SINGLE ITEM DATA");
        console.log(parsedData.returnedData);
        this.dataSource.paginator = this.paginator;
      });
  }

  itemCreate() {
    var res = this.form.value;
    this.matDialog.open(CreateItemComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: res,

      autoFocus: false,
    });
  }

  itemUpdate(element: any) {
    this.matDialog.open(ItemUpdate1Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: element,
      autoFocus: false,
    });
  }

  itemDelete(element: any) {
    this.matDialog.open(DeleteItemComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  itemCrossRef(element: any) {
    this.matDialog.open(ItemCrossRefComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  itemLots(element: any) {
    this.matDialog.open(ItemLotsComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }
  itemComments(itemElement: any) {
    this.router.navigateByUrl('/main-nav/item/comments', { state: itemElement });
  }

  itemBalance(element: any) {
    this.matDialog.open(ItemBalancesComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  itemAdjustment(element: any) {
    this.router.navigateByUrl('/main-nav/item/adjustment', { state: element });
    // this.matDialog.open(ItemBalancesComponent, {
    //   width: '1000px',
    //   maxHeight: '80vh',
    //   autoFocus: false,
    // });
  }

  itemInventoryAdjustment(element: any) {
    this.router.navigateByUrl('/main-nav/item/inventory-adjustment');
    // this.matDialog.open(ItemBalancesComponent, {
    //   width: '1000px',
    //   maxHeight: '80vh',
    //   autoFocus: false,
    // });
  }

  itemTally(element: any) {
    this.matDialog.open(ItemTallyWorkWithComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  itemLocation(element: any) {
    this.router.navigateByUrl('/main-nav/item/location', { state: element });
  }

  StockEnquiry(element: any) {
    this.matDialog.open(StockEnquiryComponent, {
      width: '1200px',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }

  itemStatus(status:any){
    this.matDialog.open(ItemStatusInquiryComponent, {
      width: '1200px',
      maxHeight: '95vh',
      data: status,
      autoFocus: false,
    });
  }
  
}
