import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { FormBuilder } from '@angular/forms';
import { IitemService } from '../iitem.service';
import { ItemLotsUpdateComponent } from './item-lots-update/item-lots-update.component';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';
import { GlobalComponent } from 'src/app/Global-Component';
@Component({
  selector: 'app-item-lots',
  templateUrl: './item-lots.component.html',
  styleUrls: ['./item-lots.component.css'],
})
export class ItemLotsComponent implements OnInit {

  user = localStorage.getItem('userName');
  GlobalComponent: any = GlobalComponent;

  building = 13;
  TallyHeaders: any = [];
  duplicateSource = [];
  id: any;
  postingDate: any;
  result: any;
  dataSource: any;
  workHeaders: any = [];

  


displayedColumns: string[] = [
    'Sts',
    'LotCodes',
    'LotUnitWeight',
    'FirstReceived',
    'LastShipped',
    'Actions',
  ];


  form = this.fb.group({
    storer: this.anyvariable.ITSTOR,
    suffix: this.anyvariable.ITSSFX,
    item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
    lot1: '',
    lot2: '',
    lot3:''
  });
  //paginator: any;


  constructor(
    private activeRouter: ActivatedRoute,
    public itemSvc: IitemService,
    private router: Router,
    private matDialog: MatDialog,
    
    

    private CustomAlertService: CustomAlertService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemLotsComponent>
  ) {}

  ngOnInit(): void {
    this.readItemLot();
    
  }

  @ViewChild('paginator') paginator!: MatPaginator;
 

  readItemLot() {
    var res = this.form.value;
    this.TallyHeaders = this.itemSvc
      .readItemLotService(this.user, res.storer, res.suffix, this.anyvariable.ITEM, res.lot1 , res.lot2, res.lot3)
      .subscribe((data) => {
        this.result = data;
        this.TallyHeaders = data;
        var res = this.result[0].data[1]?.value;

        var parsedData = JSON.parse(res);
        this.dataSource = parsedData.returnedData;
        this.duplicateSource = this.dataSource;
        console.log(this.dataSource);

        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  itemLots() {
    console.log('Lots');
  }

  updateItemLots(element: any) {
    this.matDialog.open(ItemLotsUpdateComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: element,
      autoFocus: false,
    });
  }

  deleteItemLot(lot:any) {
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete maplocation ');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
    
    var res = this.form.value;   
    this.result = this.itemSvc
    
   .deleteItemLotsService(this.user,  lot.LTSTOR, lot.LTSSFX, lot.LTITEM, lot.LTLOT1 , lot.LTLOT2 , lot.LTLOT3)
      .subscribe((data) => {
        this.result = data;
        this.result = data;
        var res = this.result[0].data[1]?.value;

        var parsedData = JSON.parse(res);
        this.dataSource = parsedData.returnedData;
        this.duplicateSource = this.dataSource;
        console.log(this.dataSource);
        if(JSON.parse(this.result[0].data[1].value).success){
          //alert('Record Created SuccessFully');
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          //this.router.navigate(['/main-nav/item/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
    }
  });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    //this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }


  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
