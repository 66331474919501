import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-customer-update',
  templateUrl: './customer-update.component.html',
  styleUrls: ['./customer-update.component.css'],
})
export class CustomerUpdateComponent implements OnInit {
  updateCustomerForm!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<CustomerUpdateComponent>,
    private router: Router,
    private fb: FormBuilder
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.updateCustomerForm = this.fb.group({
      CUST: [this.data.CUST],
      CUNAME: [this.data.CUNAME],
      CUADD1: [this.data.CUADD1],
      CUZIP: [this.data.CUZIP],
      CUPHAR: [this.data.CUPHAR],
      CUFXAR: [this.data.CUFXAR],
      CUCPAR: [null],
      CUSORT: [null],
      CUSTPR: [null],
    });
  }
}
