import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discrepancies-inventory-tags',
  templateUrl: './discrepancies-inventory-tags.component.html',
  styleUrls: ['./discrepancies-inventory-tags.component.css']
})
export class DiscrepanciesInventoryTagsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
