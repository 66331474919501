import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IItemLocationService {
  url = environment.API_BASE_URL;
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getItemLocation(user: any,building: any,storer: any,item: any): Observable<any> {
    return this.httpClient.get(
      this.url + 
        `/itemLocation/read?user=${user}&building=${building}&storer=${storer}&item=${item}`);
  }
  getItemLocationBySearch(user:any,building:any,floor:any,aisle:any,slot:any,item:any,storer:any,lot1:any,lot2:any,lot3:any){
    return this.httpClient.get(
      this.url + `/itemLocation/read?user=${user}&building=${building}&floor=${floor}&aisle=${aisle}&slot=${slot}&item=${item}&storer=${storer}&lot1=${lot1}&lot2=${lot2}&lot3=${lot3}`
    )
  }
  createItemLocation(
    buildingnumber: any,
    floor: any,
    aisle: any,
    slot: any,
    storer: any,
    suffix: any,
    item: any,
    lot1: any,
    lot2: any,
    lot3: any,
    lotUnitWeight: any,
    lotFirstReceived: any,
    sortSeq: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/itemLocation/create', {
        buildingnumber,
        floor,
        aisle,
        slot,
        storer,
        suffix,
        item,
        lot1,
        lot2,
        lot3,
        lotUnitWeight,
        lotFirstReceived,
        sortSeq,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteItemLocation(
    storeNumber: any,
    suffix: any,
    itemCode: any,
    lotCode1: any,
    lotCode2: any,
    lotCode3: any,
    buildingNumber: any,
    floor: any,
    aisle: any,
    slot: any
  ): Observable<any> {
    return this.httpClient.delete(
      this.url +
        `/itemLocation/delete?storeNumber=${storeNumber}&suffix=${suffix}&itemCode=${itemCode}&lotCode1=${lotCode1}&lotCode2=${lotCode2}&lotCode3=${lotCode3}&buildingNumber=${buildingNumber}&floor=${floor}&aisle=${aisle}&slot=${slot}`
    );
  }

  ItemLocOneSideAdj(
    storer: any,
    suffix: any,
    itemCode: any,
    lot1: any,
    lot2: any,
    lot3: any,
    bldgNum: any,
    floor: any,
    aisle: any,
    slot: any,
    inventoryAdjBucket:any,
    adjQuantity:any,
    reasonCode:any,
    referToBill:any,
    number:any,
    line:any,
    comment:any

  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/itemLocation/itemLocOneSideAdgForm1', {
       storer,
    suffix,
    itemCode,
    lot1,
    lot2,
    lot3,
    bldgNum,
    floor,
    aisle,
    slot,
    inventoryAdjBucket,
    adjQuantity,
    reasonCode,
    referToBill,
    number,
    line,
    comment

      })
      .pipe(retry(1), catchError(this.httpError));
  }


  ItemLocTwoSideAdj(
    storer: any,
    suffix: any,
    itemCode: any,
    lot1: any,
    lot2: any,
    lot3: any,
    bldgNum: any,
    floor: any,
    aisle: any,
    slot: any,
    inventoryAdjBucket:any,
    adjQuantity:any,
    reasonCode:any,
    referToBill:any,
    number:any,
    line:any,
    comment:any,
    reasonCodeDef:any,
    toItem:any,
    toBucket:any,
    toLot1:any,
    toLot2:any,
    toLot3:any,
    bldg:any,
    toFloor:any,
    toAisle:any,
    toSlot:any,
    toSortSeq:any

  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/itemLocation/itemLocTwoSideAdgForm1', {
       storer,
    suffix,
    itemCode,
    lot1,
    lot2,
    lot3,
    bldgNum,
    floor,
    aisle,
    slot,
    inventoryAdjBucket,
    adjQuantity,
    reasonCode,
    referToBill,
    number,
    line,
    comment,
    reasonCodeDef,
    toItem,
    toBucket,
    toLot1,
    toLot2,
    toLot3,
    bldg,
    toFloor,
    toAisle,
    toSlot,
    toSortSeq
    
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  twoSidedAdjBucktoBuckService(
    storer: any,
    suffix: any,
    itemCode: any,
    lot1: any,
    lot2: any,
    lot3: any,
    bldgNum: any,
    floor: any,
    aisle: any,
    slot: any,
    adjQuantity:any,
    fromBucket:any,
    toBucket:any,
    referToBill:any,
    whseNumber:any,
    seqLine:any,
    reasonCode:any,
    reasonCodeDef:any,
    comments:any

  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/itemLocation/itemLoc2SideBuckToBuck', {
        storer,
        suffix,
        itemCode,
        lot1,
        lot2,
        lot3,
        bldgNum,
        floor,
        aisle,
        slot,
        adjQuantity,
        fromBucket,
        toBucket,
        referToBill,
        whseNumber,
        seqLine,
        reasonCode,
        reasonCodeDef,
        comments
   
    
      })
      .pipe(retry(1), catchError(this.httpError));
  }




  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
