import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-metrics-definations-update',
  templateUrl: './metrics-definations-update.component.html',
  styleUrls: ['./metrics-definations-update.component.css']
})
export class MetricsDefinationsUpdateComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;

  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MetricsDefinationsUpdateComponent>
  ) 
  { }

  ngOnInit(): void {
    this.form = this.fb.group({
      METRIC:'MtrInApptActive',
      DESCRIPT:'Inbound Appointments Active',
      PROGRAM:'MRP10R',
      TYPE:'ONDEMAND',
      FRSHPERD:'3600',
      STATUS:'AC'
      
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}


