export class GlobalComponent {
 
    public static user: string = "NKOHAN";
    public static building: number = 13; 
    public static DATE_FORMAT='MM/dd/yyyy';
      
    // if(localStorage.getItem('LoggedIn')){
    //     this.user: string = localStorage.getItem('userName');
    //     this.building: number = localStorage.getItem('building');
    // }else{

    //     this.user: string = "NKOHAN";
    //     this.building: number = 13;

    // }

    // static fullStorer(input:any):string{

    //     var res = String(input);
    // if(res!=null || res !='' || res != undefined){
    //     for (var i = input.length; i < 4; i++) {
    //         res = '0' + res;
    //     }
    // }
    // console.log('FULL STORER');
    // console.log(res);
        
    //     return res;
        
    // }


   static fullBuilding(input:any):string{

        var res = input;
    for (var i = input.length; i < 3; i++) {
         res = ' ' + res;
    }
        
        return res;
    }

    static fullSlotAisl(input:any):string{

        var res = input;
    for (var i = input.length; i < 4; i++) {
         res = ' ' + res;
    }
        
        return res;
    }

    static fullHrAndMin(input:any):string{

        var inputString : string = input.toString();
        var output : string = "";
        if(inputString.length <= 3){  

            for (let i = 0; i < inputString.length; i++) {
                output += inputString[i];
                if (i === 1 && inputString.length === 3) {
                    output += ":";
                }
            }

        } else if(inputString.length == 4){
            
            for (let i = 0; i < inputString.length; i++) {
                output += inputString[i];
                if (i === 2 && inputString.length === 4) {
                    output += ":";
                }
            }
        }
        
        return output;
    }

 
}