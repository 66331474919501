<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Inventory Adjustment Inquiry </mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col"> 
        <mat-form-field appearance="outline">
          <mat-label> Storer </mat-label>
          <input matInput formControlName="storer" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Item </mat-label>
          <input matInput formControlName="item" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Lot </mat-label>
          <input matInput formControlName="lot" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Location </mat-label>
          <input matInput formControlName="Location" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Adjustment Quantity </mat-label>
          <input matInput formControlName="adjustmentQuantity" readonly />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Inventory Bucket </mat-label>
          <input matInput formControlName="inventoryBucket" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Date & Time </mat-label>
          <input matInput  formControlName="date_Time" readonly value={{dateAndTime}}/>
        </mat-form-field>
        <!-- <mat-form-field appearance="outline">
          <mat-label> Time: </mat-label>
          <input matInput type="time" formControlName="Time" readonly />
        </mat-form-field> -->
        <mat-form-field appearance="outline">
          <mat-label> Change User ID </mat-label>
          <input matInput formControlName="changeUserID" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> WR or Bill Number </mat-label>
          <input matInput formControlName="wRorBillNumber" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Serial </mat-label>
          <input matInput formControlName="serial" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Reason for Adjustment </mat-label>
          <input matInput formControlName="reasonforAdjustment" readonly />
        </mat-form-field>
      
        <div style="margin-left:20px"><b>Comments</b></div>
        <hr />
    </div>
  </div>
  <!-- <div class="btn-text-right">
    <button mat-button class="button addButton"(click)="attributes()">Inquiry</button>
  </div> -->

</form>
</mat-card-content>
</mat-card>

<!--
  Items - Item Adjustment - Item Adjustment Inquiry
  
  Items - Inventory Adjustment - Adjustment Inquiry(display) form and above form are same.
-->
      
