<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">
      <button class="backButton" mat-button (click)="onBack()">
        <mat-icon>navigate_before </mat-icon>
      </button>
      Work With System Comments</mat-card-title>
    <div class="buttons">
      <button mat-button (click)="searchTally()" class="addButton" *ngIf="searchTextDisplay">
        <mat-icon>clear</mat-icon>
      </button>
      <mat-menu #mainMenuButton="matMenu">
        <span (click)="$event.stopPropagation()" *ngFor="let column of columnShowHideList; index as i">
          <mat-slide-toggle style="color: blue" [(ngModel)]="column.isActive" (change)="toggleColumn(column)">
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button mat-button (click)="searchTally()" class="addButton" *ngIf="!searchTextDisplay"
        matTooltip="Search Storer">
        <mat-icon>search</mat-icon>
      </button>

      <button mat-button (click)="storerCommentCreate()" class="addButton buttons"
        matTooltip="Create New Storer">
        <mat-icon>add</mat-icon>
        
      </button>
      <!-- <button mat-button (click)="storerContactCreate(this.ctcons)" class="addButton buttons" matTooltip="Add New Storer">
        <mat-icon>add</mat-icon>
        
      </button> -->
      <button mat-button matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn" #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton">
        <mat-icon>visibility_off</mat-icon>
      </button>
    </div>

  </mat-card-header>

  <mat-card-content class="mt-4">

    <form [formGroup]="form" (ngSubmit)="searchStorerComment(form.value)" *ngIf="searchTextDisplay"
      class="form over-Flow">

      <div class="col">


        <mat-form-field appearance="outline">
          <mat-label>File ID</mat-label>
          <input readonly matInput formControlName="fileId" />
        </mat-form-field>




        <mat-form-field appearance="outline">
          <mat-label>Key</mat-label>
          <input readonly matInput formControlName="key" />
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label> Sub Type: </mat-label>
          <input matInput formControlName="subType" />
        </mat-form-field>

        <button class="buttons addButton" mat-raised-button type="submit" placeholder="Search">
          Search
        </button>
      </div>

    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
    <!-- Tally Column -->
    <ng-container matColumnDef="ID">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        ID
      </th>
      <td mat-cell *matCellDef="let element">{{ element.CMRECD }}</td>
    </ng-container>

    <ng-container matColumnDef="Key">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Key
      </th>
      <td mat-cell *matCellDef="let element">{{ element.CMKEY }}</td>
    </ng-container>

    <ng-container matColumnDef="Tp">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Sub Type
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CMSUBD }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Seq">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Sequence
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CMSEQ }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Comment">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Comment
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CMCOMM }}
      </td>
    </ng-container>
    <ng-container matColumnDef="LastMaintained">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Last Maintained
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CMDATE }} {{element.CMTIME}}
      </td>
    </ng-container>
    <ng-container matColumnDef="By">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        By
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CMUSER }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Job">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Job
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CMWSID }}
      </td>
    </ng-container>
   

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
        <!-- Actions -->
      </th>

      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)=" storerCommentUpdate(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)=" storerCommentDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>

          </ul>
        </div>

    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
        #paginator
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator> -->
  <mat-paginator #paginator [pageSizeOptions]="[3, 6, 12]" showFirstLastButtons
    class="tally-table-pagination"></mat-paginator>
</mat-card>