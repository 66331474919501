import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import Swal from 'sweetalert2';
import { IitemService } from '../../iitem.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-item-adjustment-delete',
  templateUrl: './item-adjustment-delete.component.html',
  styleUrls: ['./item-adjustment-delete.component.css']
})
export class ItemAdjustmentDeleteComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public itemSvc: IitemService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router:Router,
   private dialogRef: MatDialogRef<ItemAdjustmentDeleteComponent>
   ) 
   { }

  ngOnInit(): void {
    this.form = this.fb.group({
  
    Storer: this.anyvariable.AJSTOR,
    ItemCode: this.anyvariable.AJITEM,
    Description: ''

  
       
    });
  }

  closeDialog(){
    // Write your stuff here
   this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  DeleteItemAdjustment(){
    if (
      confirm(
        'Are you sure you want to delete Item Adjustment : ' +
          '' +
          this.anyvariable.AJTRAN +
          '?'
      )
    ) {
      this.result = this.itemSvc
        .deleteItemAdjustment(
          this.anyvariable.AJTRAN,
          
        )
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);
          var value = this.result[0].data[4].value;

          if (this.result[0].data[this.result[0].data.length-1].value=='') {
            const msg = value;

            this.successMessage(msg);
            this.reloadCurrentRoute();
          } else {
            const msg = value;

            this.failureMessage(msg);
            this.reloadCurrentRoute();
          }
        });
    }
  }
  reloadCurrentRoute() {
    this.router.navigate([['/main-nav/item/read']]);
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

}
