import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { LaborProcessingAddComponent } from './labor-processing-add/labor-processing-add.component';
import { LaborProcessingChangeComponent } from './labor-processing-change/labor-processing-change.component';
import { LaborProcessingCopyComponent } from './labor-processing-copy/labor-processing-copy.component';
import { LaborProcessingDeleteComponent } from './labor-processing-delete/labor-processing-delete.component';

@Component({
  selector: 'app-ww-labor-processing',
  templateUrl: './ww-labor-processing.component.html',
  styleUrls: ['./ww-labor-processing.component.css']
})
export class WwLaborProcessingComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'BuildingNumber',
    'StorerNumber',
    'Process',
    'Step',
    'Task',
    'OperatorGroup',
    'MoveTp',
    'MQ',
    'ResponsibleProfile',
    'ST',
    'Action'
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      BuildingNumber:'13',
      StorerNumber:'1',
      Process:'2',
      Step:'JAMES JAMES',
      Task:'2-3-2001',
      OperatorGroup:'2-4-2001',
      MoveTp:'1',
      MQ: '12',
      ResponsibleProfile : '10',
      ST : '10'
    },

    {
      BuildingNumber:'15',
      StorerNumber:'11',
      Process:'2',
      Step:'JAMES JAMES',
      Task:'2-3-2001',
      OperatorGroup:'2-4-2001',
      MoveTp:'1',
      MQ: '12',
      ResponsibleProfile : '10',
      ST : '10'
    },

  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      
      BuildingNumber:'',
      StorerNumber:''

    })

    this.dataSource = this.duplicateSource;
  }
  addLaborProcessing()
  {
   this.matDialog.open(LaborProcessingAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  changeLaborProcessing(element:any)
  {
   this.matDialog.open(LaborProcessingChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      
      "data":element,
      "autoFocus": false
    });
  }
  
  deleteLaborProcessing(element:any)
  {
   this.matDialog.open(LaborProcessingDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  copyLaborProcessing(element:any)
  {
   this.matDialog.open(LaborProcessingCopyComponent, {
      "width": '1000px',
      "maxHeight": '80vh', 
      "data":element,
      "autoFocus": false
    });
  }

}
