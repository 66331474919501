<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Resequence Item Locations</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

  <!-- <mat-card-content>
    <form [formGroup]="form">
      
      &nbsp;
    </form>
  </mat-card-content> -->

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="SequenceNumber">
      <th mat-header-cell *matHeaderCellDef>Sequence Number</th>
      <td mat-cell *matCellDef="let element">{{ element.SequenceNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="Location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element">{{ element.Location }}</td>
    </ng-container>

    <ng-container matColumnDef="Lot">
      <th mat-header-cell *matHeaderCellDef>Lot</th>
      <td mat-cell *matCellDef="let element">{{ element.Lot }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
