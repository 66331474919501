<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
        <mat-card-title>Order Comments Maintenance</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="fill">
                    <mat-label>Order</mat-label>
                    <input matInput formControlName="order" />
                </mat-form-field>
            </div>
        </div>

        <div formArrayName="comments">
            
            <div *ngFor="let comment of comments().controls; let i=index" [formGroupName]="i">

                <div class="row">
                  <div class="col">
                        <mat-form-field appearance="outline" class="full-width w-100">
                            <mat-label>Comment :</mat-label>
                            <input matInput formControlName="comment">
                        </mat-form-field> 
                   
                        <mat-form-field appearance="outline" class="formfields">  
                            <mat-label> Print  </mat-label> 
                            <mat-select matNativeControl >
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                            <!-- <input matInput formControlName="print" /> -->
                        </mat-form-field>
     
                        <mat-form-field appearance="outline" class="formfields">
                            <mat-label> Form:  </mat-label>
                            <input matInput formControlName="form">
                        </mat-form-field>
                 
                
                        <mat-form-field appearance="outline" class="formfields">
                            <mat-label> Type:</mat-label>
                            <input matInput formControlName="type">
                        </mat-form-field>   

                        <button mat-button (click)="addComments()" class="buttons addButton">
                            <mat-icon>add</mat-icon>
                            <span>Comment</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    </mat-card-content>
    <!--
    <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
        <ng-container matColumnDef="Comments">
          <th mat-header-cell *matHeaderCellDef>Comments</th>
          <td mat-cell *matCellDef="let element">{{ element.CISMNT }}</td>
        </ng-container>

        <ng-container matColumnDef="Print">
            <th mat-header-cell *matHeaderCellDef>Print</th>
            <td mat-cell *matCellDef="let element">{{ element.CISMNT }}</td>
        </ng-container>

        <ng-container matColumnDef="Form">
            <th mat-header-cell *matHeaderCellDef>Form</th>
            <td mat-cell *matCellDef="let element">{{ element.CISMNT }}</td>
        </ng-container>

        <ng-container matColumnDef="Type">
            <th mat-header-cell *matHeaderCellDef>Type</th>
            <td mat-cell *matCellDef="let element">{{ element.CISMNT }}</td>
        </ng-container>
        
        <ng-container matColumnDef="Actions">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select placeholder="Select Option">
              <mat-option>
                <button mat-button (click)="updateTallyComment(element)">
                  <mat-icon mat-button color="primary">edit</mat-icon>
                  <mat-label>Edit</mat-label>
                </button></mat-option
              >
    
              <mat-option>
                <button mat-button (click)="deleteTallyComment()">
                  <mat-icon mat-button color="warn"
                    >delete{{ element.BUILD }}</mat-icon
                  >
                  <mat-label>Delete {{ element.no }}</mat-label>
                </button></mat-option
              >
              
            </mat-select>
          </td>
    
    
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
      #paginator
      [pageSizeOptions]="[8, 16, 24]"
      showFirstLastButtons class="tally-table-pagination"
    ></mat-paginator> -->

</mat-card>