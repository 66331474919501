
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service'
import { WwValidationEventsDeleteComponent } from './ww-validation-events-delete/ww-validation-events-delete.component';
import { WwValidationEventsErrordetailsComponent } from './ww-validation-events-errordetails/ww-validation-events-errordetails.component';
@Component({
  selector: 'app-ww-validation-events',
  templateUrl: './ww-validation-events.component.html',
  styleUrls: ['./ww-validation-events.component.css']
})
export class WwValidationEventsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'Opt',
    'Storer',
    'Consignee',
    'Type',
    'Number',
    'Seq Storer Reference No',
    'Actions'
    
    
    
  ];
  dataSource:any=[]


  duplicateSource:any =[
    {
      OPTION:'_',
      EESTOR:'EEV1410',
      MSGTX:'No Details Recieved',
      EEDOCT:'214D',
      EEDOCN:'2460',
      EEDSEQ:'10',
     
        
    },
   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      STOR :'',
      CONS:'',
      DOCT:'',
      DOCN:'',
      SREF:'',
      MSGD:'',
      SEVR:'',
      
     

     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/validation-events/read']);
 
}
 
  ValiationEventErrorDetailsForm(element:any){
     this.matDialog.open( WwValidationEventsErrordetailsComponent, {
        "width": '1000px',
   "maxHeight": '90vh',
    'data': element,
  "autoFocus": false                                
   });
  }

ValidationEventDeleteForm(element:any){
   this.matDialog.open( WwValidationEventsDeleteComponent, {
         "width": '1000px',
 "maxHeight": '90vh',
  'data': element,
"autoFocus": false                                
 });
 }




  
            }

            // addAttribute(){

            // }

            // df    
          
