<mat-card>

    <div class="main-header-title">
      <mat-card-header>
          <mat-card-title fxLayoutAlign="center">Work with Storer Attributes</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" fxLayoutAlign="center">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="Storer" />
                </mat-form-field>
                
            </div>  
        </div>     
      </form>
    </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="Attribute">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            IO
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Attribute }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Value">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Value
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Value }}</td>
          </ng-container>

        <ng-container matColumnDef="Action">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
         <td mat-cell *matCellDef="let element">
          <div class="dropdown more_options_btn">
            <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <mat-icon>more_vert</mat-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li></li>
            </ul>
          </div>  
         </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>

  

</mat-card>