import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IheaderService } from '../iheader.service';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  _closeDialogVia,
} from '@angular/material/dialog';
import { GlobalComponent } from 'src/app/Global-Component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

//  export interface TallyPosting {
//   tally: number;
//   sequence: number;
//   severity: string;
//   message: string;
//   item : string;
//   code: string;
//   intransit: string;
//   receive: string;
// }

@Component({
  selector: 'app-tally-posting',
  templateUrl: './tally-posting.component.html',
  styleUrls: ['./tally-posting.component.css'],
})
export class TallyPostingComponent implements OnInit {
  displayedColumns: string[] = [
    'tally',
    'sequence',
    'severity',
    'message',
    'item',
    'code',
    'intransit',
    'receive',
  ];
  dataSource: any = [];
  duplicateSource: any = [
    {
      tally: 123,
      sequence: 1236,
      severity: '',
      message: '',
      item: '',
      code: '',
      intransit: '',
      receive: 'recieved',
    },
    {
      tally: 1234,
      sequence: 1436,
      severity: 'severity',
      message: 'message',
      item: 'item',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
    {
      tally: 1234,
      sequence: 1436,
      severity: 'severity',
      message: 'message',
      item: 'item',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
    {
      tally: 1234,
      sequence: 1436,
      severity: 'severity',
      message: 'message',
      item: 'item',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
    {
      tally: 1234,
      sequence: 1436,
      severity: 'severity',
      message: 'message',
      item: 'item',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
    {
      tally: 1234,
      sequence: 1436,
      severity: 'severity',
      message: 'message',
      item: 'item',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
    {
      tally: 1234,
      sequence: 1436,
      severity: 'severity',
      message: 'message',
      item: 'item',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
    {
      tally: 1234,
      sequence: 1436,
      severity: 'severity',
      message: 'message',
      item: 'item',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
    {
      tally: 1234,
      sequence: 1436,
      severity: 'severity',
      message: 'message',
      item: 'item',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
    {
      tally: 1189075,
      sequence: 1000,
      severity: 'severity',
      message: 'new message',
      item: 'item 1',
      code: 'code',
      intransit: 'intransit',
      receive: 'receive',
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    public iHeaderSvc: IheaderService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<TallyPostingComponent>
  ) {}

  id: any;
  postingDate: any;
  result: any;
  //user= GlobalComponent.user;
  user = localStorage.getItem('userName');

  @ViewChild('paginator') paginator!: MatPaginator;

  TallyHeaders: any = [];
  //id:number=0;
  ngOnInit(): void {
    console.log("any",this.anyvariable);
    
    this.posting()
    // this.posting();
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  posting() {
    // this.id=this.activeRouter.snapshot.params['id'];
    console.log('tally Number ::' + this.id);

    this.iHeaderSvc
      .getTallyPostErrors(this.user, this.anyvariable)
      .subscribe((data) => {
        console.log('DATA');
        console.log(data);
        this.result = data;
        //  this.TallyHeaders = data;

        var res = data;
        //  console.log(res);
        var res = this.result[0].data[1]?.value;
        console.log(res);
        console.log('GET ALL DATA');
        var parsedData = JSON.parse(res);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
