<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>Record Storage Labor Processing Maintenance</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Building  : </mat-label> 
                    <input matInput formControlName="Building"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Storer: </mat-label> 
                    <input matInput formControlName="Storer"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Process : </mat-label> 
                    <input matInput formControlName="Process"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Group : </mat-label> 
                    <input matInput formControlName="Group"/>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Account Step : </mat-label> 
                    <input matInput formControlName="AccountStep"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Move Type: </mat-label> 
                    <input matInput formControlName="MoveType" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Condition : </mat-label> 
                    <input matInput formControlName="Condition"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Task </mat-label> 
                    <input matInput formControlName="Task"/>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Responsible : </mat-label> 
                    <input matInput formControlName="Responsible"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Move Queue Transaction: </mat-label> 
                    <input matInput formControlName="MoveQueueTransaction" />
                </mat-form-field>
            </div>
            <div class="col">
                <button mat-button (click)="addLaborProcessing()" class="addButton">
                    
                    <span>create</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>


