import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';


@Component({
  selector: 'app-ww-inbound-details-purchase-ords',
  templateUrl: './ww-inbound-details-purchase-ords.component.html',
  styleUrls: ['./ww-inbound-details-purchase-ords.component.css']
})
export class WwInboundDetailsPurchaseOrdsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [    
    'PO Number',
    'Line',
    'Applied',
    'ST',   
    
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      P6PO:'',
      P6LINE:'',
      P6QTYA:'',
      P6STAT:'',     
        
    }, 
  ]
  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwInboundDetailsPurchaseOrdsComponent>
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      P6TALY:'1157813', 
      P6SEQ:'100',
      IDQREC:'',
      QTYA:'',
      QTYD:'',     
      
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/WwInboundDetails/read']);
 
}
  closeDialog(){
    this.dialogRef.close();
  }

} 
