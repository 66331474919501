import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-customer-rating-pref-add',
  templateUrl: './customer-rating-pref-add.component.html',
  styleUrls: ['./customer-rating-pref-add.component.css']
})
export class CustomerRatingPrefAddComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CustomerRatingPrefAddComponent>
   ) 
   { }

  ngOnInit(): void {
    this.form = this.fb.group({
  
    customerNumber: '',
    stateCode:'',
    tariffName:'',
    originZone:'',
    fromZip:'',
    toZip:'',
    scacCode:'',
    discount:'',
    interStateMin:'$.00',
    intraStateMin:'$.00',
    flateRate:'$.00',
    perMileRate:'$.3000',
    customRatePGM:'',
    status:'AC',

 
   
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }


}
