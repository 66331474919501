<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Delete Item Location </mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col"> 
  
        <mat-form-field appearance="outline">
          <mat-label> Storer </mat-label>
          <input readonly matInput formControlName="storer" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Item </mat-label>

          <input readonly matInput formControlName="item" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Building </mat-label>

          <input readonly matInput formControlName="building" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Floor </mat-label>

          <input matInput formControlName="floor" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Aisle </mat-label>

          <input readonly matInput formControlName="aisle" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Slot </mat-label>

          <input readonly matInput formControlName="slot" />
        </mat-form-field>
      </div>
    </div>
    <!-- <div class="btn-text-right">
      <button mat-button class="button removeButton"(click)="deleteItemLocation()">Delete</button>
    </div> -->
    <div id="custom-alert">
      <div class="custom-alert-content">
        <button mat-button class="buttons removeButton" 
        (click)="deleteItemLocation()">Delete</button>

      </div>
    </div>
  
  </form>
</mat-card-content>
</mat-card>

      
      
