<mat-card class="over-Flow">
    <mat-card-header>  
      <mat-card-title  class="title" >
  
        Work With Incoming Event</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="form" class="form over-Flow">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Bill:</mat-label>
                    <input matInput formControlName="BILL"/>
                </mat-form-field>
  
                <mat-form-field appearance="outline">
                  <mat-label>Ref No:</mat-label>
                    <input matInput formControlName="SREF"/>
                </mat-form-field>
  
                <mat-form-field appearance="outline">
                  <mat-label>PO No:</mat-label>
                    <input matInput formControlName="PO"/>
                </mat-form-field>
  
                <mat-form-field appearance="outline">
                  <mat-label>PRO No:</mat-label>
                    <input matInput formControlName="PRO"/>
                </mat-form-field>
                   <button
                    class="addButton"
                       mat-raised-button
                          type="submit"
                          placeholder="Search">
                          Search
                    </button>
                  </div>
                </div>
              </form>           
    </mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Opt">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>Opt</th> 
          <td mat-cell *matCellDef="let element">{{ element.OPTION }}</td>
        </ng-container>

        <ng-container matColumnDef="Bill">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>Bill</th> 
            <td mat-cell *matCellDef="let element">{{ element.OHBILL }}</td>
          </ng-container>
          <ng-container matColumnDef="Storer">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>Storer</th> 
            <td mat-cell *matCellDef="let element">{{ element.OHSSFX }}</td>
          </ng-container>
          <ng-container matColumnDef="Reference Number">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>Reference Number</th> 
            <td mat-cell *matCellDef="let element">{{ element.OHSREF }}</td>
          </ng-container>
          <ng-container matColumnDef="Event Code">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>Event Code </th> 
            <td mat-cell *matCellDef="let element">{{ element.EVCODE }}</td>
          </ng-container>
          <ng-container matColumnDef="Reason Code">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>Reason Code</th> 
            <td mat-cell *matCellDef="let element">{{ element.EVSRCD}}</td>
          </ng-container>
          <ng-container matColumnDef="Time">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>Time</th> 
            <td mat-cell *matCellDef="let element">{{ element.EVDATE }}</td>
          </ng-container>
          <ng-container matColumnDef="Date">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>Date</th> 
            <td mat-cell *matCellDef="let element">{{ element.EVTIME }}</td>
          </ng-container>
           <ng-container matColumnDef="Actions">
            <th  mat-header-cell mat-header-cell *matHeaderCellDef>
              Actions
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-select  placeholder="Select Option">
         
              <mat-option>
              <button mat-icon-button (click)="IncomingEventErrorDetailsForm(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Errordetails</mat-label>
              </button>
            </mat-option> 
            <mat-option>
              <button mat-icon-button (click)="IncomingEventDeleteForm(element)">
                <mat-icon mat-icon-button color="primary">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </mat-option> 
              
            </mat-select>
            </td>
          </ng-container> 
      

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
         
      </table>
      <mat-paginator
      #paginator
      [pageSizeOptions]="[4, 8, 16]"
      showFirstLastButtons
    ></mat-paginator>
    </mat-card>