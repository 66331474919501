import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';


@Component({
  selector: 'app-metricsconstraints-add',
  templateUrl: './metricsconstraints-add.component.html',
  styleUrls: ['./metricsconstraints-add.component.css']
})
export class MetricsconstraintsAddComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;

  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MetricsconstraintsAddComponent>
  ) 
  { }

  ngOnInit(): void {
    this.form = this.fb.group({
      CNSTRSET:'BLDG012',
      DESCRIPT:'Building 12 Constraint set',
      Constraints:'Building = 012, Storer =1701',
      STATUS:'AC',
      
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}

