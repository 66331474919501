<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Change Product Code </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline"
              ><mat-label> Storer Number </mat-label>
              <input matInput formControlName="storerNumber" />
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label> Product Code </mat-label>
  
              <input matInput formControlName="productCode" />
            </mat-form-field>
           
            <mat-form-field appearance="outline">
                <mat-label> Description </mat-label>
    
                <input matInput formControlName="description" />
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label> Status </mat-label>
    
                <input matInput formControlName="status" />
              </mat-form-field>
              
              
              
           
              <button
              mat-button
              class="buttons addButton"
              mat-raised-button
              (click)="update()"
            >
              Update
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>