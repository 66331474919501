<mat-card>

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Request 869 Order Information</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
     <form [formGroup]="form">
        <div class="row"  >
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Storer</mat-label>
                   <input matInput formControlName="Storer"/>
               </mat-form-field>
              
               <mat-form-field appearance="outline">
                <mat-label>ScheduleToShipBy</mat-label>
                <input matInput formControlName="ScheduleToShipBy"/>
            </mat-form-field>
           
            <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <input matInput formControlName="Status"/>
            </mat-form-field>
           
            </div>
        

        </div>     
   </form>
   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
       <ng-container matColumnDef="Bill">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Bill
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Bill}}</td>
         </ng-container>
         <ng-container matColumnDef="No">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            No
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.No}}</td>
         </ng-container>
         <ng-container matColumnDef="Reference">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Reference
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Reference}}</td>
         </ng-container>
         <ng-container matColumnDef="Number">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Number
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Number}}</td>
         </ng-container>
         <ng-container matColumnDef="Consignee">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Consignee
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Consignee}}</td>
         </ng-container>
         <ng-container matColumnDef="Sts">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Sts
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Sts}}</td>
         </ng-container>

       
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
    </mat-card-content>
</mat-card>