import { Component, Inject, OnInit } from '@angular/core';
import { OrderDetailsService } from '../order-details.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-change-event-comments',
  templateUrl: './change-event-comments.component.html',
  styleUrls: ['./change-event-comments.component.css']
})
export class ChangeEventCommentsComponent implements OnInit {

  constructor(
    public orderDetailSrv: OrderDetailsService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router : Router,
    private dialogRef: MatDialogRef<ChangeEventCommentsComponent>
  ) { }

  form!: FormGroup;
  result:any;
  user=localStorage.getItem('userName');
  duplicateSource:any;
  orderSeq:any;

  ngOnInit(): void {

    this.form= this.fb.group({
      bol:this.anyvariable.ODBILL,
      seq:this.anyvariable.ODSEQ,
      item:this.anyvariable.ODITDS,
      comment1:'',
      print1:'',
      comment2:'',
      print2:'',
      order:this.anyvariable.code,     
      comments: this.fb.array([]) , 
    })
  }

  changeEventComments(){
    var res = this.form.value;    
    this.result = this.orderDetailSrv.changeEventCommentsService(
      res.bol,
      res.seq,
      res.comment1,
      res.print1,
      res.comment2,
      res.print2   

    ).subscribe((data: any) => {
      this.result = data;
      var length = this.result[0].data.length;
      //if(this.result[0].data[length-1].value=='Cmnts Creatd Success'||'Cmnts updatd Success'){
        if(this.result[0].data[length-1].value=='Successfully Created'){
        const msg= this.result[0].data[length-1].value;
        this.successMessage(msg)
        this.closeDialog();
      }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
      }
    });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/details']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }
  

  comments() : FormArray {  
    return this.form.get("comments") as FormArray  
  }  

  newComment(): FormGroup {  
    return this.fb.group({  
      comment: '',  
      print: '',  
      form:'',
      type:'',
    })  
  }  

  addComments() {  
    this.comments().push(this.newComment());  
  }  
     
  removeComments(i:number) {  
    this.comments().removeAt(i);  
  } 

  closeDialog(){
    this.dialogRef.close();
  }

}
