<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Maintain Postal Abbrevations</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Country</mat-label>
                    <input matInput formControlName="Country" />
                </mat-form-field>

                
            </div> 

            <div class="col" >
                <mat-form-field appearance="outline">
                    <mat-label>Abbrev Type</mat-label>
                    <input matInput formControlName="AbbrevType"  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Abbrev</mat-label>
                    <input matInput formControlName="Abbrev"  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Value</mat-label>
                    <input matInput formControlName="Value" />
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput placeholder="Comment" formControlName="Description"></textarea>
                </mat-form-field>
            </div> 
        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="changePAbbrev()" class="editButton">           
        <mat-icon mat-icon-button color="primary">edit</mat-icon>
        <mat-label>update</mat-label>
    </button>
</mat-card-footer>
