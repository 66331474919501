import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  url = environment.API_BASE_URL;

  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAllOrderDetails(user: any): Observable<any> {
    return this.httpClient.get(this.url + `/orders/readAll?user=${user}`);
  }

  readOrderService(
    user: any,
    billNo:any,
    status:any,
    storer:any,
    suffix:any,
    refNo:any,
    consignee:any,
    building:any,
    poNo:any,
    carrier:any,
    proNo:any
     ): Observable<any> {
    return this.httpClient.get(this.url + `/orders/read?user=${user}&billNo=${billNo}&status=${status}&storer=${storer}&suffix=${suffix}&refNo=${refNo}&consignee=${consignee}&building=${building}&poNo=${poNo}&carrier=${carrier}&proNo=${proNo}`);
  }

  deleteMapZone(user: any, building: any, zone: any): Observable<any> {
    return this.httpClient.delete(
      this.url + `/mapzone/delete?user=${user}&building=${building}&zone=${zone}`
    );
  }


  orderServiceCreate1(
    
    storer:any,
    suffix: any,
    storerRef: any,
    purchaseOrder: any,
    consignee: any,
    
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/orders/create1', {
        
        storer,
        suffix,
        storerRef,
        purchaseOrder,
        consignee
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  orderServiceCreate2(
    billOfLading:any,
		  storerNumber:any,
		  suffix:any,
		  schShippedDt:any,
		  schArrivalDt:any,
		  shipType:any, 
		  carrierCode:any,
		  freightCode:any,
		  codAmount:any,
		  spCharges:any,
		  billToStorer:any,
		  billToSuffix:any,
		  brokerName:any,
		  totalQuantity:any,
		  shipFromBucket:any,
		  overrideQty:any,
		  building:any,
		  ctrlCopyPrinterId:any,
		  ctrlCopyImmedFlag:any,
		  zoneCode:any,
		  commitFlag:any,
		  pickSheetPrinterId:any,
		  pickSheetImmedFlag:any,
		  bolPrinter:any,
		  bolImmedFlag:any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/orders/create2', {
        storerNumber,
		  suffix,
		  schShippedDt,
		  schArrivalDt,
		  shipType, 
		  carrierCode,
		  freightCode,
		  codAmount,
		  spCharges,
		  billToStorer,
		  billToSuffix,
		  brokerName,
		  totalQuantity,
		  shipFromBucket,
		  overrideQty,
		  building,
		  ctrlCopyPrinterId,
		  ctrlCopyImmedFlag,
		  zoneCode,
		  commitFlag,
		  pickSheetPrinterId,
		  pickSheetImmedFlag,
		  bolPrinter,
		  bolImmedFlag
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  orderServiceUpdate1(
    billOfLading: any,
    orderStatus: any,
    storer: any,
    suffix: any,
    storerRef: any,
    purchaseOrder: any,
    consignee: any,
    consigneeName:any,
    address1: any,
    address2: any,
    address3: any,
    address4: any,
    address5: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/orders/update1', {
        billOfLading,
        orderStatus,
        storer,
        suffix,
        storerRef,
        purchaseOrder,
        consignee,
        consigneeName,
        address1,
        address2,
        address3,
        address4,
        address5,
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  orderServiceUpdate2(
    billOfLading:any, //OHBILL
    storer:any, //OHSTOR
    suffix:any, //OHSSFX
    schShippedDt:any, //SHIPD
    schArrivalDt:any, //ARIVD
    shipType:any, //OHSHPT 
    carrierCode:any, //OHCAR
    freightCode:any, //OHFCOV
    codAmount:any, //OHCOD
    spCharges:any, //OHCHGF
    billToStorer:any, //OHSTRB
    billToSuffix:any, //OHSFXB
    brokerName:any, //OHBRKR
    totalQuantity:any, //OHHQTY
    shipFromBucket:any, //OHSHFR
    overrideQty:any, //OHOQTY
    building:any, //OHBLDG
    ctrlCopyPrinterId:any, //OHBLCP
    ctrlCopyImmedFlag:any, //CTLIP
    zoneCode:any, //OHZONE
    commitInv:any, //COMIT
    pickSheetPrinterId:any, //OHPSPR
    pickSheetImmedFlag:any, //PIKIP
    bolPrinter:any, //OHBLPR
    bolImmedFlag:any //BOLIP
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/orders/update2', {
        billOfLading, //OHBILL
        storer, //OHSTOR
        suffix, //OHSSFX
        schShippedDt, //SHIPD
        schArrivalDt, //ARIVD
        shipType, //OHSHPT 
        carrierCode, //OHCAR
        freightCode, //OHFCOV
        codAmount, //OHCOD
        spCharges, //OHCHGF
        billToStorer, //OHSTRB
        billToSuffix, //OHSFXB
        brokerName, //OHBRKR
        totalQuantity, //OHHQTY
        shipFromBucket, //OHSHFR
        overrideQty, //OHOQTY
        building, //OHBLDG
        ctrlCopyPrinterId, //OHBLCP
        ctrlCopyImmedFlag, //CTLIP
        zoneCode, //OHZONE
        commitInv, //COMIT
        pickSheetPrinterId, //OHPSPR
        pickSheetImmedFlag, //PIKIP
        bolPrinter, //OHBLPR
        bolImmedFlag, //BOLIP
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  
  orderDeship(
    billOfLading: any,
  
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/orders/deship', {
        billOfLading,
       
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  orderToMoveQueueService(
    
    billOfLading:any,
    priority: any,
    location: any
    
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/orders/createMQ', {
        
        billOfLading,
        priority,
        location
      })
      .pipe(retry(1), catchError(this.httpError));
  }



  deleteOrder(billOfLading: any): Observable<any> {
    return this.httpClient.delete(
      this.url + `/orders/delete?billOfLading=${billOfLading}`
    );
  }

  //W/W order events18.11)
  getOrderEventHistory(user: any,docn:any,status:any): Observable<any> {
    return this.httpClient.get(this.url + `/orders/events/read?user=${user}&docn=${docn}&status=${status}`);
  }


  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
