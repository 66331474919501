<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>
   <mat-card-title>
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Stock Activity Inquiry</mat-card-title>
    </mat-card-header>
  
     <form [formGroup]="form">
       <div class="row">
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Storer</mat-label>
                   <input matInput formControlName="Storer"/>
               </mat-form-field>
             
                <mat-form-field appearance="outline">  <mat-label> locationAdj  </mat-label> 
                
                <mat-select matNativeControl required matInput formControlName="locationAdj">
                <mat-option value="N">N</mat-option>
                <mat-option value="Quantity">Quantity</mat-option>
                </mat-select>
               <!-- <input matInput formControlName="locationAdj" /> -->
            </mat-form-field>
           
              <mat-form-field appearance="outline">
                <mat-label>Start</mat-label>
                <input matInput formControlName="Start"/>
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>End</mat-label>
              <input matInput formControlName="End"/>
          </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline">
                <mat-label>Item</mat-label>
                <input matInput formControlName="Item"/>
            </mat-form-field>
            </div>
            </div>
                   
      
  
  
   </form>
   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
       <ng-container matColumnDef="date">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            date
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.date}}</td>
         </ng-container>
         <ng-container matColumnDef="storer">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            storer
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.storer}}</td>
         </ng-container>
         <ng-container matColumnDef="reference">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            reference
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.reference}}</td>
         </ng-container>
         <ng-container matColumnDef="wrBL">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            wrBL
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.wrBL}}</td>
         </ng-container>
         <ng-container matColumnDef="description">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            description
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.description}}</td>
         </ng-container>
         <ng-container matColumnDef="amount">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            amount
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.amount}}</td>
        </ng-container>
        <ng-container matColumnDef="balance">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            balance
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.balance}}</td>
        </ng-container>
        
  
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
