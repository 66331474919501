<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Maintain Freight Codes</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Freight Code </mat-label>
            <input matInput formControlName="FreightCode" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Description </mat-label>

            <input matInput formControlName="Description" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Hazardous </mat-label>

            <input matInput formControlName="Hazardous" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Placard Type </mat-label>

            <input matInput formControlName="PlacardType" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> NMFC Number </mat-label>

            <input matInput formControlName="NMFCNumber" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Sub-Code </mat-label>

            <input matInput formControlName="SubCode" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> UN Code </mat-label>

            <input matInput formControlName="UNCode" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> NA Code </mat-label>

            <input matInput formControlName="NACode" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> LTL Freight Class </mat-label>

            <input matInput formControlName="LTLFreightClass" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Truck Load </mat-label>

            <input matInput formControlName="TruckLoad" />
          </mat-form-field>

          <button
            mat-button
            class="buttons addButton"
            mat-raised-button
            (click)="add()"
          >
            Add
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
