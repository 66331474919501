import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { ExpectedDurationsAddComponent } from './expected-durations-add/expected-durations-add.component';
import { ExpectedDurationsChangeComponent } from './expected-durations-change/expected-durations-change.component';
import { ExpectedDurationsDeleteComponent } from './expected-durations-delete/expected-durations-delete.component';

@Component({
  selector: 'app-ww-expected-durations',
  templateUrl: './ww-expected-durations.component.html',
  styleUrls: ['./ww-expected-durations.component.css']
})
export class WwExpectedDurationsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  displayedColumns: string[] = [
    'inOut',
    'appointmentType',
    'loadType',
    'accountCode',
    'expectedDuration',
    'opt',
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      inOut:'I',
      appointmentType:'Trailer',
      loadType:'Pallet',
      accountCode:'',
      expectedDuration:'2.00',
      
     

      
    },
    {
      inOut:'O',
      appointmentType:'Trailer',
      loadType:'Pallet',
      accountCode:'',
      expectedDuration:'3.00',
      
     

      
    },

   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    // private dialogRef: MatDialogRef<AttributesComponent>
    
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      inOut:'',
      
    
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
 
 


  }

  
  ExpectedDurationsChangeForm(element:any){
    this.matDialog.open(ExpectedDurationsChangeComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });
 
  }

  
  ExpectedDurationsAddForm(element:any){
    this.matDialog.open(ExpectedDurationsAddComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });
 
  }
  ExpectedDurationsDeleteForm(element:any){
    this.matDialog.open(ExpectedDurationsDeleteComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });
 
  }


  


addAttribute(){

}

}
