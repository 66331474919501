<mat-card class="over-Flow">

  <mat-card-header>
    <mat-card-title>
    <button mat-button (click)="onBack()" class="backButton">
      <mat-icon>navigate_before </mat-icon>
    </button>Work With System Comments</mat-card-title>
    <div class="buttons">
      <button mat-button (click)="searchTally()" class="addButton" *ngIf="searchTextDisplay">
        <mat-icon>clear</mat-icon>
      </button>
    <button mat-button (click)="searchTally()" class="addButton" *ngIf="!searchTextDisplay"
      matTooltip="Search Consignee">
    <mat-icon>search</mat-icon>
    </button>


    <button mat-button (click)="consigneeCommentCreate()" class="buttons addButton">
      <mat-icon>add</mat-icon>
      <!-- <span>New Comment</span> -->
    </button>
    </div>
    </mat-card-header>
    
  <mat-card-content class="tally-serach-form">

    <form [formGroup]="form" (ngSubmit)="searchConsigneeComment(form.value)" *ngIf="searchTextDisplay"
    class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>File Id</mat-label>
            <input matInput formControlName="fileId"/>
          </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>Key</mat-label>
            <input matInput formControlName="key"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Sub Type</mat-label>
            <input matInput formControlName="subType"/>
          </mat-form-field>

          <button class="buttons addButton" mat-raised-button type="submit" placeholder="Search">
            Search
          </button>

          <div class="row">
            <div class="col">
              
            </div>
          </div>
        </div>
      </div>     
    </form>
  </mat-card-content>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        
        <ng-container matColumnDef="ID">
          <th mat-header-cell *matHeaderCellDef width="20%">Id</th>
          <td matcell *matCellDef="let element">{{element.CMRECD}}</td>
        </ng-container>

        <ng-container matColumnDef="Key">
          <th mat-header-cell *matHeaderCellDef width="20%">Key</th>
          <td matcell *matCellDef="let element">{{element.CMKEY}}</td>
        </ng-container>

        <ng-container matColumnDef="Tp">
          <th mat-header-cell *matHeaderCellDef width="20%">Tp</th>
          <td matcell *matCellDef="let element">{{element.CMSUBD}}</td>
        </ng-container>

        <ng-container matColumnDef="Seq">
          <th mat-header-cell *matHeaderCellDef width="20%">Seq</th>
          <td matcell *matCellDef="let element">{{element.CMSEQ}}</td>
        </ng-container>

        <ng-container matColumnDef="Comment">
          <th mat-header-cell *matHeaderCellDef width="20%">Comment</th>
          <td matcell *matCellDef="let element">{{element.CMCOMM}}</td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell *matHeaderCellDef width="20%">
            <!-- Actions -->
          </th>
          <td matcell *matCellDef="let element">
            <div class="dropdown more_options_btn">
              <button
                class="btn"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                <li>
                  <button mat-icon-button (click)=" consigneeCommentUpdate(element)">           
                    <mat-icon mat-icon-button color="primary">edit</mat-icon>
                    <mat-label>Edit</mat-label>
                  </button>
                </li>
                <li>
                  <button mat-icon-button (click)=" consigneeCommentDelete(element)">           
                    <mat-icon mat-icon-button color="warn">delete</mat-icon>
                    <mat-label>Delete</mat-label>
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
      </table>
   
      <mat-paginator #paginator [pageSizeOptions]="[3, 6, 12]" showFirstLastButtons
      class="tally-table-pagination"></mat-paginator>
    
</mat-card>
