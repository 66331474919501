import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { MessageclassshredDetailsDetailsChangeComponent } from './messageclassshred-details-details-change/messageclassshred-details-details-change.component';
import { MessageclassshredDetailsDetailsDeleteComponent } from './messageclassshred-details-details-delete/messageclassshred-details-details-delete.component';
@Component({
  selector: 'app-messageclassshred-details-details',
  templateUrl: './messageclassshred-details-details.component.html',
  styleUrls: ['./messageclassshred-details-details.component.css']
})
export class MessageclassshredDetailsDetailsComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    
    'Field',
    'DataType',
    'XPath',
    'Null',
    'Actions'
    
  

  ];
  dataSource:any=[]

  duplicateSource:any =[
    {
    // DOCUMENT:'AXWAY 943',
    // TABLE:'EXH943P',
    FIELD:'EXDATA',
    DTYPE:'CHAR(120)',
    XPATH:'EXDATA',
    ALLOWNULL:'Y'
  
    },
    {  
      // DOCUMENT:'AXWAY 943',
      // TABLE:'EXH943P',
      FIELD:'EXDOCN',
      DTYPE:'CHAR(9)',
      XPATH:'EXDOCN',
      ALLOWNULL:'N'
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      DOCUMENT:'AXWAY 943',
      TABLE:'EXH943P',
      FIELD:'EXDOCN',
      DTYPE:'CHAR(9)',
      XPATH:'EXDOCN',
      ALLOWNULL:'N'

    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/message-class-shred/details']);

}
MessageClassShredDetailsUpdateForm(element:any){
  this.matDialog.open(MessageclassshredDetailsDetailsChangeComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
     'data': element,
     "autoFocus": false                           
    });
    }
    MessageClassShredDetailsDeleteForm(element:any){
      this.matDialog.open(MessageclassshredDetailsDetailsDeleteComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        }
        addAttribute(){

        }

}
