 
import { RemoveNUllPipe,PhonePipe } from './remove.pipe';
import { NgModule } from '@angular/core';
@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    RemoveNUllPipe,PhonePipe
  ],
  exports: [
    RemoveNUllPipe,PhonePipe
  ]
})
export class ApplicationPipesModule {}