<mat-card class="over-Flow">
  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain Storer Information</mat-card-title
        >
        &nbsp;
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>
      <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
                
              <div style="margin-left: 20px"><mat-label>Storer Number*:</mat-label>

                <mat-form-field  appearance="outline">                    
                  <input matInput formControlName="storeNumber" />
                  <mat-error *ngIf="form.controls['creditHold'].hasError('required')">
                    Required
                 </mat-error>
                  <mat-error *ngIf="form.controls['storeNumber'].hasError('pattern')">
                    only numbers are allowed
                  </mat-error>
                </mat-form-field>

                <mat-form-field  appearance="outline"> 
                  <input matInput formControlName="suffix" style="text-transform: uppercase"/>
                  <mat-hint >storer suffix</mat-hint>
                </mat-form-field>

                <mat-form-field appearance="outline" >   
                  <mat-label> Name: </mat-label>
                  <input matInput formControlName="storeName"/>
                  <mat-error *ngIf="form.controls['storeName'].hasError('required')">
                    Required
                 </mat-error>
                </mat-form-field>

              </div>
             
              <div style="margin-left: 20px"><mat-label>Address:</mat-label>
                  
                <mat-form-field appearance="outline" >
                  <mat-label> </mat-label>
                  <input matInput formControlName="address1"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                  <mat-label> </mat-label>
                  <input matInput formControlName="address2"/>
                </mat-form-field>

                <mat-form-field appearance="outline" >
                  <mat-label> </mat-label>
                  <input matInput formControlName="address3"/>
                </mat-form-field>
             
              </div>

              <div style="margin-left: 20px"><mat-label>Zip Code*:</mat-label>
                  <mat-form-field appearance="outline" >
                      <input matInput formControlName="zipCode"/>
                      <mat-error *ngIf="form.controls['zipCode'].hasError('required')">
                        Required
                      </mat-error>
                      <mat-error *ngIf="form.controls['zipCode'].hasError('pattern')">
                        zip code must be 5 digits long
                      </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" >
                    <input matInput formControlName="zipSuffix" maxlength="4"/>
                    <mat-hint >zip suffix</mat-hint>
                  </mat-form-field>
                   
              </div>

                <div style="margin-left: 20px"><mat-label>Phone Number:</mat-label>

                  <mat-form-field appearance="outline" >
                    <input matInput formControlName="areaCode"/>
                    <mat-hint >area code</mat-hint>
                    <mat-error *ngIf="form.controls['areaCode'].hasError('pattern')">
                      area code must be 3 digits long
                     </mat-error>
                  </mat-form-field>

                  <mat-form-field appearance="outline" >
                    <input matInput formControlName="phoneExtension"/>
                    <mat-hint >extension</mat-hint>
                    <mat-error *ngIf="form.controls['phoneExtension'].hasError('pattern')">
                      phone extension must be 3 digits long
                    </mat-error>
                  </mat-form-field>

                   <mat-form-field appearance="outline" >
                    <input matInput formControlName="phoneNumber"/>
                    <mat-hint >phone no.</mat-hint>
                    <mat-error *ngIf="form.controls['phoneNumber'].hasError('pattern')">
                      phone no. must be 4 digits long
                    </mat-error>
                   </mat-form-field>                 

                </div>

                   <!-- <mat-form-field appearance="outline" class="example-tel-input-container"  >   
                      <mat-label> Phone Number </mat-label> -->
                      
                    
                      <!-- <input type="text"  class="example1 " matInput formControlName="areaCode" #areaCode maxlength="3" (keyup)="move($event,'',areaCode,phoneExtension)"  />
                      
                      <span class="example-tel-input-spacer">&ndash;</span>
                      <input   type="text"   class="example2" matInput formControlName="phoneExtension" #phoneExtension maxlength="3" (keyup)="move($event,areaCode,phoneExtension,phoneNumber)" />
                      <span class="example-tel-input-spacer">&ndash;</span>
                      <input   type="text"    class="example3" matInput formControlName="phoneNumber" #phoneNumber maxlength="4" (keyup)="move($event,phoneExtension,phoneNumber,'')" />
                      <mat-error *ngIf="form.controls['phoneNumber'].hasError('pattern')">
                        Only <strong>numbers </strong> are allowed
                      </mat-error> 
                   </mat-form-field>  -->
                  
           
                  
                   <mat-form-field appearance="outline" >
                      <mat-label> Primary Rep </mat-label>
                      <input matInput formControlName="primaryRep"/>
                  </mat-form-field>
         
                
                   <mat-form-field appearance="outline" floatLabel="always" >  
                      <mat-label> Credit Limit </mat-label>
                      <input matInput formControlName="creditLimit" class="example-right-align" placeholder="0"/><span matSuffix>$</span>
                      <mat-hint >enter in dollars</mat-hint>
                      <mat-error *ngIf="form.controls['creditLimit'].hasError('pattern')">
                        only digits are allowed
                      </mat-error>
                  </mat-form-field>
                  

                
                   <mat-form-field appearance="outline" >
                      <mat-label> Credit Hold: </mat-label>
                      <mat-select formControlName="creditHold" >
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls['creditHold'].hasError('required')">
                         Required
                      </mat-error>
                  </mat-form-field>
              
                 
                   <mat-form-field appearance="outline" >
                       <mat-label> Building </mat-label>
                      <input matInput formControlName="building"/>
                  </mat-form-field>

                  
                   <mat-form-field appearance="outline" >
                      <mat-label> Track QBL </mat-label>
                      <mat-select formControlName="trackQBL">
                        <mat-option value="Y">Yes = quantity by location level</mat-option>
                        <mat-option value="N">No = location quantities are not required.</mat-option>
                      </mat-select>
                  </mat-form-field>
             
                  
                   <mat-form-field appearance="outline" 
                   ><mat-label> Standard Close </mat-label>
                   <mat-select formControlName="standardClose">
                    <mat-option value="Y">Yes</mat-option>
                    <mat-option value="N">No</mat-option>
                  </mat-select>
                  <mat-error *ngIf="form.controls['standardClose'].hasError('required')">
                     Required
                  </mat-error>
                  </mat-form-field>

                
                   <mat-form-field appearance="outline" > 
                       <mat-label> Mid Month CutOff Code </mat-label>
                       <mat-select formControlName="midMnthCutOff">
                        <mat-option value="A">A= Actual Date</mat-option>
                        <mat-option value="D">D= Displacement Number</mat-option>
                        
                      </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" > 
                    <mat-label> Mid Month CutOff Date </mat-label>
                    <mat-select formControlName="midMnthCutOffDt">
                        <mat-option value="A">A= Actual Date</mat-option>
                        <mat-option value="D">D= Displacement Date</mat-option>
                        <mat-option value="V">V= Anniversary Based Storage Account</mat-option>
                        <mat-option value="X">X= Average Daily Balance</mat-option>
                      </mat-select>
                  </mat-form-field>
             
                 
                   <mat-form-field appearance="outline"> 
                      <mat-label> Force PS/BL </mat-label>
                      <mat-select matInput formControlName="forcePSBL" style="text-transform: uppercase">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                      </mat-select>
                  </mat-form-field>

                
                   <mat-form-field appearance="outline" > 
                       <mat-label> Combo PS/BL </mat-label>
                       <mat-select formControlName="comboPSBL">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls['comboPSBL'].hasError('required')">
                         Required
                      </mat-error>
                  </mat-form-field>
           
                  
                   <mat-form-field appearance="outline" >
                      <mat-label> Cuts Allowed </mat-label>
                      <mat-select formControlName="cutsAllowed">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls['cutsAllowed'].hasError('required')">
                        Required
                      </mat-error>
                  </mat-form-field>

                  
                   <mat-form-field appearance="outline" >
                      <mat-label> Blind Tally </mat-label>
                      <input matInput formControlName="blindTally" style="text-transform: uppercase"/>
                  </mat-form-field>
              
              
                   <mat-form-field appearance="outline" >    
                      <mat-label> CHEP Pallets </mat-label>
                      <mat-select formControlName="chepPallets">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls['chepPallets'].hasError('required')">
                        Required
                      </mat-error>
                  </mat-form-field>

            
                   <mat-form-field appearance="outline" >      
                      <mat-label> Prevent Consolidation </mat-label>
                      <mat-select formControlName="preventConsolidation">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls['preventConsolidation'].hasError('required')">
                        Required
                      </mat-error>
                  </mat-form-field>  
              
                
                <button [disabled]="!(form.valid && form.dirty)" mat-button class="addButton buttons" (click)="storerCreate1()"  >
                    
                    <span>Create</span>
                </button>
            </div>
          </div>
      </form>    
      </mat-card-content>
     
</mat-card>
  


