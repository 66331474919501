<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>Maintain X-Dock Reference</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
            <div class="row">
                <div class="col">
                    <mat-form-field  appearance="outline">
                        <mat-label> Bill Number: </mat-label> 
                        <input readonly matInput value= {{this.bol}}/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Original Line No: </mat-label> 
                        <input readonly matInput value= {{this.seq}}/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Tally Number: </mat-label> 
                        <input matInput formControlName="tally" value={{this.tallyNo}}/>
                    </mat-form-field>
                    
                   
                <!-- <div class="col">
                    <button mat-button (click)="changeShipForm()" class="addButton">
                        
                        <span>Create</span>
                      </button>
                </div> -->
                </div>
            </div>
            <div class="row">
              <div class="col">
                <button mat-button class="buttons removeButton" (click)="deleteXDocRef()">Delete</button>

                <button mat-button class="buttons addButton" (click)="updateXDocRef()">Update</button>

                <button mat-button class="buttons addButton" (click)="createXDocRef()">Create</button>
              </div>
            </div>
            
        </form>
    </mat-card-content>
</mat-card>



