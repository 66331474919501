<mat-card>
    <mat-card-header>  
      <mat-card-title>
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
         Message Class Details</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="form" class="form over-Flow">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Document</mat-label>
                    <input matInput formControlName="DOCUMENT"/>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Table Name</mat-label>
                      <input matInput formControlName="TABLE"/>
                </mat-form-field>
                   <button
                    class="addButton"
                       mat-raised-button
                          type="submit"
                          placeholder="Search">
                          Search
                    </button>
              </div>
            </div>
        </form>
    </mat-card-content>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">
      <ng-container matColumnDef="Field">
        <th mat-header-cell *matHeaderCellDef>Field</th>
        <td mat-cell *matCellDef="let element">{{element.FIELD}}</td>
      </ng-container>
      <ng-container matColumnDef="DataType">
        <th mat-header-cell *matHeaderCellDef>Data Type</th>
        <td mat-cell *matCellDef="let element">{{element.DTYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="XPath">
        <th mat-header-cell *matHeaderCellDef>Xpath</th>
        <td mat-cell *matCellDef="let element">{{element.XPATH}}</td>
      </ng-container>
      <ng-container matColumnDef="Null">
        <th mat-header-cell *matHeaderCellDef>Null</th>
        <td mat-cell *matCellDef="let element">{{element.ALLOWNULL}}</td>
      </ng-container>
      <ng-container matColumnDef="Actions">
        <th  mat-header-cell mat-header-cell *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
                <mat-option>
                    <button mat-icon-button (click)="MessageClassShredDetailsUpdateForm(element)">
                        <mat-icon mat-icon-button color="primary">edit</mat-icon>
                        <mat-label>Change</mat-label>
                    </button>
                </mat-option>
                <mat-option>
                  <button mat-icon-button (click)="MessageClassShredDetailsDeleteForm(element)">
                      <mat-icon mat-icon-button color="primary">delete</mat-icon>
                      <mat-label>Delete</mat-label>
                  </button>
                </mat-option>
            </mat-select>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
    </table>
      
    