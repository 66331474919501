import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { StorerCreate6Component } from '../storer-create6/storer-create6.component';
import { StorerServiceService } from '../storer-service.service';

@Component({
  selector: 'app-storer-create5',
  templateUrl: './storer-create5.component.html',
  styleUrls: ['./storer-create5.component.css']
})
export class StorerCreate5Component implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerCreate5Component>,
   private matDialog: MatDialog
    ) { }

  ngOnInit(): void {

    console.log(this.anyvariable.storerNumber);
       this.form = this.fb.group({
        
        storeNumber: this.anyvariable.storeNumber, // STORE#
        suffix: this.anyvariable.suffix, // STSFX
        storeName: this.anyvariable.storeName, // STNAME
        blPrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],//STBLPR     
        picksheetPrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],  //STPSPR
        controlcopyPrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],//STBLCP    
        wrPrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],  //STWRPR
        storageInvoicePrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],//STSIPR     
        generalInvoicePrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],  //STGIPR
        cartEdiReportPrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],//STCIPR     
        accessInvoicePrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],  //STAIPR
        freightBillPrinter:[
          '',
          [
            Validators.required,
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
            
          ],
        ],//STFBPR   
       });
  }


  storerCreate5(){
    var res = this.form.value;
       
    this.result = this.storerSvc
      .storerServiceCreate5( 
        res.storeNumber,
        res.suffix,
        res.storeName,
        res.blPrinter,
        res.picksheetPrinter,
        res.controlcopyPrinter,
        res.wrPrinter,
        res.storageInvoicePrinter,
        res.generalInvoicePrinter,
        res.cartEdiReportPrinter,
        res.accessInvoicePrinter,
        res.freightBillPrinter,
        this.user

      ).subscribe((data: any) => {
        this.result = data;
        if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
          const msg= this.result[0].data[this.result[0].data.length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.create6();
       }else{
        this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
       }
      });
  }


  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }
  
  create6() {
    var res = this.form.value;
    this.matDialog.open(StorerCreate6Component, {      
      width: '1000px',
      maxHeight: '80vh',
      data: res,
      autoFocus: false,
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
