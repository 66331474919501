<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title 
                    >Maintain an Expected Duration</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> In/Out: </mat-label> 
                          <input matInput formControlName="inOut" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> AppointmentType: </mat-label> 
                          <input matInput formControlName="appointmentType" />
                        </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> LoadType: </mat-label> 
                    <input matInput formControlName="loadType" />
                  </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">  <mat-label> AccountCode: </mat-label> 
            <input matInput formControlName="accountCode" />
          </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">  <mat-label> ExpectedDuratin: </mat-label> 
            <input matInput formControlName="expectedDuration" />
          </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">  <mat-label> Last Changed Date: </mat-label> 
            <input matInput formControlName="lastChangedDate" />
          </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">  <mat-label> Last Changed By: </mat-label> 
            <input matInput formControlName="lastChangedBy" />
          </mat-form-field>
      </div>
            <div >
             <button mat-button class="buttonCss" (click)="attributes()">ADD</button>
            </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  
  
