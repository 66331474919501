import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { OrderDetailsService } from '../order-details.service';


@Component({
  selector: 'app-order-detail-lot-status',
  templateUrl: './order-detail-lot-status.component.html',
  styleUrls: ['./order-detail-lot-status.component.css']
})

export class OrderDetailLotStatusComponent implements OnInit {

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    public orderDetailSrv : OrderDetailsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<OrderDetailLotStatusComponent>

  ) { }

  displayedColumns: string[] = [
    'lots',
    'location',
    'available',
    'commited',
    'damaged',
    'held',
    'user1',
    'user2',
    'user3',
    'workOrder',
    'shipped'
  ];

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  form!: FormGroup;
  dataSource:any=[]
  duplicateSource:any;
  user = localStorage.getItem('userName');
  storer: any;
  suffix:any;
  item:any;
  bol:any;
  storerName:any;
  itemDescription:any;
  result:any;

  ngOnInit(): void {

    this.orderDetailSrv.orderDtlLotStatusService(
      this.user,
      this.anyvariable.ODSTOR,
      this.anyvariable.ODSSFX,
      this.anyvariable.ODITEM,
      this.anyvariable.ODBILL,
      this.anyvariable.ODSSEQ
    ).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;

      console.log('All LOT STATUS DATA');
      console.log(this.duplicateSource);

      this.storerName = this.duplicateSource[0].ODNAME;
      console.log(this.storerName)
      this.itemDescription = this.duplicateSource[0].ODDSCS;

      this.dataSource = new MatTableDataSource(this.duplicateSource);
      this.dataSource.paginator = this.paginator;

      this.storer=this.anyvariable.ODSTOR +'  '+ this.storerName;
      this.suffix=this.anyvariable.ODSSFX;
      this.bol=this.anyvariable.ODBILL;
      this.item=this.anyvariable.ODITEM + '  ' + this.itemDescription;
    });

    this.form= this.fb.group({
  
    })
  
  }

  onBack() {
    this.router.navigate(['order/details']);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
