<!-- Form No : 40.1(task) -->
<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Change Adjustment Date </mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">

    <form [formGroup]="form">
      <div class="row">
        <div class="col"> 
        <mat-form-field appearance="outline">
          <mat-label> transactionNumber: </mat-label>
          <input matInput formControlName="transactionNumber" readonly />
        </mat-form-field>
      
      
        <mat-form-field appearance="outline">
          <mat-label> storer: </mat-label>
          <input matInput formControlName="storer" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> item: </mat-label>
          <input matInput formControlName="item" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> quantity: </mat-label>
          <input matInput formControlName="quantity" readonly />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Bucket: </mat-label>
          <input matInput formControlName="Bucket" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Location: </mat-label>
          <input matInput formControlName="Location" readonly />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> adjustmentDate: </mat-label>
          <input matInput type="date" formControlName="adjustmentDate" />
        </mat-form-field>
      </div>
    </div>
      

      <div class="btn-text-right">
    <button mat-button class="button addButton"(click)="attributes()">Add</button>
  </div>
    </form>
  </mat-card-content>
  </mat-card>