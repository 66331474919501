<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Record Storage Box Synopsis</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>BoxNumber</mat-label>
                      <input matInput formControlName="BoxNumber"/>
                      </mat-form-field>
                    
                      <mat-form-field appearance="outline">
                       <mat-label>Location</mat-label>
                       <input matInput formControlName="Location"/>
                       </mat-form-field>
                    
                       <mat-form-field appearance="outline">
                        <mat-label>FirstIn</mat-label>
                        <input matInput formControlName="FirstIn"/>
                        </mat-form-field>
                     </div>
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Description</mat-label>
                       <input matInput formControlName="Description"/>
                       </mat-form-field>
                      </div>
  
                       <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>SecondDesc</mat-label>
                       <input matInput formControlName="SecondDesc"/>
                       </mat-form-field>  
  
                      <mat-form-field appearance="outline">
                        <mat-label>BoxSize</mat-label>
                       <input matInput formControlName="BoxSize"/>
                       </mat-form-field>
                     </div>
  
                     <div class="col">        
                      <mat-form-field appearance="outline">
                        <mat-label>Reference</mat-label>
                       <input matInput formControlName="Reference"/>
                       </mat-form-field>
                     </div>
       
                  <div class="col">    
                      <mat-form-field appearance="outline">
                        <mat-label>FileType</mat-label>
                       <input matInput formControlName="FileType"/>
                       </mat-form-field>
  
                       <mat-form-field appearance="outline">
                        <mat-label>AgeClass</mat-label>
                       <input matInput formControlName="AgeClass"/>
                       </mat-form-field>
  
                       <mat-form-field appearance="outline">
                        <mat-label>ContentDates</mat-label>
                       <input matInput formControlName="ContentDates"/>
                       </mat-form-field>
                     </div>
  
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Retention</mat-label>
                       <input matInput formControlName="Retention"/>
                       </mat-form-field>
  
                       <mat-form-field appearance="outline">
                        <mat-label>ScheduledDestruction</mat-label>
                       <input matInput formControlName="ScheduledDestruction"/>
                       </mat-form-field>
                     </div>
  
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Customer</mat-label>
                       <input matInput formControlName="Customer"/>
                       </mat-form-field>
  
                       <mat-form-field appearance="outline">
                        <mat-label>District</mat-label>
                       <input matInput formControlName="District"/>
                       </mat-form-field>
  
                       <mat-form-field appearance="outline">
                        <mat-label>Building</mat-label>
                       <input matInput formControlName="Building"/>
                       </mat-form-field>
                     </div>
  
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>CustomerBox</mat-label>
                       <input matInput formControlName="CustomerBox"/>
                       </mat-form-field>
  
                       <mat-form-field appearance="outline">
                        <mat-label>Dept</mat-label>
                       <input matInput formControlName="Dept"/>
                       </mat-form-field>
                     </div>
  
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Division</mat-label>
                       <input matInput formControlName="Division"/>
                       </mat-form-field>
                       <mat-form-field appearance="outline">
                        <mat-label>Alpha</mat-label>
                       <input matInput formControlName="Alpha"/>
                       </mat-form-field>
                     </div>
                     </form>
  
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="Request">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Request
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Request }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Line">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Line
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Line }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Activity">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Activity
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Activity }}</td>
          </ng-container>
  
          <ng-container matColumnDef="Tracking">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Tracking
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Tracking }}</td>
          </ng-container>
  
          <ng-container matColumnDef="Number">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Number
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Number }}</td>
          </ng-container>
  
          <ng-container matColumnDef="DateTime">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              DateTime
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.DateTime }}</td>
          </ng-container>
          
          <ng-container matColumnDef="Sts">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Sts
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Sts }}</td>
          </ng-container>
      
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
