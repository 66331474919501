import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../iheader.service';

@Component({
  selector: 'app-create-tally-dtl-putaway-loc',
  templateUrl: './create-tally-dtl-putaway-loc.component.html',
  styleUrls: ['./create-tally-dtl-putaway-loc.component.css']
})
export class CreateTallyDtlPutawayLocComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<CreateTallyDtlPutawayLocComponent>,
  ) {}

  ngOnInit(): void {

    console.log(this.anyvariable);

       this.form = this.fb.group({

        floor:'', // ILFLOR
        aisle:'', // ILAISL
        slot:'', // ILSLOT
        quantityAvailable:'', // ILQAVL
        damaged:'', // ILQDAM
        held:'', // ILQHLD
        user1:'',
        user2:'',
        user3:'',
        user4:'',

       });
  }

  createPutawayLoc(){
    var res = this.form.value;
    
   
    this.result = this.iHeaderSvc
      .createServicePutawayLoc( 
        this.anyvariable.IDACDL, // ACDL
        this.anyvariable.IDRTTY, // Rate Type
        this.anyvariable.IDTALY,
        this.anyvariable.IDSEQ,
        localStorage.getItem('building'),//building
        this.anyvariable.IDSTOR,
        this.anyvariable.IDITEM,
        this.anyvariable.IDLOT,
        this.anyvariable.IDLTC1,
        this.anyvariable.IDLTC2, 
        GlobalComponent.fullBuilding(res.floor),
        GlobalComponent.fullSlotAisl(res.aisle),
        GlobalComponent.fullSlotAisl(res.slot),
        res.quantityAvailable,
        res.damaged,
        res.held,
        res.user1,
        res.user2,
        res.user3,
        res.user4,
        this.user

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
      
      if(JSON.parse(this.result[0].data[1].value).success){
        //alert('Record Created SuccessFully');
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.create();
        this.router.navigate(['/main-nav/putawayLocation/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    //this.router.navigate(['/main-nav/storer/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/storer/read']);
  }


  create() {
    var res = this.form.value;
    console.log("***** FORM DATA *****");
    console.log(res);
    console.log("***** ANYVARIABLE DATA *****");
    console.log(this.anyvariable);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
