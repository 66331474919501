import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwItemAttributesCreateComponent } from './ww-item-attributes-create/ww-item-attributes-create.component';
import { WwItemAttributesDeleteComponent } from './ww-item-attributes-delete/ww-item-attributes-delete.component';
import { WwItemAttributesUpdateComponent } from './ww-item-attributes-update/ww-item-attributes-update.component';

@Component({
  selector: 'app-ww-item-attributes',
  templateUrl: './ww-item-attributes.component.html',
  styleUrls: ['./ww-item-attributes.component.css']
})
export class WwItemAttributesComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  
  displayedColumns: string[] = [
    
  
    'NumberType',
    'Number',
    'Actions',

   
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      

      IANTYP:'WIP',
      IANUMB:'ELC'

      
      
    },
    {
      IANTYP:'Item Group',
      IANUMB:'475A'
      
      
    },
    

  ]


  constructor( 
    
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,) { 

    
  }

  ngOnInit(): void {
    this.form= this.fb.group({
      IASTOR:'',
      IAITEM:'',
      

    
     
    })


    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
    
  }

  onBack() {
    this.router.navigate(['/main-nav/']);
  }
  ItemAttributesCreateForm(){
    this.matDialog.open(WwItemAttributesCreateComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      // 'data': element,
      "autoFocus": false                           
    });
  }

  ItemAttributesChangeForm(element:any){
    this.matDialog.open(WwItemAttributesUpdateComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });

   }

   ItemAttributesDeleteForm(element:any){
    this.matDialog.open(WwItemAttributesDeleteComponent, {
        "width": '1000px',
         "maxHeight": '90vh',
        'data': element,
       "autoFocus": false                           
      });
      }

   addAttribute(){

   }
   
}
