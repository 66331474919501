<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Inbound Detail Comments</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
   
      <div class="row">
        <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <mat-label>Tally</mat-label>
          <input matInput formControlName="tally" required />
        </mat-form-field>
      
        <mat-form-field appearance="outline" class="full-width w-100">
          <mat-label>Comments :</mat-label>
          <textarea matInput
          formControlName="comments"> Comments</textarea>
          <input
                  matInput
                  formControlName="comments"
                />
        </mat-form-field>

        <button
          class="buttons removeButton"
          mat-raised-button (click)="deleteTallyComment()"
        >
          Delete All Comments
        </button>
     
      <button
            class="buttons addButton" (click)="createTallyDetailComment()"
            mat-raised-button 
          >
            Create
          </button>
      </div>
    </div>  </form>
    </mat-card-content>
  
  
  
    <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
      <ng-container matColumnDef="Comments">
        <th mat-header-cell *matHeaderCellDef>Comments</th>
        <td mat-cell *matCellDef="let element">{{ element.CISMNT }}</td>
      </ng-container>
      
      <ng-container matColumnDef="Actions">
        <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
        <!-- Actions -->
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="dropdown more_options_btn">
            <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <mat-icon>more_vert</mat-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-button (click)="updateTallyComment(element)">
                <mat-icon mat-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
  
            <!-- <li>
              <button mat-button (click)="deleteTallyComment()">
                <mat-icon mat-button color="warn"
                  >delete{{ element.BUILD }}</mat-icon
                >
                <mat-label>Delete {{ element.no }}</mat-label>
              </button>
            </li> -->
            
            </ul>
            </div>
        </td>
  
  
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
  </mat-card>