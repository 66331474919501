<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Maintain Trip Expenses</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Trip Number</mat-label>
                    <input matInput formControlName="TripNumber" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Date</mat-label>
                    <input type="date" matInput formControlName="Date" />
                </mat-form-field>
            </div> 

            <div class="col" >
                <mat-form-field appearance="outline">
                    <mat-label>Category</mat-label>
                    <input matInput formControlName="Category"  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Sub-Category</mat-label>
                    <input matInput formControlName="SubCategory"  />
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput placeholder="Reason for Expense" formControlName="Description"></textarea>
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Quantity</mat-label>
                    <input matInput formControlName="Quantity" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Rate</mat-label>
                    <input matInput formControlName="Rate" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input matInput formControlName="Amount" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Receipt Exists</mat-label>
                    <mat-select  formControlName="ReceiptExists">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Client Reimbursable</mat-label>
                    <mat-select  formControlName="ClientReimbursable">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Out Of Pocket</mat-label>
                    <mat-select  formControlName="OutOfPocket">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> 
        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="addExpense()" class="addButton">           
        <mat-icon mat-icon-button color="primary">create</mat-icon>
        <mat-label>Trip Expense</mat-label>
    </button>
</mat-card-footer>