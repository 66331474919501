import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { MetricsconstraintsAddComponent } from './metricsconstraints-add/metricsconstraints-add.component';
import { MetricsconstraintsUpdateComponent } from './metricsconstraints-update/metricsconstraints-update.component';
import { MetricsconstraintsDeleteComponent } from './metricsconstraints-delete/metricsconstraints-delete.component';

@Component({
  selector: 'app-metrics-constraints',
  templateUrl: './metrics-constraints.component.html',
  styleUrls: ['./metrics-constraints.component.css']
})
export class MetricsConstraintsComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'Set',
    'Description',
    'Status',
    'Actions'
   
  ];
  dataSource:any=[]
  duplicateSource:any =[
    {
      CNSTRSET:'BLDG012',
      DESCRIPT:'Building 12 Constraint set',
      STATUS:'AC',
        
    },
   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      CNSTRSET:'BLDG012', 
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/metrics-constraints/read']);
 
}
MetricsConstraintsAddForm(){
  this.matDialog.open(MetricsconstraintsAddComponent, {
      "width": '1000px',
       "maxHeight": '90vh',
    //  'data': element,
     "autoFocus": false                           
    });
    }
    MerticsconstraintsUpdateForm(element:any){
      this.matDialog.open(MetricsconstraintsUpdateComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        }
        MerticsconstraintsDeleteForm(element:any){
          this.matDialog.open(MetricsconstraintsDeleteComponent, {
              "width": '1000px',
               "maxHeight": '90vh',
             'data': element,
             "autoFocus": false                           
            });
            }
    addAttribute(){

    }
}
