<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Update Operator Equipment </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline"
              ><mat-label> Card Number  </mat-label>
              <input matInput formControlName="KBNUMB" />
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label> Storer </mat-label>
  
              <input matInput formControlName="KBSTOR" />
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label> Storer Suffix </mat-label>
    
                <input matInput formControlName="KBSSFX" />
              </mat-form-field>
           
            <mat-form-field appearance="outline">
                <mat-label> Item Code</mat-label>
    
                <input matInput formControlName="KBITEM" />
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label> Requested Quantity </mat-label>
    
                <input matInput formControlName="KBQTY" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> Deliver To Location </mat-label>
    
                <input matInput formControlName="KBLOCN" />
              </mat-form-field>
              
              
              
           
              <button
              mat-button
              class="buttons addButton"
              mat-raised-button
              (click)="delete()"
            >
              Add
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
