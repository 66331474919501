import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';

import { ValidityCharacterRemovalAddComponent } from './validity-character-removal-add/validity-character-removal-add.component';
import { ValidityCharacterRemovalChangeComponent } from './validity-character-removal-change/validity-character-removal-change.component';
import { ValidityCharacterRemovalDeleteComponent } from './validity-character-removal-delete/validity-character-removal-delete.component';

@Component({
  selector: 'app-ww-validity-character-removal',
  templateUrl: './ww-validity-character-removal.component.html',
  styleUrls: ['./ww-validity-character-removal.component.css']
})
export class WwValidityCharacterRemovalComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Storer Number',
    'Scan Type',
    'Start Pos',
    'End Pos',
    'Qualifier',
    'Start of Element',
    'Length of Element',
    'Status',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      storerNumber:'1250',
      scanType:'Item',
      startPos:1,
      endPos:1,
      qualifier:'Q',
      startofElement:2,
      lengthofElement:20,
      status:'AC'
    },
    {
      storerNumber:'1250',
      scanType:'Lot 1',
      startPos:1,
      endPos:1,
      qualifier:'L',
      startofElement:2,
      lengthofElement:20,
      status:'AC'
    },
    {
      storerNumber:'1301',
      scanType:'SR',
      startPos:1,
      endPos:2,
      qualifier:'2L',
      startofElement:3,
      lengthofElement:27,
      status:'AC'
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      Storer:''
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  addvalidityCharacterRemoval(){
    this.matDialog.open(ValidityCharacterRemovalAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  changevalidityCharacterRemoval(element:any){
    this.matDialog.open(ValidityCharacterRemovalChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

  deletevalidityCharacterRemoval(element:any)
  {
    this.matDialog.open(ValidityCharacterRemovalDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

}
