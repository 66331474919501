import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { MetricsDefinationsAddComponent } from './metrics-definations-add/metrics-definations-add.component';
import { MetricsDefinationsUpdateComponent } from './metrics-definations-update/metrics-definations-update.component';
import { MetricsDefinationsDeleteComponent } from './metrics-definations-delete/metrics-definations-delete.component';

@Component({
  selector: 'app-metrics-definations',
  templateUrl: './metrics-definations.component.html',
  styleUrls: ['./metrics-definations.component.css']
})
export class MetricsDefinationsComponent implements OnInit {
  form!: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    
    'MetricName',
    'Description',
    'Actions'
   
  ];
  dataSource:any=[]
  duplicateSource:any =[
    {
      SRCH:'',
      METRIC:'MtrInApptActive',
      DESCRIPT:'Inbound Appointments Active',
      
        
    },
   
  ]


  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      SRCH:'',
      METRIC:'MtrInApptActive',
      DESCRIPT:'Inbound Appointments Active',
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/metrics-definations/read']);
 
}

MetricsDefinationsAddForm(){
  this.matDialog.open(MetricsDefinationsAddComponent,  {
      "width": '1000px',
       "maxHeight": '90vh',
    //  'data': element,
     "autoFocus": false                           
    });
    }
    MetricsDefinationsUpdateForm(element:any){
      this.matDialog.open(MetricsDefinationsUpdateComponent, {
          "width": '1000px',
           "maxHeight": '90vh',
         'data': element,
         "autoFocus": false                           
        });
        }
        MetricsDefinationsDeleteForm(element:any){
          this.matDialog.open(MetricsDefinationsDeleteComponent, {
              "width": '1000px',
               "maxHeight": '90vh',
             'data': element,
             "autoFocus": false                           
            });
            }
    addAttribute(){

    }
}

