import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class EquipmentoperatorService {

  url = environment.API_BASE_URL;

  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  
  getEQuipmentOperator(user: any,eoUser:any,eoStat:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/equipmentOperator/read?user=${user}&eoUser=${eoUser}&eoStat=${eoStat}`
    );
  }

  getAllEQuipmentOperator(user: any): Observable<any> {
    return this.httpClient.get(
      this.url+`/equipmentOperator/readAll?eoUser=${user}`
    );
  }

  createEquipmentOperator(
    eoUser:any, 
    eoStorer:any, 
    eosfx:any, 
    eoBldg:any, 
    eoStat:any,
    shift:any,
    user: any
    ): Observable<any> {
    return this.httpClient
      .post<any>(this.url+'/equipmentOperator/create', { 

      eoUser, 
      eoStorer, 
      eosfx, 
      eoBldg,
      eoStat,
      shift,
      user
       })
      .pipe(retry(1), catchError(this.httpError));
  }
  
  updateEquipmentOperator(
    eoUser:any, 
    eoStorer:any, 
    eosfx:any, 
    eoBldg:any, 
    eoStat:any,
    user: any
    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/equipmentOperator/update', { 

      eoUser, 
      eoStorer, 
      eosfx, 
      eoBldg,
      eoStat,
      user
       })
      .pipe(retry(1), catchError(this.httpError));
  }
  
  deleteEquipmentOperator( eoUser:any, eoStorer:any,eoSfx:any, eoBldg:any,user: any): Observable<any> {
    return this.httpClient.delete<any>(
      this.url+`/equipmentOperator/delete?user=${user}&eoUser=${eoUser}&eoStore=${eoStorer}&eoSfx=${eoSfx}&eoBldg=${eoBldg}`
    );
  }
  
  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
