<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title style="color: rgb(145, 128, 32)"
                    >Item Status Inquiry</mat-card-title>
                  
                </mat-card-header>
                <div>
                  
                </div>
                <mat-card-content> 
  
                  <div class="row">
                      <div class="col">
                          <mat-form-field  appearance="outline"><mat-label> Storer : </mat-label> 
                              <input matInput formControlName="Storer" />
                            </mat-form-field> 
                          
                      </div>

                      <div class="col">
                        <mat-form-field  appearance="outline"><mat-label> Storer : </mat-label> 
                          <input matInput formControlName="Storer" />
                        </mat-form-field> 
                      </div>

                      
                 
                 
                  
              
                 

                    </div>
                 <div >
            
                  <button mat-button class="buttonCss" (click)="attributes()">Change</button>
              </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  
  