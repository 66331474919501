import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';



import { DialogRef } from '@angular/cdk/dialog';
@Component({
  selector: 'app-ww-incoming-events-errordetails',
  templateUrl: './ww-incoming-events-errordetails.component.html',
  styleUrls: ['./ww-incoming-events-errordetails.component.css']
})
export class WwIncomingEventsErrordetailsComponent implements OnInit {
  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef< WwIncomingEventsErrordetailsComponent>
   ) 
   {}
   displayedColumns: string[] = [
    'Opt',
    'Storer',
    'Consignee',
    'Type',
    'Number',
    'Seq Storer Reference No',
    
    
    
  ];
  dataSource:any=[]


  duplicateSource:any =[
    {
      OPTION:'',
      EESTOR:'',
      EECONS:'',
      EEDOCT:'',
      EEDOCN:'',
      EEDSEQ:'',
     
     
        
    },
   
  ]

  ngOnInit(): void {
    this.form = this.fb.group({
      STOR :'',
      CONS:'',
      DOCT:'214',
      DOCN:'50014',
      SREF:'',
      MSGD:'',
      SEVR:'',
      
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}
 



            // }

            // df
             


  