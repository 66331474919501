<mat-card class="over-Flow">

  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title fxLayoutAlign="center"
          >Maintain Equipment Operators</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" fxLayoutAlign="center">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Operator</mat-label>
            <input matInput formControlName="Operator" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Building</mat-label>
            <input matInput formControlName="Building" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="Storer" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Shift</mat-label>
            <mat-select formControlName="Shift">
              <mat-option value="SHF1">Shift 1</mat-option>
              <mat-option value="SHF2">Shift 2</mat-option>
              <mat-option value="SHF3">Shift 3</mat-option>
            </mat-select>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <mat-select formControlName="Status">
              <mat-option value="AC">Active Status</mat-option>
              <mat-option value="PL">Pallet Operator</mat-option>
              <mat-option value="CS">Case Operator</mat-option>
              <mat-option value="B">Both Pallet and Case</mat-option>
              <mat-option value="LD">Loader/Unloader</mat-option>
              <mat-option value="*">All Labour Activity</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row">
        <div class="col">
          <mat-label>Breaks</mat-label>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>From</mat-label>
              <input type="time" matInput formControlName="From1" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>To</mat-label>
              <input type="time" matInput formControlName="To1" />
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>From</mat-label>
              <input type="time" matInput formControlName="From2" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>To</mat-label>
              <input type="time" matInput formControlName="To2" />
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>From</mat-label>
              <input type="time" matInput formControlName="From3" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>To</mat-label>
              <input type="time" matInput formControlName="To3" />
            </mat-form-field>
          </div>

          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>From</mat-label>
              <input type="time" matInput formControlName="From4" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>To</mat-label>
              <input type="time" matInput formControlName="To4" />
            </mat-form-field>
          </div>

          <button mat-button (click)="addEquipmentOperator()" class="buttons addButton">
            <span>create</span>
          </button>
        </div>
      </div>
    </div>
    </form>
  </mat-card-content>

</mat-card>