<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Daily Status Report</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Account</mat-label>
              <input matInput formControlName="Account" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Reporting For</mat-label>
              <input matInput formControlName="ReportingFor" />
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label> Omit Pallets </mat-label>
              <mat-select formControlName="OmitPallets">
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col" style="margin-left:50px;">
            <b><u>Outbound</u></b>
          </div>  
          <div class="col">
            <b><u>Inbound</u></b>
          </div>
        </div>
        <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Total Cases To Pick </mat-label>
            <input matInput formControlName="TotalCasesToPick" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Total Orders To Pick </mat-label>
            <input matInput formControlName="TotalOrdersToPick" />
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Orders W/Loose Picks </mat-label>
            <input matInput formControlName="OrdersWLoosePicks" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Total Cases Picked </mat-label>
            <input matInput formControlName="TotalCasesPicked" />%
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Total Orders Picked </mat-label>
            <input matInput formControlName="TotalOrdersPicked" />%
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>CasesNotPicked </mat-label>
            <input matInput formControlName="CasesNotPicked" />%
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Orders Not Picked </mat-label>
            <input matInput formControlName="OrdersNotPicked" />%
          </mat-form-field>
        </div>
        
        <div class="col" >
          <mat-form-field appearance="outline">
            <mat-label>Total Cases Expected </mat-label>
            <input matInput formControlName="TotalCasesExpected" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Total Shipments Expected </mat-label>
            <input matInput formControlName="TotalShipmentsExpected" />
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Total Cases Received </mat-label>
            <input matInput formControlName="TotalCasesReceived" />%
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Total Shipments Received </mat-label>
            <input matInput formControlName="TotalShipmentsReceived" />%
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Cases Un-received </mat-label>
            <input matInput formControlName="CasesUnreceived" />%
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Shipments Un-received</mat-label>
            <input matInput formControlName="ShipmentsUnreceived" />%
          </mat-form-field>
        </div>
        </div>
        <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Loose Cases</mat-label>
            <input matInput formControlName="Loose1" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Layers </mat-label>
            <input matInput formControlName="Layers1" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Pallets </mat-label>
            <input matInput formControlName="Pallets1" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Loose Cases</mat-label>
            <input matInput formControlName="Loose2" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Layers </mat-label>
            <input matInput formControlName="Layers2" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Pallets </mat-label>
            <input matInput formControlName="Pallets2" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
