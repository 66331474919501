<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Tag Counts </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Book</mat-label>
            <input matInput formControlName="Book" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="BookName">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Book Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.BookName }}</td>
    </ng-container>

    <ng-container matColumnDef="ExtractDate">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Extract Date
      </th>
      <td mat-cell *matCellDef="let element">{{ element.ExtractDate }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell mat-header-cell width="120px" *matHeaderCellDef>
        Description
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Sts">
      <th mat-header-cell mat-header-cell width="40px" *matHeaderCellDef>
        Sts
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Sts }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="100px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="inventoryTagsChange()">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Change</mat-label>
            </button></mat-option
          >
          <!-- <mat-option>
            <button mat-icon-button (click)="inventoryTagsList()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>List</mat-label>
            </button></mat-option
          > -->
          <mat-option>
            <button mat-icon-button (click)="inventoryTagsTag()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Tag</mat-label>
            </button></mat-option
          >
          <!-- <mat-option>
            <button mat-icon-button (click)="inventoryTagsDiscrepancies()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Discrepancies</mat-label>
            </button></mat-option
          > -->
          <mat-option>
            <button mat-icon-button (click)="inventoryTagsControlGroups()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Control Groups</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="inventoryBookDetails()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Book Details</mat-label>
            </button></mat-option
          >
          <mat-option>
            <button mat-icon-button (click)="inventoryTagsCountSummary()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Count Summary</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
          #paginator
          [pageIndex]="0"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
