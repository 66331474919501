<mat-card class="over-Flow">
    <mat-card-header>
      <mat-card-title class="title">Work With Operator Equipment</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="buttons">
        <button 
          mat-button
          (click)="operatorEquipmentCreate()"
          class="addButton buttons"
        >
          <mat-icon>add</mat-icon>
          <span>New Operator Equipment</span>
        </button>
      </div>
  
      <form [formGroup]="form" class="form over-Flow">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline"
              ><mat-label> Equipment </mat-label>
              <input matInput formControlName="equipment" />
            </mat-form-field>
         
            <mat-form-field appearance="outline"
              ><mat-label> Building </mat-label>
              <input matInput formControlName="building" />
            </mat-form-field>

            <mat-form-field appearance="outline"
              ><mat-label> Status </mat-label>
              <input matInput formControlName="status" />
            </mat-form-field>
          
        </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              class="buttons addButton"
              mat-raised-button
              type="submit"
              placeholder="Search"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  
    <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
      <ng-container matColumnDef="building">
        <th mat-header-cell *matHeaderCellDef>Building</th>
        <td mat-cell *matCellDef="let element">{{ element.building }}</td>
      </ng-container>
  
      <ng-container matColumnDef="equipNumber">
        <th mat-header-cell *matHeaderCellDef>Equipment Number</th>
        <td mat-cell *matCellDef="let element">{{ element.equipNumber }}</td>
      </ng-container>

      <ng-container matColumnDef="equipType">
        <th mat-header-cell *matHeaderCellDef>Equipment Type</th>
        <td mat-cell *matCellDef="let element">{{ element.equipType }}</td>
      </ng-container>

      <ng-container matColumnDef="maxForkCap">
        <th mat-header-cell *matHeaderCellDef>Max Fork Cap</th>
        <td mat-cell *matCellDef="let element">{{ element.maxForkCap }}</td>
      </ng-container>

      <ng-container matColumnDef="pmForShiftoRDay">
        <th mat-header-cell *matHeaderCellDef>pm For Shift or Day</th>
        <td mat-cell *matCellDef="let element">{{ element.pmForShiftoRDay }}</td>
      </ng-container>

      <ng-container matColumnDef="trailerCapable">
        <th mat-header-cell *matHeaderCellDef>trailer capable</th>
        <td mat-cell *matCellDef="let element">{{ element.trailerCapable }}</td>
      </ng-container>

      <ng-container matColumnDef="frightType">
        <th mat-header-cell *matHeaderCellDef>Fright Type</th>
        <td mat-cell *matCellDef="let element">{{ element.frightType }}</td>
      </ng-container>

      <ng-container matColumnDef="raisedForks">
        <th mat-header-cell *matHeaderCellDef>Raised Forks</th>
        <td mat-cell *matCellDef="let element">{{ element.raisedForks }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Actions">
        <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-select class="mat-select" placeholder="Select Option">
            <mat-option>
              <button mat-icon-button (click)="operatorEquipmentUpdate(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button></mat-option
            >
  
            <mat-option>
              <button mat-icon-button (click)="operatorEquipmentDelete()">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button></mat-option
            >

            <mat-option>
              <button mat-icon-button (click)="operatorEquipmentCopy(element)">
                <mat-icon mat-icon-button color="warn">call_made</mat-icon>
                <mat-label>Copy</mat-label>
              </button></mat-option
            >
          </mat-select>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[4, 8, 16]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card>
