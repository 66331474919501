import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { OrderCreate2Component } from '../order-create2/order-create2.component';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-orders-create-filled1',
  templateUrl: './orders-create-filled1.component.html',
  styleUrls: ['./orders-create-filled1.component.css']
})
export class OrdersCreateFilled1Component implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  constructor(
    private router: Router,
    private matDialog: MatDialog,
    public iOrderService: OrderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
   private dialogRef: MatDialogRef<OrdersCreateFilled1Component>
   ) 
   { }

  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({
    bolNumber:this.anyvariable[0].bolNumber,
    orderStatus:this.anyvariable[0].orderStatus,
    storerNumber:this.anyvariable[0].storerNumber,
    suffix:this.anyvariable[0].suffix,
    storerReference:this.anyvariable[0].storerReference,
    purchaseOrder:this.anyvariable[0].purchaseOrder,
    consignee:this.anyvariable[1][0].CONSCD,
    consigneeName:this.anyvariable[1][0].CONAME,
    address1:this.anyvariable[1][0].COADD1,
    address2:this.anyvariable[1][0].COADD2,
    address3:this.anyvariable[1][0].COADD3,
    address4:this.anyvariable[1][0].COADD4,
    address5:'',
       
    });
  }

  // orderCreateFilled1(){
  //   var res = this.form.value;   
  //   this.result = this.iOrderService
  //     .orderServiceCreate1( 
        
  //       res.bolNumber,
  //       res.orderStatus,
  //       res.storerNumber,
  //       res.suffix,
  //       res.storerReference,
  //       res.purchaseOrder,
  //       res.consignee,
  //       res.consigneeName,
  //       res.address1,
  //       res.address2,
  //       res.address3,
  //       res.address4,
  //       res.address5
        

  //       ).subscribe((data: any) => {
  //       this.result = data;
  //       console.log( this.result,'*******');
  //      // console.log(JSON.parse(this.result).returnedData.messaage);

  //      console.log(this.result[0].data[16].value);
  //      console.log("*********************");

  //      var length = this.result[0].data.length;

  //      // if(JSON.parse(this.result[0].data[1].value).success){
  //         if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Successfully Created'){
  //         //alert('Record Created SuccessFully');
  //        const msg= this.result[0].data[length-1].value;
  //         this.successMessage(msg)
  //         this.closeDialog();
  //         this.createOrder2();
  //        // this.router.navigate(['/main-nav/tallys/read']);
  //      }else{
  //       const msg=this.result[0].data[length-1].value;
  //       this.failureMessage(msg);
  //      }
  //     });
  // }

  // successMessage(msg:any){
  //   Swal.fire(msg,'','success');
  //   this.router.navigate(['/main-nav/item/read']);
  // }

  // failureMessage(msg:any){
  //   Swal.fire(msg,'','error');
  //  this.router.navigate(['/main-nav/item/read']);
  // }


  closeDialog(){
    this.dialogRef.close();
  }


  backstorer(){
    
  }

  createOrder2() {
    var res = this.form.value;
    this.matDialog.open(OrderCreate2Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

}
