import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConsigeeService } from '../../consignee/consigee.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { IitemService } from '../iitem.service';

@Component({
  selector: 'app-item-cross-ref',
  templateUrl: './item-cross-ref.component.html',
  styleUrls: ['./item-cross-ref.component.css']
})
export class ItemCrossRefComponent implements OnInit {

  
  form!:FormGroup
  constructor(
    public consigneeService: ConsigeeService,
    public itemSvc: IitemService,
    private router: Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemCrossRefComponent>
  ) { }

  result:any;
  dataSource:any;
  duplicateSource:any;
  duplicateSourcevalue:any;
  user=localStorage.getItem('userName');
  consCode = this.anyvariable.ITEM.toUpperCase();
  
  ngOnInit(): void {
    console.log('ANY VARIABLE : ' + this.anyvariable.ITEM)
this.duplicateSourcevalue='';
    this.itemSvc
    .getItemXRef(this.user, this.anyvariable.ITSTOR,this.anyvariable.ITSSFX,this.anyvariable.ITEM)
    .subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;

      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('Test :'+this.duplicateSource[0]&&this.duplicateSource[0].IXITEM)
      if(this.duplicateSource[0]&&this.duplicateSource[0].IXITEM)
      {
        this.duplicateSourcevalue=this.duplicateSource[0].IXITEM;
        console.log('PARENT CONSIGNEE 1 : ' + this.duplicateSource[0].IXITEM);
      }
    });
  
    this.form= this.fb.group({
      storer:this.anyvariable.ITSTOR +' '+this.anyvariable.ITSSFX,
      itemCode:this.anyvariable.ITEM,
      itemXcode:this.duplicateSourcevalue
      
    })
  }

  createItemXref() {
    console.log(this.form);
    var res = this.form.value;
    console.log(res);

    this.result = this.itemSvc
      .createItemXref(
        this.user,
       this.anyvariable.ITACDL,
       this.anyvariable.ITSTOR,
       this.anyvariable.ITSSFX,
       res.itemXcode,
       this.anyvariable.ITEM,
       this.anyvariable.ITUPCC
       )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       if(JSON.parse(this.result[0].data[1].value).success){

        //alert('Record Created SuccessFully');

       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/item/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }


  updateItemXref() {
    console.log(this.form);
    var res = this.form.value;
    console.log(res);

    this.result = this.itemSvc
      .updateItemXref(
        this.user,
       this.anyvariable.ITACDL,
       this.anyvariable.ITSTOR,
       this.anyvariable.ITSSFX,
       res.itemXcode,
       this.anyvariable.ITEM,
       this.anyvariable.ITUPCC
       )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       if(JSON.parse(this.result[0].data[1].value).success){

        //alert('Record Created SuccessFully');

       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/item/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  

  deleteConsigneeXref() {
    this.consigneeService
      .deleteConsigneeXrefService(this.user, this.consCode)
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        if(JSON.parse(this.result[0].data[1].value).success){

          //alert('Record Deleted SuccessFully');
  
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          this.router.navigate(['/main-nav/maplocation/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
  }



  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
