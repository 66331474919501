import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IitemService } from '../../iitem.service';

@Component({
  selector: 'app-item-comment-create',
  templateUrl: './item-comment-create.component.html',
  styleUrls: ['./item-comment-create.component.css']
})
export class ItemCommentCreateComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user =localStorage.getItem('userName');

  constructor(
    private itemService : IitemService,
   // private toast : ToastrService,
   private router : Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemCommentCreateComponent>
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      fileId: this.anyvariable.fileId,
      key:this.anyvariable.key, // Storer
      subCode:'',
      sequence:'',
      text:['', [Validators.required]]
     });
  }

  createItemComment(){
    var res = this.form.value;
    this.result = this.itemService
      .createItemCommentService(
        res.key,
        res.subCode,
        res.sequence,
        res.text,
        this.user
      )
      .subscribe((data) => {
        this.result = data;

        if(JSON.parse(this.result[0].data[1].value).sucess || JSON.parse(this.result[0].data[1].value).success ){
          //var msg = 'Record Created Successfully';
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this.closeDialog();
          this.router.navigate(['/main-nav/item/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });   
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
    //this.router.navigate(['/main-nav/storer/comments']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
    this.dialogRef.close(); 
  }

}
