import { GlobalComponent } from './../../../Global-Component';
import { IbuildingsService } from './../ibuildings.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-building',
  templateUrl: './create-building.component.html',
  styleUrls: ['./create-building.component.css'],
})
export class CreateBuildingComponent implements OnInit {
  constructor(
    private router: Router,
    public ibuildingSvc: IbuildingsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<CreateBuildingComponent>
  ) {}
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  ngOnInit(): void {
    this.form = this.fb.group({
      buildingNumber: ['', [Validators.required]],
      buildingName: [null],
      address1: [null],
      address2: [null],
      zipcode: [null, Validators.pattern('[1-9]{1}[0-9]{4}')],
      suffix: [null],
      NumberOfDoors: [
        '',
        [Validators.min(0), Validators.pattern('[0-9]{1,10}')],
      ],
    });
  }

  onBack() {
    this.router.navigate(['/main-nav/building']);
  }
  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  addBuilding() {
    var res = this.form.value;
    for (var i = res.buildingNumber.length; i < 3; i++) {
      res.buildingNumber = ' ' + res.buildingNumber;
    }
    this.result = this.ibuildingSvc
      .createBuilding(
        this.user,
        res.buildingNumber,
        res.buildingName,
        res.address1,
        res.address2,
        res.zipcode,
        res.suffix,
        res.NumberOfDoors
      )
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);

        if (JSON.parse(this.result[0].data[1].value).sucess) {
          //alert('Record Created SuccessFully');
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/building/read']);
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.failureMessage(msg);
        }
      });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    // this.router.navigate(['/main-nav/tallys/read']);
  }
}
