<button mat-icon-button (click)="closeDialog()" class="closeIcon">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <button mat-button (click)="backstorer()">
        <mat-icon></mat-icon>
      </button>

      <mat-card-title style="color: rgb(18, 20, 24)">
        Add Maint Question</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Building Number </mat-label>
            <input matInput formControlName="BLDG" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Equipment  Type </mat-label>
            <input matInput formControlName="EZTYPE" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Sequence  Number </mat-label>
            <input matInput formControlName="EZSEQ" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Maintenance  Question </mat-label>
            <input matInput formControlName="EZQUES" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Maintenance  Severity </mat-label>
            <input matInput formControlName="EZSEV" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Mandatory  Question</mat-label>
            <mat-select formControlName="EZMAND">
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Status  Code </mat-label>
            <input matInput formControlName="EZSTAT" />
          </mat-form-field>
          <button
            mat-button
            class="buttons addButton"
            mat-raised-button
            (click)="MaintQuestionsAddForm()"
          >
            Add
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
