<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Customer Addresses</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>Customer Number:</mat-label>
                      <input matInput formControlName="CustomerNumber"/>
                      </mat-form-field>  
                      
                    
                     </div>
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="CustomerNumber">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            CustomerNumber
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.CustomerNumber }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Addrtype">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Addrtype
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Addrtype }}</td>
        </ng-container>
  
        <ng-container matColumnDef="CustomerName">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                CustomerName
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.CustomerName }}
                
            </td>
          </ng-container>
  
          <ng-container matColumnDef="CityStateZip">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                City/State/Zip
            </th> 
            <td mat-cell *matCellDef="let element" >{{ element.CityStateZip }}</td>
          </ng-container>

  
        <ng-container matColumnDef="Opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            Opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">

                 <mat-option>
                    <button mat-icon-button (click)="CustomerAddressesAddForm(element)">
                      <mat-icon mat-icon-button color="primary">add</mat-icon>
                      <mat-label>ADD</mat-label>
                    </button>
                
                  </mat-option>

                  
                 <mat-option>
                  <button mat-icon-button (click)="CustomerAddressChangeForm(element)">
                    <mat-icon mat-icon-button color="primary">edit</mat-icon>
                    <mat-label>Change</mat-label>
                  </button>
              
                </mat-option> 

          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
      </table>

