

<div class="main-login-page">
  <div class="black-overlay"></div>
  <div class="login-form-sec">
    <!-- <a class="login-page-logo" href=""><img src="assets/images/logo.png" alt=""></a> -->
    <form [formGroup]="accountSelelctionForm" (ngSubmit)="submit()">
      <div class="centered-content">
        <a class="login-page-logo" href="" ><img src="assets/images/logo.png" alt=""></a>
    
      <hr class="new1">
      
      <span><h3>Step 2 - Please select account</h3></span>
      </div>
        <!-- <span>Step 2</span>
      <h2>Please select account</h2> -->
      <div class="input-group"> 
        <mat-form-field appearance="outline"  class="select"  >
          <mat-label>Building Number</mat-label>
        <mat-select formControlName="building" name="buiding" id="building"  >
          <mat-option *ngFor="let opt of element" [value]="opt">
            Building: {{ opt.buildingNumber }} & Storer:
                  {{ opt.storerNumber }}
          </mat-option>  <!-- 
         <mat-option value="2">Building & Storer Number</mat-option>
          <mat-option value="3">Building & Storer Number</mat-option>
          <mat-option value="4">Building & Storer Number</mat-option>-->
        </mat-select>

      </mat-form-field>
 
      </div>
      <!-- <a class="login-cnt-btn" href="">Submit</a> -->
      <button class="login-cnt-btn"  type="submit">Submit</button>
    </form>
  </div>
</div>