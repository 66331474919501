import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { ConsigeeService } from '../consigee.service';

@Component({
  selector: 'app-consignee-update',
  templateUrl: './consignee-update.component.html',
  styleUrls: ['./consignee-update.component.css']
})
export class ConsigneeUpdateComponent implements OnInit {

  form!: FormGroup;

  constructor(
    //public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    public consigneeService: ConsigeeService,
    public router:Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ConsigneeUpdateComponent>
  ) { }

  result:any;
  user=localStorage.getItem('userName');

  ngOnInit(): void {
    console.log(this.anyvariable)
    this.form= this.fb.group({
      ConsigneeCode:this.anyvariable.CONSCD,  //CONSCD
      Name:this.anyvariable.CONAME,        // CONAME
      address1:this.anyvariable.COADD1, //COADD1
      address2:this.anyvariable.COADD2, //COADD2
      address3:this.anyvariable.COADD3, //COADD3
      address4:this.anyvariable.COADD4, //COADD4
      ZipCode:this.anyvariable.COZIP, //COZIP
      zipcodeSuffix:this.anyvariable.COZSFX, //COZSFX
      ZoneCode:this.anyvariable.COZONE,  //COZONE
      areaCodeOfPhoneNumber:this.anyvariable.COPHAR, //COPHAR
      firstThreeDigOfPhNum:this.anyvariable.COPHEX, //COPHEX
      finalFourDigOfPhNum:this.anyvariable.COPHNU, //COPHNU
      DestinationCode:this.anyvariable.CODEST, //CODEST
     
    })
  }

  updateConsignee(){
    var res = this.form.value;
    console.log(res);

    this.result = this.consigneeService
      .updateConsignee(
        res.ConsigneeCode,
        res.Name,
        res.address1,
        res.address2,
        res.address3,
        res.address4,
        res.ZipCode,
        res.zipcodeSuffix,
        res.ZoneCode,
        res.areaCodeOfPhoneNumber,
        res.firstThreeDigOfPhNum,
        res.finalFourDigOfPhNum,
        res.DestinationCode,
       this.user)
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       // var msg = JSON.parse(this.result).returnedData.message;

       // this.successMessage(msg);
       if(JSON.parse(this.result[0].data[1].value).success){
        //alert('Record Created SuccessFully');
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/consignee/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
