import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { CarriersAddComponent } from './carriers-add/carriers-add.component';
import { CarriersContactsComponent } from './carriers-contacts/carriers-contacts.component';
import { CarriersDeleteComponent } from './carriers-delete/carriers-delete.component';
import { CarriersEditComponent } from './carriers-edit/carriers-edit.component';
import { CarriersFakTransComponent } from './carriers-fak-trans/carriers-fak-trans.component';
import { WwCarEquipComponent } from './ww-car-equip/ww-car-equip.component';

@Component({
  selector: 'app-ww-carriers',
  templateUrl: './ww-carriers.component.html',
  styleUrls: ['./ww-carriers.component.css']
})
export class WwCarriersComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  displayedColumns: string[] = [
    'SCACCode',
    'CarrierName',
    'HM',
    'PE',
    'CityState',
    'Status',
    'Opt',
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      SCACCode:'ODFL' ,
      CarrierName:'Old Dominion',
      HM:'Y',
      PE:'N',
      CityState:'Atlanta,GA',
      Status:'AC'
      
      
    },

    

  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    // private dialogRef: MatDialogRef<AttributesComponent>
    
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      SCACCode:'',
      Name:'',
      

    
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
 
 


   }



   CarrierChangeForm(element:any){
    this.matDialog.open(CarriersEditComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      'data': element,
      "autoFocus": false                           
    });
  }
  CarrierDeleteForm(element:any){
    this.matDialog.open(CarriersDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      'data': element,
      "autoFocus": false                           
    });
  }
  CarrierAddForm(element:any){
    this.matDialog.open(CarriersAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      'data': element,
      "autoFocus": false                           
    });
  }

  CarrierFakTrans(element:any){
    this.matDialog.open(CarriersFakTransComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      'data': element,
      "autoFocus": false                           
    });
  }

  CarrierEquip(element:any){
    this.matDialog.open(WwCarEquipComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      'data': element,
      "autoFocus": false                           
    });
  }

  CarrierContacts(element:any){
    this.matDialog.open(CarriersContactsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      'data': element,
      "autoFocus": false                           
    });
  }



addAttribute(){

}


}
