<mat-card>
    <mat-card-header>  
      <mat-card-title >Work with Email Format</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        
        <div class="formDiv">
          <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                          <mat-form-field appearance="outline">
                            <mat-label>DocumentName</mat-label>
                            <input matInput formControlName="DocumentName"/>
                          </mat-form-field>

                              
                    </div>
                </div>
            </form>
        </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Record">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Record
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Record }}</td>
            </ng-container>
            <ng-container matColumnDef="Content">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Content
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.Content }}</td>
            </ng-container>
      
   
            <ng-container matColumnDef="Action">
                <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
                    <mat-option>
                        <button mat-icon-button (click)=" changeEmailFormat(element)">
                          <mat-icon mat-icon-button color="primary">edit</mat-icon>
                          <mat-label>Change</mat-label>
                        </button>
                    </mat-option>

                    <mat-option>
                        <button mat-icon-button (click)=" deleteEmailFormat(element)">
                          <mat-icon mat-icon-button color="warn">delete</mat-icon>
                          <mat-label>Delete</mat-label>
                        </button>
                    </mat-option>

                    <mat-option>
                        <button mat-icon-button  (click)="insertBeforeEmailFormat(element)">           
                          <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                          <mat-label>Insert Before</mat-label>
                        </button>
                    </mat-option>
                    <mat-option>
                        <button mat-icon-button  (click)="insertAfterEmailFormat(element)">           
                          <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                          <mat-label>Insert After</mat-label>
                        </button>
                    </mat-option>                
                </mat-select>
                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            
        </table>
    </mat-card-content>
</mat-card>

