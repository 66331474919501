import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unload-pallet',
  templateUrl: './unload-pallet.component.html',
  styleUrls: ['./unload-pallet.component.css'],
})
export class UnloadPalletComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UnloadPalletComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      PalletSerial: '00000000000000',
      OrderNumber: 4790417,
      DocumentNumber: 4255,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  unload() {
    console.log('unloaded');
  }
}
