<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With extract Filters</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
  
  
      
      <div class="buttons">
        <button mat-button (click)="addextractReportFilters()"  class="addButton">
          <mat-icon>add</mat-icon>
          <span>New Extract Filter</span>
        </button>
      </div>
     
     <div class="formDiv">
      <form [formGroup]="form">
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Filter Name:</mat-label>
            <input matInput formControlName="FilterName"/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Program Name:</mat-label>
            <input matInput formControlName="ProgramName"/>
          </mat-form-field>

        </div>     
      </form>
     </div>
     
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Filter Name">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Filter Name
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.FilterName }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Program">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Program
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Program }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Description">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Description
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
        </ng-container>

  
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          <mat-option>
            <button mat-icon-button  (click)="changeextractReportFilters(element)">           
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button>
          </mat-option>

          <mat-option>
            <button mat-icon-button  (click)="copyextractReportFilters(element)">           
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Copy</mat-label>
            </button>
          </mat-option>
  
       
  
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
    </mat-card-content>
  </mat-card>


