
<div fxLayout="row" fxLayoutAlign="center center">
  <form [formGroup]="loginForm" novalidate (ngSubmit)="login()">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Login</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="User Name"
                formControlName="username"
              />
              <mat-error
                *ngIf="loginForm.controls['username'].hasError('required')"
              >
                Username is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <mat-form-field class="full-width">
              <input
                matInput
                placeholder="Password"
                formControlName="password"
              />
              <mat-error
                *ngIf="loginForm.controls['password'].hasError('required')"
              >
                Password is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <div fxFlex></div>
        <button mat-raised-button color="primary" type="submit">Login</button>
      </mat-card-actions>
    </mat-card>
  </form>
</div>
