<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Forecast </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="addForecast()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>New Forecast</span>
      </button>
    </div>
    <form class="form over-Flow" [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="IFSTOR" />
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label>Reference</mat-label>
            <input matInput formControlName="IFSREF" />
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label>Release</mat-label>
            <input matInput formControlName="IFRELS" />
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label>Consignee</mat-label>
            <input matInput formControlName="IFCONS" />
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label>Status</mat-label>
            <input matInput formControlName="IFSTAT" />
          </mat-form-field>

          <button
            class="addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="Storer">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Storer
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IFSTOR }}</td>
    </ng-container>

    <ng-container matColumnDef="Consignee">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Consignee
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IFCONS }}</td>
    </ng-container>

    <ng-container matColumnDef="ForecastDate">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Forecast Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.FDAT }}
      </td>
    </ng-container>

    <ng-container matColumnDef="FT">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>FT</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IFFTYP }}
      </td>
    </ng-container>

    <ng-container matColumnDef="DT">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>DT</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IFDTYP }}
      </td>
    </ng-container>

    <ng-container matColumnDef="BeginHorizon">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Begin Horizon
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.BHOR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="EndHorizon">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        End Horizon
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.EHOR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="QT">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>QT</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IFQTYP }}
      </td>
    </ng-container>
    <ng-container matColumnDef="UOM">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        UOM
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IFUOM }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ST">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>ST</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IFSTAT }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Release">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Release
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IFRELS }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Supplier">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Supplier
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IFSPLR }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Reference">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Reference
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IFSREF }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option (click)="getUpdateForm(element)">
            <button mat-icon-button>
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >
          <mat-option (click)="getDeleteForm(element)">
            <button mat-icon-button>
              <mat-icon mat-icon-button color="primary">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
          <mat-option (click)="getDetailsForm(element)">
            <button mat-icon-button>
              <mat-icon mat-icon-button color="primary">details</mat-icon>
              <mat-label>Details</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
              #paginator
              [pageIndex]="0"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator> -->
  <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
  <router-outlet></router-outlet>
</mat-card>
