<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Postal Abbrevations</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <div class="buttons">
          <button mat-button  class="addButton" (click)="addPostalAbbrevation()">
            <mat-icon>add</mat-icon>
            <span>New Postal Abbrevation</span>
          </button>
        </div>

        <div class="formDiv">
            <form [formGroup]="form">
              <div class="row">
                <mat-form-field appearance="outline">
                  <mat-label>Country</mat-label>
                  <input matInput formControlName="Country"/>
                </mat-form-field>
        
                <mat-form-field appearance="outline">
                  <mat-label>Type</mat-label>
                  <input matInput formControlName="Type"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Abbrevation</mat-label>
                    <input matInput formControlName="Abbrevation"/>
                  </mat-form-field>
              </div>     
            </form>
           </div>

           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="Country">
              <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
                Country
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.Country }}</td>
            </ng-container>
      
            <ng-container matColumnDef="Abbrev Type">
              <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
                Abbrev Type
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.AbbrevType }}</td>
            </ng-container>
      
            <ng-container matColumnDef="Abbrev">
              <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
                Abbrev
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.Abbrev }}</td>
            </ng-container>
      
            <ng-container matColumnDef="Value">
              <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
                Value
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.Value }}</td>
            </ng-container>
      
            <ng-container matColumnDef="Actions">
                <th  mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
                    <mat-option>
                        <button mat-icon-button  (click)="changePostalAbbrevation(element)">           
                          <mat-icon mat-icon-button color="primary">edit</mat-icon>
                          <mat-label>Edit</mat-label>
                        </button>
                      </mat-option>

                      <mat-option>
                        <button mat-icon-button (click)="deletePostalAbbrevation(element)">           
                          <mat-icon mat-icon-button color="warn">delete</mat-icon>
                          <mat-label>Delete</mat-label>
                        </button>
                      </mat-option>
                  </mat-select>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            
        </table>
        
    <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
    </mat-card-content>
</mat-card>
