<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Data Elements</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button
        mat-button
        (click)="dataElementCreate()"
        class="addButton buttons"
      >
        <mat-icon>add</mat-icon>
        <span>Create Data Element</span>
      </button>
    </div>

    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Data Element Code </mat-label>
            <input matInput formControlName="dataElementCode" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
    <ng-container matColumnDef="ElementCode">
      <th mat-header-cell *matHeaderCellDef>Element Code</th>
      <td mat-cell *matCellDef="let element">{{ element.ElementCode }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
    </ng-container>

    <ng-container matColumnDef="Internal/External">
      <th mat-header-cell *matHeaderCellDef width="60px">Internal/External</th>
      <td mat-cell *matCellDef="let element">
        {{ element.InternalExternal }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="dataElementUpdate()">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="dataElementDelete()">
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="ReasonCode()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Reason Code</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
