<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Maintain Storer Item Defaults</mat-card-title
      >
      &nbsp;
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
            <div class="col">
              
                <mat-form-field  appearance="outline">
                    <mat-label> Storer: </mat-label> 
                    <input  matInput formControlName="storeNumber" style="text-transform: uppercase"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                  <mat-label> Suffix: </mat-label> 
                  <input   matInput formControlName="suffix" style="text-transform: uppercase"/>
              </mat-form-field>

            <mat-form-field  appearance="outline">
              <mat-label> Store Name: </mat-label> 
              <input matInput   formControlName="storeName" style="text-transform: uppercase"/>
          </mat-form-field>
           
               <div style="margin-left: 20px"><b>Inventory Labels</b></div>
                 <mat-form-field appearance="outline" >   
                    <mat-label> User1: </mat-label>
                    <input matInput formControlName="user1"/>
                </mat-form-field>
           
             
                 <mat-form-field appearance="outline" >
                    <mat-label> User2:</mat-label>
                    <input matInput formControlName="user2"/>
                </mat-form-field>

                <mat-form-field appearance="outline" >
                  <mat-label> User3:</mat-label>
                  <input matInput formControlName="user3"/>
              </mat-form-field>
           
                 <mat-form-field appearance="outline" >
                    <mat-label> User4:</mat-label>
                    <input matInput formControlName="user4"/>
                </mat-form-field>
                </div>
           
              <div>
                <mat-form-field >
                  <mat-label> All Items Lotted: </mat-label>
                  <mat-select formControlName="allItemsLotted">
                    <mat-option value="Y">Yes</mat-option>
                    <mat-option value="N">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
         
                <div style="margin-left: 20px"><b> Lot Code Labels </b></div>
                 <mat-form-field appearance="outline" >
                    <mat-label> Label 1: </mat-label>
                    <input matInput formControlName="lotCodeLabel1" maxlength="10"/>
                      <mat-error *ngIf="form.controls['lotCodeLabel1'].hasError('pattern')">
                        Only <strong>characters </strong> are allowed
                      </mat-error>
                      <mat-error *ngIf="form.controls['lotCodeLabel1'].hasError('maxlength')">
                        only 10 characters are <strong>allowed</strong>
                    </mat-error>
                </mat-form-field>
       
              
                 <mat-form-field appearance="outline" >  
                    <mat-label> Label 2: </mat-label>
                    <input matInput formControlName="lotCodeLabel2" maxlength="10"/>
                      <mat-error *ngIf="form.controls['lotCodeLabel2'].hasError('pattern')">
                        Only <strong>characters </strong> are allowed
                      </mat-error>
                      <mat-error *ngIf="form.controls['lotCodeLabel2'].hasError('maxlength')">
                        only 10 characters are <strong>allowed</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" >  
                  <mat-label> Label 3: </mat-label>
                  <input matInput formControlName="lotCodeLabel3" maxlength="10"/>
                      <mat-error *ngIf="form.controls['lotCodeLabel3'].hasError('pattern')">
                        Only <strong>characters </strong> are allowed
                      </mat-error>
                      <mat-error *ngIf="form.controls['lotCodeLabel3'].hasError('maxlength')">
                        only 10 characters are <strong>allowed</strong>
                    </mat-error>
              </mat-form-field>
            
            
               <div>
                 <mat-form-field appearance="outline" >
                     <mat-label>Primary Mask:</mat-label>
                     <mat-select formControlName="primaryMask">
                      <mat-option value="1">1</mat-option>
                      <mat-option value="2">2</mat-option>
                      <mat-option value="3">3</mat-option>
                    </mat-select>
                    <mat-hint >Select no.of masks</mat-hint>
                </mat-form-field>
              </div>

                <div style="margin-left: 20px">Sort Mask:</div>
                 <mat-form-field appearance="outline" >
                    <mat-label>Sort Mask 1 :</mat-label>
                    <input matInput formControlName="sortMask1"/>
                </mat-form-field>
           
                
                <mat-form-field appearance="outline" >
                  <mat-label>Sort Mask 2 :</mat-label>
                  <input matInput formControlName="sortMask2"/>
              </mat-form-field>

              <mat-form-field appearance="outline" >
                  <mat-label>Sort Mask 3 :</mat-label>
                  <input matInput formControlName="sortMask3"/>
              </mat-form-field>

            
              <button mat-button (click)="storerUpdate2()" class="addButton buttons">
                  
                  <span>Update</span>
                </button>
          
              </form> 
    </mat-card-content>
   
  </mat-card>



