import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { StorerServiceService } from '../../storer-service.service';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';

@Component({
  selector: 'app-storer-contact-delete',
  templateUrl: './storer-contact-delete.component.html',
  styleUrls: ['./storer-contact-delete.component.css']
})
export class StorerContactDeleteComponent implements OnInit {

  form!: FormGroup;
  result: any;
 // user = GlobalComponent.user;
 user = localStorage.getItem('userName');

  constructor(
    public iHeaderSvc: IheaderService,
    private storerContactService : StorerServiceService,
    private CustomAlertService: CustomAlertService,
    private fb: FormBuilder,
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<StorerContactDeleteComponent>

  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      Consignee: this.anyvariable.CTCONS,
      contactName:this.anyvariable.CTNAME,
      telePhone:this.anyvariable.CTTELN,
      cellPhone:this.anyvariable.CTCELN,
      contactType:this.anyvariable.CTTYPE,
      faxNumber:this.anyvariable.CTFAXN,
      emailAddress:this.anyvariable.CTEMAL,
      emailFormat:this.anyvariable.CTEFMT,
      statusCode:this.anyvariable.CTSTAT
     });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  deleteStorerContact(){
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete contact name :' + this.anyvariable.CTNAME + '?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result){
      this.result = this.storerContactService
        .deleteStorerContact(this.user,this.anyvariable.CTNAME, this.anyvariable.CTTYPE,this.anyvariable.CTCONS)
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);

          if (JSON.parse(this.result[0].data[1].value).success) {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
              this.closeDialog();
            this.successMessage(msg);
           
          } else {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.failureMessage(msg);
            
          }
        });
    }
  });
  }


  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/storer']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
}
