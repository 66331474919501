import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  // BASE_URL = 'http://localhost:4200/api';
  //BASE_URL = 'http://170.249.89.98:5200/api';
  url = environment.API_BASE_URL;
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  Login(userData: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });


    
    
    // let user = { username: 'VREDDY', password: 'vk58gaw' };
    return this.httpClient
      .post(this.url+'/user/login', userData)
     // .pipe(
       // map((res: any) => res),
       
       // catchError(this.httpError)
     // ); 
  }


  deleteAccountUser(user: string): Observable<any> {
    
    return this.httpClient.delete(
      this.url+`/account/delete/?userName=${user}`
    );
  }


  

  createAccountUser(
    userName: any,
    name: any,
    storerNumber: any,
    storerSuffix: any,
    buildingNumber: any,
    status: any,
    
    
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url+'/account/create', {
        userName,
        name,
        storerNumber,
        storerSuffix,
        buildingNumber,
        status
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateAccountUser(
    userName: any,
    name: any,
    storerNumber: any,
    storerSuffix: any,
    buildingNumber: any,
    status: any,
    
    
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/account/update', {
        userName,
        name,
        storerNumber,
        storerSuffix,
        buildingNumber,
        status
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
