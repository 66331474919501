import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsigeeService } from '../../consigee.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-consignee-comments-update',
  templateUrl: './consignee-comments-update.component.html',
  styleUrls: ['./consignee-comments-update.component.css']
})
export class ConsigneeCommentsUpdateComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user =localStorage.getItem('userName');

  constructor(
    private ConsigneeService : ConsigeeService,
   // private toast : ToastrService,
   private router : Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ConsigneeCommentsUpdateComponent>
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      fileId: this.anyvariable.CMRECD,
      key:this.anyvariable.CMKEY,
      subCode:this.anyvariable.CMSUBD,
      sequence:this.anyvariable.CMSEQ,
      text:[this.anyvariable.CMCOMM, [Validators.required]]
     });
  }

  updateConsigneeComment(){
    var res = this.form.value;
    this.result = this.ConsigneeService
      .updateConsigneeCommentService(
        res.key,
        res.subCode,
        res.sequence,
        res.text,
        this.user
      )
      .subscribe((data) => {
        this.result = data;

        if(JSON.parse(this.result[0].data[1].value).sucess || JSON.parse(this.result[0].data[1].value).success ){
          //var msg = 'Record Created Successfully';
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/consignee/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });   
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/consignee/read']);
    //this.router.navigate(['/main-nav/storer/comments']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
    this.dialogRef.close(); 
  }

}
