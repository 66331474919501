<mat-card>
    <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
    <mat-card-header>
        <mat-card-title>
            <!-- <button class="backButton" mat-button (click)="onBack()">
                <mat-icon>navigate_before </mat-icon>
            </button> -->

             Labor Content Details</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="formDiv">
            <form [formGroup]="form">
                <div class="row">
                    <mat-form-field appearance="outline">
                        <mat-label>Document</mat-label>
                        <input matInput formControlName="Document" />
                    </mat-form-field>   
                </div>
            </form>

            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="ProcessTask">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        ProcessTask
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.ProcessTask }}</td>
                </ng-container>

                <ng-container matColumnDef="MoveType">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        MoveType
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.MoveType }}</td>
                </ng-container>

                <ng-container matColumnDef="ReleaseDate">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        ReleaseDate
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.ReleaseDate }}</td>
                </ng-container>

                <ng-container matColumnDef="ReleaseTime">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        ReleaseTime
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.ReleaseTime }}</td>
                </ng-container>

                <ng-container matColumnDef="CompletedDate">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        CompletedDate
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.CompletedDate }}</td>
                </ng-container>

                <ng-container matColumnDef="CompletedTime">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        CompletedTime
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.CompletedTime }}</td>
                </ng-container>

                <ng-container matColumnDef="Operator">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        Operator
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.Operator }}</td>
                </ng-container>
                <ng-container matColumnDef="OR">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        OR
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.OR }}</td>
                </ng-container>
                <ng-container matColumnDef="ST">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        ST
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.ST }}</td>
                </ng-container>
                <!-- <ng-container matColumnDef="Opt">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>
                        Opt
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-select placeholder="Select Option">
                        </mat-select>
                    </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


            </table>