<!-- <div class="buttons">
  <button class="backButton" mat-button (click)="onBack()">
    <mat-icon>navigate_before </mat-icon>
  </button>
</div> -->

<mat-card>
  <mat-card-header>
    <mat-card-title>Orders</mat-card-title>

    <div class="buttons">
      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search the Order"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>

      <mat-menu #mainMenuButton="matMenu">
        <span
          (click)="$event.stopPropagation()"
          *ngFor="let column of columnShowHideList; index as i"
        >
          <mat-slide-toggle
            style="color: blue"
            [(ngModel)]="column.isActive"
            (change)="toggleColumn(column)"
          >
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button mat-button (click)="addOrder()" class="addButton">
        <mat-icon>add</mat-icon>
        <!-- <span>New Orders</span> -->
      </button>
      <button mat-button matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn" #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton">
        <mat-icon>visibility_off</mat-icon>
      </button>
    </div>
  </mat-card-header>


  <mat-card-content class="tally-serach-form">
    <form class="form over-Flow"
      [formGroup]="updateForm"
      *ngIf="searchTextDisplay"
      (ngSubmit)="readOneOrder(updateForm.value)"
    >
    <div class="row">
      <div class="col">
      <mat-form-field appearance="outline">
        <mat-label>Bill No</mat-label>
        <input matInput formControlName="billNo" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Ref No</mat-label>
        <input matInput formControlName="refNo" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>P.O. No</mat-label>
        <input matInput formControlName="poNo" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>PRO No</mat-label>
        <input matInput formControlName="proNo" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Consignee Number</mat-label>
        <input matInput formControlName="consignee" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Carrier</mat-label>
        <input matInput formControlName="carrier" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Ship Dates</mat-label>
        <input matInput formControlName="shipDates" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Status</mat-label>
        <input matInput formControlName="status" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Building</mat-label>
        <input matInput formControlName="bldg" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Storer Number</mat-label>
        <input matInput formControlName="storer" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Trk</mat-label>
        <input matInput formControlName="trk" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Broker</mat-label>
        <input matInput formControlName="broker" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Open / All</mat-label>
        <input matInput formControlName="openAll" />
      </mat-form-field>
    </div>
  </div>
      <button
        class="buttons addButton"
        mat-raised-button
        type="submit"
        placeholder="Search"
      >
        Search
      </button>
    </form>
  </mat-card-content>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="Sts">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.OHSTAT }}</td>
    </ng-container>
    <ng-container matColumnDef="OrderNumber">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Order Number</th>
      <td mat-cell *matCellDef="let element">{{ element.OHBILL }}</td>
    </ng-container>
    <ng-container matColumnDef="OT">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>OT</th>
      <td mat-cell *matCellDef="let element">{{ element.OT }}</td>
    </ng-container>
    <ng-container matColumnDef="StorerNumber">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Storer Number</th>
      <td mat-cell *matCellDef="let element">{{ element.OHSTOR }}</td>
    </ng-container>
    <ng-container matColumnDef="ShpTyp">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Ship Type</th>
      <td mat-cell *matCellDef="let element">{{ element.OHSHPT }}</td>
    </ng-container>
    <ng-container matColumnDef="SchedShip">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Scheduled Ship date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.OHSCHS | date : this.GlobalComponent.DATE_FORMAT }}
      </td>
    </ng-container>
    <ng-container matColumnDef="ActualShip">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Actually Shipped
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.OHACTS | date : this.GlobalComponent.DATE_FORMAT }}
      </td>
    </ng-container>
    <ng-container matColumnDef="StorerReference">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>StorerReference</th>
      <td mat-cell *matCellDef="let element">
        {{ element.OHSREF }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Consignee">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Consignee</th>
      <td mat-cell *matCellDef="let element">
        {{ element.OHCONS }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Carrier">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Carrier</th>
      <td mat-cell *matCellDef="let element">
        {{ element.OHCAR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="PONumber">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>P. O. Number</th>
      <td mat-cell *matCellDef="let element">
        {{ element.OHPO }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="OrdersUpdateForm(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="OrdersDeleteForm(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="OrderEventHistory(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Event-History</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="orderDship(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Order-Deship</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="OrderSendToMoveQueue(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Send Order To Move</mat-label>
              </button>
            </li>
            <li>
              <button
                mat-icon-button
                (click)="OrderMoveToHistoryInquiry(element)"
              >
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Move To History Location</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="OrderComments(element)">
                <mat-icon mat-icon-button color="primary">comment</mat-icon>
                <mat-label>Order Comments</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="orderShipConfirm(element)">
                <mat-icon mat-icon-button color="primary">comment</mat-icon>
                <mat-label>Order Ship Confirm</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="orderDetails(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Order Details</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="EventEntry(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Event Entry </mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
