import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IItemLocationService } from '../i-item-location.service';
import Swal from 'sweetalert2';
import { StorerServiceService } from 'src/app/features/storer/storer-service.service';
@Component({
  selector: 'app-side-adjustment',
  templateUrl: './side-adjustment.component.html',
  styleUrls: ['./side-adjustment.component.css'],
})
export class SideAdjustmentComponent implements OnInit {
  form!: FormGroup;

  constructor(
    public iItemLcnSvc:IItemLocationService,
    public storerService : StorerServiceService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<SideAdjustmentComponent>
  ) {}

  result:any;
  duplicateSource:any;
  user = localStorage.getItem('userName');
  fullStorer: any;
  fullItem = this.anyvariable[0].ITEM + '  ' + this.anyvariable[0].ITDSC1;

  ngOnInit(): void {

    console.log('ITEM AND ITEM LOCATION ARRAY DATA');
    console.log(this.anyvariable);
    
    // ----- CALL TO STORER FOR STORER NAME -----
    this.storerService.getAll(this.user, this.anyvariable[1].LCSTOR).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('STORER NAME FOR 1-SIDE ADJUSTMENT');
      console.log(this.duplicateSource[0].STNAME);
      this.fullStorer = this.anyvariable[1].LCSTOR + '  ' + this.duplicateSource[0].STNAME;
    });

    this.form = this.fb.group({
      storer: this.anyvariable[1].LCSTOR,
      sfx: '',
      item: this.anyvariable[1].LCITEM,
      lot1: this.anyvariable[1].LCLTC1,
      lot2: this.anyvariable[1].LCLTC2,
      lot3: this.anyvariable[1].LCLTC3,
      bldg: this.anyvariable[1].LCBLDG,      
      floor: this.anyvariable[1].LCFLOR,
      aisl: this.anyvariable[1].LCAISL,
      slot: this.anyvariable[1].LCSLOT,      
      line: this.anyvariable[1].LCLSEQ,
      received: this.anyvariable[1].LCRCVD,
      available: this.anyvariable[1].LCQAVL,
      held: this.anyvariable[1].LCQHLD,
      committed: this.anyvariable[1].LCQCOM,
      user1: this.anyvariable[1].LCQUS1,
      user2: this.anyvariable[1].LCQUS2,
      user3: this.anyvariable[1].LCQUS3,
      user4: this.anyvariable[1].LCQUS4,
      damaged: this.anyvariable[1].LCQDAM,

      adjustmentBucket: '',
      quantity: '',
      reasonCode: '',
      referBolWr: '',
      number: '',
      comment:'',

      lots: this.anyvariable[1].LCLTC1 + ' | ' + this.anyvariable[1].LCLTC2 + ' | ' + this.anyvariable[1].LCLTC3,
      location: this.anyvariable[1].LCBLDG + '   ' + this.anyvariable[1].LCFLOR + '  ' + this.anyvariable[1].LCAISL + '/' + this.anyvariable[1].LCSLOT,
    });
  }

  oneSideAdj() {
  
    var res = this.form.value;
    this.iItemLcnSvc
      .ItemLocOneSideAdj(
        res.storer,
        res.sfx,
        res.item,
        res.lot1,
        res.lot2,
        res.lot3,
        res.bldg,
        res.floor,
        res.aisl,
        res.slot,
        res.adjustmentBucket,
        res.quantity,
        res.reasonCode,
        res.referBolWr,
        res.number,
        res.line,
        res.comment
      )
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[15].value;
        var length = this.result[0].data.length;

        if(this.result[0].data[length-1].value=='Adjustment Record Created Successfully'){
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
       }else{
        const msg= this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  sideAdjustment() {
    console.log(this.form.value);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
