import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorerUpdate5Component } from '../storer-update5/storer-update5.component';
import Swal from 'sweetalert2';
import { StorerServiceService } from '../storer-service.service';

@Component({
  selector: 'app-storer-update4',
  templateUrl: './storer-update4.component.html',
  styleUrls: ['./storer-update4.component.css']
})
export class StorerUpdate4Component implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerUpdate4Component>,
   private matDialog: MatDialog
    ) { }

    ngOnInit(): void {

      console.log(this.anyvariable.storerNumber);
         this.form = this.fb.group({
          
          storeNumber: this.anyvariable.STORE,
          suffix: this.anyvariable.STSFX,
          storeName: this.anyvariable.STNAME,
          clInboundWeight:this.anyvariable.STCLIW,     
          clInboundCube:this.anyvariable.STCLIC,
          clOutboundWeight:this.anyvariable.STCLOW,
          clOutboundCube:this.anyvariable.STCLOC,
          tlInboundWeight:this.anyvariable.STTLIW,  
          tlInboundCube:this.anyvariable.STTLIC,
          tlOutboundWeight:this.anyvariable.STTLOW,    
          tlOutboundCube:this.anyvariable.STTLOC,
          storageInvoice:this.anyvariable.STSTIA,     
          accessorialInvoice:this.anyvariable.STASIA,
          wrInvoice:this.anyvariable.STWRIA,   
          generalInvoice:this.anyvariable.STGNIA,
          freightInvoive:this.anyvariable.STFBIA,     
          cartageInvoice:this.anyvariable.STCAIA,
          storageVariancePercent:[this.anyvariable.STINVA,[
            
            Validators.maxLength(10),
            
          ],]
         });
    }
  
    storerUpdate4(){
      var res = this.form.value;
         
      this.result = this.storerSvc
        .storerServiceUpdate4( 
          res.storeNumber,
          res.suffix,
          res.storeName,
          res.clInboundWeight,
          res.clInboundCube,
          res.clOutboundWeight,
          res.clOutboundCube,
          res.tlInboundWeight,
          res.tlInboundCube,
          res.tlOutboundWeight,
          res.tlOutboundCube,
          res.storageInvoice,
          res.accessorialInvoice,
          res.wrInvoice,
          res.generalInvoice,
          res.freightInvoive,
          res.cartageInvoice,
          res.storageVariancePercent,
          this.user
  
        ).subscribe((data: any) => {
          this.result = data;
            if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
            const msg= this.result[0].data[this.result[0].data.length-1].value;
            this.successMessage(msg)
            this.closeDialog();
            this.update5();
         }else{
          this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
         }
        });
    }
  
  
    successMessage(msg:any){
      Swal.fire(msg,'','success');
      //this.router.navigate(['/main-nav/storer/read']);
    }
  
    failureMessage(msg:any){
      Swal.fire(msg,'','error');
     // this.router.navigate(['/main-nav/storer/read']);
    }
  
    update5() {
      var res = this.anyvariable;
      this.matDialog.open(StorerUpdate5Component, {        
        width: '1000px',
        maxHeight: '80vh',  
        data: res,
        autoFocus: false,
      });
    }

  closeDialog(){
    this.dialogRef.close();
  }

}
