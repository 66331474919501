import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IheaderService {
  url = environment.API_BASE_URL;
  // BASE_URL = 'http://170.249.89.98:5200/api';
  constructor(private httpClient: HttpClient) {
  console.log(this.url)

    // if (environment.API_BASE_URL) {
    //   var BASE_URL = environment.API_BASE_URL;
    // }
  }
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getAll(user: string, building: number): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/read?user=${user}&building=${building}`
    );
  }

  getAll2(user: string, building: number, storer:any, storerSuffix:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/read?user=${user}&building=${building}&storer=${storer}&storerSuffix=${storerSuffix}`
    );
  }

  getTallyPostErrors(user: any, tally: any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/tallyPostingErrors?user=${user}&tally=${tally}`
    );
  }

  getTallyEvents(user: any, tally: any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/tallyEvents?user=${user}&tally=${tally}`
    );
  }

  getTallyDetails(user: any, tally: any, idacl:any, sequence:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/readTallyDetail?user=${user}&tally=${tally}&idacl=${idacl}&sequence=${sequence}`
    );

  }

  getTallyDetails2(user: any, tally: any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/tallyDetailsRead?user=${user}&tally=${tally}`
    );
  }

  readTallyInquiryService(user: any, tally: any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/tallyinquiry?user=${user}&tally=${tally}`
    );
  }

  getTallyPutwayLocations(user:any,tally: any,sequence:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/tallyDtlsPutawayLoc/read?user=${user}&tally=${tally}&sequence=${sequence}`
    );
  }

  getByTally2(user: string, tally: any, storer:any, carrier:any, ref:any, schdldArrival:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/read/tally?user=${user}&tally=${tally}&storer=${storer}&carrier=${carrier}&ref=${ref}&schdldArrival=${schdldArrival}`
    );
  }

  getByTally(user: string, tally: any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/read/tally?user=${user}&tally=${tally}`
    );
  }

  getComment(tally: number): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/comments?query=iputtagunt&tallyNumber=${tally}`
    );
  }

  getTallyComment(user:any,status:any,tally:any,sequence:any,sub:any): Observable<any> {
    return this.httpClient.get(
      this.url+`/ihcrud/readTallyComments?user=${user}&status=${status}&tally=${tally}&sequence=${sequence}&sub=${sub}`
    );
  }


  getTallyDetailComment(tally: number): Observable<any> {
    return this.httpClient.get(
       this.url+`/ihcrud/tallyDetailComments?query=iputtagunt&tallyNumber=${tally}`
    );
  }

  getAccountSelection(user:string): Observable<any> {
    return this.httpClient.get(
       this.url+`/account/read?userName=${user}`
    );
  }

  createTallyDetail1(user: any): Observable<any> {
    const createUrl = this.url+`/ihcrud/createTallyDetail` 
    return this.httpClient
      .post<any>(createUrl, { user })
      .pipe(retry(1), catchError(this.httpError));
  }

  createTallyDetail(
    tally: any,
    user: any,
    storer:any,
    building:any,
    quantity: any,
    item: any,
    lotCode1: any,
    lotCode2: any,
    lotCode3: any,
    rateType: any,
    dmgReas: any,
    overrideWeight: any
    
    
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url+'/ihcrud/createTallyDetail', {
        tally,
        user,
        storer,
        building,
        quantity,
        item,
        lotCode1,
        lotCode2,
        lotCode3,
        rateType,
        dmgReas,
        overrideWeight,
       
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  TallyDetail(
    tally: any,
    user: any,
    storer:any,
    building:any,
    quantity: any,
    item: any,
    lotCode1: any,
    lotCode2: any,
    lotCode3: any,
    rateType: any,
    dmgReas: any,
    overrideWeight: any
    
    
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url+'/ihcrud/createTallyDetail', {
        tally,
        user,
        storer,
        building,
        quantity,
        item,
        lotCode1,
        lotCode2,
        lotCode3,
        rateType,
        dmgReas,
        overrideWeight,
       
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateTallyDetail(
    user: any,
    tally: any,
    sequence:any,
    storer:any,
    building:any,
    quantity: any,
    item: any,
    lotCode1: any,
    lotCode2: any,
    lotCode3: any,
    rateType: any,
    dmgReas: any,
    overrideWeight: any
    
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/ihcrud/updateTallyDetail', {
        quantity,
        item,
        lotCode1,
        lotCode2,
        lotCode3,
        rateType,
        dmgReas,
        overrideWeight,
        tally,
        sequence,
        user,
        building,
        storer
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  createTally(
    storer: any,
    storerRef: any,
   
    loadType: any,
    recptType: any,
    recvdFrom: any,
    qty: any,
    schdldArrival: any,
    carrier: any,
    actualArrival: any,
    building: any,
    pro: any,
    wgt: any,
    user: any,
    schdldtime: any,
    actualtime: any,
    authorization: any,
    car: any,
    seal1: any,
    seal2: any,
    invoiceDate: any,
    returnValue: any,
    broken1: any,
    broken2: any,
    specialCharge: any,
    notification:any,
    chargeType:any,
    billTo:any,
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url+'/ihcrud/create', {
        storer,
        storerRef,
        loadType,
        recptType,
        recvdFrom,
        qty,
        schdldArrival,
        carrier,
        actualArrival,
        building,
        pro,
        wgt,
        user,
        schdldtime,
        actualtime,
        authorization,
        car,
        seal1,
        seal2,
        invoiceDate,
        returnValue,
        broken1,
        broken2,
        specialCharge,
        notification,
        chargeType,
        billTo
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateTally(
    
    storer: any,
    storerRef: any,
    loadType: any,
    recptType: any,
    recvdFrom: any,
    qty: any,
    schdldArrival: any,
    carrier: any,
    actualArrival: any,
    building: any,
    pro: any,
    wgt: any,
    user: any,
    schdldtime: any,
    actualtime: any,
    authorization: any,
    car: any,
    seal1: any,
    seal2: any,
    invoiceDate: any,
    returnValue: any,
    broken1: any,
    broken2: any,
    specialCharge: any,
    notification:any,
    chargeType:any,
    billTo:any,
    tally: any,
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/ihcrud/update', {
        storer,
        storerRef,
        loadType,
        recptType,
        recvdFrom,
        qty,
        schdldArrival,
        carrier,
        actualArrival,
        building,
        pro,
        wgt,
        user,
        schdldtime,
        actualtime,
        authorization,
        car,
        seal1,
        seal2,
        invoiceDate,
        returnValue,
        broken1,
        broken2,
        specialCharge,
        notification,
        chargeType,
        billTo,
        tally,
        
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  createtallyDetail2(
    tally: any,
    storer: any,
    storerRef: any,
    qty: any,
    loadType: any,
    recptType: any,
    recvdFrom: any,
    schdldArrival: any,
    actualArrival: any,
    schdldtime: any,
    actualtime: any,
    carrier: any,
    pro: any,
    wgt: any,
    building: any,
    user: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/api/ihcrud/update', {
        tally,
        storer,
        storerRef,
        loadType,
        recptType,
        recvdFrom,
        qty,
        schdldArrival,
        carrier,
        actualArrival,
        building,
        pro,
        wgt,
        schdldtime,
        actualtime,
        user,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  createServicePutawayLoc(
    ilACDL: any,
    rateType: any,
    tally: any,
    sequence:any,
    building: any,
    storer: any,
    item:any,
    lotCode1:any,
    lotCode2:any,
    lotCode3:any,
    floor:any,
    aisl : any,
    slot :any,
    quantityAvailable :any,
    damaged :any,
    held :any,
    user1 : any,
    user2 :any,
    user3 : any,
    user4 :any,
    user :any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url+'/tallyDtlsPutawayLoc/create', {
    ilACDL,
    rateType,
    tally,
    sequence,
    building,
    storer,
    item,
    lotCode1,
    lotCode2,
    lotCode3,
    floor,
    aisl,
    slot,
    quantityAvailable,
    damaged,
    held,
    user1,
    user2,
    user3,
    user4,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateServicePutawayLoc(
    ilACDL: any,
    rateType: any,
    tally: any,
    sequence:any,
    building: any,
    storer: any,
    item:any,
    lotCode1:any,
    lotCode2:any,
    lotCode3:any,
    floor:any,
    aisl : any,
    slot :any,
    quantityAvailable :any,
    damaged :any,
    held :any,
    user1 : any,
    user2 :any,
    user3 : any,
    user4 :any,
    user :any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url+'/tallyDtlsPutawayLoc/update', {
    ilACDL,
    rateType,
    tally,
    sequence,
    building,
    storer,
    item,
    lotCode1,
    lotCode2,
    lotCode3,
    floor,
    aisl,
    slot,
    quantityAvailable,
    damaged,
    held,
    user1,
    user2,
    user3,
    user4,
    user
      })
      .pipe(retry(1), catchError(this.httpError));
  }



  



  deleteTally(tally: any, user: any): Observable<any> {
    return this.httpClient
      .delete<any>(this.url+`/ihcrud/delete?user=${user}&tally=${tally}`)
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteTallyDetail(tally: any, user: any, sequence :any): Observable<any> {
    return this.httpClient
      .delete<any>(this.url+`/ihcrud/tallyDetail/delete?user=${user}&tally=${tally}&sequence=${sequence}`)
      .pipe(retry(1), catchError(this.httpError));
  }

  getTallyHeaders(): Observable<any> {
    return this.httpClient
      .get<any>('api/ihcrud/read')
      .pipe(retry(1), catchError(this.httpError));
  }

  // createTally().Observable<any>{
  //   return this.httpClient.post<any>('api/ihcrud/create')
  // }

  deleteServicePutawayLoc( 
      user:any,
      ilACDL:any,
      rateType:any, 
      tally: any, 
      sequence:any,
      item:any,
      lotCode1:any,
      lotCode2:any,
      lotCode3:any,
      floor:any,
      aisl:any,
      slot:any
      ): Observable<any> {
    return this.httpClient
      .delete<any>(
        this.url+`/tallyDtlsPutawayLoc/delete?user=${user}&ilACDL=${ilACDL}&rateType=${rateType}&tally=${tally}&sequence=${sequence}&item=${item}&lotCode1=${lotCode1}&lotCode2=${lotCode2}&lotCode3=${lotCode3}&floor=${floor}&aisl=${aisl}&slot=${slot}`)
      .pipe(retry(1), catchError(this.httpError));
  }

//--------------  TALLY COMMENTS  --------------------
createTallyCommentService(
  user: any,
  status: any,
  tally:any,
  sequence:any,
  sub:any,
  commData: any,
  sId:any

): Observable<any> {
  return this.httpClient
    .post<any>(this.url+'/ihcrud/createTallyComments', {
      user,
      status,
      tally,
      sequence,
      sub,
      commData,
      sId

    })
    .pipe(retry(1), catchError(this.httpError));
}

updateTallyCommentService(
  user: any,
  status: any,
  tally:any,
  sequence:any,
  sub:any,
  commData: any
): Observable<any> {
  return this.httpClient
    .put<any>(this.url+'/ihcrud/updateTallyComments', {
      user,
      status,
      tally,
      sequence,
      sub,
      commData
     
    })
    .pipe(retry(1), catchError(this.httpError));
}

deleteTallyCommentService( user: any, status: any, tally: any, sequence :any , sub:any): Observable<any> {
  return this.httpClient
    .delete<any>(this.url+`/ihcrud/deleteTallyComments?user=${user}&status=${status}&tally=${tally}&sequence=${sequence}&sub=${sub}`)
    .pipe(retry(1), catchError(this.httpError));
}


deReceiveTallyService(
  user: any,
  tally:any,
  sequence:any,
  
): Observable<any> {
  return this.httpClient
    .put<any>(this.url+'/ihcrud/deReceiveTallys', {
      user,
      tally,
      sequence
     
    })
    .pipe(retry(1), catchError(this.httpError));
}


deReceiveTallyDetailsService(
  user: any,
  tally:any,
  sequence:any,
  
): Observable<any> {
  return this.httpClient
    .put<any>(this.url+'/ihcrud/deReceiveTallyDetails', {
      user,
      tally,
      sequence
     
    })
    .pipe(retry(1), catchError(this.httpError));
}




  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
