<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                  <mat-card-title style="color: rgb(203, 207, 219)"> One-Sided Inventory Adjustment</mat-card-title>
                </mat-card-header>
                
                <mat-card-content>  
                  
                  <div class="row">
                    <mat-form-field appearance="outline">  <mat-label>Storer </mat-label> 
                      <input matInput formControlName="STOR" />
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field appearance="outline">  <mat-label> Item Number </mat-label> 
                        <input matInput formControlName="ITEM" />
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field appearance="outline">  <mat-label> Location</mat-label> 
                          <input matInput formControlName="LOCAT" />
                        </mat-form-field>
                      </div>
                      <div class="row">
                      <mat-form-field appearance="outline">  <mat-label>Lots</mat-label> 
                          <input matInput formControlName="LOTS" />
                        </mat-form-field> 
                    </div> 
                    <div class="row">
                      
                      <mat-form-field appearance="outline">  <mat-label>Adjustment Bucket</mat-label> 
                  
                        <mat-select  formControlName="AJITYP">
                   
                          <mat-option value="A">A=Available </mat-option>
                          <mat-option value="D">D = Damaged </mat-option>
                          <mat-option value="H">H = Held  </mat-option>
                          <mat-option value="1">1 = (Determined by the User) </mat-option>
                          <mat-option value="2">2 = (Determined by the User) </mat-option>
                          <mat-option value="3">3 = (Determined by the User) </mat-option>
                        </mat-select>
                      </mat-form-field>
                
                      
                  
                      <mat-form-field appearance="outline">  <mat-label>Quantity</mat-label> 
                        <input matInput formControlName="AJADQY" />
                      </mat-form-field>
                    
                  </div>
                  <div class="row"> 
                      <mat-form-field appearance="outline">  <mat-label> ReasonCode</mat-label> 
                        <input matInput formControlName="AJREAS" />
                      </mat-form-field>
                  </div>
                  <div class="row">
                    
                      <mat-form-field appearance="outline">  <mat-label> Refer to Bol/WR</mat-label> 
                        
                        <mat-select  formControlName="AJWRBL">
                   
                          <mat-option value="I">I=Inbound </mat-option>
                          <mat-option value="O">O =Outbound </mat-option>
                        </mat-select>
                      </mat-form-field>
                
                  
                      <mat-form-field appearance="outline">  <mat-label> Number</mat-label> 
                        <input matInput formControlName="AJBLWR" />
                      </mat-form-field>
                  
                  
                      <mat-form-field appearance="outline">  <mat-label>Line</mat-label> 
                        <input matInput formControlName="AJBWSQ" />
                      </mat-form-field>
                    
                  </div>
                  <div class="row">
          
                      <mat-form-field appearance="outline">  <mat-label> Availaible</mat-label> 
                        <input matInput formControlName="LCQAVL" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label> Commited</mat-label> 
                        <input matInput formControlName="LCQCOM" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label>Held</mat-label> 
                        <input matInput formControlName="LCQHLD" />
                      </mat-form-field>
                  
                
                      <mat-form-field appearance="outline">  <mat-label> Damaged</mat-label> 
                        <input matInput formControlName="LCQDAM" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label>User1</mat-label> 
                        <input matInput formControlName="LCQUS1" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label>User2</mat-label> 
                        <input matInput formControlName="LCQUS2" />
                      </mat-form-field>
                
                
                      <mat-form-field appearance="outline">  <mat-label>User3</mat-label> 
                        <input matInput formControlName="LCQUS3" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label>User4</mat-label> 
                        <input matInput formControlName="LCQUS4" />
                      </mat-form-field>
                      
                    </div>
                  
                  
                        </mat-card-content> 
              </mat-card>
          </form>