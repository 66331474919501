import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-locater-inquiry',
  templateUrl: './ww-locater-inquiry.component.html',
  styleUrls: ['./ww-locater-inquiry.component.css']
})
export class WwLocaterInquiryComponent implements OnInit {

  storer: any;
form!: FormGroup;
result: any;
user = 'NKOHAN';
constructor(public iHeaderSvc: IheaderService, 
  @Inject(MAT_DIALOG_DATA) public anyvariable: any,
  private fb: FormBuilder,
  private dialogRef: MatDialogRef<WwLocaterInquiryComponent>
 ) 
 { }

ngOnInit(): void {
  this.form = this.fb.group({
    LCITEM:'244',
    LOCN:'1 A/1',
    LOTS:'20200209',
    LCLUWT:'',
    LCRCVD:'04/16/18',
    LCSRSQ:'20200209',
    MAHETG:'99/99',
    MASLTH:'0',
    MASLTW:'0',
    MASLTD:'0',
    TCAP:'8910',
    ADDL:'8820',
    ONHND:'90',
    LCQAVL:'90',
    LCQCOM:'0',
    LCQHLD:'0',
    LCQDAM:'0',
    LCQUS1:'0',
    LCQUS2:'0',
    LCQUS3:'0',
    LCQUS4:'0',
     
  });
}

closeDialog(){
  // Write your stuff here
  this.dialogRef.close(); // <- Close the mat dialog
}


backstorer(){
  
}
attributes(){
  
}
}