import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-door',
  templateUrl: './delete-door.component.html',
  styleUrls: ['./delete-door.component.css'],
})
export class DeleteDoorComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteDoorComponent>
  ) {}

  form!: FormGroup;
  ngOnInit(): void {
    this.form = this.fb.group({
      BldgNumb: [null],
      DoorNumb: [null],
      DoorUse: [null],
      Stat: [null],
      HandlingCodes: [null],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  delete() {
    console.log('delete');
  }
}
