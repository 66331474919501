import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
@Component({
  selector: 'app-add-message-class-columns',
  templateUrl: './add-message-class-columns.component.html',
  styleUrls: ['./add-message-class-columns.component.css']
})
export class AddMessageClassColumnsComponent implements OnInit {


  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<AddMessageClassColumnsComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      DOCUMENT:'',
      FILE:'',
      FIELD:'',
      DTYPE:'',
      XPATH1:'',
      ALLOWNULL:'',
      DVALU1:''
    })
  }

  add(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
