<mat-card class="over-Flow">
  <div class="main-header-title"> 
    <mat-card-header>
      <mat-card-title>Map Cross Reference Maintenance</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>  
    
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
      <div class="row">
        <div class="col">

            <mat-form-field appearance="outline">
              <mat-label> Building: </mat-label>
              <input matInput formControlName="building" />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Floor: </mat-label>
              <input matInput formControlName="floor" value={{floor}}/>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Aisl: </mat-label>
              <input matInput formControlName="aisl" value={{aisl}}/>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Slot: </mat-label>
              <input matInput formControlName="slot" value={{slot}}/>
            </mat-form-field>


            <mat-form-field appearance="outline">
              <mat-label>X-Reference</mat-label>
              <input matInput formControlName="xReference" value={{location}} />
            </mat-form-field>

            <div class="row">
            <div class="col">
            
            <button class="buttons removeButton" mat-raised-button (click)="deletePutawayLocation()"            >
              Delete
            </button>
        
            <button
              class="buttons addButton" mat-raised-button (click)="updateMapLocXref()">
              Update
            </button>    
         
            <button class="buttons addButton" mat-raised-button (click)="createMapLocXref()">
              Create
            </button>
            
            </div>
            </div>
          </div>
        </div> 
      </form>
    </mat-card-content>
  </mat-card>