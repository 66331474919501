  <mat-card>

    <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    <mat-card-header>
   <mat-card-title>
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Stock Inquiry</mat-card-title>
    </mat-card-header>

     <form [formGroup]="form">
       <div class="row">
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Storer</mat-label>
                   <input matInput formControlName="Storer"/>
               </mat-form-field>
               <mat-form-field appearance="outline">
                <mat-label>In_Transit</mat-label>
                <input matInput formControlName="In_Transit"/>
            </mat-form-field>
               </div>
               <div class="row">
               <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Item</mat-label>
                  <input matInput formControlName="Item"/>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Future Allocated</mat-label>
                <input matInput formControlName="FutureAllocated"/>
            </mat-form-field>
               </div>
              </div>
              <div class="row">
               <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>UPC</mat-label>
                  <input matInput formControlName="UPC"/>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Location</mat-label>
                <input matInput formControlName="Location"/>
            </mat-form-field>
           </div>
               </div>
               </div> 
   </form>
   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
       <ng-container matColumnDef="Location">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Location
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Location}}</td>
         </ng-container>
         <ng-container matColumnDef="F">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            F
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.F}}</td>
         </ng-container>
         <ng-container matColumnDef="LotCodes">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Lot Codes
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.LotCodes}}</td>
         </ng-container>
         <ng-container matColumnDef="LotReceived">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Lot Received
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.LotReceived}}</td>
         </ng-container>
         <ng-container matColumnDef="Actual">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actual
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Actual}}</td>
         </ng-container>
         <ng-container matColumnDef="Avail">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Avail
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Avail}}</td>
         </ng-container>

         <ng-container matColumnDef="Commit">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Commit
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Commit}}</td>
          </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
