import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IheaderService } from '../../iheader.service';

@Component({
  selector: 'app-update-tally-dtl-comments',
  templateUrl: './update-tally-dtl-comments.component.html',
  styleUrls: ['./update-tally-dtl-comments.component.css']
})
export class UpdateTallyDtlCommentsComponent implements OnInit {

  constructor(
    private activeRouter: ActivatedRoute,
    public iHeaderSvc: IheaderService,
    private fb:FormBuilder,
    private router : Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<UpdateTallyDtlCommentsComponent>
  ) {}

  user=localStorage.getItem('userName');
  id: any;
  result: any;
  dataSource:any;
  duplicateSource:any;

  displayedColumns: string[] = [
    'Comments', 
    'Actions'
  ];

  form = this.fb.group({
    tally:this.anyvariable.CITALY,
    seq:this.anyvariable.CISEQ,
    comments:this.anyvariable.CISMNT,
    status:'',
    sub:"WR"
    
  })
  ngOnInit(): void {
    
  }


  

  updateTallyComment() {
    var res = this.form.value;
    
    this.result = this.iHeaderSvc
      .updateTallyCommentService(
        this.user,
        res.status,
        res.tally,
        res.seq,
        res.sub,
        res.comments,

       )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       if(JSON.parse(this.result[0].data[1].value).Success){

       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg);

        this.closeDialog();
        
        this.router.navigate(['/main-nav/tallys/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }


  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }
  
  closeDialog() {
    this.dialogRef.close();
  }

}
