<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Maintain Item Warehousing Information </mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col"> 

        <mat-form-field appearance="outline">
          <mat-label> Storer  </mat-label>
          <input readonly matInput formControlName="storeNumber" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Suffix </mat-label>
          <input readonly matInput formControlName="suffix" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Item </mat-label>
          <input readonly matInput formControlName="item" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Inventory Unit </mat-label>
          <input readonly matInput formControlName="InventoryUnit"   />
          
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Shipping Unit </mat-label>
          <input readonly matInput formControlName="shippingUnit" />
        </mat-form-field>
     
      
      <div style="margin-left:20px"><b> Freight Classifications</b></div>
      <hr />
      
        <mat-form-field appearance="outline">
          <mat-label> Domestic Surface</mat-label>
          <input matInput formControlName="domesticSurface" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Domestic Air</mat-label>
          <input matInput formControlName="domesticAir" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> International Surface</mat-label>
          <input matInput formControlName="internationalSurface" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> International Air</mat-label>
          <input matInput formControlName="internationalAir" />
        </mat-form-field>
      
      
      <div style="margin-left:20px"><b> Material Handling</b></div>
      <hr />


      
        <mat-form-field appearance="outline">
          <mat-label> Material Handling Codes 1</mat-label>
          <input matInput formControlName="matHandlingCode1" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Material Handling Codes 2</mat-label>
          <input matInput formControlName="matHandlingCode2" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Material Handling Codes 3</mat-label>
          <input matInput formControlName="matHandlingCode3" />
        </mat-form-field>
      
     
      <div style="margin-left:20px"><b> Product Management</b></div>
      <hr />

      
        <mat-form-field appearance="outline">
          <mat-label> Warehouse Zone</mat-label>
          <input matInput formControlName="warehouseZone" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> BMT Code</mat-label>
          <mat-select formControlName="bmtCode">
            <mat-option value="B">B = Bottom</mat-option>
            <mat-option value="M">M = Middle</mat-option>
            <mat-option value="T">T = Top</mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Pallets High </mat-label>
          <input matInput formControlName="palletsHigh" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Deep </mat-label>
          <input matInput formControlName="palletsDeep" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Preferred Location </mat-label>
          <input matInput formControlName="prefferedLocation" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Low Stock Point</mat-label>
          <input matInput formControlName="lowStockPoint" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Pick Line</mat-label>
          <input matInput formControlName="PickLine" />
        </mat-form-field>
      </div>
    </div>
    <div class="btn-text-right">
      <button mat-button class="button addButton"(click)="createItem3()">Next</button>
    </div>
  
  </form>
</mat-card-content>
</mat-card>

        