<mat-card>
    <mat-card-header>  
      <mat-card-title >Work with Labor Processing</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <button mat-button (click)="addLaborProcessing()" class="addButton">
            <mat-icon>add</mat-icon>
            <span>Labor Processing</span>
      </button>
        <div class="formDiv">
          <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                          <mat-form-field appearance="outline">
                            <mat-label>Building Number</mat-label>
                            <input matInput formControlName="BuildingNumber"/>
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>Storer Number</mat-label>
                            <input matInput formControlName="StorerNumber"/>
                          </mat-form-field>
                              
                    </div>
    
                    
                </div>
            </form>
        </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="BuildingNumber">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Building Number
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.BuildingNumber }}</td>
            </ng-container>
            <ng-container matColumnDef="StorerNumber">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Storer Number
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.StorerNumber }}</td>
            </ng-container>
      
            <ng-container matColumnDef="Process">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Process
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.Process }}</td>
            </ng-container>

            <ng-container matColumnDef="Step">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Step
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Step }}</td>
            </ng-container>

            <ng-container matColumnDef="Task">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Task
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Task }}</td>
            </ng-container>

            <ng-container matColumnDef="OperatorGroup">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Operator Group
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.OperatorGroup }}</td>
            </ng-container>

            <ng-container matColumnDef="MoveTp">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Move Tp
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.MoveTp }}</td>
            </ng-container>

            <ng-container matColumnDef="MQ">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    MQ
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.MQ }}</td>
            </ng-container>

            <ng-container matColumnDef="ResponsibleProfile">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Responsible Profile
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.ResponsibleProfile }}</td>
            </ng-container>

            <ng-container matColumnDef="ST">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    ST
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.ST }}</td>
            </ng-container>

            <ng-container matColumnDef="Action">
                <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
                    <mat-option>
                        <button mat-icon-button (click)=" changeLaborProcessing(element)">
                          <mat-icon mat-icon-button color="primary">edit</mat-icon>
                          <mat-label>Change</mat-label>
                        </button>
                    </mat-option>

                    <mat-option>
                        <button mat-icon-button (click)=" deleteLaborProcessing(element)">
                          <mat-icon mat-icon-button color="warn">delete</mat-icon>
                          <mat-label>Labor Processing</mat-label>
                        </button>
                    </mat-option>

                    <mat-option>
                        <button mat-icon-button  (click)="copyLaborProcessing(element)">           
                          <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                          <mat-label>Copy</mat-label>
                        </button>
                    </mat-option>              
                </mat-select>
                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            
        </table>
    </mat-card-content>
</mat-card>
<!-- <p>ww-lot-status-add works!</p> -->
