import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';

import { LaborPlanChangeComponent } from './labor-plan-change/labor-plan-change.component';
import { LaborPlanCreateComponent } from './labor-plan-create/labor-plan-create.component';

@Component({
  selector: 'app-ww-labor-plans',
  templateUrl: './ww-labor-plans.component.html',
  styleUrls: ['./ww-labor-plans.component.css']
})
export class WwLaborPlansComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'LBDOCT',
    'LBDOCN',
    'CHANG',
    'POOL',
    'LOCN',
    'LBSTOR',
    'MDATE',
    'MTIME',
    'CURNT',
    'MOVES',
    'RTIME',
    'PCT',
    'LBSTAT',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      LBDOCT:'',
      LBDOCN:'',
      CHANG:'',
      POOL:'',
      LOCN:'',
      LBSTOR:'',
      MDATE:'11/01/21',
      MTIME:'',
      CURNT:'',
      MOVES:'',
      RTIME:'',
      PCT:'',
      LBSTAT:''

    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      DOCT:'',
      DOCN:'',
      SDATE:'',
      BLDG:'',
      STAT:'',
      STOR:'',
      SSFX:'',
      ADATE:'',
      OPER:'',
      OPTN1:'',
      SCAC:''

    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  add(){
    this.matDialog.open(LaborPlanCreateComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  change(element:any){
    this.matDialog.open(LaborPlanChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }


}
