import { AddBalanceComponent } from './add-balance/add-balance.component';
import { InquiryBalanceComponent } from './inquiry-balance/inquiry-balance.component';
import { StgInquiryBalanceComponent } from './stg-inquiry-balance/stg-inquiry-balance.component';
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { DeleteBalanceComponent } from './delete-balance/delete-balance.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { IItemBalanceService } from './i-item-balance.service';
import { DialogConfig } from '@angular/cdk/dialog';
@Component({
  selector: 'app-item-balances',
  templateUrl: './item-balances.component.html',
  styleUrls: ['./item-balances.component.css'],
})
export class ItemBalancesComponent implements OnInit {
  displayedColumns: string[] = [
    'Lot Code',
    'Date Written',
    'Type(P/W/D)',
    'On Hand',
    'Allocated',
    'Committed',
    'Damaged',
    'Actions',
  ];

  user = localStorage.getItem('userName');
  @ViewChild('paginator') paginator!: MatPaginator;

  constructor(
    private activeRouter: ActivatedRoute,
    public iItemBalnSvc: IItemBalanceService,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private changeDetectorRefs: ChangeDetectorRef,
    private dialogRef: MatDialogRef<ItemBalancesComponent>
  ) {}
  form = this.fb.group({
    storer: this.anyvariable.ITSTOR,
    suffix: this.anyvariable.ITSSFX,

    item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
    date: [null],
    lot1: '',
    lot2: '',
    lot3: '',
  });
  ngOnInit(): void {
    console.log(this.anyvariable.ITLOTT);
    this.iItemBalnSvc
      .getItemBalance(this.user, this.anyvariable.ITSTOR, this.anyvariable.ITEM)
      .subscribe((data) => {
        this.result = data;
        // this.TallyHeaders = data;
        var res = this.result[0].data[1]?.value;
        console.log(res);
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;

        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
        this.paginator._changePageSize(this.paginator.pageSize);
      });
  }
  TallyHeaders: any = [];
  duplicateSource = [];
  id: any;
  postingDate: any;
  result: any;
  dataSource: any;

  workHeaders: any = [];

  getItemBalanceBySearch(item: any) {
    var filterDate;
    if (item.date != null && item.date != '') {
      filterDate = item.date.toLocaleDateString('fr-CA');
    } else {
      filterDate = null;
    }

    this.iItemBalnSvc
      .getItemBalanceBySearch(
        this.user,
        item.storer,
        item.suffix,
        this.anyvariable.ITEM,

        item.lot1 ? item.lot1.toUpperCase() : '',
        item.lot2 != null ? item.lot2 : '',
        item.lot3 != null ? item.lot3 : '',
        filterDate
      )
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        console.log(res);
        var parsedData = JSON.parse(res);
        console.log(parsedData);
        this.dataSource = new MatTableDataSource(parsedData.returnedData);
        console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  itemBalanceDelete(element: any) {
    var res = this.anyvariable;
    res.date = element.BLDATE;
    res.type = element.BLTYPE;
    res.lot1 = element.BLLOTC;
    res.lot2 = element.BLLCL2;
    res.lot3 = element.BLLCL3;
    this.matDialog.open(DeleteBalanceComponent, {
      width: '700px',
      maxHeight: '80vh',
      data: res,
      autoFocus: false,
    });
  }

  itemBalanceCreate() {
    var res = this.anyvariable;

    this.matDialog.open(AddBalanceComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
      data: res,
    });
  }
  itemBalanceInquiry(element: any) {
    this.matDialog.open(InquiryBalanceComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
    });
  }
  itemBalanceStgInquiry(element: any) {
    this.matDialog.open(StgInquiryBalanceComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
