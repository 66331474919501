<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Maintain Item Cycle Count Schedule</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="INSTOR" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Item</mat-label>
                    <input matInput formControlName="INITEM" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Status</mat-label>
                    <input matInput formControlName="INSTAT" />
                </mat-form-field>
            </div> 
          
        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="change()" class="addButton">           
        <mat-icon mat-icon-button color="primary">edit</mat-icon>
        <mat-label>Item Cycle Count Schedule</mat-label>
    </button>
</mat-card-footer>


