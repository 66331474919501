<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">
      <button class="backButton" mat-button (click)="onBack()">
        <mat-icon>navigate_before </mat-icon>
      </button>
      Storer Contacts</mat-card-title>
    <div class="buttons">
      <button mat-button (click)="searchTally()" class="addButton" *ngIf="searchTextDisplay">
        <mat-icon>clear</mat-icon>
      </button>
      <mat-menu #mainMenuButton="matMenu">
        <span (click)="$event.stopPropagation()" *ngFor="let column of columnShowHideList; index as i">
          <mat-slide-toggle style="color: blue" [(ngModel)]="column.isActive" (change)="toggleColumn(column)">
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button mat-button (click)="searchTally()" class="addButton" *ngIf="!searchTextDisplay"
        matTooltip="Search Storer">
        <mat-icon>search</mat-icon>
      </button>

      <button mat-button (click)="storerContactCreate(this.ctcons)" class="addButton buttons"
        matTooltip="Create New Storer">
        <mat-icon>add</mat-icon>
        
      </button>
      <!-- <button mat-button (click)="storerContactCreate(this.ctcons)" class="addButton buttons" matTooltip="Add New Storer">
        <mat-icon>add</mat-icon>
        
      </button> -->
      <button mat-button matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn" #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton">
        <mat-icon>visibility_off</mat-icon>
      </button>
    </div>

  </mat-card-header>

  <mat-card-content class="mt-4">

    <form [formGroup]="updateForm" (ngSubmit)="storerContactBySearch(updateForm.value)" *ngIf="searchTextDisplay"
      class="form over-Flow">

      <div class="col">


        <mat-form-field appearance="outline">
          <mat-label>Contact Type</mat-label>
          <input matInput formControlName="contactType" />
        </mat-form-field>




        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" />
        </mat-form-field>


        <mat-form-field appearance="outline">
          <mat-label> Status: </mat-label>
          <input matInput formControlName="status" />
        </mat-form-field>

        <button class="buttons addButton" mat-raised-button type="submit" placeholder="Search">
          Search
        </button>
      </div>

    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
    <!-- Tally Column -->
    <ng-container matColumnDef="Type">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Type
      </th>
      <td mat-cell *matCellDef="let element">{{ element.CTTYPE }}</td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Contact Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.CTNAME }}</td>
    </ng-container>

    <ng-container matColumnDef="Telephone">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Telephone
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CTTELN }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Status">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CTSTAT }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Cell Phone Number">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Cell Phone Number
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CTCELN }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Fax Number">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Fax Number
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CTFAXN }}
      </td>
    </ng-container>
    <ng-container matColumnDef="EMail Address">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        E-Mail Address
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CTEMAL }}
      </td>
    </ng-container>



    <!-- <ng-container matColumnDef="Telephone">
        <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
          Telephone
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.CTTYPE }}
        </td>
      </ng-container> -->



    <!-- <ng-container matColumnDef="Rep">
        <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
         Rep
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.IHSTOR }}
        </td>
      </ng-container> -->






    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
        <!-- Actions -->
      </th>

      <!--  <mat-option>
              <button mat-icon-button (click)="storerCreate(id)">
                <mat-icon mat-icon-button color="primary">add</mat-icon>
                <mat-label>Add Storer</mat-label>
              </button></mat-option
            > -->
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)=" storerConatactUpdate(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Storer-Contact-Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)=" storerConatactDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Storer-Contact-Delete</mat-label>
              </button>
            </li>

          </ul>
        </div>

    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
        #paginator
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator> -->
  <mat-paginator #paginator [pageSizeOptions]="[3, 6, 12]" showFirstLastButtons
    class="tally-table-pagination"></mat-paginator>
</mat-card>