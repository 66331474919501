import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-ww-entity-attributes',
  templateUrl: './ww-entity-attributes.component.html',
  styleUrls: ['./ww-entity-attributes.component.css']
})
export class WwEntityAttributesComponent implements OnInit {

  form!: FormGroup;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'attribute',
    'value',
    'lastChanged',
    'user',
    'st',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      attribute:'',
      value:'',
      lastChanged:'',
      user:'',
      st:'',
      
      
    }
  ]
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwEntityAttributesComponent>,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      fileId:[null],
      key:[null]
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }


  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
