<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Warehouse Map</mat-card-title>
    <div class="buttons">
      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search Map Location"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>

      <button
        mat-button
        (click)="mapLocationCreate()"
        class="addButton buttons"
        matTooltip="Add Map Location"
      >
        <mat-icon>add</mat-icon>
        <!-- <span>Create Map Location</span> -->
      </button>
    </div>
  </mat-card-header>
  <mat-card-content class="tally-serach-form">
    <form
      [formGroup]="form"
      (ngSubmit)="getMapLocationBySerach(form.value)"
      class="form over-Flow"
      *ngIf="searchTextDisplay"
    >
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Building </mat-label>
            <input matInput formControlName="building" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label> Zone </mat-label>
            <input matInput formControlName="zone" />
          </mat-form-field>
          &nbsp;&nbsp;

          <mat-form-field appearance="outline"
            ><mat-label> Location </mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label> H/W/D </mat-label>
            <input matInput formControlName="hwd" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label> Available Capacity</mat-label>
            
            <input matInput formControlName="availableCapacity"/><span matSuffix>%</span>
            
          </mat-form-field>
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table
    [dataSource]="dataSource"
    mat-table
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="Location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element">
        {{ element.MAWHSE }} {{ element.MAAISL }}/{{ element.MASLOT }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Zone">
      <th mat-header-cell *matHeaderCellDef>Zone</th>
      <td mat-cell *matCellDef="let element">{{ element.MAABC }}</td>
    </ng-container>

    <ng-container matColumnDef="F">
      <th mat-header-cell *matHeaderCellDef width="60px">F</th>
      <td mat-cell *matCellDef="let element">{{ element.MAFROZ }}</td>
    </ng-container>

    <ng-container matColumnDef="Rsrv_Slot">
      <th mat-header-cell *matHeaderCellDef>Rsrv Slot</th>
      <td mat-cell *matCellDef="let element">{{ element.MARESV }}</td>
    </ng-container>

    <ng-container matColumnDef="Storer">
      <th mat-header-cell *matHeaderCellDef>Storer</th>
      <td mat-cell *matCellDef="let element">{{ element.MASTOR }}</td>
    </ng-container>

    <ng-container matColumnDef="Item_Description">
      <th mat-header-cell *matHeaderCellDef>Item/Description</th>
      <td mat-cell *matCellDef="let element">{{ element.MAITDS }}</td>
    </ng-container>

    <ng-container matColumnDef="PctOpen">
      <th mat-header-cell *matHeaderCellDef>Pct Open</th>
      <td mat-cell *matCellDef="let element">{{ element.MAPCTO }}</td>
    </ng-container>

    <ng-container matColumnDef="Pallets">
      <th mat-header-cell *matHeaderCellDef>Pallets H/D</th>
      <td mat-cell *matCellDef="let element">
        {{ element.MAHEIG }} - {{ element.MADEPT }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Dimensions">
      <th mat-header-cell *matHeaderCellDef>Dimensions H/W/D</th>
      <td mat-cell *matCellDef="let element">
        {{ element.MASLTH }} - {{ element.MASLTW }} - {{ element.MASLTD }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="mapLocationUpdate(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="mapLocationDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete </mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="XReference(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>X-Reference</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="locationStatusInquiry()">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Location Status Inquiry</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
