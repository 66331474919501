<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Write a Balance Record</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer Number </mat-label>
            <input readonly="" matInput formControlName="StorerNumber" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Item</mat-label>
            <input readonly matInput formControlName="Item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Balance Date</mat-label>
            <input
              matInput
              formControlName="BalanceDate"
              [matDatepicker]="BalanceDatePicker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="BalanceDatePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #BalanceDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot Code 1</mat-label>
            <input
              matInput
              style="text-transform: uppercase"
              formControlName="LotCode1"
              [required]="lotValue == 'Y'"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot Code 2</mat-label>
            <input
              matInput
              style="text-transform: uppercase"
              formControlName="LotCode2"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot Code 3</mat-label>
            <input
              matInput
              style="text-transform: uppercase"
              formControlName="LotCode3"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Building </mat-label>
            <input readonly matInput formControlName="Building" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Record Type </mat-label>
            <mat-select required formControlName="RecordType">
              <mat-option value="P">P = Period</mat-option>
              <mat-option value="D">D = Daily</mat-option>
              <mat-option value="W">W = Weekly</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <button mat-button class="buttons addButton" (click)="addItemBalance()">
        <span>Add</span>
      </button>
    </form>
  </mat-card-content>
</mat-card>
