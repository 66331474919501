<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title> Work With Consignees</mat-card-title>
    <div class="buttons">
      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search Consignee"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>

      <button
        mat-button
        (click)="addConsignee()"
        class="addButton"
        matTooltip="Add Consignee"
      >
        <mat-icon>add</mat-icon>
        <!-- <span>New Consignee</span> -->
      </button>
      <mat-menu #mainMenuButton="matMenu">
        <span
          (click)="$event.stopPropagation()"
          *ngFor="let column of columnShowHideList; index as i"
        >
          <mat-slide-toggle
            style="color: blue"
            [(ngModel)]="column.isActive"
            (change)="toggleColumn(column)"
          >
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button
        mat-button
        matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>

    </div>
  </mat-card-header>

  <mat-card-content class="tally-serach-form">
    <form
      [formGroup]="form"
      (ngSubmit)="getConsineeBySearch(form.value)"
      *ngIf="searchTextDisplay"
    >
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Code</mat-label>
            <input matInput formControlName="Code" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="Name" />
          </mat-form-field>
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="Code">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Code</th>
      <td style="text-transform: uppercase" mat-cell *matCellDef="let element">
        {{ element.CONSCD }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.CONAME }}</td>
    </ng-container>

    <ng-container matColumnDef="Zip Code">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Zip Code</th>
      <td mat-cell *matCellDef="let element">{{ element.COZIP }}</td>
    </ng-container>

    <ng-container matColumnDef="City">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>City</th>
      <td mat-cell *matCellDef="let element">{{ element.ZICITY }}</td>
    </ng-container>

    <ng-container matColumnDef="State">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>State</th>
      <td mat-cell *matCellDef="let element">{{ element.ZISTAT }}</td>
    </ng-container>
    <ng-container matColumnDef="Phone">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Phone</th>
      <td mat-cell *matCellDef="let element">
        {{ element.COPHAR }}&nbsp;{{ element.COPHEX }}&nbsp;{{ element.COPHNU }}
      </td>
    </ng-container>

    <ng-container matColumnDef="PreferredCarrier">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Preferred Carrier
      </th>
      <td mat-cell *matCellDef="let element">{{ element.COCARR }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef>
        <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="consigneeUpdateForm(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="consigneeDeleteForm(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="consigneeComments(element)">
                <mat-icon mat-icon-button color="primary">comment</mat-icon>
                <mat-label>Consignee Comments</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="consigneeXRefForm(element)">
                <mat-icon mat-icon-button color="primary">comment</mat-icon>
                <mat-label>X-Reference</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="consigneeItems()">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Consignee Items</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="consigneeChildItems()">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Consignee child Items</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="uccLabelFile()">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>UCC Label File</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="attributeConsignee()">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Attribute</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="ContactConsignee()">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Contacts</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="DocumentConsignee()">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Documents</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="SrchAttribute()">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Srch Attribute Inquiry</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator
    #paginator
    [pageSizeOptions]="[3, 5, 10]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
