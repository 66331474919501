import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { ItemCreate4Component } from '../item-create4/item-create4.component';
import Swal from 'sweetalert2';
import { IitemService } from '../iitem.service';

@Component({
  selector: 'app-item-create3',
  templateUrl: './item-create3.component.html',
  styleUrls: ['./item-create3.component.css'],
})
export class ItemCreate3Component implements OnInit {
  form!: FormGroup;
  result: any;

  constructor(
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    public itemSvc: IitemService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemCreate3Component>,
    private router:Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({

      storeNumber: this.anyvariable.storeNumber,
      suffix: this.anyvariable.suffix,
      item: this.anyvariable.item + '\xa0' + this.anyvariable.itemDescription1,

      InventoryUnit: 'EACHES', // static data in RPG
      ShippingUnit: 'EACHES', // static data in RPG

      domesticSurface: '', //domestic surface
      domesticAir: '', //domestic air
      internationalSurface: '', //international surface
      internationalAir: '', //international air
      matHandlingCode1: '', //Materail handling code1
      matHandlingCode2: '', //Materail handling code2
      matHandlingCode3: '', //Materail handling code3
      warehouseZone: '', //warehouse zone
      bmtCode: '', //Bmt code
      palletsHigh: '', //pallet high
      palletsDeep: '', //pallet deeep
      lowStockPoint:'',
      prefferedLocation: '', //preferred location
      PickLine: '', //pickLine
    });
  }

  createItem3(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemCreate3( 

        this.anyvariable.storeNumber,
        this.anyvariable.suffix,
        this.anyvariable.item,
        
       res.domesticSurface,
       res.domesticAir, //domestic air
       res.internationalSurface, //international surface
        res.internationalAir, //international air
        res.matHandlingCode1, //Materail handling code1
        res.matHandlingCode2, //Materail handling code2
        res.matHandlingCode3, //Materail handling code3
        res.warehouseZone, //warehouse zone
        res.bmtCode, //Bmt code
        res.palletsHigh, //pallet high
        res. palletsDeep, //pallet deeep
        res.lowStockPoint, //low stack point
        res. prefferedLocation, //preferred location
        res.PickLine, //pickLine
       

        

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       console.log(this.result[0].data.length);
          console.log("*********************");

       //console.log(this.result[0].data[23].value);
       console.log("*********************");

       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Created/Updated'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this. create4();
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg= this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
    this.router.navigate(['/main-nav/item/read']);
  }


  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }





  create4() {
    var res = this.anyvariable;
    this.matDialog.open(ItemCreate4Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

  
}
