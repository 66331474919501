<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>Shipment Confirmation</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Bill Number  : </mat-label> 
                    <input matInput formControlName="BillNo"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Storer : </mat-label> 
                    <input matInput formControlName="Storer"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Consignee : </mat-label> 
                    <input matInput formControlName="Consignee"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Pro Number : </mat-label> 
                    <input matInput formControlName="ProNumber"/>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Carrier : </mat-label> 
                    <input matInput formControlName="Carrier"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Ship Type: </mat-label> 
                    <input matInput formControlName="ShipType" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Ship Date/Time: </mat-label> 
                    <input matInput formControlName="ShipDateTime"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Ship Date Chg Code: </mat-label> 
                    <input matInput formControlName="ShipDateChgCode"/>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>Lode Code: </mat-label> 
                    <input matInput formControlName="LodeCode"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Load Quantity : </mat-label> 
                    <input matInput formControlName="LoadQuantity" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>CHEP Pallet Quantity : </mat-label> 
                    <input matInput formControlName="CHEpPalletQuantity"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Manifest No </mat-label> 
                    <input matInput formControlName="ManifestNo"/>
                </mat-form-field>

                
            </div>

            <div class="col">

                <mat-form-field  appearance="outline">
                    <mat-label>Master Ref No: </mat-label> 
                    <input matInput formControlName="MasterRefNo"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Equipment No : </mat-label> 
                    <input matInput formControlName="EquipmentNo" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Link Sequence  : </mat-label> 
                    <input matInput formControlName="LinkSequence"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Seals  : </mat-label> 
                    <input matInput formControlName="Seals"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Freight Chg  : </mat-label> 
                    <input matInput formControlName="FreightChg"/>
                </mat-form-field>
            </div>

            <div class="col">
                <button mat-button (click)="confirm()" class="addButton">
                    
                    <span>Confirm</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>

