<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title fxLayoutAlign="center"
        >Cut an Item From the Bill</mat-card-title
      >
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()" class="closeIcon">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Quantity</mat-label>
            <input matInput value="{{ this.qty }}" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Ship From</mat-label>
            <input matInput value="{{ this.shipFrom }}" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Item</mat-label>
            <input matInput value="{{ this.item }}" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Item Desc</mat-label>
            <input matInput value="{{ this.itemDsc }}" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot Code</mat-label>
            <input matInput value="{{ this.lot1 }}" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Reason: </mat-label>
            <mat-select formControlName="reason">
              <mat-option value="ZZ"> Mutually Defined </mat-option>
              <mat-option value="01"> Out of Stock </mat-option>
              <mat-option value="02"> Equipment Cut </mat-option>
              <mat-option value="03">Booking Location Request </mat-option>
              <mat-option value="04">On Hold</mat-option>
              <mat-option value="05">Order Discrepency </mat-option>
              <mat-option value="06">Receiving Location Request </mat-option>
              <mat-option value="07">Inventory Discrepancy </mat-option>
              <mat-option value="08"> Material Shortage </mat-option>
              <mat-option value="09"> Substitution </mat-option>
              <mat-option value="10"> Diverted Item </mat-option>
              <mat-option value="11"> Loading Error </mat-option>
              <mat-option value="12"> Inbound Carrier Failure </mat-option>
              <mat-option value="13"> Product Allocation Exceeded </mat-option>
              <mat-option value="14"> Improperly Sized Order </mat-option>
              <mat-option value="15"> Wrong Equipment </mat-option>
              <mat-option value="16"> Insufficient Equipment Space </mat-option>
              <mat-option value="17"> Discontinued Item </mat-option>
              <mat-option value="20"> Late Stock Transfer </mat-option>
              <mat-option value="21"> Inadequate Lead Time </mat-option>
              <mat-option value="22"> Stock Transfer Not Set Up </mat-option>
              <mat-option value="23">
                Item Not Stocked At Shipping Location
              </mat-option>
              <mat-option value="24"> Product Withdrawn </mat-option>
              <mat-option value="25">
                Addition Requested by Customer
              </mat-option>
              <mat-option value="26"> Bacteria Clearance </mat-option>
              <mat-option value="50"> QA HOLD </mat-option>
              <mat-option value="51"> Process Related </mat-option>
              <mat-option value="52"> Stock Out Item </mat-option>
              <mat-option value="53"> Specific Batch Out </mat-option>
              <mat-option value="54"> Dating </mat-option>
              <mat-option value="56"> Over Cube/Over Gross Weight </mat-option>
              <mat-option value="57"> In Transit </mat-option>
              <mat-option value="58"> In Yard-Not Unloaded </mat-option>
              <mat-option value="60"> As Directed </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div>
        <button mat-button (click)="orderDetailCut()" class="buttons addButton">
          Cut
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
