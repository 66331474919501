<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Load Out History</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Bill</mat-label>
            <input matInput formControlName="Bill" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Load Type</mat-label>
            <input matInput formControlName="LoadType" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Load Nmbr</mat-label>
            <input matInput formControlName="LoadNmbr" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>SRL</mat-label>
            <input matInput formControlName="SRL" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Status</mat-label>
            <input matInput formControlName="Status" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="PalletNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Pallet Number
      </th>
      <td mat-cell *matCellDef="let element">{{ element.PalletNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="T">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>T</th>
      <td mat-cell *matCellDef="let element">{{ element.T }}</td>
    </ng-container>

    <ng-container matColumnDef="LoadTrk">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Loadtrk#
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.LoadTrk }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Order">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Order#
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Order }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Pos">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Pos
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Pos }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Lvl">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Lvl
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Lvl }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ST">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Status
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.ST }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="unloadPallet()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Unload</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="deleteLoadOut()">
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
                        #paginator
                        [pageIndex]="0"
                        [pageSize]="10"
                        [pageSizeOptions]="[5, 10, 25, 100]"
                      >
                      </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
