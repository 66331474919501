import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
import { Router } from '@angular/router';
import { StorerCommentDeleteComponent } from './storer-comment-delete/storer-comment-delete.component';
import { StorerCommentUpdateComponent } from './storer-comment-update/storer-comment-update.component';
import { StorerCommentCreateComponent } from './storer-comment-create/storer-comment-create.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { StorerServiceService } from '../storer-service.service';
import { GlobalComponent } from 'src/app/Global-Component';

interface CustomColumn {
  possition: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-storer-comments',
  templateUrl: './storer-comments.component.html',
  styleUrls: ['./storer-comments.component.css']
})
export class StorerCommentsComponent implements OnInit {

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    public storerService: StorerServiceService,
    private fb: FormBuilder,
    private matDialog: MatDialog
  ) {}

  displayedColumns: string[] = [
    'ID',
    'Key',
    'Tp',
    'Seq',
    'Comment',
    'Actions'
  ];


  @ViewChild('paginator') paginator!: MatPaginator;
  selectedRow: any;
  editedRows: any;
  GlobalComponent:any=GlobalComponent;
   searchText: any;
  fullStorer:any;
  result: any = [];
  dataSource :any;
  duplicateSource = [];
  singleTallyData = [];
  documents = [];
  user = localStorage.getItem('userName');
  building = GlobalComponent.building;
  tally: any;
  searchValue: string = '';
  condition: boolean = true;
  prevText: string = '';
  element:any;  
  TallyHeaders: any = [];
  id: number = 0;
  public columnShowHideList: CustomColumn[] = [];
  position: any;
  name: any;
  isActive: any;
  searchTextDisplay: any = false;
  form!: FormGroup;


  
  ngOnInit(): void {

    this.element=history.state;

    const prefixWithZeros = (number: any, length: number) => String(number).padStart(length, '0');
     
    console.log('FULL STORER:')
    console.log(prefixWithZeros(this.element.STORE,4));

    this.fullStorer=prefixWithZeros(this.element.STORE,4);

    this.form = this.fb.group({ 
      storer:this.element.STORE,
      fileId: 'ST',
      key:this.fullStorer,
      subType:'',
      sequence:''
     
    });


    this.initializeColumnProperties();
    this.editedRows = [];

    
      var res = this.form.value;
    this.storerService.readStorerCommentService(this.user,this.fullStorer , res.subType, res.sequence)
      .subscribe((data:any) => {
        this.result = data; 
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('ALL STORER COMMENTS DATA');
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }
 

  toggleColumn(column: any) {
    if (column.isActive) {
      if (column.possition > this.displayedColumns.length - 1) {
        this.displayedColumns.push(column.name);
      } else {
        this.displayedColumns.splice(column.possition, 0, column.name);
      }
    } else {
      let i = this.displayedColumns.indexOf(column.name);
      let opr = i > -1 ? this.displayedColumns.splice(i, 1) : undefined;
    }
  }


  initializeColumnProperties() {
    this.displayedColumns.forEach((element, index) => {
      if (element != 'Actions' && element != 'Carrier') {
        this.columnShowHideList.push({
          possition: index,
          name: element,
          isActive: true,
        });
      }
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length,
      name: 'LastMaintained',
      isActive: false,
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length - 1,
      name: 'By',
      isActive: false,
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length - 1,
      name: 'Job',
      isActive: false,
    });

  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }

  
  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }
  
  searchStorerComment(storeContact:any){

    const prefixWithZeros = (number: any, length: number) => String(number).padStart(length, '0');
      console.log(prefixWithZeros(this.element.STORE,4));

      this.fullStorer=prefixWithZeros(this.element.STORE,4);
    var res = this.form.value;
    this.TallyHeaders = this.storerService.readStorerCommentService(this.user, this.fullStorer, res.subType, '')
      .subscribe((data:any) => {
        this.result = data; 
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('STORER COMMENTS BY SEARCH');
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });

  }

  storerCommentCreate(){
    var res = this.form.value;
    this.matDialog.open(StorerCommentCreateComponent, {
      "width": '1000px',
     "maxHeight": '80vh',  
     "data": res,
     "autoFocus": false
   });
   
 }

 storerCommentUpdate(element: any){

  this.matDialog.open(StorerCommentUpdateComponent, {
    "width": '1000px',
   "maxHeight": '80vh',
   "data": element,
   "autoFocus": false
 });
}

storerCommentDelete(element: any){

  this.matDialog.open(StorerCommentDeleteComponent, {
    "width": '1000px',
   "maxHeight": '80vh',
   "data": element,
   "autoFocus": false
 });
}
onBack() {
  this.router.navigate(['/main-nav/storer']);
}


}
