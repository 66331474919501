<mat-card>

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title fxLayoutAlign="center">Copy a Storer to a New Library</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form" >
            <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="Storer" />
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>From Library</mat-label>
                    <input matInput formControlName="FromLibrary" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>To Library</mat-label>
                    <input matInput formControlName="ToLibrary" />
                </mat-form-field>
            
                <mat-form-field appearance="outline">  
                    <mat-label> Submit To Batch  </mat-label> 
                
                    <mat-select matNativeControl required>
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                    <input matInput formControlName="submitToBatch" />
                </mat-form-field>
            </div>
            </div>
        </form>
    </mat-card-content>

</mat-card>