<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"
      >Work With Identification Numbers
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="addSerialNumber()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>Create Serial Number</span>
      </button>
    </div>

    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Bill</mat-label>
            <input matInput formControlName="Bill" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Original Line No</mat-label>
            <input matInput formControlName="OriginalLineNo" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Item</mat-label>
            <input matInput formControlName="Item" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="Type">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Type
      </th>
      <td mat-cell *matCellDef="let element">{{ element.Type }}</td>
    </ng-container>

    <ng-container matColumnDef="SerialNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Serial Number
      </th>
      <td mat-cell *matCellDef="let element">{{ element.SerialNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="editSerialNumber()">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="deleteSerialNumber()">
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
                    #paginator
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                  >
                  </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
