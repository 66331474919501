import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-data-element',
  templateUrl: './add-data-element.component.html',
  styleUrls: ['./add-data-element.component.css'],
})
export class AddDataElementComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<AddDataElementComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      dataElementCode: [null],
      dataElementDefinition: [null],
      InternalExternal: [null],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addDataElement() {
    console.log('added');
  }
}
