<div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Load An Order Onto Movement Queue</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input readonly matInput formControlName="storer" />
                </mat-form-field>
            
                <mat-form-field appearance="outline">
                    <mat-label>Bill Number</mat-label>
                    <input readonly matInput formControlName="billOfLading" />
                </mat-form-field>
                 
                <mat-form-field appearance="outline">
                    <mat-label>Priority</mat-label>
                    <input matInput formControlName="priority" />
                </mat-form-field>
           
                <mat-form-field appearance="outline">
                    <mat-label>Staging/Door Location</mat-label>
                    <input matInput formControlName="location" />
                </mat-form-field>
            </div>
        </div>

        <div class="btn-text-right">
            <button mat-button class="button addButton"(click)="orderToMoveQueue()">Next</button>
        </div>

    </form>
</mat-card-content>

