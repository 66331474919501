<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Buildings</mat-card-title>
    <div class="buttons">
      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
      </button>

      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search Building"
      >
        <mat-icon>search</mat-icon>
      </button>

      <button
        mat-button
        (click)="addBuilding()"
        class="addButton buttons"
        matTooltip="Add Building"
      >
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content class="tally-serach-form">
    <form
      [formGroup]="form"
      (ngSubmit)="getBuildingBySearch(form.value)"
      *ngIf="searchTextDisplay"
      class="form over-Flow"
    >
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Building Number</mat-label>
            <input matInput formControlName="building" />
          </mat-form-field>
        </div>
      </div>
      <button
        class="buttons addButton"
        mat-raised-button
        type="submit"
        placeholder="Search"
      >
        Search
      </button>
    </form>
  </mat-card-content>

  <table
    [dataSource]="dataSource"
    mat-table
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="No">
      <th mat-header-cell *matHeaderCellDef>No.</th>
      <td mat-cell *matCellDef="let element">{{ element.BUILD }}</td>
    </ng-container>
    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef>Name</th>
      <td mat-cell *matCellDef="let element">{{ element.BUNAME }}</td>
    </ng-container>

    <ng-container matColumnDef="Address">
      <th mat-header-cell *matHeaderCellDef>Address</th>
      <td mat-cell *matCellDef="let element">{{ element.BUADD1 }}</td>
    </ng-container>

    <ng-container matColumnDef="Zip">
      <th mat-header-cell *matHeaderCellDef>Zip</th>
      <td mat-cell *matCellDef="let element">{{ element.BUZIP }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef>
        <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="updateForm(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="deleteForm(element)">
                <mat-icon mat-icon-button color="warn"
                  >delete{{ element.BUILD }}</mat-icon
                >
                <mat-label>Delete {{ element.no }}</mat-label>
              </button>
            </li>
            <!-- <li>
              <button mat-icon-button (click)="doorForm()">
                <mat-icon mat-icon-button color="primary">window</mat-icon>
                <mat-label>Doors</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="buildingContact()">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>Buillding Contact </mat-label>
              </button>
            </li> -->
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
