import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CarriersService {

  url = environment.API_BASE_URL;

  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getCarrier(user: any, carrierCode:any, carrierName:any): Observable<any> {
    return this.httpClient.get(
      this.url + `/carriers/read?user=${user}&carrierCode=${carrierCode}&carrierName=${carrierName}`
    );
  }

  
  getAllCarriers(user: any): Observable<any> {
    return this.httpClient.get(
      this.url + `/carriers/readAll?user=${user}`
    );
  }

  createCarrier(
    carrierCode:any, //CRNUMB
    carrierName:any, //CRNAME
    carrierAdd1:any, //CRADD1
    carrierAdd2:any, //CRADD2
    zipcode:any,  //CRZIP
    suffix:any, //CRZSFX
    carrierScacCode :any,//CRSCAC
    hazardousMaterials:any,//CRHAZM
    palletExchange:any,//CRPALE
    billFrequency:any,//CRBILL
    loadingAllowance:any,//CRLAPR
    hundredWeight:any,//CRLAWG
    minimumAmount:any,//CRLAMI
    maximumAmount:any,//CRLAMA
    user: any
    ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/carriers/create', { 

      carrierCode, 
      carrierName, 
      carrierAdd1,
      carrierAdd2, 
      zipcode, 
      suffix,
      carrierScacCode,
      hazardousMaterials,
      palletExchange,
      billFrequency,
      loadingAllowance,
      hundredWeight,
      minimumAmount,
      maximumAmount,
      user
       })
      .pipe(retry(1), catchError(this.httpError));
  }


  updateCarrier(
    carrierCode:any, //CRNUMB
    carrierName:any, //CRNAME
    carrierAdd1:any, //CRADD1
    carrierAdd2:any, //CRADD2
    zipcode:any,  //CRZIP
    suffix:any, //CRZSFX
    carrierScacCode :any,//CRSCAC
    hazardousMaterials:any,//CRHAZM
    palletExchange:any,//CRPALE
    billFrequency:any,//CRBILL
    loadingAllowance:any,//CRLAPR
    hundredWeight:any,//CRLAWG
    minimumAmount:any,//CRLAMI
    maximumAmount:any,//CRLAMA
    user: any
    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/carriers/update', { 

      carrierCode, 
      carrierName, 
      carrierAdd1,
      carrierAdd2, 
      zipcode, 
      suffix,
      carrierScacCode,
      hazardousMaterials,
      palletExchange,
      billFrequency,
      loadingAllowance,
      hundredWeight,
      minimumAmount,
      maximumAmount,
      user
       })
      .pipe(retry(1), catchError(this.httpError));
  }
  



  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
