<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>Add Labor Plan</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>T</mat-label> 
                    <input matInput formControlName="T"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> DocumentNumber </mat-label> 
                    <input matInput formControlName="DocumentNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>CR </mat-label> 
                    <input matInput formControlName="CR"/>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>RP</mat-label> 
                    <input matInput formControlName="RP"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> BuildingLocation </mat-label> 
                    <input matInput formControlName="BuildingLocation"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Storer </mat-label> 
                    <input matInput formControlName="Storer"/>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>Date</mat-label> 
                    <input matInput formControlName="Date"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Time </mat-label> 
                    <input matInput formControlName="Time"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Actv </mat-label> 
                    <input matInput formControlName="Actv"/>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>SCAC</mat-label> 
                    <input matInput formControlName="SCAC"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Operator </mat-label> 
                    <input matInput formControlName="Operator"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Location </mat-label> 
                    <input matInput formControlName="Location"/>
                </mat-form-field>


            </div> 
            <div class="col">
                <button mat-button (click)="addLaborPlan()" class="addButton">  
                    <span>Create</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>
