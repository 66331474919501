<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">
      <button class="backButton go-back-btn" mat-button (click)="onBack()">
        <mat-icon>navigate_before </mat-icon>
      </button>
      Work With Inventory Adjustments
    </mat-card-title>
  </mat-card-header>

  <mat-card-content class="tally-serach-form">
    <!-- <div class="buttons">
                 <button mat-button  (click)="BatchFileAddForm()" class="addButton">
                  <mat-icon>add</mat-icon>
                   <span>File</span>
                     </button>
                    </div> -->
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Trans</mat-label>
            <input matInput formControlName="trans" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Reason</mat-label>
            <input matInput formControlName="reason" />
          </mat-form-field>
        </div>
      </div>
      <div style="margin-left:20px">
        <h3><b>Dates</b></h3>
      </div>
        <div class="row">
          <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>FromDate</mat-label>
            <input matInput formControlName="fromDate" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>ToDate</mat-label>
            <input matInput formControlName="toDate" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="storer" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <input matInput formControlName="type" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Location</mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>
        </div>
      </div>
    </form>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="trans">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Trans</th>
        <td mat-cell *matCellDef="let element">{{ element.trans }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">{{ element.date }}</td>
      </ng-container>

      <ng-container matColumnDef="storer">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Storer</th>
        <td mat-cell *matCellDef="let element">{{ element.storer }}</td>
      </ng-container>

      <ng-container matColumnDef="fT">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>FT</th>
        <td mat-cell *matCellDef="let element">{{ element.fT }}</td>
      </ng-container>

      <ng-container matColumnDef="reason">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Reason</th>
        <td mat-cell *matCellDef="let element">{{ element.reason }}</td>
      </ng-container>

      <ng-container matColumnDef="location">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">{{ element.location }}</td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Quantity</th>
        <td mat-cell *matCellDef="let element">{{ element.quantity }}</td>
      </ng-container>

      <ng-container matColumnDef="iT">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>IT</th>
        <td mat-cell *matCellDef="let element">{{ element.iT }}</td>
      </ng-container>

      <ng-container matColumnDef="opt">
        <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <div class="dropdown more_options_btn">
            <button
              class="btn"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li>
                <button
                  mat-icon-button
                  (click)="itemAdjustmentChangeDate(element)"
                >
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label>Item Adjustment Change Date</mat-label>
                </button>
              </li>
              <li>
                <button
                  mat-icon-button
                  (click)="itemAdjustmentDisplay(element)"
                >
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label>Adjustment Inquiry (Display)</mat-label>
                </button>
              </li>
            </ul>
          </div>
        </td>
      </ng-container>

      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      #paginator
      [pageSizeOptions]="[1, 2]"
      showFirstLastButtons
    ></mat-paginator> </mat-card-content
></mat-card>
