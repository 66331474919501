<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Storer Invoice Inquiry</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row" style="float:left;" >
       <div class="col">
           <mat-form-field appearance="outline">
               <mat-label>Position_to_Item</mat-label>
               <input matInput formControlName="Position_to_Item"/>
           </mat-form-field>
           <mat-form-field appearance="outline">
            <mat-label>Generated</mat-label>
            <input matInput formControlName="Generated"/>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="Storer"/>
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Date</mat-label>
            <input matInput formControlName="Date"/>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Time</mat-label>
            <input matInput formControlName="Time"/>
          </mat-form-field>
        </div>
      </div>  
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
   <ng-container matColumnDef="Item">
       <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Item
       </th> 
       <td mat-cell *matCellDef="let element">{{ element.Item}}</td>
     </ng-container>
     <ng-container matColumnDef="OnHandBalance">
       <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        OnHandBalance
       </th> 
       <td mat-cell *matCellDef="let element">{{ element.OnHandBalance}}</td>
     </ng-container>
     <ng-container matColumnDef="OnHandWeight">
       <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        OnHandWeight
       </th> 
       <td mat-cell *matCellDef="let element">{{ element.OnHandWeight}}</td>
     </ng-container>
     <ng-container matColumnDef="Rate">
       <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Rate
       </th> 
       <td mat-cell *matCellDef="let element">{{ element.Rate}}</td>
     </ng-container>
     <ng-container matColumnDef="ChargeAmount">
       <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        ChargeAmount
       </th> 
       <td mat-cell *matCellDef="let element">{{ element.ChargeAmount}}</td>
     </ng-container>
     <ng-container matColumnDef="MN">
       <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        MN
       </th> 
       <td mat-cell *matCellDef="let element">{{ element.MN}}</td>
     </ng-container>




     <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
     <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
   </table>
</mat-card>
