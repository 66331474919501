<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Balance Record Inquiry</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer Number </mat-label>
            <input matInput formControlName="StorerNumber" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Date</mat-label>
            <input matInput formControlName="Date" readonly/>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <input matInput formControlName="Type" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Item Code</mat-label>
            <input matInput formControlName="ItemCode" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Lot Codes</mat-label>
            <input matInput formControlName="LotCodes" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Building </mat-label>
            <input matInput formControlName="Building" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Storage Invoice Number </mat-label>
            <input matInput formControlName="StorageInvoiceNumber" readonly/>
          </mat-form-field>
        </div>
        </div>
        <div style="margin-left:20px">
          <b><u>Quantities</u></b>
        </div>
        <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>On Hand </mat-label>
            <input matInput formControlName="QtyOnHand" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Allocated </mat-label>
            <input matInput formControlName="QtyAllocated" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Committed </mat-label>
            <input matInput formControlName="QtyCommitted" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Held </mat-label>
            <input matInput formControlName="QtyHeld" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Damaged </mat-label>
            <input matInput formControlName="QtyDamaged" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Shipped </mat-label>
            <input matInput formControlName="QtyShipped" readonly/>
          </mat-form-field>
        </div>
        </div>
        <div style="margin-left:20px">
          <b><u>Weights</u></b>
        </div>
        <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>On Hand </mat-label>
            <input matInput formControlName="WgtOnHand" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Allocated </mat-label>
            <input matInput formControlName="WgtAllocated" readonly/>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Committed </mat-label>
            <input matInput formControlName="WgtCommitted" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Held </mat-label>
            <input matInput formControlName="WgtHeld" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Damaged </mat-label>
            <input matInput formControlName="WgtDamaged" readonly/>
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Shipped </mat-label>
            <input matInput formControlName="WgtShipped" readonly/>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
</mat-card>
