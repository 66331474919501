<mat-card>
    <mat-card-header>  
      <mat-card-title>
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
        Work With Metrics Definations</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="buttons">
            <button mat-button (click)="MetricsDefinationsAddForm()" class="addButton buttons">
              <mat-icon>add</mat-icon>
              <span>New Metric Defination</span>
            </button>
        </div>
        <form [formGroup]="form" class="form over-Flow">
            <div class="row">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Metric</mat-label>
                    <input matInput formControlName="SRCH"/>
                </mat-form-field>
                   <button
                    class="addButton"
                       mat-raised-button
                          type="submit"
                          placeholder="Search">
                          Search
                    </button>
                  </div>
                </div>
              </form>
                         
          </mat-card-content>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="MetricName">
              <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Metric Name
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.METRIC }}</td>
            </ng-container>
            <ng-container matColumnDef="Description">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                  Description
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.DESCRIPT }}</td>
            </ng-container>
            
            <ng-container matColumnDef="Actions">
                <th  mat-header-cell mat-header-cell *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
                    <mat-option>
                        <button mat-icon-button (click)="MetricsDefinationsAddForm()">
                          <mat-icon mat-icon-button color="primary">add</mat-icon>
                          <mat-label>Add</mat-label>
                        </button>
                    </mat-option>
                    <mat-option>
                        <button mat-icon-button (click)="MetricsDefinationsUpdateForm(element)">
                          <mat-icon mat-icon-button color="primary">edit</mat-icon>
                          <mat-label>Change</mat-label>
                        </button>
                    </mat-option>
                    <mat-option>
                        <button mat-icon-button (click)="MetricsDefinationsDeleteForm(element)">
                          <mat-icon mat-icon-button color="primary">delete</mat-icon>
                          <mat-label>Delete</mat-label>
                        </button>
                    </mat-option>
                  </mat-select>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
        </table>
        <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>




