import { EditHazmatClassComponent } from './edit-hazmat-class/edit-hazmat-class.component';
import { AddHazmatClassComponent } from './add-hazmat-class/add-hazmat-class.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-hazmat-class',
  templateUrl: './hazmat-class.component.html',
  styleUrls: ['./hazmat-class.component.css'],
})
export class HazmatClassComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'HazmatClass',
    'Description',
    'Placard',
    'Actions',
  ];
  dataSource: any = [];

  duplicateSource: any = [
    {
      HazmatClass: 'ORM-D',
      Description: 'Other Regulated ',
      Placard: 11,
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      HazmatClass: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  addHazmatClass() {
    this.matDialog.open(AddHazmatClassComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  editHazmatClass() {
    this.matDialog.open(EditHazmatClassComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  delete() {
    alert('deleted');
  }
}
