<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">
      Work With Inventory Serials/LPs
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Serial/LP</mat-label>
            <input matInput formControlName="SerialLP" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Not Found</mat-label>
            <input matInput formControlName="NotFound" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Condition Change</mat-label>
            <input matInput formControlName="ConditionChange" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Location </mat-label>
            <input matInput formControlName="Location" /> </mat-form-field
          ><mat-form-field appearance="outline">
            <mat-label>Recounts</mat-label>
            <input matInput formControlName="Recounts" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Relocations</mat-label>
            <input matInput formControlName="Relocations" />
          </mat-form-field>
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Tally Column -->
        <ng-container matColumnDef="SerialLPNumber">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Serial/LP Number
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.SerialLPNumber }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Item">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Item
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Item }}</td>
        </ng-container>

        <ng-container matColumnDef="Lots">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Lots
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Lots }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select class="mat-select" placeholder="Select Option">
              <mat-option>
                <button mat-icon-button (click)="changeCount()">
                  <mat-icon mat-icon-button color="primary">edit</mat-icon>
                  <mat-label>Change Count</mat-label>
                </button></mat-option
              >
            </mat-select>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table></form
  ></mat-card-content>
  <!-- <mat-paginator
              #paginator
              [pageIndex]="0"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]"
            >
            </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
