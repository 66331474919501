<mat-card>
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Delete An Item</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer Number </mat-label>
            <input matInput formControlName="storerNumber" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Item Code </mat-label>

            <input matInput formControlName="itemCode" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Description </mat-label>
            <input matInput formControlName="description" />
          </mat-form-field>
        </div>
      </div>
      <div id="custom-alert">
        <div class="custom-alert-content">
          <button mat-button class="buttons removeButton" 
          (click)="deleteItem()">Delete</button>
  
        </div>
      </div>
      <!-- <button mat-button (click)="deleteItem()" class="buttons removeButton">
        Delete
      </button> -->
    </form>
  </mat-card-content>
</mat-card>
