import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { OrderDetailsService } from '../order-details.service';

@Component({
  selector: 'app-order-detail-item-status',
  templateUrl: './order-detail-item-status.component.html',
  styleUrls: ['./order-detail-item-status.component.css']
})

export class OrderDetailItemStatusComponent implements OnInit {

  constructor(
    public iHeaderSvc: IheaderService, 
    public orderDetailSrv:OrderDetailsService,
   @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
   private dialogRef: MatDialogRef<OrderDetailItemStatusComponent>
   ) { }

  @ViewChild(MatPaginator) paginator!:MatPaginator;
  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  duplicateSource:any;
  dataSource:any;

  storerName:any;
  itemDesc:any;

  onHand:any;
  available:any;
  futureAllocation:any;
  committed:any;
  shipped:any;
  workOrder:any;
  ngOnInit(): void {

    this.form = this.fb.group({ 
      itemStorer:this.anyvariable.ODSTOR,
      suffix:this.anyvariable.ODSSFX,
      item:this.anyvariable.ODITEM
              
    });

    var res = this.form.value;
    this.orderDetailSrv.orderDtlItemStatusService(
      this.user,
      res.itemStorer,
      res.suffix,
      res.item
    ).subscribe((data) => {
      this.result = data;    
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('ORDER DETAIL ITEM STATUS DATA');
      console.log(this.duplicateSource);

      this.storerName = this.duplicateSource[0].ITNAME;
      this.itemDesc = this.duplicateSource[0].ITDSC1;

      this.onHand=this.duplicateSource[0].ITOHND;
      this.available=this.duplicateSource[0].ITCAVL;
      this.futureAllocation=this.duplicateSource[0].ITCALL;
      this.committed=this.duplicateSource[0].ITCCOM;
      this.shipped=this.duplicateSource[0].ITCSHP;
      this.workOrder = this.duplicateSource[0].ITCUS4;
    })
  }

  closeDialog(){
    this.dialogRef.close();
  }

  backstorer(){
    
  }
  inquiry(){
    
  }
  
}
