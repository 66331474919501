<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain Zones</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Building </mat-label>
            <input matInput formControlName="buildingNumber" readonly />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Zone</mat-label>
            <input matInput maxlength="1" formControlName="zone" readonly />
          </mat-form-field>       
        
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" />
            <mat-error *ngIf="form.controls['description'].hasError('required')">
              Description is <strong>required</strong>
            </mat-error>
          </mat-form-field>
        </div>
        </div>
     
        <b style="margin-left:20px;">Usages</b>
         
       
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Inbound Staging </mat-label>
            <mat-select formControlName="inboundStaging">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Product Putaway </mat-label>
            <mat-select formControlName="productPutaway">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Outbound Staging </mat-label>

            <mat-select formControlName="outboundStaging">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Product Picking </mat-label>

            <mat-select formControlName="productPicking">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>BackCount </mat-label>
            <mat-select formControlName="backCount">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Custom Usage1 </mat-label>

            <mat-select formControlName="customUsage1">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>
          
          <mat-form-field appearance="outline">
            <mat-label>Custom Usage2 </mat-label>

            <mat-select formControlName="customUsage2">
               <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Custom Usage3 </mat-label>
            <mat-select formControlName="customUsage3">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Custom Usage4 </mat-label>
            <mat-select formControlName="customUsage4">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <button
          class="buttons addButton"
          mat-raised-button
          (click)="updateMapZone()"
        >
          Update
        </button>
        
      </form>
    </mat-card-content>
  </mat-card>

