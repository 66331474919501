<!-- order-detail-ucc-128-maint176 -->
<mat-card>

  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain UCC 128 Serial Numbers</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>

  <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Item</mat-label>
                   <input matInput formControlName="Item"/>
               </mat-form-field>
   
                <mat-form-field appearance="outline">
                    <mat-label>Lot</mat-label>
                    <input matInput formControlName="Lot"/>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Qty</mat-label>
                    <input matInput formControlName="Qty"/>
                </mat-form-field>                   
           </div>
        </div>     
      </form>
  </mat-card-content>

   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
       <ng-container matColumnDef="Serial_Number">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Serial_Number
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Serial_Number}}</td>
         </ng-container>
         <ng-container matColumnDef="Quantity">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Quantity
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Quantity}}</td>
         </ng-container>
         
  
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
</mat-card>

