import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { StorerServiceService } from '../../storer-service.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';

@Component({
  selector: 'app-storer-comment-delete',
  templateUrl: './storer-comment-delete.component.html',
  styleUrls: ['./storer-comment-delete.component.css']
})
export class StorerCommentDeleteComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user =localStorage.getItem('userName');

  constructor(
    private storerContactService : StorerServiceService,
    private CustomAlertService: CustomAlertService,
   // private toast : ToastrService,
   private router : Router,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<StorerCommentDeleteComponent>
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      fileId: this.anyvariable.CMRECD,
      key: this.anyvariable.CMKEY, // Storer
      subCode: this.anyvariable.CMSUBD,
      sequence: this.anyvariable.CMSEQ,
      text: this.anyvariable.CMCOMM,
      lastChangeDate: this.anyvariable.CMDATE,
      lastChangeTime: this.anyvariable.CMTIME,
      changedBy: this.anyvariable.CMUSER
     });
  }

  deleteStorerComment(){

    var res = this.form.value;
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete comment :' );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result){
      this.result = this.storerContactService
        .deleteStorerCommentService(this.user, res.key, res.subCode, res.sequence)
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);

          if (JSON.parse(this.result[0].data[1].value).success) {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
            this.closeDialog();
            this.successMessage(msg);
            this.router.navigate(['/main-nav/storer']);
          } else {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.failureMessage(msg);
            
          }
        });
      }  
    });
  }
    

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/storer']);
    //this.router.navigate(['/main-nav/storer/comments']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
    this.dialogRef.close(); 
  }

}
