<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>
   <mat-card-title>
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Work With Modules</mat-card-title>
    </mat-card-header>

     <form [formGroup]="form">
        <div class="row" style="float:left;" >
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Application</mat-label>
                   <input matInput formControlName="Application"/>
               </mat-form-field>
            
       </div>   
    </div>     
   </form>
   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
       <ng-container matColumnDef="Number">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Number
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Number}}</td>
         </ng-container>
         <ng-container matColumnDef="Sequence">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Sequence
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Sequence}}</td>
         </ng-container>
         <ng-container matColumnDef="Module_Name">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Module_Name
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Module_Name}}</td>
         </ng-container>
         <ng-container matColumnDef="Opt">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Opt
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Opt}}</td>
         </ng-container>
    
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
 
