<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Ledger Codes</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="addledger()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>Create Ledge</span>
      </button>
    </div>

    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Ledger Code</mat-label>
            <input matInput formControlName="LedgerCode" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="LedgerCode">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Ledger Code
      </th>
      <td mat-cell *matCellDef="let element">{{ element.LedgerCode }}</td>
    </ng-container>

    <ng-container matColumnDef="SubCode">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Sub Code
      </th>
      <td mat-cell *matCellDef="let element">{{ element.SubCode }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Description
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Description }}
      </td>
    </ng-container>

    <ng-container matColumnDef="CostCenter">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Cost Center
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CostCenter }}
      </td>
    </ng-container>
    <ng-container matColumnDef="SubCenter">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Sub Center
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.SubCenter }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="updateledger()">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="deleteLedger()">
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="consolidate()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Consolidate</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
                  #paginator
                  [pageIndex]="0"
                  [pageSize]="10"
                  [pageSizeOptions]="[5, 10, 25, 100]"
                >
                </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
