<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>Storer Delete</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form" fxLayoutAlign="center">
            <div class="row">
                <div class="col">
                    <mat-form-field  appearance="outline">
                        <mat-label> Storer  : </mat-label> 
                        <input matInput formControlName="storeNumber"/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Suffix  : </mat-label> 
                        <input matInput formControlName="suffix"/>
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Address : </mat-label> 
                        <input matInput formControlName="address"/>
                    </mat-form-field>
            
                    <mat-form-field  appearance="outline">
                        <mat-label>Phone : </mat-label> 
                        <input matInput formControlName="phone" />
                    </mat-form-field>

                    <!-- <mat-form-field  appearance="outline">
                        <mat-label> Primary Account Rep : </mat-label> 
                        <input matInput formControlName="primaryAccountRep"/>
                    </mat-form-field> -->

                    <p>Press Enter to confirm Deletion of this storer, all inbound and outbound orders, inventory locations and adjustments, all balance records.</p>
                    <div id="custom-alert">
                        <div class="custom-alert-content">
                          <button mat-button class="buttons removeButton" 
                          (click)="storerDelete()">Delete</button>
                  
                        </div>
                      </div>
                    <!-- <button mat-button (click)="storerDelete()" class="buttons removeButton"> 
                        Delete 
                    </button> -->
                </div>
            </div>

        </form>

        

        
    </mat-card-content>
</mat-card>


