<!-- Form No : 253 -->
<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Automated Storer Rate Changes</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
     <form [formGroup]="form">
       <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Storer_Number</mat-label>
              <input matInput formControlName="Storer_Number"/>
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label>Building</mat-label>
              <input matInput formControlName="Building"/>
            </mat-form-field>        
          
            <mat-form-field appearance="outline">
                <mat-label>Only_Active</mat-label>
                <input matInput formControlName="Only_Active"/>
            </mat-form-field>
        
            <mat-form-field appearance="outline">
              <mat-label>Storer_name</mat-label>
              <input matInput formControlName="Storer_name"/>
            </mat-form-field>
           </div>    
        </div>     
      </form>
    </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
       <ng-container matColumnDef="Storer_Number">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Storer Number
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Storer_Number}}</td>
         </ng-container>
         <ng-container matColumnDef="Name">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Name
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Name}}</td>
         </ng-container>
         <ng-container matColumnDef="Days_Between_increase">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Days Between Increase
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Days_Between_increase}}</td>
         </ng-container>
         <ng-container matColumnDef="Last_increase">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Last Increase
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Last_increase}}</td>
         </ng-container>
         <ng-container matColumnDef="Increase_Percentage">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Increase Percentage
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Increase_Percentage}}</td>
         </ng-container>
         <ng-container matColumnDef="Ntfy">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Ntfy
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Ntfy}}</td>
         </ng-container>

         <ng-container matColumnDef="Opt">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Opt
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Opt}}</td>
          </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
