<mat-card class="over-Flow">

  <mat-card-header>
    <mat-card-title class="title">
      <button class="backButton go-back-btn" mat-button (click)="onBack()">
        <mat-icon>navigate_before </mat-icon>
      </button>
      Work With an Items Adjustments </mat-card-title>
    <div class="buttons">

      <button mat-button (click)="searchTally()" class="addButton" *ngIf="searchTextDisplay">
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button mat-button (click)="searchTally()" class="addButton" *ngIf="!searchTextDisplay" matTooltip="Search Tally">
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>
      <mat-menu #mainMenuButton="matMenu">
        <span
          (click)="$event.stopPropagation()"
          *ngFor="let column of columnShowHideList; index as i"
        >
          <mat-slide-toggle
            style="color: blue"
            [(ngModel)]="column.isActive"
            (change)="toggleColumn(column)"
          >
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button
        mat-button
        matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>

    </div>
  </mat-card-header>
  <mat-card-content class="tally-serach-form">
    

    <form [formGroup]="form" class="form over-Flow" *ngIf="searchTextDisplay">
      <div class="row">
        <div class="col">

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="Storer" readonly />
          </mat-form-field>


          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Item</mat-label>
            <input matInput formControlName="Item" readonly />
          </mat-form-field>


          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Type </mat-label>
            <mat-select formControlName="Type">
              <mat-option value="I">I-Inbound</mat-option>
              <mat-option value="O">O-Outbound</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Building</mat-label>
            <input matInput formControlName="Building" />
          </mat-form-field>


          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Floor</mat-label>
            <input matInput formControlName="Floor" />
          </mat-form-field>


          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Aisle</mat-label>
            <input matInput formControlName="Aisle" />
          </mat-form-field>


          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Slot</mat-label>
            <input matInput formControlName="Slot" />
          </mat-form-field>


          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Date</mat-label>
            <input type="date" matInput formControlName="Date" />
          </mat-form-field>


          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Lot1</mat-label>
            <input matInput formControlName="Lot1" />
          </mat-form-field>


          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Lot2</mat-label>
            <input matInput formControlName="Lot2" />
          </mat-form-field>


          <mat-form-field class="full-width" appearance="outline">
            <mat-label>Lot3</mat-label>
            <input matInput formControlName="Lot3" />
          </mat-form-field>

        </div>
      </div>
      
      <button *ngIf="searchTextDisplay" class="buttons addButton tally-search-btn" 
        mat-raised-button type="submit"
        (click)="getItemAdjustment(form.value)"
        placeholder="Search">
        Search
      </button>
    </form>
</mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
      <ng-container matColumnDef="Number">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Number</th>
        <td mat-cell *matCellDef="let element">{{ element.AJTRAN }}</td>
      </ng-container>
      <ng-container matColumnDef="L">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>L</th>
        <td mat-cell *matCellDef="let element">{{ element.AJFTYP }}</td>
      </ng-container>
      <ng-container matColumnDef="Date">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.AJADDD + "/" + element.AJADMM + "/" + element.AJADYY }}
        </td>
      </ng-container>

      <ng-container matColumnDef="LotCodes">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Lot Codes</th>
        <td mat-cell *matCellDef="let element">
          {{ element.AJLTC1 + " " + element.AJLTC2 + " " + element.AJLTC3 }}
        </td>
      </ng-container>
      <ng-container matColumnDef="Location">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Location</th>
        <td mat-cell *matCellDef="let element">
          {{
          element.AJBLDG +" "+ element.AJFLOR + element.AJAISL + "/" + element.AJSLOT
          }}
        </td>
      </ng-container>

      <ng-container matColumnDef="Bkt">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Bkt</th>
        <td mat-cell *matCellDef="let element">{{ element.AJITYP }}</td>
      </ng-container>

      <ng-container matColumnDef="Qty">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Qty</th>
        <td mat-cell *matCellDef="let element">{{ element.AJADQY }}</td>
      </ng-container>
      <ng-container matColumnDef="User">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>User</th>
        <td mat-cell *matCellDef="let element">{{ element.AJCHGU }}</td>
      </ng-container>
      <ng-container matColumnDef="TransactionDate">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Transaction Date</th>
        <td mat-cell *matCellDef="let element">
          {{ element.AJADDD + "/" + element.AJADMM + "/" + element.AJADYY }}
        </td>
      </ng-container>
      <ng-container matColumnDef="BolTally">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>Bol/Tally</th>
        <td mat-cell *matCellDef="let element">{{ element.BolTally }}</td>
      </ng-container>

      <ng-container matColumnDef="Actions">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
        <!-- Opt --> 
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="dropdown more_options_btn">
            <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
              <mat-icon>more_vert</mat-icon>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <!-- <mat-option>
          <button mat-icon-button (click)="itemAdjustmentInquiry(element)">
            <mat-icon mat-icon-button color="warn">edit</mat-icon>
            <mat-label>Item Adjustment Inquiry</mat-label>
          </button></mat-option
        > -->

              <li>
                <button mat-icon-button (click)="itemAdjustmentDelete(element)">
                  <mat-icon mat-icon-button color="warn">delete</mat-icon>
                  <mat-label>Item Adjustment Delete</mat-label>
                </button>
              </li>

              <li>
                <button mat-icon-button (click)="itemAdjustmentChangeDate(element)">
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label>Item Adjustment Change Date</mat-label>
                </button>
              </li>


              <li>
                <button mat-icon-button (click)="itemAdjustmentDisplay(element)">
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label>Item Adjustment Inquiry</mat-label>
                </button>
              </li>
            </ul>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator #paginator [pageSizeOptions]="[4, 8, 12]" 
      showFirstLastButtons class="tally-table-pagination"
    ></mat-paginator>
 
</mat-card>