<mat-card class="over-Flow">

   <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Change Queue Priorities</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

      <mat-card-content class="tally-serach-form"> 
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
          
              <mat-form-field  appearance="outline"><mat-label> Item </mat-label> 
                  <input readonly matInput formControlName="item" />
              </mat-form-field>
          
            
              <mat-form-field appearance="outline">  <mat-label> Lots </mat-label> 
                    <input readonly
                    matInput
                    formControlName="lots"
                    style="text-transform: uppercase"/>
                
              </mat-form-field>
        
              <mat-form-field appearance="outline">  <mat-label> From  </mat-label> 
                <input  readonly matInput formControlName="from" />
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> To  </mat-label> 
                <input readonly  matInput formControlName="to" />
              </mat-form-field>

          
              <mat-form-field appearance="outline">  <mat-label> Source </mat-label> 
                <input readonly
                  matInput
                  formControlName="source"
                  style="text-transform: uppercase"
                />            
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> Update Priority By  </mat-label> 
                <input matInput formControlName="updatePriorityBy" placeholder="enter a value between 1-99 both inclusive"/>
              </mat-form-field>

              <mat-form-field appearance="outline"  style="width: 35%;"><mat-label>Change for only this move</mat-label> 
        
                    <mat-select matNativeControl required>
                        <mat-option value="Y">Y</mat-option>  
                        <mat-option value="N">N</mat-option> 
                    </mat-select>
                    <input matInput formControlName="changeThisMove" />
              </mat-form-field>
              
              <mat-form-field appearance="outline" style="width: 35%;"><mat-label> Change all moves<br>for this source document </mat-label> 
        
                    <mat-select matNativeControl required>
                        <mat-option value="Y">Y</mat-option>  
                        <mat-option value="N">N</mat-option> 
                    </mat-select>
                    <input matInput formControlName="changeAllForDoc" />
              </mat-form-field>
            
              <mat-form-field appearance="outline" style=" width: 35%;"> 
                 <mat-label> Change all moves for this item </mat-label> 
        
                    <mat-select matNativeControl required>                          
                        <mat-option value="Y">Y</mat-option> 
                        <mat-option value="N">N</mat-option>
                    </mat-select>
                    <input matInput formControlName="changeAllForItem" />
              </mat-form-field>
          
              <mat-form-field appearance="outline" style="width: 35%;">  <mat-label> Change all moves<br>for the From location </mat-label> 
        
                    <mat-select matNativeControl required>
                      <mat-option value="Y">Y</mat-option> 
                      <mat-option value="N">N</mat-option>
                    </mat-select>
                    <input matInput formControlName="changeAllForFromLoc" />
              </mat-form-field>
          
              <mat-form-field appearance="outline" style="width: 35%;">  <mat-label> Change all moves<br>for the To location </mat-label> 
        
                    <mat-select matNativeControl required>
                      <mat-option value="Y">Y</mat-option> 
                      <mat-option value="N">N</mat-option>
                    </mat-select>
                    <input matInput formControlName="changeAllForToLoc" />                                          
              </mat-form-field>
            
            </div>
          </div>
          <button [disabled]="!(form.valid && form.dirty)" mat-button class="buttons addButton" (click)="updateMovePriority()">Update Prioriry</button>
        </form>  
      </mat-card-content>
</mat-card>
      
  