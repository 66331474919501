import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { ItemTallyInquiryComponent } from './item-tally-inquiry/item-tally-inquiry.component';
import { IitemService } from '../iitem.service';

@Component({
  selector: 'app-item-tally-work-with',
  templateUrl: './item-tally-work-with.component.html',
  styleUrls: ['./item-tally-work-with.component.css'],
})
export class ItemTallyWorkWithComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'TallyNumber',
    'Seq',
    'ST',
    'transDate',
    'ArrivalDate',
    'Expected',
    'Recv',
    'Lots',
    'opt',
  ];

  dataSource: any = [];

  duplicateSource: any = [];

  constructor(
    private router: Router,
    public iItemSvc: IitemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ItemTallyWorkWithComponent>,

    @Inject(MAT_DIALOG_DATA) public anyvariable: any
  ) {}
  user = localStorage.getItem('userName');
  result: any;
  sequence: any;
  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({
      Storer: this.anyvariable.ITSTOR,
      Item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
    });

    this.iItemSvc
      .getItemTally(
        this.user,
        this.anyvariable.ITSTOR,
        this.anyvariable.ITSSFX,

        this.anyvariable.ITEM
      )
      .subscribe((data) => {
        this.result = data;
        // this.TallyHeaders = data;
        var res = this.result[0].data[1]?.value;
        console.log(res);
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  itemTallyInquiry(element: any) {
    this.matDialog.open(ItemTallyInquiryComponent, {
      width: '1000px',
      height: 'auto',
      maxHeight: '95vh',
      data: element,
      autoFocus: false,
    });
  }

  jumpToTally(element:any){
    this.router.navigateByUrl('/main-nav/tallys/read', { state: element });
    this.closeDialog();
  }

  onBack() {
    this.router.navigate(['/main-nav/item/read']);
    
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
