<form [formGroup]="form">
    <mat-card>
      <mat-card-header>
          <button mat-button (click)="backstorer()">
              <mat-icon></mat-icon>
              
          </button>
          
        <mat-card-title style="color: rgb(145, 128, 32)"
          >Maintain Freight Audit</mat-card-title>
        
      </mat-card-header>
      <div>
        
      </div>
      <mat-card-content> 

        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline"><mat-label> loadNumber : </mat-label> 
                    <input matInput formControlName="loadNumber" />
                  </mat-form-field>    
            </div>
            <div class="col"><mat-form-field appearance="outline">  <mat-label> referenceNumber: </mat-label> 
              <input matInput formControlName="referenceNumber" />
            </mat-form-field></div>
            </div>
       <div >
          <div class="col"><mat-form-field appearance="outline"><mat-label> headerStatus:  </mat-label> 
              <input matInput formControlName="headerStatus" />
            </mat-form-field></div>
            </div>
       <div >
       </div>

       <div >
  
        <button mat-button class="buttonCss" (click)="attributes()">Status</button>
    </div>
              
      </mat-card-content>
    </mat-card>
  </form>  
