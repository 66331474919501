<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title style="color: rgb(203, 206, 212)"
                    >Reset Event Status</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label>Bill Number: </mat-label> 
                          <input matInput formControlName="EVDOCN" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">  <mat-label> Event Number:</mat-label> 
                            <input matInput formControlName="EVNUMR" />
                          </mat-form-field>
                  </div>
                  <div class="col">
                        <mat-form-field appearance="outline">  <mat-label> Event Status Code:</mat-label> 
                            <input matInput formControlName="EVCODE" />
                          </mat-form-field>

                         <mat-form-field appearance="outline">  <mat-label> Event Status: </mat-label> 
                            <input matInput formControlName="EVSTAT"/>
                         </mat-form-field>
                   </div>
                 <div class="col">
                    
                      <button mat-button
                      class="buttons addButton"
                      mat-raised-button
                      (click)="attributes()">
                      Change
                    </button> </div>
                        
                </mat-card-content>
              </mat-card>
            </form> 