import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-carriers-add',
  templateUrl: './carriers-add.component.html',
  styleUrls: ['./carriers-add.component.css']
})
export class CarriersAddComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<CarriersAddComponent>
   ) 
   { }

  ngOnInit(): void {
    this.form = this.fb.group({
  
    SCACCode: 'SXTI',
    CarrierName:'SXTI ',
    CarrierAddress:'123 test',
    ZipPostalCode:'12222  Albany NY',
    FuelSurchargeTBL:'',
    HazardousMaterials:'Y',
    PalletExchange:'Y',
    SummaryPayments:'Y',
    ChangeDate:'2015-10-29-16.44.11.904034',
    ChangedBy:'KPAVER',
    Status:'AC',

 
   
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  
  attributes(){
    
  }


}
