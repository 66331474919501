<mat-card class="over-Flow">
    <mat-card-header>
      <mat-card-title class="title">Work With Work Schedule</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="buttons">
        <button (click)="workScheduleCreate()"
          mat-button
          
          class="addButton buttons"
        >
          <mat-icon>add</mat-icon>
          <span>New Operator Equipment</span>
        </button>
      </div>
  
      <form [formGroup]="form" class="form over-Flow">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>Type Of Hours</mat-label>
                  <mat-select  formControlName="typeOfHours">
                   
                    <mat-option value="APPT">APPT</mat-option>
                    <mat-option value="SHF1">SHF1</mat-option>
                    <mat-option value="SHF2">SHF2</mat-option>
                    <mat-option value="SHF3">SHF3</mat-option>
                   
                  </mat-select>
              </mat-form-field>
         
            <mat-form-field appearance="outline"
              ><mat-label> Building </mat-label>
              <input matInput formControlName="building" />
            </mat-form-field>

            <mat-form-field appearance="outline"
              ><mat-label> Account Code </mat-label>
              <input matInput formControlName="accountCode" />
            </mat-form-field>
          
        </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              class="buttons addButton"
              mat-raised-button
              type="submit"
              placeholder="Search"
            >
              Search
            </button>
          </div>
        </div>
      </form>
   
  
    <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>
  
      <ng-container matColumnDef="build">
        <th mat-header-cell *matHeaderCellDef>Build</th>
        <td mat-cell *matCellDef="let element">{{ element.build }}</td>
      </ng-container>

      <ng-container matColumnDef="account">
        <th mat-header-cell *matHeaderCellDef>Account</th>
        <td mat-cell *matCellDef="let element">{{ element.account }}</td>
      </ng-container>

      <ng-container matColumnDef="dayOfTheWeek">
        <th mat-header-cell *matHeaderCellDef>Day Of Week</th>
        <td mat-cell *matCellDef="let element">{{ element.dayOfTheWeek }}</td>
      </ng-container>

      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef>Start Time</th>
        <td mat-cell *matCellDef="let element">{{ element.startTime }}</td>
      </ng-container>

      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef>End Time</th>
        <td mat-cell *matCellDef="let element">{{ element.endTime }}</td>
      </ng-container>

      
  
      <ng-container matColumnDef="Actions">
        <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-select class="mat-select" placeholder="Select Option">
            <mat-option>
              <button mat-icon-button (click)="workScheduleUpdate()">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button></mat-option
            >
  
            <mat-option>
              <button mat-icon-button (click)="workScheduleDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button></mat-option
            >
          </mat-select>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[4, 8, 16]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card-content>
  </mat-card>

