import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.css'],
})
export class CustomerCreateComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<CustomerCreateComponent>,
    private router: Router,
    private fb: FormBuilder
  ) {}

  addCustomerForm: any = this.fb.group({
    CUST: [null],
    CUNAME: [null],
    CUADD1: [null],
    CUZIP: [null],
    CUPHAR: [null],
    CUFXAR: [null],
    CUCPER: [null],
    CUSORT: [null],
    CUSTPR: [null],
  });

  closeDialog() {
    this.dialogRef.close();
  }
  ngOnInit(): void {}
}
