import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-trip-expenses-add',
  templateUrl: './trip-expenses-add.component.html',
  styleUrls: ['./trip-expenses-add.component.css']
})
export class TripExpensesAddComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<TripExpensesAddComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      TripNumber:'',
      Date:'',
      Category:'',
      SubCategory:'',
      Description:'',
      Quantity:'',
      Rate:'',
      Amount:'',
      ReceiptExists:'',
      ClientReimbursable:'',
      OutOfPocket:''
    })
  }

  addExpense(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


}
