<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Assign A Move Queue Entry</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"><mat-label> Item : </mat-label>
            <input matInput formControlName="item" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline"><mat-label> Transaction No : </mat-label>
            <input matInput formControlName="transNo" readonly />
          </mat-form-field>

          <!-- <hr/> -->

          <mat-form-field appearance="outline">
            <mat-label> Lots: </mat-label>

            <input readonly matInput formControlName="lots" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Quantity : </mat-label>
            <input matInput formControlName="quantity" readonly />
          </mat-form-field>
          <hr />

          <mat-form-field appearance="outline">
            <mat-label> Type: </mat-label>
            <input readonly matInput formControlName="type" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> From : </mat-label>
            <input matInput formControlName="from" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Bucket: </mat-label>
            <input readonly matInput formControlName="bucket" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Source: </mat-label>
            <input matInput formControlName="source" readonly />
          </mat-form-field>

          <hr />
          <mat-form-field appearance="outline">
            <mat-label> To: </mat-label>
            <input matInput formControlName="to" readonly />
          </mat-form-field>
          

          <mat-form-field appearance="outline">
            <mat-label> Operator ID: </mat-label>
            <input matInput formControlName="opertaorID" required />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Priority: </mat-label>
            <input matInput formControlName="priority" maxlength="2" />
          </mat-form-field>

        </div>
                  <!-- <button mat-button class="buttons addButton" (click)="assignUpdate()">
            Assign
          </button> -->
      </div>
        <button [disabled]="!(form.valid && form.dirty)" mat-button class="buttons addButton" (click)="assignUpdate()">Assign</button>
    </form>        
  </mat-card-content>
</mat-card>