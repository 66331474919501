<!-- <mat-card >-->
<!--<div class="buttons"> 
    <button class="backButton" mat-button (click)="onBack()">
      <mat-icon>navigate_before </mat-icon>
      <span>Tally</span>
    </button>
  </div>-->

<!-- <mat-grid-list cols="1" rows="1" rowHeight="2:1">
    <mat-grid-tile class="over-Flow"> -->
<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form class="over-Flow" [formGroup]="updateForecastForm">
  <mat-card class="over-Flow">
    <mat-card-header>
      <mat-card-title> Update a Forecast</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Storer </mat-label>
            <input matInput required formControlName="IFSTOR" />
            <mat-error> storer is <strong>required</strong> </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Consignee </mat-label>
            <input
              matInput
              formControlName="IFCONS"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Forecast Date </mat-label>
            <input
              matInput
              formControlName="FDAT"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Forecast Type </mat-label>
            <input
              matInput
              formControlName="IFFTYP"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Delivery Type </mat-label>
            <input
              matInput
              formControlName="IFDTYP"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Begin Horizon </mat-label>
            <input
              matInput
              required
              formControlName="BHOR"
              style="text-transform: uppercase"
            />
            <!-- <mat-error> Zip Code From is <strong>required</strong> </mat-error> -->
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> End Horizon </mat-label>
            <input matInput formControlName="EHOR" />
            <!-- <mat-error> Phone Number is <strong>required</strong> </mat-error> -->
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Ship To Location </mat-label>
            <input
              matInput
              formControlName="IFDLOC"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Quantity Type </mat-label>
            <input matInput formControlName="IFQTYP" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Unit of Measure </mat-label>
            <input
              matInput
              formControlName="IFUOM"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Supplier </mat-label>
            <input
              matInput
              formControlName="IFSPLR"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Reference </mat-label>
            <input
              matInput
              formControlName="IFSREF"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Release </mat-label>
            <input
              matInput
              formControlName="IFRELS"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Status </mat-label>
            <input
              matInput
              formControlName="IFSTAT"
              style="text-transform: uppercase"
            />
          </mat-form-field>
        </div>
      </div>
      <button class="buttons addButton" (click)="update()" mat-raised-button>
        Update
      </button>
    </mat-card-content>
  </mat-card>
</form>
<!-- </mat-grid-tile>
                                              </mat-grid-list>
                                            </mat-card> -->
