import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { IexitPointsService } from '../iexit-points.service';

@Component({
  selector: 'app-exit-points-update',
  templateUrl: './exit-points-update.component.html',
  styleUrls: ['./exit-points-update.component.css'],
})
export class ExitPointsUpdateComponent implements OnInit {
  storer: any;
  form!: FormGroup;
  result: any;
  constructor(
    public iExitSvc: IexitPointsService,

    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExitPointsUpdateComponent>,
    private router :Router
  ) {}

  user=localStorage.getItem('userName');

  ngOnInit(): void {
    this.form = this.fb.group({
      Storer: this.anyvariable.SFSTOR,
      ExitPoint: this.anyvariable.SFFORM,
      Choice: this.anyvariable.SFPGM,
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }
  updateExitPoint() {
    var res = this.form.value;
    console.log(res);

    this.result = this.iExitSvc
      .updateExitPoint(
        res.Storer,
        this.anyvariable.SFSSFX,
        res.ExitPoint,
        res.Choice,
       this.user)
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       // var msg = JSON.parse(this.result).returnedData.message;

       // this.successMessage(msg);
       if(JSON.parse(this.result[0].data[1].value).success){
        //alert('Record Created SuccessFully');
       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg)
        this. closeDialog();
        this.router.navigate(['/main-nav/exitpoint/read']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/exitpoint/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
}
