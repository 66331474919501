import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { MoveQueueService } from '../move-queue.service';

@Component({
  selector: 'app-move-change-staging-location',
  templateUrl: './move-change-staging-location.component.html',
  styleUrls: ['./move-change-staging-location.component.css']
})
export class MoveChangeStagingLocationComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    public moveQueueSrv: MoveQueueService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router : Router,
   private dialogRef: MatDialogRef<MoveChangeStagingLocationComponent>
   ) 
   { }

  ngOnInit(): void {
    this.form = this.fb.group({
  
      item: this.anyvariable.MQITEM,
      transNo: this.anyvariable.MQTRAN,
      lots: this.anyvariable.MQLOT1 + ' | ' + this.anyvariable.MQLOT2  + ' | ' +  this.anyvariable.MQLOT3,
      from:  this.anyvariable.MQBLDG + '  ' + this.anyvariable.MQFRAI+'/'+this.anyvariable.MQFRSL,
      source: this.anyvariable.MQDOCN,
      to: this.anyvariable.MQBLDG + '  ' + this.anyvariable.MQTOAI+'/'+this.anyvariable.MQTOSL,
      newStagingloc: '',
      changeThisMove: '',
      changeAllMoves: '',  
       
    });
  }


  updateStageLoc(){
    var res = this.form.value;
    
    console.log('#######################################################');
   console.log(res);
    this.result = this.moveQueueSrv
      .updateMoveStagingLoc( 
        
        this.user,
        res.transNo,
        res.newStagingloc,
        res.changeThisMove,
        res.changeAllMoves
        

        ).subscribe((data: any) => {
        this.result = data;
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

    //   console.log(this.result[0].data[16].value);
       console.log("*********************");
       var length = this.result[0].data.length;

       console.log(this.result[0].data[length-1].value=='Staging Location Updated Successfully');

      

       // if(JSON.parse(this.result[0].data[1].value).success){
          if( this.result[0].data[length-1].value=='Staging Location Updated Successfully'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/movequeue/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/movequeue/read']);
  }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }


}
