import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class IitemService {
  url = environment.API_BASE_URL;
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getItemAll(user: any): Observable<any> {
    return this.httpClient.get(this.url + `/item/read?user=${user}`);
  }

  getAll(user: any): Observable<any> {
    return this.httpClient.get(this.url + `/consignee/readAll?user=${user}`);
  }

  getItemTally(
    user: any,
    storer: any,
    suffix: any,
    item: any
  ): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/item/read/tally?user=${user}&storer=${storer}&suffix=${suffix}&item=${item}`
    );
  }

  getItemAdjustmentBySearch(
    user: any,
    storer: any,
    item: any,
    type: any,
    date: any,
    month:any,
    year:any,
    building: any,
    floor: any,
    aisle: any,
    slot: any,
    lot1: any,
    lot2: any,
    lot3: any
  ): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/itemAdjustment/readBySearch?user=${user}&storer=${storer}&item=${item}&type=${type}&date=${date}&month=${month}&year=${year}&building=${building}&floor=${floor}&aisle=${aisle}&slot=${slot}&lot1=${lot1}&lot2=${lot2}&lot3=${lot3}`
    );
  }

  getItemBySearch(
    user: any,
    storer: any,
    suffix: any,
    item: any
  ): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/item/readBySearch?user=${user}&storer=${storer}&suffix=${suffix}&item=${item}`
    );
  }

  getItemAdjustment(user: any, storer: any): Observable<any> {
    return this.httpClient.get(
      this.url + `/itemAdjustment/read?user=${user}&storer=${storer}`
    );
  }

  getItemAdjustmentInquiry(user: any, transaction: any, storer:any,suffix:any,item:any,lotCode1:any,lotCode2:any,lotCode3:any,building:any,floor:any,aisle:any,slot:any): Observable<any> {
    return this.httpClient.get(
      this.url + `/itemAdjustment/itemAdjInqRead?user=${user}&transaction=${transaction}&storer=${storer}&suffix=${suffix}&item=${item}&lotCode1=${lotCode1}&lotCode2=${lotCode2}&lotCode3=${lotCode3}&building=${building}&floor=${floor}&aisle=${aisle}&slot=${slot}`
    );
  }

 

  itemCreate1(
    storeNumber: any,
    suffix: any,
    item: any,
    itemDescription1: any,
    itemDescription2: any,
    itemUpcCode: any,
    itemUpcTailCode: any,
    manufacturerProdCode: any,
    quickItem: any,
    lottedItem: any,
    lotUnitWeight: any,
    lotSortMask: any,
    primSortMaskPointer: any,
    prevItem: any
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/item/create1', {
        storeNumber,
        suffix,
        item,
        itemDescription1,
        itemDescription2,
        itemUpcCode,
        itemUpcTailCode,
        manufacturerProdCode,
        quickItem,
        lottedItem,
        lotUnitWeight,
        lotSortMask,
        primSortMaskPointer,
        prevItem,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  itemCreate2(
    storeNumber: any,
    suffix: any,
    item: any,
    unitGrossWeight: any,
    unitWeightType: any,
    casesPerTier: any,
    tiersPerPallet: any,
    unitSquareFeet: any,
    unitCubeFeet: any,
    unitInchesHigh: any,
    unitInchesWide: any,
    unitInchesDeep: any,
    unitOfMeasure1: any,
    unitOfMeasure2: any,
    qtyPer2UnitOfMultiplier: any,
    unitOfMeasure3: any,
    qtyPer3UnitOfMultiplier: any,
    unitOfMeasure4: any,
    qtyPer4UnitOfMultiplier: any,
    inventoryUnit: any,
    shippingUnit: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/item/create2', {
        storeNumber,
        suffix,
        item,
        unitGrossWeight,
        unitWeightType,
        casesPerTier,
        tiersPerPallet,
        unitSquareFeet,
        unitCubeFeet,
        unitInchesHigh,
        unitInchesWide,
        unitInchesDeep,
        unitOfMeasure1,
        unitOfMeasure2,
        qtyPer2UnitOfMultiplier,
        unitOfMeasure3,
        qtyPer3UnitOfMultiplier,
        unitOfMeasure4,
        qtyPer4UnitOfMultiplier,
        inventoryUnit,
        shippingUnit,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  itemCreate3(
    storeNumber: any,
    suffix: any,
    item: any,
    domesticSurface: any,
    domesticAir: any,
    internationalSurface: any,
    internationalAir: any,
    matHandlingCode1: any,
    matHandlingCode2: any,
    matHandlingCode3: any,
    warehouseZone: any,
    bmtCode: any,
    palletsHigh: any,
    palletsDeep: any,
    lowStockPoint: any,
    prefferedLocation: any,
    pickLine: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/item/create3', {
        storeNumber,
        suffix,
        item,
        domesticSurface,
        domesticAir,
        internationalSurface,
        internationalAir,
        matHandlingCode1,
        matHandlingCode2,
        matHandlingCode3,
        warehouseZone,
        bmtCode,
        palletsHigh,
        palletsDeep,
        lowStockPoint,
        prefferedLocation,
        pickLine,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  itemCreate4(
    storeNumber: any,
    suffix: any,
    item: any,
    rcvStageRate: any,
    rcvStageBase: any,
    rcvStorageMin: any,
    rcrStorageRate: any,
    rcrStorageBase: any,
    rcrStorageMin: any,
    regHandlingRate: any,
    regHandlingBase: any,
    regHandlingMin: any,
    distHandlingRate: any,
    distHandlingBase: any,
    distHandlingMin: any,
    altHandlingRate: any,
    altHandlingBase: any,
    altHandlingMin: any,
    insLiabilityAmount: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/item/create4', {
        storeNumber,
        suffix,
        item,
        rcvStageRate,
        rcvStageBase,
        rcvStorageMin,
        rcrStorageRate,
        rcrStorageBase,
        rcrStorageMin,
        regHandlingRate,
        regHandlingBase,
        regHandlingMin,
        distHandlingRate,
        distHandlingBase,
        distHandlingMin,
        altHandlingRate,
        altHandlingBase,
        altHandlingMin,
        insLiabilityAmount,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  itemServiceUpdate1(
    storeNumber: any,
    suffix: any,
    item: any,
    itemDescription1: any,
    itemDescription2: any,
    itemUpcCode: any,
    itemUpcTailCode: any,
    manufacturerProdCode: any, //Product Code
    quickItem: any, //Allow Web Selection
    lottedItem: any,
    lotUnitWeight: any,
    lotSortMask: any,
    primSortMaskPointer: any, //Apply to Lot
    prevItem: any // Place After
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/item/update1', {
        storeNumber,
        suffix,
        item,
        itemDescription1,
        itemDescription2,
        itemUpcCode,
        itemUpcTailCode,
        manufacturerProdCode,
        quickItem,
        lottedItem,
        lotUnitWeight,
        lotSortMask,
        primSortMaskPointer,
        prevItem,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  itemServiceUpdate2(
    storeNumber: any,
    suffix: any,
    item: any,
    unitGrossWeight: any,
    unitWeightType: any,
    casesPerTier: any,
    tiersPerPallet: any,
    unitSquareFeet: any,
    unitCubeFeet: any,
    unitInchesHigh: any,
    unitInchesWide: any,
    unitInchesDeep: any,
    unitOfMeasure1: any,
    unitOfMeasure2: any,
    qtyPer2UnitOfMultiplier: any,
    unitOfMeasure3: any,
    qtyPer3UnitOfMultiplier: any,
    unitOfMeasure4: any,
    qtyPer4UnitOfMultiplier: any,
    inventoryUnit: any,
    shippingUnit: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/item/update2', {
        storeNumber,
        suffix,
        item,
        unitGrossWeight,
        unitWeightType,
        casesPerTier,
        tiersPerPallet,
        unitSquareFeet,
        unitCubeFeet,
        unitInchesHigh,
        unitInchesWide,
        unitInchesDeep,
        unitOfMeasure1,
        unitOfMeasure2,
        qtyPer2UnitOfMultiplier,
        unitOfMeasure3,
        qtyPer3UnitOfMultiplier,
        unitOfMeasure4,
        qtyPer4UnitOfMultiplier,
        inventoryUnit,
        shippingUnit,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  itemServiceUpdate3(
    storeNumber: any,
    suffix: any,
    item: any,
    domesticSurface: any,
    domesticAir: any,
    internationalSurface: any,
    internationalAir: any,
    matHandlingCode1: any,
    matHandlingCode2: any,
    matHandlingCode3: any,
    warehouseZone: any,
    bmtCode: any,
    palletsHigh: any,
    palletsDeep: any,
    lowStockPoint: any,
    prefferedLocation: any,
    PickLine: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/item/update3', {
        storeNumber,
        suffix,
        item,
        domesticSurface,
        domesticAir,
        internationalSurface,
        internationalAir,
        matHandlingCode1,
        matHandlingCode2,
        matHandlingCode3,
        warehouseZone,
        bmtCode,
        palletsHigh,
        palletsDeep,
        lowStockPoint,
        prefferedLocation,
        PickLine,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  itemServiceUpdate4(
    storeNumber: any,
    suffix: any,
    item: any,
    rcvStageRate: any,
    rcvStageBase: any,
    rcvStorageMin: any,
    rcrStorageRate: any,
    rcrStorageBase: any,
    rcrStorageMin: any,
    regHandlingRate: any,
    regHandlingBase: any,
    regHandlingMin: any,
    distHandlingRate: any,
    distHandlingBase: any,
    distHandlingMin: any,
    altHandlingRate: any,
    altHandlingBase: any,
    altHandlingMin: any,
    insLiabilityAmount: any
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/item/update4', {
        storeNumber,
        suffix,
        item,
        rcvStageRate,
        rcvStageBase,
        rcvStorageMin,
        rcrStorageRate,
        rcrStorageBase,
        rcrStorageMin,
        regHandlingRate,
        regHandlingBase,
        regHandlingMin,
        distHandlingRate,
        distHandlingBase,
        distHandlingMin,
        altHandlingRate,
        altHandlingBase,
        altHandlingMin,
        insLiabilityAmount,
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteConsignee(user: any, consCode: any): Observable<any> {
    return this.httpClient.delete<any>(
      this.url + `/consignee/delete?user=${user}&consCode=${consCode}`
    );
  }

  deleteItem(storeNumber: any, suffix: any, item: any): Observable<any> {
    return this.httpClient.delete(
      this.url +
        `/item/delete?storeNumber=${storeNumber}&suffix=${suffix}&item=${item}`
    );
  }

  readItemLotService(
    user: any,
    storer: any,
    suffix: any,
    item: any,
    lot1:any,
    lot2:any,
    lot3:any
  ): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/item/readItemLots?user=${user}&storer=${storer}&suffix=${suffix}&item=${item}&lot1=${lot1}&lot2=${lot2}&lot3=${lot3}`
    );
  }

  getItemXRef(
    user: any,
    storer: any,
    suffix: any,
    itemCode: any,
    
  ): Observable<any> {
    return this.httpClient.get(
      this.url +
        `/itemXreference/read?user=${user}&storer=${storer}&suffix=${suffix}&itemCode=${itemCode}`
    );
  }

  createItemXref(
    user: any,
    acdl: any,
    storer: any,
    suffix: any,
    itemXcode: any,
    itemCode: any,
    itemXupc: any,
    
  ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/itemXreference/create', {
        user,
        acdl,
        storer,
        suffix,
        itemXcode,
        itemCode,
        itemXupc
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateItemXref(
    user: any,
    acdl: any,
    storer: any,
    suffix: any,
    itemXcode: any,
    itemCode: any,
    itemXupc: any,
    
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/itemXreference/update', {
        user,
        acdl,
        storer,
        suffix,
        itemXcode,
        itemCode,
        itemXupc
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  updateItemLotsService(
    user: any,
    storer: any,
    suffix: any,
    item:any,
    lot1: any,
    lot2: any,
    lot3: any,
    lotUnitWeight:any,
    lotFirstRecieved:any,
    lotLastShipped:any,
    status:any
    
  ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/item/updateItemLots', {
        user,
        storer,
        suffix,
        item,
        lot1,
        lot2,
        lot3,
        lotUnitWeight,
        lotFirstRecieved,
        lotLastShipped,
        status
      })
      .pipe(retry(1), catchError(this.httpError));
  }


  deleteItemLotsService(user: any, storer:any, suffix: any, item: any, lot1:any, lot2:any, lot3:any): Observable<any> {
    return this.httpClient.delete(
      this.url +
        `/item/deleteItemLots?user=${user}&storer=${storer}&suffix=${suffix}&item=${item}&lot1=${lot1}&lot2=${lot2}&lot3=${lot3}`
    );
  }

  deleteItemAdjustment(tansacNun:any): Observable<any> {
    return this.httpClient.delete(
      this.url +`/itemAdjustment/delete?TransacNum=${tansacNun}`
    );

  }


  //---- ITEM COMMENTS ------

  readItemCommentService(user:any, storerAndItem: any, subType: any, sequence:any): Observable<any> {
    return this.httpClient
      .get<any>(this.url+`/item/comments/read?user=${user}&storerAndItem=${storerAndItem}&subType=${subType}&sequence=${sequence}`)
      .pipe(retry(1), catchError(this.httpError));
  }

  createItemCommentService(
    storerAndItem:any,
    subType:any,
    sequence:any,
    comment:any,  
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .post<any>(this.url+'/item/comments/create', {
        storerAndItem,
        subType,
        sequence,
        comment,  
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  updateItemCommentService(
    storerAndItem:any,
    subType:any,
    sequence:any,
    comment:any,  
    user:any
  ): Observable<any> {
    
    return this.httpClient
   
      .put<any>(this.url+'/item/comments/update', {
        storerAndItem,
        subType,
        sequence,
        comment,  
        user
      })
      .pipe(retry(1), catchError(this.httpError));
  }

  deleteItemCommentService(user:any, storerAndItem: any, subType: any, sequence:any): Observable<any> {
    return this.httpClient
      .delete<any>(this.url+`/item/comments/delete?user=${user}&storerAndItem=${storerAndItem}&subType=${subType}&sequence=${sequence}`)
      .pipe(retry(1), catchError(this.httpError));
  }


  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
