<mat-card class="over-Flow">
      <mat-card-header>  
        <mat-card-title  class="title" >
    
          Work With StorerAttributes Content</mat-card-title>
      </mat-card-header>
    <mat-card-content>
      <div class="buttons">
        <button mat-button (click)="StorerAttributesCreateForm()" class="addButton buttons">
          <mat-icon>add</mat-icon>
          <span>New Storer Attribute</span>
        </button>
      </div>
                  
      <form [formGroup]="form" class="form over-Flow">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Storer</mat-label>
                <input matInput formControlName="IASTOR"/>
            </mat-form-field>
               <button
                class="addButton"
                   mat-raised-button
                      type="submit"
                      placeholder="Search">
                      Search
                </button>
              </div>
            </div>
          </form>
                     
      </mat-card-content>
       <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Attribute">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Attribute
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.IANTYP }}</td>
        </ng-container>

        <ng-container matColumnDef="Value">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Value
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.IANUMB }}</td>
          </ng-container>
          
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
       
            <mat-option>
            <button mat-icon-button (click)="StorerAttributesUpdateForm(element)">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Change</mat-label>
            </button>
          </mat-option>  
          
          
          
          <mat-option>
            <button mat-icon-button (click)="StorerAttributesDeleteForm(element)">
              <mat-icon mat-icon-button color="primary">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button>
          </mat-option> 
          
            
          </mat-select>
          </td>
        </ng-container>
  
       
  
        
      </table>
      <mat-paginator
      #paginator
      [pageSizeOptions]="[4, 8, 16]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card>
  
