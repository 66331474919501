import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { IitemService } from '../iitem.service';
import { ItemAdjustmentChangeDateComponent } from './item-adjustment-change-date/item-adjustment-change-date.component';
import { ItemAdjustmentDeleteComponent } from './item-adjustment-delete/item-adjustment-delete.component';
import { ItemAdjustmentDisplayComponent } from './item-adjustment-display/item-adjustment-display.component';
import { ItemAdjustmentInquiryComponent } from './item-adjustment-inquiry/item-adjustment-inquiry.component';
import { AnimateTimings } from '@angular/animations';
import Swal from 'sweetalert2';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';
import { TableServiceService } from 'src/app/table-service.service';
import { CustomColumn } from 'src/app/table-service.service';
@Component({
  selector: 'app-item-adjustment-work-with',
  templateUrl: './item-adjustment-work-with.component.html',
  styleUrls: ['./item-adjustment-work-with.component.css'],
})
export class ItemAdjustmentWorkWithComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Number',
    'L',
    'Date',
    'LotCodes',
    'Location',
    'Bkt',
    'Qty',
    'Actions',
  ];
  otherColumns:string[] = [
    'User',
    'TransactionDate',
    'BolTally',
  ]
  dataSource: any = [];
  dialogRef: any;

  // duplicateSource:any =[
  //   {
  //    Number:'123494230',
  //    L:'I',
  //    Date:'10/19/20',
  //    LotCodes:'',
  //    Location:'13  1/A',
  //    Bkt:'A',
  //    Qty:'5',
  //    Opt:'',
  //   },
  //   {
  //     Number:'123494183',
  //     L:'I',
  //     Date:'9/24/20',
  //     LotCodes:'',
  //     Location:'13  1/A',
  //     Bkt:'A',
  //     Qty:'100',
  //     Opt:'',
  //    }

  // ]

  constructor(
    private router: Router,
    // public iHeaderSvc: IheaderService,
    public itemService: IitemService,
    public tableSvc :TableServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
   private CustomAlertService: CustomAlertService,
  ) {}

  user = localStorage.getItem('userName');
  storer = localStorage.getItem('storer');
  Date: any;
  date: any;
  month: any;
  year: any;
  result: any;
  item: any;

  selectedRow :any;
  columnShowHideList:CustomColumn[] = []
  ngOnInit(): void {
    console.log('hitory', history.state);
    this.columnShowHideList = this.tableSvc.initializeColumnProperties(this.displayedColumns,this.otherColumns);
    this.item = history.state.ITEM + ' ' + history.state.ITDSC1;
    this.form = this.fb.group({
      Storer: [history.state.ITSTOR],
      Item: [this.item],
      Type: '',
      Date: '',
      Building: '',
      Floor: '',
      Aisle: '',
      Slot: '',
      Lot1: '',
      Lot2: '',
      Lot3: '',
    });

    this.itemService
      .getItemAdjustment(this.user, this.storer)
      .subscribe((data) => {
        this.result = data;
        //  this.TallyHeaders = data;
        //   console.log(this.TallyHeaders);
        var res = this.result[0].data[1]?.value;
        console.log(res);
        var parsedData = JSON.parse(res);
        console.log(parsedData);
        console.log('DaTA');
        // this.dataSource = parsedData.returnedData;
        this.dataSource = new MatTableDataSource(parsedData.returnedData);
        console.log(this.dataSource);
        console.log('DaTA');
        // console.log(tal);
        //console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
      });

    // this.dataSource = new MatTableDataSource(this.duplicateSource);
    // this.dataSource.paginator = this.paginator;
  }
  searchTextDisplay: any;

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  getItemAdjustment(element: any) {
    this.Date = element.Date.toString().split('-');
    this.date = this.Date[2];
    this.month = this.Date[1];
    this.year = this.Date[0];
    var slotLength = element.Slot.length;
    var aisleLength = element.Aisle.length;
    for (let i = 0; i < 4 - slotLength; i++) {
      element.Slot = ' ' + element.Slot;
    }
    for (let i = 0; i < 4 - aisleLength; i++) {
      element.Aisle = ' ' + element.Aisle;
    }
    this.itemService
      .getItemAdjustmentBySearch(
        this.user,
        element.Storer,
        history.state.ITEM,
        element.Type,
        this.date,
        this.month,
        this.year,
        element.Building,
        element.Floor,
        element.Aisle,
        element.Slot,
        element.Lot1,
        element.Lot2,
        element.Lot3
      )
      .subscribe((data) => {
        this.result = data;
        //  this.TallyHeaders = data;
        //   console.log(this.TallyHeaders);
        var res = this.result[0].data[1]?.value;
        console.log(res);
        var parsedData = JSON.parse(res);
        console.log(parsedData);
        console.log('DaTA');
        // this.dataSource = parsedData.returnedData;
        this.dataSource = new MatTableDataSource(parsedData.returnedData);
        console.log(this.dataSource);
        console.log('DaTA');
        // console.log(tal);
        //console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  toggleColumn(column:any){
    this.displayedColumns = this.tableSvc.toggleColumn(column,this.displayedColumns);
  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }
  itemAdjustmentInquiry(element: any) {
    this.matDialog.open(ItemAdjustmentInquiryComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
    });
  }

  itemAdjustmentDelete(element: any) {
    // this.matDialog.open(ItemAdjustmentDeleteComponent, {
    //   width: '1000px',
    //   maxHeight: '80vh',
    //   data:element,
    //   autoFocus: false,
    // });
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure you want to delete Item Adjustment : ' +
  
    element.AJTRAN +
    '?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
    
      this.result = this.itemService
        .deleteItemAdjustment(
          element.AJTRAN
          
        )
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);
         // var value = this.result[0].data[4].value;
         console.log('');
          console.log(this.result[0].data[this.result[0].data.length-1].value=='');
          if (this.result[0].data[this.result[0].data.length-1].value=='') {
            const msg = 'Item Adjustment Delted';

            this.successMessage(msg);
            this.reloadCurrentRoute();
          } else {
            const msg = this.result[0].data[this.result[0].data.length-1].value;

            this.failureMessage(msg);
           // this.reloadCurrentRoute();
          }
        });
      }
    });
    }
  reloadCurrentRoute() {
    this.router.navigate(['/main-nav/item/read']);
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }


  itemAdjustmentChangeDate(element: any) {
    this.matDialog.open(ItemAdjustmentChangeDateComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
    });
  }

  itemAdjustmentDisplay(element: any) {
    this.matDialog.open(ItemAdjustmentDisplayComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data:element,
      autoFocus: false,
    });
  }

  onBack() {
    this.router.navigate(['/main-nav/item/read']);
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
