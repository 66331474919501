<mat-card>
    <mat-card-header>
        <mat-card-title>Work with Labor Plan</mat-card-title>
    </mat-card-header>

    <mat-card-content>

        <button mat-button (click)="  addWwLaborPlan()" class="addButton">
            <mat-icon>add</mat-icon>
            <span>Work With Labor Plan</span>
        </button>

        <div class="formDiv">
            <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                        <mat-form-field appearance="outline">
                            <mat-label>Document</mat-label>
                            <input matInput formControlName="Document" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>MustStartDate</mat-label>
                            <input matInput formControlName="MustStartDate" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                            <mat-label>Building</mat-label>
                            <input matInput formControlName="Building" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Status</mat-label>
                            <input matInput formControlName="Status" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Storer</mat-label>
                            <input matInput formControlName="Storer" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>AppointmentDate</mat-label>
                            <input matInput formControlName="AppointmentDate" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Active</mat-label>
                            <input matInput formControlName="Active" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>SCAC</mat-label>
                            <input matInput formControlName="SCAC" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Operator</mat-label>
                            <input matInput formControlName="Operator" />
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>Manual_Auto</mat-label>
                            <input matInput formControlName="Manual_Auto" />
                        </mat-form-field>
                    </div>
                </div>
            </form>
        </div>

        
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="T">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>T</th>
                <td mat-cell *matCellDef="let element">{{ element.T }}</td>
            </ng-container>

            <ng-container matColumnDef="DocumentNumber">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    DocumentNumber
                </th>
                <td mat-cell *matCellDef="let element">{{ element.DocumentNumber }}</td>
            </ng-container>

            <ng-container matColumnDef="CR">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    CR
                </th>
                <td mat-cell *matCellDef="let element">{{ element.CR }}</td>
            </ng-container>
            <ng-container matColumnDef="RP">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>RP</th>
                <td mat-cell *matCellDef="let element">{{ element.RP }}</td>
            </ng-container>

            <ng-container matColumnDef="BuildingLocation">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    BuildingLocation
                </th>
                <td mat-cell *matCellDef="let element">{{ element.BuildingLocation }}</td>
            </ng-container>

            <ng-container matColumnDef="Storer">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Storer
                </th>
                <td mat-cell *matCellDef="let element">{{ element.Storer }}</td>
            </ng-container>
            <ng-container matColumnDef="Date">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Date
                </th>
                <td mat-cell *matCellDef="let element">{{ element.Date }}</td>
            </ng-container>

            <ng-container matColumnDef="Time">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Time
                </th>
                <td mat-cell *matCellDef="let element">{{ element.Time }}</td>
            </ng-container>
            <ng-container matColumnDef="Actv">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Actv
                </th>
                <td mat-cell *matCellDef="let element">{{ element.Actv }}</td>
            </ng-container>

            <ng-container matColumnDef="SCAC">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    SCAC
                </th>
                <td mat-cell *matCellDef="let element">{{ element.SCAC }}</td>
            </ng-container>
            <ng-container matColumnDef="Operator">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Operator
                </th>
                <td mat-cell *matCellDef="let element">{{ element.Operator }}</td>
            </ng-container>

            <ng-container matColumnDef="Location">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Location
                </th>
                <td mat-cell *matCellDef="let element">{{ element.Location }}</td>
            </ng-container>



            <ng-container matColumnDef="Actions">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Actions
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-select placeholder="Select Option">
                        <mat-option>
                            <button mat-icon-button (click)=" changeWwLaborPlan()">
                                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                                <mat-label>Change</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                            <button mat-icon-button (click)=" detailsLaborPlan()">
                                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                                <mat-label>Details</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                            <button mat-icon-button (click)=" eventInquiryLaborPlan()">
                                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                                <mat-label>Event Inquiry</mat-label>
                            </button>
                        </mat-option>
                        <mat-option>
                            <button mat-icon-button (click)=" deleteLaborPlan()">
                                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                                <mat-label>Delete</mat-label>
                            </button>
                        </mat-option>


                    </mat-select>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        </table>
        
    </mat-card-content>
</mat-card>