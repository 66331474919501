import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-door',
  templateUrl: './update-door.component.html',
  styleUrls: ['./update-door.component.css'],
})
export class UpdateDoorComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdateDoorComponent>
  ) {}

  form!: FormGroup;
  ngOnInit(): void {
    this.form = this.fb.group({
      BldgNumb: [null],
      DoorNumb: [null],
      DoorUse: [null],
      Stat: [null],
      SpecialAttribute: [null],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('update');
  }
}
