<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Consolidate Ledger Code References</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Ledger Code </mat-label>
            <input matInput formControlName="LELEGC" />
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Cost Center </mat-label>

            <input matInput formControlName="LECC" maxlength="1" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Consolidate all references to </mat-label>

            <input matInput formControlName="LEGC" />
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
