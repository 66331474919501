<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
        Customer Rating Preferences</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>Customer Number:</mat-label>
                      <input matInput formControlName="customerNumber"/>
                      </mat-form-field> 
                     </div>
                   
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="customerNumber">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Customer Number
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.customerNumber }}</td>
        </ng-container>
  
        <ng-container matColumnDef="sT">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            ST
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.sT }}</td>
        </ng-container>
  
        <ng-container matColumnDef="tariffName">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                TariffName
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.tariffName }}
                
            </td>
          </ng-container>
          
        <ng-container matColumnDef="sCACCode">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                SCACCode
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.sCACCode }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="disc">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Disc
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.disc }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="interstateMinimum">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Interstate Minimum
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.interstateMinimum }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="perMile">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Per Mile
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.perMile }}
                
            </td>
          </ng-container>
          
          <ng-container matColumnDef="flatRate">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Flat Rate
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.flatRate }}
                
            </td>
          </ng-container>

          
          <ng-container matColumnDef="ST">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                ST
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.ST}}
                
            </td>
          </ng-container>

        

  
        <ng-container matColumnDef="opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            Opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
                  
                     <mat-option>
                  <button mat-icon-button (click)="PreferencesChangeForm(element)">
                    <mat-icon mat-icon-button color="primary">edit</mat-icon>
                    <mat-label>Edit</mat-label>
                  </button>
                </mat-option>  
              
                  <mat-option>
                    <button mat-icon-button (click)="PreferencesAddForm(element)">
                      <mat-icon mat-icon-button color="primary">add</mat-icon>
                      <mat-label>Add</mat-label>
                    </button>
                  </mat-option>    
                
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
      </table>

