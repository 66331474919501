<mat-card class="over-Flow">
    <mat-card-header>
      <mat-card-title class="title">Work With Extract Base Feilds</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="buttons">
        <button (click)="extractReportCreate()"
          mat-button
          
          class="addButton buttons"
        >
          <mat-icon>add</mat-icon>
          <span>New Extract base Field</span>
        </button>
      </div>
  
      <form [formGroup]="form" class="form over-Flow">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline"
              ><mat-label> Report Name </mat-label>
              <input matInput formControlName="reportName" />
            </mat-form-field>
         
            <mat-form-field appearance="outline"
              ><mat-label> Field Name</mat-label>
              <input matInput formControlName="fieldName" />
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              class="buttons addButton"
              mat-raised-button
              type="submit"
              placeholder="Search"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  
    <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
      <ng-container matColumnDef="extractName">
        <th mat-header-cell *matHeaderCellDef>Extract Name</th>
        <td mat-cell *matCellDef="let element">{{ element.extractName }}</td>
      </ng-container>
  
      <ng-container matColumnDef="sequence">
        <th mat-header-cell *matHeaderCellDef>Sequence</th>
        <td mat-cell *matCellDef="let element">{{ element.sequence }}</td>
      </ng-container>

      <ng-container matColumnDef="fieldName">
        <th mat-header-cell *matHeaderCellDef>Field Name</th>
        <td mat-cell *matCellDef="let element">{{ element.fieldName }}</td>
      </ng-container>

      <ng-container matColumnDef="filterName">
        <th mat-header-cell *matHeaderCellDef>Filter Name</th>
        <td mat-cell *matCellDef="let element">{{ element.filterName }}</td>
      </ng-container>

      <ng-container matColumnDef="columnHeading">
        <th mat-header-cell *matHeaderCellDef>Column Heading</th>
        <td mat-cell *matCellDef="let element">{{ element.columnHeading }}</td>
      </ng-container>
  
      <ng-container matColumnDef="Actions">
        <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-select class="mat-select" placeholder="Select Option">
            <mat-option>
              <button mat-icon-button (click)="extractReportUpdate(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button></mat-option
            >
  
            <mat-option>
              <button mat-icon-button (click)="delete()">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button></mat-option
            >
          </mat-select>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[4, 8, 16]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card>