import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';


@Component({
  selector: 'app-ww-locater2s-adjust-new-itm',
  templateUrl: './ww-locater2s-adjust-new-itm.component.html',
  styleUrls: ['./ww-locater2s-adjust-new-itm.component.css']
})
export class WwLocater2sAdjustNewItmComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<WwLocater2sAdjustNewItmComponent>
   ) 
   { }
  
  ngOnInit(): void {
    this.form = this.fb.group({
      STOR:'5002 PERFECT POP POP CORN',
      ITEM:'244 WHITE POLYBAGS',
      LOCAT:"1 A/1",
      LOTS:'20200209',
      ADQY:'',
      ITYPF:'',
      ITYPT:'',
      AJREAS:'',
      AJWRBL:'',
      AJBLWR:'0',
      AJBWSQ:'0',
      LCQAVL:'90',
      LCQCOM:'0',
      LCQDAM:'0',
      LCQHLD:'0',      
      LCQUS1:'0',
      LCQUS2:'0',
      LCQUS3:'0',
      LCQUS4:'0',
      ITEMT:'',
      LTC1:'',
      LTC2:'',
      LTC3:'',
      FLORT:'',
      AISLT:'',
      SLOTT:'',
      SRSQT:'',
       
    });
  }
  
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
  
  
  backstorer(){
    
  }
  attributes(){
    
  }
  }

