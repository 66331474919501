import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
import { OrderService } from '../order.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';
@Component({
  selector: 'app-order-delete',
  templateUrl: './order-delete.component.html',
  styleUrls: ['./order-delete.component.css'],
})
export class OrderDeleteComponent implements OnInit {
  
  constructor(
    public iOrderSvc: OrderService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog,
    private CustomAlertService: CustomAlertService,

    private dialogRef: MatDialogRef<OrderDeleteComponent>
  ) {}

  storer: any;
  form!: FormGroup;
  result: any;

  ngOnInit(): void {
    this.form = this.fb.group({
      Billoflanding: this.anyvariable.OHBILL,
      Storer: this.anyvariable.OHSTOR,
      Consignee: this.anyvariable.OHCONS,
      ScheduledShipDate: this.anyvariable.OHSCHS,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deleteOrder() {
    var res = this.form.value;
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure you want to delete Order ');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      this.result = this.iOrderSvc
        .deleteOrder(res.Billoflanding)
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);
          var value = this.result[0].data[2].value;

          if (value == 'Deleted Successfully') {
            this.successMessage(value);
            this.closeDialog();
            this.router.navigate(['/main-nav/orders/read']);
          } else {
            this.successMessage(value);
            this.closeDialog();
            this.router.navigate(['/main-nav/orders/read']);
          }
        });
    }
  });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
   
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

}
