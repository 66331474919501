import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-schedule-exception-add',
  templateUrl: './schedule-exception-add.component.html',
  styleUrls: ['./schedule-exception-add.component.css']
})
export class ScheduleExceptionAddComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ScheduleExceptionAddComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      HETYPE:'',
      HEBLDG:'',
      HEACCT:'',
      DATE:'',
      INOU:'',
      HEINOM:'',
      HESTRT:'',
      HEENDT:'',
      HEDESC:''
    })
  }

  add(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
