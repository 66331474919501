import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-reason-codes-element',
  templateUrl: './reason-codes-element.component.html',
  styleUrls: ['./reason-codes-element.component.css'],
})
export class ReasonCodesElementComponent implements OnInit {
  displayedColumns: string[] = ['ElementCode', 'ReasonCode', 'Description'];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      ElementCode: 127,
      ReasonCode: 'A1',
      Description: 'ADJUST AVAIABLE',
    },
    {
      ElementCode: 127,
      ReasonCode: 'A2',
      Description: 'ADJUST DAMAGE',
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private dialogRef: MatDialogRef<ReasonCodesElementComponent>,

    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      DataElementCode: [null],
      ReasonCode: [null],
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  @ViewChild('paginator') paginator!: MatPaginator;
  workHeaders: any = [];

  reasonCode() {
    console.log('Reason Code');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
