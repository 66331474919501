<!-- Form No : 283 -->
<mat-card>
    <mat-card-header>  
      <mat-card-title >Work With Receivables</mat-card-title>
    </mat-card-header>

    <mat-card-content>
       <div class="formDiv">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Invoice</mat-label>
                    <input matInput formControlName="Invoice"/>
                  </mat-form-field>
          
                  <mat-form-field appearance="outline">
                    <mat-label>Customer</mat-label>
                    <input matInput formControlName="Customer"/>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Dates</mat-label>
                    <input matInput formControlName="Dates"/>
                  </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Ref No</mat-label>
                    <input matInput formControlName="RefNo"/>
                  </mat-form-field>
          
                  <mat-form-field appearance="outline">
                    <mat-label>Building</mat-label>
                    <input matInput formControlName="Building"/>
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Status</mat-label>
                    <input matInput formControlName="Status"/>
                  </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Type</mat-label>
                    <input matInput formControlName="Type"/>
                  </mat-form-field>
          
                 
            </div>
            
          </div>     
        </form>
       </div>


       <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="STS">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            STS
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.STS }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Invoice Number">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Invoice Number
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.InvoiceNumber }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Invoice Date">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Invoice Date
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.InvoiceDate }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Tc">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Tc
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Tc }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Tr Tp">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Tr Tp
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.TrTp }}</td>
        </ng-container>

        <ng-container matColumnDef="Bld No">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Bld No
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.BldNo }}</td>
        </ng-container>

        <ng-container matColumnDef="Customer">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Customer
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Customer }}</td>
        </ng-container>

        <ng-container matColumnDef="Invoice Amount">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Invoice Amount
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.InvoiceAmount }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Action">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              <mat-option>
                <button mat-icon-button  (click)="receivableAdjustInvoice(element)">           
                  <mat-icon mat-icon-button color="primary">edit</mat-icon>
                  <mat-label>Adjust Invoice</mat-label>
                </button>
              </mat-option>

              <mat-option>
                <button mat-icon-button  (click)="receivableCreditInvoice(element)">           
                  <mat-icon mat-icon-button color="primary">edit</mat-icon>
                  <mat-label>Credit Invoice</mat-label>
                </button>
              </mat-option>

              <mat-option>
                <button mat-icon-button  (click)="receivableInquiry(element)">           
                  <mat-icon mat-icon-button color="primary">edit</mat-icon>
                  <mat-label>Inquiry</mat-label>
                </button>
              </mat-option>
              
              <mat-option>
                <button mat-icon-button  (click)="receivableAdjustInquiry(element)">           
                  <mat-icon mat-icon-button color="primary">edit</mat-icon>
                  <mat-label>Adjust-Inquiry</mat-label>
                </button>
              </mat-option>
              
              <mat-option>
                <button mat-icon-button  (click)="receivableComments(element)">           
                  <mat-icon mat-icon-button color="primary">comment</mat-icon>
                  <mat-label>Comments</mat-label>
                </button>
              </mat-option>

              <mat-option>
                <button mat-icon-button  (click)="receivableDetails(element)">           
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label>Receivalble Details</mat-label>
                </button>
              </mat-option>

              <mat-option>
                <button mat-icon-button  (click)=" receivablePrint(element)">           
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label>Receivalble Print</mat-label>
                </button>
              </mat-option>
            
            </mat-select>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
        </table>
    </mat-card-content>
</mat-card>
