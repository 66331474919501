<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon></button
><mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Invoice Adjustment Inquiry </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Invoice</mat-label>
            <input matInput formControlName="Invoice" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Customer</mat-label>
            <input matInput formControlName="Customer" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Date</mat-label>
            <input matInput formControlName="InvDateoice" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Bill-To</mat-label>
            <input matInput formControlName="BillTo" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Amount</mat-label>
            <input matInput formControlName="Amount" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Type</mat-label>
            <input matInput formControlName="Type" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="SeqNo">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Seq No.
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTOR }}
      </td>
    </ng-container>
    <ng-container matColumnDef="AdjustDate">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Adjust Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHACTA }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Ledger">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Ledger
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTRF }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Description
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSCHT }}
      </td>
      <td *matFooterCellDef>Adjusted Value</td>
    </ng-container>
    <ng-container matColumnDef="AdjustmentAmount">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Adjustment Amount
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHTQTY }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
            #paginator
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
