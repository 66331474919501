import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { NgModel } from '@angular/forms'; 
 // selector: 'app-alert-dialog'

  @Component({
    selector: 'app-alert-dialog',
    templateUrl: 'custom-alert.component.html',
    styleUrls: ['custom-alert.component.css']
  })
  export class CustomAlertComponent {
   
    constructor(
      public dialogRef: MatDialogRef<CustomAlertComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
    ngOnInit() {
    }
  
    closeDialog() {
      this.dialogRef.close(false);
    }
   
  }