<mat-card class="over-Flow">
    
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title class="title">Work With Entity Attributes</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> File ID </mat-label>
            <input matInput formControlName="FileID" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Key </mat-label>
            <input matInput formControlName="Key" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="Attribute">
      <th mat-header-cell *matHeaderCellDef>Attribute</th>
      <td mat-cell *matCellDef="let element">{{ element.Attribute }}</td>
    </ng-container>

    <ng-container matColumnDef="Value">
      <th mat-header-cell *matHeaderCellDef width="60px">Value</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Value }}
      </td>
    </ng-container>

    <ng-container matColumnDef="LastChanged">
      <th mat-header-cell *matHeaderCellDef>Last Changed</th>
      <td mat-cell *matCellDef="let element">{{ element.LastChanged }}</td>
    </ng-container>

    <ng-container matColumnDef="User">
      <th mat-header-cell *matHeaderCellDef>User</th>
      <td mat-cell *matCellDef="let element">{{ element.User }}</td>
    </ng-container>

    <ng-container matColumnDef="St">
      <th mat-header-cell *matHeaderCellDef>St</th>
      <td mat-cell *matCellDef="let element">{{ element.St }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
