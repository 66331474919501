<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<mat-card>
  <mat-card-header>
    <mat-card-title>Delete a Door</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Building Number </mat-label>
            <input matInput formControlName="BldgNumb" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Door Number </mat-label>

            <input matInput formControlName="DoorNumb" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Door Usage </mat-label>

            <input matInput formControlName="DoorUse" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Handling Codes </mat-label>

            <input matInput formControlName="HandlingCodes" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Status Code </mat-label>

            <input matInput formControlName="Stat" />
          </mat-form-field>
        </div>
      </div>
    </form>

    <div>
      <p>Press Enter to confirm Delete</p>
      <button mat-button (click)="delete()" class="deleteButton">Delete</button>
      <br />
    </div>
  </mat-card-content>
</mat-card>
