import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../iheader.service';
import { CustomAlertService } from '../../custom-alert/custom-alert.service';

@Component({
  selector: 'app-tally-detail-delete',
  templateUrl: './tally-detail-delete.component.html',
  styleUrls: ['./tally-detail-delete.component.css']
})
export class TallyDetailDeleteComponent implements OnInit {

 // tally : any;
  form!: FormGroup;
  sequence=100;
  result:any;
  user:any;
  building:any;
  

  constructor( public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<TallyDetailDeleteComponent>,
    private CustomAlertService : CustomAlertService
    ) { }

  ngOnInit(): void {
    if(localStorage.getItem('LoggedIn')){
      this.user = localStorage.getItem('userName')
      this.building = localStorage.getItem('building')
  }else{
    this.user= GlobalComponent.user;
    this.building=GlobalComponent.building;
  }
   // this.tally=this.anyvariable;
    this.form = this.fb.group({
      tally: this.anyvariable.IDTALY,
      linenumber: this.anyvariable.IDSEQ,
      storer:this.anyvariable.IDSTOR,
      item:this.anyvariable.IDITEM,
      lots:this.anyvariable.IDLOT,
      received:this.anyvariable.IDQREC,
      intransit:this.anyvariable.IDQINT
    });
  }

  deletetallyDetail(){
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete Tly dtls for Tally No: ? '+this.anyvariable.IDTALY);
  
    dialogRef.afterClosed().subscribe((result: any) => {
    if (result)
    //if(confirm('Are you sure want to delete Tally No: ? '+this.anyvariable.IDTALY))
    {

      this.result = this.iHeaderSvc
      .deleteTallyDetail(this.anyvariable.IDTALY, this.user,this.anyvariable.IDSEQ)
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);

        if (JSON.parse(this.result[0].data[1].value).success) {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.successMessage(msg);
          this.closeDialog();
        
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.failureMessage(msg);
        
        }
      });
    }
    });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
   this.closeDialog();
    this.router.navigate(['/main-nav/tallys/read']);
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
