import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LoginComponent } from '../login/login.component';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AccountSelectionUpdateComponent } from '../features/account-selection/account-selection-update/account-selection-update.component';

@Component({
  selector: 'app-main-nav',
  templateUrl: './main-nav.component.html',
  styleUrls: ['./main-nav.component.css'],
})
export class MainNavComponent implements OnInit {
  isLoggedIn: boolean = LoginComponent.isLoggedIn;
  isLoggedOut: boolean = LoginComponent.isLoggedOut;
  loggedUser: any;
  userName: any;
  menuStatus:any;

  //console.log(this.isLoggedIn);
  
  opened=false;
  togglesidebar(){
    this.opened=!this.opened;
  };



  
  

  

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay()
    );

    mySubscription:any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.mySubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
  // Trick the Router into believing it's last link wasn't previously loaded
      this.router.navigated = false;
      }
    }); 

    // detect screen size changes
    this.breakpointObserver.observe([
      "(max-width: 768px)"
    ]).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.router.events.subscribe(event => {
          this.opened=false;
        })   
      } else {
        this.opened=true;   
      }
    });
  }

  name:any;
  ngOnInit() {
    //this.router.navigate([this.router.url]);
    this.loggedUser = localStorage.getItem('LoggedIn');
    this.userName = localStorage.getItem('userName');
    this.name = localStorage.getItem('name');
    this.menuStatus=localStorage.getItem('menuStatus');
    console.log('asdassdfsdgfgd', localStorage.getItem('LoggedIn'));
   
    //    this.router.navigate(['/tallys/read']);
    console.log(this.menuStatus);
  }
  Logout() {
    localStorage.removeItem('LoggedIn');
    localStorage.removeItem('userName');
    localStorage.removeItem('building');
    localStorage.removeItem('storer');
    localStorage.removeItem('storerSuffix');
    localStorage.removeItem('menuStatus');
    this.router.navigateByUrl('/');
  }

  accountSelectionUpdate(userName: any) {
    // this.router.navigate(['tallys/posting',tally]);
    this.matDialog.open(AccountSelectionUpdateComponent, {
      width: '500px',
      maxHeight: '80vh',
      data: userName,
      autoFocus: false,
    });
  }

  isExpanded = true;
  showForecastsSubmenu: boolean = false;
  showEmailExitPointsSubmenu: boolean = false;
  showCustomersSubmenu: boolean = false;
  showreceviceSubmenu: boolean = false;
  showorderSubmenu: boolean = false;
  showtallysSubmenu: boolean = false;
  showshippingSubmenu: boolean = false;
  showstorerSubmenu: boolean = false;
  showBuildingSubmenu: boolean = false;
  showMapZoneSubmenu: boolean = false;
  showMapLocationSubMenu: boolean = false;
  showCarrierSubmenu: boolean = false;
  showItemSubmenu: boolean = false;
  showConsigneeSubmenu: boolean = false;
  showExitPointSubmenu: boolean = false;
  showMoveQueueSubmenu: boolean = false;
  showPostalAbbreviations: boolean = false;
  showReceivables: boolean = false;
  showWorkWithActionsRsms: boolean = false;
  showWorkWithApplication: boolean = false;
  showControllBatchFile: boolean = false;
  showAuditHeaders: boolean = false;
  showBoxContent: boolean = false;
  showAccountProcessing: boolean = false;
  showActivityDescription: boolean = false;
  showBalanceErrors: boolean = false;
  showInventoryBooks: boolean = false;
  showAddressTypes: boolean = false;
  showInventoryTagSubmenu: boolean = false;
  showOrderStagingBatches: boolean = false;
  showDockSchedules: boolean = false;
  showLtlBatches: boolean = false;
  showBookDetails: boolean = false;
  showWorkWithBoxes: boolean = false;
  showMasterSerialList: boolean = false;
  showHazardousChemicals: boolean = false;
  showSystemComments: boolean = false;
  showSerializedInventories: boolean = false;
  showCarrierEdiDocuments: boolean = false;
  showWorkWithContents: boolean = false;
  showCommodityPrinters: boolean = false;
  showFiles: boolean = false;
  showcampusBuildings: boolean = false;
  showCommentSubcodes: boolean = false;
  showCustomerAddresses: boolean = false;
  showProductCodeDescription = false;
  showCampuses = false;
  showConsigneeBasedTriggers = false;
  showMetricConversions = false;
  showExpectedDurations = false;
  showContactTypes = false;
  showCustomerRatingPreferences = false;
  showItemAttributes: boolean = false;
  showItemForecastDetails: boolean = false;
  showStorerAttributes: boolean = false;
  showLocater: boolean = false;
  showMaintQuestions: boolean = false;
  showLotStatusSubmenu: boolean = false;
  showLaborProcessingSubmenu: boolean = false;
  showMetricsConstraints: boolean = false;
  showMetricsDefinations: boolean = false;
  showMessageClassShred: boolean = false;
  showInboundDetails: boolean = false;
  showExtractReportWhereSubmenu : boolean = false;
  showHazardmaterialsGroupSubmenu : boolean = false;
  showLaborsPlanSubmenu : boolean = false;
  showERSSubmenu : boolean = false;
  showEmailFormatSubmenu: boolean = false;
  showEmailSubmenu = false;
  showOrderEvents: boolean = false;
  showIncomingEvents: boolean = false;
  showValidationEvents: boolean = false;
  emailDisplay = 'none';
  emailDirection = '';
  
  
  isShowing = false;
  forecastsdisplay = 'none';
  forecastsdirection = '';
  emailExitPointsdisplay = 'none';
  emailExitPointsdirection = '';
  customersdisplay = 'none';
  customersdirection = '';
  receviedisplay = 'none';
  receviedirection = '';
  tallysdisplay = 'none';
  tallysdirection = '';
  carrierdisplay = 'none';
  carrierdirection = '';
  ordersdisplay = 'none';
  ordersdirection = '';
  shippingsdisplay = 'none';
  shippingsdirection = '';
  mapZonedisplay = 'none';
  mapZonedirection = '';
  storerdisplay = 'none';
  storerdirection = '';
  buildingdisplay = 'none';
  buildingdirection = '';
  mapLocationDispaly = 'none';
  mapLocationDirection = '';
  itemdisplay = 'none';
  itemdirection = '';
  consigneeDisplay = 'none';
  consigneeDirection = '';
  exitPointDisplay = 'none';
  exitPointDirection = '';
  moveQueueDisplay = 'none';
  moveQueueDirection = '';
  postalAbbreviationsDisplay = 'none';
  postalAbbreviationsDirection = '';
  receivablesDisplay = 'none';
  receivablesDirection = '';
  actionRsmsDisplay = 'none';
  actionRsmsDirection = '';
  applicationDisplay = 'none';
  applicationDirection = '';
  controllBatchFileDisplay = 'none';
  controllBatchFileDirection = '';
  auditHeaderDisplay = 'none';
  auditHeaderDirection = '';
  boxContentDisplay = 'none';
  boxContentDirection = '';
  AccountProcessingDisplay = 'none';
  AccountProcessingDirection = '';
  ActivityDescriptionDisplay = 'none';
  ActivityDescriptionDirection = '';
  balanceErrorDisplay = 'none';
  balanceErrorDirection = '';
  inventoryBooksDisplay = 'none';
  inventoryBooksDirection = '';
  addressTypeDisplay = 'none';
  addressTypeDirection = '';
  inventoryTagDisplay = 'none';
  inventoryTagDirection = '';
  orderStagingBatchesDisplay = 'none';
  orderStagingBatchesDirection = '';
  dockScheduleDisplay = 'none';
  dockScheduleDirection = '';
  ltlBatchesDisplay = 'none';
  ltlBatchesDirection = '';
  bookDetailsDisplay = 'none';
  bookDetailsDirection = '';
  boxDisplay = 'none';
  boxDirection = '';
  masterSerialListDisplay = 'none';
  masterSerialListDirection = '';
  hazardousChemicalsDisplay = 'none';
  hazardousChemicalsDirection = '';
  systemCommentsDisplay = 'none';
  systemCommentsDirection = '';
  serializedInventoriesDisplay = 'none';
  serializedInventoriesDirection = '';
  carrierEdiDocumentDisplay = 'none';
  carrierEdiDocumentDirection = '';
  contentsDisplay = 'none';
  contentsDirection = '';
  commodityDisplay = 'none';
  commodityDirection = '';
  filesDisplay = 'none';
  filesDirection = '';
  campusBuildingsDisplay = 'none';
  campusBuildingsDirection = '';
  commentSubcodeDisplay = 'none';
  commentSubcodeDirection = '';
  customerAddressesDisplay = 'none';
  customerAddressesDirection = '';
  productCodeDescriptionDisplay = 'none';
  productCodeDescriptionDirection = '';
  campusesDisplay = 'none';
  campusesDirection = '';
  consigneeBasedTriggerDisplay = 'none';
  consigneeBasedTriggerDirection = '';
  metricConversionsDisplay = 'none';
  metricConversionsDirection = '';
  expectedDurationsDisplay = 'none';
  expectedDurationsDirection = '';
  contactTypesDisplay = 'none';
  contactTypeDirection = '';
  customerRatingPreferenceDisplay = 'none';
  customerRatingPreferenceDirection = '';
  itemAttributesDisplay = 'none';
  itemAttributesDirection = '';
  itemForecastDetailsDisplay = 'none';
  itemForecastDetailsDirection = '';
  storerAttributesDisplay = 'none';
  storerAttributesDirection = '';
  locaterDisplay = 'none';
  locaterDirection = '';
  MaintQuestionsDirection = '';
  MaintQuestionsDisplay = 'none';
  MetricsConstraintsDirection = '';
  MetricsConstraintsDisplay = 'none';
  MetricsDefinationsDirection = '';
  MetricsDefinationsDisplay = 'none';
  MessageClassShredDirection = '';
  MessageClassShredDisplay = 'none';
  InboundDetailsDisplay = 'none';
  InboundDetailsDirection = '';
  OrderEventsDisplay = 'none';
  OrderEventsDirection = '';
  IncomingEventsDisplay = 'none';
  IncomingEventsDirection = '';
  ValidationEventsDisplay = 'none';
  ValidationEventsDirection = '';

  LotStatusdisplay = 'none';
  LotStatusdirection = '';
  LaborProcessingdisplay = 'none';
  LaborProcessingdirection = '';
  ExtractReportWheredisplay= 'none';
  ExtractReportWheredirection= '';
  HazardmaterialsGroupdisplay= 'none';
  HazardmaterialsGroupdirection= '';
  LaborPlandisplay= 'none';
  LaborPlandirection= '';
  ERSdisplay= 'none';
  ERSdirection= '';
  EmailFormatdisplay = 'none';
  EmailFormatdirection = '';
  showreceviceSubmenushowhide() {
    this.showreceviceSubmenu = !this.showreceviceSubmenu;
    this.showCustomersSubmenu = false;
    this.showorderSubmenu = false;
    this.showtallysSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.storerdisplay = 'none';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.storerdirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.ordersdisplay = 'none';
    this.ordersdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showreceviceSubmenu == true) {
      this.receviedisplay = '';
      this.receviedirection = '';
    } else {
      this.receviedisplay = 'none';
      this.receviedirection = '';
    }
  }

  showtallySubmenushowhide() {
    this.showreceviceSubmenu = false;
    this.showCustomersSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showtallysSubmenu = !this.showtallysSubmenu;
    if (this.showtallysSubmenu == true) {
      this.tallysdisplay = '';
      this.tallysdirection = '';
    } else {
      this.tallysdisplay = 'none';
      this.tallysdirection = '';
    }
  }
  showItemAttributesSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showItemAttributes = !this.showItemAttributes;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showItemAttributes == true) {
      this.itemAttributesDisplay = '';
      this.itemAttributesDirection = '';
    } else {
      this.itemAttributesDisplay = 'none';
      this.itemAttributesDirection = '';
    }
  }
  showItemForecastDetailsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showItemForecastDetails = !this.showItemForecastDetails;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showItemForecastDetails == true) {
      this.itemForecastDetailsDisplay = '';
      this.itemForecastDetailsDirection = '';
    } else {
      this.itemForecastDetailsDisplay = 'none';
      this.itemForecastDetailsDirection = '';
    }
  }

  showInboundDetailsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showInboundDetails = !this.showInboundDetails;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showInboundDetails == true) {
      this.InboundDetailsDisplay = '';
      this.InboundDetailsDirection = '';
    } else {
      this.InboundDetailsDisplay = 'none';
      this.InboundDetailsDirection = '';
    }
  }

  showStorerAttributesSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showStorerAttributes = !this.showStorerAttributes;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showStorerAttributes == true) {
      this.storerAttributesDisplay = '';
      this.storerAttributesDirection = '';
    } else {
      this.storerAttributesDisplay = 'none';
      this.storerAttributesDirection = '';
    }
  }

  showOrderEventsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showOrderEvents = !this.showOrderEvents;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showOrderEvents == true) {
      this.OrderEventsDisplay = '';
      this.OrderEventsDirection = '';
    } else {
      this.OrderEventsDisplay = 'none';
      this.OrderEventsDirection = '';
    }
  }
  showIncomingEventsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showOrderEvents = !this.showOrderEvents;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showIncomingEvents == true) {
      this.IncomingEventsDisplay = '';
      this.IncomingEventsDirection = '';
    } else {
      this.IncomingEventsDisplay = 'none';
      this.IncomingEventsDirection = '';
    }
  }
  showValidationEventsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showOrderEvents = !this.showOrderEvents;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showValidationEvents == true) {
      this.ValidationEventsDisplay = '';
      this.ValidationEventsDirection = '';
    } else {
      this.ValidationEventsDisplay = 'none';
      this.ValidationEventsDirection = '';
    }
  }
  showMaintQuestionsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showMaintQuestions = !this.showMaintQuestions;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showMaintQuestions == true) {
      this.MaintQuestionsDisplay = '';
      this.MaintQuestionsDirection = '';
    } else {
      this.MaintQuestionsDisplay = 'none';
      this.MaintQuestionsDirection = '';
    }
  }
  showMetricsConstraintsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showMetricsConstraints = !this.showMetricsConstraints;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showMetricsConstraints == true) {
      this.MetricsConstraintsDisplay = '';
      this.MetricsConstraintsDirection = '';
    } else {
      this.MetricsConstraintsDisplay = 'none';
      this.MetricsConstraintsDirection = '';
    }
  }
  showMetricsDefinationsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showMetricsDefinations = !this.showMetricsDefinations;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showMetricsDefinations == true) {
      this.MetricsDefinationsDisplay = '';
      this.MetricsDefinationsDirection = '';
    } else {
      this.MetricsDefinationsDisplay = 'none';
      this.MetricsDefinationsDirection = '';
    }
  }
  showMessageClassShredSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showMessageClassShred = !this.showMessageClassShred;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showMessageClassShred == true) {
      this.MessageClassShredDisplay = '';
      this.MessageClassShredDirection = '';
    } else {
      this.MessageClassShredDisplay = 'none';
      this.MessageClassShredDirection = '';
    }
  }
  showLocaterSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showLocater = !this.showLocater;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = 'none';
    this.bookDetailsDisplay = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showLocater == true) {
      this.locaterDisplay = '';
      this.locaterDirection = '';
    } else {
      this.locaterDisplay = 'none';
      this.locaterDirection = '';
    }
  }

  showordersSubmenushowhide() {
    this.showreceviceSubmenu = false;
    this.showCustomersSubmenu = false;
    this.showtallysSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showorderSubmenu = !this.showorderSubmenu;
    if (this.showorderSubmenu == true) {
      this.ordersdisplay = '';
      this.ordersdirection = '';
    } else {
      this.ordersdisplay = 'none';
      this.ordersdirection = '';
    }
  }

  showEmailExitPointsSubmenushowhide() {
    this.showreceviceSubmenu = false;
    this.showCustomersSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = !this.showEmailExitPointsSubmenu;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showtallysSubmenu = !this.showtallysSubmenu;
    if (this.showEmailExitPointsSubmenu == true) {
      this.emailExitPointsdisplay = '';
      this.emailExitPointsdirection = '';
    } else {
      this.emailExitPointsdisplay = 'none';
      this.emailExitPointsdirection = '';
    }
  }
  showForecastsSubmenushowhide() {
    this.showForecastsSubmenu = !this.showForecastsSubmenu;
    this.showreceviceSubmenu = false;
    this.showCustomersSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.forecastsdisplay = 'none';
    this.forecastsdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showtallysSubmenu = !this.showtallysSubmenu;
    if (this.showForecastsSubmenu == true) {
      this.forecastsdisplay = '';
      this.forecastsdirection = '';
    } else {
      this.forecastsdisplay = 'none';
      this.forecastsdirection = '';
    }
  }

  showCustomersSubmenushowhide() {
    this.showreceviceSubmenu = false;
    this.showCustomersSubmenu = !this.showCustomersSubmenu;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showtallysSubmenu = !this.showtallysSubmenu;
    if (this.showCustomersSubmenu == true) {
      this.customersdisplay = '';
      this.customersdirection = '';
    } else {
      this.customersdisplay = 'none';
      this.customersdirection = '';
    }
  }

  showshippingsSubmenushowhide() {
    this.showCustomersSubmenu = false;
    this.showorderSubmenu = false;
    this.showtallysSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.ordersdisplay = 'none';
    this.ordersdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showshippingSubmenu = !this.showshippingSubmenu;
    if (this.showshippingSubmenu == true) {
      this.shippingsdisplay = '';
      this.shippingsdirection = '';
    } else {
      this.shippingsdisplay = 'none';
      this.shippingsdirection = '';
    }
  }

  showstorerSubmenushowhide() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showtallysSubmenu = false;
    this.showshippingSubmenu = false;
    this.showorderSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.ordersdisplay = 'none';
    this.ordersdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showstorerSubmenu = !this.showstorerSubmenu;
    if (this.showstorerSubmenu == true) {
      this.storerdisplay = '';
      this.storerdirection = '';
    } else {
      this.storerdisplay = 'none';
      this.storerdirection = '';
    }
  }

  showBuildingSubmenushowhide() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showtallysSubmenu = false;
    this.showshippingSubmenu = false;
    this.showorderSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.ordersdisplay = 'none';
    this.ordersdirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');

    this.showBuildingSubmenu = !this.showBuildingSubmenu;
    if (this.showBuildingSubmenu == true) {
      this.buildingdisplay = '';
      this.buildingdirection = '';
    } else {
      this.buildingdisplay = 'none';
      this.buildingdirection = '';
    }
  }

  showMapZoneSubmenushowhide() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showtallysSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showMapZoneSubmenu = !this.showMapZoneSubmenu;
    if (this.showMapZoneSubmenu == true) {
      this.mapZonedisplay = '';
      this.mapZonedirection = '';
    } else {
      this.mapZonedisplay = 'none';
      this.mapZonedirection = '';
    }
  }

  showMapLocationMenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    this.showMapLocationSubMenu = !this.showMapLocationSubMenu;
    if (this.showMapLocationSubMenu == true) {
      this.mapLocationDispaly = '';
      this.mapLocationDirection = '';
    } else {
      this.mapLocationDispaly = 'none';
      this.mapLocationDirection = '';
    }
  }

  showCarrierSubmenushowhide() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showCarrierSubmenu = !this.showCarrierSubmenu;
    if (this.showCarrierSubmenu == true) {
      this.carrierdisplay = '';
      this.carrierdirection = '';
    } else {
      this.carrierdisplay = 'none';
      this.carrierdirection = '';
    }
  }

  showConsigneeSubmenuShowhide() {
    this.showCustomersSubmenu = false;
    this.showConsigneeSubmenu = !this.showConsigneeSubmenu;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showtallysSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.storerdisplay = 'none';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.storerdirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.ordersdisplay = 'none';
    this.ordersdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showConsigneeSubmenu == true) {
      this.consigneeDisplay = '';
      this.consigneeDirection = '';
    } else {
      this.consigneeDisplay = 'none';
      this.consigneeDirection = '';
    }
  }

  showExitPointSubMenuShowhide() {
    this.showCustomersSubmenu = false;
    this.showConsigneeSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showtallysSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showExitPointSubmenu = !this.showExitPointSubmenu;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.storerdisplay = 'none';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.storerdirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.ordersdisplay = 'none';
    this.ordersdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showExitPointSubmenu == true) {
      this.exitPointDisplay = '';
      this.exitPointDirection = '';
    } else {
      this.exitPointDisplay = 'none';
      this.exitPointDirection = '';
    }
  }

  showItemSubmenushowhide() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showItemSubmenu = !this.showItemSubmenu;
    if (this.showItemSubmenu == true) {
      this.itemdisplay = '';
      this.itemdirection = '';
    } else {
      this.itemdisplay = 'none';
      this.itemdirection = '';
    }
  }

  showMoveQueueSubmenuShowhide() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showMoveQueueSubmenu = !this.showMoveQueueSubmenu;
    if (this.showMoveQueueSubmenu == true) {
      this.moveQueueDisplay = '';
      this.moveQueueDirection = '';
    } else {
      this.moveQueueDisplay = 'none';
      this.moveQueueDirection = '';
    }
  }

  showPostalAbbreviationSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showPostalAbbreviations = !this.showPostalAbbreviations;
    if (this.showPostalAbbreviations == true) {
      this.postalAbbreviationsDisplay = '';
      this.postalAbbreviationsDirection = '';
    } else {
      this.postalAbbreviationsDisplay = 'none';
      this.postalAbbreviationsDirection = '';
    }
  }

  showReceivablesSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showReceivables = !this.showReceivables;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showReceivables == true) {
      this.receivablesDisplay = '';
      this.receivablesDirection = '';
    } else {
      this.receivablesDisplay = 'none';
      this.receivablesDirection = '';
    }
  }

  showActionRsmsSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showReceivables = false;
    this.showWorkWithActionsRsms = !this.showWorkWithActionsRsms;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showWorkWithActionsRsms == true) {
      this.actionRsmsDisplay = '';
      this.actionRsmsDirection = '';
    } else {
      this.actionRsmsDisplay = 'none';
      this.actionRsmsDirection = '';
    }
  }

  showApplicationSubMenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showReceivables = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = !this.showWorkWithApplication;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showWorkWithApplication == true) {
      this.applicationDisplay = '';
      this.applicationDirection = '';
    } else {
      this.applicationDisplay = 'none';
      this.applicationDirection = '';
    }
  }

  showControllBatchFileSubMenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showReceivables = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = !this.showControllBatchFile;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showControllBatchFile == true) {
      this.controllBatchFileDirection = '';
      this.controllBatchFileDisplay = '';
    } else {
      this.controllBatchFileDisplay = 'none';
      this.controllBatchFileDirection = '';
    }
  }

  showAuditHeaderSubMenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showReceivables = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = !this.showAuditHeaders;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showAuditHeaders == true) {
      this.auditHeaderDisplay = '';
      this.auditHeaderDirection = '';
    } else {
      this.auditHeaderDisplay = 'none';
      this.auditHeaderDirection = '';
    }
  }

  showBoxContentSubMenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showReceivables = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showBoxContent = !this.showBoxContent;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showBoxContent == true) {
      this.boxContentDisplay = '';
      this.boxContentDirection = '';
    } else {
      this.boxContentDisplay = 'none';
      this.boxContentDirection = '';
    }
  }

  showAccountProcessingSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showActivityDescription = false;
    this.ActivityDescriptionDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showAccountProcessing = !this.showAccountProcessing;

    if (this.showAccountProcessing == true) {
      this.AccountProcessingDisplay = '';
      this.AccountProcessingDirection = '';
    } else {
      this.AccountProcessingDisplay = 'none';
      this.AccountProcessingDirection = '';
    }
  }

  showActivitDescriptionSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;

    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showActivityDescription = !this.showActivityDescription;

    if (this.showActivityDescription == true) {
      this.ActivityDescriptionDisplay = '';
      this.ActivityDescriptionDirection = '';
    } else {
      this.ActivityDescriptionDisplay = 'none';
      this.ActivityDescriptionDirection = '';
    }
  }

  showBalanceErrorSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = !this.showBalanceErrors;

    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showBalanceErrors == true) {
      this.balanceErrorDisplay = '';
      this.balanceErrorDirection = '';
    } else {
      this.balanceErrorDisplay = 'none';
      this.balanceErrorDirection = '';
    }
  }

  showInventoryBooksSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryBooks = !this.showInventoryBooks;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';

    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showInventoryBooks == true) {
      this.inventoryBooksDisplay = '';
      this.inventoryBooksDirection = '';
    } else {
      this.inventoryBooksDisplay = 'none';
      this.inventoryBooksDirection = '';
    }
  }

  showAddressTypeSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryBooks = false;
    this.showAddressTypes = !this.showAddressTypes;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';

    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showAddressTypes == true) {
      this.addressTypeDisplay = '';
      this.addressTypeDirection = '';
    } else {
      this.addressTypeDisplay = 'none';
      this.addressTypeDirection = '';
    }
  }

  showInventoryTagsSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';

    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showInventoryTagSubmenu = !this.showInventoryTagSubmenu;

    if (this.showInventoryTagSubmenu == true) {
      this.inventoryTagDisplay = '';
      this.inventoryTagDirection = '';
    } else {
      this.inventoryTagDisplay = 'none';
      this.inventoryTagDirection = '';
    }
  }

  showOrderStagingSubMenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = !this.showOrderStagingBatches;

    if (this.showOrderStagingBatches == true) {
      this.orderStagingBatchesDisplay = '';
      this.orderStagingBatchesDirection = '';
    } else {
      this.orderStagingBatchesDisplay = 'none';
      this.orderStagingBatchesDirection = '';
    }
  }

  // showOrderStagingSubMenu() {
  //   this.showreceviceSubmenu = false;
  //   this.showorderSubmenu = false;
  //   this.showshippingSubmenu = false;
  //   this.showBuildingSubmenu = false;
  //   this.showtallysSubmenu = false;
  //   this.showMapZoneSubmenu = false;
  //   this.showCarrierSubmenu = false;
  //   this.showMoveQueueSubmenu = false;
  //   this.showPostalAbbreviations = false;
  //   this.showWorkWithActionsRsms = false;
  //   this.showWorkWithApplication = false;
  //   this.showControllBatchFile = false;
  //   this.showAuditHeaders = false;
  //   this.showReceivables = false;
  //   this.showActivityDescription = false;
  //   this.ActivityDescriptionDirection = '';
  //   this.ActivityDescriptionDisplay = 'none';

  //   this.receivablesDisplay = 'none';
  //   this.receivablesDirection = '';
  //   this.showAccountProcessing = false;
  //   this.AccountProcessingDisplay = 'none';
  //   this.ActivityDescriptionDirection = '';
  //   this.itemdisplay = 'none';
  //   this.itemdirection = '';
  //   this.carrierdisplay = 'none';
  //   this.carrierdirection = '';
  //   this.receviedisplay = 'none';
  //   this.receviedirection = '';
  //   this.tallysdisplay = 'none';
  //   this.tallysdirection = '';
  //   this.buildingdisplay = 'none';
  //   this.buildingdirection = '';
  //   this.shippingsdisplay = 'none';
  //   this.shippingsdirection = '';
  //   this.mapZonedirection = '';
  //   this.mapZonedisplay = 'none';
  //   this.mapLocationDispaly = 'none';
  //   this.mapLocationDirection = '';
  //   this.exitPointDisplay = 'none';
  //   this.exitPointDirection = '';
  //   this.moveQueueDisplay = 'none';
  //   this.moveQueueDirection = '';
  //   this.inventoryBooksDisplay='none'
  //   this.inventoryBooksDirection=''
  //   this.showInventoryTagSubmenu = false;
  //   this.showOrderStagingBatches= !this.showOrderStagingBatches;

  //   if (this.showOrderStagingBatches == true) {
  //     this.orderStagingBatchesDisplay = '';
  //     this.orderStagingBatchesDirection = '';
  //   } else {
  //     this.orderStagingBatchesDisplay = 'none';
  //     this.orderStagingBatchesDirection = '';
  //   }
  // }

  showDockScheduleSubMenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = !this.showDockSchedules;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';

    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';

    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.inventoryBooksDisplay = 'none';
    this.inventoryBooksDirection = '';

    if (this.showDockSchedules == true) {
      this.dockScheduleDisplay = '';
      this.dockScheduleDirection = '';
    } else {
      this.dockScheduleDisplay = 'none';
      this.dockScheduleDirection = '';
    }
  }

  showLtlBatchesSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = !this.showLtlBatches;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.inventoryBooksDisplay = 'none';
    this.inventoryBooksDirection = '';

    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showLtlBatches == true) {
      this.ltlBatchesDisplay = '';
      this.ltlBatchesDirection = '';
    } else {
      this.ltlBatchesDisplay = 'none';
      this.ltlBatchesDirection = '';
    }
  }

  showBookDetailsSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;

    this.showBookDetails = !this.showBookDetails;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.ActivityDescriptionDirection = '';
    this.inventoryBooksDisplay = 'none';
    this.inventoryBooksDirection = '';

    this.ActivityDescriptionDisplay = 'none';

    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';

    if (this.showBookDetails == true) {
      this.bookDetailsDisplay = '';
      this.bookDetailsDirection = '';
    } else {
      this.bookDetailsDisplay = 'none';
      this.bookDetailsDirection = '';
    }
  }

  showBoxSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showLtlBatches = false;
    this.showWorkWithBoxes = !this.showWorkWithBoxes;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showDockSchedules = false;

    if (this.showWorkWithBoxes == true) {
      this.boxDisplay = '';
      this.boxDirection = '';
    } else {
      this.boxDisplay = 'none';
      this.boxDirection = '';
    }
  }

  showMasterSerialListSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showLtlBatches = false;
    this.showWorkWithBoxes = false;
    this.showMasterSerialList = !this.showMasterSerialList;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showDockSchedules = false;

    if (this.showMasterSerialList == true) {
      this.masterSerialListDisplay = '';
      this.masterSerialListDirection = '';
    } else {
      this.masterSerialListDisplay = 'none';
      this.masterSerialListDirection = '';
    }
  }

  showHazardousChemicalsSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showLtlBatches = false;
    this.showWorkWithBoxes = false;
    this.showMasterSerialList = false;
    this.showHazardousChemicals = !this.showHazardousChemicals;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showDockSchedules = false;

    if (this.showHazardousChemicals == true) {
      this.hazardousChemicalsDisplay = '';
      this.hazardousChemicalsDirection = '';
    } else {
      this.hazardousChemicalsDisplay = 'none';
      this.masterSerialListDirection = '';
    }
  }

  showSystemCommentsSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showLtlBatches = false;
    this.showWorkWithBoxes = false;
    this.showMasterSerialList = false;
    this.showHazardousChemicals = false;
    this.showSystemComments = !this.showSystemComments;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showDockSchedules = false;

    if (this.showSystemComments == true) {
      this.systemCommentsDisplay = '';
      this.systemCommentsDirection = '';
    } else {
      this.systemCommentsDisplay = 'none';
      this.systemCommentsDirection = '';
    }
  }

  showSeriallizedInventoriesSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showLtlBatches = false;
    this.showWorkWithBoxes = false;
    this.showMasterSerialList = false;
    this.showHazardousChemicals = false;
    this.showSystemComments = false;
    this.showSerializedInventories = !this.showSerializedInventories;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showDockSchedules = false;

    if (this.showSerializedInventories == true) {
      this.serializedInventoriesDisplay = '';
      this.serializedInventoriesDirection = '';
    } else {
      this.serializedInventoriesDisplay = 'none';
      this.serializedInventoriesDirection = '';
    }
  }

  showCarrierEdiDocumentSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showLtlBatches = false;
    this.showWorkWithBoxes = false;
    this.showMasterSerialList = false;
    this.showHazardousChemicals = false;
    this.showSystemComments = false;
    this.showSerializedInventories = false;
    this.showCarrierEdiDocuments = !this.showCarrierEdiDocuments;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showDockSchedules = false;

    if (this.showCarrierEdiDocuments == true) {
      this.carrierEdiDocumentDisplay = '';
      this.carrierEdiDocumentDirection = '';
    } else {
      this.carrierEdiDocumentDisplay = 'none';
      this.carrierEdiDocumentDirection = '';
    }
  }

  showContentsSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showBalanceErrors = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showLtlBatches = false;
    this.showWorkWithBoxes = false;
    this.showMasterSerialList = false;
    this.showHazardousChemicals = false;
    this.showSystemComments = false;
    this.showSerializedInventories = false;
    this.showCarrierEdiDocuments = false;
    this.showWorkWithContents = !this.showWorkWithContents;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showDockSchedules = false;

    if (this.showWorkWithContents == true) {
      this.contentsDisplay = '';
      this.contentsDirection = '';
    } else {
      this.contentsDisplay = 'none';
      this.contentsDirection = '';
    }
  }

  showCommodityPrintersSubMenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showCommodityPrinters = !this.showCommodityPrinters;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showFiles = false;
    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.showcampusBuildings = false;
    this.campusBuildingsDirection = '';
    this.campusBuildingsDisplay = 'none';
    this.showBookDetails = false;
    this.bookDetailsDisplay = 'none';
    this.bookDetailsDirection = '';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDisplay = 'none';
    this.bookDetailsDirection = '';
    if (this.showCommodityPrinters == true) {
      this.commodityDisplay = '';
      this.commodityDirection = '';
    } else {
      this.commodityDisplay = 'none';
      this.commodityDirection = '';
    }
  }

  showFilesSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showFiles = !this.showFiles;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    this.showcampusBuildings = false;
    this.campusBuildingsDirection = '';
    this.campusBuildingsDisplay = 'none';
    if (this.showFiles == true) {
      this.filesDisplay = '';
      this.filesDirection = '';
    } else {
      this.filesDisplay = 'none';
      this.filesDirection = '';
    }
  }

  showcampusBuildingsSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = !this.showcampusBuildings;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showcampusBuildings == true) {
      this.campusBuildingsDisplay = '';
      this.campusBuildingsDirection = '';
    } else {
      this.campusBuildingsDisplay = 'none';
      this.campusBuildingsDirection = '';
    }
  }

  showcommentSubcodesSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = !this.showCommentSubcodes;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showCommentSubcodes == true) {
      this.commentSubcodeDisplay = '';
      this.commentSubcodeDirection = '';
    } else {
      this.commentSubcodeDisplay = 'none';
      this.commentSubcodeDirection = '';
    }
  }

  showcustomerAddressesSubmenu() {
    this.showCustomersSubmenu = false;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = !this.showCustomerAddresses;
    this.showEmailExitPointsSubmenu = false;
    this.emailExitPointsdisplay = 'none';
    this.emailExitPointsdirection = '';
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showCustomerAddresses == true) {
      this.customerAddressesDisplay = '';
      this.customerAddressesDirection = '';
    } else {
      this.customerAddressesDisplay = 'none';
      this.customerAddressesDirection = '';
    }
  }

  showProductCodeDescriptionSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showBookDetails = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = !this.showProductCodeDescription;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';

    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showProductCodeDescription == true) {
      this.productCodeDescriptionDisplay = '';
      this.productCodeDescriptionDirection = '';
    } else {
      this.productCodeDescriptionDisplay = 'none';
      this.productCodeDescriptionDirection = '';
    }
  }

  showCampusesSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showCampuses = !this.showCampuses;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showCampuses == true) {
      this.campusesDisplay = '';
      this.campusesDirection = '';
    } else {
      this.campusesDisplay = 'none';
      this.campusesDirection = '';
    }
  }

  showConsigneeBasedTriggerSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showCampuses = false;
    this.showConsigneeBasedTriggers = !this.showConsigneeBasedTriggers;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showConsigneeBasedTriggers == true) {
      this.consigneeBasedTriggerDisplay = '';
      this.consigneeBasedTriggerDirection = '';
    } else {
      this.consigneeBasedTriggerDisplay = 'none';
      this.consigneeBasedTriggerDirection = '';
    }
  }

  showMetricConversionSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showCampuses = false;
    this.showConsigneeBasedTriggers = false;
    this.showMetricConversions = !this.showMetricConversions;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showMetricConversions == true) {
      this.metricConversionsDisplay = '';
      this.metricConversionsDirection = '';
    } else {
      this.metricConversionsDisplay = 'none';
      this.metricConversionsDirection = '';
    }
  }

  showExpectedDurationsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showCampuses = false;
    this.showConsigneeBasedTriggers = false;
    this.showMetricConversions = false;
    this.showExpectedDurations = !this.showExpectedDurations;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showExpectedDurations == true) {
      this.expectedDurationsDisplay = '';
      this.expectedDurationsDirection = '';
    } else {
      this.expectedDurationsDisplay = 'none';
      this.expectedDurationsDirection = '';
    }
  }

  showContactTypesSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showCampuses = false;
    this.showConsigneeBasedTriggers = false;
    this.showMetricConversions = false;
    this.showExpectedDurations = false;
    this.showContactTypes = !this.showContactTypes;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showContactTypes == true) {
      this.contactTypesDisplay = '';
      this.contactTypeDirection = '';
    } else {
      this.contactTypesDisplay = 'none';
      this.contactTypeDirection = '';
    }
  }

  showCustomerRatingPreferenceSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showCampuses = false;
    this.showConsigneeBasedTriggers = false;
    this.showMetricConversions = false;
    this.showExpectedDurations = false;
    this.showContactTypes = false;
    this.showCustomerRatingPreferences = !this.showCustomerRatingPreferences;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showCustomerRatingPreferences == true) {
      this.customerRatingPreferenceDisplay = '';
      this.customerRatingPreferenceDirection = '';
    } else {
      this.customerRatingPreferenceDisplay = 'none';
      this.customerRatingPreferenceDirection = '';
    }
  }

  showDataElementSubmenu = false;

  DataElementdisplay = 'none';
  DataElementDirection = '';

  showDataElementSubmenushowhide() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showDataElementSubmenu = !this.showDataElementSubmenu;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showDataElementSubmenu == true) {
      this.DataElementdisplay = '';
      this.DataElementDirection = '';
    } else {
      this.DataElementdisplay = 'none';
      this.DataElementDirection = '';
    }
  }

  showDockSchedulerControll = false;

  dockSchedulerControllDisplay = 'none';
  dockSchedulerControllDirection = '';

  showDockSchedulerControllSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showDockSchedulerControll = !this.showDockSchedulerControll;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showDataElementSubmenu = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showDockSchedulerControll == true) {
      this.dockSchedulerControllDisplay = '';
      this.dockSchedulerControllDirection = '';
    } else {
      this.dockSchedulerControllDisplay = 'none';
      this.dockSchedulerControllDirection = '';
    }
  }

  showDisplayDiviceAttributes = false;

  displayDiviceAttributesDisplay = 'none';
  displayDiviceAttributesDirection = '';

  showDisplayDiviceAttributeSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showDockSchedulerControll = false;
    this.showDisplayDiviceAttributes = !this.showDisplayDiviceAttributes;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showDataElementSubmenu = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showDisplayDiviceAttributes == true) {
      this.displayDiviceAttributesDisplay = '';
      this.displayDiviceAttributesDirection = '';
    } else {
      this.displayDiviceAttributesDisplay = 'none';
      this.displayDiviceAttributesDirection = '';
    }
  }

  showInvoiceSubmenu = false;
  invoicedisplay = 'none';
  invoiceDirection = '';

  showInvoiceSubmenushowhide() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = !this.showInvoiceSubmenu;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showInvoiceSubmenu == true) {
      this.invoicedisplay = '';
      this.invoiceDirection = '';
    } else {
      this.invoicedisplay = 'none';
      this.invoiceDirection = '';
    }
  }

  showTripExpences = false;
  tripExpensesDisplay = 'none';
  tripExpensesDirection = '';

  showTripExpensesSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showTripExpences = !this.showTripExpences;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showTripExpences == true) {
      this.tripExpensesDisplay = '';
      this.tripExpensesDirection = '';
    } else {
      this.tripExpensesDisplay = 'none';
      this.tripExpensesDirection = '';
    }
  }

  showEmailSubmenushowhide() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showEmailSubmenu = !this.showEmailSubmenu;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showEmailSubmenu == true) {
      this.emailDisplay = '';
      this.emailDirection = '';
    } else {
      this.emailDisplay = 'none';
      this.emailDirection = '';
    }
  }

  showExtractreportsBaseFeilds = false;
  extractReportsDisplay = 'none';
  extractReportsDirection = '';

  showExtractreportsBaseFeildsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showTripExpences = false;
    this.showExtractreportsBaseFeilds = !this.showExtractreportsBaseFeilds;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showExtractreportsBaseFeilds == true) {
      this.extractReportsDisplay = '';
      this.extractReportsDirection = '';
    } else {
      this.extractReportsDisplay = 'none';
      this.extractReportsDirection = '';
    }
  }

  showEquipmentOperators = false;
  equipmentOperatorsDisplay = 'none';
  equipmentOperatorsDirection = '';

  showEquipmentOperatorsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = !this.showEquipmentOperators;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showEquipmentOperators == true) {
      this.equipmentOperatorsDisplay = '';
      this.equipmentOperatorsDirection = '';
    } else {
      this.equipmentOperatorsDisplay = 'none';
      this.equipmentOperatorsDirection = '';
    }
  }

  showOperatorEquipment = false;
  operatorEquipmentDisplay = 'none';
  operatorEquipmentDirection = '';

  showOperatorEquipmentSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showOperatorEquipment = !this.showOperatorEquipment;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showOperatorEquipment == true) {
      this.operatorEquipmentDisplay = '';
      this.operatorEquipmentDirection = '';
    } else {
      this.operatorEquipmentDisplay = 'none';
      this.operatorEquipmentDirection = '';
    }
  }

  showExtractReportFilters = false;
  extractReportFiltersDisplay = 'none';
  extractReportFilterssDirection = '';

  showExtractReportFiltersSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showExtractReportFilters = !this.showExtractReportFilters;
    this.showEquipmentOperators = false;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showExtractReportFilters == true) {
      this.extractReportFiltersDisplay = '';
      this.extractReportFilterssDirection = '';
    } else {
      this.extractReportFiltersDisplay = 'none';
      this.extractReportFilterssDirection = '';
    }
  }

  showWorkSchedule = false;
  workScheduleDisplay = 'none';
  workScheduleDirection = '';

  showWorkScheduleSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showExtractReportFilters = false;
    this.showWorkSchedule = !this.showWorkSchedule;
    this.showEquipmentOperators = false;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showWorkSchedule == true) {
      this.workScheduleDisplay = '';
      this.workScheduleDirection = '';
    } else {
      this.workScheduleDisplay = 'none';
      this.workScheduleDirection = '';
    }
  }

  showValidityCharacterRemoval = false;
  validityCharacterRemovalDisplay = 'none';
  validityCharacterRemovalDirection = '';

  showValidityCharacterRemovalSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showValidityCharacterRemoval = !this.showValidityCharacterRemoval;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showValidityCharacterRemoval == true) {
      this.validityCharacterRemovalDisplay = '';
      this.validityCharacterRemovalDirection = '';
    } else {
      this.validityCharacterRemovalDisplay = 'none';
      this.validityCharacterRemovalDirection = '';
    }
  }

  showHazmatClass = false;
  HazmatClassDisplay = 'none';
  HazmatClassDirection = '';

  showHazmatClassSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showHazmatClass = !this.showHazmatClass;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showHazmatClass == true) {
      this.HazmatClassDisplay = '';
      this.HazmatClassDirection = '';
    } else {
      this.HazmatClassDisplay = 'none';
      this.HazmatClassDirection = '';
    }
  }

  showFreightCode = false;
  FreightCodeDisplay = 'none';
  FreightCodeDirection = '';

  showFreightCodeSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showFreightCode = !this.showFreightCode;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showFreightCode == true) {
      this.FreightCodeDisplay = '';
      this.FreightCodeDirection = '';
    } else {
      this.FreightCodeDisplay = 'none';
      this.FreightCodeDirection = '';
    }
  }

  showEnvironment = false;
  EnvironmentDisplay = 'none';
  EnvironmentDirection = '';

  showEnvironmentSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showEnvironment = !this.showEnvironment;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showEnvironment == true) {
      this.EnvironmentDisplay = '';
      this.EnvironmentDirection = '';
    } else {
      this.EnvironmentDisplay = 'none';
      this.EnvironmentDirection = '';
    }
  }

  showProductCode = false;
  productCodeDisplay = 'none';
  productCodeDirection = '';

  showProductCodeSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showEnvironment = false;
    this.showProductCode = !this.showProductCode;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showProductCode == true) {
      this.productCodeDisplay = '';
      this.productCodeDirection = '';
    } else {
      this.productCodeDisplay = 'none';
      this.productCodeDirection = '';
    }
  }

  showItemCountSchedule = false;
  itemCountScheduleDisplay = 'none';
  itemCountScheduleDirection = '';

  showItemCountScheduleSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showItemCountSchedule = !this.showItemCountSchedule;
    this.showEquipmentOperators = false;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showItemCountSchedule == true) {
      this.itemCountScheduleDisplay = '';
      this.itemCountScheduleDirection = '';
    } else {
      this.itemCountScheduleDisplay = 'none';
      this.itemCountScheduleDirection = '';
    }
  }

  showKanban = false;
  kanbanDisplay = 'none';
  kanbanDirection = '';

  showKanbanSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showItemCountSchedule = false;
    this.showEquipmentOperators = false;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.showKanban = !this.showKanban;
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showKanban == true) {
      this.kanbanDisplay = '';
      this.kanbanDirection = '';
    } else {
      this.kanbanDisplay = 'none';
      this.kanbanDirection = '';
    }
  }

  showscheduleException = false;
  scheduleExceptionDisplay = 'none';
  scheduleExceptionDirection = '';

  showScheduleExceptionSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showscheduleException = !this.showscheduleException;
    this.showItemCountSchedule = false;
    this.showEquipmentOperators = false;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showscheduleException == true) {
      this.scheduleExceptionDisplay = '';
      this.scheduleExceptionDirection = '';
    } else {
      this.scheduleExceptionDisplay = 'none';
      this.scheduleExceptionDirection = '';
    }
  }

  showgeneralInvoice = false;
  generalInvoiceDisplay = 'none';
  generalInvoiceDirection = '';

  showgeneralInvoiceSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showgeneralInvoice = !this.showgeneralInvoice;
    this.showscheduleException = false;
    this.showItemCountSchedule = false;
    this.showEquipmentOperators = false;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showgeneralInvoice == true) {
      this.generalInvoiceDisplay = '';
      this.generalInvoiceDirection = '';
    } else {
      this.generalInvoiceDisplay = 'none';
      this.generalInvoiceDirection = '';
    }
  }

  showExtractReports = false;
  wwExtractReportsDisplay = 'none';
  wwExtractReportstDirection = '';

  showExtractReportSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showgeneralInvoice = false;
    this.showExtractReports = !this.showExtractReports;
    this.showscheduleException = false;
    this.showItemCountSchedule = false;
    this.showEquipmentOperators = false;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showExtractReports == true) {
      this.wwExtractReportsDisplay = '';
      this.wwExtractReportstDirection = '';
    } else {
      this.wwExtractReportsDisplay = 'none';
      this.wwExtractReportstDirection = '';
    }
  }

  showPeriods = false;

  periodDisplay = 'none';
  periodDirection = '';

  showPeriodsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showDockSchedulerControll = false;
    this.showPeriods = !this.showPeriods;
    this.showDisplayDiviceAttributes = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showDataElementSubmenu = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showPeriods == true) {
      this.periodDisplay = '';
      this.periodDirection = '';
    } else {
      this.periodDisplay = 'none';
      this.periodDirection = '';
    }
  }

  showLaborPlans = false;

  laborPlansDisplay = 'none';
  laborPlansDirection = '';

  showLaborPlansSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showDockSchedulerControll = false;
    this.showLaborPlans = !this.showLaborPlans;
    this.showDisplayDiviceAttributes = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showDataElementSubmenu = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showLaborPlans == true) {
      this.laborPlansDisplay = '';
      this.laborPlansDirection = '';
    } else {
      this.laborPlansDisplay = 'none';
      this.laborPlansDirection = '';
    }
  }

  //Work with ware house map-1549
  showWareHouseMap = false;

  wareHouseMapDisplay = 'none';
  wareHouseMapDirection = '';

  showwareHouseMapSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showDockSchedulerControll = false;
    this.showLaborPlans = false;
    this.showWareHouseMap = !this.showWareHouseMap;
    this.showDisplayDiviceAttributes = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showDataElementSubmenu = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showWareHouseMap == true) {
      this.wareHouseMapDisplay = '';
      this.wareHouseMapDirection = '';
    } else {
      this.wareHouseMapDisplay = 'none';
      this.wareHouseMapDirection = '';
    }
  }

  showSerialNumbers = false;
  SerialNumbersDisplay = 'none';
  SerialNumbersDirection = '';

  showSerialNumbersSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showSerialNumbers = !this.showSerialNumbers;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showSerialNumbers == true) {
      this.SerialNumbersDisplay = '';
      this.SerialNumbersDirection = '';
    } else {
      this.SerialNumbersDisplay = 'none';
      this.SerialNumbersDirection = '';
    }
  }

  showLedgerCode = false;
  LedgerCodeDisplay = 'none';
  LedgerCodeDirection = '';

  showLedgerCodeSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showLedgerCode = !this.showLedgerCode;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showLedgerCode == true) {
      this.LedgerCodeDisplay = '';
      this.LedgerCodeDirection = '';
    } else {
      this.LedgerCodeDisplay = 'none';
      this.LedgerCodeDirection = '';
    }
  }

  showLaborPlan = false;
  LaborPlanDisplay = 'none';
  LaborPlanDirection = '';

  showLaborPlanSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showLaborPlan = !this.showLaborPlan;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showLaborPlan == true) {
      this.LaborPlanDisplay = '';
      this.LaborPlanDirection = '';
    } else {
      this.LaborPlanDisplay = 'none';
      this.LaborPlanDirection = '';
    }
  }

  showOperatorGroups = false;
  OperatorGroupsDisplay = 'none';
  OperatorGroupsDirection = '';

  showOperatorGroupsSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showOperatorGroups = !this.showOperatorGroups;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showOperatorGroups == true) {
      this.OperatorGroupsDisplay = '';
      this.OperatorGroupsDirection = '';
    } else {
      this.OperatorGroupsDisplay = 'none';
      this.OperatorGroupsDirection = '';
    }
  }

  showLoadOut = false;
  LoadOutDisplay = 'none';
  LoadOutDirection = '';

  showLoadOutSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showInvoiceSubmenu = false;
    this.showEquipmentOperators = false;
    this.showLoadOut = !this.showLoadOut;
    this.showTripExpences = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    if (this.showLoadOut == true) {
      this.LoadOutDisplay = '';
      this.LoadOutDirection = '';
    } else {
      this.LoadOutDisplay = 'none';
      this.LoadOutDirection = '';
    }
  }


  showLotStatusSubmenushowhide() {
    this.showLotStatusSubmenu = !this.showLotStatusSubmenu;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    if (this.showLotStatusSubmenu == true) {
      this.LotStatusdisplay = '';
      this.LotStatusdirection = '';
    } else {
      this.LotStatusdisplay = 'none';
      this.LotStatusdirection = '';
    }
  }
  
  showLaborProcessingSubmenushowhide() {
    this.showLaborProcessingSubmenu = !this.showLaborProcessingSubmenu;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showDockSchedulerControll = false;
    this.showMessageClassTables = false;
    this.showLaborPlans = false;
    this.showDisplayDiviceAttributes = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showDataElementSubmenu = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';


    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';


    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    if (this.showLaborProcessingSubmenu == true) {
      this.LaborProcessingdisplay = '';
      this.LaborProcessingdirection = '';
    } else {
      this.LaborProcessingdisplay = 'none';
      this.LaborProcessingdirection = '';
    }

  }

  showMessageClassTables = false;

  messageClassTablesDisplay = 'none';
  messageClassTablesDirection = '';

  showMessageClassTablesSubmenu() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showtallysSubmenu = false;
    this.showMapZoneSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.showBookDetails = false;
    this.showDockSchedulerControll = false;
    this.showMessageClassTables = !this.showMessageClassTables;
    this.showLaborPlans = false;
    this.showDisplayDiviceAttributes = false;
    this.showPostalAbbreviations = false;
    this.showWorkWithActionsRsms = false;
    this.showWorkWithApplication = false;
    this.showControllBatchFile = false;
    this.showAuditHeaders = false;
    this.showReceivables = false;
    this.showActivityDescription = false;
    this.showInventoryTagSubmenu = false;
    this.showOrderStagingBatches = false;
    this.showDockSchedules = false;
    this.showLtlBatches = false;
    this.showcampusBuildings = false;
    this.showCommentSubcodes = false;
    this.showCustomerAddresses = false;
    this.showProductCodeDescription = false;
    this.showDataElementSubmenu = false;
    this.ActivityDescriptionDirection = '';
    this.ActivityDescriptionDisplay = 'none';
    this.showCommodityPrinters = false;
    this.commodityDisplay = 'none';
    this.commodityDirection = '';
    this.showBookDetails = false;
    this.bookDetailsDirection = '';
    this.bookDetailsDisplay = 'none';
    this.receivablesDisplay = 'none';
    this.receivablesDirection = '';
    this.showAccountProcessing = false;
    this.AccountProcessingDisplay = 'none';
    this.ActivityDescriptionDirection = '';
    this.showFiles = false;

    this.filesDisplay = 'none';
    this.filesDirection = '';


    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';


    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';
    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    // if (this.showLaborProcessingSubmenu == true) {
    //   this.LaborProcessingdisplay = '';
    //   this.LaborProcessingdirection = '';
    // } else {
    //   this.LaborProcessingdisplay = 'none';
    //   this.LaborProcessingdirection = '';
    // }

     if (this.showMessageClassTables == true) {
      this.messageClassTablesDisplay = '';
      this.messageClassTablesDirection = '';
    } else {
      this.messageClassTablesDisplay = 'none';
      this.messageClassTablesDirection = '';


    }
  }
 
  showExtractReportWhereshowhide() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu=false;
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay='none';
    this.consigneeDirection='';
    this.exitPointDisplay='none';
    this.exitPointDirection='';
    this.moveQueueDisplay='none';
 this.moveQueueDirection='';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showExtractReportWhereSubmenu = !this.showExtractReportWhereSubmenu;
    if (this.showExtractReportWhereSubmenu == true) {
      this.ExtractReportWheredisplay = '';
      this.ExtractReportWheredirection = '';
    } else {
      this.ExtractReportWheredisplay = 'none';
      this.ExtractReportWheredirection = '';
    }
  }
showHazardmaterialsGroupshowhide() {
  this.showreceviceSubmenu = false;
  this.showorderSubmenu = false;
  this.showshippingSubmenu = false;
  this.showstorerSubmenu = false;
  this.showBuildingSubmenu = false;
  this.showCarrierSubmenu = false;
  this.showItemSubmenu = false;
  this.showMoveQueueSubmenu=false;
  this.itemdisplay = 'none';
  this.itemdirection = '';
  this.carrierdisplay = 'none';
  this.carrierdirection = '';
  this.storerdisplay = 'none';
  this.storerdirection = '';
  this.mapZonedirection = '';
  this.mapZonedisplay = 'none';
  this.receviedisplay = 'none';
  this.receviedirection = '';
  this.tallysdisplay = 'none';
  this.tallysdirection = '';
  this.shippingsdisplay = 'none';
  this.shippingsdirection = '';
  this.buildingdisplay = 'none';
  this.buildingdirection = '';
  this.consigneeDisplay='none';
  this.consigneeDirection='';
  this.exitPointDisplay='none';
  this.exitPointDirection='';
  this.moveQueueDisplay='none';
this.moveQueueDirection='';
  (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
  this.showHazardmaterialsGroupSubmenu = !this.showHazardmaterialsGroupSubmenu;
  if (this.showHazardmaterialsGroupSubmenu == true) {
    this.HazardmaterialsGroupdisplay = '';
    this.HazardmaterialsGroupdirection = '';
  } else {
    this.HazardmaterialsGroupdisplay = 'none';
    this.HazardmaterialsGroupdirection = '';
    }
  }
  showLaborPlanshowhide() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu=false;
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';


    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';


    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay='none';
    this.consigneeDirection='';
    this.exitPointDisplay='none';
    this.exitPointDirection='';
    this.moveQueueDisplay='none';
 this.moveQueueDirection='';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showLaborsPlanSubmenu = !this.showLaborsPlanSubmenu;
    if (this.showLaborsPlanSubmenu == true) {
      this.LaborPlandisplay = '';
      this.LaborPlandirection = '';
    } else {
      this.LaborPlandisplay = 'none';
      this.LaborPlandirection = '';
    }
  }
 
  showERSSubmenushowhide() {
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu=false;
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay='none';
    this.consigneeDirection='';
    this.exitPointDisplay='none';
    this.exitPointDirection='';
    this.moveQueueDisplay='none';
 this.moveQueueDirection='';
    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showERSSubmenu = !this.showERSSubmenu;
    if (this.showERSSubmenu == true) {
      this.ERSdisplay = '';
      this.ERSdirection = '';
    } else {
      this.ERSdisplay = 'none';
      this.ERSdirection = '';
    }
  }

  showEmailFormatSubmenushowhide() {
    this.showEmailFormatSubmenu = !this.showEmailFormatSubmenu;
    this.showreceviceSubmenu = false;
    this.showorderSubmenu = false;
    this.showshippingSubmenu = false;
    this.showstorerSubmenu = false;
    this.showBuildingSubmenu = false;
    this.showCarrierSubmenu = false;
    this.showItemSubmenu = false;
    this.showMoveQueueSubmenu = false;
    this.itemdisplay = 'none';
    this.itemdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.customersdisplay = 'none';
    this.customersdirection = '';
    this.storerdisplay = 'none';
    this.storerdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.receviedisplay = 'none';
    this.receviedirection = '';
    this.tallysdisplay = 'none';
    this.tallysdirection = '';
    this.carrierdisplay = 'none';
    this.carrierdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.consigneeDisplay = 'none';
    this.consigneeDirection = '';


    this.buildingdisplay = 'none';
    this.buildingdirection = '';
    this.shippingsdisplay = 'none';
    this.shippingsdirection = '';
    this.mapZonedirection = '';
    this.mapZonedisplay = 'none';
    this.mapLocationDispaly = 'none';
    this.mapLocationDirection = '';


    this.exitPointDisplay = 'none';
    this.exitPointDirection = '';
    this.moveQueueDisplay = 'none';
    this.moveQueueDirection = '';


    (this.mapLocationDispaly = 'none'), (this.mapLocationDirection = '');
    this.showExtractReportWhereSubmenu = !this.showExtractReportWhereSubmenu;
    if (this.showEmailFormatSubmenu == true) {
      this.EmailFormatdisplay = '';
      this.EmailFormatdirection = '';
    } else {
      this.EmailFormatdisplay = 'none';
      this.EmailFormatdirection = '';


    // if (this.showMessageClassTables == true) {
    //   this.messageClassTablesDisplay = '';
    //   this.messageClassTablesDirection = '';
    // } else {
    //   this.messageClassTablesDisplay = 'none';
    //   this.messageClassTablesDirection = '';




    // }
  }}
  
  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }

}
