<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Delete a Period</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Storer</mat-label>
          <input matInput formControlName="PESTOR" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Period Start Date</mat-label>
          <input type="date" matInput formControlName="STDT" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Period End Date</mat-label>
          <input type="date" matInput formControlName="ENDT" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Balance Written</mat-label>
          <input matInput formControlName="PEBALN" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Storage Invoice Number</mat-label>
          <input matInput formControlName="PEINV" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Status</mat-label>
          <input matInput formControlName="PESTAT" />
        </mat-form-field>
        
        <div class="row">
        <div class="col">
          <button
          class="buttons removeButton"
          mat-raised-button
          (click)="delete()"
        >
          <span>Delete</span>
        </button>
        </div>
        </div>
      </div>
    </form>
  </mat-card-content>

</mat-card>
