<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Item Master Maintenance</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">

    <form [formGroup]="form">

      <div class="row">
        <div class="col">   

         <mat-label style="margin-left: 20px">Storer Number*:</mat-label>

            <mat-form-field  appearance="outline">                    
              <input matInput formControlName="storeNumber" />
            </mat-form-field>

            <mat-form-field  appearance="outline"> 
              <input matInput formControlName="suffix" style="text-transform: uppercase"/>
              <mat-hint >storer suffix</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Item Code</mat-label>
              <input matInput formControlName="item" />
            </mat-form-field>         
          
        
          <mat-label style="margin-left: 20px">Description*:</mat-label>

            <mat-form-field appearance="outline">
              <input matInput formControlName="itemDescription1" />
              <mat-hint >description 1</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <input matInput formControlName="itemDescription2" />
              <mat-hint >description 2</mat-hint>
            </mat-form-field>

         

          <mat-form-field appearance="outline">
            <mat-label>UPC</mat-label>
            <input matInput formControlName="itemUpcCode" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Tail Code</mat-label>
            <input matInput formControlName="itemUpcTailCode" />
          </mat-form-field>
 
          <mat-form-field appearance="outline">
            <mat-label>Product Code</mat-label>
            <input matInput formControlName="manufacturerProdCode" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Allow Web Selection </mat-label>
            <mat-select formControlName="quickItem">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lotted Item </mat-label>
            <mat-select formControlName="lottedItem">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot Unit Weight </mat-label>
            <mat-select formControlName="lotUnitWeight">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Sort Mask </mat-label>
            <input matInput formControlName="lotSortMask" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Apply To Lot </mat-label>
            <input matInput formControlName="primSortMaskPointer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Place After </mat-label>
            <input matInput formControlName="prevItem" />
          </mat-form-field>
      </div>
    </div>
    <div class="btn-text-right">
      <button mat-button class="button addButton"(click)="createItem()">Next</button>
    </div>
  
  </form>
</mat-card-content>
</mat-card>