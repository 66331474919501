<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title> Delete Labor Plan </mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>DocumentType</mat-label> 
                    <input matInput formControlName="DocumentType"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> DocumentNumber </mat-label> 
                    <input matInput formControlName="DocumentNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>MustStartDate </mat-label> 
                    <input matInput formControlName="MustStartDate"/>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>BuildingNumber</mat-label> 
                    <input matInput formControlName="BuildingNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> StorerNumber </mat-label> 
                    <input matInput formControlName="StorerNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>StageLocation </mat-label> 
                    <input matInput formControlName="StageLocation"/>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>WorkflowProcess </mat-label> 
                    <input matInput formControlName="WorkflowProcess"/>
                </mat-form-field>

            </div> 
            <div class="col">
                <button mat-button (click)="deleteLaborPlan()" class="addButton">
                    
                    <span>Delete</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>
