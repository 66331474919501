import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { InboundPutawayService } from './inbound-putaway.service';

@Component({
  selector: 'app-inbound-putaway',
  templateUrl: './inbound-putaway.component.html',
  styleUrls: ['./inbound-putaway.component.css'],
})
export class InboundPutawayComponent implements OnInit {
  error: string = '';
  putAwayForm = this.fb.group({
    tallyNumber: [null, Validators.required],
    item: [null, Validators.required],
    Quantity: [null, Validators.required],
    InventoryType: [null, Validators.required],
    lot1: [null],
    lot2: [null],
    lot3: [null],
    location: [null],
  });
  result: any = [];
  count = 0;
  constructor(
    private fb: FormBuilder,
    private router: Router,

    public iPutAwaySvc: InboundPutawayService
  ) {}
 // user = GlobalComponent.user;
 user = localStorage.getItem('userName');

  ngOnInit(): void {}

  // onSubmit(): void {
  //   var res = this.putAwayForm.value;

  //   this.result = this.iPutAwaySvc
  //     .getData(
  //       res.tallyNumber,
  //       res.item,
  //       res.lot1,
  //       res.lot2,
  //       res.lot3,
  //       res.Quantity,
  //       res.InventoryType,
  //       res.location,

  //       this.user
  //     )
  //     .subscribe((data) => {
  //       this.result = data[0];
  //       var msg = 'Data saved successfully';

  //       if (this.result.data[10].value === '') {
  //         this.successMessage(msg);
  //       } else {
  //         msg = this.result.data[10].value;
  //         this.FailureMessage(msg);
  //       }
  //     });
  // }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.reloadCurrentRoute();
  }

  FailureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    this.reloadCurrentRoute();
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getCount2(){
    this.count++;
  }

  onSubmit() {
    this.error = '';
    var res = this.putAwayForm.value;
    if (this.count === 1) {
      this.result = this.iPutAwaySvc
        .getItem(
          res.tallyNumber,
          res.item,
          res.lot1,
          res.lot2,
          res.lot3,
          res.Quantity,
          res.InventoryType,
          this.user
        )
        .subscribe((data) => {
          this.result = data;
          if (this.result[0].data[10].value == 'Tally Not Found.') {
            this.error = 'Tally Number not available';
            //this.result[0].data[10].value;
          }
        });
      //this.count++;
    }
    if (this.count === 1) {
      this.result = this.iPutAwaySvc
        .getLocation(res.tallyNumber, res.location, this.user)
        .subscribe((data) => {
          this.result = data[0];
        });
    }

    if (this.count === 1) {
      var res = this.putAwayForm.value;

      this.result = this.iPutAwaySvc
        .getData(
          res.tallyNumber,
          res.item,
          res.lot1,
          res.lot2,
          res.lot3,
          res.Quantity,
          res.InventoryType,
          res.location,
  
          this.user
        )
        .subscribe((data) => {
          this.result = data[0];
          var msg = 'Data saved successfully';
  
          if (this.result.data[10].value === '') {
            this.successMessage(msg);
          } else {
            msg = this.result.data[10].value;
            this.FailureMessage(msg);
          }
        });
    }
  }
}
