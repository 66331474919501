import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import { IItemBalanceService } from '../i-item-balance.service';
import Swal from 'sweetalert2';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-balance',
  templateUrl: './delete-balance.component.html',
  styleUrls: ['./delete-balance.component.css'],
})
export class DeleteBalanceComponent implements OnInit {
  item: any;
  form!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private iitemBalnSvc: IItemBalanceService,
    private matDialog: MatDialog,
    private CustomAlertService: CustomAlertService,

    private dialogRef: MatDialogRef<DeleteBalanceComponent>
  ) {}

  ngOnInit(): void {
    console.log(this.anyvariable);
    var type = typeof this.anyvariable.ITSTOR;
    console.log(type);
    this.form = this.fb.group({
      StorerNumber: this.anyvariable.ITSTOR,
      suffix: this.anyvariable.ITSSFX,
      Item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
      Date: this.anyvariable.date,
      LotCode1: this.anyvariable.lot1,
      Building: this.anyvariable.ITBLDG,
      RecordType: this.anyvariable.type,
      LotCode2: this.anyvariable.lot2,
      LotCode3: this.anyvariable.lot3,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  result: any;

  deleteItemBalance() {
    var res = this.form.value;

    const dialogRef = this.CustomAlertService.openCustomAlert(
      'Are you sure you want to delete Item Balance? '
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.result = this.iitemBalnSvc
          .deleteItemBalance(
            this.anyvariable.ITSTOR,
            res.suffix,
            this.anyvariable.ITEM,
            res.LotCode1,
            res.LotCode2,
            res.LotCode3,
            res.Building,
            res.Date
          )
          .subscribe((data: any) => {
            this.result = data;
            console.log(this.result);
            var value = this.result[0].data[9].value;
            if (value == 'DELETED SUCCESSFULLY') {
              this.successMessage(value);
              this.closeDialog();
              location.reload();

              //this.router.navigate(['/main-nav/item/read']);
            } else {
              this.failureMessage(value);
            }
          });
      }
    });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }
}
