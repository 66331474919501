<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Reason Codes</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Data Element Code </mat-label>
            <input matInput formControlName="DataElementCode" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Reason Code </mat-label>
            <input matInput formControlName="ReasonCode" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
    <ng-container matColumnDef="ElementCode">
      <th mat-header-cell *matHeaderCellDef>Element Code</th>
      <td mat-cell *matCellDef="let element">{{ element.ElementCode }}</td>
    </ng-container>

    <ng-container matColumnDef="ReasonCode">
      <th mat-header-cell *matHeaderCellDef width="100px">Reason Code</th>
      <td mat-cell *matCellDef="let element">
        {{ element.ReasonCode }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
