<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title 
                    >Maintain Product Code Description</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Storer: </mat-label> 
                          <input matInput formControlName="Storer" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label>Product Code: </mat-label> 
                          <input matInput formControlName="ProductCode" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Description: </mat-label> 
                        <input matInput formControlName="Description" />
                      </mat-form-field>
                      <button mat-button class="buttons addButton" (click)="attributes()">Change</button>
                </div>
                        
                            
                              
                          
                        
                </mat-card-content>
              </mat-card>
            </form>  
