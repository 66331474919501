<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                  <mat-card-title style="color: rgb(203, 207, 219)">Delete Item Location</mat-card-title>
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> Storer </mat-label> 
                        <input matInput formControlName="STOR" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label> Item</mat-label> 
                          <input matInput formControlName="ITMDS" />
                        </mat-form-field>
                       
                      <mat-form-field appearance="outline">  <mat-label>Building</mat-label> 
                          <input matInput formControlName="LCBLDG" />
                        </mat-form-field> 
                         
                      <mat-form-field appearance="outline">  <mat-label>Floor</mat-label> 
                        <input matInput formControlName="LCFLOR" />
                      </mat-form-field>
                       
                      <mat-form-field appearance="outline">  <mat-label>Asile</mat-label> 
                        <input matInput formControlName="LCAISL" />
                      </mat-form-field>
                       
                      <mat-form-field appearance="outline">  <mat-label>Slot</mat-label> 
                        <input matInput formControlName="LCSLOT" />
                      </mat-form-field>
                    </div>
                    <div>
                        <p style="color:crimson">Press Enter to confirm Deletion</p>
                        </div>       
                         <div >
                             <button mat-button class="buttonCss" (click)="attributes()">Delete</button>
                          </div>
                  
                        
                </mat-card-content>
              </mat-card>
            </form>  
