<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Work With Item Balances</mat-card-title>
    </mat-card-header>
    <div class="buttons">
      <button mat-button (click)="itemBalanceCreate()" class="addButton">
        <mat-icon>add</mat-icon>
      </button>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
  </div>

  <mat-card-content class="tally-serach-form">
    <form
      [formGroup]="form"
      (ngSubmit)="getItemBalanceBySearch(form.value)"
      class="form over-Flow"
    >
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label>Storer</mat-label>
            <input readonly matInput formControlName="storer" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Item</mat-label>
            <input readonly matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Date</mat-label>
            <input
              matInput
              formControlName="date"
              [matDatepicker]="datePicker"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="datePicker"
            ></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Lot1</mat-label>
            <input matInput formControlName="lot1" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Lot2</mat-label>
            <input matInput formControlName="lot2" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Lot3</mat-label>
            <input matInput formControlName="lot3" />
          </mat-form-field>
        </div>
      </div>
      <!-- <button
        class="buttons addButton"
        mat-raised-button
        type="submit"
        placeholder="Search"
      >
        Search
      </button> -->
    </form>
  </mat-card-content>

  <table
    [dataSource]="dataSource"
    mat-table
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="Lot Code">
      <th mat-header-cell *matHeaderCellDef>Lot Code</th>
      <td mat-cell *matCellDef="let element">{{ element.BLLOTC }}</td>
    </ng-container>

    <ng-container matColumnDef="Date Written">
      <th mat-header-cell *matHeaderCellDef>Date Written</th>
      <td mat-cell *matCellDef="let element">{{ element.BLDATE }}</td>
    </ng-container>

    <ng-container matColumnDef="Type(P/W/D)">
      <th mat-header-cell *matHeaderCellDef>Type(P/W/D)</th>
      <td mat-cell *matCellDef="let element">{{ element.BLTYPE }}</td>
    </ng-container>

    <ng-container matColumnDef="On Hand">
      <th mat-header-cell *matHeaderCellDef>On Hand</th>
      <td mat-cell *matCellDef="let element">{{ element.BLQAVL }}</td>
    </ng-container>

    <ng-container matColumnDef="Allocated">
      <th mat-header-cell *matHeaderCellDef>Allocated</th>
      <td mat-cell *matCellDef="let element">{{ element.BLQALL }}</td>
    </ng-container>
    <ng-container matColumnDef="Committed">
      <th mat-header-cell *matHeaderCellDef>Committed</th>
      <td mat-cell *matCellDef="let element">{{ element.BLQCOM }}</td>
    </ng-container>
    <ng-container matColumnDef="Damaged">
      <th mat-header-cell *matHeaderCellDef>Damaged</th>
      <td mat-cell *matCellDef="let element">{{ element.BLQDAM }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="itemBalanceDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
            <!-- <li>
              <button mat-icon-button (click)="itemBalanceInquiry(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>Inquiry</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemBalanceStgInquiry(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Stg Inquiry</mat-label>
              </button>
            </li> -->
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 12]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
