<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
        Work With Dock Scheduler controls</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>Control:</mat-label>
                      <input matInput formControlName="control"/>
                      </mat-form-field> 
                     </div>
                   
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="control">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Control
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.control }}</td>
        </ng-container>
  
        <ng-container matColumnDef="build">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Build
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.build }}</td>
        </ng-container>
  
        <ng-container matColumnDef="account">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Account
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.account }}
                
            </td>
          </ng-container>
          
        <ng-container matColumnDef="inOut">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                In/Out
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.inOut }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="value">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                value
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.value }}
                
            </td>
          </ng-container>
  
        <ng-container matColumnDef="opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            Opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
                  
                     <mat-option>
                  <button mat-icon-button (click)="ControlsUpdateForm(element)">
                    <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                    <mat-label>UPDATE</mat-label>
                  </button>
                </mat-option>  
              
                  <!-- <mat-option>
                    <button mat-icon-button (click)="PreferencesAddForm(element)">
                      <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                      <mat-label>ADD</mat-label>
                    </button>
                  </mat-option>  -->
                
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
      </table>

