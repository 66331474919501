<mat-card class="over-Flow">

  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title >Consignee Search Attribute Inquiry</mat-card-title>
        
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Consignee </mat-label>
            <input matInput formControlName="Consignee" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Zip </mat-label>
            <input matInput formControlName="Zip" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Street </mat-label>
            <input matInput formControlName="Street" />
          </mat-form-field>
        </div>
      </div>
      <h1>Terms</h1>
      <hr />
      <br /><br />
    </form>
    </mat-card-content>
</mat-card>
