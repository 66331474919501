<!--<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>-->
    
<div class="fluid-container"> 
  <!--      
        
      <mat-card-content> -->
  
  
        <h3>Account Selection</h3><br>
  
  <form [formGroup]="accountSelelctionForm" (ngSubmit)="submit()" class="accountform" 
  style="width: 97% !important;">
  
       
    <!-- <label> Select Building & Storer Number</label> -->
    <!-- <select formControlName="building" placeholder="Select Building & Storer Number">
      <option *ngFor="let opt of element" [value]="opt">
        Building: {{ opt.buildingNumber }} & Storer:
        {{ opt.storerNumber }}
      </option>
  
    </select> -->
    <!-- <label class="form-label select-label"> Select Building & Storer Number</label><br> -->
    <div class="options" id="optionsselect" >
      <span> 
         <mat-form-field appearance="outline">
      <mat-select class="select"  formControlName="building" class="mat-select-value" 
      placeholder="Select Building & Storer Number"> 
    <mat-option value="" disabled selected >Select Building & Storer Number</mat-option>
      <mat-option *ngFor="let opt of element" [value]="opt" class="option" style="padding: 10px 10px 10px 10px !important">
        Building: {{ opt.buildingNumber }} & Storer:
        {{ opt.storerNumber }}
      </mat-option>
    </mat-select>
  </mat-form-field></span>
     </div>
    <div class="formbuttons" style=" align-items: center !important;">
  
    <button mat-raise-button style="background-color:red!important" type="submit"
    class="cancelbutton" (click)="closeDialog()" 
  >Cancel</button>
    <button mat-raise-button  type="submit">Update</button></div>
  </form>  
  <!-- </mat-card-content>   
    style="margin-right: 10px;margin-left: 95px;" 
    style="margin-left: 20px;"-->
     
      </div>