import { GlobalComponent } from './../../Global-Component';
import { DeleteMapzoneComponent } from './delete-mapzone/delete-mapzone.component';
import { UpdateMapzoneComponent } from './update-mapzone/update-mapzone.component';
import { CreateMapzoneComponent } from './create-mapzone/create-mapzone.component';
import { IheaderService } from './../tallys/iheader.service';
import { ImapZoneService } from './imap-zone.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-map-zones',
  templateUrl: './map-zones.component.html',
  styleUrls: ['./map-zones.component.css'],
})
export class MapZonesComponent implements OnInit {
  displayedColumns: string[] = [
    'Building',
    'Zone',
    'Description',
    'Locns',
    'Empty',
    'Items',
    'On',
    'Hand',
    'PctOpen',
    'Actions',
  ];
  @ViewChild('paginator') paginator!: MatPaginator;

  form = this.fb.group({
    zone: [''],
    building: [''],
    description: [''],
    locns: [''],
    empty: [''],
    storerName: [''],
    items: [''],
    on: [''],
    hand: [''],
    pctOpen: [''],
  });
  result: any = [];
  dataSource: any = [];
  duplicateSource: any = [];

  // user = GlobalComponent.user;
  user = localStorage.getItem('userName');
  building = GlobalComponent.building;

  constructor(
    public iMapsvc: ImapZoneService,
    private fb: FormBuilder,
    private matDialog: MatDialog
  ) {}
  TallyHeaders: any = [];
  id: number = 0;
  ngOnInit(): void {
    this.getMapZone();
  }


  searchTextDisplay:any

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }
  
  getMapZone() {
    this.iMapsvc.getAll(this.user).subscribe((data) => {
      this.result = data;
      var res = this.result;

      var res = this.result[0].data[1]?.value;

      var parsedData = JSON.parse(res);

      this.duplicateSource = parsedData.returnedData;

      this.dataSource = new MatTableDataSource(this.duplicateSource);

      this.dataSource.paginator = this.paginator;
    });
  }
  getMapZoneBySerach(mapzone: any) {
    var tal: any = [];
    console.log(mapzone.building, '**building');
    // console.log((tally.tally==null || tally.tally=='') && (tally.storer==null || tally.storer=='') &&
    // (tally.carrier==null || tally.carrier=='') &&
    //   (tally.storerRef==null || tally.storerRef=='') &&
    //   (tally.schdldArrival==null || tally.schdldArrival),'****TEST');

    this.iMapsvc
      .getMapZone(this.user, GlobalComponent.fullBuilding(mapzone.building), mapzone.zone)
      .subscribe((data) => {
        this.result = data;
        this.TallyHeaders = data;
        console.log(this.TallyHeaders);
        var res = this.result[0].data[1]?.value;
        console.log(res);
        var parsedData = JSON.parse(res);
        console.log(parsedData);
        console.log('DaTA');
        // this.dataSource = parsedData.returnedData;
        this.dataSource = new MatTableDataSource(parsedData.returnedData);
        console.log(this.dataSource);
        console.log('DaTA');
        console.log(tal);
        //console.log(this.dataSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  mapZoneCreate() {
    this.matDialog.open(CreateMapzoneComponent, {
      width: '1000px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  updateMapZone(mapzone: any) {
    this.matDialog.open(UpdateMapzoneComponent, {
      width: '1000px',
      maxHeight: '100vh',
      data: mapzone,
      autoFocus: false,
    });
  }

  deleteMapZone(mapzone: any) {
    this.matDialog.open(DeleteMapzoneComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: mapzone,
      autoFocus: false,
    });
  }
}