<mat-card>
  <mat-card-header>
    <mat-card-title>
      <button class="backButton" mat-button (click)="onBack()">
        <mat-icon>navigate_before </mat-icon>
      </button>
      Work With Maint Questions</mat-card-title
    >
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button
        mat-button
        (click)="MaintQuestionsAddForm()"
        class="addButton buttons"
      >
        <mat-icon>add</mat-icon>
        <span>New Maint Question</span>
      </button>
    </div>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Building  Number</mat-label>
            <input matInput formControlName="BLDG" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Equipment  Type</mat-label>
            <input matInput formControlName="EZTYPE" />
          </mat-form-field>
          <button
            class="addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="BuildingNumber">
      <th mat-header-cell *matHeaderCellDef>Building  Number</th>
      <td mat-cell *matCellDef="let element">{{ element.BLDG }}</td>
    </ng-container>
    <ng-container matColumnDef="EquipmentType">
      <th mat-header-cell *matHeaderCellDef>Equipment  Type</th>
      <td mat-cell *matCellDef="let element">{{ element.EZTYPE }}</td>
    </ng-container>
    <ng-container matColumnDef="SequenceNumber">
      <th mat-header-cell *matHeaderCellDef>Sequence  Number</th>
      <td mat-cell *matCellDef="let element">{{ element.EZSEQ }}</td>
    </ng-container>
    <ng-container matColumnDef="MaintenanceQuestion">
      <th mat-header-cell *matHeaderCellDef>Maintenance  Question</th>
      <td mat-cell *matCellDef="let element">{{ element.EZQUES }}</td>
    </ng-container>
    <ng-container matColumnDef="MaintenanceSeverity">
      <th mat-header-cell *matHeaderCellDef>Maintenance  Severity</th>
      <td mat-cell *matCellDef="let element">{{ element.EZSEV }}</td>
    </ng-container>
    <ng-container matColumnDef="MandatoryQuestion">
      <th mat-header-cell *matHeaderCellDef>Mandatory  Question</th>
      <td mat-cell *matCellDef="let element">{{ element.EZMAND }}</td>
    </ng-container>
    <ng-container matColumnDef="StatusCode">
      <th mat-header-cell *matHeaderCellDef>Status  Code</th>
      <td mat-cell *matCellDef="let element">{{ element.EZSTAT }}</td>
    </ng-container>
    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <mat-select placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="MaintQuestionsAddForm()">
              <mat-icon mat-icon-button color="primary">add</mat-icon>
              <mat-label>Add</mat-label>
            </button>
          </mat-option>
          <mat-option>
            <button mat-icon-button (click)="MaintQuestionsUpdateForm(element)">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Change</mat-label>
            </button>
          </mat-option>
          <mat-option>
            <button mat-icon-button (click)="MaintQuestionsDeleteForm(element)">
              <mat-icon mat-icon-button color="primary">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button>
          </mat-option>
          <mat-option>
            <button mat-icon-button (click)="MaintQuestionsCopyForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Copy</mat-label>
            </button>
          </mat-option>
        </mat-select>
      </td></ng-container
    >

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
