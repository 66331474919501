<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Customer </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="addCustomer()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>New Customer</span>
      </button>
    </div>
    <form class="form over-Flow" [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label>Customer No</mat-label>
            <input matInput formControlName="CUST" />
          </mat-form-field>
          <mat-form-field appearance="outline" style="padding-right: 14px">
            <mat-label>Name</mat-label>
            <input matInput formControlName="CUNAME" />
          </mat-form-field>
          <button
            class="addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="CustomerNo">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Customer No
      </th>
      <td mat-cell *matCellDef="let element">{{ element.CUST }}</td>
    </ng-container>

    <ng-container matColumnDef="CustomerName">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Customer Name
      </th>
      <td mat-cell *matCellDef="let element">{{ element.CUNAME }}</td>
    </ng-container>

    <ng-container matColumnDef="Address">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Address
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CUADD1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ZipCode">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Zip Code
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CUZIP }}
      </td>
    </ng-container>

    <ng-container matColumnDef="PhoneNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Phone Number
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CUPHAR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="FaxNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Fax Number
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.CUFXAR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option (click)="getUpdateForm(element)">
            <button mat-icon-button>
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >
          <mat-option (click)="getConsolidateForm(element)">
            <button mat-icon-button>
              <mat-icon mat-icon-button color="primary">consolidate</mat-icon>
              <mat-label>Consolidate</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
          #paginator
          [pageIndex]="0"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator> -->
  <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
<router-outlet></router-outlet>
