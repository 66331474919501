import { AddGeneralInvoiceComponent } from './add-general-invoice/add-general-invoice.component';
import { EditGeneralInvoiceComponent } from './edit-general-invoice/edit-general-invoice.component';
import { DeleteGeneralInvoiceComponent } from './delete-general-invoice/delete-general-invoice.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-general-invoices',
  templateUrl: './general-invoices.component.html',
  styleUrls: ['./general-invoices.component.css'],
})
export class GeneralInvoicesComponent implements OnInit {
  displayedColumns: string[] = [
    'ControlNumber',
    'St',
    'Tp',
    'Customer',
    'InvoiceDate',
    'InvoiceAmount',
    'InvoiceNumber',
    'Actions',
  ];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      ControlNumber: 6,
      St: 'EN',
      Tp: 'GI',
      Customer: '0773 Tony Item Storer',
      InvoiceDate: '08/09/2022',
      InvoiceAmount: '0.00',
      InvoiceNumber: '',
    },
    {
      ControlNumber: 5,
      St: 'EN',
      Tp: 'GI',
      Customer: '0013 Neil Item Storer',
      InvoiceDate: '10/05/2021',
      InvoiceAmount: '32.00',
      InvoiceNumber: 3,
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      Control: [null],
      Customer: [null],
      Dates: [null],
      Invoice: [null],
      RefNo: [null],
      Building: [null],
      Status: [null],
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  invoiceCreate() {
    this.matDialog.open(AddGeneralInvoiceComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  invoiceUpdate() {
    this.matDialog.open(EditGeneralInvoiceComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  invoiceDelete() {
    this.matDialog.open(DeleteGeneralInvoiceComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }
}
