  <!-- <mat-toolbar color="primary">
    <img class="imageCss" src="assets/images/logo.png">
  </mat-toolbar>  
<div fxLayout="row" fxLayoutAlign="center center">
    
    <form [formGroup]="loginForm" novalidate (ngSubmit)="login()">
      <mat-card class="loginSpace">
        <mat-card-header>
          <mat-card-title>Login</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          
              <mat-form-field class="full-width login-mat-form-field" appearance="outline">
                <mat-label>Username</mat-label> 
                <input
                  matInput
                  placeholder="User Name"
                  formControlName="username" oninput="this.value = this.value.toUpperCase();"
                />
                <mat-error
                  *ngIf="loginForm.controls['username'].hasError('required')"
                >
                  Username is <strong>required</strong>
                </mat-error>
              </mat-form-field>
            
              <mat-form-field class="full-width login-mat-form-field"  appearance="outline"  >
                <mat-label>Password</mat-label> 
                
                <input
                  matInput
                  type="password"
                  placeholder="Password"
                  formControlName="password"
                />
                <mat-error
                  *ngIf="loginForm.controls['password'].hasError('required')"
                >
                  Password is <strong>required</strong>
                </mat-error>
              </mat-form-field>
          
        </mat-card-content>
        <mat-card-actions>
          <div fxFlex></div>
          <button mat-raised-button color="primary" type="submit">Next</button>
        </mat-card-actions>
      </mat-card>
    </form>
  </div> -->

  <div class="main-login-page">
    <div class="black-overlay"></div>
    <div class="login-form-sec">
     
      <form [formGroup]="loginForm" novalidate (ngSubmit)="login()">
        <div class="centered-content">
           <a class="login-page-logo" href="" ><img src="assets/images/logo.png" alt=""></a>
       
         <hr class="new1">
         <span><h3>Step 1 - Login to Your Account</h3></span>
      </div>
        <!-- <h2>Login to your account </h2> -->
        <div class="input-group">
          <label>Username</label>
          <input type="email" name="" placeholder="Enter Username" 
            formControlName="username" oninput="this.value = this.value.toUpperCase();" required>
     
          </div>
        <div class="input-group">
          <label>Password</label>
          <!-- <input type="password" name="" placeholder=" Enter Password" 
          formControlName="password" required> 
          
          <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
         -->

         <input type="{{ showPassword ? 'text' : 'password' }}" name="" placeholder="Enter Password" formControlName="password" required>
<span class="fa fa-fw fa-eye field-icon toggle-password" (click)="togglePasswordVisibility()"></span>
        </div>
        <!-- <a class="login-cnt-btn" href="">Continue</a> -->
         <button class="login-cnt-btn" type="submit">Continue</button>
      </form>
    </div>
  </div>
