<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Maintain Schedule Exceptions</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Type of Hours</mat-label>
                    <input matInput formControlName="HETYPE" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Building Number</mat-label>
                    <input  matInput formControlName="HEBLDG" />
                </mat-form-field>
            </div> 

            <div class="col" >
                <mat-form-field appearance="outline">
                    <mat-label>Account Code</mat-label>
                    <input matInput formControlName="HEACCT"  />
                </mat-form-field>

               
            </div>

            <div class="col"> 
                <mat-form-field appearance="outline">
                    <mat-label>Exception Date</mat-label>
                    <input type="date" matInput formControlName="DATE"  />
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>In/Out</mat-label>
                    <input matInput formControlName="INOU"/>
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Include Omit</mat-label>
                    <input matInput formControlName="HEINOM" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Start Time</mat-label>
                    <input type="time" matInput formControlName="HESTRT" />
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>End Time</mat-label>
                    <input type="time" matInput formControlName="HEENDT" />
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="HEDESC" />
                </mat-form-field>
            </div>


        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="change()" class="addButton">           
        <mat-icon mat-icon-button color="primary">create</mat-icon>
        <mat-label>Schedule Exception</mat-label>
    </button>
</mat-card-footer>