import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-item-attributes-create',
  templateUrl: './ww-item-attributes-create.component.html',
  styleUrls: ['./ww-item-attributes-create.component.css']
})
export class WwItemAttributesCreateComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  

  constructor(
    public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<WwItemAttributesCreateComponent>) { }

  ngOnInit(): void {
    this.form = this.fb.group({
  
      IASTOR: '',
      IAITEM:'',
      IANTYP:'',
      IANUMB:'',
   
     
         
      });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
  backstorer(){
    
  }
  attributes(){
    
  }


}
