<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
    <form [formGroup]="form">
        <mat-card>
        <mat-card-header>
            <button mat-button (click)="backstorer()">
                <mat-icon></mat-icon>
                
            </button>
            
            <mat-card-title style="color: rgb(203, 206, 212)"
            >Delete a Line from a Tally</mat-card-title>
            
        </mat-card-header>

        <mat-card-content>
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Tally: </mat-label> 
                    <input matInput formControlName="IDTALY" />
                  </mat-form-field>
    
                  <mat-form-field appearance="outline">  <mat-label> Line Number:</mat-label> 
                      <input matInput formControlName="IDSEQ" />
                    </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Storer:</mat-label> 
                    <input matInput formControlName="IDSTOR" />
                  </mat-form-field>           
           </div>

           <div class="col">
            <mat-form-field appearance="outline">  <mat-label> Item:</mat-label> 
                <input matInput formControlName="IDITEM" />
              </mat-form-field>           
       </div>

       <div class="col">
        <mat-form-field appearance="outline">  <mat-label> Lots:</mat-label> 
            <input matInput formControlName="IDLOT" />
          </mat-form-field>           
   </div>
   <div class="col">
    <mat-form-field appearance="outline">  <mat-label> Received:</mat-label> 
        <input matInput formControlName="IDQREC" />
      </mat-form-field> 
      
      <mat-form-field appearance="outline">  <mat-label> Intransit:</mat-label> 
        <input matInput formControlName="IDQINT" />
      </mat-form-field>
</div>
<div >
    <button mat-button class="buttonCss" (click)="attributes()">Delete</button>
</div>

</mat-card-content>
</mat-card>
</form> 
