import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { PeriodsService } from '../periods.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';

@Component({
  selector: 'app-period-delete',
  templateUrl: './period-delete.component.html',
  styleUrls: ['./period-delete.component.css'],
})
export class PeriodDeleteComponent implements OnInit {
  form!: FormGroup;

  constructor(
    public iHeaderSvc: IheaderService,
    public periodsService: PeriodsService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private router: Router,
    private CustomAlertService: CustomAlertService,

    private dialogRef: MatDialogRef<PeriodDeleteComponent>
  ) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  ngOnInit(): void {
    this.form = this.fb.group({
      PESTOR: this.anyvariable.PESTOR,
      STDT: this.anyvariable.PESTDT,
      ENDT: this.anyvariable.PEENDT,
      PEBALN: this.anyvariable.PEBALN,
      PEINV: this.anyvariable.PEINV,
      PESTAT: this.anyvariable.PESTAT,
    });
  }
  user = localStorage.getItem('userName');
  result: any;
  duplicateSource: any = [];
  dataSource: any = [];

  delete() {
    var res = this.form.value;
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure you want to delete this period ?'+this.anyvariable.PESTDT);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result){
      console.log(res);
      this.periodsService
        .deletePeriod(res.PESTOR, this.user, res.STDT)
        .subscribe((data) => {
          this.result = data;
          var res = this.result[0].data[1]?.value;
          console.log(res);
          if (JSON.parse(this.result[0].data[1].value).success) {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.successMessage(msg);
            this.closeDialog();
            this.router.navigate(['/main-nav/storer']);
          } else {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.failureMessage(msg);
          }
        });
    }
  });
}
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/storer']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
