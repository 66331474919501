<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Tally </mat-card-title>
    <div class="buttons">
      <!-- <button mat-button (click)="onBack()" class="backButton">
  <mat-icon>navigate_before </mat-icon>
  <span>Modules</span>
</button> -->
      <button mat-button (click)="searchTally()" class="addButton" *ngIf="searchTextDisplay">
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>
      <!-- <button class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn">
        <li class="dropdown">
          <a >
            Hide columns
          </a>
        Start of Dropdown
          <ul class="dropdown-nav">
            <form>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                <label class="form-check-label" for="flexCheckDefault">
                  Tally Number
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" >
                <label class="form-check-label" for="flexCheckChecked">
                  Invoice
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" >
                <label class="form-check-label" for="flexCheckChecked">
                  Storer
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" >
                <label class="form-check-label" for="flexCheckChecked">
                  Scheduled Arrival
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" >
                <label class="form-check-label" for="flexCheckChecked">
                  Actual Arrival
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" >
                <label class="form-check-label" for="flexCheckChecked">
                  Storer Ref No	
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" >
                <label class="form-check-label" for="flexCheckChecked">
                  Pro No
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" >
                <label class="form-check-label" for="flexCheckChecked">
                  Carrier
                </label>
              </div>
              
            </form>
          </ul>
        </li>
      </button> -->

      <mat-menu #mainMenuButton="matMenu">
        <span (click)="$event.stopPropagation()" *ngFor="let column of columnShowHideList; index as i">
          <mat-slide-toggle style="color: blue" [(ngModel)]="column.isActive" (change)="toggleColumn(column)">
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button mat-button (click)="searchTally()" class="addButton" *ngIf="!searchTextDisplay" matTooltip="Search Tally">
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>

      <button mat-button (click)="addTally()" class="addButton" matTooltip="Add New Tally">
        <mat-icon>add</mat-icon>
        <!-- <span>New Tally</span> -->
      </button>

      <button mat-button matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn" #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton">
        <mat-icon>visibility_off</mat-icon>
      </button>
    </div>
  </mat-card-header>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="updateForm" (ngSubmit)="getTallyBySearch2(updateForm.value)" class="form over-Flow"
      *ngIf="searchTextDisplay">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Tally</mat-label>
            <input matInput formControlName="tally" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Ref#:</mat-label>
            <input matInput formControlName="storerRef" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Schd Arv:</mat-label>
            <input matInput formControlName="schdldArrival" [matDatepicker]="schdldArrivalDatePicker" />
            <mat-datepicker-toggle matSuffix [for]="schdldArrivalDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #schdldArrivalDatePicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Invoice</mat-label>
            <input matInput formControlName="invoice" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Carrier </mat-label>
            <input matInput formControlName="carrier" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Building </mat-label>

            <input matInput formControlName="building" readonly/>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Storer </mat-label>
            <input matInput formControlName="storer" [readonly]="this.checkStorer(this.storer1)"
              (change)="onChangeEvent($event)" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Consignee </mat-label>
            <input matInput formControlName="recvdFrom" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Status </mat-label>
            <mat-select formControlName="status">
              <mat-option value="I"> Intransit </mat-option>
              <mat-option value="A"> Arrived (at dock) </mat-option>
              <mat-option value="P"> Put Away </mat-option>
              <mat-option value="S"> Submitted for W/R print </mat-option>
              <mat-option value="W"> W/R printed </mat-option>
              <mat-option value="D"> Deleted </mat-option>
              <mat-option value="C"> Cancelled </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> PRO Number </mat-label>
            <input matInput formControlName="pro" />
          </mat-form-field>
        </div>
      </div>
      <button *ngIf="searchTextDisplay" class="buttons addButton tally-search-btn" mat-raised-button type="submit"
        placeholder="Search">
        Search
      </button>
    </form>
  </mat-card-content>

  <!-- <button style="float:right" class="btn btn-sm btn-primary top-margin" #menuTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="mainMenuButton">
                        hide/show
                    </button>
                    <mat-menu #mainMenuButton="matMenu">
                        <span (click)="$event.stopPropagation()" *ngFor="let column of columnShowHideList;index as i">
                            <mat-slide-toggle [(ngModel)]="column.isActive" (change)="toggleColumn(column)">
                            </mat-slide-toggle>
                            {{column.name}}
                            <br>
                        </span>
                    </mat-menu> -->

  <table mat-table [dataSource]="dataSource" id="myTable"
  class="mat-elevation-z8 tally-table">
    <!-- Tally Column -->
    <ng-container matColumnDef="Status">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.IHRCPS }}</td>
    </ng-container>
    <ng-container matColumnDef="Tally">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Tally Number</th>
      <td mat-cell *matCellDef="let element">{{ element.IHTALY }}</td>
    </ng-container>

    <ng-container matColumnDef="Invoice">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Invoice</th>
      <td mat-cell *matCellDef="let element">{{ element.IHINVO }}</td>
    </ng-container>

    <ng-container matColumnDef="Storer">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Storer</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTOR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ScheduleArrival">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Scheduled Arrival
      </th>
      <td mat-cell *matCellDef="let element">
        {{ (element.IHSCHA) | date: this.GlobalComponent.DATE_FORMAT}}
      </td>
    </ng-container>

    <ng-container matColumnDef="ActualArrival">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Actual Arrival</th>
      <td mat-cell *matCellDef="let element">
        {{ (element.IHACTA) | date: this.GlobalComponent.DATE_FORMAT }}
      </td>
    </ng-container>

    <ng-container matColumnDef="StorerRefNo.">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Storer Ref No</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTRF | removenull}}
      </td>
    </ng-container>

    <ng-container matColumnDef="ProNo.">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Pro No</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHPRO }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Carrier">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Carrier</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHCARR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef>
        <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button class="dropdown-item" (click)="getUpdateForm(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="delete(element['IHTALY'])" class="cancelbutton">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="comment(element)">
                <mat-icon mat-icon-button color="secondary">comments</mat-icon>
                <mat-label>Comments</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="tallyPosting(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>Tally Posting Errors</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="tallyDetails(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>Tally Details</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="wrInquiry(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>WR Inquiry</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="tallyEvent(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>Tally Event</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="deReceive(element)">
                <mat-icon mat-icon-button color="secondary">call_made</mat-icon>
                <mat-label>De Receive</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>
     <!-- <button (click)="openConfirmationDialog()" type="button"
     class="btn btn-primary">Open dialog</button>   -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [class.selected]="selectedRow == row.id"
      (click)="rowClick(row.id)"></tr>

    <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns; let i = index;"
        [class.selected]="selectedRow == row.id" (click)="rowClick(row.id)">
    </mat-row> -->
  </table>
  <!-- <mat-paginator
      #paginator
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]"
    >
    </mat-paginator> -->
  <mat-paginator #paginator [pageSizeOptions]="[8, 16, 24]" showFirstLastButtons
    class="tally-table-pagination"></mat-paginator>
</mat-card>
