<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>System Comments Maintenance</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
    <mat-card-content>
        <form [formGroup]="form" fxLayoutAlign="center">
           
                <div class="col">
                    <mat-form-field  appearance="outline">
                        <mat-label> File ID   </mat-label> 
                        <input readonly matInput formControlName="fileId" />
                    </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Key  </mat-label> 
                        <input readonly matInput formControlName="key"/>
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Sub Code</mat-label>
                        <mat-select formControlName="subCode">
                          <mat-option value="BL">BL</mat-option>
                          <mat-option value="PS">PS</mat-option>
                          <mat-option value="CC">CC</mat-option>
                        </mat-select>
                      </mat-form-field>

                    <mat-form-field  appearance="outline">
                        <mat-label> Sequence  </mat-label> 
                        <input matInput formControlName="sequence"/>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="full-width w-100">
                        <mat-label>Text :</mat-label>
                        <textarea matInput
                        formControlName="text"> Comments</textarea>
                    </mat-form-field>              
                
                <button mat-button (click)="updateItemComment()" class="buttons addButton">                        
                    <span>Update</span>
                </button>
            
            </div>                    
        </form>
    </mat-card-content>
</mat-card>
