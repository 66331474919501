<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Orders Update</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">  

          
            <mat-form-field  appearance="outline"><mat-label> BOL Number: </mat-label> 
              <input matInput formControlName="bolNumber" />
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Order Status: </mat-label> 
              <input
                matInput
                formControlName="orderStatus"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Storer Number: </mat-label>
              <input
                matInput
                formControlName="storer"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Suffix: </mat-label> 
              <input
                matInput
                formControlName="suffix"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
          
            <mat-form-field appearance="outline">  <mat-label> Storer Reference: </mat-label>
              <input
                matInput
                formControlName="storerRef"
                style="text-transform: uppercase"
              />            
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Purchase Order: </mat-label> 
              <input
                matInput
                formControlName="purchaseOrder"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

         
            <mat-form-field appearance="outline">  <mat-label> Consignee : </mat-label>
              <input
                matInput
                formControlName="consignee"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Consignee Name: </mat-label>
              <input
                matInput
                formControlName="consigneeName" value="{{consName}}"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          <div style="margin-left:20px"><b>Address</b></div>

          
            <mat-form-field appearance="outline">  <mat-label> Address 1: </mat-label> 
              <input
                matInput
                formControlName="address1" value="{{consAdrs1}}"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Address 2: </mat-label> 
              <input
                matInput
                formControlName="address2" value="{{consAdrs2}}"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Address 3: </mat-label> 
              <input
                matInput
                formControlName="address3" value="{{consAdrs3}}"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          

          
            <mat-form-field appearance="outline">  <mat-label> Address 4: </mat-label> 
              <input
                matInput
                formControlName="address4" value="{{consAdrs4}}"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          
            <mat-form-field appearance="outline">  <mat-label> Address 5: </mat-label> 
              <input
                matInput
                formControlName="address5"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="btn-text-right">
          <button mat-button class="button addButton"(click)="orderUpdate1()">Next</button>
        </div>
      </form>
    </mat-card-content>
 </mat-card>
