import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-details-group',
  templateUrl: './add-details-group.component.html',
  styleUrls: ['./add-details-group.component.css'],
})
export class AddDetailsGroupComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddDetailsGroupComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      LUNAME: '',
      LUOPER: '',
      LUPRIO: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  add() {
    console.log('added');
  }
}
