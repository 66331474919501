<!-- Form no: 211 -->
<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Work With Storer Rate Change</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
     <form [formGroup]="form">
        <div class="row"  >
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Storer_Number</mat-label>
                   <input matInput formControlName="Storer_Number"/>
               </mat-form-field>
               <mat-form-field appearance="outline">
                <mat-label>Sch_EffectiveDate</mat-label>
                <input matInput formControlName="Sch_EffectiveDate"/>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Status</mat-label>
                <input matInput formControlName="Status"/>
            </mat-form-field>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Item</mat-label>
                    <input matInput formControlName="Item"/>
                </mat-form-field>
            </div>
          </div>
        </div>     
      </form>
    
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
       <ng-container matColumnDef="Item">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Item
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Item}}</td>
         </ng-container>
         <ng-container matColumnDef="CM">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            CM
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.CM}}</td>
         </ng-container>
         <ng-container matColumnDef="RateType">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            RateType
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.RateType}}</td>
         </ng-container>
         <ng-container matColumnDef="ChangeType">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            ChangeType
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.ChangeType}}</td>
         </ng-container>
         <ng-container matColumnDef="Amount">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Amount
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Amount}}</td>
         </ng-container>
         <ng-container matColumnDef="BS">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            BS
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.BS}}</td>
         </ng-container>
         <ng-container matColumnDef="Sch_Eff_Date">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Sch_Eff_Date
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Sch_Eff_Date}}</td>
          </ng-container>
          <ng-container matColumnDef="St">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                St
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.St}}</td>
          </ng-container>
          <ng-container matColumnDef="Opt">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Opt
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Opt}}</td>
          </ng-container>
 

       
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
    </mat-card-content>
</mat-card>