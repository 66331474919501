import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-general-invoice',
  templateUrl: './delete-general-invoice.component.html',
  styleUrls: ['./delete-general-invoice.component.css'],
})
export class DeleteGeneralInvoiceComponent implements OnInit {
  form!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteGeneralInvoiceComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      ControlNumber: 6,
      Building: 901,
      InvoiceDate: '08/09/2022',
      Amount: 0.0,
      InvoiceType: 'GI',
      Customer: '0773 Tony Item',
      ReferenceNo: [null],
      CarTrailer: [null],
      Description: [null],
    });
  }
  delete() {
    alert('Deleted');
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
