import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-attribute-consignee',
  templateUrl: './attribute-consignee.component.html',
  styleUrls: ['./attribute-consignee.component.css'],
})
export class AttributeConsigneeComponent implements OnInit {
  displayedColumns: string[] = [
    'Attribute',
    'Value',
    'LastChanged',
    'User',
    'St',
  ];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      Attribute: [null],
      Value: [null],
      LastChanged: [null],
      User: [null],
      St: [null],
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<AttributeConsigneeComponent>
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      FileID: [null],
      Key: [null],
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  @ViewChild('paginator') paginator!: MatPaginator;
  workHeaders: any = [];

  reasonCode() {
    console.log('Reason Code');
  }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
