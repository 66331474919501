<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Write Balance Records for a Storer</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
      <div class="row">
        <div class="col">
          
          <mat-form-field appearance="outline"
            ><mat-label> Storer Number </mat-label>
            <input matInput formControlName="StorerNumber" readonly />
          </mat-form-field>
        </div>
      </div>
      
      <p style="margin-left: 20px;">This process will write a balance record for each item/lot of this storer. The balances are computed from the activity.  Enter the date and type of balance record to write.</p>
      <div class="row">
        <div class="col">

          <mat-form-field appearance="outline">
            <mat-label> Balance Date </mat-label>

            <input matInput type="date" formControlName="BalanceDate" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Record Type </mat-label>

            <mat-select matNativeControl formControlName="RecordType" required>
              <mat-option value="P">P = Period</mat-option>
              <mat-option value="D">D = Daily</mat-option>
              <mat-option value="W">W = Weekly</mat-option>
            </mat-select>
          </mat-form-field>
        
        </div>
        <div>
          <button
            class="buttons addButton"
            mat-raised-button
            (click)="writeBalanceRecord()"
          >
            Update
          </button>
        </div>
      </div>
      </form>
    </mat-card-content>


</mat-card>
