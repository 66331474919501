import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-work-schedule-create',
  templateUrl: './work-schedule-create.component.html',
  styleUrls: ['./work-schedule-create.component.css']
})
export class WorkScheduleCreateComponent implements OnInit {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<WorkScheduleCreateComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      typeOfHours: [null],
      buildingNumber: '10',
      AccountCode:[null],
      dayOfTheWeek:[null],
      inOrOut:[null],
      startTime:[null],
      endTime:[null]
      


    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  add() {
    console.log('added');
  }


}
