<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>Hazard Materials Group</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>HazardMatGroup</mat-label> 
                    <input matInput formControlName="HazardMatGroup"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> ProperName </mat-label> 
                    <input matInput formControlName="ProperName"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Class </mat-label> 
                    <input matInput formControlName="Class"/>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>IdNo</mat-label> 
                    <input matInput formControlName="IdNo"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> PackingGroup </mat-label> 
                    <input matInput formControlName="PackingGroup"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>SpecialRequire </mat-label> 
                    <input matInput formControlName="SpecialRequire"/>
                </mat-form-field>

            </div> 
            <div class="col">
                <button mat-button (click)="changeHazardMaterials()" class="addButton">
                    
                    <span>Change</span>
                  </button>
            </div>
        </div>

    </form>
</mat-card-content>
