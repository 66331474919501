<!-- EDI _Info(220) -->
<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain EDI Information</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>  
      
    <mat-card-content class="tally-serach-form"> 
        <form [formGroup]="form">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline"><mat-label> Storer : </mat-label> 
                  <input matInput formControlName="Storer" />
                </mat-form-field>
          
            <mat-form-field  appearance="outline"><mat-label> WiNS_CommsID : </mat-label> 
                <input matInput formControlName="WiNS_CommsID" />
            </mat-form-field>
         
            <mat-form-field appearance="outline">  <mat-label> DUNS_Number: </mat-label> 
                <input matInput formControlName="DUNS_Number" />
            </mat-form-field>
                
            <mat-form-field appearance="outline">  <mat-label>     CustomEDI_CommsID:'',: </mat-label> 
                <input matInput formControlName="    CustomEDI_CommsID:''," />
            </mat-form-field>
            
            <mat-form-field appearance="outline">  <mat-label> CHEPPallet_User  </mat-label> 
        
            <mat-select matNativeControl required>
            <mat-option value="N">N</mat-option>
            <mat-option value="Y">Y</mat-option>
            </mat-select>
                <input matInput formControlName="CHEPPallet_User" />
            </mat-form-field>
    
            <mat-form-field appearance="outline">  <mat-label> ASN_Required: </mat-label> 
        
                    <input matInput
                      formControlName="ASN_Required"
                      style="text-transform: uppercase" />
            </mat-form-field>

              
            <button mat-button class="buttons addButton" (click)="information()">Information</button>
            
          </div>        
        </div>
        </form>      
    </mat-card-content>

</mat-card> 


