<mat-card>
  
    <mat-card-header>
      <mat-card-title>Work With Items</mat-card-title>
      <div class="buttons">

        <button
    mat-button
    (click)="searchTally()"
    class="addButton"
    *ngIf="searchTextDisplay"
  >
    <mat-icon>clear</mat-icon>
    <!-- <span>Hide Search Details</span> -->
  </button>

  <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay" matTooltip="Search Tally"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>
        
        <button mat-button (click)="itemCreate()" class="addButton">
          <mat-icon>add</mat-icon>
          <!-- <span>Create Item</span> -->                                       
        </button>
        <mat-menu #mainMenuButton="matMenu">
          <span
            (click)="$event.stopPropagation()"
            *ngFor="let column of columnShowHideList; index as i"
          >
            <mat-slide-toggle
              style="color: blue"
              [(ngModel)]="column.isActive"
              (change)="toggleColumn(column)"
            >
              {{ column.name }}
            </mat-slide-toggle>
  
            <br />
          </span>
        </mat-menu>
  
        <button
          mat-button
          matTooltip="Hide/Show Columns"
          class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn"
          #menuTrigger="matMenuTrigger"
          [matMenuTriggerFor]="mainMenuButton"
        >
          <mat-icon>visibility_off</mat-icon>
        </button>
  
      </div>
    </mat-card-header>
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" (ngSubmit)="getItemBySearch(form.value)" *ngIf="searchTextDisplay" class="form over-Flow">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline"
            ><mat-label>Storer</mat-label>
            <input matInput formControlName="storer" />
          </mat-form-field>

            <mat-form-field appearance="outline"
                ><mat-label>Item Code</mat-label>
                <input matInput formControlName="itemCode" />
              </mat-form-field>

            
              <mat-form-field appearance="outline">
                <mat-label>Only Active </mat-label>
                <mat-select formControlName="onlyActive">
                  <mat-option value="Y">Yes</mat-option>
                  <mat-option value="N">No</mat-option>
                </mat-select>
              </mat-form-field>

            <mat-form-field appearance="outline"
                ><mat-label>Product</mat-label>
                <input matInput maxlength="1" formControlName="product" />
              </mat-form-field>

            <mat-form-field appearance="outline"
                ><mat-label>Description</mat-label>
                <input matInput  formControlName="description" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> Inventory </mat-label>
                <mat-select formControlName="inventory">
                  <mat-option value="Y">Yes</mat-option>
                  <mat-option value="N">No</mat-option>
                </mat-select>
              </mat-form-field>
              <button class="buttons addButton" mat-raised-button type="submit" placeholder="Search">Search</button>
          </div>
        </div>
        
    </form>
    </mat-card-content>
 

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="AD">
      <th mat-header-cell *matHeaderCellDef>AD</th>
      <td mat-cell *matCellDef="let element">{{ element.ITSTOR }}</td>
    </ng-container>

    <ng-container matColumnDef="ItemCode">
      <th mat-header-cell *matHeaderCellDef>Item Code</th>
      <td mat-cell *matCellDef="let element">{{ element.ITEM }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.ITDSC1 }}</td>
    </ng-container>

    <ng-container matColumnDef="Lot">
      <th mat-header-cell *matHeaderCellDef>Lot</th>
      <td mat-cell *matCellDef="let element">{{ element.ITLOTT }}</td>
    </ng-container>

    <ng-container matColumnDef="Bld">
      <th mat-header-cell *matHeaderCellDef>Building</th>
      <td mat-cell *matCellDef="let element">{{ element.ITBLDG }}</td>
    </ng-container>

    <ng-container matColumnDef="UPC">
      <th mat-header-cell *matHeaderCellDef>UPC</th>
      <td mat-cell *matCellDef="let element">{{ element.ITLOTT }}</td>
    </ng-container>

    <ng-container matColumnDef="Tail">
      <th mat-header-cell *matHeaderCellDef>Tail</th>
      <td mat-cell *matCellDef="let element">{{ element.ITAISL }}</td>
    </ng-container>

    <ng-container matColumnDef="LotUnitWeight">
      <th mat-header-cell *matHeaderCellDef>Lot Unit Weight</th>
      <td mat-cell *matCellDef="let element">{{ element.ITLUWT }}</td>
    </ng-container>

    <ng-container matColumnDef="X-Reference">
      <th mat-header-cell *matHeaderCellDef>X-Reference</th>
      <td mat-cell *matCellDef="let element">{{ element.ITXREF }}</td>
    </ng-container>vvvv

    <ng-container matColumnDef="Allocated">
      <th mat-header-cell *matHeaderCellDef>Allocated</th>
      <td mat-cell *matCellDef="let element">{{ element.ITCALL }}</td>
    </ng-container>

    <ng-container matColumnDef="Available">
      <th mat-header-cell *matHeaderCellDef>Available</th>
      <td mat-cell *matCellDef="let element">{{ element.ITCAVL }}</td>
    </ng-container>

    <ng-container matColumnDef="Committed">
      <th mat-header-cell *matHeaderCellDef>Committed</th>
      <td mat-cell *matCellDef="let element">{{ element.ITCCOM }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="250px" *matHeaderCellDef>
        <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="itemUpdate(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemComments(element)">
                <mat-icon mat-icon-button color="primary">message</mat-icon>
                <mat-label>Comments</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemBalance(element)">
                <mat-icon mat-icon-button color="primary">money</mat-icon>
                <mat-label>Balance</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemCrossRef(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>X-Reference</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemLots(element)">
                <mat-icon mat-icon-button color="primary">store</mat-icon>
                <mat-label>Item Lots</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="StockEnquiry(element)">
                <mat-icon mat-icon-button color="primary">store</mat-icon>
                <mat-label>Stock Enquiry</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemTally(element)">
                <mat-icon mat-icon-button color="primary">store</mat-icon>
                <mat-label>Item Tally Work With</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemAdjustment(element)">
                <mat-icon mat-icon-button color="primary">store</mat-icon>
                <mat-label>Item Adjustment</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemInventoryAdjustment(element)">
                <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                <mat-label>Inventory Adjustment</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="itemLocation(element)">
                <mat-icon mat-icon-button color="primary">place</mat-icon>
                <mat-label>Item Location</mat-label>
              </button>
            </li>

            <li>
              <button mat-icon-button (click)="itemStatus(element)">
                <mat-icon mat-icon-button color="primary">place</mat-icon>
                <mat-label>Item Status</mat-label>
              </button>
            </li>

          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 12]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
