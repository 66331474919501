<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
          <mat-card-title fxLayoutAlign="center">Work with Work Assignments</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" >
          <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Operator</mat-label>
                    <input matInput formControlName="Operator" />
                </mat-form-field>
            </div>    
          </div>
      </form>
    </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="Operator">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Operator
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Operator }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Seq">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Seq
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Seq }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Prty">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Prty
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Prty }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Type">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Type
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Type }}</td>
        </ng-container>

        <ng-container matColumnDef="Sub Type">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Sub Type
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.SubType }}</td>
          </ng-container>

          <ng-container matColumnDef="I">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
              I
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.I }}</td>
          </ng-container>

          <ng-container matColumnDef="Shipment Type">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Shipment Type
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.ShipmentType }}</td>
          </ng-container>

          <ng-container matColumnDef="Bldg">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Bldg
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Bldg }}</td>
          </ng-container>

          <ng-container matColumnDef="Zone">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Zone
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Zone }}</td>
          </ng-container>

          <ng-container matColumnDef="Aisle">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Aisle
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Aisle }}</td>
          </ng-container>

          <ng-container matColumnDef="Slot">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Slot
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Slot }}</td>
          </ng-container>
  
        <ng-container matColumnDef="Status">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Status
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">

          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" 
          showFirstLastButtons class="tally-table-pagination"
      ></mat-paginator>


</mat-card>



