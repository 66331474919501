import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalComponent } from 'src/app/Global-Component';
import { IheaderService } from '../../tallys/iheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorerUpdate3Component } from '../storer-update3/storer-update3.component';
import Swal from 'sweetalert2';
import { StorerServiceService } from '../storer-service.service';

@Component({
  selector: 'app-storer-update2',
  templateUrl: './storer-update2.component.html',
  styleUrls: ['./storer-update2.component.css']
})
export class StorerUpdate2Component implements OnInit {

  constructor(
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerUpdate2Component>,
   private matDialog: MatDialog
    ) { }

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName')  

    ngOnInit(): void {

      console.log(this.anyvariable.storerNumber);
         this.form = this.fb.group({
  
          storeNumber: this.anyvariable.STORE,
          suffix:this.anyvariable.STSFX,
          storeName:this.anyvariable.STNAME,
          user1:this.anyvariable.STUSR1,
          user2:this.anyvariable.STUSR2,
          user3:this.anyvariable.STUSR3,
          user4:this.anyvariable.STUSR4,
          allItemsLotted:this.anyvariable.STLOTF,
          lotCodeLabel1:[this.anyvariable.STLCL1,[
            
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
          ],],
          lotCodeLabel2:[this.anyvariable.STLCL2,[
            
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
          ],],
          lotCodeLabel3:[this.anyvariable.STLCL3,[
            
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
          ],],
          primaryMask:this.anyvariable.STSRMF,
          sortMask1:this.anyvariable.STSRMK,
          sortMask2:this.anyvariable.STLLS2,
          sortMask3:this.anyvariable.STLLS3,
  
         });
    }
  
    storerUpdate2(){
      var res = this.form.value;     
      this.result = this.storerSvc
        .storerServiceUpdate2( 
         res.storeNumber,
         res.suffix,
         res.storeName,
         res.user1,
         res.user2,
         res.user3,
         res.user4,
         res.allItemsLotted,
         res.lotCodeLabel1,
         res.lotCodeLabel2,
         res.lotCodeLabel3,
         res.primaryMask,
         res.sortMask1,
         res.sortMask2,
         res.sortMask3,
         this.user
  
          ).subscribe((data: any) => {
          this.result = data;
            if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Created/Updated'){
           
           const msg= this.result[0].data[this.result[0].data.length-1].value;
            this.successMessage(msg)
            this.closeDialog();
            this.update3();
         }else{
          this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
         }
        });
    }
  
    successMessage(msg:any){
      Swal.fire(msg,'','success');
      //this.router.navigate(['/main-nav/storer/read']);
    }
  
    failureMessage(msg:any){
      Swal.fire(msg,'','error');
     // this.router.navigate(['/main-nav/storer/read']);
    }

  update3() {
    var res = this.anyvariable;
    this.matDialog.open(StorerUpdate3Component, {
      
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }


}
