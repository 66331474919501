<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Stock Inquiry</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" class="form over-Flow">
          <div class="row">
           <div class="col">
              <mat-form-field appearance="outline">
                  <mat-label>Storer</mat-label>
                  <input matInput formControlName="Storer" readonly/>
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>In_Transit</mat-label>
                <input matInput formControlName="In_Transit" readonly/>
              </mat-form-field>
                      
              <mat-form-field appearance="outline">
                <mat-label>Item</mat-label>
                <input matInput formControlName="Item" readonly/>
              </mat-form-field>
              
              <mat-form-field appearance="outline">
                <mat-label>Future_Allocated</mat-label>
                <input matInput formControlName="Future_Allocated" readonly/>
              </mat-form-field>
        
              <mat-form-field appearance="outline">
                <mat-label>UPC</mat-label>
                <input matInput formControlName="UPC" readonly/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Lot1</mat-label>
                <input matInput formControlName="Lot1"/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Lot2</mat-label>
                <input matInput formControlName="Lot2"/>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Lot3</mat-label>
                <input matInput formControlName="Lot3"/>
              </mat-form-field>
            
              <mat-form-field appearance="outline">
                <mat-label>Location</mat-label>
                <input matInput formControlName="Location"/>
              </mat-form-field>
        
            </div>
          </div>     
          <div>
            <button
              mat-button
              class="buttons addButton"
            >
              Search
            </button>
          </div>
      </form>
    </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
       <ng-container matColumnDef="Location">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Location
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Location}}</td>
         </ng-container>
         <ng-container matColumnDef="F">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            F
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.F}}</td>
         </ng-container>
         <ng-container matColumnDef="Lot_Codes">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Lot_Codes
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Lot_Codes}}</td>
         </ng-container>
         <ng-container matColumnDef="LotReceived">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Lot Received
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.LotReceived}}</td>
         </ng-container>
         <ng-container matColumnDef="Actual">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actual
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Actual}}</td>
         </ng-container>
         <ng-container matColumnDef="Avail">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Avail
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Avail}}</td>
         </ng-container>

         <ng-container matColumnDef="Commit">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Commit
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Commit}}</td>
          </ng-container>

         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator
        #paginator
        [pageSizeOptions]="[4, 8, 16]"
        showFirstLastButtons
      ></mat-paginator>
</mat-card>
