import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { EquipmentoperatorService } from '../../equipmentoperator.service';
import * as moment from 'moment';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
// import { CustomAlertService } from '../custom-alert/custom-alert.service';
@Component({
  selector: 'app-delete-equipment-operators',
  templateUrl: './delete-equipment-operators.component.html',
  styleUrls: ['./delete-equipment-operators.component.css'],
})
export class DeleteEquipmentOperatorsComponent implements OnInit {
  form!: FormGroup;

  constructor(
    public eopSvc: EquipmentoperatorService,
    private fb: FormBuilder,
    private router: Router,
    public CustomAlertService: CustomAlertService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<DeleteEquipmentOperatorsComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      UserId: this.anyvariable.EOUSER,
      Name: this.anyvariable.EONAME,
    });
  }

  result: any;
  user = localStorage.getItem('userName');

  deleteEquipmentOperator() {
    for (var i = this.anyvariable.EOBLDG.length; i < 3; i++) {
      this.anyvariable.EOBLDG = ' ' + this.anyvariable.EOBLDG;
    }
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete Equipment Operator:' +this.anyvariable.EOUSER +'?');
  
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
          this.result = this.eopSvc
            .deleteEquipmentOperator(
              this.anyvariable.EOUSER,
              this.anyvariable.EOSTOR,
              this.anyvariable.EOSSFX,
              this.anyvariable.EOBLDG,
              this.user
            )
            .subscribe((data: any) => {
              this.result = data;
              //  var msg = JSON.parse(this.result).returnedData.message;
              console.log(this.result);

              if (JSON.parse(this.result[0].data[1].value).success) {
                //alert('Record Created SuccessFully');
                const msg = JSON.parse(this.result[0].data[1].value).returnedData
                  .message;
                this.successMessage(msg);
                this.closeDialog();
                this.router.navigate(['/main-nav/equipmentOperators/read']);
              } else {
                const msg = JSON.parse(this.result[0].data[1].value).returnedData
                  .message;
                this.failureMessage(msg);
              }

              // this.successMessage(msg);
            });
        
          }
        });
      } 

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/equipmentOperators/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    // this.router.navigate(['/main-nav/tallys/read']);
  }
  
  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
