<mat-card>

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title>Maintain Reasonable Values</mat-card-title>&nbsp;
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
            <div class="col">
              <div class="col">
                
                <mat-form-field  appearance="outline">
                    <mat-label> Storer: </mat-label> 
                    <input readonly  matInput formControlName="storeNumber" style="text-transform: uppercase"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                  <mat-label> Suffix: </mat-label> 
                  <input readonly matInput formControlName="suffix" style="text-transform: uppercase"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                  <mat-label> Store Name: </mat-label> 
                  <input readonly matInput formControlName="storeName" style="text-transform: uppercase"/>
                </mat-form-field>

            
                
                <table style="margin-left: 20px">
                    <tr>
                        <th></th>
                        <th style="text-align: center;">Weight</th>
                        <th style="text-align: center;">Cubes</th>
                    </tr>
                    <tr>
                        <td>Car Load Inbound</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="clInboundWeight"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="clInboundCube"/>
                            </mat-form-field>
                        </td> 
                    </tr>

                    <tr>
                        <td>Car Load Outbound</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="clOutboundWeight"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="clOutboundCube"/>
                            </mat-form-field>
                        </td> 
                    </tr>

                    <tr>
                        <td>Truck Load Inbound</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="tlInboundWeight"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="tlInboundCube"/>
                            </mat-form-field>
                        </td> 
                    </tr>

                    <tr>
                        <td>Truck Load Outbound</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="tlOutboundWeight"/>
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="tlOutboundCube"/>
                            </mat-form-field>
                        </td> 
                    </tr>



                </table>

                <table style="margin-left: 20px">
                    <tr>
                        <th></th>
                        <th style="text-align: center;">Amount</th>
                    </tr>
                    <tr>
                        <td>Storage Invoice</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="storageInvoice"/>
                            </mat-form-field>
                        </td> 
                    </tr>

                    <tr>
                        <td>Accessorial Invoice</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="accessorialInvoice "/>
                            </mat-form-field>
                        </td>
                    </tr>

                    <tr>
                        <td>W/R Invoice</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="wrInvoice"/>
                            </mat-form-field>
                        </td>
                     
                    </tr>

                    <tr>
                        <td>General Invoice</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="generalInvoice"/>
                            </mat-form-field>
                        </td>
                       
                    </tr>

                    <tr>
                        <td>Freight Invoice</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="freightInvoive"/>
                            </mat-form-field>
                        </td>
                       
                    </tr>

                    <tr>
                        <td>Cartage Invoice</td>
                        <td>
                            <mat-form-field appearance="outline" >
                            <mat-label> </mat-label>
                            <input matInput formControlName="cartageInvoice"/>
                            </mat-form-field>
                        </td>
                       
                    </tr>
                </table>
                
                    
                <mat-form-field  appearance="outline">
                    <mat-label> Storage Variance Percent: </mat-label> 
                    <input matInput formControlName="storageVariancePercent" maxlength="2" />
                    <mat-error *ngIf="form.controls['storageVariancePercent'].hasError('maxlength')">
                        Only <strong>upto 2 digits </strong> are allowed
                      </mat-error>
                </mat-form-field>

                        

                <button [disabled]="!(form.valid && form.dirty)" mat-button (click)="storerCreate4()"class="addButton buttons" >
                    
                    <span>Create</span>
                </button>
            </div>  
            </div>
        </form> 
      </mat-card-content>
     
</mat-card>




