<!-- EDI _Documents(202) -->
<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
          <mat-card-title fxLayoutAlign="center">Work with EDI Documents Traded</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
    
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" fxLayoutAlign="center">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="Storer" />
                </mat-form-field> 
            </div>
        </div>     
      </form>
    
    
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="IO">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            IO
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.IO }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Document Type">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            Document Type
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.DocumentType }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Application Name">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            Application Name
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ApplicationName }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Validity program">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            Validity program
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Validityprogram }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Validity Message File">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            Validity Message File
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ValidityMessageFile }}</td>
        </ng-container>

        <ng-container matColumnDef="Status">
            <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
                Status
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
          </ng-container>
  
        <ng-container matColumnDef="Action">
          <th  mat-header-cell mat-header-cell width="10%" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option"></mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
        
      </table>

    </mat-card-content>
</mat-card>