<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Maintain Message Class Columns File</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Document</mat-label>
                    <input matInput formControlName="DOCUMENT" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Table Name</mat-label>
                    <input  matInput formControlName="FILE" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Field</mat-label>
                    <input  matInput formControlName="FIELD" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Data Type</mat-label>
                    <input  matInput formControlName="DTYPE" />
                </mat-form-field>
            </div> 

            <div class="col" >
                <mat-form-field appearance="outline">
                    <mat-label>XPATH</mat-label>
                    <input matInput formControlName="XPATH1"  />
                </mat-form-field>  
            </div>

            <div class="col" >
                <mat-form-field appearance="outline">
                    <mat-label>Allow Null</mat-label>
                    <mat-select  formControlName="ALLOWNULL">
                        <mat-option value="Y">Yes </mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                </mat-form-field>  
            </div>

            <div class="col" >
                <mat-form-field appearance="outline">
                    <mat-label>Default Value</mat-label>
                    <input matInput formControlName="DVALU1"  />
                </mat-form-field>  
            </div>
        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="change()" class="addButton">           
        <mat-icon mat-icon-button color="primary">edit</mat-icon>
        <mat-label>Message Class Table</mat-label>
    </button>
</mat-card-footer>



