import { GlobalComponent } from './../../../Global-Component';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
import { IMapLocationService } from '../i-map-location.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CustomAlertService } from '../../custom-alert/custom-alert.service';

@Component({
  selector: 'app-map-location-delete',
  templateUrl: './map-location-delete.component.html',
  styleUrls: ['./map-location-delete.component.css'],
})
export class MapLocationDeleteComponent implements OnInit {
  //form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(
    public iMapSvc: IMapLocationService,
    private fb: FormBuilder,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<MapLocationDeleteComponent>,
    private CustomAlertService : CustomAlertService
  ) {}

  form = this.fb.group({
    buildingNumber: this.anyvariable.MABLDG,
    floor: this.anyvariable.MAFLOR,
    aisle: this.anyvariable.MAAISL,
    slot: this.anyvariable.MASLOT,
  });
  // Are you sure you want to delete this map location?
  ngOnInit(): void {}

  closeDialog() {
    this.dialogRef.close(); // <- Close the mat dialog
  }
  deleteMapLocation() 
  {
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure you want to delete this map location? ');
  
    dialogRef.afterClosed().subscribe((result: any) => {
    if (result)
    {
      this.result = this.iMapSvc
        .deleteMapLocation(
          this.user,
          GlobalComponent.fullBuilding(this.anyvariable.MABLDG),
          '',
          GlobalComponent.fullSlotAisl(this.anyvariable.MAAISL),
          GlobalComponent.fullSlotAisl(this.anyvariable.MASLOT)
        )
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);

          if (JSON.parse(this.result[0].data[1].value).success) {
            //alert('Record Created SuccessFully');
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.successMessage(msg);
            this.closeDialog();
            this.router.navigate(['/main-nav/map-location/read']);
          } else {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.failureMessage(msg);
          }
        });
    }
    });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/mapzone/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    // this.router.navigate(['/main-nav/tallys/read']);
  }
}
