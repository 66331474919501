import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IitemService } from 'src/app/features/item/iitem.service';
import Swal from 'sweetalert2';
import { OrderDetailsService } from '../order-details.service';

@Component({
  selector: 'app-order-detail-cut',
  templateUrl: './order-detail-cut.component.html',
  styleUrls: ['./order-detail-cut.component.css']
})

export class OrderDetailCutComponent implements OnInit {

  constructor(
    public orderDetailsSrv: OrderDetailsService,
    public itemService :IitemService,
    private fb: FormBuilder,
    private router : Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<OrderDetailCutComponent>
  ) { }

  form!:FormGroup
  user = localStorage.getItem('userName');
  bol:any;
  seq:any;
  qty:any;
  shipFrom:any;
  item:any;
  itemDsc:any;
  lot1:any;
  lot2:any;
  lot3:any;
  result:any;
  duplicateSource:any;

  ngOnInit(): void {
    this.bol = this.anyvariable.ODBILL;
    this.qty = this.anyvariable.ODQTYS;
    this.seq = this.anyvariable.ODSEQ;
    this.shipFrom = this.anyvariable.ODSHFR;
    this.item = this.anyvariable.ODITEM;
    this.lot1 = this.anyvariable.ODLOT1;
    this.lot2 = this.anyvariable.ODLOT2;
    this.lot3 = this.anyvariable.ODLOT3;

    this.itemService.getItemBySearch(
      this.user,
      this.anyvariable.ODSTOR,
      '',
      this.item
    ).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('SINGLE ITEM DATA');
      console.log(this.duplicateSource);
      this.itemDsc = this.duplicateSource[0].ITDSC1;   
    })

    this.form= this.fb.group({
      reason : this.anyvariable.ODREAS      
    })
  }

  orderDetailCut() {
    var res = this.form.value;
    this.result = this.orderDetailsSrv.orderDetailCutService(
      this.bol,
      this.seq,
      res.reason       
    ).subscribe((data: any) => {
      this.result = data;
      var length = this.result[0].data.length;
      if(this.result[0].data[length-1].value=='Order Detail Cut Processed Successfully'){
        const msg= this.result[0].data[length-1].value;
        this.successMessage(msg)
        this.closeDialog();
      }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
      }
    });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/details']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }


  closeDialog(){
    this.dialogRef.close();
  }
  
}
