<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Labor Plans</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
  
  
      
      <div class="buttons">
        <button mat-button (click)="add()"  class="addButton">
          <mat-icon>add</mat-icon>
          <span>New Labor Plan</span>
        </button>
      </div>
     
     <div class="formDiv">
      <form [formGroup]="form">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Document :</mat-label>
                    <input matInput formControlName="DOCT"/>
                  </mat-form-field>
        
                  <mat-form-field appearance="outline">
                    <mat-label>Must Start Date:</mat-label>
                    <input matInput formControlName="SDATE"/>
                  </mat-form-field>
        
                  <mat-form-field appearance="outline">
                    <mat-label>Building:</mat-label>
                    <input matInput formControlName="BLDG"/>
                  </mat-form-field>
        
                  <mat-form-field appearance="outline">
                    <mat-label>Status:</mat-label>
                    <input matInput formControlName="STAT"/>
                  </mat-form-field>
            </div>
          

          <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>Storer :</mat-label>
                <input matInput formControlName="STOR"/>
              </mat-form-field>
    
              <mat-form-field appearance="outline">
                <mat-label>Appointment Date:</mat-label>
                <input matInput formControlName="ADATE"/>
              </mat-form-field>
    
              <mat-form-field appearance="outline">
                <mat-label>Active:</mat-label>
                <input matInput formControlName="OPER"/>
              </mat-form-field>
    
              <mat-form-field appearance="outline">
                <mat-label>SCAC:</mat-label>
                <input matInput formControlName="SCAC"/>
              </mat-form-field>
          </div>
          

          <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>Operator:</mat-label>
                <input matInput formControlName="OPTN1"/>
              </mat-form-field>
    
              <mat-form-field appearance="outline">
                <mat-label>Manual/Auto:</mat-label>
                <input matInput formControlName="OPTN1"/>
              </mat-form-field>
          </div>

          
        </div>     
      </form>
     </div>
     
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="LBDOCT">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Document Type
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LBDOCT }}</td>
        </ng-container>
  
        <ng-container matColumnDef="LBDOCN">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Document Number
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LBDOCN }}</td>
        </ng-container>
  
        <ng-container matColumnDef="CHANG">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            CR
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.CHANG }}</td>
        </ng-container>
  
        <ng-container matColumnDef="POOL">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            RP
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.POOL }}</td>
        </ng-container>
  
        <ng-container matColumnDef="LOCN">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
          Building/Location
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LOCN }}</td>
        </ng-container>

        
        <ng-container matColumnDef="LBSTOR">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Storer
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LBSTOR }}</td>
        </ng-container>

        <ng-container matColumnDef="MDATE">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Must Date
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.MDATE }}</td>
        </ng-container>

        <ng-container matColumnDef="MTIME">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
               Start By Time
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.MTIME }}</td>
        </ng-container>

        <ng-container matColumnDef="CURNT">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
               ACTC SCAC
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.CURNT }}</td>
        </ng-container>

        <ng-container matColumnDef="MOVES">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
               Remaining Trans
            <td mat-cell *matCellDef="let element">{{ element.MOVES }}</td>
        </ng-container>

        <ng-container matColumnDef="RTIME">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Remaining Time
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.RTIME }}</td>
        </ng-container>

        <ng-container matColumnDef="PCT">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
              %CMT
            <td mat-cell *matCellDef="let element">{{ element.PCT }}</td>
        </ng-container>

        <ng-container matColumnDef="LBSTAT">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Status
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LBSTAT }}</td>
        </ng-container>
        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          <mat-option>
            <button mat-icon-button  (click)="change(element)">           
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button>
          </mat-option>
  
       
  
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
    </mat-card-content>
  </mat-card>



