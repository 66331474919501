import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { ItemCountScheduleChangeComponent } from './item-count-schedule-change/item-count-schedule-change.component';
import { ItemCountScheduleCreateComponent } from './item-count-schedule-create/item-count-schedule-create.component';

@Component({
  selector: 'app-ww-item-count-schedules',
  templateUrl: './ww-item-count-schedules.component.html',
  styleUrls: ['./ww-item-count-schedules.component.css']
})
export class WwItemCountSchedulesComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'storer',
    'itemCode',
    'qty',
    'locnBuckets',
    'lots',
    'posted',
    'status',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      storer:1979,
      itemCode:1980,
      qty:'',
      locnBuckets:'',
      lots:'',
      posted:'',
      status:'CL'
    },
    {
      storer:1979,
      itemCode:1980,
      qty:'',
      locnBuckets:'',
      lots:'',
      posted:'',
      status:'AC'
    },
    {
      storer:1979,
      itemCode:1983,
      qty:'',
      locnBuckets:'',
      lots:'',
      posted:'',
      status:'AC'
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      STOR:'',
      ITEM:'',
      DATE:'',
      STAT1:''
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }


  add(){
    this.matDialog.open(ItemCountScheduleCreateComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  change(element:any){
    this.matDialog.open(ItemCountScheduleChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }

}
