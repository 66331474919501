<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Record Storage Lot Status Synopsis</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>Storer Number</mat-label>
                      <input matInput formControlName="StorerNumber"/>
                      </mat-form-field>
                    
                      <mat-form-field appearance="outline">
                       <mat-label>Item Number</mat-label>
                       <input matInput formControlName="ItemNumber"/>
                       </mat-form-field>
                    
                       <mat-form-field appearance="outline">
                        <mat-label>Lot Code</mat-label>
                        <input matInput formControlName="LotCode"/>
                        </mat-form-field>
                     </div>
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Lot Weight</mat-label>
                       <input matInput formControlName="LotWeight"/>
                       </mat-form-field>
                      </div>
  
                       <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Expiration</mat-label>
                       <input matInput formControlName="Expiration"/>
                       </mat-form-field>  
  
                      <mat-form-field appearance="outline">
                        <mat-label>Date Held</mat-label>
                       <input matInput formControlName="DateHeld"/>
                       </mat-form-field>
                     </div>
  
                     <div class="col">        
                      <mat-form-field appearance="outline">
                        <mat-label>Date Released</mat-label>
                       <input matInput formControlName="DateReleased"/>
                       </mat-form-field>
                     </div>
       
                  <div class="col">    
                      <mat-form-field appearance="outline">
                        <mat-label>Date Received</mat-label>
                       <input matInput formControlName="DateReceived"/>
                       </mat-form-field>
  
                       <mat-form-field appearance="outline">
                        <mat-label>Date Changed</mat-label>
                       <input matInput formControlName="DateChanged"/>
                       </mat-form-field>
  
                       <mat-form-field appearance="outline">
                        <mat-label>Changed By</mat-label>
                       <input matInput formControlName="ChangedBy"/>
                       </mat-form-field>
                     </div>
  
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                       <input matInput formControlName="Status"/>
                       </mat-form-field>
  
                     </div>
                  </form>
            <!-- <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="Request">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Request
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Request }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Line">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Line
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Line }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Activity">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Activity
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Activity }}</td>
          </ng-container>
  
          <ng-container matColumnDef="Tracking">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Tracking
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Tracking }}</td>
          </ng-container>
  
          <ng-container matColumnDef="Number">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Number
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Number }}</td>
          </ng-container>
  
          <ng-container matColumnDef="DateTime">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              DateTime
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.DateTime }}</td>
          </ng-container>
          
          <ng-container matColumnDef="Sts">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Sts
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Sts }}</td>
          </ng-container>
      
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table> -->

