import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-lot-status-add',
  templateUrl: './ww-lot-status-add.component.html',
  styleUrls: ['./ww-lot-status-add.component.css']
})
export class WwLotStatusAddComponent implements OnInit {
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwLotStatusAddComponent>) { }

  ngOnInit(): void {
    this.form= this.fb.group({
          StorerNumber:'',
          ItemNumber:'',
          LotCode:'',
          LotWeight:'',
          Expiration:'',
          DateHeld:'',
          DateReleased:'',
          Status:''
      
    });
  }
  addLotStatus(){
    
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}


// displayedColumns: string[] = [
//   'Storer Number',
//   'Item Number',
//   'Lot Code',
//   'Date Held',
//   'Date Released',
//   'St',
//   'Expiration',
//   'Received',
//   'Action'
// ];

// dataSource:any=[]

// duplicateSource:any =[
//   {
//     StorerNumber:'1',
//     ItemNumber:'2',
//     LotCode:'JAMES JAMES',
//     DateHeld:'2-3-2001',
//     DateReleased:'2-4-2001',
//     St:'1',
//     Expiration: '12',
//     Received : '10'
//   },

//   {
//     StorerNumber:'2',
//     ItemNumber:'1',
//     LotCode:'JAMES JAMES',
//     DateHeld:'5-3-2001',
//     DateReleased:'2-6-2001',
//     St:'1',
//     Expiration: '12',
//     Received : '10'
//   },

// ]
// constructor(private router: Router,
//   public iHeaderSvc: IheaderService,
//   private fb: FormBuilder,
//   private route: ActivatedRoute,
//   private matDialog: MatDialog) { }

// ngOnInit(): void {
//   this.form= this.fb.group({
//     StorerNumber:'',
//     ItemNumber:'',
//     LotCode:'',
//     LotWeight:'',
//     Expiration:'',
//     DateHeld:'',
//     DateReleased:'',
//     Status:''

//   })

//   this.dataSource = this.duplicateSource;
// }