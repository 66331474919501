<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
            <mat-card-title fxLayoutAlign="center">Work with Serial Control File</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
          <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>    
    
    <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" fxLayoutAlign="center">

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="Storer" />
                </mat-form-field>
                
            </div>
        </div>     
    </form>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="Label Type">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            Label Type
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LabelType }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Lead Digits">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            Lead Digits
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LeadDigits }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Manufacturer Number">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            Manufacturer Number
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.ManufacturerNumber }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Sequence Number">
          <th mat-header-cell mat-header-cell width="15%" *matHeaderCellDef>
            Sequence Number
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.SequenceNumber }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Action">
          <th  mat-header-cell mat-header-cell width="10%" *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          
        
  
            </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  </mat-card-content>

</mat-card>