import { MaintQuestionsComponent } from './features/maint-questions/maint-questions.component';
import { EnvironmentComponent } from './features/environment copy/environment.component';
import { HazmatClassComponent } from './features/hazmat-class/hazmat-class.component';
import { CarriersComponent } from './features/carriers/carriers.component';
import { DeleteBuildingComponent } from './features/buildings/delete-building/delete-building.component';
import { UpdateBuildingComponent } from './features/buildings/update-building/update-building.component';
import { CreateBuildingComponent } from './features/buildings/create-building/create-building.component';
import { MaintainComponent } from './features/tallys/maintain/maintain.component';
import { CommentsComponent } from './features/tallys/comments/comments.component';
import { TallysComponent } from './features/tallys/tallys.component';
import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateComponent } from './features/tallys/create/create.component';
import { MaintainReadComponent } from './features/tallys/maintain-read/maintain-read.component';
import { UpdateComponent } from './features/tallys/update/update.component';
import { TallyPostingComponent } from './features/tallys/tally-posting/tally-posting.component';
import { TallyDetailWorkComponent } from './features/tallys/tally-detail-work/tally-detail-work.component';
import { TallyInquiryComponent } from './features/tallys/tally-inquiry/tally-inquiry.component';
import { StorerComponent } from './features/storer/storer.component';
import { StorerCreateComponent } from './features/storer/storer-create/storer-create.component';
import { StorerUpdateComponent } from './features/storer/storer-update/storer-update.component';
import { StorerDeactiveComponent } from './features/storer/storer-deactive/storer-deactive.component';
import { BuildingsWorkWithComponent } from './features/buildings/buildings.component';
import { StorerContactsComponent } from './features/storer/storer-contacts/storer-contacts.component';

import { MapLocationComponent } from './features/map-location/map-location.component';
import { MapLocationCreateComponent } from './features/map-location/map-location-create/map-location-create.component';

import { MapZonesComponent } from './features/map-zones/map-zones.component';
import { ConsigneeComponent } from './features/consignee/consignee.component';
import { ExitPointsComponent } from './features/exit-points/exit-points.component';
import { OrdersComponent } from './features/orders/orders.component';
import { MoveQueueComponent } from './features/move-queue/move-queue.component';
import { ItemComponent } from './features/item/item.component';
import { ItemTallyWorkWithComponent } from './features/item/item-tally-work-with/item-tally-work-with.component';
import { ItemAdjustmentWorkWithComponent } from './features/item/item-adjustment-work-with/item-adjustment-work-with.component';
import { OrderDetailsComponent } from './features/orders/order-details/order-details.component';
import { StorerWwAccountAttributesComponent } from './features/storer/storer-ww-account-attributes/storer-ww-account-attributes.component';
import { PostalAbbreviationsComponent } from './features/postal-abbreviations/postal-abbreviations.component';
import { ReceivablesComponent } from './features/receivables/receivables.component';
import { MainNavComponent } from './main-nav/main-nav.component';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { StorerAutoSchedulingProceesComponent } from './features/storer/storer-auto-scheduling-procees/storer-auto-scheduling-procees.component';
import { ReceivableDetailsComponent } from './features/receivables/receivable-details/receivable-details.component';
import { InboundStagingComponent } from './features/receive/inbound-staging/inbound-staging.component';
import { InboundPutawayComponent } from './features/receive/inbound-putaway/inbound-putaway.component';
import { InboundOnestepComponent } from './features/receive/inbound-onestep/inbound-onestep.component';
import { ActionRsmsComponent } from './features/action-rsms/action-rsms.component';
import { ApplicationsComponent } from './features/applications/applications.component';
import { ControllBatchFileComponent } from './features/controll-batch-file/controll-batch-file.component';
import { AuditHeadersComponent } from './features/audit-headers/audit-headers.component';
import { BoxContentComponent } from './features/box-content/box-content.component';
import { AccountProcessingComponent } from './features/account-processing/account-processing.component';
import { ActivityDescriptionComponent } from './features/activity-description/activity-description.component';
import { BalanceErrorsComponent } from './features/balance-errors/balance-errors.component';
import { InventoryBooksComponent } from './features/inventory-books/inventory-books.component';
import { AddressTypesComponent } from './features/address-types/address-types.component';
import { InventoryAdjustmentsComponent } from './features/item/inventory-adjustments/inventory-adjustments.component';
import { InventoryTagsComponent } from './features/item/inventory-tags/inventory-tags.component';
import { OrderStagingComponent } from './features/order-staging/order-staging.component';
import { WorkWithDockSchedularComponent } from './features/order-staging/work-with-dock-schedular/work-with-dock-schedular.component';
import { WorkWithBatchDetailsComponent } from './features/order-staging/work-with-batch-details/work-with-batch-details.component';
import { DockScheduleComponent } from './features/dock-schedule/dock-schedule.component';
import { LtlBatchesComponent } from './features/ltl-batches/ltl-batches.component';
import { BookDetailsComponent } from './features/item/inventory-tags/book-details/book-details.component';
import { WorkWithBookDetailsComponent } from './features/work-with-book-details/work-with-book-details.component';
import { WorkWithBoxesComponent } from './features/work-with-boxes/work-with-boxes.component';
import { WwMasterSerialListComponent } from './features/ww-master-serial-list/ww-master-serial-list.component';
import { WwHazardousChemicalsComponent } from './features/ww-hazardous-chemicals/ww-hazardous-chemicals.component';
import { WwConsigneeItemsComponent } from './features/consignee/ww-consignee-items/ww-consignee-items.component';
import { WwConsigneeChildItemsComponent } from './features/consignee/ww-consignee-child-items/ww-consignee-child-items.component';
import { WwSystemCommentsComponent } from './features/ww-system-comments/ww-system-comments.component';
import { WwSerializedInventoriesComponent } from './features/ww-serialized-inventories/ww-serialized-inventories.component';
import { WwCarrierEdiDocumentsComponent } from './features/ww-carrier-edi-documents/ww-carrier-edi-documents.component';
import { WwContentsComponent } from './features/ww-contents/ww-contents.component';
import { FilesComponent } from './features/files/files.component';
import { CommodityPrintersComponent } from './features/commodity-printers/commodity-printers.component';
import { CampusBuildingsComponent } from './features/campus-buildings/campus-buildings.component';
import { WwCommentSubcodesComponent } from './features/ww-comment-subcodes/ww-comment-subcodes.component';
import { WwCustomerAddressesComponent } from './features/ww-customer-addresses/ww-customer-addresses.component';
import { AccountSelectionComponent } from './features/account-selection/account-selection.component';
import { WwCarriersComponent } from './features/carriers/ww-carriers/ww-carriers.component';
import { ProductCodeDescriptionComponent } from './features/product-code-description/product-code-description.component';
import { WwConsigneeBasedTriggersComponent } from './features/ww-consignee-based-triggers/ww-consignee-based-triggers.component';
import { WwMetricConversionsComponent } from './features/ww-metric-conversions/ww-metric-conversions.component';
import { WwExpectedDurationsComponent } from './features/ww-expected-durations/ww-expected-durations.component';
import { WwContactTypesComponent } from './features/ww-contact-types/ww-contact-types/ww-contact-types.component';
import { WwCustomerRatingPreferenceComponent } from './features/ww-customer-rating-preference/ww-customer-rating-preference.component';
import { DataElementsComponent } from './features/data-elements/data-elements.component';
import { GeneralInvoicesComponent } from './features/general-invoices/general-invoices.component';
import { WwDockSchedulerControlsComponent } from './features/ww-dock-scheduler-controls/ww-dock-scheduler-controls.component';
import { WwTripExpensesComponent } from './features/ww-trip-expenses/ww-trip-expenses.component';
import { EmailAddressComponent } from './features/email-address/email-address.component';
import { ExractReportsBaseFieldsComponent } from './features/exract-reports-base-fields/exract-reports-base-fields.component';
import { WwEquipmentOperatorsComponent } from './features/ww-equipment-operators/ww-equipment-operators/ww-equipment-operators.component';
import { WwOperatorEquipmentComponent } from './features/ww-operator-equipment/ww-operator-equipment.component';
import { ExtractReportFiltersComponent } from './features/extract-report-filters/extract-report-filters.component';
import { WwWorkScheduleComponent } from './features/ww-work-schedule/ww-work-schedule.component';
import { WwValidityCharacterRemovalComponent } from './features/ww-validity-character-removal/ww-validity-character-removal/ww-validity-character-removal.component';
import { FreightCodesComponent } from './features/freight-codes/freight-codes.component';
import { WwProductCodesComponent } from './features/ww-product-codes/ww-product-codes.component';
import { WwItemCountSchedulesComponent } from './features/ww-item-count-schedules/ww-item-count-schedules.component';
import { WwKanbanComponent } from './features/ww-kanban/ww-kanban.component';
import { WwScheduleExceptionsComponent } from './features/ww-schedule-exceptions/ww-schedule-exceptions.component';
import { WwGeneralInvoiceDetailsComponent } from './features/ww-general-invoice-details/ww-general-invoice-details.component';
import { WorkWithExtractReportsComponent } from './features/work-with-extract-reports/work-with-extract-reports.component';
import { WwPeriodsComponent } from './features/ww-periods/ww-periods/ww-periods.component';
import { WwLaborPlansComponent } from './features/ww-labor-plans/ww-labor-plans/ww-labor-plans.component';
import { LaborPlanGroupComponent } from './features/labor-plan-group/labor-plan-group.component';
import { LoadOutComponent } from './features/load-out/load-out.component';
import { OperatorGroupsComponent } from './features/operator-groups/operator-groups.component';
import { SerialNumbersComponent } from './features/serial-numbers/serial-numbers.component';

import { WwItemAttributesComponent } from './features/ww-item-attributes/ww-item-attributes.component';
import { WwItemForecastDetailsComponent } from './features/ww-item-forecast-details/ww-item-forecast-details.component';
import { WwStorerAttributesComponent } from './features/ww-storer-attributes/ww-storer-attributes.component';
import { WwLocaterComponent } from './features/ww-locater/ww-locater.component';
// import { MaintQuestionsComponent } from './features/maint-questions/maint-questions.component';
import { MetricsConstraintsComponent } from './features/metrics-constraints/metrics-constraints.component';
import { MessageClassShredComponent } from './features/message-class-shred/message-class-shred.component';
import { WorkWithLotStatusComponent } from './features/work-with-lot-status/work-with-lot-status.component';
import { WwLaborProcessingComponent } from './features/ww-labor-processing/ww-labor-processing.component';
import { WwInboundDetailsComponent } from './features/ww-inbound-details/ww-inbound-details.component';
import { MessageclassshredDetailsComponent } from './features/message-class-shred/messageclassshred-details/messageclassshred-details.component';
import { MessageclassshredDetailsDetailsComponent } from './features/message-class-shred/messageclassshred-details/messageclassshred-details-details/messageclassshred-details-details.component';
import { ExtractReportWhereclausesComponent } from './features/extract-report-whereclauses/extract-report-whereclauses.component';
import { ExtractReportSortClausesComponent } from './features/extract-report-sort-clauses/extract-report-sort-clauses.component';
import { WwHazardMaterialsGroupComponent } from './features/ww-hazard-materials-group/ww-hazard-materials-group.component';
import { WwLaborPlanComponent } from './features/ww-labor-plan/ww-labor-plan.component';
import { WorkWithEmailFormatComponent } from './features/work-with-email-format/work-with-email-format.component';
import { WwOrderEventsComponent } from './features/ww-order-events/ww-order-events.component';

import { WwMesssageClassTablesComponent } from './features/ww-messsage-class-tables/ww-messsage-class-tables.component';
import { WwMessageClassColumnsComponent } from './features/ww-messsage-class-tables/ww-message-class-columns/ww-message-class-columns.component';


import { WwIncomingEventsComponent } from './features/ww-incoming-events/ww-incoming-events.component';

import { MetricsDefinationsComponent } from './features/metrics-definations/metrics-definations.component';

import { WwValidationEventsComponent } from './features/ww-validation-events/ww-validation-events.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { TallyDetailPutawayLocationComponent } from './features/tallys/tally-detail-putaway-location/tally-detail-putaway-location.component';
import { StorerContactCreateComponent } from './features/storer/storer-contacts/storer-contact-create/storer-contact-create.component';

import { ItemLocationsComponent } from './features/item/item-locations/item-locations.component';

import { StorerCommentsComponent } from './features/storer/storer-comments/storer-comments.component';
import { ConsineeCommentsComponent } from './features/consignee/consinee-comments/consinee-comments.component';
import { ItemCommentsComponent } from './features/item/item-comments/item-comments.component';

const routes: Routes = [
  {
    path: 'receive',
    loadChildren: () =>
      import('./features/receive/receive.module').then((m) => m.ReceiveModule),
  },
  {
    path: 'tallys',
    loadChildren: () =>
      import('./features/tallys/tallys.module').then((m) => m.TallysModule),
  },

  {
    path: 'main-nav',
    component: MainNavComponent,
    children: [
      {
        path: 'tallys/read',
        component: TallysComponent,
      },
      {
        path: 'tallys/add',
        component: CreateComponent,
      },
      {
        path: 'tallys/update',
        component: UpdateComponent,
      },

      {
        path: 'tallys/comments/:id',
        component: CommentsComponent,
      },
      {
        path: 'tallys/posting/:id',
        component: TallyPostingComponent,
      },
      {
        path: 'tallys/enquiry',
        component: TallyInquiryComponent,
      },
      {
        path: 'storer',
        component: StorerComponent,
      },
      {
        path: 'storer/create',
        component: StorerCreateComponent,
      },

      {
        path: 'storer/update',
        component: StorerUpdateComponent,
      },
      
      {
        path: 'storer/deactive',
        component: StorerDeactiveComponent,
      },
      {
        path: 'storer/account-attribute',

        component: StorerWwAccountAttributesComponent,
      },
      {
        path: 'tallys/details',
        component: TallyDetailWorkComponent,
      },
      {
        path: 'storer/contacts',
        component: StorerContactsComponent,
      },
      {
        path: 'storer/autoScheduleProcess',
        component: StorerAutoSchedulingProceesComponent,
      },
      {
        path: 'item-attributes/read',
        component: WwItemAttributesComponent,
      },
      {
        path: 'item-Forecast-Details/read',
        component: WwItemForecastDetailsComponent,
      },
      {
        path: 'item/location',
        component: ItemLocationsComponent,
      },
      {
        path: 'InboundDetails/read',
        component: WwInboundDetailsComponent,
      },

      {
        path: 'storer-attributes/read',
        component: WwStorerAttributesComponent,
      },

      {
        path: 'order-events/read',
        component: WwOrderEventsComponent,
      },
      {
        path: 'incoming-events/read',
        component: WwIncomingEventsComponent,
      },
      {
        path: 'validation-events/read',
        component: WwValidationEventsComponent,
      },
      {
        path: 'locater/read',
        component: WwLocaterComponent,
      },

      {
        path: 'tallys/detail-add',
        component: MaintainComponent,
      },
      {
        path: 'maintquestions/read',
        component: MaintQuestionsComponent,
      },
      {
        path: 'tallys/maintain',
        component: MaintainComponent,
      },
      {
        path: 'tallys/maintain-read',
        component: MaintainReadComponent,
      },
      {
        path: 'building/read',
        component: BuildingsWorkWithComponent,
      },
      {
        path: 'building/add',
        component: CreateBuildingComponent,
      },
      {
        path: 'building/update',
        component: UpdateBuildingComponent,
      },
      {
        path: 'building/delete',
        component: DeleteBuildingComponent,
      },
      {
        path: 'map-location/read',
        component: MapLocationComponent,
      },

      {
        path: 'mapzone/read',
        component: MapZonesComponent,
      },
      {
        path: 'consignee/read',
        component: ConsigneeComponent,
      },
      {
        path: 'consignee/read',
        component: ConsigneeComponent,
      },
      {
        path: 'exitpoint/read',
        component: ExitPointsComponent,
      },

      {
        path: 'carrier/read',
        component: CarriersComponent,
      },

      {
        path: 'ww-carrier/read',
        component: WwCarriersComponent,
      },

      {
        path: 'orders/read',
        component: OrdersComponent,
      },
      {
        path: 'orders/details',
        component: OrderDetailsComponent,
      },
      {
        path: 'message-class-shred/details',
        component: MessageclassshredDetailsComponent,
      },
      {
        path: 'message-class-shred-details/details',
        component: MessageclassshredDetailsDetailsComponent,
      },
      {
        path: 'movequeue/read',
        component: MoveQueueComponent,
      },
      {
        path: 'item/read',
        component: ItemComponent,
      },
      {
        path: 'item/tally',
        component: ItemTallyWorkWithComponent,
      },
      {
        path: 'item/adjustment',
        component: ItemAdjustmentWorkWithComponent,
      },
      {
        path: 'item/inventory-adjustment',
        component: InventoryAdjustmentsComponent,
      },
      {
        path: 'postalAbbreviation/read',
        component: PostalAbbreviationsComponent,
      },
      {
        path: 'receivables/read',
        component: ReceivablesComponent,
      },
      {
        path: 'receivables/details',
        component: ReceivableDetailsComponent,
      },

      { path: 'receive/staging', component: InboundStagingComponent },
      { path: 'receive/putaway', component: InboundPutawayComponent },
      { path: 'receive/onestep', component: InboundOnestepComponent },
      {
        path: 'laborPlan/read',
        component: LaborPlanGroupComponent,
      },
      {
        path: 'loadOut/read',
        component: LoadOutComponent,
      },
      {
        path: 'operatorGroups/read',
        component: OperatorGroupsComponent,
      },
      {
        path: 'serialNumbers/read',
        component: SerialNumbersComponent,
      },
      {
        path: 'action/read',
        component: ActionRsmsComponent,
      },

      {
        path: 'application/read',
        component: ApplicationsComponent,
      },

      {
        path: 'controllbatchfile/read',
        component: ControllBatchFileComponent,
      },

      {
        path: 'audit-header/read',
        component: AuditHeadersComponent,
      },

      {
        path: 'box-content/read',
        component: BoxContentComponent,
      },
      {
        path: 'accountProcessing/read',
        component: AccountProcessingComponent,
      },
      {
        path: 'activityDescription/read',
        component: ActivityDescriptionComponent,
      },
      {
        path: 'balance-error/read',
        component: BalanceErrorsComponent,
      },
      {
        path: 'inventory-books/read',
        component: InventoryBooksComponent,
      },
      {
        path: 'address-type/read',
        component: AddressTypesComponent,
      },
      {
        path: 'inventoryTags/read',
        component: InventoryTagsComponent,
      },
      {
        path: 'orderStagingBatch/read',
        component: OrderStagingComponent,
      },
      {
        path: 'orderStagingBatch/dockSchedule',
        component: WorkWithDockSchedularComponent,
      },
      {
        path: 'orderStagingBatch/batchDetails',
        component: WorkWithBatchDetailsComponent,
      },
      {
        path: 'dockSchedule/read',
        component: DockScheduleComponent,
      },
      {
        path: 'ltlBatch/read',
        component: LtlBatchesComponent,
      },
      {
        path: 'bookDetails/read',
        component: WorkWithBookDetailsComponent,
      },
      {
        path: 'boxes/read',
        component: WorkWithBoxesComponent,
      },
      {
        path: 'masterSerialList/read',
        component: WwMasterSerialListComponent,
      },
      {
        path: 'hazardousChemical/read',
        component: WwHazardousChemicalsComponent,
      },
      {
        path: 'consignee-items/read',
        component: WwConsigneeItemsComponent,
      },
      {
        path: 'consignee-child-items/read',
        component: WwConsigneeChildItemsComponent,
      },
      {
        path: 'system-comments/read',
        component: WwSystemCommentsComponent,
      },
      {
        path: 'serialized-inventories/read',
        component: WwSerializedInventoriesComponent,
      },
      {
        path: 'carrier-edi-documents/read',
        component: WwCarrierEdiDocumentsComponent,
      },
      {
        path: 'contents/read',
        component: WwContentsComponent,
      },
      {
        path: 'files/read',
        component: FilesComponent,
      },
      {
        path: 'commodityPrinters/read',
        component: CommodityPrintersComponent,
      },
      {
        path: 'campusBuildings/read',
        component: CampusBuildingsComponent,
      },
      {
        path: 'comment-subcodes/read',
        component: WwCommentSubcodesComponent,
      },
      {
        path: 'customer-addresses/read',
        component: WwCustomerAddressesComponent,
      },
      {
        path: 'product-description/read',
        component: ProductCodeDescriptionComponent,
      },
      {
        path: 'consignee-based-trigger/read',
        component: WwConsigneeBasedTriggersComponent,
      },
      {
        path: 'metric-conversions/read',
        component: WwMetricConversionsComponent,
      },

      {
        path: 'expected-durations/read',
        component: WwExpectedDurationsComponent,
      },

      {
        path: 'contact-types/read',
        component: WwContactTypesComponent,
      },
      
      {
        path: 'customer-rating-peference/read',
        component: WwCustomerRatingPreferenceComponent,
      },

      {
        path: 'dataElement/read',
        component: DataElementsComponent,
      },
      {
        path: 'generalInvoice/read',
        component: GeneralInvoicesComponent,
      },
      {
        path: 'dockSchedulercontroll/read',
        component: WwDockSchedulerControlsComponent,
      },
      {
        path: 'freightCode/read',
        component: FreightCodesComponent,
      },

      {
        path: 'hazmatClass/read',
        component: HazmatClassComponent,
      },
      {
        path: 'environment/read',
        component: EnvironmentComponent,
      },
      {
        path: 'tripExpenses/read',
        component: WwTripExpensesComponent,
      },
      {
        path: 'emailAddress/read',
        component: EmailAddressComponent,
      },
      {
        path: 'extractBaseFields/read',
        component: ExractReportsBaseFieldsComponent,
      },
      {
        path: 'equipmentOperators/read',
        component: WwEquipmentOperatorsComponent,
      },
      {
        path: 'operatorEquipment/read',
        component: WwOperatorEquipmentComponent,
      },
      {
        path: 'extractReportFilters/read',
        component: ExtractReportFiltersComponent,
      },
      {
        path: 'workSchedule/read',
        component: WwWorkScheduleComponent,
      },
      {
        path: 'validityCharacterRemoval/read',
        component: WwValidityCharacterRemovalComponent,
      },
      {
        path: 'productCode/read',
        component: WwProductCodesComponent,
      },
      {
        path: 'itemCountScchedule/read',
        component: WwItemCountSchedulesComponent,
      },
      {
        path: 'kanban/read',
        component: WwKanbanComponent,
      },
      {
        path: 'scheduleException/read',
        component: WwScheduleExceptionsComponent,
      },
      {
        path: 'generalInvoiceDetails/read',
        component: WwGeneralInvoiceDetailsComponent,
      },
      {
        path: 'extractRepots/read',
        component: WorkWithExtractReportsComponent,
      },
      {
        path: 'periods/read',
        component: WwPeriodsComponent,
      },
      {
        path: 'metrics-constraints/read',
        component: MetricsConstraintsComponent,
      },
      {
        path: 'maintquestions/read',
        component: MaintQuestionsComponent,
      },
      {
        path: 'message-class-shred/read',
        component: MessageClassShredComponent,
      },
      {
        path: 'labor-plans/read',
        component: WwLaborPlansComponent,
      },

      {
        path: 'lot-status/read',
        component: WorkWithLotStatusComponent,
      },
      {
        path: 'labor-processing/read',
        component: WwLaborProcessingComponent,
      },
      {
        path : 'extractreportwhere/read',
        component : ExtractReportWhereclausesComponent,
      },
      {
        path : 'extractreportsort/read',
        component : ExtractReportSortClausesComponent,
      },
      {
        path :'hazardmaterialsgroup/read',
        component :WwHazardMaterialsGroupComponent,

      },
      {
        path : 'laborplan/read',
        component : WwLaborPlanComponent,
      },
      {
        path: 'email-format/read',
        component: WorkWithEmailFormatComponent,
      },
      {
        path: 'metrics-definations/read',
        component: MetricsDefinationsComponent,
      },


      {
        path: 'messageClassTables/read',
        component: WwMesssageClassTablesComponent,
      },

      {
        path: 'messageClassColumns/read',
        component: WwMessageClassColumnsComponent,
      },

      {
        path: 'putawayLocation/read',
        component: TallyDetailPutawayLocationComponent,
      },

      {
        path: 'storer/comments',
        component: StorerCommentsComponent,
      },

      {
        path: 'consignee/comments',
        component: ConsineeCommentsComponent,
      },

      {
        path: 'item/comments',
        component: ItemCommentsComponent,
      },

      

      

      
    


    ],
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
  },

  {
    path: '',
    component: LoginComponent,
  },

  {
    path: 'accountSelection',
    component: AccountSelectionComponent,
  },

  
  {
    path: 'orders',
    loadChildren: () =>
      import('./features/orders/orders.module').then((m) => m.OrdersModule),
  },
  {
    path: 'shipping',
    loadChildren: () =>
      import('./features/shipping/shipping.module').then(
        (m) => m.ShippingModule
      ),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./features/auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
