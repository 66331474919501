<div class="admin-user-header">
   
        <a routerLink="dashboard"><img class="imageCss admin-sidebar-logo" src="assets/images/logo.png" /></a>
    <div class="dropdown">
      <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
      {{this.user}}
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><button mat-raised-button class="signout" (click)="accountSelectionUpdate(this.user)">Change Account Selection
        </button></li>
        <li><button mat-raised-button class="signout" (click)="Logout()">Sign Out</button></li>
      </ul>
    </div>
  </div>
<div class="main-dashborad-page">
    <div class="row">
        <div class="col-md-3 mb-5">
            <a routerLink="" class="dashboard-card" (click)="customerServiceMenu()">
                <mat-icon role="img" mat-list-icon="" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">store</mat-icon>  
                <h2>Customer Service</h2>
            </a>
        </div>
        <div class="col-md-3 mb-5">
            <a  class="dashboard-card" (click)="managementMenu()">
                <mat-icon role="img" mat-list-icon="" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">assignment_ind</mat-icon>  
                <h2>Management</h2>
            </a>
        </div>
        <div class="col-md-3 mb-5" >
            <a routerLink="" class="dashboard-card" (click)="rfMenu()">
                <mat-icon role="img" mat-list-icon="" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">home</mat-icon>                
                <h2>RF Menu</h2>
            </a>
        </div>
        <div class="col-md-3 mb-5" >
            <a routerLink="" class="dashboard-card" (click)="inventory()">
                <mat-icon role="img" mat-list-icon="" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">assignment_ind</mat-icon>                
                <h2>Inventory</h2>
            </a>
        </div>
        <div class="col-md-3 mb-5">
            <a routerLink="" class="dashboard-card" (click)="maintanance()">
                <mat-icon _ngcontent-xqe-c407="" role="img" mat-list-icon="" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">map</mat-icon>                
                <h2>Maintanance</h2>
            </a>
        </div>
        <div class="col-md-3 mb-5">
            <a routerLink="/main-nav/dashboard" class="dashboard-card">
                <mat-icon _ngcontent-whm-c407="" role="img" mat-list-icon="" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">explore</mat-icon>                
                <h2>Security</h2>
            </a>
        </div>
        <!-- <div class="col-md-3 mb-5">
            <a routerLink="" class="dashboard-card" >
                <mat-icon _ngcontent-dok-c407="" role="img" mat-list-icon="" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">store</mat-icon>                
                <h2>Storer</h2>
            </a>
        </div>

        <div class="col-md-3 mb-5">
            <a routerLink="" class="dashboard-card">
                <mat-icon role="img" mat-list-icon="" class="mat-icon notranslate mat-list-icon material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">assignment_ind</mat-icon>                
                <h2>Items</h2>
            </a>
        </div> -->
    </div>
</div>
