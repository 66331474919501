import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { IheaderService } from '../../tallys/iheader.service';
import { StorerServiceService } from '../storer-service.service';
import { StorerContactCreateComponent } from './storer-contact-create/storer-contact-create.component';
import { StorerContactDeleteComponent } from './storer-contact-delete/storer-contact-delete.component';
import { StorerContactUpdateComponent } from './storer-contact-update/storer-contact-update.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';

interface CustomColumn {
  possition: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-storer-contacts',
  templateUrl: './storer-contacts.component.html',
  styleUrls: ['./storer-contacts.component.css']
})
export class StorerContactsComponent implements OnInit {

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    public storerService: StorerServiceService,
    private fb: FormBuilder,
    private matDialog: MatDialog
  ) {}

  displayedColumns: string[] = [
    'Type',
    'Name',
    'Telephone',
    'Status',
    'Actions'
  ];


  @ViewChild('paginator') paginator!: MatPaginator;
  selectedRow: any;
  editedRows: any;
  GlobalComponent:any=GlobalComponent;
  // searchText: any;
  ctcons:any;
  result: any = [];
  dataSource :any;
  duplicateSource = [];
  singleTallyData = [];
  documents = [];
  user = localStorage.getItem('userName');
  building = GlobalComponent.building;
  tally: any;
  searchValue: string = '';
  condition: boolean = true;
  prevText: string = '';
  element:any;  
  TallyHeaders: any = [];
  id: number = 0;
  public columnShowHideList: CustomColumn[] = [];
  position: any;
  name: any;
  isActive: any;
  searchTextDisplay: any = false;


  updateForm = this.fb.group({ 
    
    contactType: [''],
    name: [''],   
    status: 'AC',
   
  });

  
  ngOnInit(): void {
    this.initializeColumnProperties();
    this.editedRows = [];

    this.element=history.state;

      const prefixWithZeros = (number: any, length: number) => String(number).padStart(length, '0');
      console.log(prefixWithZeros(this.element.STORE,4));

      this.ctcons='STOR'+prefixWithZeros(this.element.STORE,4)
      console.log(this.ctcons);
      
    this.TallyHeaders = this.storerService.getAllStorerContacts(this.user, this.ctcons)
      .subscribe((data:any) => {
        this.result = data; 
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('ALL STORER CONTACTS DATA');
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }
 

  toggleColumn(column: any) {
    if (column.isActive) {
      if (column.possition > this.displayedColumns.length - 1) {
        this.displayedColumns.push(column.name);
      } else {
        this.displayedColumns.splice(column.possition, 0, column.name);
      }
    } else {
      let i = this.displayedColumns.indexOf(column.name);
      let opr = i > -1 ? this.displayedColumns.splice(i, 1) : undefined;
    }
  }


  initializeColumnProperties() {
    this.displayedColumns.forEach((element, index) => {
      if (element != 'Actions' && element != 'Carrier') {
        this.columnShowHideList.push({
          possition: index,
          name: element,
          isActive: true,
        });
      }
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length,
      name: 'Cell Phone Number',
      isActive: false,
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length - 1,
      name: 'Fax Number',
      isActive: false,
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length - 1,
      name: 'EMail Address',
      isActive: false,
    });

  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }

  
  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }
  
  storerContactBySearch(storeContact:any){

    const prefixWithZeros = (number: any, length: number) => String(number).padStart(length, '0');
      console.log(prefixWithZeros(this.element.STORE,4));

      this.ctcons='STOR'+prefixWithZeros(this.element.STORE,4)
      console.log(this.ctcons);
      
    console.log('********');
    this.TallyHeaders = this.storerService.getStorerContactsBySearch(this.user, this.ctcons, storeContact.contactType, storeContact.name, storeContact.status)
      .subscribe((data:any) => {
        this.result = data; 
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('STORER CONTACTS BY SEARCH');
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });

  }

  storerContactCreate(storer:any){

    this.matDialog.open(StorerContactCreateComponent, {
      "width": '1000px',
     "maxHeight": '80vh',
  
     "data": storer,
     "autoFocus": false
   });
   
 }

 storerConatactUpdate(storer:any){

  this.matDialog.open(StorerContactUpdateComponent, {
    "width": '1000px',
   "maxHeight": '80vh',

   "data": storer,
   "autoFocus": false
 });
}

storerConatactDelete(storer:any){

  this.matDialog.open(StorerContactDeleteComponent, {
    "width": '1000px',
   "maxHeight": '80vh',

   "data": storer,
   "autoFocus": false
 });
}
onBack() {
  this.router.navigate(['/main-nav/storer']);
}



}
