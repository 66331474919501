import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ww-order-events-delete',
  templateUrl: './ww-order-events-delete.component.html',
  styleUrls: ['./ww-order-events-delete.component.css']
})
export class WwOrderEventsDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
