<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<mat-card>
    <mat-card-header>
   <mat-card-title>
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Lot Code Inquiry</mat-card-title>
    </mat-card-header>
  
     <form [formGroup]="form">
       <div class="row">
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Storer</mat-label>
                   <input matInput formControlName="Storer"/>
               </mat-form-field>           
           </div> 
           <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Item</mat-label>
              <input matInput formControlName="Item"/>
          </mat-form-field>   
           </div>   
    </div>     
   </form>
   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
       <ng-container matColumnDef="Location">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Location
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Location}}</td>
         </ng-container>
         <ng-container matColumnDef="Available">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Available
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Available}}</td>
         </ng-container>
  
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
