<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Delete Workflow Step</mat-card-title>
      &nbsp;
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer </mat-label>
            <input matInput formControlName="Storer" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Process</mat-label>
            <input matInput formControlName="Process" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Account Step </mat-label>
            <input matInput formControlName="AccountStep" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Processing Task </mat-label>
            <input matInput formControlName="ProcessingTask" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Type of Condition </mat-label>
            <input matInput formControlName="TypefCondition" />
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Responsible Profile </mat-label>
            <input matInput formControlName="ResponsibleProfile" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Standard Hours </mat-label>
            <input matInput formControlName="StandardHours" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Status Code </mat-label>
            <input
              matInput
              formControlName="StatusCode"
              maxlength="2"
              style="text-transform: uppercase"
            />
          </mat-form-field>
        </div>
      </div>
      <div>
        <button mat-button (click)="deleteAccountProcessing()" class="removeButton">
          Delete
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
