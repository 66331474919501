<!-- form No : 729 -->
<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
        Work With Consignee Based Triggers</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>Cons:</mat-label>
                      <input matInput formControlName="cons"/>
                      </mat-form-field> 
                      <mat-form-field appearance="outline">
                        <mat-label>Doc:</mat-label>
                       <input matInput formControlName="doc"/>
                       </mat-form-field> 

                       <mat-form-field appearance="outline">
                        <mat-label>TypeNumber:</mat-label>
                       <input matInput formControlName="typeNumber"/>
                       </mat-form-field> 

                       <mat-form-field appearance="outline">
                        <mat-label>generated:</mat-label>
                       <input matInput formControlName="generated"/>
                       </mat-form-field> 
                     </div>
                     <div class="col">
                        <mat-form-field appearance="outline">
                            <mat-label>Status:</mat-label>
                           <input matInput formControlName="status"/>
                           </mat-form-field> 
                     </div>
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="consignee">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Consignee
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.consignee }}</td>
        </ng-container>
  
        <ng-container matColumnDef="documentType">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            DocumentType
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.documentType }}</td>
        </ng-container>
  
        <ng-container matColumnDef="documentNumber">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                DocumentNumber
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.documentNumber }}
                
            </td>
          </ng-container>
          
        <ng-container matColumnDef="generatedDate">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                GeneratedDate
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.generatedDate }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="generatedTime">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                GeneratedTime
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.generatedTime }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="processedDate">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                ProcessedDate
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.processedDate }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="processedTime">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                ProcessedTime
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.processedTime }}
                
            </td>
          </ng-container>

          <ng-container matColumnDef="sts">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Sts
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.sts }}
                
            </td>
          </ng-container>
  
        <ng-container matColumnDef="Opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            Opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
                  
                   <mat-option>
                  <button mat-icon-button >
                    <mat-icon mat-icon-button color="primary" (click)="triggersChangeForm(element)">call_made</mat-icon>
                    <mat-label>CHANGE</mat-label>
                  </button>
                </mat-option>  
              
                  <mat-option>
                    <button mat-icon-button >
                      <mat-icon mat-icon-button color="primary" (click)="TriggersAddForm(element)">add</mat-icon>
                      <mat-label>ADD</mat-label>
                    </button>
                  </mat-option>   
                
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
      </table>
