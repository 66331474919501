<mat-card class="over-Flow">
<div class="main-header-title">
  <mat-card-header>
    <mat-card-title>Delete a Balance Record</mat-card-title>
  </mat-card-header>
  <button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
</div>


    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" class="form over-Flow">
      <div class="row">  
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Building </mat-label>
          <input readonly matInput formControlName="Building" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Storer </mat-label>
          <input readonly matInput formControlName="StorerNumber" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Item </mat-label>
          <input readonly matInput formControlName="Item" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Lot Code</mat-label>
          <input readonly matInput formControlName="LotCode1" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Date</mat-label>
          <input readonly matInput formControlName="Date" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Type </mat-label>
          <mat-select [disabled]="true" formControlName="RecordType">
            <mat-option value="P">P = Period</mat-option>
            <mat-option value="D">D = Daily</mat-option>
            <mat-option value="W">W = Weekly</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      </div>
      <div id="custom-alert">
        <div class="custom-alert-content">
          <button mat-button class="buttons removeButton" 
          (click)="deleteItemBalance()">Delete</button>
  
        </div>
      </div>
      
      <!-- <button class="buttons removeButton"
          mat-raised-button
          (click)="deleteItemBalance()">
          <span>Delete</span>
      </button> -->
      
    </form>
    </mat-card-content>

</mat-card>
