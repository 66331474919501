import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-email-exit-points-create',
  templateUrl: './email-exit-points-create.component.html',
  styleUrls: ['./email-exit-points-create.component.css'],
})
export class EmailExitPointsCreateComponent implements OnInit {
  emailExitPointsForm = this.fb.group({
    EPPOIN: [],
    EPEFMT: [],
    EPNTYP: [],
    EPCTYP: [],
    EPMNAM: [],
    EPMBLD: [],
    EPSTAT: [],
  });

  constructor(
    private dialogRef: MatDialogRef<EmailExitPointsCreateComponent>,
    private fb: FormBuilder,
    private router: Router
  ) {}

  add() {}

  closeDialog() {
    this.dialogRef.close();
  }
  ngOnInit(): void {}
}
