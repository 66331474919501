import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-kanban-change',
  templateUrl: './kanban-change.component.html',
  styleUrls: ['./kanban-change.component.css']
})
export class KanbanChangeComponent implements OnInit {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<KanbanChangeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      KBNUMB : [null], //cardNumber
      KBSTOR: [null], //storer
      KBSSFX:[null],//storer sugffix
      KBITEM:[null], //itemCode
      KBQTY:[null], //requestedQuantity
      KBLOCN:[null] //deleverToLocation

     


    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('added');
  }

}
