<button mat-icon-button (click)="closeDialog()">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
  <mat-card>
    <mat-card-header>
   <mat-card-title style="color: rgb(203, 207, 219)">
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Apply Receipts To PO</mat-card-title>
    </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form">
        <div class="row">
          <div class="col">            
                <mat-form-field appearance="outline">
                    <mat-label>Tally</mat-label>
                    <input matInput formControlName="P6TALY"/>
                </mat-form-field>
                <mat-form-field appearance="outline">
                 <mat-label>Line</mat-label>
                 <input matInput formControlName="P6SEQ"/>
             </mat-form-field>
                       
            </div>
            </div>
            
 
            <div class="col">
             <mat-form-field appearance="outline">
                 <mat-label>Quantity Received</mat-label>
                 <input matInput formControlName="IDQREC"/>
             </mat-form-field>

             </div>
             <div >
              <mat-form-field appearance="outline">
                <mat-label>Quantity Applied</mat-label>
                <input matInput formControlName="QTYA"/>
            </mat-form-field>

             </div>
             <div>
              <mat-form-field appearance="outline">
                <mat-label>Difference</mat-label>
                <input matInput formControlName="QTYD"/>
            </mat-form-field>  
                   
                   
        </div>           
           
         </form>
        </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="PO Number">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             PO Number
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.P6PO}}</td>
          </ng-container>
          <ng-container matColumnDef="Line">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Line
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.P6LINE}}</td>
          </ng-container>
          <ng-container matColumnDef="Applied">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Applied
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.P6QTYA}}</td>
          </ng-container>
          <ng-container matColumnDef="ST">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             ST
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.P6STAT}}</td>
          </ng-container>
          
 
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
        #paginator
        [pageSizeOptions]="[4, 8, 16]"
        showFirstLastButtons
      ></mat-paginator>
 