<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>
   <mat-card-title>
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Work With Document Object Authority</mat-card-title>
    </mat-card-header>

     <form [formGroup]="form">
        <div class="row" style="float:left;" >
           <div class="col">
               <mat-form-field appearance="outline">
                   <mat-label>Object</mat-label>
                   <input matInput formControlName="Object"/>
               </mat-form-field>
            
       </div>   
    </div>     
   </form>
   <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
       <ng-container matColumnDef="User">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            User
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.User}}</td>
         </ng-container>
         <ng-container matColumnDef="Authority">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Authority
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Authority}}</td>
         </ng-container>
         <ng-container matColumnDef="Opt">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Opt
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Opt}}</td>
         </ng-container>
        
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table>
 </mat-card>
