import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-consolidate-ledger-code',
  templateUrl: './consolidate-ledger-code.component.html',
  styleUrls: ['./consolidate-ledger-code.component.css'],
})
export class ConsolidateLedgerCodeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ConsolidateLedgerCodeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      LELEGC: '100 Handling Charges',
      LECC: 'H',
      LEGC: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
