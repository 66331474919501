import { Component, OnInit, ViewChild,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-ww-locater-resequence',
  templateUrl: './ww-locater-resequence.component.html',
  styleUrls: ['./ww-locater-resequence.component.css']
})
export class WwLocaterResequenceComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  displayedColumns: string[] = [
    
    'Sequence Number',
    'Location',
    'Asile',
    'Slot',
    'Lot',
    'First Received',
    'On hand',
   
  ];
  dataSource:any=[]
  
  
  duplicateSource:any =[
    {
      LCSQT:'20200126',
      LCBLDG:'1',
      LCASIL:'251',
      LCSLOT:'091',
      LCLTC1:'20200126',
      LCRCVD:'04/16/18',
      ONHND:'90',
        
    },
   
  ]
  
  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<WwLocaterResequenceComponent>
  ) { }
  
  ngOnInit(): void {
  
  
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  
  }
  addAttribute(){

  }
closeDialog(){
  this.dialogRef.close();

}

}
