<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Event Entry </mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Bill of Lading </mat-label>
            <input matInput formControlName="BillofLoading" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Storer </mat-label>
            <input matInput formControlName="Storer" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Consignee </mat-label>
            <input matInput formControlName="Consignee" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Event Type </mat-label>
            <input matInput formControlName="EventType" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Code </mat-label>
            <input matInput formControlName="Code" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Event Date </mat-label>
            <input matInput formControlName="EventDate" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Time </mat-label>
            <input matInput formControlName="Time" />
          </mat-form-field>

        </div>
        </div>
      </form>
    </mat-card-content>
</mat-card>