import { IitemService } from './../iitem.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { ItemCreate2Component } from '../item-create2/item-create2.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-item',
  templateUrl: './create-item.component.html',
  styleUrls: ['./create-item.component.css'],
})
export class CreateItemComponent implements OnInit {
  constructor(
    private router: Router,
    public itemSvc: IitemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<CreateItemComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({

      storeNumber: this.anyvariable.storer,
      suffix: '',
      item: '',
      itemDescription1: '',
      itemDescription2: '',
      itemUpcCode: '',
      itemUpcTailCode: '', //tail-code
      manufacturerProdCode: '', //product code
      quickItem: 'N', //allow web selection
      lottedItem: 'N', 
      lotUnitWeight: 'N',
      lotSortMask: '', //sort mark
      primSortMaskPointer: '', //apply to lot
      prevItem: '', //place after
    });
  }

  createItem(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemCreate1( 
        
        res.storeNumber,
        res.suffix,
       res.item,
       res.itemDescription1,
        res.itemDescription2,
        res.itemUpcCode,
       res.itemUpcTailCode,
        res.manufacturerProdCode,
        res.quickItem,
        res.lottedItem,
        res.lotUnitWeight,
        res.lotSortMask,
        res.primSortMaskPointer,
        res.prevItem
        

        ).subscribe((data: any) => {
        this.result = data;
       var length = this.result[0].data.length;
          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Successfully Created'){
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.Item2();         
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/item/read']);
  }


  closeDialog(){
    this.dialogRef.close();
  }

  Item2() {
    var res = this.form.value;
    this.matDialog.open(ItemCreate2Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }
}
