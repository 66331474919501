import { TallyDetailCommentsComponent } from './tally-detail-comments/tally-detail-comments.component';
import { MaintainComponent } from './maintain/maintain.component';
import { FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { IheaderService } from './iheader.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CommentsComponent } from './comments/comments.component';
import { TallyPostingComponent } from './tally-posting/tally-posting.component';
import { TallyInquiryComponent } from './tally-inquiry/tally-inquiry.component';
import { TallyEventComponent } from './tally-event/tally-event.component';
import { TallyDetailDeleteComponent } from './tally-detail-delete/tally-detail-delete.component';
import { TallyDetailUpdateComponent } from './tally-detail-update/tally-detail-update.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { TallyData } from '../tally-data';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { CustomAlertService } from '../custom-alert/custom-alert.service';

interface CustomColumn {
  possition: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-tallys',
  templateUrl: './tallys.component.html',
  styleUrls: ['./tallys.component.css'],
})
export class TallysComponent implements OnInit {

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private matDialog: MatDialog,
    private CustomAlertService: CustomAlertService
  ) {}

  displayedColumns: string[] = [
    'Status',
    'Tally',
    'Invoice',
    'Storer',
    'ScheduleArrival',
    'ActualArrival',
    'StorerRefNo.',

    'Actions',
  ];

  @ViewChild('paginator') paginator!: MatPaginator;
  selectedRow: any;
  editedRows: any;
  GlobalComponent: any = GlobalComponent;
  result: any = [];
  dataSource: any = [];
  duplicateSource: any = [];
  singleTallyData = [];
  documents = [];
  position: any;
  name: any;
  isActive: any;
  user: any;
  building: any;
  storer1: any;
  storerSuffix: any;
  searchText: any;
  scArrival: any;
  //storer2:any;
  element: any;
  tally: any;
  searchValue: string = '';
  condition: boolean = true;
  prevText: string = '';
  TALLY_DATA: TallyData = [];
  loggedUser: any;
  searchTextDisplay = false;

  
  TallyHeaders: any = [];
  id: number = 0;
  public columnShowHideList: CustomColumn[] = [];


  updateForm = this.fb.group({
    status:'',
    tally: [null],
    storer: localStorage.getItem('storer'),
    building: localStorage.getItem('building'),
    storerRef: '',
    loadType: [null],
    recptType: [null],
    recvdFrom: [null],
    qty: [
      '',
      [
        Validators.required,
        Validators.min(0),
        Validators.pattern('[0-9]{1,10}'),
      ],
    ],
    schdldArrival: '',
    carrier: [''],
    actualArrival: [null],
    scdldTime: [null],
    actualTime: [null],
    wgt: [null],
    pro: [null],
    invoice: [null],
  });


  ngOnInit(): void {
    this.element = history.state;
    this.initializeColumnProperties();
    this.editedRows = [];
    this.loggedUser = localStorage.getItem('LoggedIn');
    if (localStorage.getItem('tally') !== null) {
      localStorage.removeItem('tally');
      localStorage.removeItem('storer1');
      localStorage.removeItem('storername');
    }
    if (localStorage.getItem('LoggedIn')) {
      this.user = localStorage.getItem('userName');
      this.building = localStorage.getItem('building');
      this.storer1 = localStorage.getItem('storer');
      this.storerSuffix = localStorage.getItem('storerSuffix');
    } else {
      this.user = GlobalComponent.user;
      this.building = GlobalComponent.building;
    }
    //this.getTallyHeaders();
    if (this.element.IDTALY == null || this.element.IDTALY == undefined) {
      this.getTallyHeaders();
    } else {
      this.getTallyBySearch3(this.element);
    }
  }


  getTallyHeaders() {
    console.log(this.building);
    this.building = this.building.replace(/\s/g, '').trim();

    this.iHeaderSvc
      .getAll2(this.user, this.building, this.storer1, this.storerSuffix)
      .subscribe((data) => {
        this.result = data;
        this.TallyHeaders = data;
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('TALLYS DATA');
        console.log(this.duplicateSource);

        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  getTallyBySearch2(tally: any) {
    var tal: any = [];
    if (
      (tally.tally == null || tally.tally == '') &&
      //(tally.storer==null || tally.storer=='') &&
      (tally.carrier == null || tally.carrier == '') &&
      (tally.storerRef == null || tally.storerRef == '') &&
      (tally.schdldArrival == null || tally.schdldArrival == '')
    ) {
      this.getTallyHeaders();
    } else {  
        console.log(tally.schdldArrival);
        
        if(tally.schdldArrival!=null && tally.schdldArrival != ''){    
          this.scArrival = tally.schdldArrival.toLocaleDateString('fr-CA');     
        } else{
          this.scArrival = '';   
        }
      this.TallyHeaders = this.iHeaderSvc
        .getByTally2(
          this.user,
          tally.tally,
          tally.storer,
          tally.carrier,
          tally.storerRef,
          this.scArrival
        )
        .subscribe((data) => {
          this.result = data;
          this.TallyHeaders = data;
          var res = this.result[0].data[1]?.value;
          var parsedData = JSON.parse(res);      
          this.dataSource = new MatTableDataSource(parsedData.returnedData);
          console.log('TALLY DATA BY SEARCH');
          console.log(parsedData.returnedData);
          this.dataSource.paginator = this.paginator;
        });
    }
  }

  getTallyBySearch3(element: any) {
    this.TallyHeaders = this.iHeaderSvc
      .getByTally2(this.user, element.IDTALY, '', '', '', '')
      .subscribe((data) => {
        this.result = data;
        this.TallyHeaders = data;
        console.log(this.TallyHeaders);
        var res = this.result[0].data[1]?.value;
        var parsedData = JSON.parse(res);
        this.dataSource = new MatTableDataSource(parsedData.returnedData);
        this.dataSource.paginator = this.paginator;
      });
  }


  deReceive(tally: any) {
    this.result = this.iHeaderSvc
      .deReceiveTallyService(this.user, tally.IHTALY, tally.IHSEQ)
      .subscribe((data: any) => {
        this.result = data;
        var length = this.result[0].data.length;

        if (
          this.result[0].data[length - 1].value ==
          'Successfully Changed Tally Status to R'
        ) {
          const msg = this.result[0].data[length - 1].value;
          console.log('De Receive Tally Success');
          this.successMessage(msg);
        } else {
          const msg = this.result[0].data[length - 1].value;
          console.log('De Receive Tally Failed');
          this.failureMessage(msg);
        }
      });
  }


  tallyDetailsComment(tally: any) {
    console.log(tally);
    this.result = this.iHeaderSvc
      .getTallyDetailComment(tally)
      .subscribe((data: any) => {
        console.log(data);
        this.result = data;

        this.matDialog.open(TallyDetailCommentsComponent, {
          width: '1000px',
          maxHeight: '80vh',
          data: tally,
          autoFocus: false,
        });
      });
  }


  toggleColumn(column: any) {
    if (column.isActive) {
      if (column.possition > this.displayedColumns.length - 1) {
        this.displayedColumns.push(column.name);
      } else {
        this.displayedColumns.splice(column.possition, 0, column.name);
      }
    } else {
      let i = this.displayedColumns.indexOf(column.name);
      let opr = i > -1 ? this.displayedColumns.splice(i, 1) : undefined;
    }
  }


  initializeColumnProperties() {
    this.displayedColumns.forEach((element, index) => {
      if (element != 'Actions' && element != 'Carrier') {
        this.columnShowHideList.push({
          possition: index,
          name: element,
          isActive: true,
        });
      }
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length,
      name: 'Carrier',
      isActive: false,
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length - 1,
      name: 'ProNo.',
      isActive: false,
    });
  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }
  

  onChangeEvent(event: any) {
    this.storer1 = event.target.value;
    if (this.storer1 != '0') {
      localStorage.setItem('storer', this.storer1);
    }
  }

  

  comment(tally: any) {
    this.matDialog.open(CommentsComponent, {
      width: '1200px',
      maxHeight: '90vh',
      data: tally,
      autoFocus: false,
    });
  }

  

  tallyPosting(tally: any) {
    this.matDialog.open(TallyPostingComponent, {
      width: '1110px',
      maxHeight: '105vh',
      data: tally,
      autoFocus: false,
    });
  }

  tallyEvent(tally: any) {
    this.matDialog.open(TallyEventComponent, {
      width: '1350px',
      maxHeight: '350vh',
      data: tally,
      autoFocus: false,
    });
  }

  tallyDetailDelete(tally: any) {
    this.matDialog.open(TallyDetailDeleteComponent, {
      width: '1000px',
      maxHeight: '80vh',

      data: tally,
      autoFocus: false,
    });
  }

  tallyDetailUpdate(tally: any) {
    this.matDialog.open(TallyDetailUpdateComponent, {
      width: '1250px',
      maxHeight: '180vh',

      data: tally,
      autoFocus: false,
    });
  }

  getTallyBySearch(tally: any) {
    var tal: any = [];
    console.log('TALLY NO :' + tally.tally);

    if (tally.tally == '') {
      tal = this.duplicateSource;
    } else {
      this.duplicateSource.forEach((element: any) => {
        console.log('*******  ******' + element);

        if (element['IHTALY'] == tally.tally) {
          tal.push(element);
        }
      });
    }
    this.dataSource = tal;
  }


  changeDateFormat(date: any): string {
    return moment(date).format('MMM Do YYYY');
  }

  getUpdateForm(element: any) {
    this.router.navigateByUrl('/main-nav/tallys/update', { state: element });
  }

  wrInquiry(element: any) {
    this.matDialog.open(TallyInquiryComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  tallyDetailsCreate() {
    this.matDialog.open(MaintainComponent, {
      width: '1000px',
      maxHeight: '80vh',
      autoFocus: false,
    });
  }

  tallyDetails(element: any) {
    this.router.navigateByUrl('/main-nav/tallys/details', { state: element });
  }

  update(tally: any) {
    console.log(tally);
    this.result = this.iHeaderSvc.getComment(tally).subscribe((data: any) => {
      this.result = data;
      this.router.navigate(['tallys/comments', tally]);
    });
  }

  

  onBack() {
    this.router.navigate(['tally/details']);
  }

  onSubmit(event: any) {
    this.condition = true;
    this.prevText = this.searchValue;
  }

  handleClick(event: Event) {
    const { target } = event;
    console.log(target);
  }

  addTally() {
    this.router.navigate(['/main-nav/tallys/add']);
  }

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }
  result1: any;
  tallyId: any;

  getTally(tally: any) {
    //
    console.log(tally);

    this.iHeaderSvc.getByTally(this.user, tally).subscribe((data) => {
      this.result1 = data;
      this.tallyId = this.result1.tallyNumber;
      console.log('******  :' + this.tallyId);
      this.router.navigate(['tallys/update', this.tallyId]);
    });
  }


  delete(tally: any) {
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete Tally No: ? ');
  
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.result = this.iHeaderSvc
          .deleteTally(tally, this.user)
          .subscribe((data: any) => {
            this.result = data;
            console.log(this.result);

            if (JSON.parse(this.result[0].data[1].value).success) {
              const msg = JSON.parse(this.result[0].data[1].value).returnedData
                .message;
              this.successMessage(msg);
              this.getTallyHeaders();
            } else {
              const msg = JSON.parse(this.result[0].data[1].value).returnedData
                .message;
              this.failureMessage(msg);
              this.getTallyHeaders();
            }
          });
      }
    });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  checkStorer(storer: any): boolean {
    if (storer == '0') {
      return false;
    } else {
      return true;
    }
  }
}

function getTallyBySearch2() {
  throw new Error('Function not implemented.');
}
