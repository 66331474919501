<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title style="color: rgb(32, 68, 145)"
                    >Maintain Book</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                  
                <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> BookHeader: </mat-label> 
                        <input matInput formControlName="bookHeader" />
                      </mat-form-field>
                  
                </div>
                
                
                <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> BookDescription: </mat-label> 
                        <input matInput formControlName="bookDescription" />
                      </mat-form-field>
                </div>

                
                <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> FrmTag: </mat-label> 
                        <input matInput formControlName="frmTag" />
                      </mat-form-field>

                </div>

                       
                <div class="col">
                   
                    <mat-form-field appearance="outline">  <mat-label> ToTag: </mat-label> 
                        <input matInput formControlName="toTag" />
                      </mat-form-field>
                </div>
              
                            <div >
                              <button mat-button class="buttonCss" (click)="attributes()">Book</button>
                          </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  
