<button mat-icon-button (click)="closeDialog()">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>
  
<form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Maintain EDI Information</mat-card-title
        >
        &nbsp;
      </mat-card-header>
      <mat-card-content>
        
              <div class="col">
                
                <mat-form-field  appearance="outline">
                    <mat-label> Storer: </mat-label> 
                    <input  readonly matInput formControlName="storeNumber" style="text-transform: uppercase"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                  <mat-label> Suffix: </mat-label> 
                  <input readonly  matInput formControlName="suffix" style="text-transform: uppercase"/>
              </mat-form-field>

              <mat-form-field  appearance="outline">
                  <mat-label>  Store Name: </mat-label> 
                  <input  readonly matInput formControlName="storeName" style="text-transform: uppercase"/>
              </mat-form-field>

            
                <mat-form-field  appearance="outline">
                    <mat-label> Wins Comms Id: </mat-label> 
                    <input matInput formControlName="winsCommsID" style="text-transform: uppercase"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> DUNS Number: </mat-label> 
                    <input matInput formControlName="dunsNumber" style="text-transform: uppercase"/>
                    <mat-error *ngIf="form.controls['dunsNumber'].hasError('pattern')">
                        Only <strong>numbers </strong> are allowed
                      </mat-error>
                </mat-form-field>
             
                <mat-form-field  appearance="outline">
                    <mat-label> Custom EDI CommsID: </mat-label> 
                    <input matInput formControlName="customEDICommsId" style="text-transform: uppercase"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>CHEP Pallet User?: </mat-label> 
                    <mat-select  formControlName="chepPalletUser">
                   
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> 856 ASN Required: </mat-label> 
                    <mat-select  formControlName="AsnRequired856">
                   
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                </mat-form-field>

                        

                <button [disabled]="!(form.valid && form.dirty)" mat-button (click)="storerUpdate6()"class="addButton buttons">
                    
                    <span>Update & Save</span>
                </button>
              
            </div>
         
      </mat-card-content>
     
    </mat-card>
  </form> 





