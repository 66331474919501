<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Delete Char Removal</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer Number</mat-label>
                    <input matInput formControlName="storerNumber" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Scan Type</mat-label>
                    <input matInput formControlName="scanType" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Qualifier Start</mat-label>
                    <input matInput formControlName="qualifierStart" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Qualifier Length</mat-label>
                    <input matInput formControlName="qualifierLength" />
                </mat-form-field>
            </div> 
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Value To Remove</mat-label>
                    <input matInput formControlName="valueToRemove" />
                </mat-form-field>
            </div> 

 
        


            
        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="delete()" class="addButton">           
        <mat-icon mat-icon-button color="primary">create</mat-icon>
        <mat-label>Equipment Operator</mat-label>
    </button>
</mat-card-footer>



