import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-expected-durations-change',
  templateUrl: './expected-durations-change.component.html',
  styleUrls: ['./expected-durations-change.component.css']
})
export class ExpectedDurationsChangeComponent implements OnInit {


  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ExpectedDurationsChangeComponent>
   ) 
   { }

  ngOnInit(): void {
    this.form = this.fb.group({
  
    inOut: 'I ',
    appointmentType:'TR Trailer',
    loadType:'P Pallet',
    accountCode:'',
    expectedDuration:'2.00',
    lastChangeDate:'09/30/22 14:36',
    lastChangedBy:'TBALDWIN',

   
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}
