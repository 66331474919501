import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-update-data-element',
  templateUrl: './update-data-element.component.html',
  styleUrls: ['./update-data-element.component.css'],
})
export class UpdateDataElementComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<UpdateDataElementComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      dataElementCode: 400,
      dataElementDefinition: 'Labor Planning',
      InternalExternal: 'I',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  updateDataElement() {
    console.log('updated');
  }
}
