import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IitemService } from '../../iitem.service';
import { GlobalComponent } from 'src/app/Global-Component';

@Component({
  selector: 'app-item-lots-update',
  templateUrl: './item-lots-update.component.html',
  styleUrls: ['./item-lots-update.component.css']
})
export class ItemLotsUpdateComponent implements OnInit {
  
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  GlobalComponent: any = GlobalComponent;


  constructor(
    private router: Router,
    public itemSvc: IitemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemLotsUpdateComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({

      storer:this.anyvariable.LTSTOR,
      suffix: this.anyvariable.LTSSFX,
      item: this.anyvariable.LTITEM, 
      
      lot1: this.anyvariable.LTLOT1, 
      lot2: this.anyvariable.LTLOT2, 
      lot3: this.anyvariable.LTLOT3,
      lotUnitWeight: this.anyvariable.LTLUWT, 
      lotFirstReceived: this.anyvariable.LTRCVD,
      lotLastShipped: this.anyvariable.LTSHPL,
      status: this.anyvariable.LTSTAT,

    });
  }



  updateItemLot(){
    var res = this.form.value;   
    this.result = this.itemSvc
      .updateItemLotsService( 
        
        this.user,
        res.storer,
        res.suffix,
        res.item,
        res.lot1,
        res.lot2,
        res.lot3,
        res.lotUnitWeight,
        res.lotFirstRecieved,
        res.lotLastShipped,
        res.status        

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Updated Item Lot');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       //console.log(this.result[0].data[16].value);
       console.log("*********************");
       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
        if(JSON.parse(this.result[0].data[1].value).success){
          //alert('Record Created SuccessFully');
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          //this.router.navigate(['/main-nav/item/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
  }


  successMessage(msg:any){
    Swal.fire(msg,'','success');
    //this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

 

}
