<mat-card class="over-Flow">

  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Complete Move</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"><mat-label> Storer : </mat-label>
            <input readonly matInput formControlName="storer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Item Code: </mat-label>
            <input readonly matInput formControlName="item" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline"><mat-label> Transaction No: </mat-label>
            <input readonly matInput formControlName="transNum" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Lots : </mat-label>
            <input readonly matInput formControlName="lots" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Form: </mat-label>
            <input readonly matInput formControlName="from" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> To : </mat-label>
            <input readonly matInput formControlName="to" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Quantity: </mat-label>
            <input readonly matInput formControlName="quantity" style="text-transform: uppercase" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Move Type: </mat-label>
            <input readonly matInput formControlName="type" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Priority: </mat-label>
            <input readonly matInput formControlName="priority" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Operator: </mat-label>
            <input readonly matInput formControlName="operator" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Status: </mat-label>
            <input readonly matInput formControlName="status" />
          </mat-form-field>



        </div>
      </div>
      <button mat-button class="buttons addButton" (click)="updateMoveComplete()">
        Complete Move
      </button>
    </form>
  </mat-card-content>


</mat-card>