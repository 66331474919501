import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';

import { PeriodChangeComponent } from './period-change/period-change.component';
import { PeriodDeleteComponent } from './period-delete/period-delete.component';
import { PeriodsAddComponent } from './periods-add/periods-add.component';
import { PeriodsService } from './periods.service';

@Component({
  selector: 'app-ww-periods',
  templateUrl: './ww-periods.component.html',
  styleUrls: ['./ww-periods.component.css'],
})
export class WwPeriodsComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'STDSC',
    'PESTDT',
    'PEENDT',
    'PEBALN',
    'PEINV',
    'PESTAT',
    'Actions',
  ];

  dataSource: any = [];

  // duplicateSource:any =[
  //   {
  //     STDSC:'0013 NEILS ITEM STORER',
  //     PESTDT:'11/01/21',
  //     PEENDT:'11/30/21',
  //     PEBALN:'',
  //     PEINV:'',
  //     PESTAT:'AV'

  //   },
  //   {
  //     STDSC:'0013 NEILS ITEM STORER',
  //     PESTDT:'12/01/21',
  //     PEENDT:'12/31/21',
  //     PEBALN:'',
  //     PEINV:'',
  //     PESTAT:'AV'
  //   }
  // ]

  constructor(
    private router: Router,
    // public iHeaderSvc: IheaderService,
    public periodsService: PeriodsService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  result: any;
  user = localStorage.getItem('userName');
  duplicateSource: any = [];
  element: any;

  ngOnInit(): void {
    this.element = history.state;

    this.form = this.fb.group({
      STOR: this.element.STORE,
      STDT: '',
    });

    this.periodsService
      .getAll(this.user, this.element.STORE)
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        console.log(res);

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log(this.dataSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  searchTextDisplay: any;

  searchTally() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }

  //get Periods by search
  getPeriod(storer: any) {
    this.periodsService
      .getAll2(this.user, storer.STOR, storer.STDT)
      .subscribe((data) => {
        this.result = data;
        var res = this.result[0].data[1]?.value;
        console.log(res);

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log(this.dataSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  onBack() {
    this.router.navigate(['/main-nav/storer']);
  }

  addPeriod() {
    var element = this.form.value;
    this.matDialog.open(PeriodsAddComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  change(element: any) {
    this.matDialog.open(PeriodChangeComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }

  delete(element: any) {
    this.matDialog.open(PeriodDeleteComponent, {
      width: '1000px',
      maxHeight: '80vh',
      data: element,
      autoFocus: false,
    });
  }
}
