<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
  
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Event Entry</mat-card-title>
        &nbsp;
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Bill Of Lading</mat-label>
              <input matInput formControlName="billOfLading" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Storer</mat-label>
              <input matInput formControlName="storer" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Consignee</mat-label>
              <input matInput formControlName="consignee" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Event Type </mat-label>
              <input matInput formControlName="eventType" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Code </mat-label>
              <input matInput formControlName="code" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Event Date </mat-label>
              <input type="date" matInput formControlName="eventDate" />
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label> Time </mat-label>
              <input type="time" matInput formControlName="time" />
            </mat-form-field>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  
