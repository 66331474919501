import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../iheader.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { UpdateTallyDtlCommentsComponent } from './update-tally-dtl-comments/update-tally-dtl-comments.component';
@Component({
  selector: 'app-tally-detail-comments',
  templateUrl: './tally-detail-comments.component.html',
  styleUrls: ['./tally-detail-comments.component.css'],
})
export class TallyDetailCommentsComponent implements OnInit {
  constructor(
    private activeRouter: ActivatedRoute,
    public iHeaderSvc: IheaderService,
    private fb:FormBuilder,
    private matDialog: MatDialog,
    private router : Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<TallyDetailCommentsComponent>
  ) {}

  user=localStorage.getItem('userName');
  id: any;
  result: any;
  dataSource:any;
  duplicateSource:any;

  displayedColumns: string[] = [
    'Comments', 
    'Actions'
  ];

  form = this.fb.group({
    tally:this.anyvariable.IDTALY,
    seq:this.anyvariable.IDSEQ,
    comments:'',
    status:'',
    sub:'WR',
    sId:'WEB'
    
  })
  ngOnInit(): void {
    this.getComment();
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  getComment(){
    var res = this.form.value; 
    console.log(res);

    this.iHeaderSvc.getTallyComment(this.user,res.status,res.tally, res.seq, res.sub).subscribe((data) => {
      this.result = data;
      var res = this.result;
      var res = this.result[0].data[1]?.value;
      console.log(res);
      console.log(res);
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      //this.dataSource.replaceAll("BUILD#","BUILD");
      this.dataSource = new MatTableDataSource(this.duplicateSource);
      this.dataSource.paginator = this.paginator;
    });
  }

  createTallyDetailComment() {
    var res = this.form.value;
    
    this.result = this.iHeaderSvc
      .createTallyCommentService(
        this.user,
        res.status,
        res.tally,
        res.seq,
        res.sub,
        res.comments,
        res.sId

       )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       if(JSON.parse(this.result[0].data[1].value).success){

        //alert('Record Created SuccessFully');

       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg);
        this. closeDialog();
       // this.router.navigate(['/main-nav/tallys/details']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
      });
  }


  updateTallyComment(res: any) {
    // this.router.navigate(['tallys/posting',tally]);
    this.matDialog.open(UpdateTallyDtlCommentsComponent, {
      width: '1100px',
      maxHeight: '80vh',
      data: res,
      autoFocus: false,
    });
  }

  deleteTallyComment(){
    var res = this.form.value;
    console.log('FORM VALUE IS : ')
    console.log(res.tally);
    this.iHeaderSvc.deleteTallyCommentService(this.user,res.status,res.tally,res.seq,res.sub).subscribe((data) => {
      this.result = data;
      if(JSON.parse(this.result[0].data[1].value).Success){

       const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.successMessage(msg);

        this. closeDialog();

        this.router.navigate(['/main-nav/tallys/details']);
     }else{
      const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(msg);
     }
    
    });
  }


  successMessage(msg:any){
    Swal.fire(msg,'','success');
   // this.router.navigate(['/main-nav/tallys/details']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
  
  closeDialog() {
    this.dialogRef.close();
  }
}
