<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title fxLayoutAlign="center"
        >Load a line onto the Movement Queue</mat-card-title
      >
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()" class="closeIcon">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer: </mat-label>
            <input matInput formControlName="storer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Bill Number: </mat-label>
            <input matInput formControlName="bol" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Line: </mat-label>
            <input matInput formControlName="seq" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Priority: </mat-label>
            <input matInput formControlName="priority" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Staging/Door Location: </mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>
        </div>
      </div>
      <div>
        <button
          mat-button
          (click)="orderDtlMoveQueue()"
          class="buttons addButton"
        >
          <span>Send</span>
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
