import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-ww-inbound-details-activity-inquiry',
  templateUrl: './ww-inbound-details-activity-inquiry.component.html',
  styleUrls: ['./ww-inbound-details-activity-inquiry.component.css']
})
export class WwInboundDetailsActivityInquiryComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Date',
    'Storer',
    'Reference',
    'Wr/BOL',
    'Description',
    'Amount',
    'Balance',
   
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      DATE:'',
      STORE:'',
      REF:'',
      ABTA:'',
      DESC:'',
      QTY:'',
      BALNC:'',
     
    },


  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwInboundDetailsActivityInquiryComponent>
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
    STORE:'13 Neils Item Storer',
    ITDSC:'TEST 1 test item 1',
    STR:'02/01/23',
    END:'02/28/23',
    LADJS:'N',
   


    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/WwInboundDetails/read']);
  }

  closeDialog(){
      this.dialogRef.close();
  }


}