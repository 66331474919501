import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-srch-attr-inquiry',
  templateUrl: './srch-attr-inquiry.component.html',
  styleUrls: ['./srch-attr-inquiry.component.css'],
})
export class SrchAttrInquiryComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<SrchAttrInquiryComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      Consignee: 'RAT',
      Zip: [null],
      Street: [null],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  SrchAtttrInquiry() {
    console.log('search');
  }
}
