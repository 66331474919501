<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
       Work With Expected Durations</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>In/Out:</mat-label>
                      <input matInput formControlName="inOut"/>
                      </mat-form-field>
  
                       </div>
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="inOut">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            InOut
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.inOut }}</td>
        </ng-container>
  
        <ng-container matColumnDef="appointmentType">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            AppointmentType
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.appointmentType }}</td>
        </ng-container>
  
        <ng-container matColumnDef="loadType">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                LoadType
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.loadType }}</td>
          </ng-container>
  
          <ng-container matColumnDef="accountCode">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                AccountCode
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.accountCode }}</td>
          </ng-container>
         
          
          <ng-container matColumnDef="expectedDuration">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                ExpectedDuration
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.expectedDuration }}</td>
          </ng-container>
  
        <ng-container matColumnDef="opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">

               <mat-option>
                <button mat-icon-button (click)="ExpectedDurationsAddForm(element)">
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label>ExpectedDuration ADD</mat-label>
                </button>
            
              </mat-option>
              
         <mat-option>
            <button mat-icon-button (click)="ExpectedDurationsChangeForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>ExpectedDuration Change</mat-label>
            </button>
        
          </mat-option> 
               
         <mat-option>
            <button mat-icon-button (click)="ExpectedDurationsDeleteForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>ExpectedDuration Delete</mat-label>
            </button>
        
          </mat-option> 
        
        

          

       
  
         
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
