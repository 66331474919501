<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
<form [formGroup]="form">
    <mat-card>
      <mat-card-header>
          <button mat-button (click)="backstorer()">
              <mat-icon></mat-icon>
              
          </button>
          
        <mat-card-title style="color: rgb(145, 128, 32)"
          >Maintain Audit Comments</mat-card-title>
        
      </mat-card-header>
      
      <mat-card-content> 

       

              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>loadNumber</mat-label>
                  <input matInput placeholder="Comment" formControlName="loadNumber"/>
              </mat-form-field>
              </div>

              <mat-form-field appearance="outline">
                <mat-label>Audit Comment</mat-label>
                <textarea matInput placeholder="Comment" formControlName="Comment"></textarea>
            </mat-form-field>
                  <div >
                    <button mat-button class="buttonCss" (click)="attributes()">Comments</button>
                </div>
              
      </mat-card-content>
    </mat-card>
  </form>  

