<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Maintain Warehouse Map</mat-card-title>
    </mat-card-header>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>

  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"><mat-label> Building Number </mat-label>
            <input matInput formControlName="BuildingNumber" />
            <mat-error *ngIf="form.controls['BuildingNumber'].hasError('pattern')">
              Only <strong>numbers </strong> are allowed
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Floor </mat-label>
            <input matInput formControlName="Floor" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Aisle </mat-label>
            <input matInput formControlName="Aisle" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Slot </mat-label>
            <input matInput formControlName="Slot" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Pallets High</mat-label>
            <input matInput formControlName="PalletsHigh" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Deep </mat-label>
            <input matInput formControlName="Deep" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Slot Height </mat-label>
            <input matInput formControlName="SlotHeight" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Width </mat-label>
            <input matInput formControlName="Width" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Depth </mat-label>
            <input matInput formControlName="Depth" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Picking Sequence </mat-label>
            <input matInput formControlName="PickingSequence" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Warehouse Zone</mat-label>
            <input matInput formControlName="WarehouseZone" />
            <mat-error *ngIf="form.controls['WarehouseZone'].hasError('required')">
              Warehouse Zone is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Reserved Slot? </mat-label>

            <mat-select formControlName="ReservedSlot">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
            <mat-error *ngIf="form.controls['ReservedSlot'].hasError('required')">
              <strong>*required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Reserved Storer Number </mat-label>
            <input matInput formControlName="ReservedStorerNumber" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Reserved Storer Suffix </mat-label>
            <input matInput formControlName="ReservedStorerSuffix" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Reserved Item code </mat-label>
            <input matInput formControlName="ReservedItemcode" />
          </mat-form-field>
        </div>
      </div>
        <button class="buttons addButton" mat-raised-button (click)="addMapLocation()">
          Add
        </button>
    </form>
  </mat-card-content>
</mat-card>