<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"
      >Work With Activity Descriptions
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="buttons">
      <button
        mat-button
        (click)="activityDescriptionCreate()"
        class="addButton buttons"
      >
        <mat-icon>add</mat-icon>
        <span>New Activity Description</span>
      </button>
    </div>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Activity</mat-label>
            <input matInput formControlName="Activity" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="Activity">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Activity
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IHSTOR }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Description
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IHCHGW }}</td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button
              mat-icon-button
              (click)="activityDescriptionUpdate(element)"
            >
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button
              mat-icon-button
              (click)="activityDescriptionDelete(element)"
            >
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
          #paginator
          [pageIndex]="0"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 100]"
        >
        </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
