import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import Swal from 'sweetalert2';
import { EquipmentoperatorService } from '../../equipmentoperator.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-equipment-operators',
  templateUrl: './change-equipment-operators.component.html',
  styleUrls: ['./change-equipment-operators.component.css'],
})
export class ChangeEquipmentOperatorsComponent implements OnInit {
  form!: FormGroup;

  constructor(
    public eopSvc: EquipmentoperatorService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ChangeEquipmentOperatorsComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      Operator: this.anyvariable.EOUSER,
      Building: this.anyvariable.EOBLDG,
      Storer: this.anyvariable.EOSTOR,
      Suffix: this.anyvariable.EOSSFX,
      Shift: '',
      Status: this.anyvariable.EOSTAT,
      From1: '',
      To1: '',
      From2: '',
      To2: '',
      From3: '',
      To3: '',
      From4: '',
      To4: '',
    });
  }

  result: any;
  user = localStorage.getItem('userName');

  changeEquipmentOperator() {
    var res = this.form.value;
    console.log(res);
    for (var i = res.Building.length; i < 3; i++) {
      res.Building = ' ' + res.Building;
    }
    this.result = this.eopSvc
      .updateEquipmentOperator(
        res.Operator,
        res.Storer,
        this.anyvariable.EOSSFX,
        res.Building,
        res.Status,
        this.user
      )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
        // var msg = JSON.parse(this.result).returnedData.message;

        // this.successMessage(msg);
        if (JSON.parse(this.result[0].data[1].value).success) {
          //alert('Record Created SuccessFully');
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/equipmentOperators/read']);
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.failureMessage(msg);
        }
      });
  }
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/equipmentOperators/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
