
<mat-card class="over-Flow">
    <mat-card-header>  
      <mat-card-title  class="title">
  
        Work With Locater</mat-card-title>
    </mat-card-header>
  <mat-card-content>
    <!-- <div class="buttons">
      <button mat-button (click)="StorerAttributesCreateForm()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>New Storer Attribute</span>
      </button>
    </div> -->
                
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" style="padding-right: 14px;">
            <mat-label>Storer</mat-label>
              <input matInput formControlName="STOR"/>
          </mat-form-field>

          <mat-form-field appearance="outline" style="padding-right: 14px;">
            <mat-label>Storer Suffix</mat-label>
              <input matInput formControlName="STRX"/>
          </mat-form-field>
          
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" style="padding-right: 14px;">
            <mat-label>Item</mat-label>
            <input matInput formControlName="ITEM" />
          </mat-form-field>

          <mat-form-field appearance="outline" style="padding-right: 14px;">
            <mat-label>Location</mat-label>
            <input matInput formControlName="LOCN" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" style="padding-right: 14px;">
            <mat-label>Lot 1</mat-label>
            <input matInput formControlName="LOT1" />
          </mat-form-field>

          <mat-form-field appearance="outline" style="padding-right: 14px;">
            <mat-label>Lot 2</mat-label>
            <input matInput formControlName="LOT2" />
          </mat-form-field>

          <mat-form-field appearance="outline" style="padding-right: 14px;">
            <mat-label>Lot 3</mat-label>
            <input matInput formControlName="LOT3" />
          </mat-form-field>
          
             <button
              class="addButton"
                 mat-raised-button
                    type="submit"
                    placeholder="Search">
                    Search
              </button>
            </div>
          </div>
        </form>
                   
    </mat-card-content>
     <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="Location">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
          Location
        </th> 
        <td mat-cell *matCellDef="let element">{{ element.LOCAT }}</td>
      </ng-container>

      <ng-container matColumnDef="Storer">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Storer
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LCSTOR }}</td>
        </ng-container>

        <ng-container matColumnDef="Item">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Item
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LCITEM }}</td>
          </ng-container>
        
          
        <ng-container matColumnDef="First Received">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                First Received
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LCRCVD }}</td>
          </ng-container>

          <ng-container matColumnDef="Total Capacity">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Total Capacity
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.TCAP }}</td>
          </ng-container>

          <ng-container matColumnDef="On hand">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                On hand
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.ONHND }}</td>
          </ng-container>
          
        
      <ng-container matColumnDef="Actions">
        <th  mat-header-cell mat-header-cell *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-select  placeholder="Select Option">
     
          <mat-option>
          <button mat-icon-button (click)="LocaterInquiryForm(element)">
            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
            <mat-label>Inquiry</mat-label>
          </button>
        </mat-option>  
        
        <mat-option>
          <button mat-icon-button (click)="LocaterDeleteForm(element)">
            <mat-icon mat-icon-button color="primary">delete</mat-icon>
            <mat-label>Delete</mat-label>
          </button>
        </mat-option>

        <mat-option>
          <button mat-icon-button (click)="LocaterResequenceForm(element)">
            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
            <mat-label>Resequence</mat-label>
          </button>
        </mat-option>
        
        <mat-option>
          <button mat-icon-button (click)="Locater1sAdjustForm(element)">
            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
            <mat-label>1s Adjust</mat-label>
          </button>
        </mat-option>
        <mat-option>
          <button mat-icon-button (click)="Locater2sAdjustBBForm(element)">
            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
            <mat-label>2s Adj B-B</mat-label>
          </button>
        </mat-option>
        <mat-option>
          <button mat-icon-button (click)="Locater2sAdjustNewItmForm(element)">
            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
            <mat-label>2s Adj New Itm</mat-label>
          </button>
        </mat-option>
        <mat-option>
          <button mat-icon-button (click)="LocaterLoadScheduledForm(element)">
            <mat-icon mat-icon-button color="primary">call_made</mat-icon>
            <mat-label>Load Sheduled Floor Move</mat-label>
          </button>
        </mat-option>
        
       
          
        </mat-select>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>

      
    </table>
    <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>


