import { EnvironmentComponent } from './features/environment copy/environment.component';
import { DeleteDoorComponent } from './features/buildings/doors/delete-door/delete-door.component';
import { AddDoorComponent } from './features/buildings/doors/add-door/add-door.component';
import { NgModule,  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Angular Material Modules */
import { MatPaginatorModule } from '@angular/material/paginator';
//import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { LayoutModule } from '@angular/cdk/layout';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidebarModule } from "ng-sidebar"
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MainNavComponent } from './main-nav/main-nav.component';

/* Feature Modules */
import { ReceiveModule } from './features/receive/receive.module';
import { TallysModule } from './features/tallys/tallys.module';
import { OrdersModule } from './features/orders/orders.module';
import { ShippingModule } from './features/shipping/shipping.module';
import { AuthModule } from './features/auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { StorerComponent } from './features/storer/storer.component';
import { StorerCreateComponent } from './features/storer/storer-create/storer-create.component';
import { StorerUpdateComponent } from './features/storer/storer-update/storer-update.component';
import { StorerDeactiveComponent } from './features/storer/storer-deactive/storer-deactive.component';
import { StorerContactsComponent } from './features/storer/storer-contacts/storer-contacts.component';
import { StorerContactCreateComponent } from './features/storer/storer-contacts/storer-contact-create/storer-contact-create.component';
import { StorerContactUpdateComponent } from './features/storer/storer-contacts/storer-contact-update/storer-contact-update.component';
import { StorerContactDeleteComponent } from './features/storer/storer-contacts/storer-contact-delete/storer-contact-delete.component';
import { SroterPeriodRecordsComponent } from './features/storer/storer-period-records/sroter-period-records.component';
import { SroterWriteBalanceRecordComponent } from './features/storer/storer-write-balance-record/sroter-write-balance-record.component';
import { MapLocationComponent } from './features/map-location/map-location.component';
import { MapLocationCreateComponent } from './features/map-location/map-location-create/map-location-create.component';
import { MapLocationUpdateComponent } from './features/map-location/map-location-update/map-location-update.component';
import { MapLocationDeleteComponent } from './features/map-location/map-location-delete/map-location-delete.component';
import { MapZonesComponent } from './features/map-zones/map-zones.component';
import { CreateMapzoneComponent } from './features/map-zones/create-mapzone/create-mapzone.component';
import { UpdateMapzoneComponent } from './features/map-zones/update-mapzone/update-mapzone.component';
import { DeleteMapzoneComponent } from './features/map-zones/delete-mapzone/delete-mapzone.component';

import { LocationStatusInquiryComponent } from './features/map-location/location-status-inquiry/location-status-inquiry.component';

import { ConsigneeComponent } from './features/consignee/consignee.component';
import { ConsigneeCreateComponent } from './features/consignee/consignee-create/consignee-create.component';
import { ConsigneeUpdateComponent } from './features/consignee/consignee-update/consignee-update.component';
import { ConsigneeDeleteComponent } from './features/consignee/consignee-delete/consignee-delete.component';
import { ConsigneeXrefComponent } from './features/consignee/consignee-xref/consignee-xref.component';
import { ExitPointsComponent } from './features/exit-points/exit-points.component';
import { ExitPointsCreateComponent } from './features/exit-points/exit-points-create/exit-points-create.component';
import { ExitPointsUpdateComponent } from './features/exit-points/exit-points-update/exit-points-update.component';
import { ExitPointsDeleteComponent } from './features/exit-points/exit-points-delete/exit-points-delete.component';

import { CarriersComponent } from './features/carriers/carriers.component';
import { CreateCarriersComponent } from './features/carriers/create-carriers/create-carriers.component';
import { UpdateCarriersComponent } from './features/carriers/update-carriers/update-carriers.component';
import { DeleteCarriersComponent } from './features/carriers/delete-carriers/delete-carriers.component';
import { ConsineeCommentsComponent } from './features/consignee/consinee-comments/consinee-comments.component';
import { OrdersComponent } from './features/orders/orders.component';
import { OrderCreateComponent } from './features/orders/order-create/order-create.component';
import { OrderUpdateComponent } from './features/orders/order-update/order-update.component';
import { OrderDeleteComponent } from './features/orders/order-delete/order-delete.component';
import { OrderEventHistoryComponent } from './features/orders/order-event-history/order-event-history.component';
import { OrderDshipComponent } from './features/orders/order-dship/order-dship.component';
import { OrderSendtoMoveQueueComponent } from './features/orders/order-sendto-move-queue/order-sendto-move-queue.component';
import { OrderMoveHistoryInquiryComponent } from './features/orders/order-move-history-inquiry/order-move-history-inquiry.component';
import { MoveQueueComponent } from './features/move-queue/move-queue.component';
import { OrderCommentsComponent } from './features/orders/order-comments/order-comments.component';
import { OrderShipConfirmComponent } from './features/orders/order-ship-confirm/order-ship-confirm.component';
import { MoveQueueChangeComponent } from './features/move-queue/move-queue-change/move-queue-change.component';
import { MoveQueueCompleteComponent } from './features/move-queue/move-queue-complete/move-queue-complete.component';
import { MoveQueueEntryAssignComponent } from './features/move-queue/move-queue-entry-assign/move-queue-entry-assign.component';
import { MoveQueueEntryInquiryComponent } from './features/move-queue/move-queue-entry-inquiry/move-queue-entry-inquiry.component';
import { MoveChangeStagingLocationComponent } from './features/move-queue/move-change-staging-location/move-change-staging-location.component';
import { MoveQueueNoPickComponent } from './features/move-queue/move-queue-no-pick/move-queue-no-pick.component';
import { MoveChangeQueuePriorityComponent } from './features/move-queue/move-change-queue-priority/move-change-queue-priority.component';
import { ItemComponent } from './features/item/item.component';
import { CreateItemComponent } from './features/item/create-item/create-item.component';
import { UpdateItemComponent } from './features/item/update-item/update-item.component';
import { DeleteItemComponent } from './features/item/delete-item/delete-item.component';
import { CrossRefItemComponent } from './features/item/cross-ref-item/cross-ref-item.component';
import { ItemLotsComponent } from './features/item/item-lots/item-lots.component';
import { ItemCommentsComponent } from './features/item/item-comments/item-comments.component';
import { ItemBalancesComponent } from './features/item/item-balances/item-balances.component';
import { ItemTallyWorkWithComponent } from './features/item/item-tally-work-with/item-tally-work-with.component';
import { ItemTallyInquiryComponent } from './features/item/item-tally-work-with/item-tally-inquiry/item-tally-inquiry.component';
import { ItemAdjustmentWorkWithComponent } from './features/item/item-adjustment-work-with/item-adjustment-work-with.component';
import { ItemAdjustmentInquiryComponent } from './features/item/item-adjustment-work-with/item-adjustment-inquiry/item-adjustment-inquiry.component';
import { ItemAdjustmentDeleteComponent } from './features/item/item-adjustment-work-with/item-adjustment-delete/item-adjustment-delete.component';
import { ItemRebalancesComponent } from './features/item/item-rebalances/item-rebalances.component';
import { ItemLocationsComponent } from './features/item/item-locations/item-locations.component';
import { CreateitemLocationComponent } from './features/item/item-locations/createitem-location/createitem-location.component';
import { DeleteitemlocationComponent } from './features/item/item-locations/deleteitemlocation/deleteitemlocation.component';
import { SideAdjustmentComponent } from './features/item/item-locations/side-adjustment/side-adjustment.component';
import { OrderDetailsComponent } from './features/orders/order-details/order-details.component';
import { OrderDetailsCreateComponent } from './features/orders/order-details/order-details-create/order-details-create.component';
import { OrderDetailEditComponent } from './features/orders/order-details/order-detail-edit/order-detail-edit.component';
import { OrderDetailStockStatusComponent } from './features/orders/order-details/order-detail-stock-status/order-detail-stock-status.component';
import { OrderDetailItemStatusComponent } from './features/orders/order-details/order-detail-item-status/order-detail-item-status.component';
import { OrderDetailStackActivityComponent } from './features/orders/order-details/order-detail-stack-activity/order-detail-stack-activity.component';
import { TwoSideAdjustmentComponent } from './features/item/item-locations/two-side-adjustment/two-side-adjustment.component';
import { ReSequenceComponent } from './features/item/item-locations/re-sequence/re-sequence.component';
import { OrderDetailChangeShipFromComponent } from './features/orders/order-details/order-detail-change-ship-from/order-detail-change-ship-from.component';
import { OrderDetailSplitLineComponent } from './features/orders/order-details/order-detail-split-line/order-detail-split-line.component';
import { OrderDetailCrossDocRefComponent } from './features/orders/order-details/order-detail-cross-doc-ref/order-detail-cross-doc-ref.component';
import { OrderDetailCommentsComponent } from './features/orders/order-details/order-detail-comments/order-detail-comments.component';
import { OrderDetailCutComponent } from './features/orders/order-details/order-detail-cut/order-detail-cut.component';
import { OrderDetailChangeEventComponent } from './features/orders/order-details/order-detail-change-event/order-detail-change-event.component';
import { OrderChangeLocLotComponent } from './features/orders/order-details/order-change-loc-lot/order-change-loc-lot.component';
import { OrderDetailLotStatusComponent } from './features/orders/order-details/order-detail-lot-status/order-detail-lot-status.component';
import { OrderDetailDeleteComponent } from './features/orders/order-details/order-detail-delete/order-detail-delete.component';
import { StorerAccessInvoiceEnquiryComponent } from './features/storer/storer-access-invoice-enquiry/storer-access-invoice-enquiry.component';
import { StorerArAgingInquiryComponent } from './features/storer/storer-ar-aging-inquiry/storer-ar-aging-inquiry.component';
import { StorerRecIvcInquiryComponent } from './features/storer/storer-rec-ivc-inquiry/storer-rec-ivc-inquiry.component';
import { StorerEdiDocumentsComponent } from './features/storer/storer-edi-documents/storer-edi-documents.component';
import { StorerConsItemComponent } from './features/storer/storer-cons-item/storer-cons-item.component';
import { StorerUccSerCtlComponent } from './features/storer/storer-ucc-ser-ctl/storer-ucc-ser-ctl.component';
import { StorerUccLblCtlComponent } from './features/storer/storer-ucc-lbl-ctl/storer-ucc-lbl-ctl.component';
import { StorerPrintersComponent } from './features/storer/storer-printers/storer-printers.component';
import { StorerRatesBasesComponent } from './features/storer/storer-rates-bases/storer-rates-bases.component';
import { OrderDetailUcc128MaintComponent } from './features/orders/order-details/order-detail-ucc128-maint/order-detail-ucc128-maint.component';
import { OrderDetailMoveQueueComponent } from './features/orders/order-details/order-detail-move-queue/order-detail-move-queue.component';
import { StorerChangeRatesComponent } from './features/storer/storer-change-rates/storer-change-rates.component';
import { StorerGen869TriggerComponent } from './features/storer/storer-gen869-trigger/storer-gen869-trigger.component';
import { StorerReasonablenessComponent } from './features/storer/storer-reasonableness/storer-reasonableness.component';
import { StorerEdiInfoComponent } from './features/storer/storer-edi-info/storer-edi-info.component';
import { StorerItemDefaultsComponent } from './features/storer/storer-item-defaults/storer-item-defaults.component';
import { StorerWorkWithErrorsComponent } from './features/storer/storer-work-with-errors/storer-work-with-errors.component';

import { StorerExportComponent } from './features/storer/storer-export/storer-export.component';
import { StorerAttributesComponent } from './features/storer/storer-attributes/storer-attributes.component';
import { StorerInvoiceInquiryComponent } from './features/storer/storer-invoice-inquiry/storage-invoice-inquiry.component';
import { StorerAccountStatusComponent } from './features/storer/storer-account-status/storer-account-status.component';
import { StorerAccountAttributesComponent } from './features/storer/storer-account-attributes/storer-account-attributes.component';
import { StorerXrefComponent } from './features/storer/storer-xref/storer-xref.component';
import { StorerAcctAttributeComponent } from './features/storer/storer-acct-attribute/storer-acct-attribute.component';
import { StorerCommentsComponent } from './features/storer/storer-comments/storer-comments.component';
import { StorerExpNoActivityComponent } from './features/storer/storer-exp-no-activity/storer-exp-no-activity.component';
import { StorerProductCodesComponent } from './features/storer/storer-product-codes/storer-product-codes.component';
import { StorerAutoRatesComponent } from './features/storer/storer-auto-rates/storer-auto-rates.component';
import { StorerPutawaySchemeComponent } from './features/storer/storer-putaway-scheme/storer-putaway-scheme.component';
import { StorerWwAccountAttributesComponent } from './features/storer/storer-ww-account-attributes/storer-ww-account-attributes.component';
import { StorerAddAccountAttributeComponent } from './features/storer/storer-ww-account-attributes/storer-add-account-attribute/storer-add-account-attribute.component';
import { StorerEditAccountAttributeComponent } from './features/storer/storer-ww-account-attributes/storer-edit-account-attribute/storer-edit-account-attribute.component';
import { StorerDailyStatusComponent } from './features/storer/storer-daily-status/storer-daily-status.component';
import { StorerShortStockComponent } from './features/storer/storer-short-stock/storer-short-stock.component';
import { PostalAbbreviationsComponent } from './features/postal-abbreviations/postal-abbreviations.component';
import { PostalAbbreviationsAddComponent } from './features/postal-abbreviations/postal-abbreviations-add/postal-abbreviations-add.component';
import { PostalAbbreviationsChangeComponent } from './features/postal-abbreviations/postal-abbreviations-change/postal-abbreviations-change.component';
import { PostalAbbreviationsDeleteComponent } from './features/postal-abbreviations/postal-abbreviations-delete/postal-abbreviations-delete.component';
import { ReceivablesComponent } from './features/receivables/receivables.component';
import { ReceivableAdjustInvoiceComponent } from './features/receivables/receivable-adjust-invoice/receivable-adjust-invoice.component';
import { ReceivableCreditInvoiceComponent } from './features/receivables/receivable-credit-invoice/receivable-credit-invoice.component';
import { ReceivableInquiryComponent } from './features/receivables/receivable-inquiry/receivable-inquiry.component';
import { ReceivableAdjustInquiryComponent } from './features/receivables/receivable-adjust-inquiry/receivable-adjust-inquiry.component';
import { ReceivableCommentsComponent } from './features/receivables/receivable-comments/receivable-comments.component';
import { LoginComponent } from './login/login.component';
import { StorageInvoiceInquiryComponent } from './features/storer/storage-invoice-inquiry/storage-invoice-inquiry.component';
import { StorerAutoSchedulingProceesComponent } from './features/storer/storer-auto-scheduling-procees/storer-auto-scheduling-procees.component';
import { StorerAutoSchedulingProceesUpdateComponent } from './features/storer/storer-auto-scheduling-procees/storer-auto-scheduling-procees-update/storer-auto-scheduling-procees-update.component';
import { StorerAutoSchedulingProceesAddComponent } from './features/storer/storer-auto-scheduling-procees/storer-auto-scheduling-procees-add/storer-auto-scheduling-procees-add.component';
import { ReceivableDetailsComponent } from './features/receivables/receivable-details/receivable-details.component';
import { UpdateLedgerComponent } from './features/receivables/receivable-details/update-ledger/update-ledger.component';
import { AdjustmentInquiryComponent } from './features/receivables/receivable-details/adjustment-inquiry/adjustment-inquiry.component';
import { TransactionInquiryComponent } from './features/receivables/receivable-details/transaction-inquiry/transaction-inquiry.component';
import { StorerDeleteComponent } from './features/storer/storer-delete/storer-delete.component';
import { ActionRsmsComponent } from './features/action-rsms/action-rsms.component';
import { ActionRsmsAddComponent } from './features/action-rsms/action-rsms-add/action-rsms-add.component';
import { ActionRsmsChangeComponent } from './features/action-rsms/action-rsms-change/action-rsms-change.component';
import { StorerAutoScheduleProcresErrorComponent } from './features/storer/storer-auto-scheduling-procees/storer-auto-schedule-procres-error/storer-auto-schedule-procres-error.component';
import { ReceivablePrintComponent } from './features/receivables/receivable-print/receivable-print.component';
import { ApplicationsComponent } from './features/applications/applications.component';
import { ApplicationAddComponent } from './features/applications/application-add/application-add.component';
import { ApplicationChangeComponent } from './features/applications/application-change/application-change.component';
import { ApplicationModuleComponent } from './features/applications/application-module/application-module.component';
import { ControllBatchFileComponent } from './features/controll-batch-file/controll-batch-file.component';
import { ControllBatchFileCreateComponent } from './features/controll-batch-file/controll-batch-file-create/controll-batch-file-create.component';
import { ControllBatchFileEditComponent } from './features/controll-batch-file/controll-batch-file-edit/controll-batch-file-edit.component';
import { ControllBatchFileDeleteComponent } from './features/controll-batch-file/controll-batch-file-delete/controll-batch-file-delete.component';
import { ControllBatchFileConstraintsComponent } from './features/controll-batch-file/controll-batch-file-constraints/controll-batch-file-constraints.component';
import { AuditHeadersComponent } from './features/audit-headers/audit-headers.component';
import { AuditHeadersChangeComponent } from './features/audit-headers/audit-headers-change/audit-headers-change.component';
import { AuditHeadersCommentsComponent } from './features/audit-headers/audit-headers-comments/audit-headers-comments.component';
import { AuditHeadersStatusComponent } from './features/audit-headers/audit-headers-status/audit-headers-status.component';
import { ApplicationAuthoritiesComponent } from './features/applications/application-authorities/application-authorities.component';
import { BoxContentComponent } from './features/box-content/box-content.component';
import { BoxContentChangeComponent } from './features/box-content/box-content-change/box-content-change.component';
import { BoxContentDeactiveComponent } from './features/box-content/box-content-deactive/box-content-deactive.component';
import { BoxContentItemActivityComponent } from './features/box-content/box-content-item-activity/box-content-item-activity.component';
import { BoxContentItemLabelComponent } from './features/box-content/box-content-item-label/box-content-item-label.component';
import { BoxContentDeleteComponent } from './features/box-content/box-content-delete/box-content-delete.component';
import { BoxContentAddPressHoldComponent } from './features/box-content/box-content-add-press-hold/box-content-add-press-hold.component';
import { BoxContentRemovePressHoldComponent } from './features/box-content/box-content-remove-press-hold/box-content-remove-press-hold.component';
import { AccountProcessingComponent } from './features/account-processing/account-processing.component';
import { CreateAccountProcessingComponent } from './features/account-processing/create-account-processing/create-account-processing.component';
import { UpdateAccountProcessingComponent } from './features/account-processing/update-account-processing/update-account-processing.component';
import { DeleteAccountProcessingComponent } from './features/account-processing/delete-account-processing/delete-account-processing.component';
import { CopyWorkflowAccountProcessingComponent } from './features/account-processing/copy-workflow-account-processing/copy-workflow-account-processing.component';
import { ActivityDescriptionComponent } from './features/activity-description/activity-description.component';
import { CreateActivityDescriptionComponent } from './features/activity-description/create-activity-description/create-activity-description.component';
import { UpdateActivityDescriptionComponent } from './features/activity-description/update-activity-description/update-activity-description.component';
import { BoxContentEventHistoryInquiryComponent } from './features/box-content/box-content-event-history-inquiry/box-content-event-history-inquiry.component';
import { BoxContentInquiryComponent } from './features/box-content/box-content-inquiry/box-content-inquiry.component';
import { BalanceErrorsComponent } from './features/balance-errors/balance-errors.component';
import { BalanceErrorLotStatusComponent } from './features/balance-errors/balance-error-lot-status/balance-error-lot-status.component';
import { BalanceErrorItemStatusComponent } from './features/balance-errors/balance-error-item-status/balance-error-item-status.component';
import { BalanceErrorStkActInquiryComponent } from './features/balance-errors/balance-error-stk-act-inquiry/balance-error-stk-act-inquiry.component';
import { BalanceErrorsOrdersComponent } from './features/balance-errors/balance-errors-orders/balance-errors-orders.component';
import { BalanceErrorsAdjustmentsComponent } from './features/balance-errors/balance-errors-adjustments/balance-errors-adjustments.component';
import { BalanceErrorsBalancesComponent } from './features/balance-errors/balance-errors-balances/balance-errors-balances.component';
import { BalanceErrorsItemLocationsComponent } from './features/balance-errors/balance-errors-item-locations/balance-errors-item-locations.component';
import { BalanceErrorsItemReceiversComponent } from './features/balance-errors/balance-errors-item-receivers/balance-errors-item-receivers.component';
import { InventoryBooksComponent } from './features/inventory-books/inventory-books.component';
import { InventoryBooksMissingCountComponent } from './features/inventory-books/inventory-books-missing-count/inventory-books-missing-count.component';
import { InventoryBooksWorkSheetComponent } from './features/inventory-books/inventory-books-work-sheet/inventory-books-work-sheet.component';
import { InventoryBookSynopsysComponent } from './features/inventory-books/inventory-book-synopsys/inventory-book-synopsys.component';
import { InventoryBookToCountComponent } from './features/inventory-books/inventory-book-to-count/inventory-book-to-count.component';
import { InventoryBookCountListingComponent } from './features/inventory-books/inventory-book-count-listing/inventory-book-count-listing.component';
import { InventoryBookDeleteComponent } from './features/inventory-books/inventory-book-delete/inventory-book-delete.component';
import { InventoryBookReservePostComponent } from './features/inventory-books/inventory-book-reserve-post/inventory-book-reserve-post.component';
import { InventoryMaintainBookComponent } from './features/inventory-books/inventory-maintain-book/inventory-maintain-book.component';
import { InventoryEnterCountComponent } from './features/inventory-books/inventory-enter-count/inventory-enter-count.component';
import { InventoryLpBookToCountComponent } from './features/inventory-books/inventory-lp-book-to-count/inventory-lp-book-to-count.component';
import { AddressTypesComponent } from './features/address-types/address-types.component';
import { AddressTypeChangeComponent } from './features/address-types/address-type-change/address-type-change.component';
import { AddressTypeDeleteComponent } from './features/address-types/address-type-delete/address-type-delete.component';
import { AddressTypeAddComponent } from './features/address-types/address-type-add/address-type-add.component';
import { ItemAdjustmentChangeDateComponent } from './features/item/item-adjustment-work-with/item-adjustment-change-date/item-adjustment-change-date.component';
import { ItemAdjustmentDisplayComponent } from './features/item/item-adjustment-work-with/item-adjustment-display/item-adjustment-display.component';
import { InventoryAdjustmentsComponent } from './features/item/inventory-adjustments/inventory-adjustments.component';
import { AddBalanceComponent } from './features/item/item-balances/add-balance/add-balance.component';
import { StgInquiryBalanceComponent } from './features/item/item-balances/stg-inquiry-balance/stg-inquiry-balance.component';
import { DeleteBalanceComponent } from './features/item/item-balances/delete-balance/delete-balance.component';
import { InquiryBalanceComponent } from './features/item/item-balances/inquiry-balance/inquiry-balance.component';
import { InventoryTagsComponent } from './features/item/inventory-tags/inventory-tags.component';
import { ChangeInventoryTagsComponent } from './features/item/inventory-tags/change-inventory-tags/change-inventory-tags.component';
import { ListInventoryTagsComponent } from './features/item/inventory-tags/list-inventory-tags/list-inventory-tags.component';
import { DiscrepanciesInventoryTagsComponent } from './features/item/inventory-tags/discrepancies-inventory-tags/discrepancies-inventory-tags.component';
import { TagsInventoryComponent } from './features/item/inventory-tags/tags-inventory/tags-inventory.component';
import { CountSummaryInventoryTagsComponent } from './features/item/inventory-tags/count-summary-inventory-tags/count-summary-inventory-tags.component';
import { ControlGroupsInventoryTagsComponent } from './features/item/inventory-tags/control-groups-inventory-tags/control-groups-inventory-tags.component';
import { BookDetailsComponent } from './features/item/inventory-tags/book-details/book-details.component';
import { ChangeCountComponent } from './features/item/inventory-tags/book-details/change-count/change-count.component';
import { OrderStagingComponent } from './features/order-staging/order-staging.component';
import { AssignLaneOrderStagingComponent } from './features/order-staging/assign-lane-order-staging/assign-lane-order-staging.component';
import { DeleteOrderStagingComponent } from './features/order-staging/delete-order-staging/delete-order-staging.component';
import { BatchDetailsOrderComponent } from './features/order-staging/batch-details-order/batch-details-order.component';
import { WorkWithDockSchedularComponent } from './features/order-staging/work-with-dock-schedular/work-with-dock-schedular.component';
import { DockScheduleAddComponent } from './features/order-staging/work-with-dock-schedular/dock-schedule-add/dock-schedule-add.component';
import { MoveHistoryComponent } from './features/order-staging/move-history/move-history.component';
import { WorkWithBatchDetailsComponent } from './features/order-staging/work-with-batch-details/work-with-batch-details.component';
import { BillSynopsisComponent } from './features/order-staging/work-with-batch-details/bill-synopsis/bill-synopsis.component';
import { ShipConfirmComponent } from './features/order-staging/work-with-batch-details/ship-confirm/ship-confirm.component';
import { EventHistoryComponent } from './features/order-staging/work-with-batch-details/event-history/event-history.component';
import { AppointmentEntryComponent } from './features/order-staging/work-with-batch-details/appointment-entry/appointment-entry.component';
import { UserEventComponent } from './features/order-staging/work-with-batch-details/user-event/user-event.component';
import { ShortShipComponent } from './features/order-staging/work-with-batch-details/short-ship/short-ship.component';
import { ControlFieldsComponent } from './features/order-staging/work-with-batch-details/control-fields/control-fields.component';
import { DockScheduleComponent } from './features/dock-schedule/dock-schedule.component';
import { DockScheduleCreateComponent } from './features/dock-schedule/dock-schedule-create/dock-schedule-create.component';
import { DockScheduleUpdateComponent } from './features/dock-schedule/dock-schedule-update/dock-schedule-update.component';
import { DockScheduleInfoComponent } from './features/dock-schedule/dock-schedule-info/dock-schedule-info.component';
import { DockScheduleMarkHotComponent } from './features/dock-schedule/dock-schedule-mark-hot/dock-schedule-mark-hot.component';
import { LtlBatchesComponent } from './features/ltl-batches/ltl-batches.component';
import { LtlBatchChangeComponent } from './features/ltl-batches/ltl-batch-change/ltl-batch-change.component';
import { LtlBatchDetailsComponent } from './features/ltl-batches/ltl-batch-details/ltl-batch-details.component';
import { WorkWithBookDetailsComponent } from './features/work-with-book-details/work-with-book-details.component';
import { WwBookDetailsAddComponent } from './features/work-with-book-details/ww-book-details-add/ww-book-details-add.component';
import { WwBookTagInquiryComponent } from './features/work-with-book-details/ww-book-tag-inquiry/ww-book-tag-inquiry.component';
import { WorkWithBoxesComponent } from './features/work-with-boxes/work-with-boxes.component';
import { WwBoxesChangeComponent } from './features/work-with-boxes/ww-boxes-change/ww-boxes-change.component';
import { WwBoxesDeleteComponent } from './features/work-with-boxes/ww-boxes-delete/ww-boxes-delete.component';
import { WwBoxesAddComponent } from './features/work-with-boxes/ww-boxes-add/ww-boxes-add.component';
import { WwBoxesDetailsComponent } from './features/work-with-boxes/ww-boxes-details/ww-boxes-details.component';
import { WwBoxesEventInquiryComponent } from './features/work-with-boxes/ww-boxes-event-inquiry/ww-boxes-event-inquiry.component';
import { WwBoxesInquiryComponent } from './features/work-with-boxes/ww-boxes-inquiry/ww-boxes-inquiry.component';
import { WwBoxesItemLabelsComponent } from './features/work-with-boxes/ww-boxes-item-labels/ww-boxes-item-labels.component';
import { WwBoxesBundleLabelsComponent } from './features/work-with-boxes/ww-boxes-bundle-labels/ww-boxes-bundle-labels.component';
import { WwBoxesBundleManifestComponent } from './features/work-with-boxes/ww-boxes-bundle-manifest/ww-boxes-bundle-manifest.component';
import { WwBoxesUpdateStsDeleteComponent } from './features/work-with-boxes/ww-boxes-update-sts-delete/ww-boxes-update-sts-delete.component';
import { WwBoxesUpdateStatusComponent } from './features/work-with-boxes/ww-boxes-update-status/ww-boxes-update-status.component';
import { WwBoxesChgCustomerComponent } from './features/work-with-boxes/ww-boxes-chg-customer/ww-boxes-chg-customer.component';
import { WwBoxesExportBoxComponent } from './features/work-with-boxes/ww-boxes-export-box/ww-boxes-export-box.component';
import { WwMasterSerialListComponent } from './features/ww-master-serial-list/ww-master-serial-list.component';
import { WwMasterSerialListAddComponent } from './features/ww-master-serial-list/ww-master-serial-list-add/ww-master-serial-list-add.component';
import { WwMasterSerialListChangeComponent } from './features/ww-master-serial-list/ww-master-serial-list-change/ww-master-serial-list-change.component';
import { WwHazardousChemicalsComponent } from './features/ww-hazardous-chemicals/ww-hazardous-chemicals.component';
import { HazardousChemicalsChangeComponent } from './features/ww-hazardous-chemicals/hazardous-chemicals-change/hazardous-chemicals-change.component';
import { HazardousChemicalsAddComponent } from './features/ww-hazardous-chemicals/hazardous-chemicals-add/hazardous-chemicals-add.component';
import { WwConsigneeItemsComponent } from './features/consignee/ww-consignee-items/ww-consignee-items.component';
import { ConsigneeItemsChangeComponent } from './features/consignee/ww-consignee-items/consignee-items-change/consignee-items-change.component';
import { ConsigneeItemsAddComponent } from './features/consignee/ww-consignee-items/consignee-items-add/consignee-items-add.component';
import { WwConsigneeChildItemsComponent } from './features/consignee/ww-consignee-child-items/ww-consignee-child-items.component';
import { ConsigneeChildItemsAddComponent } from './features/consignee/ww-consignee-child-items/consignee-child-items-add/consignee-child-items-add.component';
import { ConsigneeChildItemsChangeComponent } from './features/consignee/ww-consignee-child-items/consignee-child-items-change/consignee-child-items-change.component';
import { WwSystemCommentsComponent } from './features/ww-system-comments/ww-system-comments.component';
import { SystemCommentsChangeComponent } from './features/ww-system-comments/system-comments-change/system-comments-change.component';
import { SystemCommentsChangeSetComponent } from './features/ww-system-comments/system-comments-change-set/system-comments-change-set.component';
import { SystemCommentsDeleteComponent } from './features/ww-system-comments/system-comments-delete/system-comments-delete.component';
import { SystemCommentsAddComponent } from './features/ww-system-comments/system-comments-add/system-comments-add.component';
import { WwSerializedInventoriesComponent } from './features/ww-serialized-inventories/ww-serialized-inventories.component';
import { SerializedDetailsComponent } from './features/ww-serialized-inventories/serialized-details/serialized-details.component';
import { SerialWorksheetComponent } from './features/ww-serialized-inventories/serial-worksheet/serial-worksheet.component';
import { SerialCountComponent } from './features/ww-serialized-inventories/serial-count/serial-count.component';
import { SerializedAdjustmentLogComponent } from './features/ww-serialized-inventories/serialized-adjustment-log/serialized-adjustment-log.component';
import { SerializedBookSynopsysComponent } from './features/ww-serialized-inventories/serialized-book-synopsys/serialized-book-synopsys.component';
import { BookToCountComponent } from './features/ww-serialized-inventories/book-to-count/book-to-count.component';
import { WwCarrierEdiDocumentsComponent } from './features/ww-carrier-edi-documents/ww-carrier-edi-documents.component';
import { CarrierEdiDocumentsChangeComponent } from './features/ww-carrier-edi-documents/carrier-edi-documents-change/carrier-edi-documents-change.component';
import { CarrierEdiDocumentsAddComponent } from './features/ww-carrier-edi-documents/carrier-edi-documents-add/carrier-edi-documents-add.component';
import { CarrierEdiDocumentsDeleteComponent } from './features/ww-carrier-edi-documents/carrier-edi-documents-delete/carrier-edi-documents-delete.component';
import { WwContentsComponent } from './features/ww-contents/ww-contents.component';
import { ContentChangeComponent } from './features/ww-contents/content-change/content-change.component';
import { ContentAddComponent } from './features/ww-contents/content-add/content-add.component';
import { ContentItemLabelComponent } from './features/ww-contents/content-item-label/content-item-label.component';
import { ContentAddPressHoldComponent } from './features/ww-contents/content-add-press-hold/content-add-press-hold.component';
import { ContentRemovePressHoldComponent } from './features/ww-contents/content-remove-press-hold/content-remove-press-hold.component';
import { ContentInquiryComponent } from './features/ww-contents/content-inquiry/content-inquiry.component';

import { FilesComponent } from './features/files/files.component';
import { AddComponent } from './features/files/add/add.component';

import { AddCommodityPrinterComponent } from './features/commodity-printers/add-commodity-printer/add-commodity-printer.component';
import { CampusBuildingsComponent } from './features/campus-buildings/campus-buildings.component';
import { AddCampusBuildingComponent } from './features/campus-buildings/add-campus-building/add-campus-building.component';
import { CommodityPrintersComponent } from './features/commodity-printers/commodity-printers.component';
import { UpdateCommodityPrinterComponent } from './features/commodity-printers/update-commodity-printer/update-commodity-printer.component';
import { DeleteCommodityPrinterComponent } from './features/commodity-printers/delete-commodity-printer/delete-commodity-printer.component';
import { SynopsisComponent } from './features/ltl-batches/ltl-batch-details/synopsis/synopsis.component';
import { WwCommentSubcodesComponent } from './features/ww-comment-subcodes/ww-comment-subcodes.component';
import { CommentSubCodesAddComponent } from './features/ww-comment-subcodes/comment-sub-codes-add/comment-sub-codes-add.component';
import { WwCommentSubcodesChangeComponent } from './features/ww-comment-subcodes/ww-comment-subcodes-change/ww-comment-subcodes-change.component';
import { WwCustomerAddressesComponent } from './features/ww-customer-addresses/ww-customer-addresses.component';
import { CustomerAddressesAddComponent } from './features/ww-customer-addresses/customer-addresses-add/customer-addresses-add.component';
import { CustomerAddressesChangeComponent } from './features/ww-customer-addresses/customer-addresses-change/customer-addresses-change.component';
import { MapCrossReferenceComponent } from './features/map-location/map-cross-reference/map-cross-reference.component';
import { AccountSelectionComponent } from './features/account-selection/account-selection.component';
import { CustomersComponent } from './features/customers/customers.component';
import { CustomerCreateComponent } from './features/customers/customer-create/customer-create.component';
import { CustomerUpdateComponent } from './features/customers/customer-update/customer-update.component';
import { EmailExitPointsComponent } from './features/email-exit-points/email-exit-points.component';
import { EmailExitPointsCreateComponent } from './features/email-exit-points/email-exit-points-create/email-exit-points-create.component';
import { EmailExitPointsUpdateComponent } from './features/email-exit-points/email-exit-points-update/email-exit-points-update.component';
import { EmailExitPointsDeleteComponent } from './features/email-exit-points/email-exit-points-delete/email-exit-points-delete.component';
import { EmailExitPointsCopyComponent } from './features/email-exit-points/email-exit-points-copy/email-exit-points-copy.component';
import { ForecastComponent } from './features/forecast/forecast.component';
import { ForecastCreateComponent } from './features/forecast/forecast-create/forecast-create.component';
import { ForecastUpdateComponent } from './features/forecast/forecast-update/forecast-update.component';
import { ForecastDeleteComponent } from './features/forecast/forecast-delete/forecast-delete.component';
import { ForecastDetailsComponent } from './features/forecast/forecast-details/forecast-details.component';
import { CustomerConsolidateComponent } from './features/customers/customer-consolidate/customer-consolidate.component';
import { WwCarriersComponent } from './features/carriers/ww-carriers/ww-carriers.component';
import { CarriersEditComponent } from './features/carriers/ww-carriers/carriers-edit/carriers-edit.component';
import { CarriersDeleteComponent } from './features/carriers/ww-carriers/carriers-delete/carriers-delete.component';
import { CarriersAddComponent } from './features/carriers/ww-carriers/carriers-add/carriers-add.component';
import { CarriersFakTransComponent } from './features/carriers/ww-carriers/carriers-fak-trans/carriers-fak-trans.component';
import { WwCarEquipComponent } from './features/carriers/ww-carriers/ww-car-equip/ww-car-equip.component';
import { CarriersContactsComponent } from './features/carriers/ww-carriers/carriers-contacts/carriers-contacts.component';
import { ProductCodeDescriptionComponent } from './features/product-code-description/product-code-description.component';
import { ProdCodeDescChangeComponent } from './features/product-code-description/prod-code-desc-change/prod-code-desc-change.component';
import { ProdCodeDescAddComponent } from './features/product-code-description/prod-code-desc-add/prod-code-desc-add.component';
import { AccountSelectionUpdateComponent } from './features/account-selection/account-selection-update/account-selection-update.component';
import { WwConsigneeBasedTriggersComponent } from './features/ww-consignee-based-triggers/ww-consignee-based-triggers.component';
import { ConsigneeBasedTriggerChangeComponent } from './features/ww-consignee-based-triggers/consignee-based-trigger-change/consignee-based-trigger-change.component';
import { ConsigneeBasedTriggerAddComponent } from './features/ww-consignee-based-triggers/consignee-based-trigger-add/consignee-based-trigger-add.component';
import { WwMetricConversionsComponent } from './features/ww-metric-conversions/ww-metric-conversions.component';
import { MetricConversionChangeComponent } from './features/ww-metric-conversions/metric-conversion-change/metric-conversion-change.component';
import { WwExpectedDurationsComponent } from './features/ww-expected-durations/ww-expected-durations.component';
import { ExpectedDurationsAddComponent } from './features/ww-expected-durations/expected-durations-add/expected-durations-add.component';
import { ExpectedDurationsChangeComponent } from './features/ww-expected-durations/expected-durations-change/expected-durations-change.component';
import { ExpectedDurationsDeleteComponent } from './features/ww-expected-durations/expected-durations-delete/expected-durations-delete.component';
import { WwContactTypesComponent } from './features/ww-contact-types/ww-contact-types/ww-contact-types.component';
import { ContactTypesAddComponent } from './features/ww-contact-types/ww-contact-types/contact-types-add/contact-types-add.component';
import { ContactTypesChangeComponent } from './features/ww-contact-types/ww-contact-types/contact-types-change/contact-types-change.component';
import { WwCustomerRatingPreferenceComponent } from './features/ww-customer-rating-preference/ww-customer-rating-preference.component';
import { CustomerRatingPrefChangeComponent } from './features/ww-customer-rating-preference/customer-rating-pref-change/customer-rating-pref-change.component';
import { CustomerRatingPrefAddComponent } from './features/ww-customer-rating-preference/customer-rating-pref-add/customer-rating-pref-add.component';

import { DataElementsComponent } from './features/data-elements/data-elements.component';
import { AddDataElementComponent } from './features/data-elements/add-data-element/add-data-element.component';
import { UpdateDataElementComponent } from './features/data-elements/update-data-element/update-data-element.component';
import { DeleteDataElementComponent } from './features/data-elements/delete-data-element/delete-data-element.component';
import { ReasonCodesElementComponent } from './features/data-elements/reason-codes-element/reason-codes-element.component';
import { UccLabelCtrlFileComponent } from './features/consignee/ucc-label-ctrl-file/ucc-label-ctrl-file.component';
import { AttributeConsigneeComponent } from './features/consignee/attribute-consignee/attribute-consignee.component';

import { GeneralInvoicesComponent } from './features/general-invoices/general-invoices.component';
import { AddGeneralInvoiceComponent } from './features/general-invoices/add-general-invoice/add-general-invoice.component';
import { EditGeneralInvoiceComponent } from './features/general-invoices/edit-general-invoice/edit-general-invoice.component';
import { DeleteGeneralInvoiceComponent } from './features/general-invoices/delete-general-invoice/delete-general-invoice.component';
import { SrchAttrInquiryComponent } from './features/consignee/srch-attr-inquiry/srch-attr-inquiry.component';
import { ContactsConsigneeComponent } from './features/consignee/contacts-consignee/contacts-consignee.component';
import { DocumentsConsigneeComponent } from './features/consignee/documents-consignee/documents-consignee.component';
import { WwDockSchedulerControlsComponent } from './features/ww-dock-scheduler-controls/ww-dock-scheduler-controls.component';
import { DockSchedulerControllAddComponent } from './features/ww-dock-scheduler-controls/dock-scheduler-controll-add/dock-scheduler-controll-add.component';
import { WwTripExpensesComponent } from './features/ww-trip-expenses/ww-trip-expenses.component';
import { TripExpensesAddComponent } from './features/ww-trip-expenses/trip-expenses-add/trip-expenses-add.component';
import { TripExpensesChangeComponent } from './features/ww-trip-expenses/trip-expenses-change/trip-expenses-change.component';
// import { CustomerContactsComponent } from './features/customer-contacts/customer-contacts.component';
// import { AddCustomerContactComponent } from './features/customer-contacts/add-customer-contact/add-customer-contact.component';
// import { UpdateCustomerContactComponent } from './features/customer-contacts/update-customer-contact/update-customer-contact.component';
// import { DeleteCustomerContactComponent } from './features/customer-contacts/delete-customer-contact/delete-customer-contact.component';
import { CreateBuildingComponent } from './features/buildings/create-building/create-building.component';
import { UpdateBuildingComponent } from './features/buildings/update-building/update-building.component';
import { DeleteBuildingComponent } from './features/buildings/delete-building/delete-building.component';
import { BuildingContactComponent } from './features/buildings/building-contact/building-contact.component';
import { BuildingsWorkWithComponent } from './features/buildings/buildings.component';
import { DoorUsageComponent } from './features/buildings/doors/door-usage/door-usage.component';
import { AddDoorUsageComponent } from './features/buildings/doors/door-usage/add-door-usage/add-door-usage.component';
import { UpdateDoorComponent } from './features/buildings/doors/update-door/update-door.component';
import { DoorsComponent } from './features/buildings/doors/doors.component';
import { EmailAddressComponent } from './features/email-address/email-address.component';
import { AddEmailAddressComponent } from './features/email-address/add-email-address/add-email-address.component';
import { EditEmailAddressComponent } from './features/email-address/edit-email-address/edit-email-address.component';
import { ExractReportsBaseFieldsComponent } from './features/exract-reports-base-fields/exract-reports-base-fields.component';
import { ExtractReportAddComponent } from './features/exract-reports-base-fields/extract-report-add/extract-report-add.component';
import { ExtractReportChangeComponent } from './features/exract-reports-base-fields/extract-report-change/extract-report-change.component';

import { WwEquipmentOperatorsComponent } from './features/ww-equipment-operators/ww-equipment-operators/ww-equipment-operators.component';
import { AddEquipmentOperatorsComponent } from './features/ww-equipment-operators/ww-equipment-operators/add-equipment-operators/add-equipment-operators.component';
import { ChangeEquipmentOperatorsComponent } from './features/ww-equipment-operators/ww-equipment-operators/change-equipment-operators/change-equipment-operators.component';
import { DeleteEquipmentOperatorsComponent } from './features/ww-equipment-operators/ww-equipment-operators/delete-equipment-operators/delete-equipment-operators.component';
import { WwWorkAssignmentsComponent } from './features/ww-equipment-operators/ww-equipment-operators/ww-work-assignments/ww-work-assignments.component';
import { WwEntityAttributesComponent } from './features/ww-equipment-operators/ww-equipment-operators/ww-entity-attributes/ww-entity-attributes.component';
import { WwOperatorEquipmentComponent } from './features/ww-operator-equipment/ww-operator-equipment.component';
import { OperatorEquipmentCreateComponent } from './features/ww-operator-equipment/operator-equipment-create/operator-equipment-create.component';
import { OperatorEquipmentChangeComponent } from './features/ww-operator-equipment/operator-equipment-change/operator-equipment-change.component';
import { OperatorEquipmentDeleteComponent } from './features/ww-operator-equipment/operator-equipment-delete/operator-equipment-delete.component';

import { ExtractReportFiltersComponent } from './features/extract-report-filters/extract-report-filters.component';
import { ExtractReportFiltersAddComponent } from './features/extract-report-filters/extract-report-filters-add/extract-report-filters-add.component';
import { ExtractReportFiltersUpdateComponent } from './features/extract-report-filters/extract-report-filters-update/extract-report-filters-update.component';
import { ExtractReportFiltersCopyComponent } from './features/extract-report-filters/extract-report-filters-copy/extract-report-filters-copy.component';
import { WwWorkScheduleComponent } from './features/ww-work-schedule/ww-work-schedule.component';
import { WorkScheduleCreateComponent } from './features/ww-work-schedule/work-schedule-create/work-schedule-create.component';
import { WorkScheduleUpdateComponent } from './features/ww-work-schedule/work-schedule-update/work-schedule-update.component';
import { WorkScheduleDeleteComponent } from './features/ww-work-schedule/work-schedule-delete/work-schedule-delete.component';
import { OperatorEquipmentCopyComponent } from './features/ww-operator-equipment/operator-equipment-copy/operator-equipment-copy.component';

import { ValidityCharacterRemovalAddComponent } from './features/ww-validity-character-removal/ww-validity-character-removal/validity-character-removal-add/validity-character-removal-add.component';
import { ValidityCharacterRemovalChangeComponent } from './features/ww-validity-character-removal/ww-validity-character-removal/validity-character-removal-change/validity-character-removal-change.component';
import { ValidityCharacterRemovalDeleteComponent } from './features/ww-validity-character-removal/ww-validity-character-removal/validity-character-removal-delete/validity-character-removal-delete.component';
import { WwValidityCharacterRemovalComponent } from './features/ww-validity-character-removal/ww-validity-character-removal/ww-validity-character-removal.component';
import { OrderEventEntryComponent } from './features/orders/order-event-entry/order-event-entry.component';
import { FreightCodesComponent } from './features/freight-codes/freight-codes.component';
import { AddFreightCodeComponent } from './features/freight-codes/add-freight-code/add-freight-code.component';
import { EditFreightCodeComponent } from './features/freight-codes/edit-freight-code/edit-freight-code.component';
import { HazmatClassComponent } from './features/hazmat-class/hazmat-class.component';
import { AddHazmatClassComponent } from './features/hazmat-class/add-hazmat-class/add-hazmat-class.component';
import { EditHazmatClassComponent } from './features/hazmat-class/edit-hazmat-class/edit-hazmat-class.component';
import { BillTextFreightCodeComponent } from './features/freight-codes/bill-text-freight-code/bill-text-freight-code.component';
import { CopyEnvironmentComponent } from './features/environment copy/copy-environment/copy-environment.component';
import { WwProductCodesComponent } from './features/ww-product-codes/ww-product-codes.component';
import { WwItemCountSchedulesComponent } from './features/ww-item-count-schedules/ww-item-count-schedules.component';
import { ItemCountScheduleCreateComponent } from './features/ww-item-count-schedules/item-count-schedule-create/item-count-schedule-create.component';
import { ItemCountScheduleChangeComponent } from './features/ww-item-count-schedules/item-count-schedule-change/item-count-schedule-change.component';
import { ProductCodeCreateComponent } from './features/ww-product-codes/product-code-create/product-code-create.component';
import { ProductCodeChangeComponent } from './features/ww-product-codes/product-code-change/product-code-change.component';
import { WwKanbanComponent } from './features/ww-kanban/ww-kanban.component';
import { KanbanAddComponent } from './features/ww-kanban/kanban-add/kanban-add.component';
import { KanbanChangeComponent } from './features/ww-kanban/kanban-change/kanban-change.component';
import { KanbanDeleteComponent } from './features/ww-kanban/kanban-delete/kanban-delete.component';
import { WwScheduleExceptionsComponent } from './features/ww-schedule-exceptions/ww-schedule-exceptions.component';
import { ScheduleExceptionAddComponent } from './features/ww-schedule-exceptions/schedule-exception-add/schedule-exception-add.component';
import { ScheduleExceptionChangeComponent } from './features/ww-schedule-exceptions/schedule-exception-change/schedule-exception-change.component';
import { ScheduleExceptionDeleteComponent } from './features/ww-schedule-exceptions/schedule-exception-delete/schedule-exception-delete.component';
import { WwGeneralInvoiceDetailsComponent } from './features/ww-general-invoice-details/ww-general-invoice-details.component';
import { GeneralInvoiceDetailsAddComponent } from './features/ww-general-invoice-details/general-invoice-details-add/general-invoice-details-add.component';
import { GeneralInvoiceDetailsChangeComponent } from './features/ww-general-invoice-details/general-invoice-details-change/general-invoice-details-change.component';
import { GeneralInvoiceDetailsDeleteComponent } from './features/ww-general-invoice-details/general-invoice-details-delete/general-invoice-details-delete.component';
import { GeneralInvoiceDetailsCommentsComponent } from './features/ww-general-invoice-details/general-invoice-details-comments/general-invoice-details-comments.component';
import { WorkWithExtractReportsComponent } from './features/work-with-extract-reports/work-with-extract-reports.component';
import { ExtractReportsAddComponent } from './features/work-with-extract-reports/extract-reports-add/extract-reports-add.component';
import { ExtractReportsEditComponent } from './features/work-with-extract-reports/extract-reports-edit/extract-reports-edit.component';
import { WwPeriodsComponent } from './features/ww-periods/ww-periods/ww-periods.component';
import { PeriodsAddComponent } from './features/ww-periods/ww-periods/periods-add/periods-add.component';
import { PeriodChangeComponent } from './features/ww-periods/ww-periods/period-change/period-change.component';
import { PeriodDeleteComponent } from './features/ww-periods/ww-periods/period-delete/period-delete.component';
import { WwLaborPlansComponent } from './features/ww-labor-plans/ww-labor-plans/ww-labor-plans.component';
import { LaborPlanCreateComponent } from './features/ww-labor-plans/ww-labor-plans/labor-plan-create/labor-plan-create.component';
import { LaborPlanChangeComponent } from './features/ww-labor-plans/ww-labor-plans/labor-plan-change/labor-plan-change.component';
import { SerialNumbersComponent } from './features/serial-numbers/serial-numbers.component';
import { EditSerialNumberComponent } from './features/serial-numbers/edit-serial-number/edit-serial-number.component';
import { DeleteSerialNumberComponent } from './features/serial-numbers/delete-serial-number/delete-serial-number.component';
import { WwSerialNumberComponent } from './features/serial-numbers/ww-serial-number/ww-serial-number.component';
import { AddSerialNumberComponent } from './features/serial-numbers/add-serial-number/add-serial-number.component';
import { LedgerCodesComponent } from './features/ledger-codes/ledger-codes.component';
import { AddLedgerCodeComponent } from './features/ledger-codes/add-ledger-code/add-ledger-code.component';
import { EditLedgerCodeComponent } from './features/ledger-codes/edit-ledger-code/edit-ledger-code.component';
import { DeleteLedgerCodeComponent } from './features/ledger-codes/delete-ledger-code/delete-ledger-code.component';
import { ConsolidateLedgerCodeComponent } from './features/ledger-codes/consolidate-ledger-code/consolidate-ledger-code.component';
import { AddGroupComponent } from './features/labor-plan-group/add-group/add-group.component';
import { EditGroupComponent } from './features/labor-plan-group/edit-group/edit-group.component';
import { DetailsGroupComponent } from './features/labor-plan-group/details-group/details-group.component';
import { AddDetailsGroupComponent } from './features/labor-plan-group/details-group/add-details-group/add-details-group.component';
import { EditDetailsGroupComponent } from './features/labor-plan-group/details-group/edit-details-group/edit-details-group.component';
import { DeleteGroupComponent } from './features/labor-plan-group/delete-group/delete-group.component';
import { LaborPlanGroupComponent } from './features/labor-plan-group/labor-plan-group.component';
import { OperatorGroupsComponent } from './features/operator-groups/operator-groups.component';
import { AddOperatorGroupComponent } from './features/operator-groups/add-operator-group/add-operator-group.component';
import { EditOperatorGroupComponent } from './features/operator-groups/edit-operator-group/edit-operator-group.component';
import { LoadOutComponent } from './features/load-out/load-out.component';
import { UnloadPalletComponent } from './features/load-out/unload-pallet/unload-pallet.component';
import { DeleteLoadOutComponent } from './features/load-out/delete-load-out/delete-load-out.component';
import { WwItemAttributesComponent } from './features/ww-item-attributes/ww-item-attributes.component';
import { WwItemAttributesCreateComponent } from './features/ww-item-attributes/ww-item-attributes-create/ww-item-attributes-create.component';
import { WwItemAttributesUpdateComponent } from './features/ww-item-attributes/ww-item-attributes-update/ww-item-attributes-update.component';
import { WwItemAttributesDeleteComponent } from './features/ww-item-attributes/ww-item-attributes-delete/ww-item-attributes-delete.component';
import { WwItemForecastDetailsComponent } from './features/ww-item-forecast-details/ww-item-forecast-details.component';
import { WwItemForecastDetailsCreateComponent } from './features/ww-item-forecast-details/ww-item-forecast-details-create/ww-item-forecast-details-create.component';
import { WwItemForecastDetailsUpdateComponent } from './features/ww-item-forecast-details/ww-item-forecast-details-update/ww-item-forecast-details-update.component';
import { WwItemForecastDetailsDeleteComponent } from './features/ww-item-forecast-details/ww-item-forecast-details-delete/ww-item-forecast-details-delete.component';
import { WwStorerAttributesComponent } from './features/ww-storer-attributes/ww-storer-attributes.component';
import { WwStorerAttributesCreateComponent } from './features/ww-storer-attributes/ww-storer-attributes-create/ww-storer-attributes-create.component';
import { WwStorerAttributesDeleteComponent } from './features/ww-storer-attributes/ww-storer-attributes-delete/ww-storer-attributes-delete.component';
import { WwStorerAttributesUpdateComponent } from './features/ww-storer-attributes/ww-storer-attributes-update/ww-storer-attributes-update.component';
import { WwLocaterComponent } from './features/ww-locater/ww-locater.component';
import { WwLocaterDeleteComponent } from './features/ww-locater/ww-locater-delete/ww-locater-delete.component';
import { WwLocaterInquiryComponent } from './features/ww-locater/ww-locater-inquiry/ww-locater-inquiry.component';
import { WwLocaterResequenceComponent } from './features/ww-locater/ww-locater-resequence/ww-locater-resequence.component';
import { WwLocater1sAdjustComponent } from './features/ww-locater/ww-locater1s-adjust/ww-locater1s-adjust.component';
import { MaintQuestionsComponent } from './features/maint-questions/maint-questions.component';
import { MaintquestionsAddComponent } from './features/maint-questions/maintquestions-add/maintquestions-add.component';
import { MaintquestionsChangeComponent } from './features/maint-questions/maintquestions-change/maintquestions-change.component';
import { MaintquestionsDeleteComponent } from './features/maint-questions/maintquestions-delete/maintquestions-delete.component';
import { MaintquestionsCopyComponent } from './features/maint-questions/maintquestions-copy/maintquestions-copy.component';
import { WorkWithLotStatusComponent } from './features/work-with-lot-status/work-with-lot-status.component';
import { WwLotStatusAddComponent } from './features/work-with-lot-status/ww-lot-status-add/ww-lot-status-add.component';
import { WwLotStatusChangeComponent } from './features/work-with-lot-status/ww-lot-status-change/ww-lot-status-change.component';
import { WwLotStatusInquiryComponent } from './features/work-with-lot-status/ww-lot-status-inquiry/ww-lot-status-inquiry.component';
import { WwLaborProcessingComponent } from './features/ww-labor-processing/ww-labor-processing.component';
import { LaborProcessingAddComponent } from './features/ww-labor-processing/labor-processing-add/labor-processing-add.component';
import { LaborProcessingChangeComponent } from './features/ww-labor-processing/labor-processing-change/labor-processing-change.component';
import { LaborProcessingCopyComponent } from './features/ww-labor-processing/labor-processing-copy/labor-processing-copy.component';
import { LaborProcessingDeleteComponent } from './features/ww-labor-processing/labor-processing-delete/labor-processing-delete.component';
import { MetricsConstraintsComponent } from './features/metrics-constraints/metrics-constraints.component';
import { MetricsconstraintsAddComponent } from './features/metrics-constraints/metricsconstraints-add/metricsconstraints-add.component';
import { MetricsconstraintsUpdateComponent } from './features/metrics-constraints/metricsconstraints-update/metricsconstraints-update.component';
import { MetricsconstraintsDeleteComponent } from './features/metrics-constraints/metricsconstraints-delete/metricsconstraints-delete.component';
import { MessageClassShredComponent } from './features/message-class-shred/message-class-shred.component';
import { MessageclassshredAddComponent } from './features/message-class-shred/messageclassshred-add/messageclassshred-add.component';
import { MessageclassshredChangeComponent } from './features/message-class-shred/messageclassshred-change/messageclassshred-change.component';
import { MessageclassshredCopyComponent } from './features/message-class-shred/messageclassshred-copy/messageclassshred-copy.component';
import { MessageclassshredDeleteComponent } from './features/message-class-shred/messageclassshred-delete/messageclassshred-delete.component';
import { WwLocater2sAdjustBBComponent } from './features/ww-locater/ww-locater2s-adjust-bb/ww-locater2s-adjust-bb.component';
import { WwLocater2sAdjustNewItmComponent } from './features/ww-locater/ww-locater2s-adjust-new-itm/ww-locater2s-adjust-new-itm.component';
import { WwLocaterLoadSheduledFloorMoveComponent } from './features/ww-locater/ww-locater-load-sheduled-floor-move/ww-locater-load-sheduled-floor-move.component';
import { WwInboundDetailsComponent } from './features/ww-inbound-details/ww-inbound-details.component';
import { WwInboundDetailsAddComponent } from './features/ww-inbound-details/ww-inbound-details-add/ww-inbound-details-add.component';
import { WwInboundDetailsChangeComponent } from './features/ww-inbound-details/ww-inbound-details-change/ww-inbound-details-change.component';
import { WwInboundDetailsCommentsComponent } from './features/ww-inbound-details/ww-inbound-details-comments/ww-inbound-details-comments.component';
import { WwInboundDetailsDeleteComponent } from './features/ww-inbound-details/ww-inbound-details-delete/ww-inbound-details-delete.component';
import { MessageclassshredDetailsComponent } from './features/message-class-shred/messageclassshred-details/messageclassshred-details.component';
import { MessageclassshredDetailsChangeComponent } from './features/message-class-shred/messageclassshred-details/messageclassshred-details-change/messageclassshred-details-change.component';
import { MessageclassshredDetailsDeleteComponent } from './features/message-class-shred/messageclassshred-details/messageclassshred-details-delete/messageclassshred-details-delete.component';
import { MessageclassshredDetailsDetailsComponent } from './features/message-class-shred/messageclassshred-details/messageclassshred-details-details/messageclassshred-details-details.component';
import { MessageclassshredDetailsDetailsChangeComponent } from './features/message-class-shred/messageclassshred-details/messageclassshred-details-details/messageclassshred-details-details-change/messageclassshred-details-details-change.component';
import { MessageclassshredDetailsDetailsDeleteComponent } from './features/message-class-shred/messageclassshred-details/messageclassshred-details-details/messageclassshred-details-details-delete/messageclassshred-details-details-delete.component';
import { ExtractReportWhereclausesComponent } from './features/extract-report-whereclauses/extract-report-whereclauses.component';
import { ExtractReportWhereclausesAddComponent } from './features/extract-report-whereclauses/extract-report-whereclauses-add/extract-report-whereclauses-add.component';
import { ExtractReportWhereclausesDeleteComponent } from './features/extract-report-whereclauses/extract-report-whereclauses-delete/extract-report-whereclauses-delete.component';
import { ExtractReportWhereclausesUpdateComponent } from './features/extract-report-whereclauses/extract-report-whereclauses-update/extract-report-whereclauses-update.component';
import { ExtractReportSortClausesComponent } from './features/extract-report-sort-clauses/extract-report-sort-clauses.component';
import { ExtractReportSortClausesAddComponent } from './features/extract-report-sort-clauses/extract-report-sort-clauses-add/extract-report-sort-clauses-add.component';
import { ExtractReportSortClausesDeleteComponent } from './features/extract-report-sort-clauses/extract-report-sort-clauses-delete/extract-report-sort-clauses-delete.component';
import { ExtractReportSortClausesUpdateComponent } from './features/extract-report-sort-clauses/extract-report-sort-clauses-update/extract-report-sort-clauses-update.component';
import { WwHazardMaterialsGroupComponent } from './features/ww-hazard-materials-group/ww-hazard-materials-group.component';
import { WwHazardMaterialsGroupAddComponent } from './features/ww-hazard-materials-group/ww-hazard-materials-group-add/ww-hazard-materials-group-add.component';
import { WwHazardMaterialsGroupChangeComponent } from './features/ww-hazard-materials-group/ww-hazard-materials-group-change/ww-hazard-materials-group-change.component';
import { WwHazardMaterialsGroupDeleteComponent } from './features/ww-hazard-materials-group/ww-hazard-materials-group-delete/ww-hazard-materials-group-delete.component';
import { WwLaborPlanComponent } from './features/ww-labor-plan/ww-labor-plan.component';
import { WwLaborPlanAddComponent } from './features/ww-labor-plan/ww-labor-plan-add/ww-labor-plan-add.component';
import { WwLaborPlanChangeComponent } from './features/ww-labor-plan/ww-labor-plan-change/ww-labor-plan-change.component';
import { WwLaborPlanDeleteComponent } from './features/ww-labor-plan/ww-labor-plan-delete/ww-labor-plan-delete.component';
import { WwLaborPlanDetailsComponent } from './features/ww-labor-plan/ww-labor-plan-details/ww-labor-plan-details.component';
import { WwLaborPlanEventInquiryComponent } from './features/ww-labor-plan/ww-labor-plan-event-inquiry/ww-labor-plan-event-inquiry.component';
import { WorkWithEmailFormatComponent } from './features/work-with-email-format/work-with-email-format.component';
import { WwEmailFormatChangeComponent } from './features/work-with-email-format/ww-email-format-change/ww-email-format-change.component';
import { WwEmailFormatInsertAfterComponent } from './features/work-with-email-format/ww-email-format-insert-after/ww-email-format-insert-after.component';
import { WwEmailFormatInsertBeforeComponent } from './features/work-with-email-format/ww-email-format-insert-before/ww-email-format-insert-before.component';
import { WwEmailFormatDeleteComponent } from './features/work-with-email-format/ww-email-format-delete/ww-email-format-delete.component';
import { WwInboundDetailsStockStatusComponent } from './features/ww-inbound-details/ww-inbound-details-stock-status/ww-inbound-details-stock-status.component';
import { WwInboundDetailsActivityInquiryComponent } from './features/ww-inbound-details/ww-inbound-details-activity-inquiry/ww-inbound-details-activity-inquiry.component';
import { WwInboundDetailsExtraDataComponent } from './features/ww-inbound-details/ww-inbound-details-extra-data/ww-inbound-details-extra-data.component';
import { WwOrderEventsComponent } from './features/ww-order-events/ww-order-events.component';
import { WwOrderEventsChangestatusComponent } from './features/ww-order-events/ww-order-events-changestatus/ww-order-events-changestatus.component';
import { WwIncomingEventsComponent } from './features/ww-incoming-events/ww-incoming-events.component';
import { WwIncomingEventsErrordetailsComponent } from './features/ww-incoming-events/ww-incoming-events-errordetails/ww-incoming-events-errordetails.component';
import { WwIncomingEventsDeleteComponent } from './features/ww-incoming-events/ww-incoming-events-delete/ww-incoming-events-delete.component';
import { MetricsDefinationsComponent } from './features/metrics-definations/metrics-definations.component';
import { MetricsDefinationsAddComponent } from './features/metrics-definations/metrics-definations-add/metrics-definations-add.component';
import { MetricsDefinationsUpdateComponent } from './features/metrics-definations/metrics-definations-update/metrics-definations-update.component';
import { MetricsDefinationsDeleteComponent } from './features/metrics-definations/metrics-definations-delete/metrics-definations-delete.component';
import { WwOrderEventsDeleteComponent } from './features/ww-order-events/ww-order-events-delete/ww-order-events-delete.component';
import { WwValidationEventsComponent } from './features/ww-validation-events/ww-validation-events.component';

import { WwValidationEventsDeleteComponent } from './features/ww-validation-events/ww-validation-events-delete/ww-validation-events-delete.component';
import { WwValidationEventsErrordetailsComponent } from './features/ww-validation-events/ww-validation-events-errordetails/ww-validation-events-errordetails.component';



import { WwInboundDetailsPurchaseOrdsComponent } from './features/ww-inbound-details/ww-inbound-details-purchase-ords/ww-inbound-details-purchase-ords.component';

import { WwMesssageClassTablesComponent } from './features/ww-messsage-class-tables/ww-messsage-class-tables.component';
import { AddMessageClassTablesComponent } from './features/ww-messsage-class-tables/add-message-class-tables/add-message-class-tables.component';
import { ChangeMessageClassTablesComponent } from './features/ww-messsage-class-tables/change-message-class-tables/change-message-class-tables.component';
import { WwMessageClassColumnsComponent } from './features/ww-messsage-class-tables/ww-message-class-columns/ww-message-class-columns.component';
import { AddMessageClassColumnsComponent } from './features/ww-messsage-class-tables/ww-message-class-columns/add-message-class-columns/add-message-class-columns.component';
import { ChangeMessageClassColumnsComponent } from './features/ww-messsage-class-tables/ww-message-class-columns/change-message-class-columns/change-message-class-columns.component';

import { StorerCreate2Component } from './features/storer/storer-create2/storer-create2.component';
import { StorerCreate3Component } from './features/storer/storer-create3/storer-create3.component';
import { StorerCreate4Component } from './features/storer/storer-create4/storer-create4.component';
import { StorerCreate5Component } from './features/storer/storer-create5/storer-create5.component';
import { StorerCreate6Component } from './features/storer/storer-create6/storer-create6.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';

import { MatTooltipModule } from '@angular/material/tooltip';

import { StorerUpdate2Component } from './features/storer/storer-update2/storer-update2.component';
import { StorerUpdate3Component } from './features/storer/storer-update3/storer-update3.component';
import { StorerUpdate4Component } from './features/storer/storer-update4/storer-update4.component';
import { StorerUpdate5Component } from './features/storer/storer-update5/storer-update5.component';
import { StorerUpdate6Component } from './features/storer/storer-update6/storer-update6.component';

import { ItemCreate2Component } from './features/item/item-create2/item-create2.component';
import { ItemCreate3Component } from './features/item/item-create3/item-create3.component';
import { ItemCreate4Component } from './features/item/item-create4/item-create4.component';
import { ItemUpdate1Component } from './features/item/item-update1/item-update1.component';
import { ItemUpdate2Component } from './features/item/item-update2/item-update2.component';
import { ItemUpdate3Component } from './features/item/item-update3/item-update3.component';
import { ItemUpdate4Component } from './features/item/item-update4/item-update4.component';
import { OrderCreate2Component } from './features/orders/order-create2/order-create2.component';
import { OrderUpdate2Component } from './features/orders/order-update2/order-update2.component';
import { OrdersCreateFilled1Component } from './features/orders/orders-create-filled1/orders-create-filled1.component';
import { ItemCrossRefComponent } from './features/item/item-cross-ref/item-cross-ref.component';
import { ItemLotsUpdateComponent } from './features/item/item-lots/item-lots-update/item-lots-update.component';
import { MoveQueueResolveComponent } from './features/move-queue/move-queue-resolve/move-queue-resolve.component';
import { StockEnquiryComponent } from './features/item/stock-enquiry/stock-enquiry.component';
import { ApplicationPipesModule } from './Pipes/pipe.module';
import { ItemStatusInquiryComponent } from './features/item/item-status-inquiry/item-status-inquiry.component';
import { TwosidedAdjBucketToBucketComponent } from './features/item/item-locations/twosided-adj-bucket-to-bucket/twosided-adj-bucket-to-bucket.component';
import { RedirectForm1Component } from './features/move-queue/redirect-form1/redirect-form1.component';
import { RedirectForm2Component } from './features/move-queue/redirect-form2/redirect-form2.component';
import { ChangeEventCommentsComponent } from './features/orders/order-details/change-event-comments/change-event-comments.component';
import { CustomAlertComponent } from './features/custom-alert/custom-alert.component';
import { StorerCommentCreateComponent } from './features/storer/storer-comments/storer-comment-create/storer-comment-create.component';
import { StorerCommentUpdateComponent } from './features/storer/storer-comments/storer-comment-update/storer-comment-update.component';
import { StorerCommentDeleteComponent } from './features/storer/storer-comments/storer-comment-delete/storer-comment-delete.component';

import { ItemCommentCreateComponent } from './features/item/item-comments/item-comment-create/item-comment-create.component';
import { ItemCommentUpdateComponent } from './features/item/item-comments/item-comment-update/item-comment-update.component';
import { ItemCommentDeleteComponent } from './features/item/item-comments/item-comment-delete/item-comment-delete.component';

import { ConsigneeCommentsUpdateComponent } from './features/consignee/consinee-comments/consignee-comments-update/consignee-comments-update.component';
import { ConsigneeCommentsCreateComponent } from './features/consignee/consinee-comments/consignee-comments-create/consignee-comments-create.component';
import { ConsigneeCommentsDeleteComponent } from './features/consignee/consinee-comments/consignee-comments-delete/consignee-comments-delete.component';



@NgModule({
  declarations: [
    CustomAlertComponent,
    OrderEventEntryComponent,
    FreightCodesComponent,
    BillTextFreightCodeComponent,
    AddFreightCodeComponent,
    EnvironmentComponent,
    CopyEnvironmentComponent,
    EditFreightCodeComponent,
    HazmatClassComponent,
    AddHazmatClassComponent,
    EditHazmatClassComponent,
    AppComponent,
    MainNavComponent,
    StorerComponent,
    StorerCreateComponent,
    StorerUpdateComponent,
    StorerDeactiveComponent,
    StorerContactsComponent,
    StorerContactCreateComponent,
    StorerContactUpdateComponent,
    StorerContactDeleteComponent,
    SroterPeriodRecordsComponent,
    SroterWriteBalanceRecordComponent,
    MapZonesComponent,
    CreateMapzoneComponent,
    UpdateMapzoneComponent,
    DeleteMapzoneComponent,
    MapLocationComponent,
    MapLocationCreateComponent,
    MapLocationUpdateComponent,
    MapLocationDeleteComponent,
    LocationStatusInquiryComponent,
    ConsigneeComponent,
    ConsigneeCreateComponent,
    ConsigneeUpdateComponent,
    ConsigneeDeleteComponent,
    ConsigneeXrefComponent,
    ExitPointsComponent,
    ExitPointsCreateComponent,
    ExitPointsUpdateComponent,
    ExitPointsDeleteComponent,
    MapLocationCreateComponent,
    MapLocationUpdateComponent,
    MapLocationDeleteComponent,
    LocationStatusInquiryComponent,
    CarriersComponent,
    CreateCarriersComponent,
    UpdateCarriersComponent,
    DeleteCarriersComponent,
    ConsineeCommentsComponent,
    OrdersComponent,
    OrderCreateComponent,
    OrderUpdateComponent,
    OrderDeleteComponent,
    OrderEventHistoryComponent,
    OrderDshipComponent,
    OrderSendtoMoveQueueComponent,
    OrderMoveHistoryInquiryComponent,
    MoveQueueComponent,
    OrderCommentsComponent,
    OrderShipConfirmComponent,
    MoveQueueChangeComponent,
    MoveQueueCompleteComponent,
    MoveQueueEntryAssignComponent,
    MoveQueueEntryInquiryComponent,
    MoveChangeStagingLocationComponent,
    MoveQueueNoPickComponent,
    MoveChangeQueuePriorityComponent,
    ItemComponent,
    CreateItemComponent,
    UpdateItemComponent,
    DeleteItemComponent,
    CrossRefItemComponent,
    ItemLotsComponent,
    ItemCommentsComponent,
    ItemBalancesComponent,
    AddBalanceComponent,
    StgInquiryBalanceComponent,
    DeleteBalanceComponent,
    InquiryBalanceComponent,
    ItemTallyWorkWithComponent,
    ItemTallyInquiryComponent,
    ItemAdjustmentWorkWithComponent,
    ItemAdjustmentInquiryComponent,
    ItemAdjustmentDeleteComponent,
    ItemRebalancesComponent,
    ItemLocationsComponent,
    CreateitemLocationComponent,
    DeleteitemlocationComponent,
    SideAdjustmentComponent,
    TwoSideAdjustmentComponent,
    ReSequenceComponent,
    OrderDetailsComponent,
    OrderDetailsCreateComponent,
    OrderDetailEditComponent,
    OrderDetailStockStatusComponent,
    OrderDetailItemStatusComponent,
    OrderDetailStackActivityComponent,
    OrderDetailChangeShipFromComponent,
    OrderDetailSplitLineComponent,
    OrderDetailCrossDocRefComponent,
    OrderDetailCommentsComponent,
    OrderDetailCutComponent,
    OrderDetailChangeEventComponent,
    CreateBuildingComponent,
    UpdateBuildingComponent,
    UpdateDoorComponent,
    DoorUsageComponent,
    AddDoorUsageComponent,
    AddDoorComponent,
    DoorsComponent,
    DeleteDoorComponent,
    DeleteBuildingComponent,
    BuildingsWorkWithComponent,
    BuildingContactComponent,
    OrderChangeLocLotComponent,
    OrderDetailLotStatusComponent,
    OrderDetailDeleteComponent,
    OrderDetailUcc128MaintComponent,
    OrderDetailMoveQueueComponent,
    StorerAccessInvoiceEnquiryComponent,
    StorerArAgingInquiryComponent,
    StorerRecIvcInquiryComponent,
    StorerEdiDocumentsComponent,
    StorerConsItemComponent,
    StorerUccSerCtlComponent,
    StorerUccLblCtlComponent,
    StorerPrintersComponent,
    StorerRatesBasesComponent,
    StorerChangeRatesComponent,
    StorerGen869TriggerComponent,
    StorerReasonablenessComponent,
    StorerEdiInfoComponent,
    StorerItemDefaultsComponent,
    StorerWorkWithErrorsComponent,
    StorerExportComponent,
    StorerAttributesComponent,
    StorerInvoiceInquiryComponent,
    StorerAccountStatusComponent,
    StorerAccountAttributesComponent,
    StorerXrefComponent,
    StorerAcctAttributeComponent,
    StorerCommentsComponent,
    StorerExpNoActivityComponent,
    StorerProductCodesComponent,
    StorerAutoRatesComponent,
    StorerPutawaySchemeComponent,
    StorerWwAccountAttributesComponent,
    StorerAddAccountAttributeComponent,
    StorerEditAccountAttributeComponent,
    StorerDailyStatusComponent,
    StorerShortStockComponent,
    PostalAbbreviationsComponent,
    PostalAbbreviationsAddComponent,
    PostalAbbreviationsChangeComponent,
    PostalAbbreviationsDeleteComponent,
    ReceivablesComponent,
    ReceivableAdjustInvoiceComponent,
    ReceivableCreditInvoiceComponent,
    ReceivableInquiryComponent,
    ReceivableAdjustInquiryComponent,
    ReceivableCommentsComponent,
    ReceivableDetailsComponent,
    UpdateLedgerComponent,
    AdjustmentInquiryComponent,
    TransactionInquiryComponent,
    LoginComponent,
    StorageInvoiceInquiryComponent,
    StorerAutoSchedulingProceesComponent,
    StorerAutoSchedulingProceesUpdateComponent,
    StorerAutoSchedulingProceesAddComponent,
    StorerDeleteComponent,
    ActionRsmsComponent,
    ActionRsmsAddComponent,
    ActionRsmsChangeComponent,
    StorerAutoScheduleProcresErrorComponent,
    ReceivablePrintComponent,
    ApplicationsComponent,
    ApplicationAddComponent,
    ApplicationChangeComponent,
    ApplicationModuleComponent,
    ControllBatchFileComponent,
    ControllBatchFileCreateComponent,
    ControllBatchFileEditComponent,
    ControllBatchFileDeleteComponent,
    ControllBatchFileConstraintsComponent,
    AuditHeadersComponent,
    AuditHeadersChangeComponent,
    AuditHeadersCommentsComponent,
    AuditHeadersStatusComponent,
    ApplicationAuthoritiesComponent,
    BoxContentComponent,
    BoxContentChangeComponent,
    BoxContentDeactiveComponent,
    BoxContentItemActivityComponent,
    BoxContentItemLabelComponent,
    BoxContentDeleteComponent,
    BoxContentAddPressHoldComponent,
    BoxContentRemovePressHoldComponent,
    AccountProcessingComponent,
    CreateAccountProcessingComponent,
    UpdateAccountProcessingComponent,
    DeleteAccountProcessingComponent,
    CopyWorkflowAccountProcessingComponent,
    ActivityDescriptionComponent,
    CreateActivityDescriptionComponent,
    UpdateActivityDescriptionComponent,
    BoxContentEventHistoryInquiryComponent,
    BoxContentInquiryComponent,
    BalanceErrorsComponent,
    BalanceErrorLotStatusComponent,
    BalanceErrorItemStatusComponent,
    BalanceErrorStkActInquiryComponent,
    BalanceErrorsOrdersComponent,
    BalanceErrorsAdjustmentsComponent,
    BalanceErrorsBalancesComponent,
    BalanceErrorsItemLocationsComponent,
    BalanceErrorsItemReceiversComponent,
    InventoryBooksComponent,
    InventoryBooksMissingCountComponent,
    InventoryBooksWorkSheetComponent,
    InventoryBookSynopsysComponent,
    InventoryBookToCountComponent,
    InventoryBookCountListingComponent,
    InventoryBookDeleteComponent,
    InventoryBookReservePostComponent,
    InventoryMaintainBookComponent,
    InventoryEnterCountComponent,
    InventoryLpBookToCountComponent,
    AddressTypesComponent,
    AddressTypeChangeComponent,
    AddressTypeDeleteComponent,
    AddressTypeAddComponent,
    ItemAdjustmentChangeDateComponent,
    ItemAdjustmentDisplayComponent,
    InventoryAdjustmentsComponent,
    InventoryTagsComponent,
    ChangeInventoryTagsComponent,
    ListInventoryTagsComponent,
    DiscrepanciesInventoryTagsComponent,
    TagsInventoryComponent,
    CountSummaryInventoryTagsComponent,
    ControlGroupsInventoryTagsComponent,
    BookDetailsComponent,
    ChangeCountComponent,
    OrderStagingComponent,
    AssignLaneOrderStagingComponent,
    DeleteOrderStagingComponent,
    BatchDetailsOrderComponent,
    WorkWithDockSchedularComponent,
    DockScheduleAddComponent,
    MoveHistoryComponent,
    WorkWithBatchDetailsComponent,
    BillSynopsisComponent,
    ShipConfirmComponent,
    EventHistoryComponent,
    AppointmentEntryComponent,
    UserEventComponent,
    ShortShipComponent,
    ControlFieldsComponent,
    DockScheduleComponent,
    DockScheduleAddComponent,
    DockScheduleCreateComponent,
    DockScheduleUpdateComponent,
    DockScheduleInfoComponent,
    DockScheduleMarkHotComponent,
    LtlBatchesComponent,
    LtlBatchChangeComponent,
    LtlBatchDetailsComponent,
    BookDetailsComponent,
    WorkWithBookDetailsComponent,
    WwBookDetailsAddComponent,
    WwBookTagInquiryComponent,
    WorkWithBoxesComponent,
    WwBoxesChangeComponent,
    WwBoxesDeleteComponent,
    WwBoxesAddComponent,
    WwBoxesDetailsComponent,
    WwBoxesEventInquiryComponent,
    WwBoxesInquiryComponent,
    WwBoxesItemLabelsComponent,
    WwBoxesBundleLabelsComponent,
    WwBoxesBundleManifestComponent,
    WwBoxesUpdateStsDeleteComponent,
    WwBoxesUpdateStatusComponent,
    WwBoxesChgCustomerComponent,
    WwBoxesExportBoxComponent,
    WwMasterSerialListComponent,
    WwMasterSerialListAddComponent,
    WwMasterSerialListChangeComponent,
    WwHazardousChemicalsComponent,
    HazardousChemicalsChangeComponent,
    HazardousChemicalsAddComponent,
    WwConsigneeItemsComponent,
    ConsigneeItemsChangeComponent,
    ConsigneeItemsAddComponent,
    WwConsigneeChildItemsComponent,
    ConsigneeChildItemsAddComponent,
    ConsigneeChildItemsChangeComponent,
    WwSystemCommentsComponent,
    SystemCommentsChangeComponent,
    SystemCommentsChangeSetComponent,
    SystemCommentsDeleteComponent,
    SystemCommentsAddComponent,
    WwSerializedInventoriesComponent,
    SerializedDetailsComponent,
    SerialWorksheetComponent,
    SerialCountComponent,
    SerializedAdjustmentLogComponent,
    SerializedBookSynopsysComponent,
    BookToCountComponent,
    WwCarrierEdiDocumentsComponent,
    CarrierEdiDocumentsChangeComponent,
    CarrierEdiDocumentsAddComponent,
    CarrierEdiDocumentsDeleteComponent,
    WwContentsComponent,
    ContentChangeComponent,
    ContentAddComponent,
    ContentItemLabelComponent,
    ContentAddPressHoldComponent,
    ContentRemovePressHoldComponent,
    ContentInquiryComponent,
    FilesComponent,
    AddComponent,
    CommodityPrintersComponent,
    AddCommodityPrinterComponent,
    UpdateCommodityPrinterComponent,
    DeleteCommodityPrinterComponent,
    CampusBuildingsComponent,
    AddCampusBuildingComponent,
    SynopsisComponent,
    WwCommentSubcodesComponent,
    CommentSubCodesAddComponent,
    WwCommentSubcodesChangeComponent,
    WwCustomerAddressesComponent,
    CustomerAddressesAddComponent,
    CustomerAddressesChangeComponent,
    MapCrossReferenceComponent,
    AccountSelectionComponent,
    CustomersComponent,
    CustomerCreateComponent,
    CustomerUpdateComponent,
    EmailExitPointsComponent,
    EmailExitPointsCreateComponent,
    EmailExitPointsUpdateComponent,
    EmailExitPointsDeleteComponent,
    EmailExitPointsCopyComponent,
    ForecastComponent,
    ForecastCreateComponent,
    ForecastUpdateComponent,
    ForecastDeleteComponent,
    ForecastDetailsComponent,
    CustomerConsolidateComponent,
    WwCarriersComponent,
    CarriersEditComponent,
    CarriersDeleteComponent,
    CarriersAddComponent,
    CarriersFakTransComponent,
    WwCarEquipComponent,
    CarriersContactsComponent,
    ProductCodeDescriptionComponent,
    ProdCodeDescChangeComponent,
    ProdCodeDescAddComponent,

    AccountSelectionUpdateComponent,
    WwConsigneeBasedTriggersComponent,
    ConsigneeBasedTriggerChangeComponent,
    ConsigneeBasedTriggerAddComponent,
    WwMetricConversionsComponent,
    MetricConversionChangeComponent,
    WwExpectedDurationsComponent,
    ExpectedDurationsAddComponent,
    ExpectedDurationsChangeComponent,
    ExpectedDurationsDeleteComponent,
    WwContactTypesComponent,
    ContactTypesAddComponent,
    ContactTypesChangeComponent,
    WwCustomerRatingPreferenceComponent,
    CustomerRatingPrefChangeComponent,
    CustomerRatingPrefAddComponent,
    DataElementsComponent,
    AddDataElementComponent,
    UpdateDataElementComponent,
    DeleteDataElementComponent,
    ReasonCodesElementComponent,
    UccLabelCtrlFileComponent,
    AttributeConsigneeComponent,
    GeneralInvoicesComponent,
    AddGeneralInvoiceComponent,
    EditGeneralInvoiceComponent,
    DeleteGeneralInvoiceComponent,
    SrchAttrInquiryComponent,
    ContactsConsigneeComponent,
    DocumentsConsigneeComponent,
    WwDockSchedulerControlsComponent,
    DockSchedulerControllAddComponent,
    WwTripExpensesComponent,
    TripExpensesAddComponent,
    TripExpensesChangeComponent,
    EmailAddressComponent,
    AddEmailAddressComponent,
    EditEmailAddressComponent,
    ExractReportsBaseFieldsComponent,
    ExtractReportAddComponent,
    ExtractReportChangeComponent,
    WwEquipmentOperatorsComponent,
    AddEquipmentOperatorsComponent,
    ChangeEquipmentOperatorsComponent,
    DeleteEquipmentOperatorsComponent,
    WwWorkAssignmentsComponent,
    WwEntityAttributesComponent,
    WwOperatorEquipmentComponent,
    OperatorEquipmentCreateComponent,
    OperatorEquipmentChangeComponent,
    OperatorEquipmentDeleteComponent,
    ExtractReportFiltersComponent,
    ExtractReportFiltersAddComponent,
    ExtractReportFiltersUpdateComponent,
    ExtractReportFiltersCopyComponent,
    WwWorkScheduleComponent,
    WorkScheduleCreateComponent,
    WorkScheduleUpdateComponent,
    WorkScheduleDeleteComponent,
    OperatorEquipmentCopyComponent,
    WwValidityCharacterRemovalComponent,
    ValidityCharacterRemovalAddComponent,
    ValidityCharacterRemovalChangeComponent,
    ValidityCharacterRemovalDeleteComponent,
    WwItemCountSchedulesComponent,
    ItemCountScheduleCreateComponent,
    ItemCountScheduleChangeComponent,
    WwProductCodesComponent,
    ProductCodeCreateComponent,
    ProductCodeChangeComponent,
    WwKanbanComponent,
    KanbanAddComponent,
    KanbanChangeComponent,
    KanbanDeleteComponent,
    WwScheduleExceptionsComponent,
    ScheduleExceptionAddComponent,
    ScheduleExceptionChangeComponent,
    ScheduleExceptionDeleteComponent,
    WwGeneralInvoiceDetailsComponent,
    GeneralInvoiceDetailsAddComponent,
    GeneralInvoiceDetailsChangeComponent,
    GeneralInvoiceDetailsDeleteComponent,
    GeneralInvoiceDetailsCommentsComponent,
    WorkWithExtractReportsComponent,
    ExtractReportsAddComponent,
    ExtractReportsEditComponent,
    WwPeriodsComponent,
    PeriodsAddComponent,
    PeriodChangeComponent,
    PeriodDeleteComponent,
    WwLaborPlansComponent,
    LaborPlanCreateComponent,
    LaborPlanChangeComponent,
    SerialNumbersComponent,
    EditSerialNumberComponent,
    DeleteSerialNumberComponent,
    WwSerialNumberComponent,
    AddSerialNumberComponent,
    LedgerCodesComponent,
    AddLedgerCodeComponent,
    EditLedgerCodeComponent,
    DeleteLedgerCodeComponent,
    ConsolidateLedgerCodeComponent,
    AddGroupComponent,
    EditGroupComponent,
    DetailsGroupComponent,
    AddDetailsGroupComponent,
    EditDetailsGroupComponent,
    DeleteGroupComponent,
    LaborPlanGroupComponent,
    OperatorGroupsComponent,
    AddOperatorGroupComponent,
    EditOperatorGroupComponent,
    LoadOutComponent,
    UnloadPalletComponent,
    DeleteLoadOutComponent,

    WwItemAttributesComponent,
    WwItemAttributesCreateComponent,
    WwItemAttributesUpdateComponent,
    WwItemAttributesDeleteComponent,
    WwItemForecastDetailsComponent,
    WwItemForecastDetailsCreateComponent,
    WwItemForecastDetailsUpdateComponent,
    WwItemForecastDetailsDeleteComponent,
    WwStorerAttributesComponent,
    WwStorerAttributesCreateComponent,
    WwStorerAttributesDeleteComponent,
    WwStorerAttributesUpdateComponent,
    WwLocaterComponent,
    WwLocaterDeleteComponent,
    WwLocaterInquiryComponent,
    WwLocaterResequenceComponent,
    WwLocater1sAdjustComponent,
    MaintQuestionsComponent,
    MaintquestionsAddComponent,
    MaintquestionsChangeComponent,
    MaintquestionsDeleteComponent,
    MaintquestionsCopyComponent,
    WorkWithLotStatusComponent,
    WwLotStatusAddComponent,
    WwLotStatusChangeComponent,
    WwLotStatusInquiryComponent,
    WwLaborProcessingComponent,
    LaborProcessingAddComponent,
    LaborProcessingChangeComponent,
    LaborProcessingCopyComponent,
    LaborProcessingDeleteComponent,
    MetricsConstraintsComponent,
    MetricsconstraintsAddComponent,
    MetricsconstraintsUpdateComponent,
    MetricsconstraintsDeleteComponent,
    MessageClassShredComponent,
    MessageclassshredAddComponent,
    MessageclassshredChangeComponent,
    MessageclassshredCopyComponent,
    MessageclassshredDeleteComponent,
    WwLocater2sAdjustBBComponent,
    WwLocater2sAdjustNewItmComponent,
    WwLocaterLoadSheduledFloorMoveComponent,
    WwInboundDetailsComponent,
    WwInboundDetailsAddComponent,
    WwInboundDetailsChangeComponent,
    WwInboundDetailsCommentsComponent,
    WwInboundDetailsDeleteComponent,
    MessageclassshredDetailsComponent,
    MessageclassshredDetailsChangeComponent,
    MessageclassshredDetailsDeleteComponent,
    MessageclassshredDetailsDetailsComponent,
    MessageclassshredDetailsDetailsChangeComponent,
    MessageclassshredDetailsDetailsDeleteComponent,
    ExtractReportWhereclausesComponent,
    ExtractReportWhereclausesAddComponent,
    ExtractReportWhereclausesDeleteComponent,
    ExtractReportWhereclausesUpdateComponent,
    ExtractReportSortClausesComponent,
    ExtractReportSortClausesAddComponent,
    ExtractReportSortClausesDeleteComponent,
    ExtractReportSortClausesUpdateComponent,
    WwHazardMaterialsGroupComponent,
    WwHazardMaterialsGroupAddComponent,
    WwHazardMaterialsGroupChangeComponent,
    WwHazardMaterialsGroupDeleteComponent,
    WwLaborPlanComponent,
    WwLaborPlanAddComponent,
    WwLaborPlanChangeComponent,
    WwLaborPlanDeleteComponent,
    WwLaborPlanDetailsComponent,
    WwLaborPlanEventInquiryComponent,
    WorkWithEmailFormatComponent,
    WwEmailFormatChangeComponent,
    WwEmailFormatInsertAfterComponent,
    WwEmailFormatInsertBeforeComponent,
    WwEmailFormatDeleteComponent,
    WwInboundDetailsStockStatusComponent,
    WwInboundDetailsActivityInquiryComponent,
    WwInboundDetailsExtraDataComponent,
    WwOrderEventsComponent,
    WwOrderEventsChangestatusComponent,
    WwIncomingEventsComponent,
    WwIncomingEventsErrordetailsComponent,
    WwIncomingEventsDeleteComponent,
   
    MetricsDefinationsComponent,
    MetricsDefinationsAddComponent,
    MetricsDefinationsUpdateComponent,
    MetricsDefinationsDeleteComponent,
    WwOrderEventsDeleteComponent,
    WwValidationEventsComponent,

    WwValidationEventsDeleteComponent,
      WwValidationEventsErrordetailsComponent,
   

    
    
    WwInboundDetailsPurchaseOrdsComponent,

    WwMesssageClassTablesComponent,
    AddMessageClassTablesComponent,
    ChangeMessageClassTablesComponent,
    WwMessageClassColumnsComponent,
    AddMessageClassColumnsComponent,
    ChangeMessageClassColumnsComponent,

    StorerCreate2Component,
    StorerCreate3Component,
    StorerCreate4Component,
    StorerCreate5Component,
    StorerCreate6Component,


    DashboardComponent,





    StorerUpdate2Component,
    StorerUpdate3Component,
    StorerUpdate4Component,
    StorerUpdate5Component,
    StorerUpdate6Component,

    ItemCreate2Component,
    ItemCreate3Component,
    ItemCreate4Component,
    ItemUpdate1Component,
    ItemUpdate2Component,
    ItemUpdate3Component,
    ItemUpdate4Component,

    OrderCreate2Component,
    OrderUpdate2Component,
    OrdersCreateFilled1Component,
    ItemCrossRefComponent,
    ItemLotsUpdateComponent,
    MoveQueueResolveComponent,
    StockEnquiryComponent,
    ItemStatusInquiryComponent,
    TwosidedAdjBucketToBucketComponent,
    RedirectForm1Component,
    RedirectForm2Component,
    ChangeEventCommentsComponent,
    StorerCommentCreateComponent,
    StorerCommentUpdateComponent,
    StorerCommentDeleteComponent,

    ItemCommentCreateComponent,
    ItemCommentUpdateComponent,
    ItemCommentDeleteComponent,
    ConsigneeCommentsUpdateComponent,
    ConsigneeCommentsCreateComponent,
    ConsigneeCommentsDeleteComponent

    

  ],
  exports: [MatDatepickerModule, MatNativeDateModule],
  providers: [MatDatepickerModule],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ReceiveModule,
    ShippingModule,
    AuthModule,
    NgxMatTimepickerModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    NgxMatNativeDateModule,
    MatSelectModule,
    MatDatepickerModule,
    MatRadioModule,
    MatCardModule,
    LayoutModule,
    MatSidenavModule,
    MatListModule,
    MatTableModule,
    NgxMatDatetimePickerModule,
    Ng2SearchPipeModule,
    FlexLayoutModule,
    MatProgressSpinnerModule,
    SidebarModule.forRoot(),
    MatTooltipModule,
    MatSlideToggleModule,ApplicationPipesModule,
    MatDialogModule
   
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule {}
