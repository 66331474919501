<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Delete Labor Processing</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Building Number  : </mat-label> 
                    <input matInput formControlName="BuildingNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Storer Number: </mat-label> 
                    <input matInput formControlName="StorerNumber"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Process : </mat-label> 
                    <input matInput formControlName="Process"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label> Operator Group : </mat-label> 
                    <input matInput formControlName="OperatorGroup"/>
                </mat-form-field>
            </div>

            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Step : </mat-label> 
                    <input matInput formControlName="Step"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Move Tp: </mat-label> 
                    <input matInput formControlName="MoveTp" />
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Condition : </mat-label> 
                    <input matInput formControlName="Condition"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>Task </mat-label> 
                    <input matInput formControlName="Task"/>
                </mat-form-field>
            </div>
            
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label> Responsible Profile : </mat-label> 
                    <input matInput formControlName="ResponsibleProfile"/>
                </mat-form-field>

                <mat-form-field  appearance="outline">
                    <mat-label>MQ: </mat-label> 
                    <input matInput formControlName="MQ" />
                </mat-form-field>
            </div>
        </div>

    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="deleteLaborProcessing()" class="addButton">           
        <mat-icon mat-icon-button color="warn">delete</mat-icon>
        <mat-label> Delete Labor Processing</mat-label>
    </button>
</mat-card-footer>
