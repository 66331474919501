import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwOrderEventsChangestatusComponent } from './ww-order-events-changestatus/ww-order-events-changestatus.component';
import { WwOrderEventsDeleteComponent } from './ww-order-events-delete/ww-order-events-delete.component';



@Component({
  selector: 'app-ww-order-events',
  templateUrl: './ww-order-events.component.html',
  styleUrls: ['./ww-order-events.component.css']
})
export class WwOrderEventsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'Opt',
    'Bill Number',
    'Seq No',
    'Elm No',
    'Status Code',
    'Description',
    'Reason Code',
    'Date',
    'Time',
    'Sts',
    'Actions'
  ];
  dataSource:any=[]


  duplicateSource:any =[
    {
      OPTION:'_',
      EVDOCN:'1826',
      EVNUMR:'001',
      EVDELM:'888',
      EVCODE:'CSH',
      DESC:"Checker's Sheet Printed",
      EVSRCD:'',
      EVDATE:'08/06/19',
      EVTIME:'18:17',
      EVSTAT:'DL',
     
        
    },
   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      SRCH1:'',
      DECD:'',
      FRDATE:'',
      TODATE:'',
      EVCD:'',
      ESTS:'',

     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/OrderEvents/read']);
 
}
// 
OrderEventChangeStatusForm(element:any){
  this.matDialog.open(WwOrderEventsChangestatusComponent, {
      "width": '1000px',
     "maxHeight": '90vh',
    'data': element,
   "autoFocus": false                                
 });
  }
  OrderEventDeleteForm(element:any){
    this.matDialog.open(WwOrderEventsDeleteComponent, {
        "width": '1000px',
       "maxHeight": '90vh',
      'data': element,
     "autoFocus": false                                
   });
    }




  
            // }

            // addAttribute(){

            // }

            // df
             
}
