import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import { PeriodsService } from '../periods.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-period-change',
  templateUrl: './period-change.component.html',
  styleUrls: ['./period-change.component.css'],
})
export class PeriodChangeComponent implements OnInit {
  form!: FormGroup;

  constructor(
    public periodSvc: PeriodsService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<PeriodChangeComponent>
  ) {}

  ngOnInit(): void {
    // PESTOR:'', //storer
    // STDT:'', //periodStartDate
    // ENDT:'', //period end date
    // PEBALN:'', // balance written
    // PEINV:'', // storage invoice  number
    // PESTAT:'' //status

    this.form = this.fb.group({
      PESTOR: this.anyvariable.PESTOR,
      STDT: this.anyvariable.PESTDT,
      ENDT: this.anyvariable.PEENDT,
      PEBALN: this.anyvariable.PEBALN,
      PEINV: this.anyvariable.PEINV,
      PESTAT: this.anyvariable.PESTAT,
    });
  }

  result: any;
  user = localStorage.getItem('userName');

  updatePeriod() {
    var res = this.form.value;
    console.log(res);

    this.result = this.periodSvc
      .updatePeriod(
        res.PESTOR,
        res.STDT,
        res.ENDT,
        res.PEBALN,
        res.PEINV,
        res.PESTAT,
        this.user
      )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
        // var msg = JSON.parse(this.result).returnedData.message;

        // this.successMessage(msg);
        if (JSON.parse(this.result[0].data[1].value).success) {
          //alert('Record Created SuccessFully');
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/storer']);
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.failureMessage(msg);
        }
      });
  }
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    //this.router.navigate(['/main-nav/periods/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
