import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StorerUpdate4Component } from '../storer-update4/storer-update4.component';
import { StorerServiceService } from '../storer-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-storer-update3',
  templateUrl: './storer-update3.component.html',
  styleUrls: ['./storer-update3.component.css']
})
export class StorerUpdate3Component implements OnInit {

  constructor(
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerUpdate3Component>,
   private matDialog: MatDialog
    ) { }

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');  

    ngOnInit(): void {

      console.log(this.anyvariable.storerNumber);
         this.form = this.fb.group({
  
          storeNumber: this.anyvariable.STORE,
          suffix:this.anyvariable.STSFX,
          storeName:this.anyvariable.STNAME,
          defaultFreightCharge:[this.anyvariable.STFRCH ,
            
            [
              Validators.required
              
            ],
          ],
          defaultCODCharge:this.anyvariable.STCODC,
          deferredHandlingPer:[this.anyvariable.STDEFH,,
            
            [
              Validators.required
              
            ],], 
          rcrStorageRate:this.anyvariable.STMSRT,
          rcrStorageBasis:this.anyvariable.STMSBA,
          recStorageRate:this.anyvariable.STRSRT,
          recStorageBasis:this.anyvariable.STRSBA,
          regHandStorageRate:this.anyvariable.STRHRT,
          regHandStorageBasis:this.anyvariable.STRHBA, 
          altHandStorageRate:this.anyvariable.STAHRT,
          altHandStorageBasis:this.anyvariable.STAHBA, 
          distHandStorageRate:this.anyvariable.STDHRT,
          distHandStorageBasis:this.anyvariable.STDHBA,         
          insLiability:this.anyvariable.STILCA,
          amount:[this.anyvariable.STINSL,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],  
          wrLotMinimum:[this.anyvariable.STWRLM,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          storageLotMin:[this.anyvariable.STLTMI,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          wrItemHdlMin:[this.anyvariable.STWRIM,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          storageItemMin:[this.anyvariable.STITMI,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          wrItemStgMin:[this.anyvariable.STWISM,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          storageInvoiceMin:[this.anyvariable.STINMI,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          wrStorageMin:[this.anyvariable.STSTMI,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          wrHandlingMin:[this.anyvariable.STHDMI,[
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],],
          
         });
    }
  
    storerUpdate3(){
      var res = this.form.value;     
      this.result = this.storerSvc
        .storerServiceUpdate3( 
          res.storeNumber,
          res.suffix,
          res.storeName,
          res.defaultFreightCharge,
          res.defaultCODCharge,
          res.deferredHandlingPer,
          res.rcrStorageRate,
          res.rcrStorageBasis,
          res.recStorageRate,
          res.recStorageBasis,
          res.regHandStorageRate,
          res.regHandStorageBasis,
          res.altHandStorageRate,
          res.altHandStorageBasis,
          res.distHandStorageRate,
          res.distHandStorageBasis,
          res.insLiability,
          res.amount,
          res.wrLotMinimum,
          res.storageLotMin,
          res.wrItemHdlMin,
          res.storageItemMin,
          res.wrItemStgMin,
          res.storageInvoiceMin,
          res.wrStorageMin,
          res.wrHandlingMin,
          this.user
  
        ).subscribe((data: any) => {
          this.result = data;
            if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
            
           const msg= this.result[0].data[this.result[0].data.length-1].value;
            this.successMessage(msg)
            this.closeDialog();
            this.update4();
         }else{
          this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
         }
        });
    }
  
  
    successMessage(msg:any){
      Swal.fire(msg,'','success');
      //this.router.navigate(['/main-nav/storer/read']);
    }
  
    failureMessage(msg:any){
      Swal.fire(msg,'','error');
     // this.router.navigate(['/main-nav/storer/read']);
    }    
  
    update4() {
      var res = this.anyvariable;
      this.matDialog.open(StorerUpdate4Component, {        
        width: '1000px',
        maxHeight: '80vh',  
        data: res,
        autoFocus: false,
      });
    }

  closeDialog(){
    this.dialogRef.close();
  }

}
