<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Generate Invoice Comments Maintenance</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
    <div class="row">
        <div class="">
            <mat-form-field appearance="outline">
                <mat-label>Control</mat-label>
                <input matInput formControlName="Control" />
            </mat-form-field>
        </div>
        <div class="">
            <mat-form-field appearance="outline">
                <mat-label>Sequence</mat-label>
                <input matInput formControlName="sequence" />
            </mat-form-field>
        </div>

        <div class="">
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="r"></textarea>
            </mat-form-field>
        </div>

    </div>

    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="comment()" class="addButton">           
        <mat-icon mat-icon-button color="primary">add</mat-icon>
        <mat-label>General Invoice DEtail</mat-label>
    </button>
</mat-card-footer>


