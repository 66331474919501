import { ConsolidateLedgerCodeComponent } from './consolidate-ledger-code/consolidate-ledger-code.component';
import { DeleteLedgerCodeComponent } from './delete-ledger-code/delete-ledger-code.component';
import { EditLedgerCodeComponent } from './edit-ledger-code/edit-ledger-code.component';
import { AddLedgerCodeComponent } from './add-ledger-code/add-ledger-code.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ledger-codes',
  templateUrl: './ledger-codes.component.html',
  styleUrls: ['./ledger-codes.component.css'],
})
export class LedgerCodesComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'LedgerCode',
    'SubCode',
    'Description',
    'CostCenter',
    'SubCenter',
    'Actions',
  ];
  dataSource: any = [];

  duplicateSource: any = [
    {
      LedgerCode: 100,
      SubCode: '',
      Description: 'Handling Charges',
      CostCenter: 'H',
      SubCenter: '',
    },
    {
      LedgerCode: 101,
      SubCode: '',
      Description: 'Detention',
      CostCenter: 'T',
      SubCenter: 1,
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      LedgerCode: 0,
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  addledger() {
    this.matDialog.open(AddLedgerCodeComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  updateledger() {
    this.matDialog.open(EditLedgerCodeComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  deleteLedger() {
    this.matDialog.open(DeleteLedgerCodeComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  consolidate() {
    this.matDialog.open(ConsolidateLedgerCodeComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
}
