import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';


@Component({
  selector: 'app-validity-character-removal-delete',
  templateUrl: './validity-character-removal-delete.component.html',
  styleUrls: ['./validity-character-removal-delete.component.css']
})
export class ValidityCharacterRemovalDeleteComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ValidityCharacterRemovalDeleteComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      storerNumber:this.anyvariable.storerNumber,
      scanType:this.anyvariable.scanType,
      qualifierStart:'',
      qualifierLength:'',
      valueToRemove:this.anyvariable.qualifier
    })
  }

  delete(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


}
