<mat-card class="over-Flow">

  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Change Staging Location</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
   </div>

      <mat-card-content class="tally-serach-form" > 
        <form [formGroup]="form">
          <div class="row">
            <div class="col">
          
            <mat-form-field  appearance="outline"><mat-label> Item : </mat-label> 
                <input matInput formControlName="item" readonly />
              </mat-form-field>

              <mat-form-field  appearance="outline"><mat-label> Transaction No : </mat-label> 
                <input matInput formControlName="transNo" readonly />
              </mat-form-field>

            <mat-form-field appearance="outline">  <mat-label> Lots: </mat-label> 
                  <input
                  matInput
                  formControlName="lots"
                  style="text-transform: uppercase"/>
              
              </mat-form-field>
       
            <mat-form-field appearance="outline">  <mat-label> From : </mat-label> 
                <input matInput formControlName="from" />
              </mat-form-field>

              <mat-form-field appearance="outline">  <mat-label> To : </mat-label> 
                <input matInput formControlName="to" />
              </mat-form-field>

         
            <mat-form-field appearance="outline">  <mat-label> Source: </mat-label> 
                <input
                  matInput
                  formControlName="source"
                  style="text-transform: uppercase"
                />            
              </mat-form-field>
              <hr>
            <mat-form-field appearance="outline">  <mat-label> New Staging location  : </mat-label> 
                <input matInput formControlName="newStagingloc" placeholder="BBB FF/AAAA/SSSS" />
              </mat-form-field>

                <mat-form-field appearance="outline">  <mat-label> Change for only this move  </mat-label> 
        
                    <mat-select formControlName="changeThisMove"  matNativeControl required>
                      <mat-option value="Y">Y</mat-option>
                        <mat-option value="N">N</mat-option> 								
                    </mat-select>
                  </mat-form-field>
            
                <mat-form-field appearance="outline">  <mat-label> Change all moves for this document </mat-label> 
        
                    <mat-select formControlName="changeAllMoves" matNativeControl required>
                        <mat-option value="Y">Y</mat-option> 
                         <mat-option value="N">N</mat-option> 								
                    </mat-select>
                  </mat-form-field>
            
                </div>
              </div>
              <button [disabled]="!(form.valid && form.dirty)" mat-button class="buttons addButton" (click)="updateStageLoc()">Update</button>
          </form>      
      </mat-card-content>
</mat-card>
    
