<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title>Work With Carrier Equipment</mat-card-title>
    
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="left">
        <div class="row">
            <div class="col">
                <mat-form-field  appearance="outline">
                    <mat-label>SCAC Code  : </mat-label> 
                    <input matInput formControlName="SCACCode"/>
                </mat-form-field>

            </div>
        </div>

    </form>


    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="SCACCode">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            SCAC Code
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.SCACCode }}</td>
        </ng-container>
  
        <ng-container matColumnDef="TrailerType">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Trailer Type
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.TrailerType }}</td>
        </ng-container>

        

        <ng-container matColumnDef="Description">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Description
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Description }}</td>
        </ng-container>

        <ng-container matColumnDef="Status">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Status
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Status }}</td>
        </ng-container>

        <ng-container matColumnDef="Action">
            <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
              Actions
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-select  placeholder="Select Option">
               

              </mat-select>
            </td>
          </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
        
    </table>
</mat-card-content>
