import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-extract-report-change',
  templateUrl: './extract-report-change.component.html',
  styleUrls: ['./extract-report-change.component.css']
})
export class ExtractReportChangeComponent implements OnInit {

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<ExtractReportChangeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      baseReport: [null],
      sequence: '10',
      fieldName:[null],
      filterName:[null],
      columnHeading:[null]
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }

}
