import { DetailsGroupComponent } from './details-group/details-group.component';
import { DeleteGroupComponent } from './delete-group/delete-group.component';
import { EditGroupComponent } from './edit-group/edit-group.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-labor-plan-group',
  templateUrl: './labor-plan-group.component.html',
  styleUrls: ['./labor-plan-group.component.css'],
})
export class LaborPlanGroupComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'GroupName',
    'Building',
    'MaximumConcurrent',
    'Status',
    'Actions',
  ];
  dataSource: any = [];

  duplicateSource: any = [
    {
      GroupName: 'TEST GROUP',
      Building: 'ONE',
      MaximumConcurrent: 23,
      Status: 'AC',
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      NAME: '',
      BLDG: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  addGroup() {
    this.matDialog.open(AddGroupComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  updateGroup() {
    this.matDialog.open(EditGroupComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  deleteGroup() {
    this.matDialog.open(DeleteGroupComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  detailsGroup() {
    this.matDialog.open(DetailsGroupComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
}
