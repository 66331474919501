import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-ww-car-equip',
  templateUrl: './ww-car-equip.component.html',
  styleUrls: ['./ww-car-equip.component.css']
})
export class WwCarEquipComponent implements OnInit {

  form!: FormGroup;



  displayedColumns: string[] = [
    'SCACCode',
    'TrailerType',
    'Description',
    'Status',
    'Action',
    
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      SCACCode:'',
      TrailerType:'',
      Description:'',     
      Status:'',
      Action:'',

    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwCarEquipComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
    SCACCode:'SJPS'
     });

     this.dataSource = this.duplicateSource;
  }



  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }



}
