import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-schedule-exception-delete',
  templateUrl: './schedule-exception-delete.component.html',
  styleUrls: ['./schedule-exception-delete.component.css']
})
export class ScheduleExceptionDeleteComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ScheduleExceptionDeleteComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      HETYPE:this.anyvariable.type,
      HEBLDG:this.anyvariable.build,
      HEACCT:this.anyvariable.account,
      DATE:this.anyvariable.exceptionDate,
      subTypeHours:'',
      //INOU:this.anyvariable.inOut,
      HEINOM:this.anyvariable.inclOmit,
      HESTRT:this.anyvariable.startTime,
      HEENDT:this.anyvariable.endTime,
      HEDESC:''
    })
  }

  delete(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
