import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwLaborPlanAddComponent } from './ww-labor-plan-add/ww-labor-plan-add.component';
import { WwLaborPlanChangeComponent } from './ww-labor-plan-change/ww-labor-plan-change.component';
import { WwLaborPlanDeleteComponent } from './ww-labor-plan-delete/ww-labor-plan-delete.component';
import { WwLaborPlanDetailsComponent } from './ww-labor-plan-details/ww-labor-plan-details.component';
import { WwLaborPlanEventInquiryComponent } from './ww-labor-plan-event-inquiry/ww-labor-plan-event-inquiry.component';
@Component({
  selector: 'app-ww-labor-plan',
  templateUrl: './ww-labor-plan.component.html',
  styleUrls: ['./ww-labor-plan.component.css']
})
export class WwLaborPlanComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'T',
    'DocumentNumber',
    'CR',
    'RP',
    'BuildingLocation',
    'Storer',
    'Date',
    'Time',
    'Actv',
    'SCAC',
    'Operator',
    'Location',
    'Actions'
  ];

  dataSource: any = []

  duplicateSource: any = [
    {
      T: 0,
      DocumentNumber: '4790321',
      CR: '',
      RP: '',
      BuildingLocation: '100',
      Storer: '100',
      Date: '08/27/19',
      Time: '15:55',
      Actv: 'No',
      SCAC: '',
      Operator: '',
      Location: 'DOCK/1',


    },
    {
      T: 0,
      DocumentNumber: '4790352',
      CR: '',
      RP: '',
      BuildingLocation: '612',
      Storer: '773',
      Date: '12/27/19',
      Time: '14:59',
      Actv: 'No',
      SCAC: 'HAFE',
      Operator: 'JKOHAN John Kohan ',
      Location: 'DOCK/3',

    },

  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      Document: '',
      MustStartDate: '',
      Building: '',
      Status: 'OP',
      Storer: '',
      AppointmentDate: '',
      Active: '',
      SCAC: '',
      Operator: '',
      Manual_Auto: 'A'

    })

    this.dataSource = this.duplicateSource;
  }
  addWwLaborPlan()
  {
   this.matDialog.open(WwLaborPlanAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
  changeWwLaborPlan()
  {
   this.matDialog.open(WwLaborPlanChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
  detailsLaborPlan()
  {
   this.matDialog.open(WwLaborPlanDetailsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
  deleteLaborPlan()
  {
   this.matDialog.open(WwLaborPlanDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
  eventInquiryLaborPlan()
  {
   this.matDialog.open(WwLaborPlanEventInquiryComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }


}
