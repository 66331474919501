
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service'
import { WwIncomingEventsErrordetailsComponent } from './ww-incoming-events-errordetails/ww-incoming-events-errordetails.component';
import { WwIncomingEventsDeleteComponent } from './ww-incoming-events-delete/ww-incoming-events-delete.component';
@Component({
  selector: 'app-ww-incoming-events',
  templateUrl: './ww-incoming-events.component.html',
  styleUrls: ['./ww-incoming-events.component.css']
})
export class WwIncomingEventsComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  displayedColumns: string[] = [
    'Opt',
    'Bill',
    'Storer',
    'Reference Number',
    'Event Code',
    'Reason Code',
    'Date',
    'Time',
    'Actions'
    
    
    
  ];
  dataSource:any=[]


  duplicateSource:any =[
    {
      OPTION:'_',
      OHBILL:'50013',
      OHSSFX:'11',
      OHSREF:'',
      EVCODE:'D1',
      EVSRCD:'',
      EVDATE:'2/25/20',
      EVTIME:'10:05',
      
     
        
    },
   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      BILL:'',
      SREF:'',
      PO:'',
      PRO:'',
     

     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/incoming-events/read']);
 
}
 
 IncomingEventErrorDetailsForm(element:any){
     this.matDialog.open( WwIncomingEventsErrordetailsComponent, {
       "width": '1000px',
    "maxHeight": '90vh',
    'data': element,
  "autoFocus": false                                
  });
 }

 IncomingEventDeleteForm(element:any){
  this.matDialog.open( WwIncomingEventsDeleteComponent, {
    "width": '1000px',
 "maxHeight": '90vh',
 'data': element,
"autoFocus": false                                
});
}




  
            // }

            // addAttribute(){

            // }

            // df    
          }
