<mat-card>

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Delete An Equipment Operator</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>User ID</mat-label>
            <input matInput formControlName="UserId" readonly />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input matInput formControlName="Name" readonly />
          </mat-form-field>
          
          <button
            mat-button
            class="buttons removeButton"
            (click)="deleteEquipmentOperator()">
            Delete
          </button>
        </div>
      </div>
    </form>
    </mat-card-content>
</mat-card>

