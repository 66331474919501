<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Warehouse Zones </mat-card-title>
    <div class="buttons">
      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search Map Zone"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> -->
      </button>

      <button
        mat-button
        class="addButton buttons"
        (click)="mapZoneCreate()"
        matTooltip="Add Map Zone"
      >
        <mat-icon>add</mat-icon>
        <!-- <span>New Map Zone</span> -->
      </button>
    </div>
  </mat-card-header>

  <mat-card-content class="tally-serach-form">
    <form
      [formGroup]="form"
      (ngSubmit)="getMapZoneBySerach(form.value)"
      *ngIf="searchTextDisplay"
      class="form"
    >
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Building </mat-label>

            <input matInput formControlName="building" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Zone </mat-label>

            <input matInput formControlName="zone" />
          </mat-form-field>
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="Building">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Building
      </th>
      <td mat-cell *matCellDef="let element">{{ element.WZBLDG }}</td>
    </ng-container>

    <ng-container matColumnDef="Zone">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Zone
      </th>
      <td mat-cell *matCellDef="let element">{{ element.WZZONE }}</td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Description
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.WZDESC }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Locns">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Locations
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTRF }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Empty">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Empty
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHPRO }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Items">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Items
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHCARR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="On">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>On</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTOR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Hand">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Hand
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTOR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="PctOpen">
      <th mat-header-cell mat-header-cell  *matHeaderCellDef>
        Pct Open
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHSTOR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef>
        <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-button (click)="updateMapZone(element)">
                <mat-icon mat-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-button (click)="deleteMapZone(element)">
                <mat-icon mat-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
