import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { IexitPointsService } from '../iexit-points.service';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';

@Component({
  selector: 'app-exit-points-delete',
  templateUrl: './exit-points-delete.component.html',
  styleUrls: ['./exit-points-delete.component.css'],
})
export class ExitPointsDeleteComponent implements OnInit {
  storer: any;
  form!: FormGroup;
  result: any;
  constructor(
    public iExitSvc: IexitPointsService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router : Router,
    private CustomAlertService: CustomAlertService,
    private matDialog: MatDialog,
    private dialogRef: MatDialogRef<ExitPointsDeleteComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      Storer: this.anyvariable.SFSTOR,
      Exit_Point: this.anyvariable.SFFORM,
      Choice: this.anyvariable.SFPGM,
    });
  }

  user=localStorage.getItem('userName');

  closeDialog() {
    this.dialogRef.close();
  }

  deleteExitPoint() {
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete Exit Point:' + this.anyvariable.SFFORM + '?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {

    
    this.result = this.iExitSvc
      .deleteExitPoint(this.user,this.anyvariable.SFSTOR, this.anyvariable.SFSSFX, this.anyvariable.SFFORM, this.anyvariable.SFPGM)
      .subscribe((data: any) => {
        this.result = data;
      //  var msg = JSON.parse(this.result).returnedData.message;
        console.log( this.result);

        if(JSON.parse(this.result[0].data[1].value).success){
          //alert('Record Created SuccessFully');
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          this.router.navigate(['/main-nav/exitpoint/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }

      

       // this.successMessage(msg);
      });
    }
  });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/exitpoint/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
}
