import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CustomAlertComponent } from './custom-alert.component';

@Injectable({
  providedIn: 'root'
})
export class CustomAlertService {

  constructor(private dialog: MatDialog) { }

  // openCustomAlert(msg:any) {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.width = '300px';
  //   dialogConfig.panelClass = 'confirm-dialog-container';
  //   dialogConfig.disableClose = true;
  //   data:{
  //     message : msg
  //   }

  // //  this.dialog.open(CustomAlertComponent, dialogConfig);
  //   return this.dialog.open(CustomAlertComponent, dialogConfig);

    
  // }

  openCustomAlert(msg:any){
    return this.dialog.open(CustomAlertComponent,{
       width: '450px',
       panelClass: 'confirm-dialog-container',
       disableClose: true,
       data :{
         message : msg
       }
     });
   }
}
