import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IitemService } from '../iitem.service';
import { ItemUpdate4Component } from '../item-update4/item-update4.component';

@Component({
  selector: 'app-item-update3',
  templateUrl: './item-update3.component.html',
  styleUrls: ['./item-update3.component.css'],
})
export class ItemUpdate3Component implements OnInit {
  form!: FormGroup;
  result: any;

  constructor(
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    public itemSvc: IitemService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemUpdate3Component>,
    private router:Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({
      
      storeNumber: this.anyvariable.ITSTOR,
      suffix: this.anyvariable.ITSSFX,
      item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,

      inventoryUnit: 'EACHES', // static data in RPG
      shippingUnit: 'EACHES', // static data in RPG
    
      domesticSurface:this.anyvariable.ITDOMS, 
      domesticAir:this.anyvariable.ITDOMA, 
      internationalSurface: this.anyvariable.ITINTS,
      internationalAir:this.anyvariable.ITINTA, 
      matHandlingCode1: this.anyvariable.ITMHC1, 
      matHandlingCode2: this.anyvariable.ITMHC2, 
      matHandlingCode3: this.anyvariable.ITMHC3, 
      warehouseZone: this.anyvariable.ITABC, 
      bmtCode:this.anyvariable.ITBMT, 
      palletsHigh:this.anyvariable.ITPALH, 
      palletsDeep: this.anyvariable.ITPADP, 
      prefferedLocation:'', 
      lowStockPoint:this.anyvariable.ITMRPT, 

      PickLine:'', // no data in RPG
    });
  }

  itemUpdate3(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemServiceUpdate3( 
       res.storeNumber,
      this.anyvariable.ITSSFX,
      this.anyvariable.ITEM,
        res.domesticSurface,
        res.domesticAir, 
        res.internationalSurface,
        res.internationalAir, 
        res.matHandlingCode1, 
        res.matHandlingCode2, 
        res.matHandlingCode3, 
        res.warehouseZone, 
        res.bmtCode, 
        res.palletsHigh,
        res. palletsDeep,
        res.lowStockPoint,
        res. prefferedLocation,
        res.PickLine
       
       

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       console.log(this.result[0].data.length);
          console.log("*********************");

       //console.log(this.result[0].data[23].value);
       console.log("*********************");

       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Created/Updated'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this. update4();
          this.router.navigate(['/main-nav/item/read']);
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
    this.router.navigate(['/main-nav/item/read']);
  }


  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  update4() {
    var res = this.anyvariable;
    this.matDialog.open(ItemUpdate4Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

  
}
