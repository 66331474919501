import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IitemService } from 'src/app/features/item/iitem.service';
import { IheaderService } from 'src/app/features/tallys/iheader.service';
import Swal from 'sweetalert2';
import { OrderDetailsService } from '../order-details.service';

@Component({
  selector: 'app-order-change-loc-lot',
  templateUrl: './order-change-loc-lot.component.html',
  styleUrls: ['./order-change-loc-lot.component.css']
})
export class OrderChangeLocLotComponent implements OnInit {

  constructor(
    public iHeaderSvc: IheaderService, 
    public orderDetailSrv : OrderDetailsService,
    public itemService : IitemService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<OrderChangeLocLotComponent>
   ) 
   { }

   storer: any;
   form!: FormGroup;
   result: any;
   user = localStorage.getItem('userName');
   duplicateSource: any;
   itemOptions: string[] = [];
   bol:any; 
   item: any; 
   seq:any;
   quantity:any;
   itemDsc1:any;
   building:any;

  ngOnInit(): void {
    this.bol=this.anyvariable.ODBILL;
    this.quantity= this.anyvariable.ODQTYO + 'L (' + this.anyvariable.ODQTYS + ')'; 
    this.seq = this.anyvariable.ODSEQ;

    this.itemService.getItemBySearch(this.user, this.anyvariable.ODSTOR,'', this.anyvariable.ODITEM
    ).subscribe((data) => {
      this.result = data;
      var res = this.result[0].data[1]?.value;
      var parsedData = JSON.parse(res);
      this.duplicateSource = parsedData.returnedData;
      console.log('SINGLE ITEM DATA');
      console.log(this.duplicateSource);
      this.itemDsc1 = this.duplicateSource[0].ITDSC1;
      this.building = this.duplicateSource[0].ITBLDG;
      this.item= this.anyvariable.ODITEM +'  '+ this.itemDsc1;
    });

    this.form = this.fb.group({      
      aislSlot:this.anyvariable.ODAISL +'/'+this.anyvariable.ODSLOT,
      lot1:this.anyvariable.ODLOT1,
      lot2:this.anyvariable.ODLOT2,
      lot3:this.anyvariable.ODLOT3         
    });
  }

  updateOrderDetailLoc() {
    var res = this.form.value;
    this.result = this.orderDetailSrv.updateOrderDetailLocService(
      this.bol,
      this.seq,
      res.aislSlot,
      res.lot1,
      res.lot2,
      res.lot3
    ).subscribe((data: any) => {
      this.result = data;
      var length = this.result[0].data.length;
      console.log(this.result);
      
      if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Order Detail Location Changed'){
        const msg= this.result[0].data[length-1].value;
        this.successMessage(msg)
        this.closeDialog();
      }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
      }
    });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/details']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }  

  closeDialog(){
   this.dialogRef.close();
  }

  backstorer(){
    
  }
  location(){
    
  }

}
