
<mat-card><button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>
<mat-card-header>
<mat-card-title>


Labor Plan Event Inquiry</mat-card-title>
</mat-card-header>
<mat-card-content>
<div class="formDiv">
<form [formGroup]="form">
    <div class="row">
        <mat-form-field appearance="outline">
            <mat-label>Document</mat-label>
            <input matInput formControlName="Document" />
        </mat-form-field>   
    </div>
</form>

<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="Sequence">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Sequence
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Sequence }}</td>
    </ng-container>

    <ng-container matColumnDef="Comment">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Comment
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Comment }}</td>
    </ng-container>

    <ng-container matColumnDef="Date">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Date
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Date }}</td>
    </ng-container>

    <ng-container matColumnDef="Time">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Time
        </th>
        <td mat-cell *matCellDef="let element">{{ element.Time }}</td>
    </ng-container>

    <ng-container matColumnDef="User">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            User
        </th>
        <td mat-cell *matCellDef="let element">{{ element.User }}</td>
    </ng-container>
    <!-- <ng-container matColumnDef="Opt">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Opt
        </th>
        <td mat-cell *matCellDef="let element">
            <mat-select placeholder="Select Option">
            </mat-select>
        </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>


</table>