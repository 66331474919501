import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-data-element',
  templateUrl: './delete-data-element.component.html',
  styleUrls: ['./delete-data-element.component.css'],
})
export class DeleteDataElementComponent implements OnInit {
  form!: FormGroup;
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteDataElementComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      dataElementCode: 400,
      dataElementDefinition: 'Labor Planning',
    });
  }
  deleteDataElement() {
    alert('Deleted');
  }
  closeDialog() {
    this.dialogRef.close();
  }
}
