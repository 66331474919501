import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../iheader.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


interface CustomColumn {
  possition: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-tally-inquiry',
  templateUrl: './tally-inquiry.component.html',
  styleUrls: ['./tally-inquiry.component.css'],
})

export class TallyInquiryComponent implements OnInit {
  

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<TallyInquiryComponent>
  ) {}

  displayedColumns: string[] = [
    'Qty', 
    'Item', 
    'Description', 
    'LotCodes', 
    'Weight', 
    'Rate', 
    'Base', 
    'Amount',
    'HSM'
  ];

  @ViewChild('paginator') paginator!: MatPaginator;
  public columnShowHideList: CustomColumn[] = [];
  form!: FormGroup;
  user= localStorage.getItem('userName');
  selectedRow: any;
  TallyHeaders: any;
  duplicateSource: any;
  dataSource: any;
  result: any;

  tally: any;
  accountOf: any;
  billTo: any;
  carrier: any;
  proNumber: any;
  referenceNo: any;
  carTrailer: any;

  invoiceNo: any;
  invoiceDate: any;
  receivedDate: any;
  scheduledDate: any;

  buildingNo: any;
  loadType: any;
  receiptType: any;
  seal1Or2Broken: any;

  totalHandling: any;
  storage: any;
  special: any;
  wrTotal: any;

  ngOnInit(): void {

    this.tally = this.anyvariable.IHTALY;

    this.form = this.fb.group({
      tally:''
    });

      this.iHeaderSvc
        .readTallyInquiryService(this.user, this.anyvariable.IHTALY)
        .subscribe((data) => {
          this.result = data;
          this.TallyHeaders = data;
          var res = this.result[0].data[1]?.value;
          var parsedData = JSON.parse(res);
          this.duplicateSource = parsedData.returnedData;
          console.log('TALLY INQUIRY DATA');
          console.log(this.duplicateSource);
          this.accountOf = this.duplicateSource[0].IHSTOR;
          this.billTo = this.duplicateSource[0].IHCUST;
          this.carrier = this.duplicateSource[0].CRNAME;
          this.proNumber = this.duplicateSource[0].IHPRO;
          this.referenceNo = this.duplicateSource[0].IHSTRF;
          this.invoiceNo = this.duplicateSource[0].IHINVO;
          this.invoiceDate = this.duplicateSource[0].IHINDT;
          this.receivedDate = this.duplicateSource[0].IHACTA;
          this.scheduledDate = this.duplicateSource[0].IHSCHA;
          this.buildingNo = this.duplicateSource[0].IHBLDG;
          this.loadType = this.duplicateSource[0].ILDTYP;
          this.receiptType = this.duplicateSource[0].IRCTYP;
          this.seal1Or2Broken = this.duplicateSource[0].IHBRK1 + ' ' 
                                + this.duplicateSource[0].IHBRK2;

          this.totalHandling = this.duplicateSource[0].ITOTHD;
          this.storage = this.duplicateSource[0].ITOTST;
          this.special= this.duplicateSource[0].ITOTSP;
          this.wrTotal = this.duplicateSource[0].IGRTOT;

          this.dataSource = new MatTableDataSource(this.duplicateSource);
          this.dataSource.paginator = this.paginator;
        });

  }

  

  onBack() {
    this.router.navigate(['tallys']);
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
