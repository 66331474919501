import { GlobalComponent } from './../../../Global-Component';
import { IbuildingsService } from './../ibuildings.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { CustomAlertService } from '../../custom-alert/custom-alert.service';

@Component({
  selector: 'app-delete-building',
  templateUrl: './delete-building.component.html',
  styleUrls: ['./delete-building.component.css'],
})
export class DeleteBuildingComponent implements OnInit {
  building: any;
  form!: FormGroup;
  constructor(
    private router: Router,
    public ibuildingSvc: IbuildingsService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteBuildingComponent>,
    private CustomAlertService : CustomAlertService
  ) {}
  result: any;
  ngOnInit(): void {
    this.building = this.anyvariable.BUILD;
    this.form = this.fb.group({
      buildingNumber: this.anyvariable.BUILD,
      buildingName: this.anyvariable.BUNAME,
      address: this.anyvariable.BUADD1,
    });
  }

  user = localStorage.getItem('userName');
  deleteBuilding() {
    var res = this.form.value;
    for (var i = res.buildingNumber.length; i < 3; i++) {
      res.buildingNumber = ' ' + res.buildingNumber;
    }
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete building no: ? '+this.anyvariable.BUILD);
  
    dialogRef.afterClosed().subscribe((result: any) => {
    if (result) {
      this.result = this.ibuildingSvc
        .deleteBuilding(this.user, res.buildingNumber)
        .subscribe((data: any) => {
          this.result = data;
          //  var msg = JSON.parse(this.result).returnedData.message;
          console.log(this.result);

          if (JSON.parse(this.result[0].data[1].value).success) {
            //alert('Record Created SuccessFully');
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.successMessage(msg);
            this.closeDialog();
            this.router.navigate(['/main-nav/building/read']);
          } else {
            const msg = JSON.parse(this.result[0].data[1].value).returnedData
              .message;
            this.failureMessage(msg);
            this.closeDialog();
            this.router.navigate(['/main-nav/building/read']);
          }

          // this.successMessage(msg);
        });
    }
  });
}
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    // this.router.navigate(['/main-nav/tallys/read']);
  }
  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
