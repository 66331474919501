<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
          <button mat-button (click)="backstorer()">
              <mat-icon></mat-icon>
              
          </button>
          <mat-card-title style="color: rgb(7, 8, 10)"
                    > Maintain Metric Defination</mat-card-title>
                  
        </mat-card-header>
        <mat-card-content> 
                        
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Metric Name </mat-label> 
                    <input matInput formControlName="METRIC" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">
                    <mat-label>Metric Description</mat-label>
                    <textarea matInput formControlName="DESCRIPT"></textarea> 
                  </mat-form-field>
                  <mat-form-field appearance="outline">  <mat-label>Metric Calculator</mat-label> 
                    <input matInput formControlName="PROGRAM" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">  <mat-label>Metric Type</mat-label> 
                    <input matInput formControlName="TYPE" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">  <mat-label>Freshness Period</mat-label> 
                    <input matInput formControlName="FRSHPERD" />
                  </mat-form-field>
                  <mat-form-field appearance="outline">  <mat-label>Status</mat-label> 
                    <input matInput formControlName="STATUS" />
                  </mat-form-field>
           </div>
                              <div >
                                <button mat-button class="buttonCss" (click)="attributes()">Add</button>
                            </div>
      
        </mat-card-content>
    </mat-card>
   </form>  



