<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Generate Invoice Detail Maintenance</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
    <div class="row">
        <div class="row">
            <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>Task Id</mat-label>
                <input matInput formControlName="DGTASK" />
            </mat-form-field>
        </div>
        <div class="col">
            <mat-form-field appearance="outline">
                <mat-label>Ledger Codes</mat-label>
                <input matInput formControlName="DGLEGC" />
            </mat-form-field>
        </div>
       </div>
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Quantity</mat-label>
                    <input  matInput formControlName="DGQTY" />
                </mat-form-field>
            </div>
            <div class="col">
           
                <mat-form-field appearance="outline">
                    <mat-label>Rate</mat-label>
                    <input matInput formControlName="DGRATE"  />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Basis</mat-label>
                    <input matInput formControlName="DGBASE"  />
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Amount</mat-label>
                    <input  matInput formControlName="DGAMT" />
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Minimum Applied</mat-label>
                    <mat-select  formControlName="DGMINM">
                        <mat-option value="Y">Yes</mat-option>
                        <mat-option value="N">No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Description</mat-label>
                    <textarea matInput formControlName="DGDESC"></textarea>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Document Type</mat-label>
                    <input  matInput formControlName="DGDTYP" />
                </mat-form-field>

            </div>
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Document No</mat-label>
                    <input  matInput formControlName="DGDOCN" />
                </mat-form-field>
                
                
            </div>
        </div>
    </div>  

    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="change()" class="addButton">           
        <mat-icon mat-icon-button color="primary">add</mat-icon>
        <mat-label>General Invoice DEtail</mat-label>
    </button>
</mat-card-footer>

