<button mat-icon-button (click)="closeDialog()">
    <mat-icon mat-icon-button color="primary">close</mat-icon>
  </button>
  <form [formGroup]="form">
    <mat-card>
      <mat-card-header>
        <mat-card-title> Delete Operator Equipment </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline"
              ><mat-label> Building </mat-label>
              <input matInput formControlName="building" />
            </mat-form-field>
          
            <mat-form-field appearance="outline">
              <mat-label> Equipment </mat-label>
  
              <input matInput formControlName="equipment" />
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label> Maximum Capacity </mat-label>
    
                <input matInput formControlName="maximumCapacity" />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Lp or Electric </mat-label>
                  <mat-select  formControlName="lpOrElectric">
                   
                    <mat-option value="L">L = Lp </mat-option>
                    <mat-option value="E">E = Electric </mat-option>
                   
                  </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> Own or Leased </mat-label>
    
                <input matInput formControlName="ownOrLease" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> Shifr or Day pm </mat-label>
    
                <input matInput formControlName="shiftorDayPm" />
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Trailer Capable </mat-label>
                  <mat-select  formControlName="trailerCpable">
                   
                    <mat-option value="Y">YES</mat-option>
                    <mat-option value="N">NO</mat-option>
                   
                  </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Fright Type</mat-label>
                  <mat-select  formControlName="frightType">
                   
                    <mat-option value="P">Pallatized</mat-option>
                    <mat-option value="C">Clamp</mat-option>
                   
                  </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>Raised Forks </mat-label>
                  <mat-select  formControlName="raisedForks">
                   
                    <mat-option value="Y">YES</mat-option>
                    <mat-option value="N">NO</mat-option>
                   
                  </mat-select>
              </mat-form-field>

              
              <mat-form-field appearance="outline">
                <mat-label> Status </mat-label>
    
                <input matInput formControlName="status" />
              </mat-form-field>
           
              <button
              mat-button
              class="buttons removeButton"
              mat-raised-button
              (click)="delete()"
            >
              Delete
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </form>
  


