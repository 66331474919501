import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-general-invoice',
  templateUrl: './edit-general-invoice.component.html',
  styleUrls: ['./edit-general-invoice.component.css'],
})
export class EditGeneralInvoiceComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<EditGeneralInvoiceComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      Customer: [null],
      Building: [null],
      BillToCust: [null],
      InvoiceDate: [null],
      Type: [null],
      Amount: [null],
      ReferenceNo: [null],
      CarTrlrNo: [null],
      Description: [null],
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  update() {
    console.log('updated');
  }
}
