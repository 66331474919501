import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-contacts-consignee',
  templateUrl: './contacts-consignee.component.html',
  styleUrls: ['./contacts-consignee.component.css'],
})
export class ContactsConsigneeComponent implements OnInit {
  displayedColumns: string[] = ['Type', 'ContactName', 'Telephone', 'Status'];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      Type: '',
      ContactName: '',
      Telephone: '',
      Status: 'AC',
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ContactsConsigneeComponent>
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      ContactType: [null],
      Name: [null],
      Status: 'AC',
      ConsigneeCode: 'RAT',
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  @ViewChild('paginator') paginator!: MatPaginator;
  workHeaders: any = [];

  reasonCode() {
    console.log('Reason Code');
  }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
