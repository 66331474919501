<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title 
                    >maintain Consignee Triggers</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Consignee Code: </mat-label> 
                          <input matInput formControlName="consigneeCode" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label>Document Type/No: </mat-label> 
                          <input matInput formControlName="documentTypeNo" />
                        </mat-form-field>
                  </div> 

                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Generated: </mat-label> 
                      <input matInput formControlName="generated" /> 
                    </mat-form-field>
                    </div>
                 
             
                                <div class="col">
                                    <mat-form-field appearance="outline">  <mat-label> Status  </mat-label> 
                                        <mat-select matNativeControl required>
                                        <mat-option value="Item">AC</mat-option>
                                   
                                        </mat-select> 
                                    </mat-form-field>
                                 </div>
                              
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Processed: </mat-label> 
                      <input matInput formControlName="Processed" /> 
                    </mat-form-field>
                    </div>
                 
                                  
                            <div >
                              <button mat-button class="buttonCss" (click)="attributes()">CHANGE</button>
                          </div>
                        
                </mat-card-content>
              </mat-card>
            </form>  
