<mat-card class="over-Flow">
  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Two-Sided Inventory Adjustment</mat-card-title>
        &nbsp;
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>
      <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">

          <mat-form-field appearance="outline">
            <mat-label> Storer </mat-label>
            <input readonly matInput value="{{fullStorer}}" />
          </mat-form-field>
          
          <hr>
          <div style="margin-left:20px">
            <b><u>From:</u></b>
          </div>

          <mat-form-field appearance="outline">
            <mat-label> Item</mat-label>
            <input readonly matInput value="{{fullItem}}" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Lots</mat-label>
            <input readonly matInput formControlName="lots" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Location</mat-label>
            <input readonly matInput formControlName="location" />
          </mat-form-field>
       
        <hr>
       
          <mat-form-field appearance="outline">
            <mat-label>Quantity</mat-label>
            <input  required matInput formControlName="adjQuantity" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>From Bucket</mat-label>
            <input required matInput formControlName="inventoryAdjBucket" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Refer To Bol/WR</mat-label>
            <input matInput formControlName="referToBill" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Reason</mat-label>
            <input required matInput formControlName="reasonCode" />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Available</mat-label>
            <input readonly matInput formControlName="available" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Committed</mat-label>
            <input readonly matInput formControlName="committed" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Damaged</mat-label>
            <input readonly matInput formControlName="damaged" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Held</mat-label>
            <input readonly matInput formControlName="held" />
          </mat-form-field>  
          <mat-form-field appearance="outline">
            <mat-label>user1</mat-label>
            <input readonly matInput formControlName="user1" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>user2</mat-label>
            <input readonly matInput formControlName="user2" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>user3</mat-label>
            <input readonly  matInput formControlName="user3" />
          </mat-form-field> 

          <mat-form-field appearance="outline">
            <mat-label>user4</mat-label>
            <input readonly matInput formControlName="user4" />
          </mat-form-field>  
                    
          <hr>
          <div style="margin-left:20px">
            <b><u>To:</u></b> 
          </div>
          <mat-form-field appearance="outline">
            <mat-label> Item</mat-label>
            <input matInput formControlName="toItem" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> To Bucket</mat-label>
            <input matInput formControlName="toBucket" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot 1: </mat-label>
            <input matInput formControlName="toLot1" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot 2:</mat-label>
            <input matInput formControlName="toLot2" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Lot 3:</mat-label>
            <input matInput formControlName="toLot3" />
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label>Sort Seq</mat-label>
            <input required matInput formControlName="toSortSeq" />
          </mat-form-field>
        </div>
      </div>
      <div>


          

          <button mat-button class="addButton buttons" (click)="twoSideAdj()" >

            <span>Adjust</span>
          </button>
      </div>
    
</form>    
</mat-card-content>

</mat-card>
