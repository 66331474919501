import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { AddMessageClassColumnsComponent } from './add-message-class-columns/add-message-class-columns.component';
import { ChangeMessageClassColumnsComponent } from './change-message-class-columns/change-message-class-columns.component';

@Component({
  selector: 'app-ww-message-class-columns',
  templateUrl: './ww-message-class-columns.component.html',
  styleUrls: ['./ww-message-class-columns.component.css']
})
export class WwMessageClassColumnsComponent implements OnInit {

  form!: FormGroup;
  element: any

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'FIELD',
    'DTYPE',
    'XPATH',
    'ALLOWNULL',
    'Actions' 
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      FIELD:'@APDT',
      DTYPE:'CHAR(10)',
      XPATH:'@APDT',
      ALLOWNULL:'Y'

    },
    {
      FIELD:'@APTM',
      DTYPE:'CHAR(4)',
      XPATH:'@APTM',
      ALLOWNULL:'Y'
    }
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.element= history.state

    console.log(this.element);

    this.form= this.fb.group({
      DOCUMENT:'',
      TABLE:this.element.FILE
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }


  onBack() {
    this.router.navigate(['/main-nav/messageClassTables/read']);
  }


  add(){
    this.matDialog.open(AddMessageClassColumnsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false,

    });
  }

  change(element:any){
    this.matDialog.open(ChangeMessageClassColumnsComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "data":element,
      "autoFocus": false
    });
  }


}
