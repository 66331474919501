<button mat-icon-button (click)="closeDialog()">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
  <mat-card>
    <mat-card-header>
   <mat-card-title style="color: rgb(203, 207, 219)" >
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Work With Document Extra Data</mat-card-title>
    </mat-card-header>
    <mat-card-content>

    <form [formGroup]="form">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Document Type</mat-label>
                    <input matInput formControlName="EDDOCT"/>
                </mat-form-field>
                <mat-form-field appearance="outline">
                 <mat-label>Document Number</mat-label>
                 <input matInput formControlName="DOCN"/>
             </mat-form-field>
                       
            </div>           
            
          
     </div>     
    </form>
    </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Extra Data Information">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Extra Data Information
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.EDDESC}}</td>
          </ng-container>
          <ng-container matColumnDef="Actions">
            <th  mat-header-cell mat-header-cell *matHeaderCellDef>
              Actions
            </th>
            <td mat-cell *matCellDef="let element">
              <mat-select  placeholder="Select Option"> 
                
              </mat-select>
            </td>
          </ng-container>        
          
         
 
          
 
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
        #paginator
        [pageSizeOptions]="[4, 8, 16]"
        showFirstLastButtons
      ></mat-paginator>
