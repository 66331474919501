import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { LoginService } from 'src/app/login/login.service';
import Swal from 'sweetalert2';
import { IheaderService } from '../tallys/iheader.service';


@Component({
  selector: 'app-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.css']
})
export class AccountSelectionComponent implements OnInit {
  userData:any;
  accountSelelctionForm:any
  storerAndBuilding:any;

  constructor(
    private router: Router,
    public loginSvc: LoginService,
    public loginSvc2:IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) {}

  // accountSelelctionForm = this.fb.group({
  //   building: [null, Validators.required]
  // });
  
accountSelection: any = '';
  userName = GlobalComponent.user;
  result: any;
  element: any;
  
  
  ngOnInit(): void {

    this.accountSelelctionForm = this.fb.group({
      building: [null, Validators.required]
    });
    
    this.userData= history.state;
   // console.log(this.userData,'*********');
    console.log(this.userData.user,'*********');

    
    
    this.accountSelection = this.loginSvc2
      .getAccountSelection(this.userData.user)
      .subscribe((data: any) => {
        this.result = data;
        var res = JSON.parse(this.result[0].data[1].value);
        this.element = res.returnedData;
        console.log(this.element);
        console.log('Account selection Data');
      });
  }

  submit() {
    //alert('success');
    console.log(this.accountSelelctionForm.get('building').value,'Building Value');
    this.storerAndBuilding = this.accountSelelctionForm.get('building').value;

    localStorage.setItem('building', this.storerAndBuilding.buildingNumber);
    localStorage.setItem('storer', this.storerAndBuilding.storerNumber);
    localStorage.setItem('storerSuffix', this.storerAndBuilding.storerSuffix);
    localStorage.setItem('name', this.storerAndBuilding.name);
    this.loginSvc
      .deleteAccountUser(this.storerAndBuilding.userName)
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
        console.log('Account Delete Result');
      });
    
      this.loginSvc
      .createAccountUser(
        this.userData.user,
        this.storerAndBuilding.name,
        this.storerAndBuilding.storerNumber,
        this.storerAndBuilding.storerSuffix,
        this.storerAndBuilding.buildingNumber,
        this.storerAndBuilding.status
      )
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
        console.log('Account Create Result');

        if(JSON.parse(this.result[0].data[1].value).success){
          this.successMessage(JSON.parse(this.result[0].data[1].value).returnedData.message);
          this.router.navigateByUrl('/dashboard');
        }else{
          this.failureMessage(JSON.parse(this.result[0].data[1].value).returnedData.message);
        }

      });


    
  }

  successMessage(msg:any){
   // Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
  
}


