<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Update Extract Report Filter</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Filter Name</mat-label>
                <input matInput formControlName="FilterName" />
            </mat-form-field>   

            <mat-form-field appearance="outline">
                <mat-label>Program</mat-label>
                <input matInput formControlName="Program" />
            </mat-form-field> 
            
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="Description"></textarea>
            </mat-form-field>  
        </div>

    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="updateExtractReport()" class="addButton">           
        <mat-icon mat-icon-button color="primary">edit</mat-icon>
        <mat-label> Extract Report Filter</mat-label>
    </button>
</mat-card-footer>


