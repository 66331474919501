import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IMapLocationService } from '../i-map-location.service';
import { GlobalComponent } from 'src/app/Global-Component';

@Component({
  selector: 'app-map-location-create',
  templateUrl: './map-location-create.component.html',
  styleUrls: ['./map-location-create.component.css'],
})
export class MapLocationCreateComponent implements OnInit {
  storer: any;
  //form!: FormGroup;
  result: any;
  constructor(
    public mapLocationSrv: IMapLocationService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router: Router,
    private dialogRef: MatDialogRef<MapLocationCreateComponent>
  ) {}

  form = this.fb.group({
    BuildingNumber: [
      '',
      [
        
        Validators.min(0),
        Validators.pattern('[0-9]{1,10}'),
      ],
    ],
    Floor: '',
    Aisle: '',
    Slot: '',
    PalletsHigh: '',
    Deep: '',
    SlotHeight: '',
    Width: '',
    Depth: '',
    PickingSequence: '',
    WarehouseZone: ['', [Validators.required]],
    ReservedSlot: false,

    ReservedStorerNumber: '',
    ReservedStorerSuffix: '',
    ReservedItemcode: '',
  });

  user = localStorage.getItem('userName');

  ngOnInit(): void {}

  addMapLocation() {
    var res = this.form.value;
    console.log(res);
    console.log(GlobalComponent.fullBuilding(res.BuildingNumber));
    this.result = this.mapLocationSrv
      .createMapLocation(
        GlobalComponent.fullBuilding(res.BuildingNumber),
        res.Floor,
        GlobalComponent.fullSlotAisl(res.Aisle),
        GlobalComponent.fullSlotAisl(res.Slot),
        res.PalletsHigh,
        res.Deep,
        res.SlotHeight,
        res.Width,
        res.Depth,
        res.PickingSequence,
        res.WarehouseZone,
        res.ReservedSlot,
        res.ReservedStorerNumber,
        res.ReservedStorerSuffix,
        res.ReservedItemcode,
        this.user
      )
      .subscribe((data: any) => {
        this.result = data;
        
        if (JSON.parse(this.result[0].data[1].value).sucess) {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/map-location/read']);
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.failureMessage(msg);
        }
      });
  }
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.router.navigate(['/main-nav/map-location/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  maplocationscreate() {
    
  }

  backstorer() {

  }
  onBack() {
    this.router.navigate(['/main-nav/map-location/read']);
  }
}
