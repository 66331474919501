<button mat-icon-button (click)="closeDialog()" class="closeIcon">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
  <mat-card-title>Work With LTL Batch Details</mat-card-title>
</mat-card-header>

<mat-card-content>
  <form [formGroup]="form" fxLayoutAlign="center">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Batch Number : </mat-label>
          <input matInput formControlName="BatchNumber" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Customer : </mat-label>
          <input matInput formControlName="Customer" />
        </mat-form-field>
      </div>
    </div>
  </form>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="Shipment Number">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Shipment Number</th>
      <td mat-cell *matCellDef="let element">{{ element.ShipmentNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="Customer">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Customer</th>
      <td mat-cell *matCellDef="let element">{{ element.Customer }}</td>
    </ng-container>

    <ng-container matColumnDef="Scheduled Ship Date">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>
        Scheduled Ship Date
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.ScheduledShipDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Must Del By Date">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>MustDelByDate</th>
      <td mat-cell *matCellDef="let element">{{ element.MustDelByDate }}</td>
    </ng-container>

    <ng-container matColumnDef="AP">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>AP</th>
      <td mat-cell *matCellDef="let element">{{ element.AP }}</td>
    </ng-container>

    <ng-container matColumnDef="Weight">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Weight</th>
      <td mat-cell *matCellDef="let element">{{ element.Weight }}</td>
    </ng-container>

    <ng-container matColumnDef="City">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>City</th>
      <td mat-cell *matCellDef="let element">{{ element.City }}</td>
    </ng-container>

    <ng-container matColumnDef="St">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>ST</th>
      <td mat-cell *matCellDef="let element">{{ element.ST }}</td>
    </ng-container>

    <ng-container matColumnDef="Action">
      <th mat-header-cell mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <mat-select placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="delete()">
              <mat-label>Delete from Batch</mat-label>
            </button>
          </mat-option>
          <mat-option>
            <button mat-icon-button (click)="synopsis(element)">
              <mat-label>Synopsis</mat-label>
            </button>
          </mat-option>
        </mat-select>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card-content>
