<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Orders Delete </mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col"> 
        <mat-form-field appearance="outline"
          ><mat-label> Bill of Lading</mat-label>
          <input readonly matInput formControlName="Billoflanding" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Storer </mat-label>

          <input readonly matInput formControlName="Storer" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Consignee</mat-label>

          <input readonly matInput formControlName="Consignee" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Scheduled Ship Date </mat-label>

          <input readonly matInput formControlName="ScheduledShipDate" />
        </mat-form-field>
      </div>
    </div>
    <div id="custom-alert">
      <div class="custom-alert-content">
        <button mat-button class="buttons removeButton" 
        (click)="deleteOrder()">Delete</button>

      </div>
    </div>
    
      <!-- <button mat-button class="buttons removeButton"(click)="deleteOrder()">Delete</button> -->
    
  
  </form>
</mat-card-content>
</mat-card>

      
