import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { ExtractReportWhereclausesAddComponent } from './extract-report-whereclauses-add/extract-report-whereclauses-add.component';
import { ExtractReportWhereclausesDeleteComponent } from './extract-report-whereclauses-delete/extract-report-whereclauses-delete.component';
import { ExtractReportWhereclausesUpdateComponent } from './extract-report-whereclauses-update/extract-report-whereclauses-update.component';

@Component({
  selector: 'app-extract-report-whereclauses',
  templateUrl: './extract-report-whereclauses.component.html',
  styleUrls: ['./extract-report-whereclauses.component.css']
})
export class ExtractReportWhereclausesComponent {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'ExtractName',
    'FileName',
    'WhereClause',
    'Actions'
  ];

  dataSource:any=[]

  duplicateSource:any =[
    {
      ExtractName:'ACTIVITY',
      FileName:'SACTWF',
      WhereClause:'SASTOR = {STORER}'
      
    },
    {
      ExtractName:'ACTIVITYDR',
      FileName:'SACTWF',
      WhereClause:'SASTOR = {STORER}'
      
    },

  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
    ExtractName:'',
    ExtractFile: '',
    WhereClause: ''

    })

    this.dataSource = this.duplicateSource;
  }

  addExtractReportWhere()
  {
   this.matDialog.open(ExtractReportWhereclausesAddComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
  UpdateExtractReportWhere()
  {
   this.matDialog.open(ExtractReportWhereclausesUpdateComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }

  deleteExtractReportWhere()
  {
   this.matDialog.open(ExtractReportWhereclausesDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      "autoFocus": false
    });
  }
}
