import { AddDetailsGroupComponent } from './add-details-group/add-details-group.component';
import { EditDetailsGroupComponent } from './edit-details-group/edit-details-group.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-details-group',
  templateUrl: './details-group.component.html',
  styleUrls: ['./details-group.component.css'],
})
export class DetailsGroupComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'GroupName',
    'Operator',
    'GroupUserPriority',
    'Status',
    'Actions',
  ];
  dataSource: any = [];

  duplicateSource: any = [
    {
      GroupName: 'TEST GROUP',
      Operator: 'ISHWARYAP',
      GroupUserPriority: '1',
      Status: '',
    },
    {
      GroupName: ' GROUP',
      Operator: 'NKOHAN',
      GroupUserPriority: '',
      Status: '',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private matDialog: MatDialog,

    private dialogRef: MatDialogRef<DetailsGroupComponent>
  ) {}

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      GroupName: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  addGroup() {
    this.matDialog.open(AddDetailsGroupComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }

  updateGroup() {
    this.matDialog.open(EditDetailsGroupComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  delete() {
    alert('deleted');
  }
}
