import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-inbound-details-delete',
  templateUrl: './ww-inbound-details-delete.component.html',
  styleUrls: ['./ww-inbound-details-delete.component.css']
})
export class WwInboundDetailsDeleteComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = 'NKOHAN';
  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<WwInboundDetailsDeleteComponent>
   ) 
   { }

  ngOnInit(): void {
    this.form = this.fb.group({
      IDTALY:'1157813',
      IDSEQ:'100',
      IDSTOR:'100',
      IDITEM:'AA116662-9691',
      IDLOT:'',
      IDQREC:'0',
      IDQINT:'1000',
      
      
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}

