import { WwSerialNumberComponent } from './ww-serial-number/ww-serial-number.component';
import { DeleteSerialNumberComponent } from './delete-serial-number/delete-serial-number.component';
import { EditSerialNumberComponent } from './edit-serial-number/edit-serial-number.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AddSerialNumberComponent } from './add-serial-number/add-serial-number.component';

@Component({
  selector: 'app-serial-numbers',
  templateUrl: './serial-numbers.component.html',
  styleUrls: ['./serial-numbers.component.css'],
})
export class SerialNumbersComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Org',
    'New',
    'ItemNumber',
    'QtytoShip',
    'QtytoScan',
    'QuantityScanned',
    'Req',
    'Qty',
    'Stat',
    'Actions',
  ];
  dataSource: any = [];

  duplicateSource: any = [
    {
      Org: 1,
      New: 5,
      ItemNumber: 1234,
      QtytoShip: 30,
      QtytoScan: 28,
      QuantityScanned: 2,
      Req: 'Yes',
      Qty: 1,
      Stat: 'CM',
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      ODBILL: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  wwSerialNumber() {
    this.matDialog.open(WwSerialNumberComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
}
