import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-metricsconstraints-delete',
  templateUrl: './metricsconstraints-delete.component.html',
  styleUrls: ['./metricsconstraints-delete.component.css']
})
export class MetricsconstraintsDeleteComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user = 'NKOHAN';

  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MetricsconstraintsDeleteComponent>
   ) 
  { }

  ngOnInit(): void {
    this.form = this.fb.group({
      CNSTRSET:'BLDG012',
      DESCRIPT:'Building 12 Constraint set',
      STATUS:'AC'
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}


