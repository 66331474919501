<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card class="over-Flow">
    <mat-card-header>  
      <mat-card-title style="color: rgb(203, 207, 219)" >
  
        Resequence Item Locations</mat-card-title>
    </mat-card-header>
    
     <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="Sequence Number">
        <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Sequence Number
        </th> 
        <td mat-cell *matCellDef="let element">{{ element.LCSQT }}</td>
      </ng-container>

      <ng-container matColumnDef="Location">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
              Location
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.LCBLDG }}</td>
        </ng-container>

        <ng-container matColumnDef="Asile">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Item
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LCASIL }}</td>
          </ng-container>
        
          
        <ng-container matColumnDef="Slot">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Slot
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LCSLOT }}</td>
          </ng-container>

          <ng-container matColumnDef="Lot">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                Lot
            </th> 
            <td mat-cell *matCellDef="let element">{{ element. LCLTC1 }}</td>
          </ng-container>
          <ng-container matColumnDef="First Received">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                First Received
            </th> 
            <td mat-cell *matCellDef="let element">{{ element. LCRCVD }}</td>
          </ng-container>

          <ng-container matColumnDef="On hand">
            <th mat-header-cell mat-header-cell *matHeaderCellDef>
                On hand
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.ONHND }}</td>
          
        </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>

      
    </table>
    <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>
                 

</mat-card>

