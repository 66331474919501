import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class IexitPointsService {

  url = environment.API_BASE_URL;
  
  constructor(private httpClient: HttpClient) {}
  httpHeader = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  
  getExitPoint(user: any,storer:any,exitPoint:any,choice:any): Observable<any> {
    return this.httpClient.get(
      this.url + `/exitPoint/read?user=${user}&storer=${storer}&exitPoint=${exitPoint}&choice=${choice}`
    );
  }

  createExitPoint(
    storer:any, 
    storerSuffix:any, 
    exitPoint:any, 
    choice:any, 
    user: any
    ): Observable<any> {
    return this.httpClient
      .post<any>(this.url + '/exitpoint/create', { 

      storer, 
      storerSuffix, 
      exitPoint, 
      choice, 
      user
       })
      .pipe(retry(1), catchError(this.httpError));
  }
  
  updateExitPoint(
    storer:any, 
    storerSuffix:any, 
    exitPoint:any, 
    choice:any, 
    user: any
    ): Observable<any> {
    return this.httpClient
      .put<any>(this.url + '/exitpoint/update', { 

      storer, 
      storerSuffix, 
      exitPoint, 
      choice, 
      user
       })
      .pipe(retry(1), catchError(this.httpError));
  }
  
  deleteExitPoint(user: any, storer:any, storerSuffix:any,exitPoint:any, choice:any): Observable<any> {
    return this.httpClient.delete<any>(
      this.url + `/exitpoint/delete?user=${user}&storer=${storer}&storerSuffix=${storerSuffix}&exitPoint=${exitPoint}&choice=${choice}`
    );
  }
  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client side error
      msg = error.error.message;
    } else {
      // server side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(() => new Error(msg));
  }
}
