import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../tallys/iheader.service';
import { WwEmailFormatChangeComponent } from './ww-email-format-change/ww-email-format-change.component';
import { WwEmailFormatDeleteComponent } from './ww-email-format-delete/ww-email-format-delete.component';
import { WwEmailFormatInsertAfterComponent } from './ww-email-format-insert-after/ww-email-format-insert-after.component';
import { WwEmailFormatInsertBeforeComponent } from './ww-email-format-insert-before/ww-email-format-insert-before.component';
@Component({
  selector: 'app-work-with-email-format',
  templateUrl: './work-with-email-format.component.html',
  styleUrls: ['./work-with-email-format.component.css']
})
export class WorkWithEmailFormatComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Record',
    'Content',
    'Action'
  ];

  dataSource:any=[]
  duplicateSource:any =[
    {
      Record:'Title',
      Content:'Adjustment advice notice',
    },

    {
      Record:'Title',
      Content:'Adjustment advice notice',
    },

  ]
  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      
      DocumentName:''

    })

    this.dataSource = this.duplicateSource;
  }
  changeEmailFormat(element:any)
  {
   this.matDialog.open(WwEmailFormatChangeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      
      "data":element,
      "autoFocus": false
    });
  }
  deleteEmailFormat(element:any)
  {
   this.matDialog.open(WwEmailFormatDeleteComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      
      "data":element,
      "autoFocus": false
    });
  }
  insertBeforeEmailFormat(element:any)
  {
   this.matDialog.open(WwEmailFormatInsertBeforeComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      
      "data":element,
      "autoFocus": false
    });
  }
  insertAfterEmailFormat(element:any)
  {
   this.matDialog.open(WwEmailFormatInsertAfterComponent, {
      "width": '1000px',
      "maxHeight": '80vh',
      
      "data":element,
      "autoFocus": false
    });
  }
}
