<mat-card class="over-Flow">
 
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title> Maintain Item Locations </mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">           
      <mat-icon  mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col"> 
        <mat-form-field appearance="outline">
          <mat-label> Storer </mat-label>
          <input readonly matInput formControlName="storer" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label> Item</mat-label>
          <input readonly matInput formControlName="item" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Building Number</mat-label>
          <input readonly matInput formControlName="buildingnumber" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Floor</mat-label>
          <input required matInput formControlName="Floor" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Aisle</mat-label>
          <input required matInput formControlName="Aisle" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Slot</mat-label>

          <input required matInput formControlName="Slot" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label> Sort Sequence Number </mat-label>
          <input matInput maxlength="20" formControlName="sortseqNumber" />
          <mat-error *ngIf="form.controls['sortseqNumber'].hasError('length')">
            Only <strong>20 characters </strong> are allowed
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Lot Code 1</mat-label>
          <input required matInput formControlName="lotCode1" />
          <mat-error
            maxlength="20"
            *ngIf="form.controls['sortseqNumber'].hasError('length')"
          >
            Only <strong>20 characters </strong> are allowed
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Lot Code 2</mat-label>
          <input matInput formControlName="lotCode2" />
        </mat-form-field>
      
        <mat-form-field appearance="outline">
          <mat-label>Lot Code 3 </mat-label>
          <input matInput formControlName="lotCode3" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Lot Unit Weight </mat-label>
          <input required matInput formControlName="weight" />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label required>First Received Date </mat-label>

          <input
            required
            matInput
            formControlName="ReceivedDate"
            [matDatepicker]="DatePicker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="DatePicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #DatePicker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
    <div class="btn-text-right">
      <button mat-button class="button addButton"(click)="addItemLocation()">Add</button>
    </div>
  
  </form>
</mat-card-content>
</mat-card>

      