<mat-card class="over-Flow">

    <div class="main-header-title">
        <mat-card-header>
        <mat-card-title>Move History Inquiry</mat-card-title>
        </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>

    <mat-card-content class="tally-serach-form">
        <form [formGroup]="form">

            <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Document</mat-label>
                    <input matInput formControlName="Document" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Account</mat-label>
                    <input matInput formControlName="Account" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Sort</mat-label>
                    <input matInput formControlName="Sort" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>PendingMoves</mat-label>
                    <input matInput formControlName="PendingMoves" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>CompletedMoves</mat-label>
                    <input matInput formControlName="CompletedMoves" />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>TransactionType</mat-label>
                    <input matInput formControlName="TransactionType" />
                </mat-form-field>
            </div>
            </div>
        </form>
    </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="Move">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Move
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Move }}</td>
          </ng-container>

          <ng-container matColumnDef="Number">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Number
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Number }}</td>
          </ng-container>

          <ng-container matColumnDef="Item">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Item
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Item }}</td>
          </ng-container>

          <ng-container matColumnDef="Lot">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Lot
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Lot }}</td>
          </ng-container>

          <ng-container matColumnDef="Type">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Type
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Type }}</td>
          </ng-container>

          <ng-container matColumnDef="Sub">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Sub
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Sub }}</td>
          </ng-container>

          <ng-container matColumnDef="Quantity">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Quantity
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Quantity }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>

    </table>

    <mat-paginator #paginator [pageSizeOptions]="[1,2]" 
        showFirstLastButtons class="tally-table-pagination"
    ></mat-paginator>

</mat-card>