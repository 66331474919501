import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import { IheaderService } from '../../tallys/iheader.service';
import { StorerServiceService } from '../storer-service.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sroter-write-balance-record',
  templateUrl: './sroter-write-balance-record.component.html',
  styleUrls: ['./sroter-write-balance-record.component.css']
})
export class SroterWriteBalanceRecordComponent implements OnInit {

  storer: any;
  form!: FormGroup;
  result: any;
  user = GlobalComponent.user;
  
  constructor(private activeRouter:ActivatedRoute, 
    public storerSvc: StorerServiceService,
    @Inject(MAT_DIALOG_DATA) public anyvariable:any,
    private router:Router,
    private fb: FormBuilder,
      private dialogRef:MatDialogRef<SroterWriteBalanceRecordComponent>
    ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      StorerNumber: this.anyvariable.STORE,
      BalanceDate: '',
      RecordType:'',


    })
  }
  writeBalanceRecord(){
    var res = this.form.value;
    console.log(res);

    this.result = this.storerSvc
      .writeBalanceRecord(
        res.StorerNumber,
        this.anyvariable.STSFX,
        res.BalanceDate,
        res.RecordType,
      )
      .subscribe((data: any) => {
        this.result = data;

        console.log(this.result);
       // var msg = JSON.parse(this.result).returnedData.message;

       // this.successMessage(msg);
       if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='BALANCE REC WRTN'){
        //alert('Record Created SuccessFully');
        
       const msg= this.result[0].data[this.result[0].data.length-1].value;
        this.successMessage(msg)
        this.closeDialog();
       // this.router.navigate(['/main-nav/tallys/read']);
     }else{
     // const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
      this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
     }
      });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/storer']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  backstorer(){

  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


}
