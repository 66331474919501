<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With Serial Numbers</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Bill Number</mat-label>
            <input matInput formControlName="ODBILL" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="Org">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Org
      </th>
      <td mat-cell *matCellDef="let element">{{ element.Org }}</td>
    </ng-container>

    <ng-container matColumnDef="New">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        New
      </th>
      <td mat-cell *matCellDef="let element">{{ element.New }}</td>
    </ng-container>

    <ng-container matColumnDef="ItemNumber">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Item Number
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.ItemNumber }}
      </td>
    </ng-container>

    <ng-container matColumnDef="QtytoShip">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Qty to Ship
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.QtytoShip }}
      </td>
    </ng-container>
    <ng-container matColumnDef="QtytoScan">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Qty to Scan
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.QtytoScan }}
      </td>
    </ng-container>
    <ng-container matColumnDef="QuantityScanned">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Quantity Scanned
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.QuantityScanned }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Req">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Serial# Req
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Req }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Qty">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Qty
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Qty }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Stat">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Stat
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.Stat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="wwSerialNumber()">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Work With</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
                    #paginator
                    [pageIndex]="0"
                    [pageSize]="10"
                    [pageSizeOptions]="[5, 10, 25, 100]"
                  >
                  </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
