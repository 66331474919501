import { IitemService } from './../iitem.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { ItemUpdate2Component } from '../item-update2/item-update2.component';

@Component({
  selector: 'app-update-item',
  templateUrl: './update-item.component.html',
  styleUrls: ['./update-item.component.css'],
})
export class UpdateItemComponent implements OnInit {
  constructor(
    private router: Router,
    public itemSvc: IitemService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<UpdateItemComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      // ITSTOR: this.anyvariable.storer,
      // ITSSFX: this.anyvariable.storerSuffix,
      // ITEM: [null], //item
      // ITDSC1: [null], //desc1
      // ITDSC2: [null], //desc2
      // ITUPCC: [null], //upc -code
      // ITTAIL: [null], //tail-code
      // ITPDCD: [null], //product code
      // ITQUIC: 'N', //allow web selection
      // ITLOTT: 'N', //lotted item
      // ITLUWT: 'N', // lot unit weight
      // sortMask: [null], //sort mark
      // ITLTLB: [null], //apply to lot
      // ITPREV: [null], //place after

      ITSTOR: this.anyvariable.ITSTOR,
      ITSSFX: this.anyvariable.ITSSFX,
      ITEM: this.anyvariable.ITEM, //item
      ITDSC1: this.anyvariable.ITDSC1, //desc1
      ITDSC2: this.anyvariable.ITDSC2, //desc2
      ITUPCC: this.anyvariable.ITUPCC, //upc -code
      ITTAIL: this.anyvariable.ITTAIL, //tail-code
      ITPDCD: this.anyvariable.ITPDCD, //product code
      ITQUIC: this.anyvariable.ITQUIC, //allow web selection
      ITLOTT: this.anyvariable.ITLOTT, //lotted item
      ITLUWT: this.anyvariable.ITLUWT,// lot unit weight
      sortMask: this.anyvariable.ITSRMK, //sort mark
      ITLTLB: this.anyvariable.ITLTLB, //apply to lot
      ITPREV: this.anyvariable.ITPREV, //place after
    });
  }

  itemUpdate1(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemServiceUpdate1( 
        
        res.ITSTOR,
        res.ITSSFX,
       res.ITEM,
       res.ITDSC1,
        res.ITDSC2,
        res.ITUPCC,
       res.ITTAIL,
        res.ITPDCD,
        res.ITQUIC,
        res.ITLOTT,
        res.ITLUWT,
        res.ITLTLB,
        res.sortMask,
        res.ITPREV
        

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       console.log(this.result[0].data[16].value);
       console.log("*********************");
       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Successfully Updated'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.update2();
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[length-1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/tallys/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  update2() {
    var res = this.form.value;
    this.matDialog.open(ItemUpdate2Component, {
      width: '1000px',
      maxHeight: '80vh',

      data: res,
      autoFocus: false,
    });
  }

}
