<!-- Form No: 256 -add -->
<mat-card class="over-Flow">
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
        Work With Attributes</mat-card-title>
    </mat-card-header>
  
    <mat-card-content class="tally-serach-form">
     
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Attribute</mat-label>
            <input matInput formControlName="Attribute"/>
          </mat-form-field>
          </div>
        </div>
          <button 
            mat-raised-button   
            class="buttons addButton" 
            (click)="MaintainAttributesaddForm()">
            <mat-icon>add</mat-icon>
            <span>New Attribute</span>
          </button>
          
      </form>   
    </mat-card-content> 
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
        <ng-container matColumnDef="Attribute">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Attribute :
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Attribute }}</td>
        </ng-container>
  
        <ng-container matColumnDef="Attribute_Description">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Attribute Description
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Attribute_Description }}</td>
        </ng-container>
  
        <!-- <ng-container matColumnDef="Opt">
          <th mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Opt
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.Opt }}</td>
        </ng-container> -->
  
        
        <ng-container matColumnDef="Opt">
          <th  mat-header-cell mat-header-cell width="20%" *matHeaderCellDef>
            Opt
          </th>
          <td mat-cell *matCellDef="let element">
            <div class="dropdown more_options_btn">
              <button class="btn" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                <mat-icon>more_vert</mat-icon>
              </button>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              
              
          <!-- <mat-option>
            <button mat-icon-button (click)="MaintainAttributesaddForm(element)" >           
              <mat-icon mat-icon-button color="primary">add</mat-icon>
              <mat-label>Add</mat-label>
            </button>
          </mat-option> -->
  
          <li>
            <button mat-icon-button (click)="MaintainAttributes1editForm(element)">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button>
          </li>
          </ul>
          </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator 
        #paginator 
        [pageSizeOptions]="[1,2]" 
        showFirstLastButtons class="tally-table-pagination"
      ></mat-paginator>
</mat-card>
   
  
