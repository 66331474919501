import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { ImapZoneService } from '../imap-zone.service';
import { CustomAlertService } from '../../custom-alert/custom-alert.service';

@Component({
  selector: 'app-delete-mapzone',
  templateUrl: './delete-mapzone.component.html',
  styleUrls: ['./delete-mapzone.component.css'],
})
export class DeleteMapzoneComponent implements OnInit {
  mapZone: any;
  form!: FormGroup;
  result: any;
  user=localStorage.getItem('userName');

  constructor(
    public imapSvc: ImapZoneService,

    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private router:Router,
    private dialogRef: MatDialogRef<DeleteMapzoneComponent>,
    private CustomAlertService : CustomAlertService
  ) {}

  ngOnInit(): void {
    this.mapZone = this.anyvariable;
    this.form = this.fb.group({
      buildingNumber: this.anyvariable.WZBLDG,
      zone: this.anyvariable.WZZONE,
      description: this.anyvariable.WZDESC,
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  delete() 
  {
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete mapzone : ' + this.anyvariable.WZZONE + ' ?');
  
    dialogRef.afterClosed().subscribe((result: any) => {
    if (result)
    // if (confirm('Are you sure want to delete mapzone No:' + this.anyvariable.WZZONE + '?')) {
    this.result = this.imapSvc
      .deleteMapZone(this.user,GlobalComponent.fullBuilding(this.anyvariable.WZBLDG),this.anyvariable.WZZONE,)
      .subscribe((data: any) => {
        this.result = data;
        console.log( this.result);

        if(JSON.parse(this.result[0].data[1].value).success){
          //alert('Record Created SuccessFully');
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          this.router.navigate(['/main-nav/mapzone/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }

      });
    });
  }
  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/mapzone/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
}
