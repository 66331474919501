import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { OrderDetailsService } from '../order-details.service';

@Component({
  selector: 'app-order-detail-change-ship-from',
  templateUrl: './order-detail-change-ship-from.component.html',
  styleUrls: ['./order-detail-change-ship-from.component.css'],
})
export class OrderDetailChangeShipFromComponent implements OnInit {
  constructor(
    public orderDetailSrv: OrderDetailsService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<OrderDetailChangeShipFromComponent>
  ) {}

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  item: any;
  qty: any;
  currentBkt: any;
  seq: any;

  ngOnInit(): void {
    this.item = this.anyvariable.ODITEM;
    this.qty = this.anyvariable.ODQTYO;
    this.currentBkt = this.anyvariable.ODSHFR;
    this.seq = this.anyvariable.ODSEQ;

    this.form = this.fb.group({
      shipInventoryBucket: '',
    });
  }

  changeShipForm() {
    var res = this.form.value;
    console.log('res****', res.shipInventoryBucket);

    this.result = this.orderDetailSrv
      .changeShipFormService(
        this.anyvariable.ODBILL,
        this.anyvariable.ODSEQ,
        res.shipInventoryBucket
      )
      .subscribe((data: any) => {
        this.result = data;
        var length = this.result[0].data.length;
        console.log(this.result);

        if (
          this.result[0].data[length - 1].value ==
          'Inventory Errort Changed successfully'
        ) {
          const msg = this.result[0].data[length - 1].value;
          this.successMessage('Ship from Bucket Changed successfully');
          this.closeDialog();
          this.router.navigate(['/main-nav/orders/details']);
        } else {
          const msg = this.result[0].data[length - 1].value;
          this.failureMessage(msg);
        }
      });
  }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
