import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';
import { OrderService } from '../order.service';
import { StorerServiceService } from '../../storer/storer-service.service';
import { GlobalComponent } from 'src/app/Global-Component';

@Component({
  selector: 'app-order-event-history',
  templateUrl: './order-event-history.component.html',
  styleUrls: ['./order-event-history.component.css'],
})
export class OrderEventHistoryComponent implements OnInit {
  form!: FormGroup;
  GlobalComponent: any = GlobalComponent;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    'Date',
    'Time',
    'Event',
    'Received',
    'Transmitted',
    'Sts',
  ];

  dataSource: any = [];
  duplicateSource: any;

  // duplicateSource:any =[
  //   {
  //     Date:'11/11/22',
  //     Time:'01:10',
  //     Event:'Order Committed Committed By IPUTTAGUNT -Order Commited',
  //     Received:'11/11/22 01:10',
  //     Transmitted:'',
  //     Sts:'Ac',

  //   },
  //   {
  //    Date:'11/10/22',
  //   Time:'05:46',
  //   Event:'Order Committed Committed By IPUTTAGUNT -Order Commited',
  //   Received:'11/10/22 05:46',
  //   Transmitted:'',
  //   Sts:'DL',
  //   }
  // ]

  constructor(
    private router: Router,
    public orderSvc: OrderService,
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<OrderEventHistoryComponent>
  ) {
    this.form = this.fb.group({
      BillofLading: fb.control(this.anyvariable.OHBILL),
      Storer: fb.control(''),
      Consignee: fb.control(this.anyvariable.OHCONS),
    });
  }

  user = localStorage.getItem('userName');
  result: any;

  ngOnInit(): void {
    this.storerSvc
      .getAll(this.user, this.anyvariable.OHSTOR)
      .subscribe((data) => {
        this.result = data;
        // var res = this.result;
        var res = this.result[0].data[1]?.value;

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        this.form.reset({
          BillofLading: this.anyvariable.OHBILL,
          Storer:this.anyvariable.OHSTOR +" "+ this.duplicateSource[0].STNAME,
          Consignee: this.anyvariable.OHCONS,
        });
      });

    this.orderSvc
      .getOrderEventHistory(this.user, this.anyvariable.OHBILL, '')
      .subscribe((data) => {
        this.result = data;
        // var res = this.result;
        var res = this.result[0].data[1]?.value;
        console.log(data);

        var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log(this.duplicateSource);

        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
      });
  }

  onBack() {
    this.router.navigate(['/main-nav/orders/read']);
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
