<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Maintain Serial Numbers</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Bill </mat-label>
            <input matInput formControlName="KNDOCN" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Line </mat-label>
            <input matInput formControlName="KNLINE" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Item Number </mat-label>

            <input matInput formControlName="ODITEM" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Lots</mat-label>

            <input matInput formControlName="PD" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> ALT </mat-label>

            <input matInput formControlName="ALT" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Product Serial Number </mat-label>

            <input matInput formControlName="ProductSerialNumber" />
          </mat-form-field>

          <button
            mat-button
            class="buttons addButton"
            mat-raised-button
            (click)="update()"
          >
            Update
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
