<mat-card class="over-Flow">
    <mat-card-header>
      <mat-card-title class="title">Work With </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="buttons">
        <button (click)="kanbanCreate()"
          mat-button
          
          class="addButton buttons"
        >
          <mat-icon>add</mat-icon>
          <span>Create Kanban</span>
        </button>
      </div>
  
      <form [formGroup]="form" class="form over-Flow">
        <div class="row">
          <div class="col">
           
            <mat-form-field appearance="outline"
              ><mat-label> Card Number </mat-label>
              <input matInput formControlName="NUMB" />
            </mat-form-field>

            <mat-form-field appearance="outline"
              ><mat-label> Location </mat-label>
              <input matInput formControlName="LOCN" />
            </mat-form-field>

            <mat-form-field appearance="outline"
              ><mat-label> Storer </mat-label>
              <input matInput formControlName="STOR" />
            </mat-form-field>

            <mat-form-field appearance="outline"
              ><mat-label> Item </mat-label>
              <input matInput formControlName="ITEM" />
            </mat-form-field>

          
        </div>
        </div>
        <div class="row">
          <div class="col">
            <button
              class="buttons addButton"
              mat-raised-button
              type="submit"
              placeholder="Search"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  
    <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
      <ng-container matColumnDef="cardNumber">
        <th mat-header-cell *matHeaderCellDef>Card Number</th>
        <td mat-cell *matCellDef="let element">{{ element.cardNumber }}</td>
      </ng-container>
  
      <ng-container matColumnDef="storerNumner">
        <th mat-header-cell *matHeaderCellDef>Storer Number</th>
        <td mat-cell *matCellDef="let element">{{ element.storerNumner }}</td>
      </ng-container>

      <ng-container matColumnDef="itemCode">
        <th mat-header-cell *matHeaderCellDef>Item Code</th>
        <td mat-cell *matCellDef="let element">{{ element.itemCode }}</td>
      </ng-container>

      <ng-container matColumnDef="reqQuantity">
        <th mat-header-cell *matHeaderCellDef>Requested quantity</th>
        <td mat-cell *matCellDef="let element">{{ element.reqQuantity }}</td>
      </ng-container>

      <ng-container matColumnDef="deliverLocation">
        <th mat-header-cell *matHeaderCellDef>Deliver Location</th>
        <td mat-cell *matCellDef="let element">{{ element.deliverLocation }}</td>
      </ng-container>

        <ng-container matColumnDef="Actions">
        <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-select class="mat-select" placeholder="Select Option">
            <mat-option>
              <button mat-icon-button >
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button></mat-option
            >
  
            <mat-option>
              <button mat-icon-button >
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button></mat-option
            >
          </mat-select>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      #paginator
      [pageSizeOptions]="[4, 8, 16]"
      showFirstLastButtons
    ></mat-paginator>
  </mat-card>



