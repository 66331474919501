import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-ledger-code',
  templateUrl: './add-ledger-code.component.html',
  styleUrls: ['./add-ledger-code.component.css'],
})
export class AddLedgerCodeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddLedgerCodeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      LELEGC: 0,
      LELEGS: '',
      LEDESC: '',
      LECC: '',
      LECSFX: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  add() {
    console.log('added');
  }
}
