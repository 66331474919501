import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-serial-number',
  templateUrl: './add-serial-number.component.html',
  styleUrls: ['./add-serial-number.component.css'],
})
export class AddSerialNumberComponent implements OnInit {
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<AddSerialNumberComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      KNDOCN: 123455239,
      KNLINE: 1,
      ODITEM: 1234,
      PD: 123456,
      ALT: 12345,
      ProductSerialNumber: '',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  add() {
    console.log('added');
  }
}
