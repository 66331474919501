import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';
import { MoveQueueService } from '../move-queue.service';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-redirect-form2',
  templateUrl: './redirect-form2.component.html',
  styleUrls: ['./redirect-form2.component.css']
})
export class RedirectForm2Component implements OnInit {

  constructor(public moveQSrv: MoveQueueService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
     private fb: FormBuilder,
     private router : Router,
    private dialogRef: MatDialogRef<RedirectForm2Component>
    ) 
    { }

  storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');
  

  ngOnInit(): void {
    this.form = this.fb.group({
  
      transNumber: this.anyvariable.MQTRAN,
      storer: this.anyvariable.MQSTOR,
      item : this.anyvariable.MQITEM,
      fromLocation: this.anyvariable.MQBLDG + '  ' + this.anyvariable.MQFRAI+'/'+this.anyvariable.MQFRSL,
      quantity: this.anyvariable.MQQTY,
      lots: this.anyvariable.MQLOT1 + ' | ' + this.anyvariable.MQLOT2  + ' | ' +  this.anyvariable.MQLOT3,
      
      newLocation:'',
      newLot1:'',
      newLot2:'',
      newLot3:''  
       
    });
  }


  redirect2(){
    var res = this.form.value;
    this.result = this.moveQSrv
      .redirect2Service( 

        res.transNumber,
        res.newLocation,
        res.newLot1,
        res.newLot2,
        res.newLot3
        

        ).subscribe((data: any) => {
        this.result = data;
       var length = this.result[0].data.length;
          if( this.result[0].data[length-1].value=='Move Redirect is Successfull'){
            const msg= this.result[0].data[length-1].value;
            this.successMessage(msg)
            this.closeDialog();
          }else{
            const msg=this.result[0].data[length-1].value;
            this.failureMessage(msg);
          }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/movequeue/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }
  
  closeDialog(){
   this.dialogRef.close();
  }


  backstorer(){
    
  }

}
