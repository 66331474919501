import { CopyEnvironmentComponent } from './copy-environment/copy-environment.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.css'],
})
export class EnvironmentComponent implements OnInit {
  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = ['EnvironmentName', 'Description', 'Actions'];
  dataSource: any = [];

  duplicateSource: any = [
    {
      EnvironmentName: 'Test',
      Description: 'TEST ENVIRONMENT ',
    },
  ];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      EnvironmentName: '',
    });

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  copyEnvironment() {
    this.matDialog.open(CopyEnvironmentComponent, {
      width: '850px',
      maxHeight: '100vh',
      autoFocus: false,
    });
  }
  delete() {
    console.log('delete');
  }
}
