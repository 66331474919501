
<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                  <mat-card-title style="color: rgb(203, 207, 219)">Load Sheduled Floor Move</mat-card-title>
                </mat-card-header>
                
                <mat-card-content>  
                  
                  <div class="row">
                    <mat-form-field appearance="outline">  <mat-label>Storer </mat-label> 
                      <input matInput formControlName="STOR" />
                    </mat-form-field>
                  </div>
                  <div class="row">
                    <mat-form-field appearance="outline">  <mat-label> Item Number </mat-label> 
                        <input matInput formControlName="ITEM" />
                      </mat-form-field>
                    </div>
                    <div class="row">
                      <mat-form-field appearance="outline">  <mat-label> Location</mat-label> 
                          <input matInput formControlName="LOCAT" />
                        </mat-form-field>
                      </div>
                      <div class="row">
                      <mat-form-field appearance="outline">  <mat-label>Lots</mat-label> 
                          <input matInput formControlName="LOTS" />
                        </mat-form-field> 
                    </div> 
                    <div class="row">
                        <mat-form-field appearance="outline">  <mat-label>Availaible Quantity</mat-label> 
                            <input matInput formControlName="AQTY" />
                          </mat-form-field>
                        </div> 
                        <div class="row">
                      <mat-form-field appearance="outline">  <mat-label>Suggested Location</mat-label>
                        <input matInput formControlName="SUCAT"/>
                          
                      </mat-form-field>
                    </div>
                      <div class="row">
                      <mat-form-field appearance="outline">  <mat-label> Location</mat-label>
                        <input matInput formControlName="TOCAT"/>
                          
                      </mat-form-field>
                    </div>
                    <div class="row">
                    <mat-form-field appearance="outline">  <mat-label> Quantity</mat-label>
                      <input matInput formControlName="MQTY"/>
                        
                    </mat-form-field>
                </div>
                <div class="row">
                    <mat-form-field appearance="outline">  <mat-label>Priority</mat-label>
                        <input matInput formControlName="PRTY"/>
                          
                      </mat-form-field>
                    </div>
                </mat-card-content> 
            </mat-card>
        </form>