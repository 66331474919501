import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-ledger-code',
  templateUrl: './delete-ledger-code.component.html',
  styleUrls: ['./delete-ledger-code.component.css'],
})
export class DeleteLedgerCodeComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<DeleteLedgerCodeComponent>
  ) {}

  form!: FormGroup;
  result: any;
  ngOnInit(): void {
    this.form = this.fb.group({
      LELEGC: 100,
      LELEGS: '',
      LEDESC: 'Handling Charges',
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  delete() {
    console.log('deleted');
  }
}
