import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IMapLocationService } from '../i-map-location.service';
interface CustomColumn {
  possition: number;
  name: string;
  isActive: boolean;
}

@Component({
  selector: 'app-location-status-inquiry',
  templateUrl: './location-status-inquiry.component.html',
  styleUrls: ['./location-status-inquiry.component.css']
})
export class LocationStatusInquiryComponent implements OnInit {
  selectedRow: any;
  editedRows: any;


 // form!: FormGroup;
  totalA: Number = 0;

  dataSource:any=[];
  duplicateSource: any;
  @ViewChild('paginator') paginator!: MatPaginator;


  constructor(

    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    public iMapSvc: IMapLocationService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<LocationStatusInquiryComponent>

  ) { }

  form = this.fb.group({
    building:'M&W DISTRIBUTION #1',
    location:'1 A/1',
    palletsHighDeep:'',
    heightwidthdepth:''
   });

   user=localStorage.getItem('userName');
   result:any;


  ngOnInit(): void {

    this.iMapSvc.getAllLocStatusInquiry(this.user).subscribe((data) => {
      this.result = data;
          var res = this.result[0].data[1]?.value;
          var parsedData = JSON.parse(res);
        this.duplicateSource = parsedData.returnedData;
        console.log('STATUS INQUIRY DATA')
        console.log(this.duplicateSource);
        this.dataSource = new MatTableDataSource(this.duplicateSource);
        this.dataSource.paginator = this.paginator;
    
          this.initializeColumnProperties();
          this.editedRows = [];
  })}

  displayedColumns: string[] = [
    'Storer',
    "item",
    'Lot Codes',
    'Lot Received',
    'Actual',
    'Avail',
    'Commit'
   
  ];
  public columnShowHideList: CustomColumn[] = [];

  toggleColumn(column: any) {
    if (column.isActive) {
      if (column.possition > this.displayedColumns.length - 1) {
        this.displayedColumns.push(column.name);
      } else {
        this.displayedColumns.splice(column.possition, 0, column.name);
      }
    } else {
      let i = this.displayedColumns.indexOf(column.name);
      let opr = i > -1 ? this.displayedColumns.splice(i, 1) : undefined;
    }
  }

  position: any;
  name: any;
  isActive: any;

  initializeColumnProperties() {
    // this.displayedColumns.forEach((element, index) => {
    //   if (element != 'Actions') {
    //     this.columnShowHideList.push({
    //       possition: index,
    //       name: element,
    //       isActive: true,
    //     });
    //   }
    // });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length,
      name: 'serial number',
      isActive: false,
    });
    this.columnShowHideList.push({
      possition: this.columnShowHideList.length - 1,
      name: 'no ship date',
      isActive: false,
    });
    
    
    console.log('*********************');
    
    console.log(this.columnShowHideList);
    // After for loop it will look like this
    //   public columnShowHideList = [
    //   { possition: 0, name: 'action', isActive: true },
    //   { possition: 1, name: 'userName', isActive: true },
    //   { possition: 2, name: 'email', isActive: true },
    //   { possition: 3, name: 'contactNo', isActive: true },
    //   { possition: 4, name: 'address', isActive: true }
    // ];
  }

  rowClick(rowId: any) {
    this.selectedRow = rowId;
  }


  // dataSource = [
  //   {
  //     StorerItem:'5002 170',
  //     LotCodes:'20190109',
  //     LotReceived:'2/14/18',
  //     Actual:120,
  //     Avail:128,
  //    Commit:''
  //   },
  // {
  //   StorerItem:'5002 244',
  //   LotCodes:'20200209',
  //   LotReceived:'2/14/18',
  //   Actual:90,
  //   Avail:90,
  //   Commit:''
  // }];

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

 

  totalActual(){

    // var totalAc = 0 ;
    // for(let i=0;i<this.dataSource.length; i++)
    // {
    //   console.log('totalAc')
    //   console.log(totalAc)
    //   totalAc= Number(this.dataSource[i].Actual) + Number(totalAc) 
    // }

    // return totalAc;
  }

  totalAvail(){

    // var totalAv = 0 ;
    // for(let i=0;i<this.dataSource.length; i++)
    // {
    //   console.log('totalAv')
    //   console.log(totalAv)
    //   totalAv = Number(this.dataSource[i].Avail) + Number(totalAv) 
    // }

    // return totalAv;
  }
}
