<mat-card class="over-Flow">

  <div class="main-header-title">
      <mat-card-header>
          <mat-card-title fxLayoutAlign="center">Order Comments Maintenance</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
          <mat-icon  mat-icon-button color="primary" >close</mat-icon>
      </button>
  </div>

  <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">

          <mat-form-field appearance="outline" class="full-width w-100">
              <mat-label>Comments :</mat-label>
              <textarea matInput formControlName="comment"> Comments</textarea>
            </mat-form-field>

      </form>
  </mat-card-content>
  


          <table class="mat-elevation-z8 tally-table" formArrayName="comments">  
              <tr>  
                <th width="150px">
                  <button mat-button (click)="addComments()" class="addButton">
                      <mat-icon>add</mat-icon>
                      <span>Comment</span>
                  </button>
              </th>  
              </tr>  
              <tr *ngFor="let comment of comments().controls; let i=index" [formGroupName]="i">  
                <td>
                  <mat-form-field appearance="outline" class="formfields">
                      <mat-label>Comment :</mat-label>
                      <input matInput type="textarea" formControlName="comment" class="form-control"/>
                      
                  </mat-form-field>  
                   
                </td>  
                <td>  
                 

                  <mat-form-field appearance="outline" class="formfields">  
                      <mat-label> Print  </mat-label> 
              
                      <mat-select matNativeControl >
                          <mat-option value="Y">Yes</mat-option>
                          <mat-option value="N">No</mat-option>
                      </mat-select>
                      <input matInput formControlName="print" />
                    </mat-form-field>
                </td>  
                <td>  
                  <mat-form-field appearance="outline" class="formfields">
                      <mat-label> Form:  </mat-label>
                      <input matInput type="text" formControlName="form" class="form-control"/>
                  </mat-form-field>
               </td>  

               <td>  
                  <mat-form-field appearance="outline" class="formfields">
                      <mat-label> Type:</mat-label>
                      <input matInput type="text" formControlName="type" class="form-control"/>
                  </mat-form-field>   
               </td> 

              </tr>  
            </table>  
            <button mat-button class="buttons addButton" (click)="createOrderDtlComment()">Create</button>
</mat-card>