<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Maintain Exit Points</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
        <div class="col">
          <div style="margin-left:20px;"><mat-label> Storer </mat-label>
          <mat-form-field appearance="outline">
            <input matInput formControlName="Storer" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-hint> Suffix </mat-hint>
            <input matInput formControlName="Suffix" />
          </mat-form-field>
          </div>
          <mat-form-field appearance="outline">
            <mat-label> Exit Point</mat-label>

            <input matInput formControlName="ExitPoint" required/>
          </mat-form-field>
       
          <mat-form-field appearance="outline">
            <mat-label> Choice </mat-label>

            <input matInput formControlName="Choice" />
          </mat-form-field>
        </div>
        </div>
        <button [disabled]="!(form.valid && form.dirty)" class="buttons addButton" mat-raised-button (click)="createExitPoint()">
          Add
        </button>
      
    </form>
    </mat-card-content>
  </mat-card>

