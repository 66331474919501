<mat-card>
    <mat-card-header>  
      <mat-card-title >Work with Lot Status</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <button mat-button (click)="addLotStatus()" class="addButton">
            <mat-icon>add</mat-icon>
            <span>Lot Status</span>
      </button>
        <div class="formDiv">
          <form [formGroup]="form">
                <div class="row">
                    <div class="col">
                          <mat-form-field appearance="outline">
                            <mat-label>Storer Number</mat-label>
                            <input matInput formControlName="StorerNumber"/>
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>Item Number</mat-label>
                            <input matInput formControlName="ItemNumber"/>
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>Lot Code</mat-label>
                            <input matInput formControlName="LotCode"/>
                          </mat-form-field>                        
                    </div>
    
                    <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>Status</mat-label>
                        <input matInput formControlName="Status"/>
                      </mat-form-field>
                    </div>  
                </div>
            </form>
        </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="StorerNumber">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Storer Number
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.StorerNumber }}</td>
            </ng-container>
      
            <ng-container matColumnDef="ItemNumber">
              <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Item Number
              </th> 
              <td mat-cell *matCellDef="let element">{{ element.ItemNumber }}</td>
            </ng-container>

            <ng-container matColumnDef="LotCode">
                <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                    Lot Code
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LotCode }}</td>
            </ng-container>

            <ng-container matColumnDef="DateHeld">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Date Held
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.DateHeld }}</td>
            </ng-container>

            <ng-container matColumnDef="DateReleased">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Date Released
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.DateReleased }}</td>
            </ng-container>

            <ng-container matColumnDef="St">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    St
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.St }}</td>
            </ng-container>

            <ng-container matColumnDef="Expiration">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Expiration
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Expiration }}</td>
            </ng-container>

            <ng-container matColumnDef="Received">
                <th mat-header-cell mat-header-cell *matHeaderCellDef>
                    Received
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Received }}</td>
            </ng-container>

            <ng-container matColumnDef="Action">
                <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <mat-select  placeholder="Select Option">
                    <mat-option>
                        <button mat-icon-button (click)=" changeLotStatus()">
                          <mat-icon mat-icon-button color="primary">edit</mat-icon>
                          <mat-label>Change</mat-label>
                        </button>
                    </mat-option>

                    <mat-option>
                        <button mat-icon-button (click)=" inquiryLotStatus()">
                          <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                          <mat-label>Inquiry</mat-label>
                        </button>
                    </mat-option>

                   

                  
                </mat-select>
                </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
            
        </table>
    </mat-card-content>
</mat-card>
<!-- <p>ww-lot-status-add works!</p> -->
