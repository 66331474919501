<mat-card class="over-Flow">
    <div class="main-header-title">  
      <mat-card-header>
        <mat-card-title>Location Inquiry</mat-card-title>
      </mat-card-header>
      <div class="buttons">
        <mat-menu #mainMenuButton="matMenu">
          <span
            (click)="$event.stopPropagation()"
            *ngFor="let column of columnShowHideList; index as i"
          >
            <mat-slide-toggle
              style="color: blue"
              [(ngModel)]="column.isActive"
              (change)="toggleColumn(column)"
            >
              {{ column.name }}
            </mat-slide-toggle>
  
            <br />
          </span>
        </mat-menu>

        <button
        mat-button
        matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>
  
      

        <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
        </button>
    </div>
  </div>

    <mat-card-content  class="tally-serach-form">
        <form [formGroup]="form">
          <div class="row">
            <div class="col">    

                    <mat-form-field  appearance="outline">
                        <mat-label> Building </mat-label> 
                        <input matInput formControlName="building" />
                    </mat-form-field>
        
                    <mat-form-field  appearance="outline">
                        <mat-label> Pallets High/Deep </mat-label> 
                        <input matInput formControlName="palletsHighDeep" />
                    </mat-form-field>
               
                    <mat-form-field  appearance="outline">
                        <mat-label> Location </mat-label> 
                        <input matInput formControlName="location" />
                    </mat-form-field>
        
                    <mat-form-field  appearance="outline">
                        <mat-label> Height/Width/Depth </mat-label> 
                        <input matInput formControlName="heightwidthdepth" />
                    </mat-form-field>
                </div>
            </div> 
        </form>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
            <ng-container matColumnDef="Storer">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                    Storer
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LCSTOR }}</td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="item">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                    Item
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LCITEM }}</td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Lot Codes">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                    Lot Codes
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LCLTC1 }}</td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Lot Received">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                    Lot Received
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.LCRCVD }}</td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="Actual">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                    Actual
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Actual }}</td>
                <td mat-footer-cell *matFooterCellDef>{{totalActual()}}</td>
              </ng-container>

              <ng-container matColumnDef="Avail">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                    Avail
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Avail }}</td>
                <td mat-footer-cell *matFooterCellDef>{{totalAvail()}}</td>
              </ng-container>

              <ng-container matColumnDef="Commit">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                    Commit
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.Commit }}</td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="serial number">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                  Serial Numbers
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.serialnumber }}</td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>

              <ng-container matColumnDef="no ship date">
                <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
                  No Ship Date
                </th> 
                <td mat-cell *matCellDef="let element">{{ element.noshipdate }}</td>
                <td mat-footer-cell *matFooterCellDef></td>
              </ng-container>



              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr  mat-row  *matRowDef="let row; columns: displayedColumns"></tr>
              <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>

        </table><br>

        <b mat-button class="tally-table-pagination" style="margin-left:20px;">
          Item Totals  {{totalActual()}}
        </b>
                
    </mat-card-content>
</mat-card>

