<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
  <form [formGroup]="form">
    <mat-card>
        <mat-card-header>
            <button mat-button (click)="backstorer()">
              <mat-icon></mat-icon>
              
            </button>
          <mat-card-title style="color: rgb(3, 3, 4)"> Copy  A Message Class </mat-card-title>
                  
        </mat-card-header>
        <mat-card-content> 
                        
            <div class="col">
                <mat-form-field appearance="outline">  <mat-label> Document Name</mat-label> 
                    <input matInput formControlName="DOCUMENT" />
                </mat-form-field>
                <mat-form-field appearance="outline">  <mat-label>Parse Type</mat-label> 
                    <input matInput formControlName="PARSETYPE" />
                </mat-form-field>
                <mat-form-field appearance="outline">  <mat-label>New Document</mat-label> 
                    <input matInput formControlName="NewDocument" />
                </mat-form-field>
            </div>
                              <div>
                                <button mat-button class="buttonCss" (click)="attributes()">Copy</button>
                            </div>
      
        </mat-card-content>
    </mat-card>
   </form>  


