<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Maintain a Ledger Code</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Ledger Code </mat-label>
            <input matInput formControlName="LELEGC" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Ledger Sub Code </mat-label>
            <input matInput formControlName="LELEGS" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Ledger Code Description </mat-label>

            <input matInput formControlName="LEDESC" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Cost Center Code </mat-label>

            <input matInput formControlName="LECC" maxlength="1" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Cost Center Suffix </mat-label>

            <input matInput formControlName="LECSFX" />
          </mat-form-field>
          <button
            mat-button
            class="buttons addButton"
            mat-raised-button
            (click)="update()"
          >
            Update
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</form>
