import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-order-sendto-move-queue',
  templateUrl: './order-sendto-move-queue.component.html',
  styleUrls: ['./order-sendto-move-queue.component.css']
})
export class OrderSendtoMoveQueueComponent implements OnInit {

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    public iOrderService: OrderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
   private dialogRef: MatDialogRef<OrderSendtoMoveQueueComponent>
   ) 
   { }

   storer: any;
  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  ngOnInit(): void {
    this.form = this.fb.group({
  
      storer:this.anyvariable.OHSTOR,
      billOfLading:this.anyvariable.OHBILL,
      priority: '',
      location: ''
       
    });
  }


  orderToMoveQueue(){
    var res = this.form.value;   
    this.result = this.iOrderService
      .orderToMoveQueueService(         
        res.billOfLading,
        res.priority,
        res.location  

        ).subscribe((data: any) => {
        this.result = data;

       var length = this.result[0].data.length;
          if(this.result[0].data[length-1].value=='Successfully Created'){
          
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg);
          this.closeDialog();
          
       }else{
        const msg=this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/orders/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   this.router.navigate(['/main-nav/orders/read']);
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
