
<mat-card class="over-Flow">
 
    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title> Stock Inquiry </mat-card-title>
      </mat-card-header>
        <button mat-icon-button (click)="closeDialog()">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
     <!-- nnn  <button mat-button  class="addButton"  (click)="createPutawayLocation()" matTooltip="Add Tally Detais Putaway Location">
            <mat-icon>add</mat-icon>
            <span>New Tally Detail</span>
          </button>  -->        
       
        <!-- <div class="buttons">
          <button mat-button class="addButton">
            <mat-icon>add</mat-icon>
          </button>
          <button mat-icon-button (click)="closeDialog()">           
            <mat-icon  mat-icon-button color="primary">close</mat-icon>
          </button>
        </div> -->
    </div>  
    <!-- <div class="buttons">
          <button mat-button (click)="itemLocationCreate()" class="addButton">
            <mat-icon>add</mat-icon>
          </button>
        </div> -->
      
      
      
    
  <mat-card-content class="tally-serach-form">
      <form [formGroup]="form" class="form over-Flow" >
        <div class="row">
          <div class="col"> 
          <mat-form-field appearance="outline"
            ><mat-label>Storer</mat-label>
            <input readonly matInput formControlName="storer" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Item</mat-label>
            <input readonly matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>Upc</mat-label>
            <input readonly matInput formControlName="upc" />
          </mat-form-field>

          <mat-form-field appearance="outline"
            ><mat-label>In-Transit</mat-label>
            <input readonly matInput formControlName="inTrans" />
          </mat-form-field> 

          <mat-form-field appearance="outline"
            ><mat-label>Future Allocated</mat-label>
            <input readonly matInput formControlName="futureAllocate" />
          </mat-form-field> 
        
          <mat-form-field appearance="outline"
            ><mat-label>Location</mat-label>
            <input matInput formControlName="location" />
          </mat-form-field>
          
          <div style="margin-left: 20px"><b>Lot Code :

            <mat-form-field appearance="outline"
              ><mat-label>Lot code 1</mat-label>
              <input matInput formControlName="lot1" />
            </mat-form-field>
  
            <mat-form-field appearance="outline"
              ><mat-label>Lot code 2</mat-label>
              <input matInput formControlName="lot2" />
            </mat-form-field>
  
            <mat-form-field appearance="outline"
              ><mat-label>Lot code 3</mat-label>
              <input matInput formControlName="lot3" />
            </mat-form-field>
          </b></div>
        </div>
      </div>
        
      <button
        class="buttons addButton"
        mat-raised-button
        type="submit"
        placeholder="Search"
      >
        Search
      </button>
      <!-- </div>
     </div> -->
    </form>
    

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
    <ng-container matColumnDef="location">
      <th mat-header-cell *matHeaderCellDef>Location</th>
      <td mat-cell *matCellDef="let element">{{ element.LCBLDG }} &nbsp;&nbsp;&nbsp; {{ element.LCFLOR }} &nbsp; {{ element.LCAISL }}/{{ element.LCSLOT }}</td>
    </ng-container>

    <ng-container matColumnDef="f">
      <th mat-header-cell *matHeaderCellDef>F</th>
      <td mat-cell *matCellDef="let element">element.f</td>
    </ng-container>
    <ng-container matColumnDef="lotCodes">
      <th mat-header-cell *matHeaderCellDef>Lot Codes</th>
      <td mat-cell *matCellDef="let element">{{ element.LCLTC1 }} &nbsp; | &nbsp; {{ element.LCLTC2 }} &nbsp; | &nbsp; {{ element.LCLTC3 }}</td>
    </ng-container>
    <ng-container matColumnDef="lotRcv">
      <th mat-header-cell *matHeaderCellDef>Lot Received</th>
      <td mat-cell *matCellDef="let element">{{ element.LCRCVD }}</td>
    </ng-container>

    <ng-container matColumnDef="actual">
      <th mat-header-cell *matHeaderCellDef>Actual</th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>

    <ng-container matColumnDef="avail">
      <th mat-header-cell *matHeaderCellDef>Avail</th>
      <td mat-cell *matCellDef="let element">{{ element.LCQAVL }}</td>
    </ng-container>
    <ng-container matColumnDef="commit">
      <th mat-header-cell *matHeaderCellDef>Commit</th>
      <td mat-cell *matCellDef="let element"></td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
  </mat-card-content>
</mat-card>
