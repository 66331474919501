import { AddEmailAddressComponent } from './add-email-address/add-email-address.component';
import { EditEmailAddressComponent } from './edit-email-address/edit-email-address.component';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-email-address',
  templateUrl: './email-address.component.html',
  styleUrls: ['./email-address.component.css'],
})
export class EmailAddressComponent implements OnInit {
  displayedColumns: string[] = ['Name', 'EmailAddress', 'Actions'];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      Name: 'Neil Kohan',
      EmailAddress: '',
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      Name: [null],
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild('paginator') paginator!: MatPaginator;

  EmailAddressCreate() {
    this.matDialog.open(AddEmailAddressComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }

  EmailAddressUpdate() {
    this.matDialog.open(EditEmailAddressComponent, {
      width: '1000px',
      maxHeight: '80vh',

      // "data": storer,
      autoFocus: false,
    });
  }
  delete() {
    alert('deleted');
  }
}
