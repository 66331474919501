<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Update Extract Report</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Extract Name:</mat-label>
                    <input matInput formControlName="extractName" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Description:</mat-label>
                    <input matInput formControlName="description" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Calling Program:</mat-label>
                    <input matInput formControlName="callingProgram" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Output Type:</mat-label>
                    <input matInput formControlName="outputType" />
                </mat-form-field>
            </div> 

            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Ifs Location</mat-label>
                    <input matInput formControlName="ifsLocation" />
                </mat-form-field>
            </div> 

            
        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="editExtractReports()" class="addButton">           
        <mat-icon mat-icon-button color="primary">create</mat-icon>
        <mat-label>Update</mat-label>
    </button>
</mat-card-footer>


