<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Account Processing </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div class="buttons">
      <button
        mat-button
        (click)="accountProcessingCreate()"
        class="addButton buttons"
      >
        <mat-icon>add</mat-icon>
        <span>New Account Processing</span>
      </button>
    </div>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Storer</mat-label>
            <input matInput formControlName="storerNum" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Process</mat-label>
            <input matInput formControlName="process" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <!-- Tally Column -->
    <ng-container matColumnDef="Storer">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Storer
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IHSTOR }}</td>
    </ng-container>

    <ng-container matColumnDef="Process">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Process
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IHCHGW }}</td>
    </ng-container>

    <ng-container matColumnDef="CD">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>CD</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHPRO }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Description">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Description
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHCAR }}
      </td>
    </ng-container>

    <ng-container matColumnDef="ResponsibleProfile">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Responsible Profile
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHPRO }}
      </td>
    </ng-container>

    <ng-container matColumnDef="St">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>St</th>
      <td mat-cell *matCellDef="let element">
        {{ element.IHBRK1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="150px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="accountProcessingUpdate(element)">
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >
          <mat-option>
            <button mat-icon-button (click)="accountProcessingCopy(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>Copy Work</mat-label>
            </button></mat-option
          >
          <mat-option>
            <button mat-icon-button (click)="accountProcessingDelete(element)">
              <mat-icon mat-icon-button color="warn">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
        #paginator
        [pageIndex]="0"
        [pageSize]="10"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
