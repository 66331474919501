import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
@Component({
  selector: 'app-labor-processing-copy',
  templateUrl: './labor-processing-copy.component.html',
  styleUrls: ['./labor-processing-copy.component.css']
})
export class LaborProcessingCopyComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<LaborProcessingCopyComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      BuildingNumber:this.anyvariable.BuildingNumber,
      StorerNumber:this.anyvariable.StorerNumber,
      Process:this.anyvariable.Process,
      OperatorGroup:this.anyvariable.OperatorGroup,
      Step:this.anyvariable.Step,
      MoveTp:this.anyvariable.MoveTp,
      Condition:this.anyvariable.Condition,
      Task:this.anyvariable.Task,
      ResponsibleProfile:this.anyvariable.ResponsibleProfile,
      MQ:this.anyvariable.MQ
    })
  }

  copyLaborProcessing(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
