<mat-card>
    <mat-card-header>
   <mat-card-title>
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Tag Counts Matching This Book Line</mat-card-title>
    </mat-card-header>
 
     <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
       <ng-container matColumnDef="TagNumber">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            TagNumber
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.TagNumber}}</td>
         </ng-container>
         <ng-container matColumnDef="Team">
           <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Team
           </th> 
           <td mat-cell *matCellDef="let element">{{ element.Team}}</td>
         </ng-container>

         <ng-container matColumnDef="Counted">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Counted
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Counted}}</td>
          </ng-container>

          <ng-container matColumnDef="Bucket">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                Bucket
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.Bucket}}</td>
          </ng-container>

  
         <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
         <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
       </table> 
