import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';
@Component({
  selector: 'app-ww-hazard-materials-group-change',
  templateUrl: './ww-hazard-materials-group-change.component.html',
  styleUrls: ['./ww-hazard-materials-group-change.component.css']
})
export class WwHazardMaterialsGroupChangeComponent implements OnInit {

  form!: FormGroup;
  result: any;
  user = 'NKOHAN';

  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwHazardMaterialsGroupChangeComponent>
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      HazardMatGroup : '2',
      ProperName : 'ORGANOMETALLIC SUBSTANCE LIQUID,WATER',
      Class : '1.3',
      IdNo : ' ',
      PackingGroup : ' ',
      SpecialRequire:' '

     });
  }

  changeHazardMaterials(){
    
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
