import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-messageclassshred-delete',
  templateUrl: './messageclassshred-delete.component.html',
  styleUrls: ['./messageclassshred-delete.component.css']
})
export class MessageclassshredDeleteComponent implements OnInit {
  storer: any;
  form!: FormGroup;
  result: any;

  constructor(public iHeaderSvc: IheaderService, 
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<MessageclassshredDeleteComponent>
  ) 
  { }

  ngOnInit(): void {
    this.form = this.fb.group({
      DOCUMENT:'AXWAY 943',
      PARSETYPE:'XML',
      DFTSCHEMA:'QTEMP',
      
       
    });
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


  backstorer(){
    
  }
  attributes(){
    
  }

}

