import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { KanbanAddComponent } from './kanban-add/kanban-add.component';
import { KanbanChangeComponent } from './kanban-change/kanban-change.component';
import { KanbanDeleteComponent } from './kanban-delete/kanban-delete.component';

@Component({
  selector: 'app-ww-kanban',
  templateUrl: './ww-kanban.component.html',
  styleUrls: ['./ww-kanban.component.css']
})
export class WwKanbanComponent implements OnInit {

  displayedColumns: string[] = ['cardNumber', 'storerNumner', 'itemCode', 'reqQuantity', 'deliverLocation', 
  'Actions'];
 form!: FormGroup;

 dataSource: any = [];
 duplicateSource: any = [
   {
    cardNumber: 'APPT',
    storerNumner: '001',
    itemCode:'LCSTOR',
    reqQuantity: '001',
    deliverLocation:'LCSTOR',
    

    


   },
 ];

 constructor(
  private activeRouter: ActivatedRoute,
   private router: Router,
   private matDialog: MatDialog,
   private fb: FormBuilder
 ) {}
 ngOnInit(): void {
   this.form = this.fb.group({
    NUMB : [null],//cardNumber
    LOCN:[null],//location
    STOR:[null],//storer
    ITEM:[null],//item
    SSFX:[null],//storer suffix
   
   });
   this.dataSource = new MatTableDataSource(this.duplicateSource);
   this.dataSource.paginator = this.paginator;
 }

 @ViewChild('paginator') paginator!: MatPaginator;

 kanbanCreate() {
   this.matDialog.open(KanbanAddComponent, {
     width: '1000px',
     maxHeight: '80vh',

     // "data": storer,
     autoFocus: false,
   });
 }

 kanbanChange(element:any) {
   this.matDialog.open(KanbanChangeComponent, {
     width: '1000px',
     maxHeight: '80vh',

     // "data": storer,
     autoFocus: false,
   });
 }

 kanbanDelete(element :any) {
   this.matDialog.open(KanbanDeleteComponent, {
     width: '1000px',
     maxHeight: '80vh',

     // "data": storer,
     autoFocus: false,
   });
 }
 delete() {
   alert('deleted');
 }

}
