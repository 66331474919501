import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { StorerServiceService } from '../storer-service.service';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';

@Component({
  selector: 'app-storer-deactive',
  templateUrl: './storer-deactive.component.html',
  styleUrls: ['./storer-deactive.component.css'],
})
export class StorerDeactiveComponent implements OnInit {
  form!: FormGroup;
  result: any;
  user = GlobalComponent.user;

  constructor(
    private activeRouter: ActivatedRoute,
    public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router: Router,
    private matDialog: MatDialog,
    private CustomAlertService: CustomAlertService,

    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<StorerDeactiveComponent>
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      storer: this.anyvariable.STORE,
      name: this.anyvariable.STNAME,
      buliding: this.anyvariable.STBLDG,
      primaryAccountRep: this.anyvariable.STPREP,
    });
  }

  deactivateStorer() {
    const dialogRef = this.CustomAlertService.openCustomAlert('Deactivate storer : ' + this.anyvariable.STORE + '?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
    
      var res = this.form.value;
      this.result = this.storerSvc
        .storerServiceDeactivate(res.storer, this.anyvariable.STSFX)
        .subscribe((data: any) => {
          this.result = data;
          console.log(this.result);

          if (
            this.result[0].data[this.result[0].data.length - 2].value == '' &&
            this.result[0].data[this.result[0].data.length - 1].value ==
              'DELETED SUCCESSFULLY'
          ) {
            const msg = this.result[0].data[this.result[0].data.length - 1].value;
            this.successMessage(msg);
            this.closeDialog();
            this.deactivated();
          } else {
            this.failureMessage(
              this.result[0].data[this.result[0].data.length - 1].value
            );
          }
        });
      }
    });
    }

  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    this.closeDialog();
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
  }

  closeDialog() {
    this.dialogRef.close();
  }

  deactivated() {
    console.log('************** STORER DEACTIVATED *************');
  }
}
