import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';

import { ContactTypesAddComponent } from './contact-types-add/contact-types-add.component';
import { ContactTypesChangeComponent } from './contact-types-change/contact-types-change.component';


@Component({
  selector: 'app-ww-contact-types',
  templateUrl: './ww-contact-types.component.html',
  styleUrls: ['./ww-contact-types.component.css']
})
export class WwContactTypesComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;


  displayedColumns: string[] = [

    
    'contactType',
    'contactDescription',
    'opt',
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      contactType:'CCLC',
      contactDescription:'Codeworks',
      
      
     

      
    },
    {
     
      
      contactType:'CONERROR',
      contactDescription:'Parent Contact Missing Group'

      
    },
    {
     
      
      contactType:'CSR',
      contactDescription:'Customer Service Rep'

      
    },

   
  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    // @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    // private dialogRef: MatDialogRef<AttributesComponent>
    
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      contactType:'',
      
    
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['tallys']);
 
 


  }

  
  ContactTypesChangeForm(element:any){
    this.matDialog.open(ContactTypesChangeComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });
 
  }

  
  ContactTypesAddForm(element:any){
    this.matDialog.open(ContactTypesAddComponent, {
      "width": '1000px',
      "maxHeight": '90vh',
      'data': element,
      "autoFocus": false                           
    });
 
  }
 

  


addAttribute(){

}

}
