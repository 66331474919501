import { Component, OnInit,Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { IheaderService } from '../../tallys/iheader.service';
import { ConsigeeService } from '../consigee.service';
import { CustomAlertService } from 'src/app/features/custom-alert/custom-alert.service';
import { CustomAlertComponent } from 'src/app/features/custom-alert/custom-alert.component';

@Component({
  selector: 'app-consignee-delete',
  templateUrl: './consignee-delete.component.html',
  styleUrls: ['./consignee-delete.component.css']
})
export class ConsigneeDeleteComponent implements OnInit {

  form!:FormGroup
  constructor(
    //public iHeaderSvc: IheaderService,
    public consigneeService: ConsigeeService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private router:Router,
    private CustomAlertService: CustomAlertService,
    private matDialog: MatDialog,

    private dialogRef: MatDialogRef<ConsigneeDeleteComponent>
  ) { }

  ngOnInit(): void {
    console.log(this.anyvariable)
    this.form= this.fb.group({
      ConsigneeCode:this.anyvariable.CONSCD,
      Name:this.anyvariable.CONAME,
      Address:this.anyvariable.COADD1,
      ZipCode:this.anyvariable.COZIP,
     
      
    })
  }

  result:any;
  user=localStorage.getItem('userName');

  deleteConsignee() {
    const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete consignee '+this.anyvariable.CONSCD+' ?');
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
   
    this.result = this.consigneeService
      .deleteConsignee(this.user, this.anyvariable.CONSCD)
      .subscribe((data: any) => {
        this.result = data;
        console.log( this.result);

        if(JSON.parse(this.result[0].data[1].value).success){
          //alert('Record Created SuccessFully');
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          this.router.navigate(['/main-nav/consignee/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
      });
    }
  });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/consignee/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
   // this.router.navigate(['/main-nav/tallys/read']);
  }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
