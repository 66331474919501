<mat-card class="over-Flow">
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Maintain Buildings</mat-card-title>
    </mat-card-header>

    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>
  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Building Number </mat-label>
            <input matInput formControlName="buildingNumber" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Building Name </mat-label>
            <input matInput formControlName="buildingName" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Address1 </mat-label>
            <input matInput formControlName="address1" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Address2 </mat-label>
            <input matInput formControlName="address2" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Zip Code </mat-label>
            <input matInput formControlName="zipcode" />
            <mat-error *ngIf="form.controls['zipcode'].hasError('pattern')">
              Only 5 <strong>numbers </strong> are allowed
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label> Suffix</mat-label>
            <input matInput formControlName="suffix" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Number of Doors </mat-label>
            <input matInput formControlName="NumberOfDoors" />
            <mat-error
              *ngIf="form.controls['NumberOfDoors'].hasError('pattern')"
            >
              Only <strong>numbers </strong> are allowed
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div>

     
      <button [disabled]="!(form.valid && form.dirty)"
            mat-button
            class="buttons addButton"
            mat-raised-button

            (click)="addBuilding()"

          >
        Add
      </button>
    </div>

            

    </form>
  </mat-card-content>
</mat-card>
