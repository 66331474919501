import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-general-invoice-details-add',
  templateUrl: './general-invoice-details-add.component.html',
  styleUrls: ['./general-invoice-details-add.component.css']
})
export class GeneralInvoiceDetailsAddComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<GeneralInvoiceDetailsAddComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      DGTASK:'',
      DGLEGC:'',
      DGQTY:'',
      DGRATE:'',
      DGBASE:'',
      DGAMT:'',
      DGMINM:'',
      DGDESC:'',
      DGDTYP:'',
      DGDOCN:''
    })
  }

  add(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }


}
