<mat-card>
  <div class="main-header-title">
    <mat-card-header>
      <mat-card-title>Item Master Maintenance</mat-card-title>
    </mat-card-header>
    <button mat-icon-button (click)="closeDialog()">
      <mat-icon mat-icon-button color="primary">close</mat-icon>
    </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Storer Number </mat-label>
            <input readonly matInput formControlName="storeNumber" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Suffix </mat-label>
            <input readonly matInput formControlName="suffix" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Item Code</mat-label>
            <input matInput formControlName="item" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Description1</mat-label>
            <input matInput formControlName="itemDescription1" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Description2</mat-label>
            <input matInput formControlName="itemDescription2" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>UPC</mat-label>
            <input matInput formControlName="itemUpcCode" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Tail Code</mat-label>
            <input matInput formControlName="itemUpcTailCode" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Product Code</mat-label>
            <input matInput formControlName="manufacturerProdCode" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Allow Web Selection </mat-label>
            <mat-select formControlName="quickItem">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lotted Item </mat-label>
            <mat-select formControlName="lottedItem" [disabled]="true">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
            <mat-hint> *This is not editable</mat-hint>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Lot Unit Weight </mat-label>
            <mat-select formControlName="lotUnitWeight">
              <mat-option value="Y">Yes</mat-option>
              <mat-option value="N">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Sort Mask </mat-label>
            <input matInput formControlName="lotSortMask" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Apply To Lot </mat-label>
            <input matInput formControlName="primSortMaskPointer" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Place After </mat-label>
            <input matInput formControlName="prevItem" />
          </mat-form-field>
        </div>
      </div>
      <button class="buttons addButton" mat-raised-button (click)="itemUpdate1()">
        Update
      </button>
    </form>
  </mat-card-content>
</mat-card>
