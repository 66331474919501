import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-ucc-label-ctrl-file',
  templateUrl: './ucc-label-ctrl-file.component.html',
  styleUrls: ['./ucc-label-ctrl-file.component.css'],
})
export class UccLabelCtrlFileComponent implements OnInit {
  displayedColumns: string[] = [
    'Storer',
    'Form',
    'LblType',
    'MessageFile',
    'ValidityProgram',
    'LabelName',
    'OutputQueue',
  ];
  form!: FormGroup;

  dataSource: any = [];
  duplicateSource: any = [
    {
      Storer: [null],
      Form: [null],
      LblType: [null],
      MessageFile: [null],
      ValidityProgram: [null],
      LabelName: [null],
      OutputQueue: [null],
    },
  ];

  constructor(
    private activeRouter: ActivatedRoute,
    private router: Router,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<UccLabelCtrlFileComponent>
  ) {}
  ngOnInit(): void {
    this.form = this.fb.group({
      Consignee: [null],
      Storer: [null],
    });
    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  @ViewChild('paginator') paginator!: MatPaginator;
  workHeaders: any = [];

  reasonCode() {
    console.log('Reason Code');
  }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
