import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-validity-character-removal-change',
  templateUrl: './validity-character-removal-change.component.html',
  styleUrls: ['./validity-character-removal-change.component.css']
})
export class ValidityCharacterRemovalChangeComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ValidityCharacterRemovalChangeComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      storerNumber:this.anyvariable.storerNumber,
      scanType:this.anyvariable.scanType,
      qualifierStart:'',
      qualifierLength:'',
      qualifier:this.anyvariable.qualifier,
      fieldStartPos:'',
      lengthofField:this.anyvariable.lengthofElement,
      status:this.anyvariable.status
    })
  }

  change(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
