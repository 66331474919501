<!-- <mat-card >-->
<!--<div class="buttons"> 
    <button class="backButton" mat-button (click)="onBack()">
      <mat-icon>navigate_before </mat-icon>
      <span>Tally</span>
    </button>
  </div>-->

<!-- <mat-grid-list cols="1" rows="1" rowHeight="2:1">
    <mat-grid-tile class="over-Flow"> -->
<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<form class="over-Flow" [formGroup]="updateEmailExitPointsForm">
  <mat-card class="over-Flow">
    <mat-card-header>
      <mat-card-title> Update an Email Exit point</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Email Exit Point </mat-label>
            <input matInput required formControlName="EPPOIN" />
            <mat-error>
              email exit point is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Message Format </mat-label>
            <input
              matInput
              formControlName="EPEFMT"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Control No Type </mat-label>
            <input
              matInput
              formControlName="EPNTYP"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Contact Type </mat-label>
            <input
              matInput
              required
              formControlName="EPCTYP"
              style="text-transform: uppercase"
            />
            <!-- <mat-error> Zip Code From is <strong>required</strong> </mat-error> -->
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Message Name </mat-label>
            <input matInput formControlName="EPMNAM" />
            <!-- <mat-error> Phone Number is <strong>required</strong> </mat-error> -->
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label>Message Builder </mat-label>
            <input
              matInput
              formControlName="EPMBLD"
              style="text-transform: uppercase"
            />
          </mat-form-field>

          <mat-form-field appearance="outline" class="full-width">
            <mat-label> Status Code </mat-label>
            <input matInput formControlName="EPSTAT" />
          </mat-form-field>
        </div>
      </div>
      <button class="buttons addButton" (click)="update()" mat-raised-button>
        Update
      </button>
    </mat-card-content>
  </mat-card>
</form>
<!-- </mat-grid-tile>
                                    </mat-grid-list>
                                  </mat-card> -->
