<mat-card class="over-Flow">
    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Order Comments Maintenance</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
  
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline">
              <mat-label>Bill of Lading</mat-label>
              <input matInput formControlName="bol" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Sequence</mat-label>
              <input matInput formControlName="seq" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Item</mat-label>
              <input matInput formControlName="item" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="full-width w-100">
                <mat-label>Comments 1 :</mat-label>
                <textarea matInput
                formControlName="comment1"> Comments 1: </textarea>
                <input
                        matInput
                        formControlName="comment1"
                      />
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label> Print1: </mat-label>
                <mat-select formControlName="print1">
                  <mat-option value="Y"> Yes </mat-option>
                  <mat-option value="N"> No </mat-option>
                </mat-select>
              </mat-form-field>


              <mat-form-field appearance="outline" class="full-width w-100">
                <mat-label>Comments 2 :</mat-label>
                <textarea matInput
                formControlName="comment2"> Comments 2: </textarea>
                <input
                        matInput
                        formControlName="comment2"
                      />
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-width">
                <mat-label> Print2: </mat-label>
                <mat-select formControlName="print2">
                  <mat-option value="Y"> Yes </mat-option>
                  <mat-option value="N"> No </mat-option>
                </mat-select>
              </mat-form-field>

          </div>
        </div>
  
       
        <button mat-button (click)="changeEventComments()" class="buttons addButton">        Update
      </button>

      </form>
    </mat-card-content>
    
  </mat-card>
  