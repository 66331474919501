<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title 
                    >Maintain Carriers</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> SCACCode: </mat-label> 
                          <input matInput formControlName="SCACCode" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label>CarrierName: </mat-label> 
                          <input matInput formControlName="CarrierName" />
                        </mat-form-field>
                  </div> 
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Carrier Address: </mat-label> 
                      <textarea matInput formControlName="CarrierAddress" ></textarea> 
                    </mat-form-field>
                    </div>

                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label>Address: </mat-label> 
                      <textarea matInput formControlName="Address" ></textarea> 
                    </mat-form-field>
                    </div>
                    <div class="col">
                        <mat-form-field appearance="outline">  <mat-label>Zip / Postal / Code: </mat-label> 
                          <input matInput formControlName="ZipPostalCode" />
                        </mat-form-field>
                        </div>
                        <div class="col">
                            <mat-form-field appearance="outline">  <mat-label>FuelSurchargeTBL: </mat-label> 
                              <input matInput formControlName="FuelSurchargeTBL" />
                            </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="outline">  <mat-label> HazardousMaterials  </mat-label> 
                                    <mat-select matNativeControl required>
                                    <mat-option value="Item">Y</mat-option>
                                    </mat-select> 
                                </mat-form-field>
                             </div>
                             <div class="col">
                                <mat-form-field appearance="outline">  <mat-label> PalletExchange  </mat-label> 
                                    <mat-select matNativeControl required>
                                    <mat-option value="Item">Y</mat-option>
                                    </mat-select> 
                                </mat-form-field>
                             </div>
                             <div class="col">
                                <mat-form-field appearance="outline">  <mat-label> SummaryPayments  </mat-label> 
                                    <mat-select matNativeControl required>
                                    <mat-option value="Item">Y</mat-option>
                                    </mat-select> 
                                </mat-form-field>
                             </div>
                             <div class="col">
                                <mat-form-field appearance="outline">  <mat-label>ChangeDate: </mat-label> 
                                  <input matInput formControlName="ChangeDate" />
                                </mat-form-field>
                                </div>
                                <div class="col">
                                    <mat-form-field appearance="outline">  <mat-label>ChangedBy: </mat-label> 
                                      <input matInput formControlName="ChangedBy" />
                                    </mat-form-field>
                                    </div>
                                    <div class="col">
                                        <mat-form-field appearance="outline">  <mat-label> Status  </mat-label> 
                                            <mat-select matNativeControl required>
                                            <mat-option value="Item">AC</mat-option>
                                            </mat-select> 
                                        </mat-form-field>

                                      
                                            <button mat-button class="buttons addButton" (click)="attributes()">Change</button>
                                        
                                     </div>
                            
                        
                </mat-card-content>
              </mat-card>
            </form>  
