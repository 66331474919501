import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../iheader.service';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CreateTallyDtlPutawayLocComponent } from './create-tally-dtl-putaway-loc/create-tally-dtl-putaway-loc.component';
import { UpdateTallyDtlPutawayLocComponent } from './update-tally-dtl-putaway-loc/update-tally-dtl-putaway-loc.component';
import Swal from 'sweetalert2';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalComponent } from 'src/app/Global-Component';
import { CustomAlertService } from '../../custom-alert/custom-alert.service';
@Component({
  selector: 'app-tally-detail-putaway-location',
  templateUrl: './tally-detail-putaway-location.component.html',
  styleUrls: ['./tally-detail-putaway-location.component.css']
})
export class TallyDetailPutawayLocationComponent implements OnInit {

  form!: FormGroup;
  searchTextDisplay = false;
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private router:Router,
    private matDialog: MatDialog,
    private CustomAlertService : CustomAlertService
    //@Inject(MAT_DIALOG_DATA) public anyvariable: any,
   // private dialogRef: MatDialogRef<TallyDetailPutawayLocationComponent>
  ) {}
  
  user=localStorage.getItem('userName');
  result:any;

  displayedColumns: string[] = [ 'location', 'normal', 'Damaged', 'Held', 'user1','user2', 'user3','user4', 'Actions'];
  dataSource:any;
  duplicateSource:any;
  anyvariable:any
  @ViewChild('paginator') paginator!: MatPaginator;

  ngOnInit(): void {

    this.anyvariable= history.state;

    console.log(this.anyvariable);

    this.form= this.fb.group({
      tally:this.anyvariable[0].IDTALY,
      line:this.anyvariable[0].IDSEQ,
      building:localStorage.getItem('building'),
      item:this.anyvariable[0].IDITEM,
      description:this.anyvariable[0].IDTALY,
      lotCode1:this.anyvariable[0].IDLOT,
      lotCode2:this.anyvariable[0].IDLTC1,
      lotCode3:this.anyvariable[0].IDLTC2,
      Quantity:this.anyvariable[0].IDQINT,
      over:'',
      short:''
    })

    this.iHeaderSvc
    //.getTallyDetails(this.user, this.element.IHTALY, this.element.IHACDL, this.element.IHSEQ)
    .getTallyPutwayLocations(this.user, this.anyvariable[0].IDTALY,this.anyvariable[0].IDSEQ)
    .subscribe((data) => {
      console.log('DATA')
      console.log(data);
      this.result = data;
    //  this.TallyHeaders = data;
      
       var res = data;
    //  console.log(res);

   var res = this.result[0].data[1]?.value;
   // console.log(res);
    console.log('GET ALL DATA');
    var parsedData = JSON.parse(res);
    this.duplicateSource = parsedData.returnedData;
    console.log(this.duplicateSource);

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    //  this.duplicateSource=this.dataSource;
      console.log(this.dataSource);
      this.dataSource = new MatTableDataSource(this.duplicateSource);
      this.dataSource.paginator = this.paginator;
    });




    //this.addlocations();
  }

  locations() : FormArray {  
    return this.form.get("locations") as FormArray  
  }  

  newLocation(): FormGroup {  
    return this.fb.group({  
      location: '',  
      normal: '',  
      damaged:'',
      held:'',
      user1:'',
      user2:'',
      user3:'',
      user4:''
    })  
  }  

  addlocations() {  
    this.locations().push(this.newLocation());  
  }  
  
  searchPutAwayLocation() {
    if (this.searchTextDisplay == true) this.searchTextDisplay = false;
    else this.searchTextDisplay = true;
  }
  
  removeLocations(i:number) {  
    this.locations().removeAt(i);  
  }  

  closeDialog(){
    // Write your stuff here
    //this.dialogRef.close(); // <- Close the mat dialog
  }

  onBack(){
    this.router.navigate(['/main-nav/tallys/details']);
  }

  createPutawayLocation(){
      var res = this.anyvariable[0];
      var data = history.state;
      this.matDialog.open(CreateTallyDtlPutawayLocComponent, {
        
        width: '1000px',
        maxHeight: '80vh',
  
        data: res,
        autoFocus: false,
      });
    
  }

  updatePutawayLocation(putaway:any){
    var res = this.anyvariable[0];
    var res2 = putaway;
    var data=[res,res2];
    this.matDialog.open(UpdateTallyDtlPutawayLocComponent, {
      
      width: '1000px',
      maxHeight: '80vh',

      data: data,
      autoFocus: false,
    });
}

deletePutawayLocation(putwayLocation:any){
 // if(confirm('Are you sure want to delete Tally No: ? '+putwayLocation.ILTALY)){
  const dialogRef = this.CustomAlertService.openCustomAlert('Are you sure want to delete Tally No:'+putwayLocation.ILTALY +' ?');
  
  dialogRef.afterClosed().subscribe((result: any) => {
  if (result) {
    this.result = this.iHeaderSvc
    .deleteServicePutawayLoc(
      
      this.user, 
      putwayLocation.ILACDL,
      putwayLocation.ILTRTY, 
      putwayLocation.ILTALY, 
      putwayLocation.ILSEQ,
      putwayLocation.ILITEM,
      putwayLocation.ILLTC1,
      putwayLocation.ILLTC2,
      putwayLocation.ILLTC3,
      putwayLocation.ILFLOR,
      GlobalComponent.fullSlotAisl(putwayLocation.ILAISL),
      GlobalComponent.fullSlotAisl(putwayLocation.ILSLOT)
      
      )
    .subscribe((data: any) => {
      this.result = data;
      console.log(this.result);

      if (JSON.parse(this.result[0].data[1].value).success) {
        const msg = JSON.parse(this.result[0].data[1].value).returnedData
          .message;
        this.successMessage(msg);
        this.closeDialog();
      
      } else {
        const msg = JSON.parse(this.result[0].data[1].value).returnedData
          .message;
        this.failureMessage(msg);
      
      }
    });

  }
});
}

successMessage(msg:any){
  Swal.fire(msg,'','success');
 this.closeDialog();
  this.router.navigate(['/main-nav/putawayLocation/read']);
 // this.router.navigate(['/main-nav/tallys/read']);
}

failureMessage(msg:any){
  Swal.fire(msg,'','error');
 // this.router.navigate(['/main-nav/tallys/read']);
}

    
}
