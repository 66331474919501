import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-extract-reports-edit',
  templateUrl: './extract-reports-edit.component.html',
  styleUrls: ['./extract-reports-edit.component.css']
})
export class ExtractReportsEditComponent implements OnInit {

  form!: FormGroup;
  
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ExtractReportsEditComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      extractName:'ACTIVESRL',
      description:'Active Serial Extract',
      callingProgram:'EXRPLOCAT',
      outputType:'CSV',
      ifsLocation:'*WEB{STOR}',
    })
  }

  editExtractReports(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
