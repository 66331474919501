<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
       Event History Inquiry</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>item</mat-label>
                      <input matInput formControlName="item"/>
                      </mat-form-field>
                      <div ><label for="">From/To Date</label></div>
                      
                      <mat-form-field appearance="outline">
                       <mat-label>fromDate:</mat-label>
                       <input matInput formControlName="fromDate"/>
                       </mat-form-field>
                      
                       <mat-form-field appearance="outline">
                        <mat-label>toDate:</mat-label>
                       <input matInput formControlName="toDate"/>
                       </mat-form-field>
                    
                     </div>
                     <div class="col">
                      <mat-form-field appearance="outline">
                        <mat-label>customer</mat-label>
                       <input matInput formControlName="customer"/>
                       </mat-form-field>
                       
                      <mat-form-field appearance="outline">
                        <mat-label>box</mat-label>
                       <input matInput formControlName="box"/>
                       </mat-form-field>
                     </div>
                   
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="date">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            date
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.date }}</td>
        </ng-container>
  
        <ng-container matColumnDef="time">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            time
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.time }}</td>
        </ng-container>
  
        <ng-container matColumnDef="opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
              
          <!-- <mat-option>
            <button mat-icon-button (click)="BoxContentsDeactiveForm(element)">
              <mat-icon mat-icon-button color="warn">deactive</mat-icon>
              <mat-label>DEACTIVE</mat-label>
            </button>
          </mat-option> -->
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>