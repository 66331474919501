<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Maintain Labor Plan</mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form" fxLayoutAlign="center">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Document Type</mat-label>
                    <mat-select  formControlName="LBDOCT">                  
                        <mat-option value="A">Appointment</mat-option>
                        <mat-option value="O">Outbound </mat-option>
                        <mat-option value="I">Inbound</mat-option>
                      </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Document Number</mat-label>
                    <input   matInput formControlName="LBDOCN" />
                </mat-form-field>
            </div> 



            <div class="col" >
                <mat-form-field appearance="outline">
                    <mat-label>Must Start Date</mat-label>
                    <input type="date" matInput formControlName="MDATE"  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Must Start Time</mat-label>
                    <input type="time" matInput formControlName="MTIME"  />
                </mat-form-field>

               
            </div>
 
            <div class="col"> 
                <mat-form-field appearance="outline">
                    <mat-label>Stage Location</mat-label>
                    <input  matInput formControlName="LBLOCN"  />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>WorkFlow Process</mat-label>
                    <input matInput formControlName="LBPROC"/>
                </mat-form-field>
            </div>

        </div>
    </form>
</mat-card-content>

<mat-card-footer>
    <button mat-button (click)="add()" class="addButton">           
        <mat-icon mat-icon-button color="primary">create</mat-icon>
        <mat-label>Labor Plan</mat-label>
    </button>
</mat-card-footer>

