<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Contact Types</mat-card-title>
    </mat-card-header>
    <mat-card-content>
                    <div class="formDiv">
                     <form [formGroup]="form">
                     <div class="row">
                     <mat-form-field appearance="outline">
                       <mat-label>ContactType:</mat-label>
                      <input matInput formControlName="contactType"/>
                      </mat-form-field>
  
                       </div>
                     </form>
  
           <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
             <ng-container matColumnDef="contactType">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Contact Type
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.contactType }}</td>
        </ng-container>
  
        <ng-container matColumnDef="contactDescription">
          <th mat-header-cell mat-header-cell *matHeaderCellDef>
            Contact Description
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.contactDescription }}</td>
        </ng-container>
  
  
        <ng-container matColumnDef="opt">
          <th  mat-header-cell mat-header-cell *matHeaderCellDef>
            opt
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">

               <mat-option>
                <button mat-icon-button (click)="ContactTypesAddForm(element)">
                  <mat-icon mat-icon-button color="primary">call_made</mat-icon>
                  <mat-label> ADD</mat-label>
                </button>
            
              </mat-option>
              
          <mat-option>
            <button mat-icon-button (click)="ContactTypesChangeForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label> Change</mat-label>
            </button>
        
          </mat-option> 
               
         <!-- <mat-option>
            <button mat-icon-button (click)="ExpectedDurationsDeleteForm(element)">
              <mat-icon mat-icon-button color="primary">call_made</mat-icon>
              <mat-label>ExpectedDuration Delete</mat-label>
            </button>
        
          </mat-option>  -->
         
        

          

       
  
         
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
