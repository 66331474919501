<button mat-icon-button (click)="closeDialog()" class="closeIcon">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>
<form [formGroup]="form">
  <mat-card>
    <mat-card-header>
      <mat-card-title style="color: rgb(32, 68, 145)"
        >Maintain Files</mat-card-title
      >
    </mat-card-header>

    <mat-card-content>
      <div class="row">
        <div class="col">
          <mat-label>Library Name</mat-label>

          <mat-form-field appearance="outline">
            <input matInput formControlName="LibraryName" />
          </mat-form-field>
        </div>

        <div class="col">
          <mat-label>File Name</mat-label>

          <mat-form-field appearance="outline">
            <input matInput formControlName="FileName" />
          </mat-form-field>
        </div>
      </div>

      <div>
        <button mat-button class="buttonCss" (click)="add()">Add</button>
      </div>
    </mat-card-content>
  </mat-card>
</form>
