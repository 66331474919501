import { GlobalComponent } from './../../../Global-Component';
import { IbuildingsService } from './../ibuildings.service';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-building',
  templateUrl: './update-building.component.html',
  styleUrls: ['./update-building.component.css'],
})
export class UpdateBuildingComponent implements OnInit {
  building: any;
  form!: FormGroup;
  result: any;
  // user = GlobalComponent.user;

  user = localStorage.getItem('userName');
  constructor(
    private router: Router,
    public ibuildingSvc: IbuildingsService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdateBuildingComponent>
  ) {}

  //num=`\"BUILD#\"`;

  ngOnInit(): void {
    this.form = this.fb.group({
      buildingNumber: this.anyvariable.BUILD,
      buildingName: this.anyvariable.BUNAME,
      address1: this.anyvariable.BUADD1,
      address2: this.anyvariable.BUADD2,
      zipcode: this.anyvariable.BUZIP,
      suffix: this.anyvariable.BUZSFX,

      NumberOfDoors: [
        this.anyvariable.BUDOOR,
        [Validators.min(0), Validators.pattern('[0-9]{1,10}')],
      ],
    });
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

  updateBuilding() {
    var res = this.form.value;
    for (var i = res.buildingNumber.length; i < 3; i++) {
      res.buildingNumber = ' ' + res.buildingNumber;
    }

    this.result = this.ibuildingSvc
      .updateBuilding(
        this.user,
        res.buildingNumber,
        res.buildingName,
        res.address1,
        res.address2,
        res.zipcode,
        res.suffix,
        res.NumberOfDoors
      )
      .subscribe((data: any) => {
        this.result = data;
        console.log(this.result);
        //var msg = JSON.parse(this.result).returnedData.message;

        // this.successMessage(msg);
        if (JSON.parse(this.result[0].data[1].value).success) {
          //alert('Record Created SuccessFully');
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.successMessage(msg);
          this.closeDialog();
          this.router.navigate(['/main-nav/building/read']);
        } else {
          const msg = JSON.parse(this.result[0].data[1].value).returnedData
            .message;
          this.failureMessage(msg);
        }
      });
  }
  successMessage(msg: any) {
    Swal.fire(msg, '', 'success');
    //this.router.navigate(['/main-nav/building/read']);
  }

  failureMessage(msg: any) {
    Swal.fire(msg, '', 'error');
    // this.router.navigate(['/main-nav/tallys/read']);
  }
}
