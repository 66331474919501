import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalComponent } from 'src/app/Global-Component';
import Swal from 'sweetalert2';
import { IitemService } from '../iitem.service';

@Component({
  selector: 'app-item-create4',
  templateUrl: './item-create4.component.html',
  styleUrls: ['./item-create4.component.css'],
})
export class ItemCreate4Component implements OnInit {
  form!: FormGroup;
  result: any;

  constructor(
    private fb: FormBuilder,
    private activeRouter: ActivatedRoute,
    public itemSvc: IitemService,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<ItemCreate4Component>,
    private router:Router,
    private matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    console.log(this.anyvariable);
    this.form = this.fb.group({
      //Item: this.anyvariable.ITEM + '\xa0' + this.anyvariable.ITDSC1,
      Receiving: '',
      Regular: '',
      Recurring: '',
      Distribution: '',
      ItemMinimum: '',
      Alternate: '',
      LotMinimum: '',
      ItemMinimum1: '',
      LotMinimum1: '',

      storeNumber: this.anyvariable.storeNumber,
      suffix: this.anyvariable.suffix,
      item: this.anyvariable.item + '\xa0' + this.anyvariable.itemDescription1,

      rcvStorageRate: '', //Receiving Rate
      rcvStorageBase: '', //Receiving Basis
      rcvStorageMin: '', //Receiving Minimum

      rcrStorageRate: '', //Recurring Rate
      rcrStorageBase: '', //Recurring Basis
      rcrStorageMin: '', //Recurring Minimum

      regHandlingRate: '', //Regular Rate
      regHandlingBase: '', //Regular Basis
      regHandlingMin: '', //Regular Minimum

      distHandlingRate: '', //Distribution Rate
      distHandlingBase: '', //Distribution Basis
      distHandlingMin: '', //Distribution Minimum

      altHandlingRate: '', //Alternate Rate
      altHandlingBase: '', //Alternate Basis
      altHandlingMin: '', //Alternate Minimum

      insLiabilityAmount: '' //Insurance Liability Amount
    });
  }

  createItem4(){
    var res = this.form.value;
    
   
    this.result = this.itemSvc
      .itemCreate4( 
        this.anyvariable.storeNumber,
        this.anyvariable.suffix,
        this.anyvariable.item,

        res.rcvStorageRate,
        res.rcvStorageBase,
        res.rcvStorageMin,

        res.rcrStorageRate,
        res.rcrStorageBase,
        res.rcrStorageMin,

        res.regHandlingRate,
        res.regHandlingBase,
        res.regHandlingMin,

        res.distHandlingRate,
        res.distHandlingBase,
        res.distHandlingMin,

        res.altHandlingRate,
        res.altHandlingBase,
        res.altHandlingMin,

        res.insLiabilityAmount
       

        ).subscribe((data: any) => {
        this.result = data;
        console.log('Created Tally Detail');
        console.log( this.result,'*******');
       // console.log(JSON.parse(this.result).returnedData.messaage);

       console.log(this.result[0].data.length);
          console.log("*********************");

       //console.log(this.result[0].data[23].value);
       console.log("*********************");

       var length = this.result[0].data.length;

       // if(JSON.parse(this.result[0].data[1].value).success){
          if(this.result[0].data[length-2].value=='' && this.result[0].data[length-1].value=='Created/Updated'){
          //alert('Record Created SuccessFully');
         const msg= this.result[0].data[length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this. created();
         // this.router.navigate(['/main-nav/tallys/read']);
       }else{
        const msg= this.result[0].data[length-1].value;
        this.failureMessage(msg);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
    this.router.navigate(['/main-nav/item/read']);
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
    this.router.navigate(['/main-nav/item/read']);
  }

  created() {
    console.log("CREATED...");
    console.log(this.anyvariable);
  }

  closeDialog() {
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
