<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card>
  <mat-card-header>
    <mat-card-title>Data Element Code</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Data Element Code </mat-label>
            <input matInput formControlName="dataElementCode" />
          </mat-form-field>
        </div>

        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Data Element Definition </mat-label>
            <input matInput formControlName="dataElementDefinition" />
          </mat-form-field>
        </div>
      </div>
    </form>
    <div>
      <p>Press Enter to confirm Delete</p>
      <button mat-button (click)="deleteDataElement()" class="deleteButton">
        Delete
      </button>
      <br />
    </div>
  </mat-card-content>
</mat-card>
