import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StorerCreate4Component } from '../storer-create4/storer-create4.component';
import { StorerServiceService } from '../storer-service.service';

@Component({
  selector: 'app-storer-create3',
  templateUrl: './storer-create3.component.html',
  styleUrls: ['./storer-create3.component.css']
})
export class StorerCreate3Component implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerCreate3Component>,
   private matDialog: MatDialog
    ) { }

  ngOnInit(): void {

    console.log(this.anyvariable.storerNumber);
       this.form = this.fb.group({

        storeNumber: this.anyvariable.storeNumber, // STORE#
        suffix: this.anyvariable.suffix, // STSFX
        storeName: this.anyvariable.storeName, // STNAME

        defaultFreightCharge:[
          '',
          [
            Validators.required
            
          ],
        ], // STFRCH
        defaultCODCharge:'', // STCODC
        deferredHandlingPer:[
          '',
          [
            Validators.required
            
          ],
        ], // STDEFH

        rcrStorageRate:'', // STMSRT
        rcrStorageBasis:'', // STMSBA

        recStorageRate:'', // STRSRT
        recStorageBasis:'', // STRSBA

        regHandStorageRate:'', // STRHRT
        regHandStorageBasis:'', // STRHBA

        altHandStorageRate:'', // STAHRT
        altHandStorageBasis:'', // STAHBA

        distHandStorageRate:'', // STDHRT
        distHandStorageBasis:'', // STDHBA
        
        insLiability:'', // STILCA
        amount:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STINSL

        wrLotMinimum:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STWRLM
        storageLotMin:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STLTMI
        wrItemHdlMin:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STWRIM

        storageItemMin:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STITMI
        wrItemStgMin:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STWISM
        storageInvoiceMin:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STINMI

        wrStorageMin:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STSTMI
        wrHandlingMin:[
          '',
          [
            
            Validators.min(0),
            Validators.pattern('[0-9]{1,10}'),
          ],
        ], // STHDMI
       });
  }

  storerCreate3(){
    var res = this.form.value;   
    this.result = this.storerSvc
      .storerServiceCreate3( 
        res.storeNumber,
        res.suffix,
        res.storeName,
        res.defaultFreightCharge,
        res.defaultCODCharge,
        res.deferredHandlingPer,
        res.rcrStorageRate,
        res.rcrStorageBasis,
        res.recStorageRate,
        res.recStorageBasis,
        res.regHandStorageRate,
        res.regHandStorageBasis,
        res.altHandStorageRate,
        res.altHandStorageBasis,
        res.distHandStorageRate,
        res.distHandStorageBasis,
        res.insLiability,
        res.amount,
        res.wrLotMinimum,
        res.storageLotMin,
        res.wrItemHdlMin,
        res.storageItemMin,
        res.wrItemStgMin,
        res.storageInvoiceMin,
        res.wrStorageMin,
        res.wrHandlingMin,
        this.user

      ).subscribe((data: any) => {
        this.result = data;
        if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Successfully Updated'){
          const msg= this.result[0].data[this.result[0].data.length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.create4();
       }else{
        this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  create4() {
    var res = this.form.value;
    this.matDialog.open(StorerCreate4Component, {      
      width: '1000px',
      maxHeight: '80vh',
      data: res,
      autoFocus: false,
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
