
            <mat-card class="over-Flow">
                <mat-card-header>  
                  <mat-card-title  class="title" >
              
                    Work With validation Event</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <form [formGroup]="form" class="form over-Flow">
                        <div class="row">
                          <div class="col">
                            <mat-form-field appearance="outline">
                                <mat-label>Storer:</mat-label>
                                  <input matInput formControlName="STOR"/>
                              </mat-form-field>
                
                              <mat-form-field appearance="outline">
                                <mat-label>Consignee:</mat-label>
                                  <input matInput formControlName="CONS"/>
                              </mat-form-field>
                
                              <mat-form-field appearance="outline">
                                <mat-label>Document Type:</mat-label>
                                  <input matInput formControlName="DOCT"/>
                              </mat-form-field>
                
                              <mat-form-field appearance="outline">
                                <mat-label>No:</mat-label>
                                  <input matInput formControlName="DOCN"/>
                              </mat-form-field>
                
                              <mat-form-field appearance="outline">
                                <mat-label>Reference No:</mat-label>
                                  <input matInput formControlName="SREF"/>
                              </mat-form-field>
                
                              <mat-form-field appearance="outline">
                                <mat-label>Msg ID:</mat-label>
                                  <input matInput formControlName="MSGD"/>
                              </mat-form-field>
                              <mat-form-field appearance="outline">
                                <mat-label>Sev:</mat-label>
                                  <input matInput formControlName="SEVR"/>
                              </mat-form-field>
                               <button
                                class="addButton"
                                   mat-raised-button
                                      type="submit"
                                      placeholder="Search">
                                      Search
                                </button>
                              </div>
                            </div>
                          </form>           
                </mat-card-content>
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="Opt">
                  <th mat-header-cell mat-header-cell *matHeaderCellDef>Opt</th> 
                  <td mat-cell *matCellDef="let element">{{ element.OPTION }}</td>
                </ng-container>
        
                <ng-container matColumnDef="Storer">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>Storer</th> 
                    <td mat-cell *matCellDef="let element">{{ element.EESTOR }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Consignee">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>Consignee</th> 
                    <td mat-cell *matCellDef="let element">{{ element.MSGTX}}</td>
                  </ng-container>
                  <ng-container matColumnDef="Type">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>Type</th> 
                    <td mat-cell *matCellDef="let element">{{ element.EEDOCT }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Number">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>Number </th> 
                    <td mat-cell *matCellDef="let element">{{ element.EEDOCN}}</td>
                  </ng-container>
                  <ng-container matColumnDef="Seq Storer Reference No">
                    <th mat-header-cell mat-header-cell *matHeaderCellDef>Seq Storer Reference No</th> 
                    <td mat-cell *matCellDef="let element">{{ element.EEDSEQ }}</td>
                  </ng-container>
                  <ng-container matColumnDef="Actions">
                    <th  mat-header-cell mat-header-cell *matHeaderCellDef>
                      Actions
                    </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-select  placeholder="Select Option">
                 
                      <mat-option>
                      <button mat-icon-button (click)="ValiationEventErrorDetailsForm(element)">
                        <mat-icon mat-icon-button color="primary"><details></details></mat-icon>
                        <mat-label>Errordetails</mat-label>
                      </button>
                    </mat-option>
                    <mat-option>
                      <button mat-icon-button (click)="ValidationEventDeleteForm(element)">
                        <mat-icon mat-icon-button color="primary">delete</mat-icon>
                        <mat-label>Delete</mat-label>
                      </button>
                    </mat-option> 
                      
                    </mat-select>
                    </td>
                  </ng-container>
                  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
                 
              </table>
              <mat-paginator
              #paginator
              [pageSizeOptions]="[4, 8, 16]"
              showFirstLastButtons
            ></mat-paginator>
