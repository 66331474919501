<mat-card-header>
    <mat-card-title fxLayoutAlign="center">Maintain System Comments </mat-card-title>
</mat-card-header>

<mat-card-content>
    <form [formGroup]="form">
        <div class="row">
            <mat-form-field appearance="fill">
                <mat-label>File Id</mat-label>
                <input matInput formControlName="FileId" />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Key</mat-label>
                <input matInput formControlName="Key" />
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Sub</mat-label>
                <input matInput formControlName="Sub" />
            </mat-form-field>
        </div>
        
      
       
        <div class="row">
            <mat-form-field appearance="outline">
                <mat-label>Comment</mat-label>
                <textarea matInput placeholder="Comment" formControlName="Comment"></textarea>
            </mat-form-field>
        </div>
        
    </form>
</mat-card-content>
