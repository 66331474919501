<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title">Work With General Invoices</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="buttons">
      <button mat-button (click)="invoiceCreate()" class="addButton buttons">
        <mat-icon>add</mat-icon>
        <span>Create General Invoice</span>
      </button>
    </div>

    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Control </mat-label>
            <input matInput formControlName="Control" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Customer </mat-label>
            <input matInput formControlName="Customer" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Dates </mat-label>
            <input matInput formControlName="Dates" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Invoice </mat-label>
            <input matInput formControlName="Invoice" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Ref No </mat-label>
            <input matInput formControlName="RefNo" />
          </mat-form-field>
          <mat-form-field appearance="outline"
            ><mat-label> Building </mat-label>
            <input matInput formControlName="Building" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Status </mat-label>
            <input matInput formControlName="Status" />
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table [dataSource]="dataSource" mat-table class="mat-elevation-z8">
    <ng-container matColumnDef="ControlNumber">
      <th mat-header-cell *matHeaderCellDef width="60px">Control Number</th>
      <td mat-cell *matCellDef="let element">{{ element.ControlNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="St">
      <th mat-header-cell *matHeaderCellDef width="60px">Status</th>
      <td mat-cell *matCellDef="let element">{{ element.St }}</td>
    </ng-container>

    <ng-container matColumnDef="Tp">
      <th mat-header-cell *matHeaderCellDef width="60px">Tp</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Tp }}
      </td>
    </ng-container>
    <ng-container matColumnDef="Customer">
      <th mat-header-cell *matHeaderCellDef width="160px">Customer</th>
      <td mat-cell *matCellDef="let element">
        {{ element.Customer }}
      </td>
    </ng-container>
    <ng-container matColumnDef="InvoiceDate">
      <th mat-header-cell *matHeaderCellDef width="80px">Invoice Date</th>
      <td mat-cell *matCellDef="let element">
        {{ element.InvoiceDate }}
      </td>
    </ng-container>
    <ng-container matColumnDef="InvoiceAmount">
      <th mat-header-cell *matHeaderCellDef width="80px">Invoice Amount</th>
      <td mat-cell *matCellDef="let element">${{ element.InvoiceAmount }}</td>
    </ng-container>
    <ng-container matColumnDef="InvoiceNumber">
      <th mat-header-cell *matHeaderCellDef width="80px">Invoice Number</th>
      <td mat-cell *matCellDef="let element">
        {{ element.InvoiceNumber }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell mat-header-cell width="190px" *matHeaderCellDef>
        Actions
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-select class="mat-select" placeholder="Select Option">
          <mat-option>
            <button mat-icon-button (click)="invoiceUpdate()">
              <mat-icon mat-icon-button color="warn">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button></mat-option
          >

          <mat-option>
            <button mat-icon-button (click)="invoiceDelete()">
              <mat-icon mat-icon-button color="secondary">delete</mat-icon>
              <mat-label>Delete</mat-label>
            </button></mat-option
          >
        </mat-select>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
