import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductCodeChangeComponent } from './product-code-change/product-code-change.component';
import { ProductCodeCreateComponent } from './product-code-create/product-code-create.component';

@Component({
  selector: 'app-ww-product-codes',
  templateUrl: './ww-product-codes.component.html',
  styleUrls: ['./ww-product-codes.component.css']
})
export class WwProductCodesComponent implements OnInit {

  displayedColumns: string[] = ['storer', 'productCode', 'description', 'status', 
  'Actions'];
 form!: FormGroup;

 dataSource: any = [];
 duplicateSource: any = [
   {
     storer: 'APPT',
     productCode: '001',
     description:'LCSTOR',
      status:'',
     Actions:''

    


   },
 ];

 constructor(
   private activeRouter: ActivatedRoute,
   private router: Router,
   private matDialog: MatDialog,
   private fb: FormBuilder
 ) {}
 ngOnInit(): void {
   this.form = this.fb.group({
     storer: [null],
   
   });
   this.dataSource = new MatTableDataSource(this.duplicateSource);
   this.dataSource.paginator = this.paginator;
 }

 @ViewChild('paginator') paginator!: MatPaginator;

 productCodeCreate() {
   this.matDialog.open(ProductCodeCreateComponent, {
     width: '1000px',
     maxHeight: '80vh',

     // "data": storer,
     autoFocus: false,
   });
 }

 productCodeChange(element:any) {
   this.matDialog.open(ProductCodeChangeComponent, {
     width: '1000px',
     maxHeight: '80vh',

     // "data": storer,
     autoFocus: false,
   });
 }

//  workScheduleDelete(element :any) {
//    this.matDialog.open(WorkScheduleDeleteComponent, {
//      width: '1000px',
//      maxHeight: '80vh',

//      // "data": storer,
//      autoFocus: false,
//    });
//  }
 delete() {
   alert('deleted');
 }


}
