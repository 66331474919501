<mat-card>
    <mat-card-header>  
      <mat-card-title >
        <button class="backButton" mat-button (click)="onBack()">
          <mat-icon >navigate_before </mat-icon>
        </button>
  
        Work With Message Class Tables</mat-card-title>
    </mat-card-header>
  
    <mat-card-content>
  
  
      
      <div class="buttons">
        <button mat-button (click)="add()"  class="addButton">
          <mat-icon>add</mat-icon>
          <span>New Table</span>
        </button>
      </div>
     
     <div class="formDiv">
      <form [formGroup]="form">
        <div class="row">
          <mat-form-field appearance="outline">
            <mat-label>Document:</mat-label>
            <input matInput formControlName="DOCUMENT"/>
          </mat-form-field>
        </div>     
      </form>
     </div>
     
  
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="FILE">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Table
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.FILE }}</td>
        </ng-container>
  
        <ng-container matColumnDef="PATH">
          <th mat-header-cell mat-header-cell  *matHeaderCellDef>
            Table XPath
          </th> 
          <td mat-cell *matCellDef="let element">{{ element.PATH }}</td>
        </ng-container>

        <ng-container matColumnDef="Actions">
          <th  mat-header-cell mat-header-cell  *matHeaderCellDef>
            Actions
          </th>
          <td mat-cell *matCellDef="let element">
            <mat-select  placeholder="Select Option">
                
          <mat-option>
            <button mat-icon-button  (click)="details(element)">           
              <mat-icon mat-icon-button color="warn">call_made</mat-icon>
              <mat-label>Details</mat-label>
            </button>
          </mat-option>

          <mat-option>
            <button mat-icon-button  (click)="change(element)">           
              <mat-icon mat-icon-button color="primary">edit</mat-icon>
              <mat-label>Edit</mat-label>
            </button>
          </mat-option>

  
       
  
          </mat-select>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" ></tr>
  
        
      </table>
  
      <mat-paginator #paginator [pageSizeOptions]="[1,2]" showFirstLastButtons ></mat-paginator>
    </mat-card-content>
  </mat-card>



