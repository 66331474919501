import { ICarrierService } from './../i-carrier.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CarriersService } from '../carriers.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-carriers',
  templateUrl: './create-carriers.component.html',
  styleUrls: ['./create-carriers.component.css'],
})
export class CreateCarriersComponent implements OnInit {
  constructor(
    private router: Router,
    public icarSvc: CarriersService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private dialogRef: MatDialogRef<CreateCarriersComponent>
  ) {}

  form!: FormGroup;
  result: any;
  user=localStorage.getItem('userName');
  ngOnInit(): void {
    this.initializeForm();
  }
  initializeForm(){
    this.form = this.fb.group({
      // carrierCode: [null],
      // carrierName: [null],
      // address1: [null],
      // address2: [null],
      // suffix: [null],
      // pallet: false,
      // hazmat: true,
      // zipcode: [null],
      // billingFrequency: false,
      // carrierSCACCode: ['', [Validators.pattern('[a-zA-Z]{4}')]],
      // loadingAllowance: [null],
      // weight: ['', [Validators.pattern('[0-9]{1,3}'), Validators.max(100)]],
      // minAmount: ['', [Validators.pattern('[0-9]{1,10}'), Validators.min(0)]],
      // maxAmount: ['', [Validators.pattern('[0-9]{1,10}')]],

      carrierCode: [null,Validators.required],
      carrierName: [null,Validators.required],
      address1: [null],
      address2: [null],
      suffix: [null],
      pallet: [null,Validators.required],
      hazmat: [null,Validators.required],
      zipcode: [null,Validators.required],
      billingFrequency:[null,Validators.required],
      carrierSCACCode: [null],
      loadingAllowance: [null],
      weight: [null],
      minAmount: [null],
      maxAmount: [null],
    });
  }
  closeDialog() {
    this.dialogRef.close();
  }

  
    createCarrier() {
      var res = this.form.value;
      console.log(res);
  
      this.result = this.icarSvc
        .createCarrier(
          res.carrierCode,
          res.carrierName,
          res.address1,
          res.address2,
          res.zipcode,
          res.suffix,
          res.carrierSCACCode,
          res.hazmat,
          res.pallet,
          res.billingFrequency,
          res.loadingAllowance,
          res.weight,
          res.minAmount,
          res.maxAmount,
         this.user)
        .subscribe((data: any) => {
          this.result = data;
  
          console.log(this.result);
         // var msg = JSON.parse(this.result).returnedData.message;
  
         // this.successMessage(msg);
         if(JSON.parse(this.result[0].data[1].value).sucess){
          //alert('Record Created SuccessFully');
         const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
          this.successMessage(msg)
          this. closeDialog();
          this.router.navigate(['/main-nav/maplocation/read']);
       }else{
        const msg= JSON.parse(this.result[0].data[1].value).returnedData.message;
        this.failureMessage(msg);
       }
        });
    }
    successMessage(msg:any){
      Swal.fire(msg,'','success');
      this.router.navigate(['/main-nav/maplocation/read']);
    }
  
    failureMessage(msg:any){
      Swal.fire(msg,'','error');
     // this.router.navigate(['/main-nav/tallys/read']);
    }
  
  
}
