<button mat-icon-button (click)="closeDialog()" class="closeIcon">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                  <mat-card-title style="color: rgb(203, 207, 219)"> Item Location Inquiry</mat-card-title>
                </mat-card-header>
                
                <mat-card-content>  
                   
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> Item Number </mat-label> 
                        <input matInput formControlName="LCITEM" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label> Location</mat-label> 
                          <input matInput formControlName="LOCN" />
                        </mat-form-field>
                       
                      <mat-form-field appearance="outline">  <mat-label>Lot Code</mat-label> 
                          <input matInput formControlName="LOTS" />
                        </mat-form-field> 
                         
                      <mat-form-field appearance="outline">  <mat-label>First Recevied</mat-label> 
                        <input matInput formControlName="LCRCVD" />
                      </mat-form-field>
                  
                      <mat-form-field appearance="outline">  <mat-label>Lot Unit Weight</mat-label> 
                        <input matInput formControlName="LCLUWT" />
                      </mat-form-field>
                       
                      <mat-form-field appearance="outline">  <mat-label> Sort Sequence</mat-label> 
                        <input matInput formControlName="LCSRSQ" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label> Pallet H/d</mat-label> 
                        <input matInput formControlName="MAHETG" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label> Height</mat-label> 
                        <input matInput formControlName="MASLTH" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label>Width</mat-label> 
                        <input matInput formControlName="MASLTW" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label>Depth</mat-label> 
                        <input matInput formControlName="MASLTD" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label>Total Capacity</mat-label> 
                        <input matInput formControlName="TCAP" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label>Additional Capacity</mat-label> 
                        <input matInput formControlName="ADDL" />
                      </mat-form-field>
            
                        <mat-form-field appearance="outline">  <mat-label> On Hand</mat-label> 
                        <input matInput formControlName="ONHND" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label> Availaible</mat-label> 
                        <input matInput formControlName="LCQAVL" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label> Commited</mat-label> 
                        <input matInput formControlName="LCQCOM" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label>Held</mat-label> 
                        <input matInput formControlName="LCQHLD" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label> Damaged</mat-label> 
                        <input matInput formControlName="LCQDAM" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label>User1</mat-label> 
                        <input matInput formControlName="LCQUS1" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label>User2</mat-label> 
                        <input matInput formControlName="LCQUS2" />
                      </mat-form-field>

                      <mat-form-field appearance="outline">  <mat-label>User3</mat-label> 
                        <input matInput formControlName="LCQUS3" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">  <mat-label>User4</mat-label> 
                        <input matInput formControlName="LCQUS4" />
                      </mat-form-field>
                      </div>
                      
                         
                         <div >
                             <button mat-button class="buttonCss" (click)="attributes()">Inquiry</button>
                          </div>
                  
                        </mat-card-content> 
              </mat-card>
          </form>
                
              
           
