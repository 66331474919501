<button mat-icon-button (click)="closeDialog()">           
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
<mat-card>
    <mat-card-header>
   <mat-card-title style="color: rgb(203, 207, 219)">
       <button  mat-button (click)="onBack()">
           <mat-icon>navigate_before </mat-icon>
         </button>
       Stock Inquiry</mat-card-title>
    </mat-card-header>
<mat-card-content>

    <form [formGroup]="form">
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline">
                    <mat-label>Storer</mat-label>
                    <input matInput formControlName="STOR"/>
                </mat-form-field>
                <mat-form-field appearance="outline">
                 <mat-label>In Transit</mat-label>
                 <input matInput formControlName="ITCINT"/>
             </mat-form-field>
                       
            </div>
 
            <div class="col">
             <mat-form-field appearance="outline">
                 <mat-label>Item</mat-label>
                 <input matInput formControlName="ITEM"/>
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>Future Allocated</mat-label>
                 <input matInput formControlName="ITCALL"/>
             </mat-form-field>
         
            </div>
 
            <div class="col">
             <mat-form-field appearance="outline">
                 <mat-label>UPC</mat-label>
                 <input matInput formControlName="ITUPCC"/>
             </mat-form-field>
             <mat-form-field appearance="outline">
                 <mat-label>Location</mat-label>
                 <input matInput formControlName="LOCAT"/>
             </mat-form-field>
         
         </div>
           
     </div>     
    </form>
  </mat-card-content>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="Location">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Location
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LOCAT}}</td>
          </ng-container>
          <ng-container matColumnDef="F">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             F
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.F}}</td>
          </ng-container>
          <ng-container matColumnDef="Lot Codes">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Lot Codes
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.LOT1}}</td>
          </ng-container>
          <ng-container matColumnDef="Lot Received">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Lot Received
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.RCVD}}</td>
          </ng-container>
          <ng-container matColumnDef="Actual">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Actual
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.QTY1}}</td>
          </ng-container>
          <ng-container matColumnDef="Avail">
            <th mat-header-cell mat-header-cell  *matHeaderCellDef>
             Avail
            </th> 
            <td mat-cell *matCellDef="let element">{{ element.QTY2}}</td>
          </ng-container>
 
          <ng-container matColumnDef="Commit">
             <th mat-header-cell mat-header-cell  *matHeaderCellDef>
                 Commit
             </th> 
             <td mat-cell *matCellDef="let element">{{ element.QTY3}}</td>
           </ng-container>
 
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <mat-paginator
        #paginator
        [pageSizeOptions]="[4, 8, 16]"
        showFirstLastButtons
      ></mat-paginator>
 