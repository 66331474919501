import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { StorerCreate3Component } from '../storer-create3/storer-create3.component';
import { StorerServiceService } from '../storer-service.service';

@Component({
  selector: 'app-storer-create2',
  templateUrl: './storer-create2.component.html',
  styleUrls: ['./storer-create2.component.css']
})
export class StorerCreate2Component implements OnInit {

  form!: FormGroup;
  result: any;
  user = localStorage.getItem('userName');

  constructor(public storerSvc: StorerServiceService,
    private fb: FormBuilder,
    private router:Router,
    private activeRouter:ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
   private dialogRef: MatDialogRef<StorerCreate2Component>,
   private matDialog: MatDialog
    ) { }

  ngOnInit(): void {

    console.log(this.anyvariable.storerNumber);
       this.form = this.fb.group({

        storeNumber: this.anyvariable.storeNumber, // STORE#
        suffix: this.anyvariable.suffix, // STSFX
        storeName: this.anyvariable.storeName, // STNAME
        user1:'', // STUSR1
        user2:'', // STUSR2
        user3:'', // STUSR3
        user4:'', // STUSR4
        allItemsLotted:'', // STLOTF
        lotCodeLabel1:[
          '',
          [
            
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
          ],
        ], // STLCL1
        lotCodeLabel2:[
          '',
          [
            
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
          ],
         ], // STLCL2
        lotCodeLabel3:[
          '',
          [
            
            Validators.maxLength(10),
            Validators.pattern('^[a-zA-Z ]*$')
          ],
         ],  // STLCL3
        primaryMask:'', // STSRMF
        sortMask1:'', // @MS101
        sortMask2:'', // @MS201
        sortMask3:'', // @MS301

       });
  }


  storerCreate2(){
    var res = this.form.value;   
    this.result = this.storerSvc
      .storerServiceCreate2( 
        res.storeNumber,
        res.suffix,
        this.anyvariable.storeName,
        res.user1,
        res.user2,
        res.user3,
        res.user4,
        res.allItemsLotted,
        res.lotCodeLabel1,
        res.lotCodeLabel2,
        res.lotCodeLabel3,
        res.primaryMask,
        res.sortMask1,
        res.sortMask2,
        res.sortMask3,
        this.user

      ).subscribe((data: any) => {
        this.result = data;
        if(this.result[0].data[this.result[0].data.length-2].value=='' && this.result[0].data[this.result[0].data.length-1].value=='Created/Updated'){
          const msg= this.result[0].data[this.result[0].data.length-1].value;
          this.successMessage(msg)
          this.closeDialog();
          this.create3();
       }else{
        this.failureMessage(this.result[0].data[this.result[0].data.length-1].value);
       }
      });
  }

  successMessage(msg:any){
    Swal.fire(msg,'','success');
  }

  failureMessage(msg:any){
    Swal.fire(msg,'','error');
  }

  create3() {
    var res = this.form.value;
    this.matDialog.open(StorerCreate3Component, {      
      width: '1000px',
      maxHeight: '80vh',
      data: res,
      autoFocus: false,
    });
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
