import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-email-format-insert-before',
  templateUrl: './ww-email-format-insert-before.component.html',
  styleUrls: ['./ww-email-format-insert-before.component.css']
})
export class WwEmailFormatInsertBeforeComponent implements OnInit {

  form!: FormGroup;
  result: any;
  constructor(
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwEmailFormatInsertBeforeComponent>
  ) { }

  ngOnInit(): void {
    this.form= this.fb.group({
      Sequence:this.anyvariable.Sequence,
      Format:this.anyvariable.Format,
      MergeFile:this.anyvariable.MergeFile,
      Contents:this.anyvariable.Contents
    })
  }

  insertBeforeEmailFormat(){
    console.log(this.form.value)
  }

  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }
}
