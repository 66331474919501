import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from 'src/app/features/tallys/iheader.service';

@Component({
  selector: 'app-ww-inbound-details-stock-status',
  templateUrl: './ww-inbound-details-stock-status.component.html',
  styleUrls: ['./ww-inbound-details-stock-status.component.css']
})
export class WwInboundDetailsStockStatusComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [    
    'Location',
    'F',
    'Lot Codes',
    'Lot Received',
    'Actual',
    'Avail',
    'Commit'
    
   
  ];

  dataSource:any=[]


  duplicateSource:any =[
    {
      LOCAT:'13 DOOR/1',
      F:'',
      LOT1:'',
      RCVD:'',
      QTY1:'8',
      QTY2:'6',
      QTY3:'2',

        
    },  
    {
      LOCAT:'13 DOOR/2',
      F:'',
      LOT1:'',
      RCVD:'',
      QTY1:'2',
      QTY2:'',
      QTY3:'',

        
    },
    {
      LOCAT:'',
      F:'',
      LOT1:'Item Totals',
      RCVD:'',
      QTY1:'10',
      QTY2:'6',
      QTY3:'2',

        
    }    
  ]
  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwInboundDetailsStockStatusComponent>
  ) { }

  ngOnInit(): void {

    this.form= this.fb.group({
      STOR:'0013 Neils Item Storer', 
      ITCINT:'569',
      ITEM:'TEST 1 test item 1',
      ITCALL:'0',
      ITUPCC:'',
      LOCAT:'',
      
     
    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }
  onBack() {
    this.router.navigate(['/main-nav/WwInboundDetails/read']);
 
}
  closeDialog(){
    this.dialogRef.close();
  }

}
  
// InboundDetailsAddForm(){
//   this.matDialog.open(WwInboundDetailsAddComponent, {
//       "width": '1000px',
//        "maxHeight": '90vh',
//     //  'data': element,
//      "autoFocus": false                           
//     });

    
    

// }

//   addAttribute(){

//   }



