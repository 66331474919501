<mat-card class="over-Flow">
    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Order Detail Maintenance</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>
  
    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
          <div class="col">
            <mat-form-field appearance="outline"
              ><mat-label> Bill Of Lading </mat-label>
              <input matInput formControlName="bol" readonly/>
            </mat-form-field>
            <mat-form-field appearance="outline"
              ><mat-label> Sequence </mat-label>
              <input matInput formControlName="seq" readonly/>
            </mat-form-field>
            <mat-form-field appearance="outline"
              ><mat-label> Quantity </mat-label>
              <input matInput formControlName="quantity" />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label>Item</mat-label>
              <mat-select formControlName="items">
                <mat-option *ngFor="let item of itemOptions" [value]="item">{{
                  item
                }}</mat-option>
              </mat-select>
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label> Lot1 </mat-label>
              <input
                matInput
                formControlName="lot1"
                style="text-transform: uppercase"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label> Lot2 </mat-label>
              <input
                matInput
                formControlName="lot2"
                style="text-transform: uppercase"
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label> Lot3 </mat-label>
              <input
                matInput
                formControlName="lot3"
                style="text-transform: uppercase"
              />
            </mat-form-field>
  
            <mat-form-field appearance="outline" class="full-width">
              <mat-label> Ship From </mat-label>
              <input matInput formControlName="shipFrom" readonly/>
              
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label> Override Weight </mat-label>
              <input
                matInput
                formControlName="ovrWeight"
                style="text-transform: uppercase"
              />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label> Reason for Change </mat-label>
              <input
                matInput
                formControlName="changeReason"
                style="text-transform: uppercase"
              />
            </mat-form-field>
  
            <mat-form-field appearance="outline">
              <mat-label> Original Line </mat-label>
              <input
                matInput
                formControlName="originalLine"
                style="text-transform: uppercase"
              />
            </mat-form-field>
          </div>
        </div>
        <div>
          <button
            mat-button
            class="buttons addButton"
            (click)="changeEvent()"
          >
            Update
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  