<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Carriers </mat-card-title>
    <div class="buttons">
      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="searchTextDisplay"
      >
        <mat-icon>clear</mat-icon>
        <!-- <span>Hide Search Details</span> -->
      </button>

      <button
        mat-button
        (click)="searchTally()"
        class="addButton"
        *ngIf="!searchTextDisplay"
        matTooltip="Search Carrier"
      >
        <mat-icon>search</mat-icon>
        <!-- <span>Search By Tally Details</span> --></button
      ><button
        mat-button
        (click)="carrierCreate()"
        class="addButton"
        matTooltip="Add Carrier"
      >
        <mat-icon>add</mat-icon>
      </button>
      <mat-menu #mainMenuButton="matMenu">
        <span
          (click)="$event.stopPropagation()"
          *ngFor="let column of columnShowHideList; index as i"
        >
          <mat-slide-toggle
            style="color: blue"
            [(ngModel)]="column.isActive"
            (change)="toggleColumn(column)"
          >
            {{ column.name }}
          </mat-slide-toggle>

          <br />
        </span>
      </mat-menu>

      <button
        mat-button
        matTooltip="Hide/Show Columns"
        class="mat-focus-indicator addButton mat-button mat-button-base tally-toltip-btn"
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="mainMenuButton"
      >
        <mat-icon>visibility_off</mat-icon>
      </button>

    </div>
  </mat-card-header>

  <mat-card-content class="tally-serach-form">
    <form
      [formGroup]="form"
      (ngSubmit)="getCarrierBySearch(form.value)"
      class="form over-Flow"
      *ngIf="searchTextDisplay"
    >
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline"
            ><mat-label> Carrier Code</mat-label>
            <input
              matInput
              formControlName="carrierCode"
              style="text-transform: uppercase"
            />
          </mat-form-field>
          &nbsp;&nbsp;

          <mat-form-field appearance="outline"
            ><mat-label> Carrier Name</mat-label>
            <input matInput formControlName="carrierName" />
          </mat-form-field>
          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search"
          >
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table
    [dataSource]="dataSource"
    mat-table
    class="mat-elevation-z8 tally-table"
  >
    <ng-container matColumnDef="CarrierCode">
      <th mat-header-cell *matHeaderCellDef>Carrier Code</th>
      <td mat-cell *matCellDef="let element">{{ element.CRNUMB }}</td>
    </ng-container>

    <ng-container matColumnDef="CarrierName">
      <th mat-header-cell *matHeaderCellDef>Carrier Name</th>
      <td mat-cell *matCellDef="let element">{{ element.CRNAME }}</td>
    </ng-container>

    <ng-container matColumnDef="ZipCode">
      <th mat-header-cell *matHeaderCellDef>Zip Code</th>
      <td mat-cell *matCellDef="let element">{{ element.CRZIP }}</td>
    </ng-container>

    <ng-container matColumnDef="Suffix">
      <th mat-header-cell *matHeaderCellDef>Suffix</th>
      <td mat-cell *matCellDef="let element">{{ element.CRZSFX }}</td>
    </ng-container>

    <ng-container matColumnDef="PalletExch">
      <th mat-header-cell *matHeaderCellDef>Pallet Exch</th>
      <td mat-cell *matCellDef="let element">{{ element.CRPALE }}</td>
    </ng-container>

    <ng-container matColumnDef="Hazmat">
      <th mat-header-cell *matHeaderCellDef>Hazmat</th>
      <td mat-cell *matCellDef="let element">{{ element.CRHAZM }}</td>
    </ng-container>

    <ng-container matColumnDef="LoadingAllowance">
      <th mat-header-cell *matHeaderCellDef>Loading Allowance</th>
      <td mat-cell *matCellDef="let element">{{ element.CRLAPR }} </td>
    </ng-container>

    <ng-container matColumnDef="CWT">
      <th mat-header-cell *matHeaderCellDef>CWT</th>
      <td mat-cell *matCellDef="let element">{{ element.CRLAWG }}</td>
    </ng-container>

    <ng-container matColumnDef="Min/Max">
      <th mat-header-cell *matHeaderCellDef>Min/Max</th>
      <td mat-cell *matCellDef="let element">
        {{ element.CRLAMI }}/{{ element.CRLAMA }}
      </td>
    </ng-container>

    <ng-container matColumnDef="Actions">
      <th mat-header-cell class="tally_actions_sec" *matHeaderCellDef>
        <!-- Actions -->
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="dropdown more_options_btn">
          <button
            class="btn"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <button mat-icon-button (click)="carrierUpdate(element)">
                <mat-icon mat-icon-button color="primary">edit</mat-icon>
                <mat-label>Edit</mat-label>
              </button>
            </li>
            <li>
              <button mat-icon-button (click)="carrierDelete(element)">
                <mat-icon mat-icon-button color="warn">delete</mat-icon>
                <mat-label>Delete</mat-label>
              </button>
            </li>
          </ul>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[4, 8, 16]"
    showFirstLastButtons
    class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
