<button mat-icon-button (click)="closeDialog()">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card class="over-Flow">
  <mat-card-header>
    <mat-card-title class="title"> Work With Tags </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Tag Number</mat-label>
            <input matInput formControlName="TagNumber" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Team</mat-label>
            <input matInput formControlName="Team" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Differences Only</mat-label>
            <input matInput formControlName="DifferencesOnly" />
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Voids </mat-label>
            <input matInput formControlName="Voids" /> </mat-form-field
          ><mat-form-field appearance="outline">
            <mat-label>Include No Counts</mat-label>
            <input matInput formControlName="IncludeNoCounts" />
          </mat-form-field>
        </div>
      </div>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <!-- Tally Column -->
        <ng-container matColumnDef="TagNumber">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Tag Number
          </th>
          <td mat-cell *matCellDef="let element">{{ element.TagNumber }}</td>
        </ng-container>

        <ng-container matColumnDef="Qty">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Qty
          </th>
          <td mat-cell *matCellDef="let element">{{ element.Qty }}</td>
        </ng-container>

        <ng-container matColumnDef="TP">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            TP
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.TP }}
          </td>
        </ng-container>

        <ng-container matColumnDef="Item">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Item
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Item }}
          </td>
        </ng-container>
        <ng-container matColumnDef="LotCodes">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Lot Codes
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.LotCodes }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Aisle">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Aisle
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Aisle }}
          </td>
        </ng-container>
        <ng-container matColumnDef="Slot">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            Slot
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.Slot }}
          </td>
        </ng-container>
        <ng-container matColumnDef="St">
          <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
            St
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.St }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Book Lines</mat-label>
            <input matInput formControlName="BookLines" />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Book Quantity</mat-label>
            <input matInput formControlName="BookQuantity" />
          </mat-form-field>
        </div>
      </div></form
  ></mat-card-content>
  <!-- <mat-paginator
            #paginator
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
