import { Component, OnInit, Inject} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-locater-delete',
  templateUrl: './ww-locater-delete.component.html',
  styleUrls: ['./ww-locater-delete.component.css']
})
export class WwLocaterDeleteComponent implements OnInit {

  
storer: any;
form!: FormGroup;
result: any;
user = 'NKOHAN';
constructor(public iHeaderSvc: IheaderService, 
  @Inject(MAT_DIALOG_DATA) public anyvariable: any,
  private fb: FormBuilder,
  private dialogRef: MatDialogRef<WwLocaterDeleteComponent>
 ) 
 { }

ngOnInit(): void {
  this.form = this.fb.group({
    STOR:'5002',
    ITMDS:'244',
    LCBLDG:'1',
    LCFLOR:'',
    LCAISL:'A',
    LCSLOT:'1',
     
  });
}

closeDialog(){
  // Write your stuff here
  this.dialogRef.close(); // <- Close the mat dialog
}


backstorer(){
  
}
attributes(){
  
}
}