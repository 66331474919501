<mat-card class="over-Flow">

    <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Delete Warehouse Zone</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()">
        <mat-icon mat-icon-button color="primary">close</mat-icon>
      </button>
    </div>

    <mat-card-content class="tally-serach-form">
      <form [formGroup]="form">
        <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label> Building </mat-label>
            <input matInput maxlength="3" formControlName="buildingNumber" readonly />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label> Zone</mat-label>
            <input matInput maxlength="1" formControlName="zone" readonly />
          </mat-form-field>
        
          <mat-form-field appearance="outline">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" readonly />
          </mat-form-field>
        
          <br/>
          <b style="margin-left: 20px;">Press Enter to confirm Delete</b>

          <button mat-button class="buttons removeButton" (click)="delete()">
            <span>Delete</span>
          </button>
        </div>
        </div>
      </form>
    </mat-card-content>
</mat-card>

