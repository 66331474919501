import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-email-exit-points-update',
  templateUrl: './email-exit-points-update.component.html',
  styleUrls: ['./email-exit-points-update.component.css'],
})
export class EmailExitPointsUpdateComponent implements OnInit {
  updateEmailExitPointsForm!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<EmailExitPointsUpdateComponent>,
    private router: Router,
    private fb: FormBuilder
  ) {}
  update() {}

  closeDialog() {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.updateEmailExitPointsForm = this.fb.group({
      EPPOIN: [this.data.EPPOIN],
      EPEFMT: [this.data.EPEFMT],
      EPNTYP: [this.data.EPNTYP],
      EPCTYP: [this.data.EPCTYP],
      EPMNAM: [this.data.EPMNAM],
      EPMBLD: [this.data.EPMBLD],
      EPSTAT: [this.data.EPSTAT],
    });
  }
}
