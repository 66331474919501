import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IheaderService } from '../../tallys/iheader.service';

@Component({
  selector: 'app-ww-labor-plan-event-inquiry',
  templateUrl: './ww-labor-plan-event-inquiry.component.html',
  styleUrls: ['./ww-labor-plan-event-inquiry.component.css']
})
export class WwLaborPlanEventInquiryComponent implements OnInit {

  form!: FormGroup;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  displayedColumns: string[] = [
    // 'ProcessTask',
    // 'ReleaseDate',
    // 'ReleaseTime',
    // 'CompletedDate',
    // 'CompletedTime',
    // 'OR',
    // 'ST',
    // 'Operator'
    // 'Opt'
    'Sequence',
    'Comment',
    'Date',
    'Time',
    'User'
  ];

  dataSource: any = []

  duplicateSource: any = [
    {
      Sequence : '1',
      Comment : 'Labor Plan Was Updated ',
      Date : '08/26/19',
      Time : '11:13:00',
      User : 'JKOHAN'
    },
    {
      Sequence : '2',
      Comment : 'Labor Plan Was Updated ',
      Date : '09/03/19 ',
      Time : '16:16:36',
      User : 'JKOHAN'
    }


  ]

  constructor(
    private router: Router,
    public iHeaderSvc: IheaderService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public anyvariable: any,
    private dialogRef: MatDialogRef<WwLaborPlanEventInquiryComponent>
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      Document: 'O   4790321',
      // MustStartDate: '',
      // Building: '',
      // Status: 'OP',
      // Storer: '',
      // AppointmentDate: '',
      // Active: '',
      // SCAC: '',
      // Operator: '',
      // Manual_Auto: 'A'

    })

    this.dataSource = new MatTableDataSource(this.duplicateSource);
    this.dataSource.paginator = this.paginator;
  }

  onBack() {
    this.router.navigate(['/main-nav/tallys/read']);
  }
  closeDialog(){
    // Write your stuff here
    this.dialogRef.close(); // <- Close the mat dialog
  }

}
