<button mat-icon-button (click)="closeDialog()" class="closeIcon">
    <mat-icon  mat-icon-button color="primary">close</mat-icon>
  </button>
          <form [formGroup]="form">
              <mat-card>
                <mat-card-header>
                    <button mat-button (click)="backstorer()">
                        <mat-icon></mat-icon>
                        
                    </button>
                    
                  <mat-card-title style="color: rgb(203, 207, 219)"
                    >Maintain Item Attributes</mat-card-title>
                  
                </mat-card-header>
                
                <mat-card-content> 
                        
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Storer</mat-label> 
                          <input matInput formControlName="IASTOR" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                      <mat-form-field appearance="outline">  <mat-label> Item Code </mat-label> 
                          <input matInput formControlName="IAITEM" />
                        </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> Number Type</mat-label> 
                        <input matInput formControlName="IANTYP" />
                      </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-form-field appearance="outline">  <mat-label> Number</mat-label> 
                        <input matInput formControlName="IANUMB" />
                      </mat-form-field>
                      <button mat-button
                      class="buttons addButton"
                      mat-raised-button
                      (click)="attributes()">
                      Add
                    </button> </div>
                        
                </mat-card-content>
              </mat-card>
            </form> 
