<mat-card class="over-Flow">
  
  <div class="main-header-title">
      <mat-card-header>
        <mat-card-title>Work with Accounting Attributes</mat-card-title>
      </mat-card-header>
      <button mat-icon-button (click)="closeDialog()" class="closeIcon">           
        <mat-icon  mat-icon-button color="primary">close</mat-icon>
      </button>
  </div>

  <mat-card-content class="tally-serach-form">
    <form [formGroup]="form" class="form over-Flow">
      <div class="row">
        <div class="col">
          <mat-form-field appearance="outline">
            <mat-label>Customer</mat-label>
            <input matInput formControlName="customer" />
          </mat-form-field>

          <button
            class="buttons addButton"
            mat-raised-button
            type="submit"
            placeholder="Search">
            Search
          </button>
        </div>
      </div>
    </form>
  </mat-card-content>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 tally-table">
    <ng-container matColumnDef="Attribute">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Attribute
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IHTALY }}</td>
    </ng-container>

    <ng-container matColumnDef="AttributeValue">
      <th mat-header-cell mat-header-cell width="60px" *matHeaderCellDef>
        Attribute Value
      </th>
      <td mat-cell *matCellDef="let element">{{ element.IHINVO }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <!-- <mat-paginator
      #paginator
      [pageIndex]="0"
      [pageSize]="10"
      [pageSizeOptions]="[5, 10, 25, 100]"
    >
    </mat-paginator> -->
  <mat-paginator
    [pageSizeOptions]="[8, 16, 24]"
    showFirstLastButtons class="tally-table-pagination"
  ></mat-paginator>
</mat-card>
