<button mat-icon-button (click)="closeDialog()" class="closeIcon">
  <mat-icon mat-icon-button color="primary">close</mat-icon>
</button>

<mat-card-header>
  <mat-card-title>Shipment Synopsis</mat-card-title>
</mat-card-header>

<mat-card-content>
  <form [formGroup]="form" fxLayoutAlign="center">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Shipment </mat-label>
          <input matInput formControlName="Shipment" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label> Load </mat-label>
          <input matInput formControlName="Load" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Total Quantity </mat-label>
          <input matInput formControlName="TotalQuantity" />
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Status </mat-label>
          <input matInput formControlName="Status" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Stop </mat-label>
          <input matInput formControlName="Stop" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Total CBFT </mat-label>
          <input matInput formControlName="TotalCBFT" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Carrier </mat-label>
          <input matInput formControlName="Carriers" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Total Weight </mat-label>
          <input matInput formControlName="TotalWeight" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label> Customer </mat-label>
          <input matInput formControlName="Customer" />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Deliver To </mat-label>
          <input matInput formControlName="DeliverTo" />
        </mat-form-field>
      </div>

      <div class="col">
        <u><h3>Numbers</h3></u>
      </div>

      <div class="col">
        <u><h3>Dates</h3></u>
      </div>
    </div>
  </form>
</mat-card-content>
